import React, { useState, useEffect, useRef } from "react";
import { visa_image, credit_card, visa_image2, visa_image3, visa_image4, check_image } from "../../assets/images/images";
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardNumberElement, CardExpiryElement, CardCvcElement, cardholderName, useStripe, useElements } from '@stripe/react-stripe-js';
// import '../../components/PaymentStrip/stripePayment.css'; // Make sure to create a CSS file for styles
import BackButton from '../BackButton/BackButton';
// import SuccessModal from "./OrderSucess";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from '../../features/auth/AuthContext';
import api from '../../api/api';
import CongratulationsPopup from "../CongratulationPopup/Congratulations";
import { resetGeneratePracticeAssignment } from "../../state/actions/generatePracticeAssignmentAction";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "react-responsive-modal";
import CancelPopup from '../CancelPopup/CancelPopup';
import "./PracticeAssignmentPatment.css";
import PracticeAssignmentPaymentBill from "./PracticeAssignmentPaymentBill";
import { toast } from "react-toastify";
import { generateAssignmentJson, resetgenerateAssignJson } from "../../state/actions/generatePracticeAssignmentJsonAction";

// const stripePromise = loadStripe(process.env.REACT_APP_PUBLISH_KEY); // Replace with your Stripe publishable key

function CheckoutForm() {
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [paymentStatus, setPaymentStatus] = useState('');
    const [isPaymentLoading, setisPaymentLoading] = useState(false);
    const [paymentSuccessPopup, setpaymentSuccessPopup] = useState(false);
    const [cardholderName, setCardholderName] = useState(''); // State to hold the cardholder's name
    const [isCardNumberValid, setCardNumberValid] = useState(false);
    const [isCardExpiryValid, setCardExpiryValid] = useState(false);
    const [isCardCvcValid, setCardCvcValid] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [item_id, setitem_id] = useState();
    const formRef = useRef(null); // Ref for the form
    const { token } = useAuth();
    const dispatch = useDispatch();

    const proceedToPay = useSelector((state) => state.generatePracticeAssignment);

    // Function to dynamically adjust CARD_ELEMENT_OPTIONS based on screen width
    const getCardElementOptions = () => {
        // Default fontSize
        let fontSize = '16px';

        // Check if the screen width is less than or equal to 600px (common breakpoint for mobile devices)
        // if (window.innerWidth <= 600) {
        //   // Adjust fontSize for mobile screens
        //   fontSize = '12px';
        // }
        if (window.innerWidth <= 768) {
            // Adjust fontSize for mobile screens
            fontSize = '12px';
        }

        // Return the CARD_ELEMENT_OPTIONS with the adjusted fontSize
        return {
            style: {
                base: {
                    fontSize: fontSize,
                    color: '#424770', // Text color
                    '::placeholder': {
                        color: '#808080', // Placeholder text color
                    },
                },
                invalid: {
                    color: '#9e2146', // Color for invalid input
                },
            },
        };
    };

    // Use this function to get the dynamically adjusted options
    const CARD_ELEMENT_OPTIONS = getCardElementOptions();


    const [fontSize, setFontSize] = useState('14px'); // Default to mobile view

    useEffect(() => {
        const updateFontSize = () => {
            const screenWidth = window.innerWidth;
            if (screenWidth > 1024) {
                setFontSize('16px'); // Desktop
            } else if (screenWidth > 768) {
                setFontSize('15px'); // Tablet
            } else {
                setFontSize('14px'); // Mobile
            }
        };

        window.addEventListener('resize', updateFontSize);
        updateFontSize(); // Initialize font size

        return () => window.removeEventListener('resize', updateFontSize);
    }, []);

    const handleSubmit = async () => {
        setisPaymentLoading(true);
        if (!stripe || !elements) {
            setError('Stripe has not loaded yet.');
            return;
        }

        try {
            const cardElement = elements.getElement(CardNumberElement);
            const payload = {
                order_id: proceedToPay.order_id,
                purchase_type: "practice_assignment"
            };
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                }
            };
            const paymentIntentResponse = await api.post('payments/payment/', payload, config);
            const clientSecret = paymentIntentResponse.data.client_secret

            const result = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: cardElement,
                    billing_details: {
                        name: cardholderName,
                    },
                },
            });

            if (result.error) {
                setError(result.error.message);
                toast.error('Payment failed. Please try again.');
                setisPaymentLoading(false)
            } else {
                if (result.paymentIntent.status === 'succeeded') {
                    dispatch(generateAssignmentJson(pracAssignJson, token))
                    setPaymentStatus('Payment succeeded!');
                    setpaymentSuccessPopup(true);
                    setisPaymentLoading(false);
                }
            }
        } catch (err) {
            setError('An error occurred.');
            console.error(err);
        }
    };


    const close = (id) => {
        setitem_id(id)
        setOpenDialog(!openDialog); // This will close the popup
    };
    const location = useLocation();
    const lessonId = location?.state?.lessonId;
    const courseId = location?.state?.courseId;
    const course = location?.state?.course;
    const lesson = location?.state?.lesson;
    const pracAssignJson = location?.state?.pracAssignJson;

    const backtolessons = () => {
        dispatch(resetGeneratePracticeAssignment());
        navigate(`/course/${courseId}/lesson/${lessonId}/overview`, {
            state: {
                course,
                lesson,
                activeTab: "Assignments",
            },
        })
    }

    const isFormValid = () => {
        return isCardNumberValid && isCardExpiryValid && isCardCvcValid && (cardholderName.trim() !== '');
    };

    const handleChange = (event) => {
        // Update the validity state based on the element's type
        switch (event.elementType) {
            case 'cardNumber':
                setCardNumberValid(event.complete);
                break;
            case 'cardExpiry':
                setCardExpiryValid(event.complete);
                break;
            case 'cardCvc':
                setCardCvcValid(event.complete);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (paymentSuccessPopup) {
            setTimeout(() => {
                dispatch(resetGeneratePracticeAssignment());
                setpaymentSuccessPopup(false)
                navigate(`/course/${courseId}/lesson/${lessonId}/overview`, {
                    state: {
                        courseId: courseId,
                        lessonId: lessonId,
                        course: course,
                        lesson: lesson,
                        activeTab: "Assignments",
                        submission_id: pracAssignJson.submission_id,
                        practice_id: pracAssignJson.practice_id
                    }
                });
            }, 3000)
        }
    }, [paymentSuccessPopup])

    // useEffect(() => {
    //     return () => {
    //         dispatch(resetGeneratePracticeAssignment());
    //     }
    // }, [])


    return (
        <div className="practice-assignment-payment-section">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className='paymentStripe-section'>
                            <div className="Back_button" onClick={backtolessons} >
                                <i class="fa-solid fa-chevron-left"></i>
                                <span>Choose your payment method</span>
                            </div>
                            <div className='paymentStripe-wrapper'>
                                <div className='paymentStrip-left-section'>
                                    <>
                                        <div className='payment-des-container'>
                                            <p>Safe money transfer using  your bank account.
                                                Visa, Mastercard, Discover, and American express.
                                            </p>
                                            <div className='payment-visa-image-folder'>
                                                <div className='payment-visa-image'>
                                                    <img src={visa_image} />
                                                </div>
                                                <div className='payment-visa-image'>
                                                    <img src={visa_image2} />
                                                </div>
                                                <div className='payment-visa-image'>
                                                    <img src={visa_image3} />
                                                </div>
                                                <div className='payment-visa-image'>
                                                    <img src={visa_image4} />
                                                </div>
                                            </div>
                                        </div>
                                        <form className='payment-form'>
                                            <div className="payment-form-input">
                                                <label htmlFor="cardNumber">Card Number</label>
                                                <CardNumberElement id="cardNumber" options={CARD_ELEMENT_OPTIONS} onChange={handleChange} />
                                                <img className="credit_card" src={credit_card} />
                                            </div>
                                            <div className='payment-form-Card-inner-wrapper'>
                                                <div className="payment-form-input">
                                                    <label htmlFor="cardExpiry">Card Expiry</label>
                                                    <CardExpiryElement id="cardExpiry" options={CARD_ELEMENT_OPTIONS} onChange={handleChange} />
                                                    <img className="credit_card" src={credit_card} />
                                                </div>
                                                <div className="payment-form-input">
                                                    <label htmlFor="cardCvc">CVC</label>
                                                    <CardCvcElement id="cardCvc" options={CARD_ELEMENT_OPTIONS} onChange={handleChange} />
                                                    <img className="credit_card" src={credit_card} />
                                                </div>
                                            </div>
                                            <div className="payment-form-input">
                                                <label for="cardholder-name">Name on Card</label>
                                                <input
                                                    id="cardholder-name"
                                                    type="text"
                                                    placeholder="Enter your name"
                                                    required
                                                    value={cardholderName}
                                                    onChange={(e) => setCardholderName(e.target.value)}
                                                />
                                                <img className="credit_card" src={credit_card} />
                                            </div>
                                        </form>
                                    </>
                                </div>
                                <div className='paymentStrip-right-section'>
                                    <PracticeAssignmentPaymentBill isFormValid={isFormValid()} handleSubmit={handleSubmit} isPaymentLoading={isPaymentLoading} />
                                </div>
                                <div className='paymentStripe-section'>
                                </div>
                            </div>
                            {paymentSuccessPopup && <CongratulationsPopup
                                check_image={check_image}
                                des="Practice Assignments has been generated successfully."
                            />}

                            {openDialog && (
                                <Modal
                                    open={openDialog}
                                    onClose={() => setOpenDialog(false)}
                                    center
                                    showCloseIcon={false}
                                    classNames={{
                                        modal: 'Cancel-Popup',
                                    }}
                                >
                                    <CancelPopup
                                        title="Are you sure you want to remove this course from the cart?"
                                        No="No"
                                        Yes="Yes"
                                        // show={show} // Assuming you have a state or value to pass 
                                        close={close} // Pass the reference, not the invocation
                                    />
                                </Modal>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function PracticeAssignmentPayment() {

    const globalState = useSelector((state) => state.globalState);


    const stripePromise = loadStripe(globalState.is_test ? process.env.REACT_APP_TEST_PUBLISH_KEY : process.env.REACT_APP_PUBLISH_KEY); // Replace with your Stripe publishable key



    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm />
        </Elements>
    );
}


export default PracticeAssignmentPayment;

