import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import activeTabReducer from "../reducers/activeTabReducer";
import addBulkProfileReducer from "../reducers/addBulkProfileReducer";
import addIndividualProfileReducer from "../reducers/addIndividualProfileReducer";
import AddSchoolAdminReducer from "../reducers/addSchoolAdminReducer";
import addSchoolReducer from "../reducers/addSchoolReducer";
import addToCartReducer from "../reducers/addToCartReducer";
import adminDashboardCountReducer from "../reducers/adminDashboardCountReducer";
import adminDashboardGraphReducer from "../reducers/adminDashboardGraphReducer";
import adminLoginReducer from "../reducers/adminLoginReducer";
import adminRegistrationReducer from "../reducers/adminRegistrationReducer";
import assignmentAnalyticsReducer from "../reducers/assignmentAnalyticsReducer";
import assignmentFileUploadReducer from "../reducers/assignmentFileUploadReducer";
import bookADemoReducer from "../reducers/bookADemoReducer";
import cartSuggestedCoursesReducer from "../reducers/cartSuggestedCoursesReducer";
import cartlistReducer from "../reducers/cartlistReducer";
import coursePaymentReducer from "../reducers/coursePaymentReducer";
import deleteAssignmentReducer from "../reducers/deleteAssignmentReducer";
import deleteCourseReducer from "../reducers/deleteCourseReducer";
import deleteLessonReducer from "../reducers/deleteLessonReducer";
import downlodeTeacherRevenueReducer from "../reducers/downlodeTeacherRevenueReducer";
import firstLoginReducer from "../reducers/firstLoginReducer";
import generatePracticeAssignmentReducer from "../reducers/generatePracticeAssignmentReducer";
import getAllCoursesByTeacherIdReducer from "../reducers/getAllCoursesByTeacherIdReducer";
import getAllCoursesReducer from "../reducers/getAllCoursesReducer";
import getAllSchoolsReducer from "../reducers/getAllSchoolsReducer";
import getAllTeacherBySchoolReducer from "../reducers/getAllTeacherBySchoolReducer";
import getAssignmentStatsReducer from "../reducers/getAssignmentStatsReducer";
import getAssignmentsByLessonReducer from "../reducers/getAssignmentsByLessonReducer";
import getCourseByIdReducer from "../reducers/getCourseByIdReducer";
import getCourseListBySchoolIDReducer from "../reducers/getCourseListBySchoolIDReducer";
import getFileIdByNameReducer from "../reducers/getFileIdByNameReducer";
import getLessonByLessonIdReducer from "../reducers/getLessonByLessonIdReducer";
import getLessonStatsReducer from "../reducers/getLessonStatsReducer";
import getLetestTeacherReducer from "../reducers/getLetestTeacherReducer";
import getPlatformFeeReducer from "../reducers/getPlatformFeeReducer";
import getPracticeAssignmentsReducer from "../reducers/getPracticeAssignmentsReducer";
import getProfileDetailsReducer from "../reducers/getProfileDetailsReducer";
import getSchoolByIDReducer from "../reducers/getSchoolByIDReducer";
import getSchoolDetailsReducer from "../reducers/getSchoolDetailsReducer";
import getStudentByStudentIdReducer from "../reducers/getStudentByStudentIdReducer";
import getStudentEnrolledCoursesReducer from "../reducers/getStudentEnrolledCoursesReducer";
import getStudentSubsciptionPlanReducer from "../reducers/getStudentSubsciptionPlanReducer";
import getTeacherByTeacherIdReducer from "../reducers/getTeacherByTeacherIdReducer";
import getTeacherSubsciptionPlanReducer from "../reducers/getTeacherSubsciptionPlanReducer";
import getTextToSpeechReducer from "../reducers/getTextToSpeechReducer";
import getUploadFileStatusReducer from "../reducers/getUploadFileStatusReducer";
import getdataListReducer from "../reducers/getdataListReducer";
import historyReducer from "../reducers/historyReducer";
import notificationListReducer from "../reducers/notificationListReducer";
import practiceAssignmentPaymentReducer from "../reducers/practiceAssignmentPaymentReducer";
import profileLoginReducer from "../reducers/profileLoginReducer";
import purchaseHistoryReducer from "../reducers/purchaseHistoryReducer";
import removeCartReducer from "../reducers/removeCartReducer";
import removeStudentsFromCourseReducer from "../reducers/removeStudentsFromCourseReducer";
import sentInvitationListReducer from "../reducers/sentInvitationListReducer";
import setStudentSubscriptionPlanReducer from "../reducers/setStudentSubscriptionPlanReducer";
import setTeacherSubsciptionPlanReduce from "../reducers/setTeacherSubsciptionPlanReducer";
import studentSuggestedDashboardCoursesReducer from "../reducers/studentSuggestedDashboardCoursesReducer";
import superadminLoginReducer from "../reducers/superadminLoginReducer";
import teacherGeneraterRevenueReducer from "../reducers/teacherGeneraterRevenueReducer";
import teacherRatingReducer from "../reducers/teacherRatingReducer";
import teacherRatingReplyReducer from "../reducers/teacherRatingReplyReducer";
import updateCourseReducer from "../reducers/updateCourseReducer";
import updateSchoolReducer from "../reducers/updateSchoolReducer";
import updateTeacherProfileReducer from "../reducers/updateTeacherProfileReducer";
import userActivityLogsReducer from "../reducers/userActivityLogsReducer";
import userRegistrationReducer from "../reducers/userRegistrationReducer";
import courseDescriptionEnhancementReducer from "../reducers/courseDescriptionEnhancementReducer";
import courseImageGenerateReducer from "../reducers/courseImageGenerateReducer";
import globalStateReducer from "./slice";
import getUserActivePlanReducer from "../reducers/getUserActivePlanReducer";
import teacherCurrentSubscriptionPlanReducer from "../reducers/teacherCurrentSubscriptionPlanReducer";
import cancelSubscriptionReducer from "../reducers/cancelSubscriptionReducer";
import studentCurrentSubscriptionPlanReducer from "../reducers/studentCurrentSubscriptionPlanReducer";
import modifySubscriptionReducer from "../reducers/modifySubscriptionReducer";
import setAdminSubscriptionPlanReducer from "../reducers/setAdminSubscriptionPlanReducer";
import getAdminSubscriptionPlanReducer from "../reducers/getAdminSubscriptionPlanReducer";
import adminCurrentSubscriptionPlanReducer from "../reducers/adminCurrentSubscriptionPlanReducer";
import cancelAdminSubscriptionReducer from "../reducers/cancelAdminSubscriptionReducer";
import courseFileUploadReducer from "../reducers/courseFileUploadReducer";
import courseFileUploadStatusReducer from "../reducers/courseFileUploadStatusReducer";
import getCourseFileUploadsReducer from "../reducers/getCourseFileUploadsReducer";
import deleteUploadedCourseFileReducer from "../reducers/deleteUploadedCourseFileReducer";

const globalStatePersistConfig = {
  key: "globalState",
  storage,
  whitelist: [
    "token",
    "role",
    "email",
    "teacher_details",
    "student_details",
    "has_profile",
    "datalist",
    "school_id",
    "grade_details",
    "school_details",
    "subadmin_details",
    "course_list",
    'userCurrentPlan',
    'is_test'
  ],
};

const persistedReducer = persistReducer(
  globalStatePersistConfig,
  globalStateReducer
);
const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: {
    ignoredActions: ["persist/PERSIST"],
    ignoredActionPaths: ["meta.arg", "payload.timestamp"],
    ignoredPaths: ["items.dates"],
  },
});

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

const store = configureStore({
  reducer: {
    globalState: persistedReducer,
    profilelogin: profileLoginReducer,
    userregistration: userRegistrationReducer,
    firstlogin: firstLoginReducer,
    adminregistration: adminRegistrationReducer,
    adminlogin: adminLoginReducer,
    superadminLogin: superadminLoginReducer,
    addSchool: addSchoolReducer,
    schoolById: getSchoolByIDReducer,
    schoolList: getAllSchoolsReducer,
    fetchSchoolData: getAllTeacherBySchoolReducer,
    getCoursesbySchoolId: getCourseListBySchoolIDReducer,
    addIndividualProfile: addIndividualProfileReducer,
    addbulkProfile: addBulkProfileReducer,
    letestteacherlist: getLetestTeacherReducer,
    getallCoursesByTeacherId: getAllCoursesByTeacherIdReducer,
    getteacherbyTeacherId: getTeacherByTeacherIdReducer,
    getdatalist: getdataListReducer,
    getstudentByStudentId: getStudentByStudentIdReducer,
    getStudentEnrolledCourses: getStudentEnrolledCoursesReducer,
    assgnAnalytics: assignmentAnalyticsReducer,
    navigateHistory: historyReducer,
    profileDetails: getProfileDetailsReducer,
    notificationList: notificationListReducer,
    sentInvitations: sentInvitationListReducer,
    updateTeacherProfile: updateTeacherProfileReducer,
    assignmentStats: getAssignmentStatsReducer,
    lessonStats: getLessonStatsReducer,
    allcoursesList: getAllCoursesReducer,
    superadminCount: adminDashboardCountReducer,
    schoolDetails: getSchoolDetailsReducer,
    updateSchoolDetails: updateSchoolReducer,
    adminDashboardGraph: adminDashboardGraphReducer,
    deleteCourseById: deleteCourseReducer,
    addSchoolAdmin: AddSchoolAdminReducer,
    getFileIdByName: getFileIdByNameReducer,
    getFileUploadStatus: getUploadFileStatusReducer,
    addToCart: addToCartReducer,
    cartlist: cartlistReducer,
    removeCartData: removeCartReducer,
    proceedToPay: coursePaymentReducer,
    activeTabState: activeTabReducer,
    bookdemo: bookADemoReducer,
    purchaseHistory: purchaseHistoryReducer,
    teacherRating: teacherRatingReducer,
    teacherRatingReply: teacherRatingReplyReducer,
    platformFee: getPlatformFeeReducer,
    suggestedCourses: cartSuggestedCoursesReducer,
    teacherRevenueReducer: teacherGeneraterRevenueReducer,
    downlodeRevenue: downlodeTeacherRevenueReducer,
    updatecourse: updateCourseReducer,
    getCourse: getCourseByIdReducer,
    suggestedDashboardCourses: studentSuggestedDashboardCoursesReducer,
    userActivityLogs: userActivityLogsReducer,
    assignmentFileUpload: assignmentFileUploadReducer,
    deleteLesson: deleteLessonReducer,
    deleteAssignment: deleteAssignmentReducer,
    texttospeech: getTextToSpeechReducer,
    removestudentfromcourse: removeStudentsFromCourseReducer,
    studentSubscription: getStudentSubsciptionPlanReducer,
    teacherSubscription: getTeacherSubsciptionPlanReducer,
    setStudentSubscriptionPlan: setStudentSubscriptionPlanReducer,
    setTeacherSubsciptionPlan: setTeacherSubsciptionPlanReduce,
    getassignmentsbyLesson: getAssignmentsByLessonReducer,
    practiceAssignmentPayment: practiceAssignmentPaymentReducer,
    generatePracticeAssignment: generatePracticeAssignmentReducer,
    getPracticeAssignments: getPracticeAssignmentsReducer,
    getlesson: getLessonByLessonIdReducer,
    getCourseDescription: courseDescriptionEnhancementReducer,
    getcourseImage: courseImageGenerateReducer,
    userActivePlan: getUserActivePlanReducer,
    teacherCurrentPlan: teacherCurrentSubscriptionPlanReducer,
    studentCurrentPlan: studentCurrentSubscriptionPlanReducer,
    cancelSubscription: cancelSubscriptionReducer,
    modifyUserSubscription: modifySubscriptionReducer,
    setAdminSubscriptionPlan: setAdminSubscriptionPlanReducer,
    adminSubscription: getAdminSubscriptionPlanReducer,
    adminCurrentPlan: adminCurrentSubscriptionPlanReducer,
    cancelAdminSubscription: cancelAdminSubscriptionReducer,
    coursefileUploads: courseFileUploadReducer,
    courseFileUploadStatus: courseFileUploadStatusReducer,
    getCourseFileUploads: getCourseFileUploadsReducer,
    deleteUploadedCourseFile: deleteUploadedCourseFileReducer,

  },
  middleware: customizedMiddleware,
  enhancers: (defaultEnhancers) => [sentryReduxEnhancer, ...defaultEnhancers],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
export default store;
