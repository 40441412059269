import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface StudentCurrentSubscriptionPlan {
    isLoading: boolean;
    error: string | null;
    currentSubscription: any;
}

const initialState: StudentCurrentSubscriptionPlan = {
    currentSubscription: null,
    isLoading: false,
    error: null,
};

const StudentCurrentSubscriptionPlanSlice = createSlice({
    name: 'StudentCurrentSubscriptionPlan',
    initialState,
    reducers: {
        setStudentCurrentSubscriptionPlan: (state: StudentCurrentSubscriptionPlan, action: PayloadAction<StudentCurrentSubscriptionPlan>) => {
            state.currentSubscription = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: StudentCurrentSubscriptionPlan, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: StudentCurrentSubscriptionPlan, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetStudentCurrentSubscriptionPlan: () => {
            return initialState;
        },
    },
});

export const { setStudentCurrentSubscriptionPlan, setloading, seterror, resetStudentCurrentSubscriptionPlan } = StudentCurrentSubscriptionPlanSlice.actions;

export default StudentCurrentSubscriptionPlanSlice.reducer;
