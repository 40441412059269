import { Dispatch } from "redux";
import api from "../../api/api";
import {
    setgetCourse,
    setloading,
    seterror,
    //setPagination,
    resetgetCourse,
} from "../reducers/getCourseByCourseIdReducer";

const getCourseById = (id: any, token: any) => async (
    dispatch: Dispatch
) => {
    dispatch(setloading(true));
    try {
        const result = await api.get(`/courses/get-course/${id}/`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
        });

        if (result) {
            dispatch(setloading(false));
            if (result.data?.status === 200) {
                dispatch(setgetCourse(result.data.data));
            } else {
                dispatch(seterror(result.data?.message ?? "Something Went Wrong!"));
            }
        }
    } catch (error: any) {
        dispatch(seterror(error.response.data.message));
    }
};

export { getCourseById, resetgetCourse };

