import React from "react";
import "./PracticeAssignmentPatment.css";
import LoaderComponent from "../LoaderComponent/LoaderComponent";

function PracticeAssignmentPaymentBill({
    isFormValid, handleSubmit, isPaymentLoading
}) {
    return (
        <>
            <h2>Billing Summary</h2>
            <div className="paymentStripe-course-container">
                <p>Practice Assignment</p>
                <div className="paymentStripe-course-folder">
                    <div className="paymentStripe-course">
                        <span>Practice Assignment : 1</span>
                        <span><span>
                            {/* <i class="fa-solid fa-x"></i> */}
                        </span> ${1}</span>
                    </div>
                </div>
            </div>
            <div className='paymentStrip-price-wrapper'>
                <div className='paymentStrip-price-totle-folder'>
                    <span>Total</span>
                    <span>${1}</span>
                </div>
            </div>
            <button
                className='payment-proceed-to-pay'
                onClick={handleSubmit}
                disabled={!isFormValid || isPaymentLoading}
                style={{
                    backgroundColor: isFormValid ? '#0068FF' : 'gray', // Change 'normalBackgroundColor' to your desired color
                    color: isFormValid ? '#ffffff' : 'colorWhenDisabled', // Optionally set the text color
                }}
            >
                {isPaymentLoading ? <LoaderComponent /> : "Proceed to Pay"}
            </button>
        </>
    );
}


export default PracticeAssignmentPaymentBill;

