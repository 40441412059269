import React from "react";
import "./LessonGenerator.css";
import "react-datetime/css/react-datetime.css";
import { Tooltip } from "react-tooltip";

function LessonGenratorWorkBoardDropdown() {

    return (
        <>
            <div className="edit-active-lesson-btn-container">
                <div className="tab-dropdown">
                    <button
                        // key={index}
                        type="button"
                        // style={lessonbuttonStyle}
                        className="dropbtn"
                        // onClick={() => {
                        //     update(
                        //         Object.values(workboards).find(
                        //             (item) => item.name === 'Lesson'
                        //         ),
                        //         workboard?.name
                        //     )
                        //     // lessonFunction(workboard?.name)
                        // }
                        // }
                    > 
                    {/* {workboard?.name} */}
                    Lesson
                    </button>
                </div>
                <div className="tab-dropdown" 
                    // ref={dropdownRef}
                >
                    <button className="dropbtn" 
                    // onClick={toggleAssingmentDropdown} 
                    // style={{ cursor: Object.entries(lesson).length === 0 ? 'not-allowed' : 'auto', opacity: Object.entries(lesson).length === 0 ? '0.5' : '1' }} 
                    >
                        {/* <button className="dropbtn" onClick={toggleAssingmentDropdown} > */}
                        {/* {selectedAssignment} */}
                        Assignment
                        <span>
                            <i className="fa-solid fa-angle-down"></i>
                        </span>
                    </button>

                    {/* {
                        showAssingment && (
                            <div className="dropdown-content">
                                {assignmentsCount.map((assignment, index) => (
                                    <div key={index} className="assignment-item" onClick={() => {
                                        handleAssignmentClick(assignment, index)
                                    }}>
                                        {assignment}
                                    </div>
                                ))}


                                <div id="add-assignment" onClick={() => {
                                    setEachAssignment_id(null)
                                    setassignment_id('')
                                    handleAddAssignment()
                                    const workboardItem = Object.values(workboards).find(item => item.name === workboard.name);
                                    update(workboardItem, workboard.name, 'no_previesAssignment', 'removeWorkboardAssignment');

                                }
                                }>
                                    + Add Assignment
                                </div>
                            </div>
                        )
                    } */}


                </div>
                <div className="tab-dropdown" 
                // ref={dropdownRubricRef}
                >
                    <button className="dropbtn" 
                    // onClick={toggleDropdownRubric} style={{ cursor: Object.entries(lesson).length === 0 ? 'not-allowed' : 'auto', opacity: Object.entries(lesson).length === 0 ? '0.5' : '1' }}
                    >
                        Rubric
                        {/* {selectedRubric} */}
                        <span 
                        // style={{ transform: showDropdownRubric ? 'rotate(180deg)' : 'rotate(0deg)', }}  
                        >
                            <i className="fa-solid fa-angle-down"></i>
                        </span>
                    </button>


                    {/* {showDropdownRubric && (
                        <div className="dropdown-content">
                            {rubricCount.map((rubric, index) => (
                                <div key={index} className="assignment-item" onClick={() => {
                                    handleRubricClick(rubric, index)
                                }
                                }>
                                    {rubric}
                                </div>
                            ))}


                            <div id="add-assignment" onClick={() => {
                                setEachRubric_id(null)
                                setrubric_id('')
                                handleAddRubric()
                                const workboardItem = Object.values(workboards).find(item => item.name === workboard.name);
                                update(workboardItem, workboard.name, 'no_previesAssignment', 'removeWorkboardRubric');
                            }}>
                                + Add Rubric
                            </div>
                        </div>
                    )} */}
                </div>
            </div >
        </>
    );
}

export default LessonGenratorWorkBoardDropdown;


