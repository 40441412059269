import React from 'react'
import { useNavigate } from 'react-router-dom';

function BackButton({ buttonName }) {

    const navigate = useNavigate();

    return (
        <div className='Back_button' onClick={() => navigate(-1)} style={{ cursor: "pointer", display: "flex", alignItems: "center", color: "#0068ff", cursor: "pointer", marginBottom: "26px"}}>
            <i class="fa-solid fa-chevron-left"></i>
            <span style={{ padding: " 0px 5px" }}>{buttonName}
            </span>
        </div>
    )
}

export default BackButton