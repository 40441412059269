import { Dispatch } from "redux";
import api from "../../api/api";
import {
    setPracticeAssignmentsList,
    setloading,
    seterror,
    setPagination,
    resetPracticeAssignmentsList,
} from "../reducers/getPracticeAssignmentsReducer";

const getPracticeAssignments = (id: any, token: any) => async (
    dispatch: Dispatch
) => {
    dispatch(setloading(true));
    try {
        const result = await api.get(`/assignments/get-practice-assignments-by-assignment/${id}/`, {
        // params: {
        //     page: page
        // },
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Token ${token}`,
            },
        });

        if (result) {
            dispatch(setloading(false));
            if (result.data?.status === 200) {
                dispatch(setPracticeAssignmentsList(result.data.data));
                dispatch(setPagination(result.data));
            } else {
                dispatch(seterror(result.data?.message ?? "Something Went Wrong!"));
            }
        }
    } catch (error: any) {
        //dispatch(seterror(error.response.data.message));
    }
};

export { getPracticeAssignments, resetPracticeAssignmentsList };

