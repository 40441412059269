import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserActivePlan {
    isLoading: boolean;
    error: string | null;
    plan: any | null;
}

const initialState: UserActivePlan = {
    plan: null,
    isLoading: false,
    error: null,
};


const userActivePlanSlice = createSlice({
    name: 'userActivePlan',
    initialState,
    reducers: {
        setuserActivePlan: (state: UserActivePlan, action: PayloadAction<UserActivePlan>) => {
            state.plan = action.payload;
            state.isLoading = false;
            state.error = action.payload.error;
        },
        setloading: (state: UserActivePlan, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: UserActivePlan, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetUserActivePlan: () => {
            return initialState;
        },
    },
});

export const { setuserActivePlan, setloading, seterror, resetUserActivePlan } = userActivePlanSlice.actions;

export default userActivePlanSlice.reducer;










































