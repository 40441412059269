import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SuperAdminLogin {
  token: string | null;
  isLoading: boolean;
  error: string | null;
}

const initialState: SuperAdminLogin = {
  token: null,
  isLoading: false,
  error: null,
};

const superadminloginslice = createSlice({
  name: "superAdminLogin",
  initialState,
  reducers: {
    setSuperAdminLogin: (
      state: SuperAdminLogin,
      action: PayloadAction<SuperAdminLogin>
    ) => {
      state.token = action.payload.token;
      state.isLoading = false;
      state.error = null;
    },
    setloading: (state: SuperAdminLogin, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
      state.error = null;
    },
    seterror: (state: SuperAdminLogin, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    resetsuperAdminLogin: () => {
      return initialState;
    },
  },
});

export const {
  setSuperAdminLogin,
  setloading,
  seterror,
  resetsuperAdminLogin,
} = superadminloginslice.actions;

export default superadminloginslice.reducer;
