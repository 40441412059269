import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  search,
  srot
} from "../../../assets/images/images";
// import AdminSortPopup from "../../admin/customAdminSort/AdminSortPopup";
import { ReactSVG } from "react-svg";
import PaginationView from "../../../components/Pagination/PaginationView";
import { useAuth } from "../../../features/auth/AuthContext";
import {
  getAllSchoolList,
  resetSchoolList,
} from "../../../state/actions/getAllSchoolsAction";
import SchoolCard from "./SchoolCard";

const SchoolList = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const { token } = useAuth();
  const dispatch = useDispatch();
  const { schools, count, next, previous, totalPages, error } = useSelector(
    (state) => state.schoolList
  );

  const getAllSchoolsRequest = () => {
    dispatch(getAllSchoolList(currentPage, token));
  };

  useEffect(() => {
    getAllSchoolsRequest();
    window.scrollTo(0, 0);
  }, [currentPage]);

  useEffect(() => {
    return () => {
      dispatch(resetSchoolList());
    };
  }, []);

  return (
    <>
      <div className="School_List">
        <div className="School_List_wrapper">
          <div className="search_header-container">
            <div className="search-list">
              <ReactSVG className="search-icon" src={search} />
              <input
                type="text"
                className="search-input"
                placeholder="Search"
              />
            </div>
            <div className="filter-wrapper">
              <div className="lessonviewprogress-filder-tab">
                <span>
                  <i className="fa-solid fa-sliders"></i>
                </span>
                <p>Filter</p>
              </div>
              <div className="lessonviewprogress-filder-tab">
                <span>
                  {/* <img src={srot}/> */}
                  <i class="fa-solid fa-arrow-down-wide-short"></i>
                </span>
                <p>Sort</p>
              </div>
            </div>
            {/* <AdminSortPopup 
                      isVisible={true} 
                    /> */}
          </div>
          <div className="School_Course_Card_container">
            {schools?.map((data, index) => (
              <SchoolCard key={data.school_id} school={data} />
            ))}
          </div>
        </div>
      </div>
      {totalPages > 1 && (
        <PaginationView
          totalPages={totalPages}
          next={next}
          previous={previous}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
};
export default SchoolList;
