import { useEffect, useState } from "react";

const useWebSocketConnection = (url) => {
    const [socket, setSocket] = useState(null);
  
    useEffect(() => {
      const newSocket = new WebSocket(url);
      setSocket(newSocket);
  
      return () => {
        newSocket.close();
      };
    }, []);
  
    return socket;
  };

  export default useWebSocketConnection;