import React from 'react';
import { ReactSVG } from "react-svg";
import {customize_learing_journey_right_icon} from "../assets/images/images";
import 'animate.css';
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";

const CustomizedLearningCard=({  title, des, data_wow_duration}) => { return( 

    <div key={title} className='customize_learing_journey_right_inner_folder wow animate__fadeInUpBig' data-wow-duration={data_wow_duration} >
        <h2>{title}</h2>
        <p>{des}</p>
        <div className='customize_learing_journey_right_icon'>
            <ReactSVG src={customize_learing_journey_right_icon} />
        </div>
    </div>
)}

export default CustomizedLearningCard;