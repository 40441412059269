import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Delete {
    isLoading: boolean;
    error: string | null;
    message: string | null;
}

const initialState: Delete = {
    message: null,
    isLoading: false,
    error: null,
};

const deleteCourseSlice = createSlice({
    name: 'deleteCourse',
    initialState,
    reducers: {
        setdeleteCourse: (state: Delete, action: PayloadAction<Delete>) => {
            state.message = action.payload.message;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: Delete, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: Delete, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetdeleteCourse: () => {
            return initialState;
        },
    },
});

export const { setdeleteCourse, setloading, seterror, resetdeleteCourse } = deleteCourseSlice.actions;

export default deleteCourseSlice.reducer;
