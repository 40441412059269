import { Dispatch } from "redux";
import api from "../../api/api";
import {setGeneratePracticeAssignment, setloading, seterror, resetGeneratePracticeAssignment} from "../reducers/generatePracticeAssignmentReducer"

const generatePracticeAssignment = (payload: any, token: string) => async (dispatch: Dispatch) => {
    dispatch(setloading(true));
    try {
        const result = await api
            .post(
                `assignments/create-practice-assignment/`,
                payload,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Token ${token}`
                    },
                }
            ) 

        if (result) {
            dispatch(setloading(false));
            if (result.data?.status === 200) {
                dispatch(setGeneratePracticeAssignment(result.data.data));
            }
            else if(result.data?.status === 400){
                dispatch(seterror(result.data?.message));
            }
            else {
                dispatch(seterror(result.data?.message ?? "Something Went Wrong!"));
            }
        }
    }
    catch (error: any) {
        dispatch(seterror(error.response.data.message));
    }
}

export { generatePracticeAssignment, resetGeneratePracticeAssignment }

