import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { useAuth } from "../../features/auth/AuthContext";
import {
  addCourseToCart,
  resetAddToCart,
} from "../../state/actions/addtoCartAction";
import CancelPopup from "../../components/CancelPopup/CancelPopup";
import { Modal } from "react-responsive-modal";

function MarketPlaceCourseCard({
  course_id,
  course_demo_image,
  title,
  rate,
  number,
  Course_name,
  course_title,
  discounted_price,
  price,
  button_name1,
  button_name2,
  ai_avatar,
  course,
  onNavigateAway
}) {
  const navigate = useNavigate();
  const { token } = useAuth();
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const globalState = useSelector((state) => state.globalState);
  const show = () => {
    setOpenDialog(false);
    dispatch(addCourseToCart(course_id, token));
    //navigate(`/cart`);
  };

  const close = () => {
    setOpenDialog(!openDialog); // This will close the popup
  };

  return (
    <>
      <div
        className="studentDashboar-Suggested-Courses-folder"
        id="studentCourseCard-folder"
      >
        <div
          className="studentDashboar-Suggested-Courses-image"
          onClick={() => {
            navigate(`/course/${course_id}/view`, {
              state: { isFreeCourse: false, cart: 'cart', redirectTo: "cart" },
            })            
          }}
        >
          <img src={course_demo_image} alt="" />
        </div>
        <NavLink to={`/teacher/${course?.teacher}`}>
          <div className="studentDashboar-Suggested-Courses-profile-folder">
            <div className="studentDashboar-Suggested-Courses-profile">
              <div className="studentDashboar-profile-image">
                <img src={ai_avatar} alt="" />
              </div>
              <div className="studentDashboar-Suggested-Courses-profile-title">
                <h2 className="tudentDashboar-profile-card-title">{title}</h2>
                <p>Teacher</p>
              </div>
            </div>
            {/* Hidden for subdomain */}
            {number > 0 && (
              <div
                className="studentDashboar-Suggested-Courses-profile-rate"
                onClick={() => navigate(`/ratings/${course_id}/view`)}
              >
                <a>
                  <i className="fa-solid fa-star"></i>
                </a>
                {rate > 0 && <p className="rate">{rate}</p>}
                {number > 0 && <p className="number">({number})</p>}
              </div>
            )}
            {/* Hidden for subdomain */}
          </div>
        </NavLink>

        <p
          className="studentDashboar-profile-course-name"
          onClick={() => {
            navigate(`/course/${course_id}/view`, {
              state: { isFreeCourse: false, cart: 'cart', redirectTo: "cart" },
            });
            
          }}
        >
          {Course_name}
        </p>
        <h2
          className="studentDashboar-profile-course-title"
          onClick={() => {
       
            navigate(`/course/${course_id}/view`, {
              state: { isFreeCourse: false, cart: 'cart', redirectTo: "cart" },
            });
           
          }}
        >
          {course_title}
        </h2>
        {/* <div className='discount-price-folder'> */}
        {price === null ? (
          <span className="discount-big-price">$0</span>
        ) : (
          <div className="discount-price-folder">
            <span className="discount-big-price">{`$${discounted_price}`}</span>
            {discounted_price != price && (
              <span className="discount-price">{`$${price}`}</span>
            )}
          </div>
        )}

        {/* </div> */}
        <div className="studentDashboar-profile-btn-folder">
          {price !== null ? (
            <>
              <button
                type="button"
                onClick={() =>
                  navigate(`/course/${course_id}/view`, {
                    state: { isFreeCourse: false, cart: 'cart', redirectTo: "cart" },
                  })
                }
              >
                {button_name1}
              </button>
              <button
                type="button"
                className="Preview-lesson-btn"
                onClick={() => close()}
              >
                {button_name2}
              </button>
            </>
          ) : (
            <button
              type="button"
              onClick={() =>
                navigate(`/course/${course_id}/view`, {
                  state: { isFreeCourse: false, cart: 'cart', redirectTo: "cart" },
                })
              }
            >
              {button_name1}
            </button>
          )}
          {/* <button type="button" onClick={() => navigate(`/course/${course_id}/view`)}>
                        {button_name1}
                    </button>
                    <button type="button" className="Preview-lesson-btn" onClick={() => close()}  >
                        {button_name2}
                    </button> */}
        </div>
        {openDialog && (
          <Modal
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            center
            showCloseIcon={false}
            classNames={{
              modal: "Cancel-Popup",
            }}
          >
            <CancelPopup
              title="Are you sure you want to add this course to cart?"
              No="No"
              Yes="Yes"
              show={show} // Assuming you have a state or value to pass
              close={close} // Pass the reference, not the invocation
            />
          </Modal>
        )}
      </div>
    </>
  );
}

export default MarketPlaceCourseCard;
