import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function DropdownMenu({ menuItems, dropdownValue, setdropdownValue }) {
  //const [selectedItem, setSelectedItem] = React.useState(menuItems.length > 0 ? menuItems[0].selectMenu : '');

  const handleChange = (event) => {
    setdropdownValue(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 120,minHeight: 20 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label"></InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={dropdownValue}
          onChange={handleChange}
          sx={{
            height: '25px', // Set the height you want here
            borderRadius: "30px",   
            backgroundColor: "#F3D3FF",
            color: "#AA00E8",
            border: "none",
            fontSize: "14px"
          }}
        >
          {menuItems?.map((item, index) => (
            <MenuItem key={index} value={item?.value}>
              {item?.selectMenu}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
