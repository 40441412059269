import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UpdateCourse {
    course: any
    isLoading: boolean;
    error: string | null;
    message: string | null;
}

const initialState: UpdateCourse = {
    course: {},
    message: null,
    isLoading: false,
    error: null,
};

const updateCourseSlice = createSlice({
    name: 'updateCourse',
    initialState,
    reducers: {
        setupdateCourse: (state: UpdateCourse, action: PayloadAction<UpdateCourse>) => {
            state.course = action.payload;
            state.message = "Update Successfull";
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: UpdateCourse, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: UpdateCourse, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetupdateCourse: () => {
            return initialState;
        },
    },
});

export const { setupdateCourse, setloading, seterror, resetupdateCourse } = updateCourseSlice.actions;

export default updateCourseSlice.reducer;
