import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Average_Animation_gift, heigh_Animation_gift, low_Animation_gift } from "../../../../../../assets/images/images";
import PracticeAssignmentPopup from "./PracticeAssignmentPopup/PracticeAssignmentPopup";
import { Tooltip } from "react-tooltip";


const StudentAssignmentScore = ({ 
  showScore,
  getSubmissiondtls,
  onclick,
  title,
  isCurrentAssignment,
  onclick2,
  title2,
  title3,
  assignment
}) => {

  const [showPopup, setShowPopup] = useState(false);
  const globalState = useSelector((state) => state.globalState);
  const practice_assignment_status = globalState.userCurrentPlan?.currentSubscription && globalState.userCurrentPlan.currentSubscription.plan_data.practice_assignment
  // console.log("globalState 1", globalState.userCurrentPlan.currentSubscription.plan_data.practice_assignment);

  const handlePracticeAssignmentClick = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const calculatePercentage = (marksObtained, totalMarks) => {
    if (totalMarks === 0 || totalMarks === null || marksObtained === null) {
      return 0;
    }
    return ((marksObtained / totalMarks) * 100).toFixed(2);
  };

  // const percentage = 

  return (
    <div className="AssingmentMCQs-Congratulations-popup-wrapper">
      <div className="AssingmentMCQs-Congratulations-popup-container">
        {/* {!isCurrentAssignment ? (
          <i
            class="fa-solid fa-x"
            id="AssingmentMCQs-close-btn"
            onClick={showScore}
          ></i>
        ) : (
          <></>
        )} */}
        <div className="AssingmentMCQs-Congratulations-popup-image">
          {/* <img src={Animation_gift} alt="Description of GIF" /> */}
          {calculatePercentage(getSubmissiondtls?.marks_obtained, getSubmissiondtls?.total_marks) > 79.99 ? (
            <img src={heigh_Animation_gift} alt="Description of GIF" />
          ) : calculatePercentage(getSubmissiondtls?.marks_obtained, getSubmissiondtls?.total_marks) > 59.99 ?
           (<img src={Average_Animation_gift} alt="Description of GIF" />) : (<img src={low_Animation_gift} alt="Description of GIF" />)}
          
          
          
        </div>
        <span className="your-score-title">Your score</span>
        <div className="your-score-folder">
          <span className="your-score-check">
            <i class="fa-solid fa-circle-check"></i>
          </span>
          <div className="your-score">
            <span>{getSubmissiondtls?.marks_obtained ?? 0}</span>
            <span>/{getSubmissiondtls?.total_marks ?? 0}</span>
          </div>
        </div>
        {/* <p>
          <PreprocessAndRenderLatex
            content={getSubmissiondtls?.feedback.replace(/(?<!\$)\$(?!\$)(.*?)\$+(?!\$)/g, (match, p1) => `$$${p1}$$`)}
            isBlockMath={false}
          />
        </p> */}
        {/* <p>Your Assignment has been submitted!</p> */}
        <div className="Congratulations-assignment-btns">
          <button onClick={onclick} type="button" className="View-Course">
            {title}
          </button>
          {isCurrentAssignment && (
            <button onClick={onclick2} type="button" className="view-result">
              {title2}
            </button>
          )}
          {!globalState.subdomain_exists && <div className="practice-assignment">
            {/* <button
              onClick={handlePracticeAssignmentClick}
              type="button"
              className="practice-assignment-btn"
            >
              {title3} $1
              {/* <span>$1</span> */}
            {/* </button>  */}
            <button
                onClick={handlePracticeAssignmentClick}
                type="button"
                className="practice-assignment-btn"
                disabled={!practice_assignment_status}
                data-tooltip-id={!practice_assignment_status ? "show-message" : ""}
                style={{
                  backgroundColor: !practice_assignment_status ? "gray" : "",
                  color: !practice_assignment_status ? "white" : "",
                  border: !practice_assignment_status ? "none" : "",
                  cursor: !practice_assignment_status ? "not-allowed" : "",
                }}
              >
                {title3} $1
              </button>
              <Tooltip
                        id="show-message"
                        place="bottom"
                        content="Upgrade Subscription Plan"
                        type="light"
                        effect="float"
                    />
          </div>}
        </div>
      </div>
      {showPopup && <PracticeAssignmentPopup assignment={assignment} onClose={handleClosePopup} />}
    </div>
  );
};

export default StudentAssignmentScore;
