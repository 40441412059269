// import { gapi } from 'gapi-script';
import {
  Box,
  FormControl,
  FormHelperText,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Waveform } from "@uiball/loaders";
import { format } from "date-fns";
import { marked } from "marked";
import React, { useEffect, useRef, useState, useCallback } from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api, { liveBaseURL, wsURL } from "../../../../../../api/api";
import ass_img from "../../../../../../assets/images/ass-img.png";
import Calimg from "../../../../../../assets/images/calendar.png";
import doc_img from "../../../../../../assets/images/doc-logo.png";
import go_btn from "../../../../../../assets/images/go-btn-img.png";
import go_btn2 from "../../../../../../assets/images/send 2.png";
import { Course_created_successfully_image } from "../../../../../../assets/images/images";
import jpg_img from "../../../../../../assets/images/jpg-logo.png";
import pdf_img from "../../../../../../assets/images/pdf-img.jpg";
import png_img from "../../../../../../assets/images/png-logo.png";
import xlsx_img from "../../../../../../assets/images/xlsx-logo.png";
import { useAuth } from "../../../../../../features/auth/AuthContext";
import CommonTextField from "../../../../../../utils/CommonTextField";
import LoaderComponent from "../../../../../../components/LoaderComponent/LoaderComponent";
import {
  checkifDateisAfterToday,
  isRequired, parseKaTeX
} from "../../../../../../utils/validator";
import "../../../../styles/dashboard.css";
import "../../../../styles/lesson.css";
import "../../../../styles/responsive_lesson.css";
import GoogleDocFile from "./google_docs";
// import MyEditor from './MyEditor';
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { $generateHtmlFromNodes, $generateNodesFromDOM } from "@lexical/html";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { TRANSFORMERS } from "@lexical/markdown";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  $insertNodes,
} from "lexical";


import { $getSelection, $isRangeSelection } from "lexical";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import AOS from "aos";
import "aos/dist/aos.css";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import { updateGlobalState } from "../../../../../../state/global/slice.tsx";
import { AssignmentType } from "../../../../../../utils/consts.js";
import "../../../editor/css/styles.css";
import AutoLinkPlugin from "../../../editor/plugins/AutoLinkPlugin";
import CodeHighlightPlugin from "../../../editor/plugins/CodeHighlightPlugin";
import HtmlPlugin from "../../../editor/plugins/HtmlPlugin.tsx";
import ListMaxIndentLevelPlugin from "../../../editor/plugins/ListMaxIndentLevelPlugin";
import ToolbarPlugin from "../../../editor/plugins/ToolbarPlugin";
import ExampleTheme from "../../../editor/themes/ExampleTheme";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import AWSS3FileDelete from "../../../../../../components/AWSFileUpload/AWSS3FileDelete.jsx";
import AWSS3FileUpload from "../../../../../../components/AWSFileUpload/AWSS3FileUpload.jsx";
import BackButton from "../../../../../../components/BackButton/BackButton.js";
import PreprocessAndRenderLatex from "../../../../../../components/ProcessRenderLatex";
import UniversalChatbot from "../../../../../../components/universalChatbot/UniversalChatbot.js";
import { binaryStringToArrayBuffer } from "../../../../../../utils/utlis";
import Dictaphone from "../../../../../speechtotext/SpeechtoText.jsx";
import SpeechGenerator from "../../../../../texttospeech/TexttoSpeech.jsx";
import MathComponent from './MathComponent';
import "../../../../../speechtotext/SpeechtoText.css";
// import utc from "dayjs/plugin/utc";
import { getTextToSpeech, resetTextToSpeech } from "../../../../../../state/actions/getTextToSpeechAction";
import { data, error } from "jquery";
import { red } from "@mui/material/colors";
import { arrayIncludes } from "@mui/x-date-pickers/internals/utils/utils";
import { createNonNullChain } from "typescript";

const NewLessons = () => {
  const { token } = useAuth();
  const [convertedText, setConvertedText] = useState("");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const [editloading, setEditLoading] = useState(false);
  const [checkAssignmentopen, setCheckAssignmentOpen] = useState(false);

  const location = useLocation();
  const [openStudentView, setopenStudentView] = useState(false);
  const dispatch = useDispatch();
  const globalState = useSelector((state) => state.globalState);
  const teacherCurrentPlan = useSelector((state) => state.teacherCurrentPlan);
  const updateState = (key, value) => {
    dispatch(updateGlobalState({ key: key, value: value }));
  };
  const texttospeech = useSelector((state) => state.texttospeech);
  const lastTranscriptRef = useRef('');
  // New state variable for storing chat history
  const [rubrics, setRubrics] = useState({});
  const course = location.state?.course || {}; // default to Student if no role is passed
  const teacher_id =
    location.state?.teacher_id ??
    globalState.teacher_details?.data?.teacher_id ??
    "";
  // const lesson = location.state?.lesson || {};
  const [lesson, setLesson] = useState(location.state?.lesson || {});
  const [isCopied, setIsCopied] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedFileLink, setuploadedFileLink] = useState([]);
  const [progressTracker, setprogressTracker] = useState([]);
  const fileInputRef = useRef(null);
  const [assignment_id, setassignment_id] = useState("");
  const [eachAssignment_id, setEachAssignment_id] = useState(null);
  const [eachRubric_id, setEachRubric_id] = useState(null);
  const [showCloseBTN, setShowCloseBTN] = useState(false);


  const {
    isListening,
    transcript,
    startListening,
    stopListening
  } = Dictaphone({ continuous: true, interimResults: true, lang: 'en-US', });
  const handleIconClick = () => {
    fileInputRef.current.click();
  };
  const handleFileChange = (e) => {
    const updatedFiles = Array.from(e.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...updatedFiles]);
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);

    // Reset the value of the file input element
    // fileInputRef.current.value = "";
  };
  const textBoxRef = useRef(null);

  const getFileIcon = (fileName) => {
    const extension = fileName
      .split(".")
      .pop()
      .toLowerCase();
    switch (extension) {
      case "pdf":
        return pdf_img;
      case "doc":
        return doc_img;
      case "docx":
        return doc_img;
      case "xls":
        return xlsx_img;
      case "xlsx":
        return xlsx_img;
      case "jpg":
        return jpg_img;
      case "jpeg":
        return jpg_img;
      case "png":
        return png_img;
      default:
        return pdf_img; // Default icon if the file type is not recognized
    }
  };

  const getFileType = (fileName) => {
    const extension = fileName
      .split(".")
      .pop()
      .toLowerCase();
    switch (extension) {
      case "pdf":
        return "PDF";
      case "doc":
        return "Word";
      case "docx":
        return "Word";
      case "xls":
        return "Excel";
      case "xlsx":
        return "Excel";
      case "jpg":
        return "Image";
      case "jpeg":
        return "Image";
      case "png":
        return "Image";
      default:
        return "PDF"; // Default file type if not recognized
    }
  };
  // let new_lession;
  // try {

  // }
  // const [chatHist, setChatHist] = useState([...new_lession?.chat_hist ?? []]);
  const [chatHist, setChatHist] = useState([]);
  const [isNotSavePopUp, setIsNotSavePopUp] = useState(false);
  const [callAddAssignmentFun, setCallAddAssignmentFun] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [isAssignment, setIsAssignment] = useState(false);

  const [isRubric, setIsRubric] = useState(false);
  const [activeTab, setActiveTab] = useState("lesson");
  const [room_id, setRoom_id] = useState("");
  const [defaultWorkboardData, setDefaultWorkboardData] = useState({
    LESSON: {
      name: "LESSON",
      isChanged: false,
      data: "",
    },
    ASSIGNMENT: {
      name: "ASSIGNMENT",
      isChanged: false,
      data: "",
    },
    RUBRIC: {
      name: "RUBRIC",
      isChanged: false,
      data: "",
    },
  });

  const updateDefaultWorkboardData = (name, data) => {
    setDefaultWorkboardData((prev) => ({
      ...prev,
      [name]: {
        ...prev[name],
        data,
      },
    }));
  };

  // const getQueryParam = (param) => {
  //   const searchParams = new URLSearchParams(location.search);
  //   return searchParams.get(param);
  // }

  // useEffect(() => {
  //   // Get the input value from the query parameter on component mount
  //   // const savedInput = getQueryParam('input') || '';
  //   const savedInputName = getQueryParam('firstName') || '';
  //   const savedInputData = getQueryParam('lastName') || '';
  //   setInputValue(savedInput);
  // }, [location.search]);

  const [formState, setFormState] = useState(() => {
    // Attempt to load existing form data from local storage
    const savedFormData = localStorage.getItem("lesson_formState");
    var _savedFormData = {};
    if (savedFormData) {
      // const savedWorkboard = localStorage.getItem("workborad_details");
      // const savedisAssignment = localStorage.getItem("isAssignment_set");
      // const savedisRubric = localStorage.getItem("isRubric_set");
      // setIsRubric(savedisRubric);
      // setIsAssignment(savedisAssignment);
      // updateDefaultWorkboardData(
      //   isAssignment
      //     ? defaultWorkboardData.ASSIGNMENT.name
      //     : isRubric
      //     ? defaultWorkboardData.RUBRIC.name
      //     : defaultWorkboardData.LESSON.name,
      //   savedWorkboard
      // );
      //  localStorage.setItem("workborad_details", JSON.stringify(isAssignment
      //   ?  workboards.ASSIGNMENT.data
      //   : isRubric
      //   ?  workboards.RUBRIC.data : workboards.LESSON.data));
      // localStorage.setItem("isAssignment_set", JSON.stringify(isAssignment));
      // localStorage.setItem("isRubric_set", JSON.stringify(isRubric));
      _savedFormData = JSON.parse(savedFormData);
      // Assuming _savedFormData.subject_names is a string like "english,history,science"
      const roomid_saved = localStorage.getItem("roomId");
      if (roomid_saved) {
        setRoom_id(roomid_saved);
      }
    }

    return {
      ...(_savedFormData.start_date
        ? _savedFormData
        : {
          name: lesson?.name ?? null,
          assignment_no: null,
          // start_date: lesson?.start_date ?? null,
          start_date: lesson?.start_date ?? dayjs().format("YYYY-MM-DD"),
          end_date: lesson?.end_date ?? null,
          description: lesson?.description ?? null,
          chat_query: null,
          chat_hist: null,
        }),
    };
  });





  // const [formState, setFormState] = useState({
  //   name: lesson?.name ?? null,
  //   assignment_no: null,
  //   start_date: lesson?.start_date ?? null,
  //   end_date: lesson?.end_date ?? null,
  //   description: lesson?.description ?? null,
  //   chat_query: null,
  //   chat_hist: null,
  // });
  const [formLessonError, setFormLessonErrorState] = useState({
    name: "",
    start_date: "",
    end_date: "",
  });
  // console.log('lesson?.is_personalize : '+ lesson?.is_personalize);
  //   // get the assignment type value from the assignment type
  //   const getAssignmentTypeNameList = () => {
  //     return Object.values(AssignmentType).map((item) => {
  //       return item.name;
  //     });
  //   };
  //   // get the assignment type value from the assignment type
  //   const getAssignmentTypeValueList = () => {
  //     return Object.values(AssignmentType).map((item) => {
  //       return item.value;
  //     });
  //   };
  const getAssignmentTypeValue = (type) => {
    return Object.values(AssignmentType).find((item) => {
      return item.name === type;
    })?.value;
  };
  const getAssignmentTypeName = (type) => {
    return Object.values(AssignmentType).find((item) => {
      return item.value === type;
    })?.name;
  };

  const [formAssignmentState, setFormAssignmentState] = useState({
    name: null,
    assignment: null,
    assignment_json: null,
    lesson: null,
    duration_minutes: null,
    start_date: dayjs().format("YYYY-MM-DD"),
    end_date: null,
    type: null,
  });





  const [formErrorAssignmentState, setFormErrorAssignmentState] = useState({
    name: "",
    assignment: "",
    lesson: "",
    duration_minutes: "",
    start_date: "",
    end_date: "",
    type: "",
  });



  const [voices, setVoices] = useState([]);
  // console.log(voices);
  useEffect(() => {
    const voice = speechSynthesis.getVoices();
    if (Array.isArray(voice) && voice.length > 0) {
      setVoices(voice);
      return;
    }
    if ('onvoiceschanged' in speechSynthesis) {
      speechSynthesis.onvoiceschanged = function () {
        const voice = speechSynthesis.getVoices();
        setVoices(voice);
      };
    }
    getAssignment(lesson?.lesson_id);
    // console.log(voices);
  }, []);


  const handleAssignmentInputChange = (event) => {
    const { name, value } = event.target;
    let errors = {};
    if (name === "name") {
      errors.name = isRequired(value);
    }
    if (name === "assignment") {
      errors.assignment = isRequired(value);
    }
    if (name === "duration_minutes") {
      errors.duration_minutes = isRequired(value);
    }
    if (name === "start_date") {
      errors.start_date = isRequired(value);
    }
    if (name === "end_date") {
      errors.end_date = isRequired(value);
    }
    if (name === "type") {
      errors.type = isRequired(value);
    }
    setFormErrorAssignmentState(errors);

    setFormAssignmentState((prev) => ({ ...prev, [name]: value }));
  };

  const handleAssignmentInputChangedate = (name, date) => {
    let errors = {};
    if (name === "start_date") {
      errors.start_date = isRequired(format(new Date(date), "yyyy-MM-dd"));
    }
    // if (name === "end_date") {
    //   errors.end_date = isRequired(format(new Date(date), "yyyy-MM-dd"));
    // }
    setFormErrorAssignmentState(errors);
    setFormAssignmentState((prev) => ({
      ...prev,
      [name]: format(new Date(date), "yyyy-MM-dd"), // Use the provided name
    }));

  };

  const currentDate = dayjs();
  const startDate = dayjs(formState.start_date);

  const minDate = currentDate.isAfter(startDate)
    ? currentDate.format("MMM DD, YYYY")
    : startDate.format("MMM DD, YYYY");

  const courseStartDate = dayjs(course?.start_date);

  const lessonMinDate = currentDate.isAfter(courseStartDate)
    ? currentDate.format("MMM DD, YYYY")
    : courseStartDate.format("MMM DD, YYYY");

  const isValidDateAssignmentStartDate = (currentDate, selectedDate) => {
    return currentDate.isSameOrAfter(minDate, "day");
  };
  const isValidDateAssignmentEndDate = (currentDate, selectedDate) => {
    // Choose the correct start date based on whether formAssignmentState?.start_date is defined
    const startDate = moment(
      formAssignmentState?.start_date
        ? formAssignmentState.start_date
        : formState.start_date
    ).add(1, "days");

    // Disable dates that are before the start date (making only dates after start date selectable)
    return !currentDate.isBefore(startDate);
  };

  const isValidDateLessonStartDate = (currentDate) => {
    const endDate = course?.end_date ? moment(course?.end_date) : null;
    const lessonendDate = formState?.end_date
      ? moment(formState?.end_date)
      : null;
    const isAfterMinDate = currentDate.isSameOrAfter(lessonMinDate, "day");
    const isBeforeEndDate = lessonendDate
      ? lessonendDate
        ? currentDate.isSameOrBefore(lessonendDate, "day")
        : true
      : endDate
        ? currentDate.isSameOrBefore(endDate, "day")
        : true;
    return isAfterMinDate && isBeforeEndDate;
  };
  const isValidDateLessonEndDate = (currentDate, selectedDate) => {
    const startDate = moment(course?.start_date);
    const endDate = course?.end_date ? moment(course?.end_date) : null;
    const startDateLesson = moment(formState?.start_date ?? "");
    const today = moment(); // Get the current date
    // Check if endDate is provided and if it's not expired
    if (endDate && endDate.isBefore(today)) {
      return false; // endDate is expired, return false
    }

    // Disable dates between start and end dates, inclusive
    return endDate
      ? !currentDate.isBefore(startDate) &&
      !currentDate.isAfter(endDate) &&
      currentDate.isAfter(startDateLesson)
      : !currentDate.isBefore(startDate) &&
      currentDate.isAfter(startDateLesson);
  };
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const messagesEndRef = useRef(null);
  // Load form data from localStorage when the component mounts

  const createChatRoom = async (user_id) => {
    const payload = {
      user_id: user_id,
      against_user_id: user_id,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    try {
      const response = await api.post(
        `ai/api/lesson_create_chat_room/`,
        payload,
        headers
      );
      if (response.status === 200 || response.status === 201) {
        setRoom_id(response?.data?.room_id);
        localStorage.setItem("roomId", response?.data?.room_id);
        return response?.data?.room_id;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  };
  const getChatHistorybyRoomid = (roomid) => {
    const payload = {
      room_id: roomid,
    };
    const url = `ai/api/get-chat-history-by-room-id/`;
    callchathistfunction(url, payload);
  };
  const getChatHistory = (lessonId) => {
    const payload = {
      lesson_id: lessonId,
    };
    const url = `ai/api/lesson_chat_history/`;
    callchathistfunction(url, payload);
  };

  const callchathistfunction = async (url, payload) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    try {
      const response = await api.post(url, payload, headers);

      if (response.status === 200 || response.status === 201) {
        const getPastHistory = response?.data?.data?.histories?.map((item) => {
          return {
            uuid: item?.uuid,
            query: item?.query,
            answer: marked(item?.answer?.replace(/(\\\()(.*?)(\\\))/g, (match, p1, p2, p3) => `$$${p1}${p2}${p3}$$`).replace(/\\_/, '\_')),
            file: item?.file_uploads,
          };
        });
        if (payload?.lesson_id) {
          if (response?.data?.data?.room_id) {
            setRoom_id(response?.data?.data?.room_id);
            localStorage.setItem("roomId", response?.data?.data?.room_id);
          } else {
            getUserId().then((user_id) => {
              if (user_id !== null) {
                createChatRoom(user_id).then((roomId) => {
                  if (roomId !== "") {
                    setRoom_id(roomId);
                    localStorage.setItem("roomId", roomId);
                  }
                });
              }
            });
          }
        }

        setChatHist(getPastHistory);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getUserId = async () => {
    try {
      const response = await api.get(`auth/get-user-id/`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      });
      if (response.status === 200 || response.status === 201) {
        updateState("user_id", response?.data?.user_id);
        return response?.data?.user_id;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  };
  const sendFiles = async () => {
    //const files = await Promise.all([...selectedFiles].map(file => readFileAsBase64(file)));

    const files = await Promise.all(
      [...uploadedFileLink].map((file) => ({
        original_file_name: file.name,
        link: file.location,
        file_id: file.file_id,
      }))
    );

    const payload = {
      room_id,
      lesson_name: formState.name,
      course_id: course?.course_id,
      query: formState.chat_query,
      files,
    };
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${token}`,
    };
    try {
      const response = await api.post(`ai/api/upload/`, payload, headers);
      if (response.status === 200 || response.status === 201) {
        // fileInputRef.current.value = "";
      }
    } catch (error) {
      setSelectedFiles([]);
      // fileInputRef.current.value = "";
    }
  };
  const [assignments, setAssignments] = useState([]);

  const getRubric = async (lesson_id) => {
    await api
      .get(`assignments/get-rubric-by-lesson-id/${lesson_id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const get_rubics = response?.data?.data;
          setRubrics(get_rubics);
          const rubricLog = get_rubics?.map((item) => item?.rubric).join("  ");
          updateWorkboardDataByName(workboards.RUBRIC.name, rubricLog);
          const getid = get_rubics?.length > 0 ? get_rubics[0]?.rubric_id : "";
          setrubric_id(getid);

          const rubricIds = get_rubics?.map(item => item.rubric_id) || [];
          localStorage.setItem('Rubric_id', JSON.stringify(rubricIds));

          const newArray = get_rubics.map(item => {
            return {
              data: item.rubric,
              Id: item.rubric_id
            };
          });

          const globleSaveData = JSON.parse(localStorage.getItem('GlobleSaveData'));
          const globleOnChange = JSON.parse(localStorage.getItem("globleOnChangeData"));

          globleSaveData.RUBRIC.data = newArray
          globleOnChange.RUBRIC.data = newArray

          setGlobleOnChange(globleOnChange)
          setGlobleSaveData(globleSaveData)

          localStorage.setItem("GlobleSaveData", JSON.stringify(globleSaveData));
          localStorage.setItem("globleOnChangeData", JSON.stringify(globleOnChange));

        }
      })
      .catch((error) => { });
  };

  // const getLeson = async (lesson_id) => {
  //   await api
  //     .get(`/lessons/get-lesson/${lesson_id}/`, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Token ${token}`,
  //       },
  //     })
  //     .then((response) => {
  //       console.warn(response);
  //       // if (response.status === 200) {
  //       //   const get_rubics = response?.data?.data;
  //       //   setRubrics(get_rubics);
  //       //   const rubricLog = get_rubics?.map((item) => item?.rubric).join("  ");
  //       //   updateWorkboardDataByName(workboards.RUBRIC.name, rubricLog);
  //       //   const getid = get_rubics?.length > 0 ? get_rubics[0]?.rubric_id : "";
  //       //   setrubric_id(getid);

  //       //   const rubricIds = get_rubics?.map(item => item.rubric_id) || [];
  //       //   localStorage.setItem('Rubric_id', JSON.stringify(rubricIds));

  //       //   const newArray = get_rubics.map(item => {
  //       //     return {
  //       //       data: item.rubric,
  //       //       Id: item.rubric_id
  //       //     };
  //       //   });

  //       //   const globleSaveData = JSON.parse(localStorage.getItem('GlobleSaveData'));
  //       //   const globleOnChange = JSON.parse(localStorage.getItem("globleOnChangeData"));

  //       //   globleSaveData.RUBRIC.data = newArray
  //       //   globleOnChange.RUBRIC.data = newArray


  //       //   setGlobleOnChange(globleOnChange)
  //       //   setGlobleSaveData(globleSaveData)

  //       //   localStorage.setItem("GlobleSaveData", JSON.stringify(globleSaveData));
  //       //   localStorage.setItem("globleOnChangeData", JSON.stringify(globleOnChange));

  //       // }
  //     })
  //     .catch((error) => { });
  // };


  const [getassignmentdetails, setGetAssignmentdetails] = useState([]);

  const getAssignment = async (lesson_id) => {
    await api
      .get(`assignments/get-assignments-by-lesson/${lesson_id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setGetAssignmentdetails(response?.data?.data);
          const get_assignment = response?.data?.data;
          const assignmentsLog = get_assignment
            ?.map((item) => item?.assignment)
            .join("  ");
          updateWorkboardDataByName(workboards.ASSIGNMENT.name, assignmentsLog);
          const getid =
            get_assignment?.length > 0 ? get_assignment[0]?.assignment_id : "";

          if (get_assignment?.length > 0) {
            // setCheckAssignmentOpen(true);
          }

          setassignment_id(getid);

          const assignmentIds = get_assignment?.map(item => item.assignment_id) || [];
          localStorage.setItem('Assingment_Id', JSON.stringify(assignmentIds));

          const assignmentdatadetails = get_assignment?.map(item => item.assignment) || [];

          let getNewArray = []
          get_assignment.map(item => {

            getNewArray.push(item.assignment)
          });

          const newArray = get_assignment.map(item => {
            return {
              data: item.assignment,
              Id: item.assignment_id
            };
          })
          const newFormAssignmentArrya = get_assignment.map(item => {
            return {
              name: item.name,
              assignment: null,
              assignment_json: null,
              lesson: null,
              duration_minutes: item.duration_minutes,
              start_date: item.start_date,
              end_date: item.end_date,
              type: item.type
            };
          });


          const globleSaveData = JSON.parse(localStorage.getItem('GlobleSaveData'));
          const globleOnChange = JSON.parse(localStorage.getItem("globleOnChangeData"));

          globleSaveData.ASSIGNMENT.data = newArray
          globleSaveData.ASSIGNMENT.formSaveAssignment = newFormAssignmentArrya
          globleOnChange.ASSIGNMENT.data = getNewArray
          globleOnChange.ASSIGNMENT.formAssignment = newFormAssignmentArrya
          // globleOnChange.ASSIGNMENT.formAssignment = newFormAssignmentArrya

          setGlobleOnChange(globleOnChange)
          setGlobleSaveData(globleSaveData)

          localStorage.setItem("GlobleSaveData", JSON.stringify(globleSaveData));
          localStorage.setItem("globleOnChangeData", JSON.stringify(globleOnChange));

        }
      })
      .catch((error) => { });
  };
  useEffect(() => {
    if (
      typeof lesson?.lesson_id === "undefined" ||
      lesson?.lesson_id === null ||
      lesson.lesson_id === ""
    ) {
      const roomid_saved = localStorage.getItem("roomId");
      if (roomid_saved) {
        getChatHistorybyRoomid(roomid_saved);
      }
      getUserId().then((user_id) => {
        if (user_id !== null && roomid_saved == null) {
          createChatRoom(user_id).then((roomId) => {
            if (roomId !== "") {
              setRoom_id(roomId);
            }
          });
        }
      });
    } else {
      localStorage.setItem("GlobleSaveData", JSON.stringify(globleSaveData));
      localStorage.setItem("globleOnChangeData", JSON.stringify(globleOnChange));
      getChatHistory(lesson?.lesson_id);
      getAssignment(lesson?.lesson_id);
      getRubric(lesson?.lesson_id);
      // getLeson(lesson?.lesson_id);
    }
  }, []);

  // New WebSocket URL
  // Declare socket using the useState hook
  const [getSocket, setSocket] = useState(null);

  // const onArrowRightClicked = (chat) => {
  //   const { answer } = chat;
  //   console.log("answer", answer);
  //   setFormState((prev) => ({ ...prev, description: answer }));
  // };
  const renderPlainHtml = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    return doc.body.textContent || "";
  };




  const onCopyClicked = (chat) => {
    // Set isCopied to true
    setIsCopied(true);

    // After 2 seconds, reset isCopied to false
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);

    const { answer } = chat;

    // Create a new textarea element to hold the text you want to copy
    const textArea = document.createElement("textarea");
    textArea.value = marked(answer);

    // Append the textarea to the document
    document.body.appendChild(textArea);

    // Select the text within the textarea
    textArea.select();

    try {
      // Check if the Clipboard API is available
      if (navigator.clipboard && window.isSecureContext) {
        // Use the Clipboard API to copy the selected text to the clipboard
        navigator.clipboard
          .writeText(textArea.value)
          .then(() => {
            toast.success("Text copied to clipboard", {
              position: toast.POSITION.TOP_CENTER,
            });
            setIsCopied(true);
          })
          .catch((error) => {
            toast.error("Failed to copy text", {
              position: toast.POSITION.TOP_CENTER,
            });
          });
      } else {
        // Fallback method for non-SSL environments
        document.execCommand("copy");
        toast.success("Text copied to clipboard", {
          position: toast.POSITION.TOP_CENTER,
        });
        setIsCopied(true);
      }
    } catch (error) {
      toast.error("Failed to copy text", {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      // Remove the textarea element
      document.body.removeChild(textArea);
    }
  };

  const [shouldReconnect, setShouldReconnect] = useState(true);
  const [reconnectAttempts, setReconnectAttempts] = useState(0);
  let combinedMessage = [];
  // Create the WebSocket connection when the component mounts

  // const initializeWebSocket = () => {
  //   if (!room_id) return; // Ensure room_id is available

  //   const newWebSocketURL = `${wsURL}ws/lesson/${room_id}/?course_id=${course?.course_id}&teacher_id=${teacher_id}`;
  //   const socket = new WebSocket(newWebSocketURL);

  //   socket.onopen = () => {
  //     console.log("WebSocket connection opened");
  //     setReconnectAttempts(0); // Reset reconnect attempts on successful connection
  //   };

  //   socket.onmessage = (event) => {
  //     try {
  //       const data = JSON.parse(event.data);

  //       if (data && data.message) {
  //         const message = data.message;

  //         combinedMessage.push(message);
  //         let status = false;
  //         let chatdatares = combinedMessage.join("");

  //         if (
  //           chatdatares.includes("<STARTOFTURN>") ||
  //           chatdatares.includes("<ENDOFTURN>")
  //         ) {
  //           chatdatares = chatdatares
  //             .replace(/<STARTOFTURN>/g, "")
  //             .replace(/<ENDOFTURN>/g, "");

  //           if (combinedMessage.join("").includes("<ENDOFTURN>")) {
  //             status = true;
  //             combinedMessage.length = 0;
  //             combinedMessage.push(chatdatares);
  //           }
  //         }

  //         setChatHist((prevChatHist) => {
  //           const updatedChatHist = Array.isArray(prevChatHist)
  //             ? [...prevChatHist]
  //             : [];
  //           if (updatedChatHist.length > 0) {
  //             const lastQuery = updatedChatHist[updatedChatHist.length - 1];
  //             if (lastQuery) {
  //               lastQuery.answer = marked(chatdatares);
  //             }
  //           }
  //           if (status) {
  //             combinedMessage.length = 0;
  //           }
  //           return updatedChatHist;
  //         });
  //         scrollToBottom();
  //       } else {
  //       }
  //     } catch (error) {}

  //     // Handle the received message as needed
  //   };

  //   // socket.onclose = (event) => {
  //   //   console.log("WebSocket closed. Attempting to reconnect...");
  //   //   if (shouldReconnect) {
  //   //     const delay = Math.min(10000, 500 * (2 ** reconnectAttempts)); // Exponential backoff
  //   //     setTimeout(() => {
  //   //       setReconnectAttempts(reconnectAttempts + 1);
  //   //       initializeWebSocket(); // Attempt to reconnect
  //   //     }, delay);
  //   //   }
  //   // };
  //   socket.onclose = (event) => {
  //     console.log("WebSocket closed with code:", event.code, "reason:", event.reason, "Attempting to reconnect...");
  //     initiateReconnection();
  //   };
  //   socket.onerror = (error) => {
  //     console.error("WebSocket encountered an error:", error);
  //     initiateReconnection();
  //   };

  //   setSocket(socket);
  // };
  // const initiateReconnection = () => {
  //   if (shouldReconnect) {
  //     // Prevent immediate reconnection attempts that can lead to a loop
  //     setTimeout(() => {
  //       console.log("Attempting to reconnect...");
  //       initializeWebSocket(); // Attempt to reconnect
  //     }, calculateReconnectDelay());
  //   }
  // };
  // const calculateReconnectDelay = () => {
  //   const maxDelay = 10000; // Maximum delay of 10 seconds
  //   let delay = 500 * (2 ** reconnectAttempts); // Exponential backoff
  //   delay = Math.min(maxDelay, delay);
  //   setReconnectAttempts(prev => prev + 1);
  //   return delay;
  // };
  useEffect(() => {
    // Create a WebSocket connection with the new URL
    if (room_id) {
      const newWebSocketURL = `${wsURL}ws/lesson/${room_id}/?course_id=${course?.course_id}&teacher_id=${teacher_id}&token=${token}`;
      let reconnectInterval = 1000; // Initial reconnect interval in ms
      const maxReconnectInterval = 30000; // Max reconnect interval
      let reconnectAttempts = 0; // Number of reconnect attempts
      const reconnectDecay = 1.5; // Rate of increase of the reconnect delay
      const maxReconnectAttempts = 3; // Maximum number of reconnect attempts

      let socket = new WebSocket(newWebSocketURL);
      // Set the socket state variable
      setSocket(socket);

      // Event handler for when the WebSocket connection is established
      socket.onopen = () => {
        console.log("WebSocket connection opened");
        reconnectAttempts = 0; // Reset reconnect attempts on successful connection
        reconnectInterval = 1000; // Reset reconnect interval
      };

      // Event handler for incoming messages from the WebSocket server
      socket.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);

          if (data && data.message) {
            const message = data.message;
            setShowCloseBTN(true);
            combinedMessage.push(message);
            let status = false;
            let uuid = Number(data.uuid);
            let chatdatares = combinedMessage.join("");
            if (
              chatdatares.includes("<STARTOFTURN>") ||
              chatdatares.includes("<ENDOFTURN>")
            ) {
              chatdatares = chatdatares
                .replace(/<STARTOFTURN>/g, "")
                .replace(/<ENDOFTURN>/g, "")
                .replace(/(\\\()(.*?)(\\\))/g, (match, p1, p2, p3) => `$$${p1}${p2}${p3}$$`).replace(/\\_/, '\_');
              if (combinedMessage.join("").includes("<ENDOFTURN>")) {
                status = true;
                combinedMessage.length = 0;
                combinedMessage.push(chatdatares);
                setShowCloseBTN(false);
              }
            }

            setChatHist((prevChatHist) => {
              const updatedChatHist = Array.isArray(prevChatHist)
                ? [...prevChatHist]
                : [];
              if (updatedChatHist.length > 0) {
                const lastQuery = updatedChatHist[updatedChatHist.length - 1];
                if (lastQuery) {
                  lastQuery.answer = marked(chatdatares);
                  lastQuery.uuid = uuid;
                }
              }
              if (status) {
                combinedMessage.length = 0;
              }
              console.log("updatedChatHist", updatedChatHist)
              return updatedChatHist;
            });
            scrollToBottom();
          } else {
          }
        } catch (error) { }

        // Handle the received message as needed
      };

      socket.onclose = () => {
        console.log("WebSocket connection closed");
        if (reconnectAttempts < maxReconnectAttempts) {
          setTimeout(() => {
            reconnectAttempts++;
            socket.close(); // Make sure to close old sockets if not yet closed
            socket = new WebSocket(newWebSocketURL); // Create a new socket and attempt to connect
            reconnectInterval = Math.min(reconnectInterval * reconnectDecay, maxReconnectInterval);
          }, reconnectInterval);
        } else {
          console.log("Max reconnect attempts reached, will not attempt further reconnects.");
        }
      };

      socket.onerror = (error) => {
        console.error('WebSocket error:', error);
        socket.close(); // Ensure the socket is closed properly
      };

      // Event handler for when the WebSocket connection is closed
      socket.addEventListener("close", (event) => { });

      // Event handler for WebSocket errors
      socket.addEventListener("error", (error) => { });

      // Cleanup the WebSocket connection when the component unmounts
      return () => {
        if (socket.readyState === 1) {
          // <-- This is important
          socket.close();
        }
      };
    }
  }, [room_id]); // The empty dependency array ensures that this effect runs only once when the component mounts
  // useEffect(() => {
  //   initializeWebSocket();

  //   return () => {
  //     setShouldReconnect(false); // Prevent reconnection attempts after component unmount
  //     if (getSocket) {
  //       getSocket.close(1000, "Component unmounting");
  //     }
  //   };
  // }, [room_id]);
  const getname = (name) => {
    var fullName = name;
    // Split the name into words
    const words = fullName?.split(" ");

    // Initialize variables for the first letter of the first and last names
    var firstNameInitial = "";
    var lastNameInitial = "";

    // Check if there is a last name
    if (words?.length > 1) {
      firstNameInitial = words[0]?.charAt(0)?.toUpperCase();
      lastNameInitial = words[1]?.charAt(0)?.toUpperCase();
    }

    // Combine the uppercase initials
    var initials = firstNameInitial + lastNameInitial;

    return initials === "" ? "T" : initials;
  };
  function getAssignmentChatQuery() {
    setPopupVisible(false);
    const {
      name,
      start_date,
      end_date,
      duration_minutes,
      type,
    } = formAssignmentState;
    const prompt_message = formState?.chat_query;
    let endDateText = end_date ? `Assignment End Date: ${end_date}` : '';
    return `Assignment Name: ${name} 
      Start Date: ${start_date} 
      ${endDateText}  
      Assignment Timer: ${duration_minutes} mins
      Choose Assignment type: ${getAssignmentTypeName(type)}  
      
      ${prompt_message}`;
  }
  function getRubricChatQuery() {
    return `${formState?.chat_query}`;
  }

  // close the coonection if user click the close button
  const closeConnection = () => {
    getSocket.close();
    setShowCloseBTN(false)
  }


  // if (getSocket && getSocket.readyState === WebSocket.OPEN) {
  //   // socket.close();
  //   console.log('proccessing');
  // } else {
  //   console.log('send');
  // }

  // Function to enhance the description  using the WebSocket connection
  const sendGenerateLession = async (e) => {
    e.preventDefault();
    scrollToBottom();


    if (!formState.name) {
      toast.error("Please enter Lesson Name.", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    // setPopupVisible(false);
    combinedMessage = [];
    if (isAssignment) {
      let errors = {};
      if (isRequired(formAssignmentState?.name)) {
        errors.name = "Name is required";
      }
      if (isRequired(formAssignmentState?.duration_minutes)) {
        errors.duration_minutes = "Timer is required";
      }
      if (isRequired(formAssignmentState?.start_date)) {
        errors.start_date = "Start date is required";
      }
      // if (isRequired(formAssignmentState?.end_date)) {
      //   errors.end_date = "End date is required";
      // }
      if (isRequired(formAssignmentState?.type)) {
        errors.type = "Type is required";
      }
      // console.log("errors", errors);

      setFormErrorAssignmentState(errors);

      if (Object.values(errors).some((error) => error)) {
        console.log("errors", errors);
        return;
      } else {
        setPopupVisible(false);
        // setCheckAssignmentOpen(true);
      }
    }

    const teacher_details = globalState.teacher_details?.data;
    const hist = chatHist.map((item) => {
      return {
        query: item.query,
        answer: Array(item.answer)
          ?.map((item) => item)
          .join(""),
      };
    });
    if (getSocket) {
      // Create a JSON payload to send over the WebSocket

      // const base64Files = await Promise.all(
      //   selectedFiles.map(async (file) => {
      //     const base64 = await readFileAsBase64(file);
      //     return base64;
      //   })
      // );
      // console.log(base64Files);
      const payload = {
        query: isAssignment
          ? getAssignmentChatQuery()
          : isRubric
            ? getRubricChatQuery()
            : formState.chat_query,
        message: hist,
        teacher_name: teacher_details?.name,
        chatbot_name: teacher_details?.ai_name,
        course_name: course?.name,
        subject: course?.subject,
        lesson_name: formState.name,
        // file_data_list: base64Files ?? [],
      };
      if (selectedFiles.length > 0) {
        sendFiles();
      }

      // Update chatHist with the new query

      setChatHist((prevChatHist) => [
        ...prevChatHist,
        {
          query: payload.query,
          answer: "",
          file:
            selectedFiles.length > 0
              ? selectedFiles.map((item) => ({
                original_name: item?.name,
              }))
              : [],
        },
      ]);
      const payloadJSON = JSON.stringify(payload);
      if (selectedFiles.length === 0) {
        getSocket.send(payloadJSON);
      }
      // Send the JSON data over the WebSocket connection

      setFormState((prev) => ({ ...prev, chat_query: "" }));
    }
    // Add functionality to enhance the description here
    setSelectedFiles([]);
    setprogressTracker([]);
  };

  // Save form data to localStorage whenever formState changes

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prev) => ({
      ...prev,
      [name]: value,
      chat_hist: chatHist,
    }));
  };
  const handleInputKeyPress = (event) => {

    const { name, value } = event.target;
    if (value !== "" || value !== 0) {
      setFormState((prev) => ({ ...prev, [name]: value }));
      setFormState((prev) => ({ ...prev, chat_hist: chatHist }));

      if (event.key === "Enter" && !event.shiftKey) {
        if (value !== "" && value !== 0) {
          // Condition 1: Check if name is provided.
          const isNameProvided = formState.name;

          // Condition 2: If chat_query exists, ensure it's not null or an empty string.
          const isChatQueryValid = formState.chat_query
            ? formState.chat_query !== null && formState.chat_query !== ""
            : true;

          // Condition 3: If selectedFiles exist, ensure they correspond to progressTracker with all statuses 'Completed'.
          const isSelectedFilesValid = selectedFiles.length
            ? selectedFiles.length === progressTracker.length &&
            progressTracker.every((obj) => obj.status === "Completed")
            : true;

          if (!isNameProvided) {
            toast.error("Please enter Lesson Name.", {
              position: toast.POSITION.TOP_CENTER,
            });
          }

          if (isNameProvided && isChatQueryValid && isSelectedFilesValid) {
            sendGenerateLession(event);
          }
        }
        event.preventDefault();
      }
    }
  };

  const handleInputChangedate = (name, date) => {
    if (lesson_id !== "") {
      let errors = {};
      if (name === "end_date") {
        errors.end_date = checkifDateisAfterToday(
          format(new Date(date), "yyyy-MM-dd")
        );
      }
      setFormLessonErrorState((prev) => ({ ...prev, [name]: errors[name] }));

      // console.log("formState?.end_date", formState?.end_date, errors);
    }

    if (name === "start_date") {
      const startDate = moment();
      const endDate = date;
      if (endDate.isAfter(startDate)) {
        setPopupForLessonStartDateNote(true);
        setTimeout(() => {
          setPopupForLessonStartDateNote(false);
        }, 3000);
      }
    }
    setFormState((prev) => ({
      ...prev,
      [name]: format(new Date(date), "yyyy-MM-dd"), // Use the provided name
    }));
  };

  const [lesson_id, setlesson_id] = useState(lesson?.lesson_id ?? "");

  const [rubric_id, setrubric_id] = useState("");
  const [clickSaveBTN, setClickSaveBTN] = useState(false);
  const [clickSaveRubricBTN, setClickSaveRubricBTN] = useState(false);
  const [demo, setdemo] = useState({
    callFuntion: false,
    data: null
  });

  const [assingmentListOfID, setAssingmentListOfID] = useState([]);

  // useEffect(() => {
  //   const existingIds = JSON.parse(localStorage.getItem('Assingment_Id')) || [];
  //   if (typeof existingIds === 'number' || typeof assignment_id === 'number') {
  //     const updatedIds = [...existingIds, assignment_id];
  //     localStorage.setItem('Assingment_Id', JSON.stringify(updatedIds));
  //   }
  // }, [assignment_id])

  // useEffect(() => {
  //   const existingIds = JSON.parse(localStorage.getItem('Rubric_id')) || [];
  //   if (typeof existingIds === 'number' || typeof rubric_id === 'number') {
  //     const updatedIds = [...existingIds, rubric_id];
  //     localStorage.setItem('Rubric_id', JSON.stringify(updatedIds));
  //   }
  // }, [rubric_id])


  const [disabledRubric, setDisabledRubric] = useState(false);
  const [globleOnChangeDataSave, setGlobleOnChangeDataSave] = useState(false);



  const handleAssinmentRubricSubmit = async (e) => {
    setGlobleOnChangeDataSave(true)
    if (!isNotSavePopUp) {
      e.preventDefault();
    }
    if (activeWorkboard.name === 'Assignment') {
      setClickSaveBTN(true)
    }
    if (activeWorkboard.name === 'Rubric') {
      setClickSaveRubricBTN(true)
    }

    if (!checkAssignmentopen) {
      // if (isAssignment ? (previesAssignment ? true : false) : false) {
      // if (isAssignment && !previesAssignment) {
      if (isAssignment) {
        let errors = {};
        if (isRequired(formAssignmentState?.name)) {
          errors.name = "Name is required";
        }
        if (isRequired(formAssignmentState?.duration_minutes)) {
          errors.duration_minutes = "Timer is required";
        }
        if (isRequired(formAssignmentState?.start_date)) {
          errors.start_date = "Start date is required";
        }
        // if (isRequired(formAssignmentState?.end_date)) {
        //   errors.end_date = "End date is required";
        // }
        if (isRequired(formAssignmentState?.type)) {
          errors.type = "Type is required";
        }
        console.log("errors", errors);

        setFormErrorAssignmentState(errors);

        if (Object.values(errors).some((error) => error)) {
          console.log("errors", errors);
          return;
        }
      }
    }

    // assignmentsCountTwo
    // formAssignmentState
    // formState




    const payload = isAssignment
      ? previesAssignment ? {
        name:
          getassignmentdetails.length > 0
            ? getassignmentdetails[assignmentsCountTwo]?.name
            : formAssignmentState?.name,
        assignment: workboards.ASSIGNMENT.data,
        lesson: lesson_id,
        duration_minutes:
          getassignmentdetails.length > 0
            ? getassignmentdetails[assignmentsCountTwo]?.duration_minutes
            : formAssignmentState?.duration_minutes,
        start_date:
          getassignmentdetails.length > 0
            ? getassignmentdetails[assignmentsCountTwo]?.start_date
            : formAssignmentState?.start_date,
        end_date:
          getassignmentdetails.length > 0
            ? getassignmentdetails[assignmentsCountTwo]?.end_date
            : formAssignmentState?.end_date,
        type:
          getassignmentdetails.length > 0
            ? getassignmentdetails[assignmentsCountTwo]?.type
            : formAssignmentState.type,
        ...(globleSaveData.RUBRIC.data[assignmentsCountTwo] && globleSaveData.RUBRIC.data[assignmentsCountTwo].Id && { rubric: globleSaveData.RUBRIC.data[assignmentsCountTwo].Id })
      } : {
        name: formAssignmentState?.name,
        assignment: workboards.ASSIGNMENT.data,
        lesson: lesson_id,
        duration_minutes: formAssignmentState?.duration_minutes,
        start_date: formAssignmentState?.start_date,
        end_date: formAssignmentState?.end_date,
        type: formAssignmentState.type,
        ...(globleSaveData.RUBRIC.data[assignmentsCountTwo] && globleSaveData.RUBRIC.data[assignmentsCountTwo].Id && { rubric: globleSaveData.RUBRIC.data[assignmentsCountTwo].Id })

      }
      : isRubric
        ? {
          rubric: workboards.RUBRIC.data,
          lesson: lesson_id,
          ...(globleSaveData.ASSIGNMENT.data[rubricCounttTwo] && globleSaveData.ASSIGNMENT.data[rubricCounttTwo].Id && { assignment: globleSaveData.ASSIGNMENT.data[rubricCounttTwo].Id })
        }
        //       console.warn(rubricCounttTwo);
        // console.warn(rubricCount);
        // console.warn(globleSaveData[activeWorkboard.name.toUpperCase()].data.Id !== undefined);

        // if (assignmentsCount.length > 0) {
        //   console.warn(globleSaveData.ASSIGNMENT.data[rubricCounttTwo]);
        //   // console.warn(globleSaveData.ASSIGNMENT.data[rubricCounttTwo].Id);
        // }

        : {
          name: formState?.name ?? "",
          start_date: formState?.start_date ?? "",
          end_date: formState?.end_date,
          description: workboards.LESSON.data,
          course: course?.course_id ?? "",
          teacher: teacher_id,
          status: 3,
          user_query: { question: chatHist[0]?.query ?? "" },
          ai_answer: { answer: chatHist[0]?.answer ?? "" },
          room_id: room_id,
          is_personalize: isSwitchOn,
          is_file_download: isSwitchOn2,
          lesson_preview: isSwitchOnLessonPreview,
          virtual_assistant: isSwitchOnVirtualAssistant,
        };


    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };

    // const workboardItem = Object.values(workboards).find(item => item.name === workboard.name);
    // update(workboardItem, 'Assignment')



    // ? assignment_id !== ""
    // ? previesAssignment ? `assignments/update-assignment/${eachAssignment_id}/`
    //   : `assignments/update-assignment/${assignment_id}/`
    // : "assignments/create-assignment/"


    // console.log(eachAssignment_id);
    // return;




    // start 


    // setShowCourseSuccessfully(true);
    // updateDefaultWorkboardData(
    //   isAssignment
    //     ? defaultWorkboardData.ASSIGNMENT.name
    //     : isRubric
    //       ? defaultWorkboardData.RUBRIC.name
    //       : defaultWorkboardData.LESSON.name,
    //   isAssignment
    //     ? workboards.ASSIGNMENT.data
    //     : isRubric
    //       ? workboards.RUBRIC.data
    //       : workboards.LESSON.data
    // );

    // updateGlobleSaveData(
    //   isAssignment
    //     ? globleSaveData.ASSIGNMENT.name
    //     : isRubric
    //       ? globleSaveData.RUBRIC.name
    //       : globleSaveData.LESSON.name,
    //   isAssignment
    //     ? workboards.ASSIGNMENT.data
    //     : isRubric
    //       ? workboards.RUBRIC.data
    //       : workboards.LESSON.data
    // );


    // // not use this code 

    // const globleInputData1 = JSON.parse(localStorage.getItem('GlobleSaveData'));


    // const assignmentId = previesAssignment ? globleInputData1.ASSIGNMENT.data[assignmentsCountTwo].Id : Math.floor(100000 + Math.random() * 900000)
    // const rubricId = previesRubric ? globleInputData1.RUBRIC.data[rubricCounttTwo].Id : Math.floor(100000 + Math.random() * 900000)
    // const lesson = globleInputData1.LESSON.data.Id === undefined ? Math.floor(100000 + Math.random() * 900000) : globleInputData1.LESSON.data.Id

    // const getid = isAssignment
    //   ? assignmentId
    //   : isRubric
    //     ? rubricId
    //     : lesson

    // // //



    // setTimeout(() => {
    //   if (isAssignment) {
    //     setPreviesAssignment(true)
    //     setPopupVisible(false)
    //   } else if (isRubric) {
    //     console.warn('rubric');
    //     setPreviesRubric(true)
    //   }

    //   setShowCourseSuccessfully(false)
    //   const globleOnChangeData = JSON.parse(localStorage.getItem('GlobleSaveData'));
    //   if (isAssignment || isRubric) {

    //     console.warn(getid);
    //     const globleInputData = {
    //       // data: GlobleSaveData.ASSIGNMENT.data[assignmentsCountTwo] === undefined ? workboards.ASSIGNMENT.data : GlobleSaveData.ASSIGNMENT.data[assignmentsCountTwo],
    //       data: activeWorkboard.name === 'Assignment' ? globleOnChangeData.ASSIGNMENT.data[assignmentsCountTwo] : activeWorkboard.name === 'Rubric' && globleOnChangeData.RUBRIC.data[rubricCounttTwo],
    //       Id: getid
    //     }
    //     console.warn(globleInputData);
    //     updateGlobleSaveData(activeWorkboard.name, globleInputData)

    //   } else {
    //     const globleInputData = {
    //       // data: GlobleSaveData.ASSIGNMENT.data[assignmentsCountTwo] === undefined ? workboards.ASSIGNMENT.data : GlobleSaveData.ASSIGNMENT.data[assignmentsCountTwo],
    //       data: globleOnChangeData.LESSON.data,
    //       Id: getid
    //     }

    //     updateGlobleSaveData(activeWorkboard.name, globleInputData)
    //   }
    // }, 1000);






    const url = isAssignment
      // ? assignment_id !== ""
      // ? assingmentListOfID.includes(eachAssignment_id) && `assignments/update-assignment/${assignment_id}/`
      // ? assignment_id !== ""
      //   ? `assignments/update-assignment/${assignment_id}/`
      //   : "assignments/create-assignment/"
      // : isRubric
      // ? assignment_id !== ""
      //   ? previesAssignment ? `assignments/update-assignment/${eachAssignment_id}/`
      //     : `assignments/update-assignment/${assignment_id}/`
      //   : "assignments/create-assignment/"

      // ? assignment_id !== "" ? (eachAssignment_id[assignmentsCountTwo] ? `assignments/update-assignment/${eachAssignment_id}/` : "assignments/create-assignment/") : "assignments/create-assignment/"

      ? previesAssignment ? (eachAssignment_id ? `assignments/update-assignment/${eachAssignment_id}/` : "assignments/create-assignment/") : "assignments/create-assignment/"
      : isRubric
        ? rubric_id !== ""
          ? (previesRubric ? `assignments/update-rubric/${eachRubric_id}/` : "assignments/create-rubric/") : "assignments/create-rubric/"
        // ? `assignments/update-rubric/${rubric_id}/`
        // : "assignments/create-rubric/"
        : lesson_id !== ""
          ? `lessons/update-lesson/${lesson_id}/`
          : "lessons/create-lesson/";

    try {
      const response = (isAssignment
        ? assignment_id !== ""
          ? (previesAssignment ? true : false)
          : false
        // ? previesAssignment
        //   ? true
        //   : false
        : isRubric
          ? rubric_id !== ""
            ? (previesRubric ? true : false)
            : false
          : lesson_id !== ""
            ? true
            : false)
        ? await api.put(url, payload, { headers: headers })
        : await api.post(url, payload, { headers: headers });
      if (response.status === 200 || response.status === 201) {

        // setLesson(response)
        setShowCourseSuccessfully(true);
        //document.body.style.overflow = "hidden";
        updateDefaultWorkboardData(
          isAssignment
            ? defaultWorkboardData.ASSIGNMENT.name
            : isRubric
              ? defaultWorkboardData.RUBRIC.name
              : defaultWorkboardData.LESSON.name,
          isAssignment
            ? response?.data?.data?.assignment
            : isRubric
              ? response?.data?.data?.rubric
              : response?.data?.data?.description
        );
        updateGlobleSaveData(
          isAssignment
            ? globleSaveData.ASSIGNMENT.name
            : isRubric
              ? globleSaveData.RUBRIC.name
              : globleSaveData.LESSON.name,
          isAssignment
            ? response?.data?.data?.assignment
            : isRubric
              ? response?.data?.data?.rubric
              : response?.data?.data?.description
        );
        const getid = isAssignment
          ? response?.data?.data?.assignment_id
          : isRubric
            ? response?.data?.data?.rubric_id
            : response?.data?.data?.lesson_id;






        setTimeout(() => {
          setShowCourseSuccessfully(false);


          document.body.style.overflow = "auto";

          const globleOnChangeData = JSON.parse(localStorage.getItem('globleOnChangeData'))
          if (isAssignment) {
            const globleInputData = {
              // data: GlobleSaveData.ASSIGNMENT.data[assignmentsCountTwo] === undefined ? workboards.ASSIGNMENT.data : GlobleSaveData.ASSIGNMENT.data[assignmentsCountTwo],
              data: activeWorkboard.name === 'Assignment' ? globleOnChangeData.ASSIGNMENT.data[assignmentsCountTwo] : activeWorkboard.name === 'Rubric' && globleOnChangeData.RUBRIC.data[rubricCounttTwo],
              Id: getid
            }
            updateGlobleSaveData(activeWorkboard.name, globleInputData, formAssignmentState)
            if (!previesAssignment) {
              const existingIds = JSON.parse(localStorage.getItem('Assingment_Id')) || [];
              const updatedIds = [...existingIds, getid];
              localStorage.setItem('Assingment_Id', JSON.stringify(updatedIds));
              setPreviesAssignment(true)
              setEachAssignment_id(getid)
              setPopupVisible(false)

              setFormState((prev) => ({
                ...prev,
                chat_query: "",
              }));
              setassignment_id(getid);

            }
            if (assignment_id === "") {
              setAssingmentListOfID(prev => [...prev, getid]);
            }
          } else if (isRubric) {
            const globleInputData = {
              // data: GlobleSaveData.ASSIGNMENT.data[assignmentsCountTwo] === undefined ? workboards.ASSIGNMENT.data : GlobleSaveData.ASSIGNMENT.data[assignmentsCountTwo],
              data: activeWorkboard.name === 'Assignment' ? globleOnChangeData.ASSIGNMENT.data[assignmentsCountTwo] : activeWorkboard.name === 'Rubric' && globleOnChangeData.RUBRIC.data[rubricCounttTwo],
              Id: getid
            }
            updateGlobleSaveData(activeWorkboard.name, globleInputData)

            if (!previesRubric) {
              const existingIds = JSON.parse(localStorage.getItem('Rubric_id')) || [];
              const updatedIds = [...existingIds, getid];
              localStorage.setItem('Rubric_id', JSON.stringify(updatedIds));
              setPreviesRubric(true)
              setEachRubric_id(getid)
            }
            if (rubric_id === "") {
              setrubric_id(getid);
              // setOnChangeRubricData(prev => {
              //   const newState = [...prev]; // Create a copy of the previous state array
              //   newState[rubricCounttTwo]['rubricid'] = getid; // Update the specific index
              //   return newState; // Return the new state
              // });
            }
          } else {
            const globleInputData = {
              // data: GlobleSaveData.ASSIGNMENT.data[assignmentsCountTwo] === undefined ? workboards.ASSIGNMENT.data : GlobleSaveData.ASSIGNMENT.data[assignmentsCountTwo],
              data: globleOnChangeData.LESSON.data,
              Id: getid
            }

            updateGlobleSaveData(activeWorkboard.name, globleInputData)
            setLesson(response?.data?.data)
            if (lesson_id === "") {
              setlesson_id(getid);

              // setLesson(response?.data?.data?.lesson);
            }
          }



          if (isAssignment) {
            const newArray = {
              data: response?.data?.data?.assignment,
              Id: getid
            };

            const newFormAssignmentArrya = {
              name: formAssignmentState.name,
              assignment: null,
              assignment_json: null,
              lesson: null,
              duration_minutes: formAssignmentState.duration_minutes,
              start_date: formAssignmentState.start_date,
              end_date: formAssignmentState.end_date,
              type: formAssignmentState.type
            };

            const globleSaveData = JSON.parse(localStorage.getItem('GlobleSaveData'));

            globleSaveData.ASSIGNMENT.data[assignmentsCountTwo] = newArray
            globleSaveData.ASSIGNMENT.formSaveAssignment[assignmentsCountTwo] = newFormAssignmentArrya

            setGlobleSaveData(globleSaveData)

            localStorage.setItem("GlobleSaveData", JSON.stringify(globleSaveData));
          }

        }, 1000);

        //


        //




      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };








  const [showCourseSuccessfully, setShowCourseSuccessfully] = useState(false);

  // use updateDefaultWorkboardData
  // eg updateDefaultWorkboardData('LESSON', 'some data');
  useEffect(() => {
    if (lesson?.description !== "" && lesson?.description !== undefined) {
      updateDefaultWorkboardData(
        defaultWorkboardData.LESSON.name,
        lesson?.description ?? ""
      );
      updateWorkboardDataByName(
        workboards.LESSON.name,
        lesson?.description
      )
    }
  }, [lesson?.description]);




  const handleForm1Submit = async (e) => {

    e.preventDefault();

    setLoading(true);
    setEditLoading(true);

    const { name, start_date } = formState;
    toast.dismiss();

    const nameRegex = /^[a-zA-Z0-9- ]+$/;

    if (!name) {
      toast.error("Please enter Lesson Name.", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
      return;
    }

    if (!nameRegex.test(name)) {
      toast.error("Lesson Name should only contain letters, numbers, and hyphen.", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
      return;
    }
    // if (!assignment_no) {
    //     toast.error('Please select Assignment No.', {
    //         position: toast.POSITION.TOP_CENTER
    //     });
    //     setLoading(false);
    //     return;
    // }


    if (!start_date) {
      toast.error("Please enter a valid start date.", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
      return;
    } else {
      // setFormState((prev) => ({ ...prev, start_date: format(new Date(start_date), 'yyyy-MM-dd') }));
      // setFormState((prev) => ({ ...prev, end_date: format(new Date(end_date), 'yyyy-MM-dd') }));

      // setFormState((prev) => ({ ...prev, chat_hist: chatHist }));

      // const serializedFormState = {
      //     name: formState?.name,
      //     assignment_no: formState?.assignment_no,
      //     start_date: formState?.start_date,
      //     end_date: formState?.end_date,
      //     description: formState?.description,
      //     chat_query: formState?.chat_query,
      //     chat_hist: chatHist,
      // };

      // navigate("/PreviewLession", { state: { new_lession: JSON.stringify(serializedFormState), course, teacher_id } });

      //start

      // const globleData = {
      //   data: workboards.LESSON.data,
      //   Id: Math.floor(100000 + Math.random() * 900000)
      // }
      // updateGlobleSaveData(globleSaveData.LESSON.name, globleData)
      // setShowCourseSuccessfully(true);
      // // add timeout
      // //document.body.style.overflow = "hidden";
      // const getlesson_id = Math.floor(100000 + Math.random() * 900000);
      // updateDefaultWorkboardData(
      //   defaultWorkboardData.LESSON.name,
      //   workboards.LESSON.data || ""
      // );
      // // console.log('response  ' + response);
      // const lessonData = {
      //   lesson_id: getlesson_id,
      //   description: workboards.LESSON.data
      // }
      // setLesson(lessonData)

      // setTimeout(() => {
      //   setShowCourseSuccessfully(false);
      //   // navigate(`/course/${course.course_id}`, { state: { course, teacher_id } });
      //   document.body.style.overflow = "auto";
      //   if (lesson_id === "") {
      //     setlesson_id(getlesson_id);
      //   }
      // }, 1000)



      try {
        const url =
          lesson_id !== ""
            ? `lessons/update-lesson/${lesson_id}/`
            : "lessons/create-lesson/";

        const payload = {
          name: formState?.name ?? "",
          start_date: formState?.start_date ?? "",
          end_date: formState?.end_date,
          description: workboards.LESSON.data,
          course: course?.course_id ?? "",
          teacher: teacher_id,
          status: 3,
          user_query: { question: chatHist[0]?.query ?? "" },
          ai_answer: { answer: chatHist[0]?.answer ?? "" },
          room_id: room_id,
          is_personalize: isSwitchOn,
          is_file_download: isSwitchOn2,
          lesson_preview: isSwitchOnLessonPreview,
          virtual_assistant: isSwitchOnVirtualAssistant,
        };

        const headers = {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        };

        const response =
          lesson_id !== ""
            ? await api.put(url, payload, { headers: headers })
            : await api.post(url, payload, { headers: headers });


        if (response.status === 200 || response.status === 201) {

          // const globleData = {
          //   data: workboards.LESSON.data,
          //   Id: Math.floor(100000 + Math.random() * 900000)
          // }


          // updateGlobleSaveData(globleSaveData.LESSON.name, globleData)



          setShowCourseSuccessfully(true);
          // add timeout
          //document.body.style.overflow = "hidden";
          const getlesson_id = response?.data?.data?.lesson_id;
          const lessonData = {
            Id: getlesson_id,
            data: response?.data?.data?.description
          }

          updateDefaultWorkboardData(
            defaultWorkboardData.LESSON.name,
            response?.data?.data?.description || ""
          );
          console.log('response  ' + response);
          setLesson(lessonData)
          updateGlobleSaveData(activeWorkboard.name, lessonData)

          setTimeout(() => {
            setShowCourseSuccessfully(false);
            // navigate(`/course/${course.course_id}`, { state: { course, teacher_id } });
            document.body.style.overflow = "auto";
            if (lesson_id === "") {
              setlesson_id(getlesson_id);
            }
          }, 1000);

        }

        setLoading(false);
        // login(response.data.token);
        // setSuccessMessage('Signup successful: Your account has been created.'); // Set success message
        // navigate("/dashboard", { state: { role: activeTab } });
      } catch (error) {
        console.log(error);
        setLoading(false);
        // Handle errors (e.g., display error message)
        //
        // setErrorMessage(error.response.data.message); // Set error message
      }
    }
  };

  const [isSwitchOn, setIsSwitchOn] = useState(lesson?.is_personalize ?? false);
  const [isSwitchOn2, setIsSwitchOn2] = useState(lesson?.is_file_download ?? false);
  const [isSwitchOnLessonPreview, setIsSwitchOnLessonPreview] = useState(lesson?.lesson_preview ?? false);
  const [isSwitchOnVirtualAssistant, setIsSwitchOnVirtualAssistant] = useState(lesson?.virtual_assistant ?? true);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (!isSwitchOn) {
      setShowPopup(false); // Hide the popup when the switch is turned on
    }
  }, [isSwitchOn]);

  const handleSwitchToggle = () => {
    if (!isSwitchOn) {
      setShowPopup(true); // Display the popup when the switch is turned off
      setTimeout(() => {
        setShowPopup(false); // Hide the popup after 3 seconds
      }, 3000);
    }
    setIsSwitchOn(!isSwitchOn);
  };

  const handleSwitchToggleDownload = () => {
    if (!isSwitchOn2) {
      console.log("is_file_download : true");
    }
    setIsSwitchOn2(!isSwitchOn2);
    console.log("is_file_download : false");
  };

  const handleSwitchToggleLessonPreview = () => {
    if (!isSwitchOnLessonPreview) {
      console.log("lesson preview : true");
    }
    setIsSwitchOnLessonPreview(!isSwitchOnLessonPreview);
    console.log("lesson preview : false");
  };

  const handleSwitchToggleVirtualAssistant = () => {
    if (!isSwitchOnVirtualAssistant) {
      console.log("virtual assistant : true");
    }
    setIsSwitchOnVirtualAssistant(!isSwitchOnVirtualAssistant);
    console.log("virtual assistant : false");
  };


  function onError(error) {
    throw error;
  }

  function OnChangePlugin() {

    const [editor] = useLexicalComposerContext();
    // updateGlobleOnChangeData(globleOnChange.ASSIGNMENT.name, workboards.ASSIGNMENT.data, 'formAssignmentState_true', formAssignmentState)
    useEffect(() => {
      return editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          if (activeWorkboard.name === 'Assignment') {
            const globleOnChange = JSON.parse(localStorage.getItem('globleOnChangeData'))

            // if (globleOnChange.ASSIGNMENT.data[assignmentsCountTwo].AssignmentSave !== undefined) {
            if (!globleOnChangeDataSave) {
              if (!isNotSavePopUp) {
                updateGlobleOnChangeData(
                  activeWorkboard.name,
                  $generateHtmlFromNodes(editor)
                )
              }
            } else {
              setGlobleOnChangeDataSave(false)
            }
            // }
          } else {
            updateGlobleOnChangeData(
              activeWorkboard.name,
              $generateHtmlFromNodes(editor)
            )
          }
          updateWorkboardDataByName(
            activeWorkboard.name,
            $generateHtmlFromNodes(editor)
          );


          // const searchParams = new URLSearchParams(location.search);
          // searchParams.set('globleInputName', activeWorkboard.name);
          // searchParams.set('globleInputData', $generateHtmlFromNodes(editor));
          // navigate({ search: searchParams.toString() });
        });
      });
    }, [editor, onChange]);
  }

  function stripStyles(html) {
    // Create a temporary DOM element to manipulate
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    // Remove all style attributes
    const elements = tempDiv.querySelectorAll('[style]');
    elements.forEach(el => el.removeAttribute('style'));

    // Return the cleaned HTML content
    return tempDiv.innerHTML;
  }



  function OnChangeRubricPlugin({ onChangeRubricData, rubricCounttTwo }) {

    const [editor] = useLexicalComposerContext();

    useEffect(() => {
      const handleUpdate = ({ editorState }) => {
        editorState.read(() => {
          const selection = $getSelection();
          if ($isRangeSelection(selection)) {
            const htmlContent = $generateHtmlFromNodes(editor);
            const currentCleanedContent = stripStyles(htmlContent);
            const previousCleanedContent = stripStyles(onChangeRubricData[rubricCounttTwo] || '');


            // Only call the update functions if the content has changed

            if (currentCleanedContent !== previousCleanedContent || htmlContent === '') {
              setDisabledRubric(false)
              updateWorkboardDataByName(activeWorkboard.name, htmlContent);
              updateGlobleOnChangeData(
                activeWorkboard.name,
                htmlContent
              )

              // const searchParams = new URLSearchParams(location.search);
              // searchParams.set('globleInputName', activeWorkboard.name);
              // searchParams.set('globleInputData', htmlContent);
              // navigate({ search: searchParams.toString() });
            } else {
              updateWorkboardDataByName(activeWorkboard.name, onChangeRubricData[rubricCounttTwo]);
              updateGlobleOnChangeData(
                activeWorkboard.name,
                onChangeRubricData[rubricCounttTwo]
              )

              // const searchParams = new URLSearchParams(location.search);
              // searchParams.set('globleInputName', activeWorkboard.name);
              // searchParams.set('globleInputData', onChangeRubricData[rubricCounttTwo]);
              // navigate({ search: searchParams.toString() });
              // onChangeRubricData[rubricCounttTwo] = htmlContent
              setDisabledRubric(true)
            }
          }
        });
      };

      const unregisterUpdateListener = editor.registerUpdateListener(handleUpdate);

      return () => {
        unregisterUpdateListener();
      };
    }, [editor]);


    return null;
  }




  //   function OnChangePlugin() {
  //     const [editor] = useLexicalComposerContext();

  //     useEffect(() => {
  //         const handleInput = () => {
  //             editor.update(() => {
  //                 const selection = $getSelection();
  //                 if ($isRangeSelection(selection)) {
  //                     const htmlContent = $generateHtmlFromNodes(editor);
  //                     updateWorkboardDataByName(activeWorkboard.name, htmlContent);
  //                 }
  //             });
  //         };

  //         const handleKeyDown = (event) => {
  //             if (event.key === 'Backspace' || event.key === 'Delete') {
  //                 handleInput();
  //             }
  //         };

  //         const editorElement = editor.getRootElement();
  //         if (editorElement) {
  //             editorElement.addEventListener('input', handleInput);
  //             editorElement.addEventListener('keydown', handleKeyDown);
  //         }

  //         return () => {
  //             if (editorElement) {
  //                 editorElement.removeEventListener('input', handleInput);
  //                 editorElement.removeEventListener('keydown', handleKeyDown);
  //             }
  //         };
  //     }, [editor]);

  //     return null;
  // }


  // function OnChangePlugin() {
  //   const [editor] = useLexicalComposerContext();

  //   useEffect(() => {
  //       const handleUpdate = () => {
  //           editor.update(() => {
  //               const selection = $getSelection();
  //               if ($isRangeSelection(selection)) {
  //                   const htmlContent = $generateHtmlFromNodes(editor);
  //                   updateWorkboardDataByName(activeWorkboard.name, htmlContent);

  //               }
  //           });
  //       };

  //       const editorElement = editor.getRootElement();
  //       if (editorElement) {
  //           editorElement.addEventListener('input', handleUpdate);
  //           editorElement.addEventListener('keydown', handleUpdate);
  //       }

  //       return () => {
  //           if (editorElement) {
  //               editorElement.removeEventListener('input', handleUpdate);
  //               editorElement.removeEventListener('keydown', handleUpdate);
  //           }
  //       };
  //   }, [editor]);

  //   return null;
  // }


  function Placeholder() {
    return <div className="editor-placeholder">Write / Paste content here</div>;
  }
  // const [editorState, setEditorState] = useState();

  function onChange(editorState) {
    // setEditorState(editorState);
    // editorState.read(() => {
    //     // Read the contents of the EditorState here.
    //     const root = $getRoot();
    //     const selection = $getSelection();
    //     console.log("root", root);
    //     console.log("selection",selection);
    //     // const htmlString = $generateHtmlFromNodes(editorState);
    //     // setlessonDescriptin(htmlString);
    // });
  }

  // ["<p class=\"editor-paragraph\"><span style=\"white-space: pre-wrap;\">11</span></p>", "<p class=\"editor-paragraph\" dir=\"ltr\"><span style=\"white-space: pre-wrap;\">a1</span></p>"]


  const [globleOnChange, setGlobleOnChange] = useState({
    LESSON: {
      name: "Lesson",
      data: "",
    },
    ASSIGNMENT: {
      name: "Assignment",
      data: [],
      formAssignment: []
    },
    RUBRIC: {
      name: "Rubric",
      data: [],
    },
  });


  useEffect(() => {
    localStorage.setItem("globleOnChangeData", JSON.stringify(globleOnChange));
  }, [globleOnChange])


  const [globleSaveData, setGlobleSaveData] = useState({
    LESSON: {
      name: "Lesson",
      data: "",
    },
    ASSIGNMENT: {
      name: "Assignment",
      data: [],
      formSaveAssignment: []
    },
    RUBRIC: {
      name: "Rubric",
      data: [],
    },
  });

  useEffect(() => {
    localStorage.setItem("GlobleSaveData", JSON.stringify(globleSaveData));
  }, [globleSaveData])




  useEffect(() => {

    localStorage.setItem("GlobleSaveData", JSON.stringify(globleSaveData));
    localStorage.setItem("globleOnChangeData", JSON.stringify(globleOnChange));

    if (location.state?.lesson !== undefined) {
      const globleSaveData = JSON.parse(localStorage.getItem("GlobleSaveData"));
      const globleOnChange = JSON.parse(localStorage.getItem("globleOnChangeData"));

      const newLessonArrya = {
        data: {
          data: location.state?.lesson.description,
          Id: location.state?.lesson.lesson_id
        }
      }

      globleSaveData.LESSON.data = newLessonArrya
      globleOnChange.LESSON.data = newLessonArrya

      localStorage.setItem("GlobleSaveData", JSON.stringify(globleSaveData));
      localStorage.setItem("globleOnChangeData", JSON.stringify(globleOnChange));

    }
  }, [location.state?.lesson])



  const updateGlobleOnChangeData = (name, newData, assingmentpopUp, assingmentpopUpData, newDataForOnlySave) => {


    const globleOnChangeData = JSON.parse(localStorage.getItem('globleOnChangeData'))


    // setWorkboards((prevWorkboards) => {
    //   // Find the key of the workboard we want to update
    //   const workboardKey = Object.keys(prevWorkboards).find(
    //     (key) => prevWorkboards[key].name === name
    //   );
    //   // If we found a workboard with the provided name
    //   if (workboardKey) {
    //     return {
    //       ...prevWorkboards, // Copy all the existing workboards
    //       [workboardKey]: {
    //         // Use computed property names to overwrite the specific workboard
    //         ...prevWorkboards[workboardKey], // Copy all the existing properties of the workboard
    //         data: newData, // Update the data property
    //       },
    //     };
    //   } else {
    //     // If no workboard was found with the given name, return the previous state
    //     return prevWorkboards;
    //   }
    // });


    setGlobleOnChange((prevWorkboards) => {
      // Find the key of the workboard we want to update
      const workboardKey = Object.keys(prevWorkboards).find(
        (key) => prevWorkboards[key].name === name
      );
      // If we found a workboard with the provided name
      if (workboardKey) {
        let updatedData;
        let updatedFormAssignment;

        if (isAssignment) {
          // Create a copy of the existing data array
          updatedData = prevWorkboards[workboardKey].data
          // updatedData[assignmentsCountTwo] = newData;
          // updatedData = Array.isArray(prevWorkboards[workboardKey].data)
          //   ? [...prevWorkboards[workboardKey].data]
          //   : [];
          // updatedFormAssignment = Array.isArray(prevWorkboards[workboardKey].formAssignment)
          //   ? [...prevWorkboards[workboardKey].formAssignment]
          //   : [];
          // // Update the specific index
          // console.warn(prevWorkboards[workboardKey]);
          // console.warn(updatedData);

          // // updatedData[assignmentsCountTwo] = newData;
          // // if (assingmentpopUp === 'formAssignmentState_true') {
          // //   updatedFormAssignment.push(assingmentpopUpData);
          // // } else {
          updatedData[Number(assignmentsCountTwo)] = newData;
          // //   console.warn(assignmentsCountTwo);
          // //   console.warn(updatedData);
          // // }
        } else if (isRubric) {
          // Create a copy of the existing data array
          updatedData = [...prevWorkboards[workboardKey].data];
          // Update the specific index
          updatedData[rubricCounttTwo] = newData;
        } else {
          updatedData = newData;
        }

        const updatedWorkboards = {
          ...prevWorkboards, // Copy all the existing workboards
          [workboardKey]: {
            ...prevWorkboards[workboardKey], // Copy all the existing properties of the workboard
            data: updatedData, // Update the data property with the new array

          },
        };


        // Store updated state in localStorage
        // localStorage.setItem("globleOnChangeData", JSON.stringify(updatedWorkboards));
        return updatedWorkboards;



      } else {
        // If no workboard was found with the given name, return the previous state
        return prevWorkboards;
      }
    });
  };




  const updateGlobleSaveData = (name, newData, formAssignmentState) => {

    setGlobleSaveData((prevWorkboards) => {
      // Find the key of the workboard we want to update
      const workboardKey = Object.keys(prevWorkboards).find(
        (key) => prevWorkboards[key].name === name
      );
      // If we found a workboard with the provided name


      if (workboardKey) {
        let updatedData;
        let updatedFormAssignment;

        if (isAssignment) {
          // Create a copy of the existing data array
          updatedData = Array.isArray(prevWorkboards[workboardKey].data)
            ? [...prevWorkboards[workboardKey].data]
            : [];
          updatedData[assignmentsCountTwo] = newData;

          // if (formAssignmentState) {
          //   updatedFormAssignment = Array.isArray(prevWorkboards[workboardKey].formSaveAssignment)
          //     ? [...prevWorkboards[workboardKey].formSaveAssignment]
          //     : [];
          //   updatedFormAssignment[assignmentsCountTwo] = formAssignmentState

          // }
        } else if (isRubric) {
          // Create a copy of the existing data array
          updatedData = [...prevWorkboards[workboardKey].data];
          // Update the specific index
          updatedData[rubricCounttTwo] = newData;
        } else {
          updatedData = newData;
        }

        const updatedWorkboards = {
          ...prevWorkboards, // Copy all the existing workboards
          [workboardKey]: {
            ...prevWorkboards[workboardKey], // Copy all the existing properties of the workboard
            data: updatedData, // Update the data property with the new array
            // ...(isAssignment && { formSaveAssignment: updatedFormAssignment })
          },
        };


        // Store updated state in localStorage
        return updatedWorkboards;



      } else {
        // If no workboard was found with the given name, return the previous state
        return prevWorkboards;
      }
    });
  };


  const [hideRubricPopUp, setHideRubricPopUp] = useState(false);
  const [showRubricPopUp, setShowRubricPopUp] = useState(false);



  const [assignmentsCount, setAssignmentCounts] = useState([]);
  const [assignmentsCountTwo, setAssignmentCountsTwo] = useState(0);


  const [showAssingment, setShowAssingment] = useState(false);
  const [previesAssignment, setPreviesAssignment] = useState(false);
  const [previesRubric, setPreviesRubric] = useState(false);
  const [selectedAssignment, setSelectedAssignment] = useState('Assignment');
  const [onChangeAssignmentData, setOnChangeAssignmentData] = useState([]);



  useEffect(() => {
    if (getassignmentdetails.length > 0) {
      setSelectedAssignment(`Assignment ${getassignmentdetails.length}`)
      const initialArray = new Array(getassignmentdetails.length)
        .fill('')
        .map((_, index) => `Assignment ${index + 1}`);
      setAssignmentCounts(initialArray)
      // setAssignmentCountsTwo(initialArray.length)
      setOnChangeAssignmentData(getassignmentdetails?.map(item => item.assignment))
    }
  }, [getassignmentdetails])

  // useEffect(() => {


  //   console.warn(globleOnChange.ASSIGNMENT);
  //   console.warn(formAssignmentState)
  //   console.warn(assignmentsCountTwo);
  //   if (formAssignmentState.name !== '' || formAssignmentState.type !== null || formAssignmentState.duration_minutes !== 0) {

  //     globleOnChange.ASSIGNMENT.formAssignment[assignmentsCountTwo] = formAssignmentState

  //     localStorage.setItem("globleOnChangeData", JSON.stringify(globleOnChange));
  //   }


  // }, [formAssignmentState])




  const [rubricCount, setRubricCount] = useState([]);
  const [rubricCounttTwo, setRubricCountTwo] = useState(0);
  const [onChangeRubricData, setOnChangeRubricData] = useState([]);
  const [selectedRubric, setSelectedRubric] = useState('Rubric');
  const [showDropdownRubric, setShowDropdownRubric] = useState(false);




  useEffect(() => {
    if (rubrics.length > 0) {
      setSelectedRubric(`Rubric ${rubrics.length}`)
      const initialArray = new Array(rubrics.length)
        .fill('')
        .map((_, index) => `rubric ${index + 1}`);
      setRubricCount(initialArray)
      // setAssignmentCountsTwo(initialArray.length)
      setOnChangeRubricData(rubrics?.map(item => item.rubric))
    }
  }, [rubrics])


  useEffect(() => {
    let rubric

    if (rubricCount.length === 0) {
      if (assignmentsCountTwo > rubricCounttTwo) {
        setShowRubricPopUp(true)
      }
    } else {
      if (assignmentsCountTwo > (rubricCounttTwo + 1)) {
        setShowRubricPopUp(true)
      }
    }


  }, [assignmentsCountTwo, rubricCounttTwo])

  // store in localstorage

  // useEffect(() => {
  //   localStorage.setItem('Multi_Assingment', onChangeAssignmentData)
  // }, [onChangeAssignmentData])


  // useEffect(() => {
  //   localStorage.setItem('Multi_Rubric', onChangeRubricData)
  // }, [onChangeRubricData])



  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [updateFuntion, setUpdateFuntion] = useState(false);
  const [isRubricConfirmModalData, setIsRubricConfirmModalData] = useState({
    selectedRubric: null,
    selectedRubricIndex: null
  });
  const [isAssingmentConfirmModalData, setIsAssingmentConfirmModalData] = useState({
    selectedAssingment: null,
    selectedAssingmentIndex: null
  });
  const [afterConfirmation, setAfterConfirmation] = useState(null);


  // const handleConfirm = () => {
  //   if (afterConfirmation === 'Rubric') {
  //     if (onChangeAssignmentData[assignmentsCountTwo] === undefined) {
  //       setAssignmentCounts(prevAssignments => prevAssignments.slice(0, -1));
  //       setSelectedAssignment(`Assignment ${assignmentsCount.length - 1}`)


  //       setSelectedRubric(isRubricConfirmModalData.selectedRubric);
  //       setRubricCountTwo(isRubricConfirmModalData.selectedRubricIndex);
  //       setShowDropdownRubric(false);
  //       setPreviesRubric(true)
  //       setUpdateFuntion(true)
  //       setActiveWorkboard(workboards.RUBRIC)
  //       const existingIds = JSON.parse(localStorage.getItem('Rubric_id'))
  //       setEachRubric_id(existingIds[isRubricConfirmModalData.selectedRubricIndex])
  //       updateWorkboardDataByName('Rubric', onChangeRubricData[isRubricConfirmModalData.selectedRubricIndex], 'previesRubric')

  //       setIsRubric(true)
  //       setPopupVisible(false)
  //       setIsAssignment(false)
  //       setFormState((prev) => ({
  //         ...prev,
  //         chat_query: "",
  //       }));


  //     } else {

  //       setSelectedRubric(isRubricConfirmModalData.selectedRubric);
  //       setRubricCountTwo(isRubricConfirmModalData.selectedRubricIndex);
  //       setShowDropdownRubric(false);
  //       setPreviesRubric(true)
  //       setUpdateFuntion(true)
  //       setActiveWorkboard(workboards.RUBRIC)
  //       const existingIds = JSON.parse(localStorage.getItem('Rubric_id'))
  //       setEachRubric_id(existingIds[isRubricConfirmModalData.selectedRubricIndex])
  //       updateWorkboardDataByName('Rubric', onChangeRubricData[isRubricConfirmModalData.selectedRubricIndex], 'previesRubric')

  //       setIsRubric(true)
  //       setPopupVisible(false)
  //       setIsAssignment(false)
  //       setFormState((prev) => ({
  //         ...prev,
  //         chat_query: "",
  //       }));
  //     }
  //   } else if (afterConfirmation === 'addRubric') {
  //     if (onChangeAssignmentData[assignmentsCountTwo] === undefined) {
  //       setAssignmentCounts(prevAssignments => prevAssignments.slice(0, -1));
  //       setSelectedAssignment(`Assignment ${assignmentsCount.length - 1}`)


  //       updateWorkboardDataByName('Rubric', '')
  //       const newRubric = `Rubric ${rubricCount.length + 1}`;
  //       setRubricCount([...rubricCount, newRubric]);
  //       setRubricCountTwo(rubricCount.length)
  //       setShowDropdownRubric(false);
  //       setSelectedRubric(newRubric)
  //       setPreviesRubric(false)
  //       setActiveWorkboard(workboards.RUBRIC)
  //       setIsRubric(true)
  //       setUpdateFuntion(true)
  //       setPopupVisible(false)
  //       setIsAssignment(false)
  //       setFormState((prev) => ({
  //         ...prev,
  //         chat_query: "",
  //       }));

  //     } else {
  //       updateWorkboardDataByName('Rubric', '')
  //       const newRubric = `Rubric ${rubricCount.length + 1}`;
  //       setRubricCount([...rubricCount, newRubric]);
  //       setRubricCountTwo(rubricCount.length)
  //       setShowDropdownRubric(false);
  //       setSelectedRubric(newRubric)
  //       setPreviesRubric(false)
  //       setActiveWorkboard(workboards.RUBRIC)
  //       setIsRubric(true)
  //       setUpdateFuntion(true)
  //       setPopupVisible(false)
  //       setIsAssignment(false)
  //       setFormState((prev) => ({
  //         ...prev,
  //         chat_query: "",
  //       }));
  //     }
  //   }


  //   if (afterConfirmation === 'Assignment') {
  //     if (onChangeRubricData[rubricCounttTwo] === undefined) {
  //       setRubricCount(prevRubric => prevRubric.slice(0, -1));
  //       setSelectedRubric(`Rubric ${rubricCount.length - 1}`)

  //       setSelectedAssignment(isAssingmentConfirmModalData.selectedAssingment);
  //       setAssignmentCountsTwo(isAssingmentConfirmModalData.selectedAssingmentIndex);
  //       setShowDropdown(false);
  //       setPreviesAssignment(true)
  //       setShowAssingment(false);
  //       setUpdateFuntion(true)
  //       setActiveWorkboard(workboards.ASSIGNMENT)
  //       const existingIds = JSON.parse(localStorage.getItem('Assingment_Id'))
  //       setEachAssignment_id(existingIds[isAssingmentConfirmModalData.selectedAssingmentIndex])
  //       updateWorkboardDataByName('Assignment', onChangeAssignmentData[isAssingmentConfirmModalData.selectedAssingmentIndex])
  //       setPopupVisible(false)
  //       setIsRubric(false)
  //       setIsAssignment(true)
  //       setFormState((prev) => ({
  //         ...prev,
  //         chat_query: "",
  //       }));
  //     } else {
  //       setSelectedAssignment(isAssingmentConfirmModalData.selectedAssingment);
  //       setAssignmentCountsTwo(isAssingmentConfirmModalData.selectedAssingmentIndex);
  //       setShowDropdown(false);
  //       setPreviesAssignment(true)
  //       setShowAssingment(false);
  //       setUpdateFuntion(true)
  //       setActiveWorkboard(workboards.ASSIGNMENT)
  //       const existingIds = JSON.parse(localStorage.getItem('Assingment_Id'))
  //       setEachAssignment_id(existingIds[isAssingmentConfirmModalData.selectedAssingmentIndex])
  //       updateWorkboardDataByName('Assignment', onChangeAssignmentData[isAssingmentConfirmModalData.selectedAssingmentIndex])
  //       setPopupVisible(false)
  //       setIsRubric(false)
  //       setIsAssignment(true)
  //       setFormState((prev) => ({
  //         ...prev,
  //         chat_query: "",
  //       }));
  //     }
  //   } else if (afterConfirmation === 'addAssingment') {
  //     if (onChangeRubricData[rubricCounttTwo] === undefined) {
  //       setRubricCount(prevRubric => prevRubric.slice(0, -1));
  //       setSelectedRubric(`Rubric ${rubricCount.length - 1}`)

  //       updateDefaultWorkboardData(defaultWorkboardData.ASSIGNMENT.name, '')
  //       updateWorkboardDataByName('Assignment', '')

  //       const newAssignment = `Assignment ${assignmentsCount.length + 1}`;
  //       setAssignmentCounts([...assignmentsCount, newAssignment]);
  //       setAssignmentCountsTwo(assignmentsCount.length)
  //       setShowAssingment(false)
  //       setSelectedAssignment(newAssignment)
  //       setPreviesAssignment(false)
  //       setUpdateFuntion(true)
  //       setActiveWorkboard(workboards.ASSIGNMENT)
  //       setIsRubric(false)
  //       setPopupVisible(true)
  //       setIsAssignment(true)
  //       if (!checkAssignmentopen) {
  //         setFormState((prev) => ({
  //           ...prev,
  //           chat_query: "Create Assignment" + ' ' + (assignmentsCount.length + 1),
  //         }));
  //       }
  //     } else {
  //       updateDefaultWorkboardData(defaultWorkboardData.ASSIGNMENT.name, '')
  //       updateWorkboardDataByName('Assignment', '')

  //       const newAssignment = `Assignment ${assignmentsCount.length + 1}`;
  //       setAssignmentCounts([...assignmentsCount, newAssignment]);
  //       setAssignmentCountsTwo(assignmentsCount.length)
  //       setShowAssingment(false)
  //       setSelectedAssignment(newAssignment)
  //       setPreviesAssignment(false)
  //       setActiveWorkboard(workboards.ASSIGNMENT)
  //       setIsRubric(false)
  //       setUpdateFuntion(true)
  //       setPopupVisible(true)
  //       setIsAssignment(true)
  //       if (!checkAssignmentopen) {
  //         setFormState((prev) => ({
  //           ...prev,
  //           chat_query: "Create Assignment" + ' ' + (assignmentsCount.length + 1),
  //         }));
  //       }
  //     }
  //   }

  //   if (afterConfirmation === "Lesson") {
  //     if (activeWorkboard.name === "Assignment") {
  //       if (onChangeAssignmentData[assignmentsCountTwo] === undefined) {
  //         setAssignmentCounts(prevAssingment => prevAssingment.slice(0, -1));
  //         setSelectedAssignment(`Assignment ${assignmentsCount.length - 1}`)


  //         setIsAssignment(false)
  //         setIsRubric(false)
  //         setUpdateFuntion(true);
  //         setActiveWorkboard(workboards.LESSON)
  //       } else {
  //         setIsAssignment(false)
  //         setIsRubric(false)
  //         setUpdateFuntion(true);
  //         setActiveWorkboard(workboards.LESSON)
  //       }
  //     } else if (activeWorkboard.name === 'Rubric') {
  //       if (onChangeRubricData[rubricCounttTwo] === undefined) {
  //         setRubricCount(prevRubric => prevRubric.slice(0, -1));
  //         setSelectedRubric(`Rubric ${rubricCount.length - 1}`)


  //         setIsAssignment(false)
  //         setIsRubric(false)
  //         setUpdateFuntion(true);
  //         setActiveWorkboard(workboards.LESSON)
  //       } else {
  //         setIsAssignment(false)
  //         setIsRubric(false)
  //         setUpdateFuntion(true);
  //         setActiveWorkboard(workboards.LESSON)
  //       }
  //     }


  //   }

  //   if (afterConfirmation === 'LessonToAssignment') {
  //     setSelectedAssignment(isAssingmentConfirmModalData.selectedAssingment);
  //     setAssignmentCountsTwo(isAssingmentConfirmModalData.selectedAssingmentIndex);
  //     setIsAssignment(true)
  //     setIsRubric(false)
  //     setUpdateFuntion(true)
  //     setActiveWorkboard(workboards.ASSIGNMENT)
  //   } else if (afterConfirmation === 'LessonToAddAssignment') {
  //     // setSelectedAssignment(isAssingmentConfirmModalData.selectedAssingment);
  //     // setAssignmentCountsTwo(isAssingmentConfirmModalData.selectedAssingmentIndex);

  //     updateDefaultWorkboardData(defaultWorkboardData.ASSIGNMENT.name, '')
  //     updateWorkboardDataByName('Assignment', '')

  //     const newAssignment = `Assignment ${assignmentsCount.length + 1}`;
  //     setAssignmentCounts([...assignmentsCount, newAssignment]);
  //     setAssignmentCountsTwo(assignmentsCount.length)
  //     setShowAssingment(false)
  //     setSelectedAssignment(newAssignment)
  //     setPreviesAssignment(false)
  //     setUpdateFuntion(true)
  //     setActiveWorkboard(workboards.ASSIGNMENT)
  //     setIsRubric(false)
  //     setPopupVisible(true)
  //     setIsAssignment(true)
  //     if (!checkAssignmentopen) {
  //       setFormState((prev) => ({
  //         ...prev,
  //         chat_query: "Create Assignment" + ' ' + (assignmentsCount.length + 1),
  //       }));
  //     }
  //   }

  //   if (afterConfirmation === 'LessonToRubric') {
  //     setSelectedRubric(isRubricConfirmModalData.selectedRubric);
  //     setRubricCountTwo(isRubricConfirmModalData.selectedRubricIndex);
  //     setIsAssignment(false)
  //     setIsRubric(true)
  //     setUpdateFuntion(true)
  //     setActiveWorkboard(workboards.RUBRIC)
  //   } else if (afterConfirmation === 'LessonToAddRubric') {
  //     updateWorkboardDataByName('Rubric', '')
  //     const newRubric = `Rubric ${rubricCount.length + 1}`;
  //     setRubricCount([...rubricCount, newRubric]);
  //     setRubricCountTwo(rubricCount.length)
  //     setShowDropdownRubric(false);
  //     setSelectedRubric(newRubric)
  //     setPreviesRubric(false)
  //     setActiveWorkboard(workboards.RUBRIC)
  //     setIsRubric(true)
  //     setUpdateFuntion(true)
  //     setPopupVisible(false)
  //     setIsAssignment(false)
  //     setFormState((prev) => ({
  //       ...prev,
  //       chat_query: "",
  //     }));
  //   }


  //   setIsConfirmModalOpen(false);
  //   setShowAssingment(false)
  //   setShowDropdownRubric(false)
  // };


  // const handleCancel = () => {
  //   if (afterConfirmation === 'Rubric' || afterConfirmation === 'addRubric') {
  //     setIsAssignment(true)
  //     setActiveWorkboard(workboards.ASSIGNMENT)
  //   } else if (afterConfirmation === 'Assignment' || afterConfirmation === 'addAssingment') {
  //     setIsRubric(true)
  //     setActiveWorkboard(workboards.RUBRIC)
  //   } else if (afterConfirmation === "Lesson") {
  //     if (activeWorkboard.name === 'Assignment') {
  //       setIsAssignment(true)
  //       setIsRubric(false)
  //       setActiveWorkboard(workboards.ASSIGNMENT)
  //     }
  //     if (activeWorkboard.name === 'Rubric') {
  //       setIsAssignment(false)
  //       setIsRubric(true)
  //       setActiveWorkboard(workboards.RUBRIC)
  //     }
  //   } else if (afterConfirmation === 'LessonToAssignment' || afterConfirmation === 'LessonToAssignment') {
  //     setIsAssignment(false)
  //     setIsRubric(false)
  //     setActiveWorkboard(workboards.LESSON)
  //   } else if (afterConfirmation === 'LessonToRubric' || afterConfirmation === 'LessonToAddRubric') {
  //     setIsAssignment(false)
  //     setIsRubric(false)
  //     setActiveWorkboard(workboards.LESSON)
  //   }
  //   setIsConfirmModalOpen(false);
  //   setShowAssingment(false)
  //   setShowDropdownRubric(false)
  //   // Additional logic when cancellation occurs
  // };


  const handleSavePopUp = () => {
    handleAssinmentRubricSubmit()
    setIsNotSavePopUp(false)
    setCallAddAssignmentFun(true)
  }
  const handleCancelPopUp = () => {
    setIsNotSavePopUp(false)
    setCallAddAssignmentFun(true)
  }



  function UpdateActiveWorkbaordPlugin({ chat, setAssignmentCounts, assignmentsCount, showAssingment, setShowAssingment, selectedAssignment, setSelectedAssignment,
    onChangeAssignmentData, setOnChangeAssignmentData, setAssignmentCountsTwo, editWorkboardStatues, setEditWorkboardStatues, assignmentsCountTwo, showDropdownRubric,
    setShowDropdownRubric, rubricCount, setRubricCount, rubricCounttTwo, setRubricCountTwo, onChangeRubricData, setOnChangeRubricData, selectedRubric, setSelectedRubric,
    previesCountAssignment, setPreviesAssignment, setPreviesRubric, setFormAssignmentState, updateFuntion, setUpdateFuntion, isRubricConfirmModalData, setIsRubricConfirmModalData,
    afterConfirmation, setAfterConfirmation, isAssingmentConfirmModalData, setIsAssingmentConfirmModalData, callAddAssignmentFun, setCallAddAssignmentFun }) {

    const [editor] = useLexicalComposerContext();



    function update(workboard, workboardName, previesAssignment, removeWorkboardAssignment) {
      if (previesAssignment === 'previesAssignment' || previesAssignment === 'previesRubric') {
        setActiveWorkboard({
          name: workboardName,
          data: workboard
        });
      } else {
        setActiveWorkboard(workboard);
      }
      if (workboardName === workboards.ASSIGNMENT.name) {
        // setPopupVisible(true);
        setIsAssignment(true);

        // if (!checkAssignmentopen) {
        //   setFormState((prev) => ({
        //     ...prev,
        //     // chat_query: "Create Assignment" + ' ' + (assignmentsCountTwo),
        //     chat_query: "Create Assignment",
        //   }));
        //   // setActiveTab("lesson")
        // }

        setIsRubric(false);
      } else if (workboardName === workboards.RUBRIC.name) {
        setPopupVisible(false);
        setIsAssignment(false);
        setIsRubric(true);
        setFormState((prev) => ({
          ...prev,
          chat_query: "",
        }));
      } else if (workboardName === workboards.LESSON.name) {

        setPopupVisible(false);
        setIsAssignment(false);
        setIsRubric(false);
        setFormState((prev) => ({
          ...prev,
          chat_query: "",
        }));

        // if (workboards?.RUBRIC?.data === onChangeRubricData[rubricCounttTwo]) {
        //   setPopupVisible(false);
        //   setIsAssignment(false);
        //   setIsRubric(false);
        //   setFormState((prev) => ({
        //     ...prev,
        //     chat_query: "",
        //   }));
        // } else if (workboards?.RUBRIC?.data !== onChangeRubricData[rubricCounttTwo]) {
        //   setAfterConfirmation('intoLesson')
        //   setIsConfirmModalOpen(true)
        //   setActiveWorkboard(workboards.RUBRIC)
        // } else if (workboards?.ASSIGNMENT?.data === onChangeAssignmentData[assignmentsCountTwo]) {
        //   setPopupVisible(false);
        //   setIsAssignment(false);
        //   setIsRubric(false);
        //   setFormState((prev) => ({
        //     ...prev,
        //     chat_query: "",
        //   }));
        // } else if (workboards?.ASSIGNMENT?.data === onChangeAssignmentData[assignmentsCountTwo]) {
        //   setAfterConfirmation('intoLesson')
        //   setIsConfirmModalOpen(true)
        //   setActiveWorkboard(workboards.ASSIGNMENT)
        // } else {
        //   setPopupVisible(false);
        //   setIsAssignment(false);
        //   setIsRubric(false);
        //   setFormState((prev) => ({
        //     ...prev,
        //     chat_query: "",
        //   }));
        // }

      }
      editor.update(() => {
        //clear the editor and update it with the active workboard data
        // Get the RootNode from the EditorState
        const root = $getRoot();
        // Clear the root node
        root.clear();

        // Get the active workboard data
        if (previesAssignment === 'previesAssignment' || previesAssignment === 'previesRubric') {
          var activeWorkboardData = workboard;
        } else {
          var activeWorkboardData = workboard.data;
        }

        if (workboardName === 'Assignment') {
          if (previesAssignment !== 'previesAssignment' && removeWorkboardAssignment !== 'removeWorkboardAssignment') {
            if (activeWorkboardData.length > 2) {

              const getassignmentdetailsCondition = getassignmentdetails[assignmentsCountTwo] !== undefined

              if (getassignmentdetailsCondition) {
                if ((formAssignmentState.name !== '' && formAssignmentState.name !== null &&
                  getassignmentdetails[assignmentsCountTwo].name !== '' && getassignmentdetails[assignmentsCountTwo].name !== null)
                  &&
                  (formAssignmentState.type !== null && getassignmentdetails[assignmentsCountTwo].type !== null) &&
                  (formAssignmentState.duration_minutes !== 0 && formAssignmentState.duration_minutes !== null &&
                    getassignmentdetails[assignmentsCountTwo].duration_minutes !== 0 && getassignmentdetails[assignmentsCountTwo].duration_minutes !== null)) {
                  setOnChangeAssignmentData(prev => {
                    const newState = [...prev]; // Create a copy of the previous state array
                    // newState[assignmentsCountTwo]['data'] = workboards.ASSIGNMENT?.data; // Update the specific index
                    // newState[assignmentsCountTwo]['assignmentid'] = null;
                    newState[assignmentsCountTwo] = workboards.ASSIGNMENT.data;
                    return newState; // Return the new state
                  });

                }
              } else {
                if ((formAssignmentState.name !== '' && formAssignmentState.name !== null)
                  &&
                  (formAssignmentState.type !== null) &&
                  (formAssignmentState.duration_minutes !== 0 && formAssignmentState.duration_minutes !== null)) {
                  setOnChangeAssignmentData(prev => {
                    const newState = [...prev]; // Create a copy of the previous state array
                    // newState[assignmentsCountTwo]['data'] = workboards.ASSIGNMENT?.data; // Update the specific index
                    // newState[assignmentsCountTwo]['assignmentid'] = null;
                    newState[assignmentsCountTwo] = workboards.ASSIGNMENT.data;
                    return newState; // Return the new state
                  });
                }
              }


            }
          }

          if (removeWorkboardAssignment === 'removeWorkboardAssignment') {
            activeWorkboardData = ''
          }
        }


        if (workboardName === 'Rubric') {
          if (previesAssignment !== 'previesRubric' && removeWorkboardAssignment !== 'removeWorkboardRubric') {
            if (activeWorkboardData.length > 2) {

              setOnChangeRubricData(prev => {
                const newState = [...prev]; // Create a copy of the previous state array
                // newState[rubricCounttTwo]['data'] = workboards.RUBRIC?.data; // Update the specific index
                // newState[rubricCounttTwo]['rubricid'] = null; // Update the specific index
                newState[rubricCounttTwo] = workboards.RUBRIC?.data; // Update the specific index
                return newState; // Return the new state
              });
            }
          }

          if (removeWorkboardAssignment === 'removeWorkboardRubric') {
            activeWorkboardData = ''
          }
        }





        // Parse the HTML string into a DOM
        const parser = new DOMParser();
        const dom = parser.parseFromString(activeWorkboardData, "text/html");

        // Generate nodes from the DOM
        const nodes = $generateNodesFromDOM(editor, dom);



        // Insert the nodes into the root node
        try {
          $insertNodes(nodes);
        } catch (error) {
          console.error(error);
          const root = $getRoot();
          const p = $createParagraphNode();
          p.append($createTextNode(activeWorkboardData));
          root.append(nodes);
        }

        // Select the root node
        root.select();
      });
    }


    if (updateFuntion) {
      if (afterConfirmation === 'addRubric' || afterConfirmation === 'LessonToAddRubric') {
        update('', 'Rubric', 'previesAssignment', 'removeWorkboardRubric');
      } else if (afterConfirmation === 'Rubric' || afterConfirmation === 'LessonToRubric') {
        update(onChangeRubricData[isRubricConfirmModalData.selectedRubricIndex], 'Rubric', 'previesRubric')
      } else if (afterConfirmation === 'Assignment' || afterConfirmation === 'LessonToAssignment') {
        update(onChangeAssignmentData[isAssingmentConfirmModalData.selectedAssingmentIndex], 'Assignment', 'previesAssignment')
      } else if (afterConfirmation === 'addAssingment' || afterConfirmation === 'LessonToAddAssignment') {
        update('', 'Assignment', 'previesAssignment', 'removeWorkboardAssignment');
      } else if (afterConfirmation === 'Lesson') {
        update(lesson?.description, 'Lesson', 'previesAssignment', 'removeWorkboardAssignment');
      }

      setUpdateFuntion(false)
    }



    // if (editWorkboardStatues) {
    //   // update(onChangeAssignmentData[assignmentsCountTwo], 'Assignment', 'previesAssignment', 'removeWorkboardAssignment');
    // }

    if (clickSaveBTN) {
      const workboardItem = Object.values(workboards).find(item => item.name === 'Assignment');
      update(workboardItem, 'Assignment');
      setClickSaveBTN(false);
    }

    if (clickSaveRubricBTN) {
      const workboardItem = Object.values(workboards).find(item => item.name === 'Rubric');
      update(workboardItem, 'Rubric');
      setClickSaveRubricBTN(false);
    }


    const activeTabBgColor = {
      background: "#cfe5fb",
      // You can add other styles here if needed
    };


    const [showDropdown,] = useState(false);

    // const [selectedRubric, setSelectedRubric] = useState('Rubric');

    const dropdownRef = useRef(null);
    const dropdownRubricRef = useRef(null);


    // useEffect(() => {
    //   const handleClickOutside = (event) => {
    //     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
    //       // setShowDropdown(false);
    //     }
    //     if (dropdownRubricRef.current && !dropdownRubricRef.current.contains(event.target)) {
    //       setShowDropdownRubric(false);
    //     }
    //   };

    //   document.addEventListener('mousedown', handleClickOutside);
    //   return () => {
    //     document.removeEventListener('mousedown', handleClickOutside);
    //   };
    // }, []);

    const handleAddAssignment = () => {


      if ((assignmentsCount.length - 1) === assignmentsCountTwo) {
        const globleInputData = {
          data: workboards.ASSIGNMENT.data,
          Id: null
        }




        const globleOnChange = JSON.parse(localStorage.getItem("globleOnChangeData"));


        const globleSaveDataUpdate = globleOnChange.ASSIGNMENT.data

        globleSaveDataUpdate.push(workboards.ASSIGNMENT.data)

        globleOnChange.ASSIGNMENT.data = globleSaveDataUpdate


        setGlobleOnChange(globleOnChange)

        localStorage.setItem("globleOnChangeData", JSON.stringify(globleOnChange));


        // // updateGlobleOnChangeData(globleOnChange.ASSIGNMENT.name,globleInputData, 'formAssignmentState_true', formAssignmentState)

      }
      updateDefaultWorkboardData(defaultWorkboardData.ASSIGNMENT.name, '')

      const newAssignment = `Assignment ${assignmentsCount.length + 1}`;
      setAssignmentCounts([...assignmentsCount, newAssignment]);
      setAssignmentCountsTwo(assignmentsCount.length)
      setShowAssingment(false)
      setSelectedAssignment(newAssignment)
      setActiveTab('lesson')
      setPreviesAssignment(false)
      console.log("new assignment added");
      // updateDefaultWorkboardData('Assignment','')


      setPopupVisible(true)
      setIsAssignment(true)
      if (!checkAssignmentopen) {
        setFormState((prev) => ({
          ...prev,
          chat_query: "Create Assignment" + ' ' + (assignmentsCount.length + 1),
        }));
      }



      setFormAssignmentState(prevState => ({
        ...prevState,
        name: '',
        duration_minutes: null,
        start_date: dayjs().format("YYYY-MM-DD"),
        type: null,
      }));



      const sumOfCount = 5 * assignmentDurationCount


      assignmentDurationChangeTime(-sumOfCount)


      localStorage.setItem('assignmentDurationCount', 0);


    };

    if (callAddAssignmentFun) {
      handleAddAssignment()
      setCallAddAssignmentFun(false)
    }



    const toggleAssingmentDropdown = () => {
      // console.log("toggleDropdown called", !showDropdown);
      // setShowDropdown(!showDropdown);
      // setShowAssingment(prev => !prev);
      // setActiveWorkboard(workboards.ASSIGNMENT);
      // if (Object.entries(lesson).length === 0) {
      //   alert('save the lesson')

      // } else 
      // if (Object.entries(lesson).length !== 0) {

      //   if (workboards.LESSON.data === lesson.description) { 
      //     setShowAssingment(prev => !prev);
      //   } else if (workboards.LESSON.data !== lesson.description) {
      //     setAfterConfirmation('Lesson')
      //     setIsConfirmModalOpen(true)
      //   }

      // }
      // if (Object.entries(lesson).length !== 0) {
      setShowAssingment(prev => !prev);
      // }




    };

    // const toggleDropdown = (workboardName) => {
    //   setDropdownVisibility(prevState => ({
    //     ...prevState,
    //     [workboardName]: !prevState[workboardName]
    //   }));
    // };



    const handleAddRubric = () => {

      if ((rubricCounttTwo.length - 1) === rubricCounttTwo) {
        const globleInputData = {
          data: workboards.RUBRIC.data,
          Id: null
        }
        updateGlobleOnChangeData(globleOnChange.RUBRIC.name, globleInputData)
      }

      updateDefaultWorkboardData(defaultWorkboardData.RUBRIC.name, '')

      const newRubric = `Rubric ${rubricCount.length + 1}`;
      setRubricCount([...rubricCount, newRubric]);
      setRubricCountTwo(rubricCount.length)
      setShowDropdownRubric(false);
      setSelectedRubric(newRubric)
      setPreviesRubric(false)
      setActiveWorkboard(workboards.RUBRIC)
      setActiveTab('lesson')

      setPopupVisible(false)
      setIsAssignment(false)
      setFormState((prev) => ({
        ...prev,
        chat_query: "",
      }));
    };

    const toggleDropdownRubric = () => {
      if (Object.entries(lesson).length !== 0) {
        setShowDropdownRubric(!showDropdownRubric);
      }
    };



    const handleAssignmentClick = (assignment, index) => {
      // if (activeWorkboard.name === 'Rubric') {
      //   if (workboards?.RUBRIC?.data === onChangeRubricData[rubricCounttTwo]) {
      //     setSelectedAssignment(assignment);
      //     setAssignmentCountsTwo(index)
      //     setShowDropdown(false);
      //     setShowAssingment(false);
      //     setPreviesAssignment(true)
      //     // setassignment_id
      //     const existingIds = JSON.parse(localStorage.getItem('Assingment_Id'))
      //     setEachAssignment_id(existingIds[index])
      //     update(onChangeAssignmentData[index], 'Assignment', 'previesAssignment')
      //     setActiveWorkboard(workboards.ASSIGNMENT)
      //     updateWorkboardDataByName('Assignment', onChangeAssignmentData[index])
      //     setPopupVisible(false)
      //     setIsAssignment(true)
      //     setFormState((prev) => ({
      //       ...prev,
      //       chat_query: "",
      //     }));
      //   } else if (workboards?.RUBRIC?.data !== onChangeRubricData[rubricCounttTwo]) {
      //     // setAfterConfirmation('Assignment')
      //     // setIsAssingmentConfirmModalData({
      //     //   selectedAssingment: assignment,
      //     //   selectedAssingmentIndex: index
      //     // })
      //     // setIsConfirmModalOpen(true)

      //     setSelectedAssignment(assignment);
      //     setAssignmentCountsTwo(index)
      //     setShowDropdown(false);
      //     setShowAssingment(false);
      //     setPreviesAssignment(true)
      //     // setassignment_id
      //     const existingIds = JSON.parse(localStorage.getItem('Assingment_Id'))
      //     setEachAssignment_id(existingIds[index])
      //     update(onChangeAssignmentData[index], 'Assignment', 'previesAssignment')
      //     setActiveWorkboard(workboards.ASSIGNMENT)
      //     updateWorkboardDataByName('Assignment', onChangeAssignmentData[index])
      //     setPopupVisible(false)
      //     setIsAssignment(true)
      //     setFormState((prev) => ({
      //       ...prev,
      //       chat_query: "",
      //     }));

      //   }
      // } else if (activeWorkboard.name === 'Lesson') {
      //   if (workboards.LESSON.data === lesson?.description) {
      //     setSelectedAssignment(assignment);
      //     setAssignmentCountsTwo(index)
      //     setShowDropdown(false);
      //     setShowAssingment(false);
      //     setPreviesAssignment(true)
      //     // setassignment_id
      //     const existingIds = JSON.parse(localStorage.getItem('Assingment_Id'))
      //     setEachAssignment_id(existingIds[index])
      //     update(onChangeAssignmentData[index], 'Assignment', 'previesAssignment')
      //     setActiveWorkboard(workboards.ASSIGNMENT)
      //     updateWorkboardDataByName('Assignment', onChangeAssignmentData[index])
      //     setPopupVisible(false)
      //     setIsAssignment(true)
      //     setFormState((prev) => ({
      //       ...prev,
      //       chat_query: "",
      //     }));
      //   } else {
      //     // setAfterConfirmation('LessonToAssignment')
      //     // setIsAssingmentConfirmModalData({
      //     //   selectedAssingment: assignment,
      //     //   selectedAssingmentIndex: index
      //     // })
      //     // setIsConfirmModalOpen(true)
      //     setSelectedAssignment(assignment);
      //     setAssignmentCountsTwo(index)
      //     setShowDropdown(false);
      //     setShowAssingment(false);
      //     setPreviesAssignment(true)
      //     // setassignment_id
      //     const existingIds = JSON.parse(localStorage.getItem('Assingment_Id'))
      //     setEachAssignment_id(existingIds[index])
      //     update(onChangeAssignmentData[index], 'Assignment', 'previesAssignment')
      //     setActiveWorkboard(workboards.ASSIGNMENT)
      //     updateWorkboardDataByName('Assignment', onChangeAssignmentData[index])
      //     setPopupVisible(false)
      //     setIsAssignment(true)
      //     setFormState((prev) => ({
      //       ...prev,
      //       chat_query: "",
      //     }));
      //   }

      // } else {
      //   setSelectedAssignment(assignment);
      //   setAssignmentCountsTwo(index)
      //   setShowDropdown(false);
      //   setShowAssingment(false);
      //   setPreviesAssignment(true)
      //   // setassignment_id
      //   const existingIds = JSON.parse(localStorage.getItem('Assingment_Id'))
      //   setEachAssignment_id(existingIds[index])
      //   update(onChangeAssignmentData[index], 'Assignment', 'previesAssignment')
      //   setActiveWorkboard(workboards.ASSIGNMENT)
      //   updateWorkboardDataByName('Assignment', onChangeAssignmentData[index])
      //   setPopupVisible(false)
      //   setIsAssignment(true)
      //   setFormState((prev) => ({
      //     ...prev,
      //     chat_query: "",
      //   }));
      // }

      const globleOnChangeData = JSON.parse(localStorage.getItem('globleOnChangeData'))


      if (assignmentsCount.length === (Number(assignmentsCountTwo) + 1)) {
        // if (globleOnChange.ASSIGNMENT.formAssignment[assignmentsCountTwo] === undefined) {
        if (globleOnChange.ASSIGNMENT.formAssignment === undefined) {
          const globleInputData = {
            data: workboards.ASSIGNMENT.data,
            Id: null
          }
          updateGlobleOnChangeData(globleOnChange.ASSIGNMENT.name, globleInputData, 'formAssignmentState_true', formAssignmentState)
        }
      }

      setSelectedAssignment(assignment);
      setAssignmentCountsTwo(index)
      setShowDropdown(false);
      setShowAssingment(false);

      // if (workboards.ASSIGNMENT.data === onChangeAssignmentData[index]) {
      //   const existingIds = JSON.parse(localStorage.getItem('Assingment_Id'))
      //   setPreviesAssignment(true)
      //   setEachAssignment_id(existingIds[index])
      // }


      if (globleSaveData.ASSIGNMENT.data[index] !== undefined && globleSaveData.ASSIGNMENT.data[index] !== null) {
        if (globleSaveData.ASSIGNMENT.data[index].Id) {
          const GlobleSaveData = JSON.parse(localStorage.getItem('GlobleSaveData'))

          setPreviesAssignment(true)
          setEachAssignment_id(GlobleSaveData.ASSIGNMENT.data[index].Id)
          setassignment_id(GlobleSaveData.ASSIGNMENT.data[index].Id)
          setPopupVisible(false)
          if (!checkAssignmentopen) {
            setFormState((prev) => ({
              ...prev,
              chat_query: '',
            }));
          }


          setFormAssignmentState(prevState => ({
            ...prevState,
            name: GlobleSaveData.ASSIGNMENT.formSaveAssignment[index].name,
            duration_minutes: GlobleSaveData.ASSIGNMENT.formSaveAssignment[index].duration_minutes,
            start_date: dayjs().format("YYYY-MM-DD"),
            type: GlobleSaveData.ASSIGNMENT.formSaveAssignment[index].type,
            end_date: GlobleSaveData.ASSIGNMENT.formSaveAssignment[index].end_date
          }));
        }
      } else {



        const globleOnChangeData = JSON.parse(localStorage.getItem('globleOnChangeData'))

        if (globleOnChangeData.ASSIGNMENT.formAssignment[index] !== undefined && globleOnChangeData.ASSIGNMENT.formAssignment[index] !== null) {

          setPreviesAssignment(false)
          setEachAssignment_id(null)

          setPopupVisible(true)
          if (!checkAssignmentopen) {
            setFormState((prev) => ({
              ...prev,
              chat_query: "Create Assignment" + ' ' + (Number(index) + 1),
            }));
          }


          // setFormAssignmentState(prevState => ({
          //   ...prevState,
          //   name: '',
          //   duration_minutes: 0,
          //   start_date: dayjs().format("YYYY-MM-DD"),
          //   type: null,
          //   end_date: null
          // }));


          // const sumOfCount = 5 * assignmentDurationCount

          // // assignmentDurationChangeTime(-sumOfCount)
          // assignmentDurationChangeTime(sumOfCount)


          // localStorage.setItem('assignmentDurationCount', sumOfCount);



          setFormAssignmentState(prevState => ({
            ...prevState,
            name: globleOnChangeData.ASSIGNMENT.formAssignment[index].name,
            duration_minutes: globleOnChangeData.ASSIGNMENT.formAssignment[index].duration_minutes,
            start_date: dayjs().format("YYYY-MM-DD"),
            type: globleOnChangeData.ASSIGNMENT.formAssignment[index].type,
            end_date: globleOnChangeData.ASSIGNMENT.formAssignment[index].end_date
          }));

          // const sumOfCount = 5 * assignmentDurationCount
          // assignmentDurationChangeTime(-sumOfCount)
          // localStorage.setItem('assignmentDurationCount', 0);
        } else {
          setPopupVisible(true)
          setIsAssignment(true)
          if (!checkAssignmentopen) {
            setFormState((prev) => ({
              ...prev,
              chat_query: "Create Assignment" + ' ' + (Number(index) + 1),
            }));
          }



          setFormAssignmentState(prevState => ({
            ...prevState,
            name: '',
            duration_minutes: null,
            start_date: dayjs().format("YYYY-MM-DD"),
            type: null,
          }));



          const sumOfCount = 5 * assignmentDurationCount


          assignmentDurationChangeTime(-sumOfCount)


          localStorage.setItem('assignmentDurationCount', 0);

        }
      }





      // if (globleOnChangeData.ASSIGNMENT.data[index].Id !== undefined) {
      //   if (globleOnChangeData.ASSIGNMENT.data[index].Id) {
      //     setPreviesAssignment(true)
      //     setEachAssignment_id(globleOnChangeData.ASSIGNMENT.data[index].Id)
      //   }
      // }
      // setassignment_id('')



      // update(onChangeAssignmentData[index], 'Assignment', 'previesAssignment')
      if (globleOnChangeData.ASSIGNMENT.data[index].data !== undefined) {
        update(globleOnChangeData.ASSIGNMENT.data[index].data, 'Assignment', 'previesAssignment')
        updateWorkboardDataByName('Assignment', globleOnChangeData.ASSIGNMENT.data[index].data)
      } else {
        update(globleOnChangeData.ASSIGNMENT.data[index], 'Assignment', 'previesAssignment')
        updateWorkboardDataByName('Assignment', globleOnChangeData.ASSIGNMENT.data[index])
      }
      setActiveWorkboard(workboards.ASSIGNMENT)

      setIsAssignment(true)








      // if (globleOnChangeData.ASSIGNMENT.formAssignment.length > 0) {
      //   if (globleOnChangeData.ASSIGNMENT.formAssignment[index].name === '' || globleOnChangeData.ASSIGNMENT.formAssignment[index].type === null ||
      //     globleOnChangeData.ASSIGNMENT.formAssignment[index].duration_minutes === 0 || globleOnChangeData.ASSIGNMENT.formAssignment[index].end_date === null) {
      //     setPopupVisible(true)
      //     setIsAssignment(true)
      //     if (!checkAssignmentopen) {
      //       setFormState((prev) => ({
      //         ...prev,
      //         chat_query: "Create Assignment" + ' ' + (Number(index) + 1),
      //       }));
      //     }

      //     setFormAssignmentState(prevState => ({
      //       ...prevState,
      //       name: '',
      //       duration_minutes: null,
      //       start_date: dayjs().format("YYYY-MM-DD"),
      //       type: null,
      //     }));



      //     const sumOfCount = 5 * assignmentDurationCount
      //     assignmentDurationChangeTime(-sumOfCount)
      //     localStorage.setItem('assignmentDurationCount', 0);

      //   } else {
      //     setPopupVisible(false)
      //     setIsAssignment(true)
      //     setFormState((prev) => ({
      //       ...prev,
      //       chat_query: "",
      //     }));

      //     setFormAssignmentState(prevState => ({
      //       ...prevState,
      //       name: globleOnChangeData.ASSIGNMENT.formAssignment[index].name,
      //       duration_minutes: globleOnChangeData.ASSIGNMENT.formAssignment[index].duration_minutes,
      //       start_date: dayjs().format("YYYY-MM-DD"),
      //       type: globleOnChangeData.ASSIGNMENT.formAssignment[index].type,
      //       end_date: globleOnChangeData.ASSIGNMENT.formAssignment[index].end_date
      //     }));

      //   }
      // } else {
      //   setPopupVisible(true)
      //   setIsAssignment(true)
      //   if (!checkAssignmentopen) {
      //     setFormState((prev) => ({
      //       ...prev,
      //       chat_query: "Create Assignment" + ' ' + (Number(index) + 1),
      //     }));
      //   }

      //   setFormAssignmentState(prevState => ({
      //     ...prevState,
      //     name: '',
      //     duration_minutes: null,
      //     start_date: dayjs().format("YYYY-MM-DD"),
      //     type: null,
      //   }));



      //   const sumOfCount = 5 * assignmentDurationCount
      //   assignmentDurationChangeTime(-sumOfCount)
      //   localStorage.setItem('assignmentDurationCount', 0);
      // }


    };




    const handleRubricClick = (rubric, index) => {

      // // if (workboards?.ASSIGNMENT?.data === '<p class="editor-paragraph"><br></p>') {
      // if (activeWorkboard.name === 'Assignment') {
      //   if (workboards?.ASSIGNMENT?.data === onChangeAssignmentData[assignmentsCountTwo]) {
      //     setSelectedRubric(rubric);
      //     setRubricCountTwo(index);
      //     setShowDropdownRubric(false);
      //     update(onChangeRubricData[index], 'Rubric', 'previesRubric')
      //     // setPreviesRubric(true)
      //     setActiveWorkboard(workboards.RUBRIC)
      //     const existingIds = JSON.parse(localStorage.getItem('Rubric_id'))
      //     setEachRubric_id(existingIds[index])
      //     updateWorkboardDataByName('Rubric', onChangeRubricData[index], 'previesRubric')

      //     setPopupVisible(false)
      //     setIsAssignment(false)
      //     setFormState((prev) => ({
      //       ...prev,
      //       chat_query: "",
      //     }));
      //   }
      //   else if (workboards?.ASSIGNMENT?.data !== onChangeAssignmentData[assignmentsCountTwo]) {
      //     // if (previesAssignment === true) {
      //     setAfterConfirmation('Rubric')
      //     setIsRubricConfirmModalData({
      //       selectedRubric: rubric,
      //       selectedRubricIndex: index
      //     })
      //     setIsConfirmModalOpen(true)
      //     // } else {
      //     //   alert('save assingment before going Rubric')
      //     // }
      //   }
      // } else if (activeWorkboard.name === 'Lesson') {
      //   if (workboards.LESSON.data === (lesson?.description)) {
      //     setSelectedRubric(rubric);
      //     setRubricCountTwo(index);
      //     setShowDropdownRubric(false);
      //     update(onChangeRubricData[index], 'Rubric', 'previesRubric')
      //     // setPreviesRubric(true)
      //     setActiveWorkboard(workboards.RUBRIC)
      //     const existingIds = JSON.parse(localStorage.getItem('Rubric_id'))
      //     setEachRubric_id(existingIds[index])
      //     updateWorkboardDataByName('Rubric', onChangeRubricData[index], 'previesRubric')

      //     setPopupVisible(false)
      //     setIsAssignment(false)
      //     setFormState((prev) => ({
      //       ...prev,
      //       chat_query: "",
      //     }));
      //   } else {
      //     setAfterConfirmation('LessonToRubric')
      //     setIsRubricConfirmModalData({
      //       selectedRubric: rubric,
      //       selectedRubricIndex: index
      //     })
      //     setIsConfirmModalOpen(true)
      //   }
      // } else {
      //   setSelectedRubric(rubric);
      //   setRubricCountTwo(index);
      //   setShowDropdownRubric(false);
      //   update(onChangeRubricData[index], 'Rubric', 'previesRubric')
      //   // setPreviesRubric(true)
      //   setActiveWorkboard(workboards.RUBRIC)
      //   const existingIds = JSON.parse(localStorage.getItem('Rubric_id'))
      //   setEachRubric_id(existingIds[index])
      //   updateWorkboardDataByName('Rubric', onChangeRubricData[index], 'previesRubric')

      //   setPopupVisible(false)
      //   setIsAssignment(false)
      //   setFormState((prev) => ({
      //     ...prev,
      //     chat_query: "",
      //   }));
      // }





      // if (workboards.ASSIGNMENT.data === onChangeAssignmentData[index]) {
      //   const existingIds = JSON.parse(localStorage.getItem('Assingment_Id'))
      //   setPreviesAssignment(true)
      //   setEachAssignment_id(existingIds[index])
      // }




      // setassignment_id('')










      const globleOnChangeData = JSON.parse(localStorage.getItem('globleOnChangeData'))


      if (rubricCounttTwo.length === (Number(rubricCounttTwo) + 1)) {
        if (globleOnChange.RUBRIC.formAssignment[rubricCounttTwo] === undefined) {
          const globleInputData = {
            data: workboards.RUBRIC.data,
            Id: null
          }
          updateGlobleOnChangeData(globleOnChange.RUBRIC.name, globleInputData)
        }
      }

      setSelectedRubric(rubric);
      setRubricCountTwo(index);
      setShowDropdownRubric(false);



      // if (globleSaveData.RUBRIC.data[index].Id !== undefined && globleSaveData.RUBRIC.data[index].Id !== null) {
      //   if (globleSaveData.RUBRIC.data[index].Id) {
      //     setPreviesRubric(true)
      //     setEachRubric_id(globleOnChangeData.RUBRIC.data[index].Id)
      //   }
      // }

      if (globleSaveData.RUBRIC.data[index] !== undefined && globleSaveData.RUBRIC.data[index] !== null) {
        if (globleSaveData.RUBRIC.data[index].Id) {
          setPreviesRubric(true)
          setEachRubric_id(globleSaveData.RUBRIC.data[index].Id)

          setrubric_id(globleSaveData.RUBRIC.data[index].Id)
        }
      } else {
        setPreviesRubric(false)
        setEachRubric_id(null)
      }


      if (globleOnChangeData.RUBRIC.data[index].data !== undefined) {
        update(globleOnChangeData.RUBRIC.data[index].data, 'Rubric', 'previesRubric')
        updateWorkboardDataByName('Rubric', globleOnChangeData.RUBRIC.data[index].data)
      } else {
        update(globleOnChangeData.RUBRIC.data[index], 'Rubric', 'previesRubric')
        updateWorkboardDataByName('Rubric', globleOnChangeData.RUBRIC.data[index])
      }
      setActiveWorkboard(workboards.RUBRIC)

      setPopupVisible(false)
      setIsAssignment(false)
      setFormState((prev) => ({
        ...prev,
        chat_query: "",
      }));


    };



    return (
      <div className="edit-active-lesson-btn-container">
        {Object.values(workboards).map((workboard, index) => {
          return (
            <>
              {workboard.name === workboards.LESSON.name ?
                <div className="tab-dropdown">
                  <button
                    key={index} // It's important to provide a unique key for list items in React
                    type="button"
                    style={lessonbuttonStyle}
                    // className={`${activeWorkboard.name === workboard.name ? "active" : ""
                    //   } ${`${defaultWorkboardData.LESSON?.data !==
                    //     workboards.LESSON?.data
                    //     ? ""
                    //     : "completed"
                    //   }`
                    //   }`}
                    className={`dropbtn ${activeWorkboard.name === workboard.name ? "active" : ""} ${defaultWorkboardData.LESSON?.data !== workboards.LESSON?.data ? "" : "completed"}`}
                    // disabled={
                    //   typeof lesson_id === "undefined" ||
                    //   lesson_id === null ||
                    //   lesson_id === ""
                    // }
                    onClick={() => {
                      update(
                        Object.values(workboards).find(
                          (item) => item.name === 'Lesson'
                        ),
                        workboard?.name
                      )
                      // lessonFunction(workboard?.name)
                    }
                    }
                  > {workboard?.name}</button>
                </div>
                : ''}


              {workboard.name === workboards.ASSIGNMENT.name ? (
                <div className="tab-dropdown" ref={dropdownRef}>
                  {/* <button className="dropbtn" onClick={toggleAssingmentDropdown} style={{ cursor: Object.entries(lesson).length === 0 ? 'not-allowed' : 'auto', opacity: Object.entries(lesson).length === 0 ? '0.5' : '1' }} > */}
                  <button className="dropbtn"
                    onClick={toggleAssingmentDropdown}
                    disabled={Object.entries(lesson).length === 0 ||
                      (globalState.subdomain_exists === undefined ? (globalState.userCurrentPlan?.currentSubscription !== null ? (globalState.userCurrentPlan?.currentSubscription?.plan_data?.assignment_creation ? (globalState.userCurrentPlan?.currentSubscription?.remaining_credit === 0 ? true : false) : true) : true) : false)
                    }

                    style={{
                      opacity: Object.entries(lesson).length === 0 ||
                        (globalState.subdomain_exists === undefined ? (globalState.userCurrentPlan?.currentSubscription !== null ? (globalState.userCurrentPlan?.currentSubscription?.plan_data?.assignment_creation ? (globalState.userCurrentPlan?.currentSubscription?.remaining_credit === 0 ? '0.5' : '1') : '0.5') : '0.5') : '1')
                    }}
                    data-tooltip-id='Assignment-creation-validation'
                  >
                    {selectedAssignment}
                    <span style={{ transform: showAssingment ? 'rotate(180deg)' : 'rotate(0deg)', }} >
                      <i className="fa-solid fa-angle-down"></i>
                    </span>
                  </button>

                  <Tooltip
                    id="Assignment-creation-validation"
                    place="bottom"
                    content={(globalState.subdomain_exists === undefined ? (globalState.userCurrentPlan?.currentSubscription !== null ? (globalState.userCurrentPlan?.currentSubscription?.plan_data?.assignment_creation ? (globalState.userCurrentPlan?.currentSubscription?.remaining_credit === 0 ? "Upgrade Subscription Plan" : '') : "Upgrade Subscription Plan") : "Upgrade Subscription Plan") : '')}
                    type="light"
                    effect="float"
                  />

                  {
                    showAssingment && (
                      <div className="dropdown-content">
                        {assignmentsCount.map((assignment, index) => (
                          <div key={index} className="assignment-item" onClick={() => {
                            // if (activeWorkboard.name === 'Assignment') {
                            //   if (onChangeAssignmentData[assignmentsCountTwo] === workboards.ASSIGNMENT?.data) {
                            //     handleAssignmentClick(assignment, index)
                            //   } else {
                            //     (index !== assignmentsCountTwo) && alert('save assignment before going next one')
                            //   }
                            // } else {
                            //   handleAssignmentClick(assignment, index)
                            // }

                            // if (!checkAssignmentopen) {
                            //   // if (isAssignment ? (previesAssignment ? true : false) : false) {
                            //   // if (isAssignment && !previesAssignment) {
                            //   if (isAssignment) {
                            //     let errors = {};
                            //     if (isRequired(formAssignmentState?.name)) {
                            //       errors.name = "Name is required";
                            //     }
                            //     if (isRequired(formAssignmentState?.duration_minutes)) {
                            //       errors.duration_minutes = "Timer is required";
                            //     }
                            //     if (isRequired(formAssignmentState?.start_date)) {
                            //       errors.start_date = "Start date is required";
                            //     }
                            //     // if (isRequired(formAssignmentState?.end_date)) {
                            //     //   errors.end_date = "End date is required";
                            //     // }
                            //     if (isRequired(formAssignmentState?.type)) {
                            //       errors.type = "Type is required";
                            //     }
                            //     console.log("errors", errors);

                            //     setFormErrorAssignmentState(errors);

                            //     if (Object.values(errors).some((error) => error)) {
                            //       console.log("errors", errors);
                            //       return;
                            //     }
                            //   }
                            // }

                            const globleOnChangeData = JSON.parse(localStorage.getItem("globleOnChangeData"));


                            if (activeWorkboard.name === 'Assignment' && globleOnChangeData.ASSIGNMENT.data.length > globleOnChangeData.ASSIGNMENT.formAssignment.length) {
                              if (globleOnChangeData.ASSIGNMENT.formAssignment[assignmentsCountTwo] === undefined) {

                                const newFormAssignmentArrya = {
                                  name: formAssignmentState.name,
                                  assignment: null,
                                  assignment_json: null,
                                  lesson: null,
                                  duration_minutes: formAssignmentState.duration_minutes,
                                  start_date: formAssignmentState.start_date,
                                  end_date: formAssignmentState.end_date,
                                  type: formAssignmentState.type
                                };


                                const globleOnChange = JSON.parse(localStorage.getItem("globleOnChangeData"));

                                const globleOnChangeUpdateFormAssignment = globleOnChange.ASSIGNMENT.formAssignment
                                globleOnChangeUpdateFormAssignment.push(newFormAssignmentArrya)


                                globleOnChange.ASSIGNMENT.formAssignment = globleOnChangeUpdateFormAssignment
                                // // globleOnChange.ASSIGNMENT.formAssignment = newFormAssignmentArrya

                                setGlobleOnChange(globleOnChange)
                                // // setGlobleSaveData(globleSaveData)

                                localStorage.setItem("globleOnChangeData", JSON.stringify(globleOnChange));
                              } else {
                                const newFormAssignmentArrya = {
                                  name: formAssignmentState.name,
                                  assignment: null,
                                  assignment_json: null,
                                  lesson: null,
                                  duration_minutes: formAssignmentState.duration_minutes,
                                  start_date: formAssignmentState.start_date,
                                  end_date: formAssignmentState.end_date,
                                  type: formAssignmentState.type
                                };

                                const globleOnChangeUpdateFormAssignment = globleOnChange.ASSIGNMENT.formAssignment

                                globleOnChangeUpdateFormAssignment[assignmentsCountTwo] = newFormAssignmentArrya
                                globleOnChange.ASSIGNMENT.formAssignment = globleOnChangeUpdateFormAssignment

                                setGlobleOnChange(globleOnChange)
                                // // setGlobleSaveData(globleSaveData)

                                localStorage.setItem("globleOnChangeData", JSON.stringify(globleOnChange));

                              }
                            } else {
                              const newFormAssignmentArrya = {
                                name: formAssignmentState.name,
                                assignment: null,
                                assignment_json: null,
                                lesson: null,
                                duration_minutes: formAssignmentState.duration_minutes,
                                start_date: formAssignmentState.start_date,
                                end_date: formAssignmentState.end_date,
                                type: formAssignmentState.type
                              };

                              const globleOnChangeUpdateFormAssignment = globleOnChange.ASSIGNMENT.formAssignment

                              globleOnChangeUpdateFormAssignment[assignmentsCountTwo] = newFormAssignmentArrya
                              globleOnChange.ASSIGNMENT.formAssignment = globleOnChangeUpdateFormAssignment

                              setGlobleOnChange(globleOnChange)
                              // // setGlobleSaveData(globleSaveData)

                              localStorage.setItem("globleOnChangeData", JSON.stringify(globleOnChange));

                            }


                            handleAssignmentClick(assignment, index)
                          }}>
                            {assignment}
                          </div>
                        ))}
                        {/* <div id="add-assignment" onClick={handleAddAssignment}> */}


                        <div id="add-assignment" onClick={() => {
                          setEachAssignment_id(null)
                          setassignment_id('')
                          // if (activeWorkboard.name === 'Rubric') {

                          //   if (workboards?.RUBRIC?.data === onChangeRubricData[rubricCounttTwo]) {
                          //     if (activeWorkboard.name === 'Assignment') {
                          //       // setActiveWorkboard(workboards.ASSIGNMENT);
                          //       if (onChangeAssignmentData[assignmentsCountTwo] === workboards.ASSIGNMENT?.data) {
                          //         handleAddAssignment()
                          //         const workboardItem = Object.values(workboards).find(item => item.name === workboard.name);
                          //         update(workboardItem, workboard.name, 'no_previesAssignment', 'removeWorkboardAssignment');
                          //       } else {
                          //         alert('save assignment before going next one')
                          //       }
                          //     } else {
                          //       handleAddAssignment()
                          //       const workboardItem = Object.values(workboards).find(item => item.name === workboard.name);
                          //       update(workboardItem, workboard.name, 'no_previesAssignment', 'removeWorkboardAssignment');
                          //     }
                          //   } else if (workboards?.RUBRIC?.data !== onChangeRubricData[rubricCounttTwo]) {
                          //     // setAfterConfirmation('addAssingment')
                          //     // setIsAssingmentConfirmModalData({
                          //     //   selectedAssingment: `Assingment ${assignmentsCount.length + 1}`,
                          //     //   selectedAssingmentIndex: assignmentsCount.length
                          //     // })
                          //     // setIsConfirmModalOpen(true)

                          //     handleAddAssignment()
                          //     const workboardItem = Object.values(workboards).find(item => item.name === workboard.name);
                          //     update(workboardItem, workboard.name, 'no_previesAssignment', 'removeWorkboardAssignment');
                          //   }
                          // } else {
                          //   if (activeWorkboard.name === 'Lesson') {
                          //     if (workboards.LESSON.data === lesson?.description) {
                          //       handleAddAssignment()
                          //       const workboardItem = Object.values(workboards).find(item => item.name === workboard.name);
                          //       update(workboardItem, workboard.name, 'no_previesAssignment', 'removeWorkboardAssignment');
                          //     } else {
                          //       // setAfterConfirmation('LessonToAddAssignment')
                          //       // setIsAssingmentConfirmModalData({
                          //       //   selectedAssingment: `Assingment ${assignmentsCount.length + 1}`,
                          //       //   selectedAssingmentIndex: assignmentsCount.length
                          //       // })
                          //       // setIsConfirmModalOpen(true)

                          //       handleAddAssignment()
                          //       const workboardItem = Object.values(workboards).find(item => item.name === workboard.name);
                          //       update(workboardItem, workboard.name, 'no_previesAssignment', 'removeWorkboardAssignment');
                          //     }



                          //   } else if (activeWorkboard.name === 'Assignment') {
                          //     // setActiveWorkboard(workboards.ASSIGNMENT);
                          //     if (onChangeAssignmentData[assignmentsCountTwo] === workboards.ASSIGNMENT?.data) {
                          //       handleAddAssignment()
                          //       const workboardItem = Object.values(workboards).find(item => item.name === workboard.name);
                          //       update(workboardItem, workboard.name, 'no_previesAssignment', 'removeWorkboardAssignment');
                          //     } else {
                          //       alert('save assignment before going next one')
                          //     }
                          //   }

                          // }
                          // if (assignmentsCount.length >= 1) {
                          //   let errors = {};
                          //   if (isRequired(formAssignmentState?.name)) {
                          //     errors.name = "Name is required";
                          //   }
                          //   if (isRequired(formAssignmentState?.duration_minutes)) {
                          //     errors.duration_minutes = "Timer is required";
                          //   }
                          //   if (isRequired(formAssignmentState?.start_date)) {
                          //     errors.start_date = "Start date is required";
                          //   }
                          //   // if (isRequired(formAssignmentState?.end_date)) {
                          //   //   errors.end_date = "End date is required";
                          //   // }
                          //   if (isRequired(formAssignmentState?.type)) {
                          //     errors.type = "Type is required";
                          //   }
                          //   console.log("errors", errors);

                          //   setFormErrorAssignmentState(errors);

                          //   if (Object.values(errors).some((error) => error)) {
                          //     console.log("There are errors in the form", errors);
                          //     return;  // Ensure this stops further execution
                          //   } else {
                          //     handleAddAssignment()
                          //     const workboardItem = Object.values(workboards).find(item => item.name === workboard.name);
                          //     update(workboardItem, workboard.name, 'no_previesAssignment', 'removeWorkboardAssignment');
                          //   }
                          // } else {
                          //   handleAddAssignment()
                          //   const workboardItem = Object.values(workboards).find(item => item.name === workboard.name);
                          //   update(workboardItem, workboard.name, 'no_previesAssignment', 'removeWorkboardAssignment');
                          // }
                          // if (!previesAssignment && activeWorkboard.name === 'Assignment') {
                          //   setIsNotSavePopUp(true)
                          // } else {
                          // }

                          if (!checkAssignmentopen) {
                            // if (isAssignment ? (previesAssignment ? true : false) : false) {
                            // if (isAssignment && !previesAssignment) {
                            if (isAssignment) {
                              let errors = {};
                              if (isRequired(formAssignmentState?.name)) {
                                errors.name = "Name is required";
                              }
                              if (isRequired(formAssignmentState?.duration_minutes)) {
                                errors.duration_minutes = "Timer is required";
                              }
                              if (isRequired(formAssignmentState?.start_date)) {
                                errors.start_date = "Start date is required";
                              }
                              // if (isRequired(formAssignmentState?.end_date)) {
                              //   errors.end_date = "End date is required";
                              // }
                              if (isRequired(formAssignmentState?.type)) {
                                errors.type = "Type is required";
                              }
                              console.log("errors", errors);

                              setFormErrorAssignmentState(errors);

                              if (Object.values(errors).some((error) => error)) {
                                console.log("errors", errors);
                                return;
                              }
                            }
                          }

                          const globleOnChangeData = JSON.parse(localStorage.getItem("globleOnChangeData"));

                          if (activeWorkboard.name === 'Assignment' && globleOnChangeData.ASSIGNMENT.data.length > globleOnChangeData.ASSIGNMENT.formAssignment.length) {
                            if (globleOnChangeData.ASSIGNMENT.formAssignment[assignmentsCountTwo] === undefined) {

                              const newFormAssignmentArrya = {
                                name: formAssignmentState.name,
                                assignment: null,
                                assignment_json: null,
                                lesson: null,
                                duration_minutes: formAssignmentState.duration_minutes,
                                start_date: formAssignmentState.start_date,
                                end_date: formAssignmentState.end_date,
                                type: formAssignmentState.type
                              };


                              const globleOnChange = JSON.parse(localStorage.getItem("globleOnChangeData"));

                              const globleOnChangeUpdateFormAssignment = globleOnChange.ASSIGNMENT.formAssignment
                              globleOnChangeUpdateFormAssignment.push(newFormAssignmentArrya)


                              globleOnChange.ASSIGNMENT.formAssignment = globleOnChangeUpdateFormAssignment
                              // // globleOnChange.ASSIGNMENT.formAssignment = newFormAssignmentArrya

                              setGlobleOnChange(globleOnChange)
                              // // setGlobleSaveData(globleSaveData)

                              localStorage.setItem("globleOnChangeData", JSON.stringify(globleOnChange));
                            } else {
                              const newFormAssignmentArrya = {
                                name: formAssignmentState.name,
                                assignment: null,
                                assignment_json: null,
                                lesson: null,
                                duration_minutes: formAssignmentState.duration_minutes,
                                start_date: formAssignmentState.start_date,
                                end_date: formAssignmentState.end_date,
                                type: formAssignmentState.type
                              };
                              const globleOnChange = JSON.parse(localStorage.getItem("globleOnChangeData"));

                              const globleOnChangeUpdateFormAssignment = globleOnChange.ASSIGNMENT.formAssignment

                              globleOnChangeUpdateFormAssignment[assignmentsCountTwo] = newFormAssignmentArrya
                              globleOnChange.ASSIGNMENT.formAssignment = globleOnChangeUpdateFormAssignment

                              setGlobleOnChange(globleOnChange)
                              // // setGlobleSaveData(globleSaveData)

                              localStorage.setItem("globleOnChangeData", JSON.stringify(globleOnChange));
                            }
                          } else {
                            const newFormAssignmentArrya = {
                              name: formAssignmentState.name,
                              assignment: null,
                              assignment_json: null,
                              lesson: null,
                              duration_minutes: formAssignmentState.duration_minutes,
                              start_date: formAssignmentState.start_date,
                              end_date: formAssignmentState.end_date,
                              type: formAssignmentState.type
                            };
                            const globleOnChange = JSON.parse(localStorage.getItem("globleOnChangeData"));

                            const globleOnChangeUpdateFormAssignment = globleOnChange.ASSIGNMENT.formAssignment

                            globleOnChangeUpdateFormAssignment[assignmentsCountTwo] = newFormAssignmentArrya
                            globleOnChange.ASSIGNMENT.formAssignment = globleOnChangeUpdateFormAssignment

                            setGlobleOnChange(globleOnChange)
                            // // setGlobleSaveData(globleSaveData)

                            localStorage.setItem("globleOnChangeData", JSON.stringify(globleOnChange));
                          }

                          handleAddAssignment()


                          const workboardItem = Object.values(workboards).find(item => item.name === workboard.name);
                          update(workboardItem, workboard.name, 'no_previesAssignment', 'removeWorkboardAssignment');
                        }
                        }>
                          + Add Assignment
                        </div>
                      </div>
                    )
                  }


                </div >
              ) : ''}

              {workboard.name === workboards.RUBRIC.name ? (
                <div className="tab-dropdown" ref={dropdownRubricRef}>
                  {/* <button className="dropbtn" onClick={toggleDropdownRubric} style={{ cursor: Object.entries(lesson).length === 0 ? 'not-allowed' : 'auto', opacity: Object.entries(lesson).length === 0 ? '0.5' : '1' }}> */}
                  <button className="dropbtn" onClick={toggleDropdownRubric} disabled={Object.entries(lesson).length === 0} style={{ opacity: Object.entries(lesson).length === 0 ? '0.5' : '1' }} >
                    {selectedRubric}
                    <span style={{ transform: showDropdownRubric ? 'rotate(180deg)' : 'rotate(0deg)', }}  >
                      <i className="fa-solid fa-angle-down"></i>
                    </span>
                  </button>


                  {showDropdownRubric && (
                    <div className="dropdown-content">
                      {rubricCount.map((rubric, index) => (
                        <div key={index} className="assignment-item" onClick={() => {
                          // if (activeWorkboard.name === 'Rubric') {
                          //   if (onChangeRubricData[rubricCounttTwo] === workboards.RUBRIC?.data) {
                          //     setPreviesRubric(true)
                          //     handleRubricClick(rubric, index)
                          //   } else {
                          //     (index !== rubricCounttTwo) && alert('save rubric before going next one')
                          //   }
                          // } else {
                          //   setPreviesRubric(true)
                          //   handleRubricClick(rubric, index)
                          // }
                          handleRubricClick(rubric, index)
                        }
                        }>
                          {rubric}
                        </div>
                      ))}


                      <div id="add-assignment" onClick={() => {
                        setEachRubric_id(null)
                        setrubric_id('')
                        // if (activeWorkboard.name === 'Assignment') {
                        //   if (workboards?.ASSIGNMENT?.data === onChangeAssignmentData[assignmentsCountTwo]) {
                        //     if (activeWorkboard.name === 'Rubric') {
                        //       if (onChangeRubricData[rubricCounttTwo] === workboards.RUBRIC?.data) {
                        //         handleAddRubric()
                        //         const workboardItem = Object.values(workboards).find(item => item.name === workboard.name);
                        //         update(workboardItem, workboard.name, 'no_previesAssignment', 'removeWorkboardRubric');
                        //       } else {
                        //         alert('save rubric before going next one')
                        //       }
                        //     } else {
                        //       // setActiveTab('Rubric')
                        //       handleAddRubric()
                        //       const workboardItem = Object.values(workboards).find(item => item.name === workboard.name);
                        //       update(workboardItem, workboard.name, 'no_previesAssignment', 'removeWorkboardRubric');
                        //     }
                        //   } else if (workboards?.ASSIGNMENT?.data !== onChangeAssignmentData[assignmentsCountTwo]) {
                        //     setAfterConfirmation('addRubric')
                        //     setIsRubricConfirmModalData({
                        //       selectedRubric: `Rubric ${rubricCount.length + 1}`,
                        //       selectedRubricIndex: rubricCount.length
                        //     })
                        //     setIsConfirmModalOpen(true)
                        //   }
                        // } else if (activeWorkboard.name === 'Lesson') {
                        //   if (workboards.LESSON.data === lesson?.description) {
                        //     handleAddRubric()
                        //     const workboardItem = Object.values(workboards).find(item => item.name === workboard.name);
                        //     update(workboardItem, workboard.name, 'no_previesAssignment', 'removeWorkboardRubric');
                        //   } else {
                        //     setAfterConfirmation('LessonToAddRubric')
                        //     setIsRubricConfirmModalData({
                        //       selectedRubric: `Assingment ${rubricCount.length + 1}`,
                        //       selectedRubricIndex: rubricCount.length
                        //     })
                        //     setIsConfirmModalOpen(true)
                        //   }

                        // } else if (activeWorkboard.name === 'Rubric') {
                        //   // setActiveWorkboard(workboards.ASSIGNMENT);
                        //   if (onChangeRubricData[rubricCounttTwo] === workboards.RUBRIC?.data) {
                        //     handleAddRubric()
                        //     const workboardItem = Object.values(workboards).find(item => item.name === workboard.name);
                        //     update(workboardItem, workboard.name, 'no_previesAssignment', 'removeWorkboardRubric');
                        //   } else {
                        //     alert('save rubric before going next one')
                        //   }
                        // }

                        handleAddRubric()
                        const workboardItem = Object.values(workboards).find(item => item.name === workboard.name);
                        update(workboardItem, workboard.name, 'no_previesAssignment', 'removeWorkboardRubric');
                      }}>
                        + Add Rubric
                      </div>
                    </div>
                  )}
                </div>
              ) : ''}

              {/* {
                showRubric
              } */}



            </>

          );
        })
        }
      </div >
    );
  }


  function UpdatePlugin({ chat, index }) {
    const [editor] = useLexicalComposerContext();
    const { answer } = chat;
    function update() {
      setActiveWorkboard(
        isAssignment
          ? workboards.ASSIGNMENT
          : isRubric
            ? workboards.RUBRIC
            : workboards.LESSON
      );
      setActiveTab("workboard")
      console.log(6);
      // updateWorkboardDataByName(
      //   isAssignment
      //     ? workboards.ASSIGNMENT.name
      //     : isRubric
      //       ? workboards.RUBRIC.name
      //       : workboards.LESSON.name,
      //   chatHist[index].answer
      // );
      editor.update(() => {
        const parser = new DOMParser();
        const last_chatHist = chatHist[index];
        const finalAnswer = last_chatHist.answer
          .replace("<STARTOFTURN>", "")
          .replace("<ENDOFTURN>", "");
        const dom = parser.parseFromString(finalAnswer, "text/html");

        const nodes = $generateNodesFromDOM(editor, dom);

        $getRoot().select();

        try {
          $insertNodes(nodes);
        } catch (error) {
          console.error(error);
          const root = $getRoot();
          const p = $createParagraphNode();
          p.append($createTextNode(answer));
          root.append(p);
        } finally {
          scrollToBottom();
        }
      });
    }
    return (
      <>
        <i
          onClick={(globalState.subdomain_exists === undefined ? (globalState.userCurrentPlan?.currentSubscription !== null ? (globalState.userCurrentPlan?.currentSubscription?.plan_data?.download_save_copy ? (globalState.userCurrentPlan?.currentSubscription?.remaining_credit === 0 ? null : update) : null) : null) : update)}
          style={{
            cursor: (globalState.subdomain_exists === undefined ? (globalState.userCurrentPlan?.currentSubscription !== null ? (globalState.userCurrentPlan?.currentSubscription?.plan_data?.download_save_copy ? (globalState.userCurrentPlan?.currentSubscription?.remaining_credit === 0 ? "not-allowed" : 'pointer') : "not-allowed") : "not-allowed") : 'pointer'),
            color: (globalState.subdomain_exists === undefined ? (globalState.userCurrentPlan?.currentSubscription !== null ? (globalState.userCurrentPlan?.currentSubscription?.plan_data?.download_save_copy ? (globalState.userCurrentPlan?.currentSubscription?.remaining_credit === 0 ? "#ccc" : 'black') : "#ccc") : "#ccc") : 'black')
          }}
          data-tooltip-id={(globalState.subdomain_exists === undefined ? (globalState.userCurrentPlan?.currentSubscription !== null ? (globalState.userCurrentPlan?.currentSubscription?.plan_data?.download_save_copy ? (globalState.userCurrentPlan?.currentSubscription?.remaining_credit === 0 ? 'Add-to-Workboard-validation' : 'update-plugin') : 'Add-to-Workboard-validation') : 'Add-to-Workboard-validation') : 'update-plugin')}
          className="fa-solid fa-arrow-right"
        ></i>
        <Tooltip
          id="update-plugin"
          place="bottom"
          content="Add to Workboard"
          type="light"
          effect="float"
        />
        <Tooltip
          id="Add-to-Workboard-validation"
          place="bottom"
          content="Upgrade Subscription Plan"
          type="light"
          effect="float"
        />
      </>
    );
  }

  const editorConfig = {
    theme: ExampleTheme,
    onError,
    editable: true,

    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      CodeNode,
      CodeHighlightNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      AutoLinkNode,
      LinkNode,
    ],
  };

  const [isPopupVisible, setPopupVisible] = useState(false);
  // const [buttonStyle, setButtonStyle] = useState({ });


  const toggleAssignmentPopup = (e) => {
    if (isAssignment) {
      let errors = {};
      if (isRequired(formAssignmentState?.name)) {
        errors.name = "Name is required";
      }
      if (isRequired(formAssignmentState?.duration_minutes)) {
        errors.duration_minutes = "Timer is required";
      }
      if (isRequired(formAssignmentState?.start_date)) {
        errors.start_date = "Start date is required";
      }
      // if (isRequired(formAssignmentState?.end_date)) {
      //   errors.end_date = "End date is required";
      // }
      if (isRequired(formAssignmentState?.type)) {
        errors.type = "Type is required";
      }
      console.log("errors", errors);

      setFormErrorAssignmentState(errors);

      if (Object.values(errors).some((error) => error)) {
        console.log("errors", errors);
        return;
      }
    }
    setPopupVisible(!isPopupVisible);
    setIsAssignment(true);
    setIsRubric(false);
    setFormState((prev) => ({ ...prev, chat_query: "" }));
    if (lesson_id !== "") {
      let errors = {};
      errors.end_date = checkifDateisAfterToday(
        format(new Date(formState?.end_date), "yyyy-MM-dd")
      );
      setFormLessonErrorState((prev) => ({
        ...prev,
        end_date: errors.end_date,
      }));
    }
    // setButtonStyle({
    //   position: 'fixed',
    //   bottom: '12px',
    // });
  };
  const toggleRubricPopup = (e) => {
    setPopupVisible(false);
    setIsAssignment(false);
    setIsRubric(true);
  };
  console.log('assignments', assignments);
  const [workboards, setWorkboards] = useState({
    LESSON: {
      name: "Lesson",
      data: "",
    },
    ASSIGNMENT: {
      name: "Assignment",
      data:
        assignments?.length > 0
          ? assignments
          : "",
    },
    RUBRIC: {
      name: "Rubric",
      data:
        rubrics?.length > 0
          ? rubrics : "",
    },
  });






  const lessonFunction = () => {



    if (activeWorkboard.name === 'Assignment') {
      if (workboards?.ASSIGNMENT?.data !== onChangeAssignmentData[assignmentsCountTwo]) {
        // if (onChangeAssignmentData[assignmentsCountTwo] !== undefined) {
        setAfterConfirmation('Lesson');
        setIsConfirmModalOpen(true);
      } else if (workboards?.ASSIGNMENT?.data === onChangeAssignmentData[assignmentsCountTwo]) {
        setAfterConfirmation('Lesson');
        setUpdateFuntion(true)
        setIsAssignment(false)
        setIsRubric(false)
        setActiveWorkboard(workboards.LESSON)
      }
    } else if (activeWorkboard.name === 'Rubric') {
      if (workboards?.RUBRIC?.data != onChangeRubricData[rubricCounttTwo]) {
        setAfterConfirmation('Lesson');
        setIsConfirmModalOpen(true);
      } else if (workboards?.RUBRIC?.data == onChangeRubricData[rubricCounttTwo]) {
        setAfterConfirmation('Lesson');
        setUpdateFuntion(true)
        setIsAssignment(false)
        setIsRubric(false)
        setActiveWorkboard(workboards.LESSON)
      }
    } else {
      setAfterConfirmation('Lesson');
      setUpdateFuntion(true);
      setIsAssignment(false)
      setIsRubric(false)
      setActiveWorkboard(workboards.LESSON)
    }

  }




  const assignmentbuttonStylebackgroundColor =
    workboards.ASSIGNMENT.data !== "" ? "#b5ff9fcc" : "transparent"; // Replace 'defaultColor' with your desired default color
  const lessonbuttonStylebackgroundColor =
    workboards.LESSON.data !== "" ? "#b5ff9fcc" : "transparent";
  const rubricbuttonStylebackgroundColor =
    workboards.RUBRIC.data !== "" ? "#b5ff9fcc" : "transparent";
  // Inline styling for the button
  const assignmentbuttonStyle = {
    background: assignmentbuttonStylebackgroundColor,
    // You can add other styles here if needed
  };
  const lessonbuttonStyle = {
    background: lessonbuttonStylebackgroundColor,
    // You can add other styles here if needed
  };
  const rubricbuttonStyle = {
    background: rubricbuttonStylebackgroundColor,
    // You can add other styles here if needed
  };
  // console.log("workboards", workboards.ASSIGNMENT.data);
  // if(workboards.ASSIGNMENT.data !== "") {
  //   .edit-active-lesson-btn-container button{background: #b5ff9fcc;}
  // }
  // || workboards.RUBRIC.data !== "" || workboards.LESSON.data !== ""
  const [activeWorkboard, setActiveWorkboard] = useState(workboards.LESSON);
  const [editWorkboardStatues, setEditWorkboardStatues] = useState(false);




  useEffect(() => {
    const globleOnChangeData = JSON.parse(localStorage.getItem("globleOnChangeData"));

    if (globleOnChangeData.ASSIGNMENT.data.length > globleOnChangeData.ASSIGNMENT.formAssignment.length) {

      const newFormAssignmentArrya = {
        name: formAssignmentState.name,
        assignment: null,
        assignment_json: null,
        lesson: null,
        duration_minutes: formAssignmentState.duration_minutes,
        start_date: formAssignmentState.start_date,
        end_date: formAssignmentState.end_date,
        type: formAssignmentState.type
      };


      const globleOnChange = JSON.parse(localStorage.getItem("globleOnChangeData"));

      const globleOnChangeUpdateFormAssignment = globleOnChange.ASSIGNMENT.formAssignment
      globleOnChangeUpdateFormAssignment.push(newFormAssignmentArrya)


      globleOnChange.ASSIGNMENT.formAssignment = globleOnChangeUpdateFormAssignment
      // // globleOnChange.ASSIGNMENT.formAssignment = newFormAssignmentArrya

      setGlobleOnChange(globleOnChange)
      // // setGlobleSaveData(globleSaveData)

      localStorage.setItem("globleOnChangeData", JSON.stringify(globleOnChange));
    } else {
      const newFormAssignmentArrya = {
        name: formAssignmentState.name,
        assignment: null,
        assignment_json: null,
        lesson: null,
        duration_minutes: formAssignmentState.duration_minutes,
        start_date: formAssignmentState.start_date,
        end_date: formAssignmentState.end_date,
        type: formAssignmentState.type
      };
      const globleOnChange = JSON.parse(localStorage.getItem("globleOnChangeData"));

      const globleOnChangeUpdateFormAssignment = globleOnChange.ASSIGNMENT.formAssignment

      globleOnChangeUpdateFormAssignment[assignmentsCountTwo] = newFormAssignmentArrya
      globleOnChange.ASSIGNMENT.formAssignment = globleOnChangeUpdateFormAssignment

      setGlobleOnChange(globleOnChange)
      // // setGlobleSaveData(globleSaveData)

      localStorage.setItem("globleOnChangeData", JSON.stringify(globleOnChange));
    }
  }, [activeWorkboard.name])




  // useEffect(() => {
  //   if (activeWorkboard.name === 'Rubric') {
  //     if (onChangeAssignmentData[assignmentsCountTwo] !== workboards.ASSIGNMENT.data) {
  //       setAssignmentCounts(prevAssignments => prevAssignments.slice(0, -1));
  //       setSelectedAssignment(`Assignment ${assignmentsCount.length - 1}`)
  //     }
  //   }

  //   if (activeWorkboard.name === 'Assignment') {
  //     if (onChangeRubricData[rubricCounttTwo] !== workboards.RUBRIC.data) {
  //       setRubricCount(prevRubric => prevRubric.slice(0, -1));
  //       setSelectedRubric(`Rubric ${rubricCount.length - 1}`)
  //     }
  //   }

  //   // if (activeWorkboard.name === 'Lesson') {
  //   //   if (onChangeRubricData[rubricCounttTwo] !== workboards.RUBRIC.data ) {
  //   //     setRubricCount(prevRubric => prevRubric.slice(0, -1));
  //   //     setSelectedRubric(`Rubric ${rubricCount.length - 1}`)
  //   //   }
  //   //   if (onChangeAssignmentData[assignmentsCountTwo] !== workboards.ASSIGNMENT.data) {
  //   //     setAssignmentCounts(prevAssignments => prevAssignments.slice(0, -1));
  //   //     setSelectedAssignment(`Assignment ${assignmentsCount.length - 1}`)
  //   //   }
  //   // }
  // }, [activeWorkboard.name])



  const updateWorkboardDataByName = (name, newData, prevRub) => {

    setWorkboards((prevWorkboards) => {
      // Find the key of the workboard we want to update
      const workboardKey = Object.keys(prevWorkboards).find(
        (key) => prevWorkboards[key].name === name
      );
      // If we found a workboard with the provided name
      if (workboardKey) {
        return {
          ...prevWorkboards, // Copy all the existing workboards
          [workboardKey]: {
            // Use computed property names to overwrite the specific workboard
            ...prevWorkboards[workboardKey], // Copy all the existing properties of the workboard
            data: newData, // Update the data property
          },
        };
      } else {
        // If no workboard was found with the given name, return the previous state
        return prevWorkboards;
      }
    });
  };


  useEffect(() => {
    const globleOnChangeData = JSON.parse(localStorage.getItem('globleOnChangeData'))
    if (globleOnChangeData) {

      const workboardName = activeWorkboard.name.toUpperCase();


      updateWorkboardDataByName(globleOnChangeData[workboardName].name, globleOnChangeData[workboardName].data)
    }

  }, [activeWorkboard.name])

  const [showDropdown, setShowDropdown] = useState(false); // State to control visibility



  const getShareLink = ({ platform, course, baseURL }) => {
    let shareUrl = "";
    const courseDescription =
      course?.description?.length > 100
        ? `${course?.description?.substring(0, 100)}...`
        : course?.description;
    // trim the description to 150 characters

    const data = `${courseDescription}. View the course here: ${baseURL}.`;
    // function shareToGoogleDrive(data) {
    //   // Assuming `gapi` is the Google API JavaScript client library and it's already loaded and authenticated
    //   var fileContent = data; // As an example, the file content is the data you want to share
    //   var file = new Blob([fileContent], {type: 'application/vnd.google-apps.document'});
    //   var metadata = {
    //       'name': 'My document', // Filename at Google Drive
    //       'mimeType': 'application/vnd.google-apps.document', // Google Drive Doc type
    //       // Parents can be used to specify the folder the file will be placed in
    //   };

    //   var accessToken = gapi.auth.getToken().access_token; // Here get your access token from Google's OAuth 2.0
    //   var form = new FormData();
    //   form.append('metadata', new Blob([JSON.stringify(metadata)], {type: 'application/json'}));
    //   form.append('file', file);

    //   fetch('https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&fields=id', {
    //       method: 'POST',
    //       headers: new Headers({'Authorization': 'Bearer ' + accessToken}),
    //       body: form,
    //   }).then((response) => {
    //       return response.json();
    //   }).then(function(val) {
    //       console.log(val);
    //       // Here you can handle the returned file ID for further sharing operations
    //   }).catch((error) => {
    //       console.error(error);
    //   });
    // }
    switch (platform) {
      case "facebook":
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${baseURL}&quote=${data}`;
        break;
      case "whatsapp":
        shareUrl = `https://api.whatsapp.com/send?text=${baseURL} ${data}`;
        break;
      case "twitter":
        shareUrl = `https://twitter.com/intent/tweet?text=${data}`;
        break;
      case "instagram":
        shareUrl = `https://www.instagram.com/?url=${baseURL}`;
        break;
      // case "googleDrive":
      //   shareToGoogleDrive(data);
      //   break;
      case "share":
        shareUrl = baseURL;
        break;
      default:
        shareUrl = baseURL;
        return;
    }

    return shareUrl;
  };
  const getPlatform = {
    FACEBOOK: "facebook",
    WHATSAPP: "whatsapp",
    TWITTER: "twitter",
    INSTAGRAM: "instagram",
    SHARE: "share",
  };
  const [sharelink, setSharelink] = useState("");
  const onShare = async (platform, isOpenNewWindow = true) => {
    setLoading(true);

    if (!course?.course_id) {
      toast.error("Course not found");
      setLoading(false);
      return;
    }

    const getURL = `http://${liveBaseURL}/dashboard`;
    const getShareableLink = getShareLink({
      platform: platform,
      course,
      baseURL: getURL,
    });
    setSharelink(getShareableLink);
    if (isOpenNewWindow) {
      window.open(getShareableLink, "_blank");
    }
  };

  const onClickCopy = () => {
    // toggleDropdown();
    navigator.clipboard.writeText(sharelink);
    toast.success("Copied to clipboard", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const [showDownloadFile, setshowDownloadFile] = useState(false);
  const modalRef = useRef();
  const modalContentRef = useRef(); // Reference to the modal content

  // Function to open the modal
  const openModal = () => {
    setshowDownloadFile(true);
  };

  // Event listener to close modal if clicked outside the modal content
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        modalContentRef.current &&
        !modalContentRef.current.contains(event.target)
      ) {
        toggledownlodefile();
      }
    };

    // Attach the event listener
    if (showDownloadFile) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDownloadFile]);

  ;

  const toggledownlodefile = () => {
    setFileNameDownload("");
    setFileNameError("");
    setshowDownloadFile(false);
    // setShowdownlodefile(!showdownlodefile); // Toggle the visibility state
  };

  useEffect(() => {
    AOS.init({
      duration: 500,
    });
  }, []);

  const [fileNameError, setFileNameError] = useState("");
  const [fileNameDownload, setFileNameDownload] = useState("");
  const [
    pop_up_for_lesson_start_date_note,
    setPopupForLessonStartDateNote,
  ] = useState(false);
  const downloadAsPdf = async () => {
    if (fileNameDownload === null || fileNameDownload === "") {
      setFileNameError("Please enter filename");
    } else {
      const htmlData = document.querySelector(".editor-input").innerHTML;
      const payload = {
        html_text: htmlData,
      };
      const headers = {
        "Content-Type": "application/pdf",
        Authorization: `Token ${token}`,
      };
      try {
        const response = await api.post(
          `auth/convert-html-to-pdf/`,
          payload,
          headers
        );
        if (response.status === 200 || response.status === 201) {
          // Assuming the PDF data in `response.data` is the raw binary string
          const binaryString = response.data;

          // Convert binary string to a Blob
          const pdfBlob = new Blob([binaryStringToArrayBuffer(binaryString)], {
            type: "application/pdf",
          });

          // Create a download link and trigger the download
          const downloadUrl = window.URL.createObjectURL(pdfBlob);
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.download = "converted.pdf";
          document.body.appendChild(link);
          link.click();
          link.remove();
          toggledownlodefile();
          return response?.data?.user_id;
        } else {
          return null;
        }
      } catch (error) {
        console.log(error);
        return null;
      }
    }
  };

  function getEditorContent() {
    // Replace this with the actual method to get content from your rich text editor
    return document.querySelector(".editor-input").innerHTML; // Example for plain text
  }

  const exportToWord = () => {
    if (fileNameDownload === null || fileNameDownload === "") {
      setFileNameError("Please enter filename");
    } else {
      const preHtml =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Word</title></head><body>";
      const postHtml = "</body></html>";
      const html = preHtml + getEditorContent() + postHtml;

      const blob = new Blob(["\ufeff", html], {
        type: "application/msword",
      });

      const url =
        "data:application/vnd.ms-word;charset=utf-8," +
        encodeURIComponent(html);
      const fileName = fileNameDownload + ".doc";

      const downloadLink = document.createElement("a");
      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        downloadLink.href = url;
        downloadLink.download = fileName;
        downloadLink.click();
      }
      document.body.removeChild(downloadLink);
      toggledownlodefile();
    }
  };

  const handleFileNameChange = (e) => {
    const enteredValue = e.target.value.trim();

    setFileNameDownload(enteredValue); // Always update the state

    if (!enteredValue) {
      setFileNameError("Please enter filename");
    } else {
      setFileNameError("");
    }
  };
  const calculateAssignmentDateRanges = (
    start_date,
    end_date,
    assignment_start_date,
    assignment_end_date
  ) => {
    const dayjsStart = start_date ? moment(start_date) : null;
    const dayjsEnd = end_date ? moment(end_date) : null;
    const dayjsAssignmentStart = assignment_start_date
      ? moment(assignment_start_date)
      : null;
    const dayjsAssignmentEnd = dayjsAssignmentStart
      ? moment(dayjsAssignmentStart)
      : null;
    // Default to the broadest range if no specific dates are selected
    // let minStartDate = moment(dayjsStart) || dayjs();
    // let maxStartDate = moment(dayjsEnd) || dayjs("9999-12-31");
    // let minEndDate = moment(dayjsStart) || dayjs();
    // let maxEndDate = moment(dayjsEnd) || dayjs("9999-12-31");
    let minStartDate = moment(dayjsStart);
    let maxStartDate = moment(dayjsEnd);
    let minEndDate = moment(dayjsStart);
    let maxEndDate = moment(dayjsEnd);

    // if (dayjsAssignmentStart) {
    //   minEndDate = dayjsAssignmentStart.isAfter(dayjs())
    //     ? moment(dayjsAssignmentStart)
    //     : dayjs();
    //   maxEndDate = moment(dayjsEnd) || dayjs("9999-12-31");
    // } else {
    //   minEndDate = moment(dayjsStart) || dayjs();
    //   maxEndDate = dayjs("9999-12-31");
    // }

    // if (dayjsAssignmentEnd) {
    //   minStartDate = moment(dayjsStart) || dayjs();
    //   maxStartDate = dayjsAssignmentEnd.isBefore(dayjs("9999-12-31"))
    //     ? moment(dayjsAssignmentEnd)
    //     : dayjs("9999-12-31");
    // } else {
    //   minStartDate = moment(dayjsAssignmentStart) || dayjs();
    //   if (minStartDate.isBefore(moment(), 'day')) {
    //     minStartDate = moment(); // Set it to today's date
    //   }

    //   maxStartDate = moment(dayjsAssignmentEnd) || (moment(dayjsEnd) || dayjs("9999-12-31"));
    // }

    return { minStartDate, maxStartDate, minEndDate, maxEndDate };
  };
  const {
    minStartDate,
    maxStartDate,
    minEndDate,
    maxEndDate,
  } = calculateAssignmentDateRanges(
    formState?.start_date,
    formState?.end_date,
    formAssignmentState?.start_date,
    formAssignmentState?.end_date
  );

  // const {
  //   minAssignmentStart,
  //   maxAssignmentStart,
  // } = calculateAssignmentStartDateRange(
  //   formState?.start_date && formState?.start_date !== ""
  //     ? formState?.start_date
  //     : null,
  //   formState?.end_date && formState?.end_date !== ""
  //     ? formState?.end_date
  //     : null
  // );
  // const {
  //   minAssignmentEnd,
  //   maxAssignmentEnd,
  // } = calculateAssignmentEndDateRange(
  //   formAssignmentState?.start_date && formAssignmentState?.start_date !== ""
  //     ? formAssignmentState?.start_date
  //     : null,
  //   formAssignmentState?.end_date && formAssignmentState?.end_date !== ""
  //     ? formAssignmentState?.end_date
  //     : null
  // );

  const defaultWorkboardContent = `<p class="editor-paragraph"><br></p>`;



  useEffect(() => {
    if (lesson_id !== "") {
      let errors = {};

      errors.end_date = checkifDateisAfterToday(
        format(new Date(formState?.end_date), "yyyy-MM-dd")
      );
      setFormLessonErrorState((prev) => ({
        ...prev,
        end_date: errors.end_date,
      }));

      // Use setTimeout to clear the error after 5 seconds
      const timeoutId = setTimeout(() => {
        let updatedErrors = {};
        updatedErrors.end_date = "";
        setFormLessonErrorState((prev) => ({
          ...prev,
          end_date: updatedErrors.end_date,
        }));
      }, 5000);

      // Cleanup the timeout when the component unmounts
      return () => clearTimeout(timeoutId);
    }
    // Add formLessonError as a dependency if needed
    speechSynthesis.speak(new SpeechSynthesisUtterance(''));
  }, [formState?.end_date]);
  const [assignmentDuration, setAssignmentDuration] = useState({
    minutes: 0,
  });
  const [assignmentDurationCount, setAssignmentDurationCount] = useState(Number(localStorage.getItem('assignmentDurationCount')));

  useEffect(() => {
    localStorage.setItem('assignmentDurationCount', assignmentDurationCount);


    return () => {
      localStorage.setItem('assignmentDurationCount', 0);
    };
  }, [assignmentDurationCount]);

  const [
    assignmentDurationDropdownOpen,
    setAssignmentDurationDropdownOpen,
  ] = useState(false);
  const toggleAssignmentDurationDropdown = () =>
    setAssignmentDurationDropdownOpen(!assignmentDurationDropdownOpen);

  const assignmentDurationChangeTime = (value) => {
    setAssignmentDuration((prevTime) => {
      let { minutes } = prevTime;

      // Increment/Decrement minutes
      minutes += value;

      // Limit the minutes to the range 0-180
      minutes = Math.max(0, Math.min(180, minutes));

      // Update the input value for display
      const newInputValue = minutes;
      setFormAssignmentState((prev) => ({
        ...prev,
        duration_minutes: newInputValue,
      }));

      return { minutes };
    });
  };
  useEffect(() => {
    // Save the form state to local storage whenever it changes
    localStorage.setItem("lesson_formState", JSON.stringify(formState));

    // localStorage.setItem(
    //   "workborad_details",
    //     JSON.stringify(
    //       isAssignment
    //         ? workboards.ASSIGNMENT.data
    //         : isRubric
    //         ? workboards.RUBRIC.data
    //         : workboards.LESSON.data
    //     )
    //   );
    // localStorage.setItem("isAssignment_set", isAssignment);
    // localStorage.setItem("isRubric_set", isRubric);
  }, [formState]);
  // const assignmentDurationSaveTime = () => {
  //   setAssignmentDurationDropdownOpen(false);
  //   setFormAssignmentState((prev) => ({
  //     ...prev,
  //     duration_minutes: `${assignmentDuration?.minutes
  //       ?.toString()
  //       .padStart(2, "0")}:`,
  //   }));
  // };
  const [previewDtls, setpreviewDtls] = useState([]);
  const showstudentviewDtls = () => {
    setpreviewDtls(
      isAssignment
        ? workboards.ASSIGNMENT.data
        : isRubric
          ? workboards.RUBRIC.data
          : workboards.LESSON.data
    );

    setopenStudentView(!openStudentView);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (isListening) {
        if (event.key) {
          startStopListening();
        }
      }
    };

    // Adding the event listener when the component mounts or stopListeningBTN changes
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };

  }, [isListening, transcript])



  const startStopListening = () => {

    // console.log(transcript)
    if (isListening) {
      stopVoiceInput();
    } else {
      startListening();
    }
  };
  const stopVoiceInput = () => {
    stopListening();
    // setFormState(prevState => ({
    //   ...prevState,
    //   chat_query: prevState.chat_query + (transcript ? ' ' + transcript : '')
    // }));
    setFormState(prevState => ({
      ...prevState,
      chat_query: prevState.chat_query ? prevState.chat_query + (transcript ? ' ' + transcript : '') : transcript
    }));
  };


  const [chatAudioId, setchatAudioId] = useState();


  console.log("chatHist", chatHist)

  const generateAIVoice = (chat, id) => {

    setchatAudioId(id)

    let lessonData = parseKaTeX(chat);

    const req = {
      text: lessonData,
      generator_id: id
    }
    dispatch(getTextToSpeech(req, "generator", "Ruth", token))
  }

  useEffect(() => {
    return () => {
      dispatch(resetTextToSpeech());
    }
  }, [])

  // The callback to toggle the play state
  // const togglePlay = useCallback((index) => {

  //   console.log(index)
  //   // setPlayingState(prev => ({
  //   //   ...prev,
  //   //   [index]: !prev[index],
  //   // }));
  // }, []);

  // const speakText = useCallback((text, index) => {
  //   if (speechSynthesis.speaking || speechSynthesis.pending) {
  //     speechSynthesis.cancel();
  //   }
  //   // console.log(text);
  //   var contertext = parseKaTeX(text);

  //   const utterance = new SpeechSynthesisUtterance(contertext);
  //   utterance.voice = voices[2];
  //   utterance.rate = 0.7;
  //   utterance.pitch = 2;
  //   utterance.lang = "en-US";
  //   utterance.onend = () => {
  //     setPlayingState((prev) => ({
  //       ...prev,
  //       [index]: false,
  //     }));
  //   };

  //   speechSynthesis.speak(utterance);
  //   // Set the message as playing
  //   setPlayingState((prev) => ({
  //     ...prev,
  //     [index]: true,
  //   }));
  // }, []);

  // const stopSpeak = useCallback((index) => {
  //   speechSynthesis.cancel();
  //   setPlayingState((prev) => ({
  //     ...prev,
  //     [index]: false,
  //   }));
  // }, []);

  const preprocessLatex = (latexString) => {
    // Example: Replace common HTML entities with LaTeX-friendly characters
    return latexString.replace(/&amp;#39;/g, "'");
    // Add more replacements as needed
  };


  // const convertreadableformat = (text) => {
  //   const script = document.createElement('script');
  //   script.src = 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/3.2.0/es5/tex-mml-chtml.js';
  //   script.async = true;
  //   document.body.appendChild(script);

  //   script.onload = () => {
  //     window.MathJax.typesetPromise().then(() => {
  //       console.log('Equations rendered successfully');
  //     }).catch((err) => console.log('Error rendering equations: ', err));
  //   };
  //   return script;
  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }

  async function convertDrawingToText(imageData, setConvertedText) {
    try {
      const response = await fetch('https://api.mathpix.com/v3/text', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'app_id': 'Webarcitech_621a57',  // Replace with your Mathpix app ID
          'app_key': '7d36ed55c7649574c24f' // Replace with your Mathpix app key
        },
        body: JSON.stringify({ src: imageData })
      });
      const data = await response.json();
      setConvertedText(data.text || 'No text recognized');
    } catch (error) {
      console.error('Error converting drawing:', error);
      setConvertedText('Error in conversion');
    }
  }

  return (
    <>
      {" "}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <LexicalComposer initialConfig={editorConfig}>
          <section className="lesson_generator">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="lesson_generator_inner">
                    <div className="view-course-Details-back-btn-folder">
                      <BackButton buttonName={"Lesson Generator"} />
                      {/* <i
                        onClick={() => navigate(-1)}
                        className="fa-solid fa-arrow-left"
                      ></i>
                      <h2>Lesson Generator</h2> */}
                      {/* <span style={{ fontWeight: "600", width: "100%", textAlign: "center" }}>Lesson Generator</span> */}
                    </div>
                    {/* <span style={{ fontWeight: "600" }}>Lesson Generator</span> */}
                    <div className="assign_main_box">
                      <div className="assign_main_box_left">
                        <div className="assign_main_box_left_single">
                          <label>Lesson Name</label>
                          <CommonTextField
                            type="text"
                            placeholder="E.g. Neurons"
                            name="name"
                            label="Lesson name"
                            showLabel={false}
                            variant="outlined"
                            validate={(val) => isRequired(val, "Lesson name")}
                            size="sm"
                            className="custom-text-field"
                            value={formState.name || ""}
                            onChange={handleInputChange}
                            borderRadius={"6px"}
                            height={45}
                            border={"1px solid #ECECEC;"}
                            color="neutral"
                          />
                        </div>
                      </div>
                      {pop_up_for_lesson_start_date_note && (
                        <div className="pop_up_for_lesson_start_date_note">
                          <p>
                            Students can start accessing the course content from{" "}
                            {dayjs(formState.start_date).format("MMM DD, YYYY")}
                          </p>
                        </div>
                      )}

                      <div className="assign_main_box_right">
                        <div className="assign_main_box_right_single">
                          <label>Lesson Start Date</label>

                          <div className="assing_cal_sec_new">
                            <img src={Calimg} className="cal_img_sec" alt="" />
                            <Datetime
                              isValidDate={isValidDateLessonStartDate}
                              min={lessonMinDate}
                              max={
                                course?.end_date === ""
                                  ? null
                                  : dayjs(course?.end_date)
                              }
                              timeFormat={false}
                              inputProps={{
                                placeholder: "MM:DD:YYYY",
                                readOnly: true,
                              }}
                              closeOnSelect={true}
                              dateFormat="MMM DD, YYYY"
                              placeholder="E.g. Sep 10, 2023"
                              name="start_date"
                              value={
                                formState.start_date
                                  ? dayjs(formState.start_date).format(
                                    "MMM DD, YYYY"
                                  )
                                  : (course?.start_date ? dayjs(course.start_date).format("MMM DD, YYYY") : "")
                              }
                              onChange={(date) =>
                                handleInputChangedate("start_date", date)
                              }
                              className="custom-datetime"
                            />
                          </div>

                          <div className="warning-message">
                            {formLessonError?.start_date}
                          </div>
                        </div>
                        <div className="assign_main_box_right_single lesson-end-date">
                          <label>Lesson End Date (Optional)</label>
                          <div className="assing_cal_sec_new">
                            <img src={Calimg} className="cal_img_sec" alt="" />
                            <Datetime
                              isValidDate={isValidDateLessonEndDate}
                              max={dayjs(course?.end_date)}
                              timeFormat={false}
                              inputProps={{
                                placeholder: "MM:DD:YYYY",
                                readOnly: true,
                              }}
                              closeOnSelect={true}
                              dateFormat="MMM DD, YYYY"
                              placeholder="E.g. Sep 10, 2023"
                              name="end_date"
                              value={
                                formState.end_date
                                  ? dayjs(formState.end_date).format(
                                    "MMM DD, YYYY"
                                  )
                                  : (course?.end_date ? dayjs(course.end_date).format("MMM DD, YYYY") : "")
                              }
                              onChange={(date) =>
                                handleInputChangedate("end_date", date)
                              }
                              className="custom-datetime"
                            />
                          </div>

                          <div className="warning-message">
                            {formLessonError?.end_date}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="lesson_generator_body_sec" style={{ flexDirection: isSmallScreen ? "column" : "row" }}>
                    {isSmallScreen && (
                      <div className="view-lessons-btn-container">
                        <button
                          className={`tab-button ${activeTab === "lesson" ? "active" : ""
                            }`}
                          onClick={() => setActiveTab("lesson")}
                        >
                          Lessons
                        </button>
                        <button
                          className={`tab-button ${activeTab === "workboard" ? "active" : ""
                            }`}
                          onClick={() => setActiveTab("workboard")}
                        >
                          Workboard
                        </button>
                      </div>
                    )}
                    <Box
                      className="lesson_generator_body_sec_left"
                      width={isSmallScreen ? "100%" : "50%"}
                      sx={{
                        display: {
                          xs: activeTab === "lesson" ? "block" : "none",
                          lg: "block",
                        },

                      }}
                    >
                      <div className="lesson-generator-massege-folder">
                        <h6>Lesson Generator</h6>
                        <div className="lesson-genrater-toggle-folder" data-tooltip-id="chatbot-data-workboard">
                          <h6>ChatBot</h6>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={isSwitchOnVirtualAssistant}
                              onClick={handleSwitchToggleVirtualAssistant}
                            />
                            <span className="slider round"></span>
                          </label>
                          <Tooltip
                            id="chatbot-data-workboard"
                            place="top"
                            content="Show/Hide chatbot during assignments"
                            type="light"
                            effect="float"
                          />
                        </div>
                        <div className="lesson-genrater-toggle-folder" data-tooltip-id="lessonpreview-data-workboard">
                          <h6>Lesson Preview</h6>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={isSwitchOnLessonPreview}
                              onClick={handleSwitchToggleLessonPreview}
                            />
                            <span className="slider round"></span>
                          </label>
                          <Tooltip
                            id="lessonpreview-data-workboard"
                            place="top"
                            content="Show/Hide lesson preview during assignments"
                            type="light"
                            effect="float"
                          />
                        </div>
                        <div className="lesson-genrater-downlode" data-tooltip-id="filedownload-data-workboard">
                          <h6>Download Files</h6>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={isSwitchOn2}
                              onClick={handleSwitchToggleDownload}
                            />
                            <span className="slider round"></span>
                          </label>
                          <Tooltip
                            id="filedownload-data-workboard"
                            place="top"
                            content="Allow download of uploaded files"
                            type="light"
                            effect="float"
                          />
                        </div>
                      </div>
                      <div className="lesson_generator_body_sec_left_body">
                        <div className="lesson_generator_body_sec_left_text_part">
                          <h5>
                            <img src={ass_img} alt="" /> Hello Polly, I will
                            assist you to create amazing lesson for your course.
                          </h5>
                        </div>

                        <div className="chat-with-ai-lession-container">
                          {chatHist.map((chat, index) => (
                            <div key={index} >
                              <div className="chat-with-ai-lession-user-folder">
                                <div className="chat-with-ai-lession-user-sned-massege-container">
                                  {chat?.file?.length > 0 ? (
                                    chat?.file.map((file, index) => {
                                      return (
                                        <ListItem
                                          key={index}
                                          sx={{
                                            backgroundColor: "white",
                                            borderRadius: "6px",
                                            marginBottom: "8px",
                                            height: "48px",
                                          }}
                                        >
                                          <ListItemIcon>
                                            <img
                                              src={getFileIcon(
                                                file?.original_name
                                              )}
                                              alt=""
                                            />
                                          </ListItemIcon>
                                          <ListItemText
                                            primary={file?.original_name}
                                            secondary={getFileType(
                                              file?.original_name
                                            )}
                                          />
                                        </ListItem>
                                      );
                                    })
                                  ) : (
                                    <></>
                                  )}
                                  <p className="text-massege-user">
                                    {chat.query}
                                  </p>
                                </div>
                                <div className="send-user-name">
                                  <h2>
                                    {getname(
                                      globalState.teacher_details?.data?.name
                                    )}
                                  </h2>
                                </div>
                              </div>
                              <div className="chat-with-ai-lession-user-folder chat-with-ai-lession-AI-message">
                                <div className="send-user-name">
                                  <h2>AI</h2>
                                </div>
                                {chat.answer === "" && (
                                  <>
                                    <div className="loading-chat-answer">
                                      <Waveform />
                                    </div>
                                  </>
                                )}
                                <div className="read-chat-container">
                                  <div className="chat-with-ai-lession-user-sned-massege-container">
                                    <PreprocessAndRenderLatex
                                      content={chat.answer}
                                      isBlockMath={true}
                                    // setShowCloseBTN={setShowCloseBTN}
                                    />
                                    {/* <MathJaxComponent content={chat.answer} /> */}
                                  </div>
                                  {!(chat.answer === "") && (
                                    <div key={index} className="copy-past-text-container">
                                      <div className="play-pause-btn-lesson-generator">

                                        {chatAudioId === chat.uuid && texttospeech.isLoading ?
                                          <LoaderComponent /> :
                                          chatAudioId === chat.uuid && chatAudioId === texttospeech.generatorId && texttospeech.success ?
                                            <audio controls src={texttospeech.voice.audio_url} />
                                            :
                                            <i className="fa-solid fa-circle-play"
                                              onClick={() => generateAIVoice(chat.answer, chat.uuid)}
                                            ></i>
                                        }
                                      </div>
                                      <i
                                        onClick={() => (globalState.subdomain_exists === undefined ? (globalState.userCurrentPlan?.currentSubscription !== null ? (globalState.userCurrentPlan?.currentSubscription?.plan_data?.download_save_copy ? (globalState.userCurrentPlan?.currentSubscription?.remaining_credit === 0 ? null : onCopyClicked(chat)) : null) : null) : onCopyClicked(chat))}
                                        style={{
                                          cursor: (globalState.subdomain_exists === undefined ? (globalState.userCurrentPlan?.currentSubscription !== null ? (globalState.userCurrentPlan?.currentSubscription?.plan_data?.download_save_copy ? (globalState.userCurrentPlan?.currentSubscription?.remaining_credit === 0 ? "not-allowed" : 'pointer') : "not-allowed") : "not-allowed") : 'pointer'),
                                          color: (globalState.subdomain_exists === undefined ? (globalState.userCurrentPlan?.currentSubscription !== null ? (globalState.userCurrentPlan?.currentSubscription?.plan_data?.download_save_copy ? (globalState.userCurrentPlan?.currentSubscription?.remaining_credit === 0 ? "#ccc" : 'black') : "#ccc") : "#ccc") : 'black')
                                        }}
                                        data-tooltip-id={(globalState.subdomain_exists === undefined ? (globalState.userCurrentPlan?.currentSubscription !== null ? (globalState.userCurrentPlan?.currentSubscription?.plan_data?.download_save_copy ? (globalState.userCurrentPlan?.currentSubscription?.remaining_credit === 0 ? 'Copy-Workboard-validation' : 'copy-data') : 'Copy-Workboard-validation') : 'Copy-Workboard-validation') : 'copy-data')}

                                        className={"fa-regular fa-clipboard"}
                                      ></i>
                                      {/* < i onClick={() => onArrowRightClicked(chat)} className="fa-solid fa-arrow-right"></i> */}
                                      <UpdatePlugin index={index} chat={chat} />
                                      <Tooltip
                                        id="copy-data"
                                        place="bottom"
                                        content="Copy"
                                        type="light"
                                        effect="float"
                                      />
                                      <Tooltip
                                        id="Copy-Workboard-validation"
                                        place="bottom"
                                        content="Upgrade Subscription Plan"
                                        type="light"
                                        effect="float"
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                          <div ref={messagesEndRef} />
                        </div>

                        <div className="ganrate-lesson-text-feild-wrapper">
                          {/* <div className={`ganrate-lesson-text-feild-suggestion ${genratesuggestion ? 'show' : ''}`}>
                        <h2>generate lesson</h2>
                      </div> */}
                          <div className="pdf_box_sec">
                            <div className="pdf_box_sec_inner">
                              <AWSS3FileDelete
                                selectedFiles={selectedFiles}
                                setSelectedFiles={setSelectedFiles}
                                getFileIcon={getFileIcon}
                                uploadedFileLink={uploadedFileLink}
                                setuploadedFileLink={setuploadedFileLink}
                                progressTracker={progressTracker}
                                setprogressTracker={setprogressTracker}
                              />
                              {/* {selectedFiles.length > 0 &&
                                selectedFiles.map((file, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="pdf_box_sec_inner_single"
                                    >
                                      <img src={getFileIcon(file?.name)} alt="" />
                                      <span>
                                        <h5>{truncateFileName(file.name)}</h5>
                                      </span>
                                      <i
                                        className="fas fa-times"
                                        onClick={() => handleRemoveFile(index)}
                                      ></i>
                                    </div>
                                  );
                                })} */}
                            </div>
                            {/* {
                              showCloseBTN && <> <button onClick={closeConnection} >close</button> </>
                            } */}
                            <form action="" onSubmit={sendGenerateLession}>
                              <div
                                className={`newleson-create-Assignment-container-popup ${checkAssignmentopen
                                  ? ""
                                  : isPopupVisible
                                    ? "show"
                                    : ""
                                  }`}
                                style={{
                                  padding: `${checkAssignmentopen
                                    ? ""
                                    : isPopupVisible
                                      ? "10px"
                                      : "0px"
                                    }`,
                                }}
                              >
                                <div className="create-Assignment-container-popup-folder1">
                                  <div className="assign_main_box_left_single create-Assignment-input-feild">
                                    <label>Assignment Name</label>
                                    <FormControl
                                      margin="0px"
                                      fullWidth
                                      error={!!formErrorAssignmentState.name}
                                    >
                                      <TextField
                                        name="name"
                                        size="small"
                                        defaultValue={""}
                                        placeholder="E.g. Neurons"
                                        className="custom-text-field"
                                        variant="outlined"
                                        value={formAssignmentState.name}
                                        // value={'hello'}
                                        error={!!formErrorAssignmentState.name}
                                        onChange={handleAssignmentInputChange}
                                        InputProps={{
                                          sx: {
                                            borderRadius: "6px",
                                            backgroundColor: "#FFFFFF",
                                            fontSize: "10px",
                                            height: "35px",
                                          },
                                        }}
                                      />
                                      <FormHelperText sx={{ color: "#d31332" }}>
                                        {formErrorAssignmentState.name}
                                      </FormHelperText>
                                    </FormControl>
                                  </div>
                                  <div className="Create-Course-inner-input Create-Assignment-dropdown">
                                    <label>Assignment timer</label>
                                    <FormControl margin="none">
                                      <div className="time-field">
                                        <button
                                          onClick={(e) => {
                                            e.preventDefault();
                                            assignmentDurationChangeTime(-5);
                                            setAssignmentDurationCount(assignmentDurationCount > 0 ? assignmentDurationCount - 1 : 0)
                                          }}
                                        >
                                          -
                                        </button>
                                        <span>
                                          {`${assignmentDuration.minutes
                                            .toString()
                                            .padStart(2, "0")} Minute${assignmentDuration.minutes?.toString() >
                                              1
                                              ? "s"
                                              : ""
                                            }`}
                                        </span>
                                        <button
                                          onClick={(e) => {
                                            e.preventDefault();
                                            assignmentDurationChangeTime(5);
                                            setAssignmentDurationCount(Number(assignmentDurationCount) + 1)
                                          }}
                                        >
                                          +
                                        </button>
                                      </div>

                                      <FormHelperText sx={{ color: "#d31332" }}>
                                        {
                                          formErrorAssignmentState.duration_minutes
                                        }
                                      </FormHelperText>
                                    </FormControl>
                                  </div>
                                </div>
                                <div className="create-Assignment-container-popup-folder2">
                                  <div className="assign_main_box_right_date">
                                    <label>Start Date</label>
                                    <FormControl
                                      margin="none"
                                      fullWidth
                                      error={
                                        !!formErrorAssignmentState.start_date
                                      }
                                    >
                                      <img
                                        src={Calimg}
                                        className="cal_img_sec_assignment"
                                        alt=""
                                      />
                                      <Datetime
                                        isValidDate={
                                          isValidDateAssignmentStartDate
                                        }
                                        // max={dayjs(formAssignmentState?.start_date).format("MMM DD, YYYY")}
                                        min={minDate}
                                        timeFormat={false}
                                        inputProps={{
                                          placeholder: "MM:DD:YYYY",
                                          readOnly: true,
                                        }}
                                        closeOnSelect={true}
                                        dateFormat="MMM DD, YYYY"
                                        placeholder="E.g. Sep 10, 2023"
                                        name="start_date"
                                        value={
                                          formAssignmentState.start_date
                                            ? dayjs(
                                              formAssignmentState.start_date
                                            ).format("MMM DD, YYYY")
                                            : ""
                                        }
                                        onChange={(date) =>
                                          handleAssignmentInputChangedate(
                                            "start_date",
                                            date
                                          )
                                        }
                                        className="custom-datetime my-custom-picker"
                                      />
                                      {/* <DesktopDatePicker
                                    name="start_date"
                                    variant="outlined"
                                    // disablePast
                                    // defaultValue={formState?.start_date}
                                    minDate={dayjs(minStartDate).startOf(
                                      "day"
                                    )}
                                    maxDate={dayjs(maxStartDate).endOf("day")}
                                    value={dayjs(
                                      formAssignmentState.start_date
                                    ).startOf("day")}
                                    error={
                                      !!formErrorAssignmentState.start_date
                                    }
                                    onChange={(date) => {
                                      if (date && dayjs(date).isValid()) {
                                        const localDate = dayjs(date).startOf(
                                          "day"
                                        );
                                        console.log(
                                          "Selected Date:",
                                          localDate.format("YYYY-MM-DD")
                                        );
                                        handleAssignmentInputChangedate(
                                          "start_date",
                                          localDate.format("YYYY-MM-DD")
                                        );
                                      } else {
                                        // Handle invalid or null date
                                      }
                                    }}
                                    slots={{
                                      openPickerIcon: CalendarMonthIcon,
                                    }}
                                    renderInput={(props) => (
                                      <TextField
                                        size="small"
                                        error={
                                          !!formErrorAssignmentState.start_date
                                        }
                                        {...props}
                                      />
                                    )}
                                    slotProps={{
                                      inputAdornment: {
                                        position: "start",
                                      },
                                      textField: {
                                        size: "small",
                                        readOnly: true,
                                        InputProps: {
                                          sx: {
                                            borderRadius: "5px",
                                            backgroundColor: "#FFFFFF",
                                          },
                                        },
                                      },
                                    }}
                                  /> */}
                                      <FormHelperText sx={{ color: "#d31332" }}>
                                        {formErrorAssignmentState.start_date}
                                      </FormHelperText>
                                    </FormControl>
                                  </div>
                                  <div className="assign_main_box_right_date">
                                    <label>Expiry Date</label>
                                    <FormControl
                                      margin="none"
                                      fullWidth
                                      error={
                                        !!formErrorAssignmentState.start_date
                                      }
                                    >
                                      <img
                                        src={Calimg}
                                        className="cal_img_sec_assignment"
                                        alt=""
                                      />

                                      <Datetime
                                        isValidDate={
                                          isValidDateAssignmentEndDate
                                        }
                                        // max={dayjs(formAssignmentState?.end_date).format("MMM DD, YYYY")}
                                        min={dayjs(
                                          formAssignmentState?.start_date
                                            ? formState.start_date
                                            : formAssignmentState?.start_date
                                        )
                                          .add(1, "day")
                                          .format("MMM DD, YYYY")}
                                        // max={dayjs(maxEndDate).format("MMM DD, YYYY")}
                                        timeFormat={false}
                                        inputProps={{
                                          placeholder: "MM:DD:YYYY",
                                          readOnly: true,
                                        }}
                                        closeOnSelect={true}
                                        dateFormat="MMM DD, YYYY"
                                        placeholder="E.g. Sep 10, 2023"
                                        name="end_date"
                                        value={formAssignmentState.end_date === null ? '' : dayjs(formAssignmentState.end_date).format("MMM DD, YYYY")}
                                        // value={dayjs(formAssignmentState.end_date === null ? null : formAssignmentState.end_date).format("MMM DD, YYYY")}
                                        onChange={(date) =>
                                          handleAssignmentInputChangedate(
                                            "end_date",
                                            date
                                          )
                                        }
                                        className="custom-datetime my-custom-picker"
                                        initialViewDate={dayjs(
                                          formAssignmentState?.start_date ??
                                          formState.start_date
                                        )
                                          .add(1, "day")
                                          .toDate()}
                                      />




                                      {/* <DesktopDatePicker
                                    name="end_date"
                                    variant="outlined"
                                    error={
                                      !!formErrorAssignmentState.end_date
                                      // disablePast
                                    }
                                    // defaultValue={formState?.end_date}
                                    value={dayjs(
                                      formAssignmentState.end_date
                                    ).utc()}
                                    minDate={minEndDate}
                                    maxDate={maxEndDate}
                                    onChange={(date) => {
                                      if (date && dayjs(date).isValid()) {
                                        // handleAssignmentInputChangedate(
                                        //   "end_date",
                                        //   dayjs(date).format("YYYY-MM-DD")
                                        // );
                                        const utcDate = dayjs(date)
                                          .utc()
                                          .format("YYYY-MM-DD");
                                        handleAssignmentInputChangedate(
                                          "end_date",
                                          utcDate
                                        );
                                      } else {
                                        // Handle invalid or null date
                                      }
                                    }}
                                    slots={{
                                      openPickerIcon: CalendarMonthIcon,
                                    }}
                                    renderInput={(props) => (
                                      <TextField
                                        size="small"
                                        error={
                                          !!formErrorAssignmentState.end_date
                                        }
                                        {...props}
                                      />
                                    )}
                                    slotProps={{
                                      inputAdornment: {
                                        position: "start",
                                      },
                                      textField: {
                                        size: "small",
                                        readOnly: true,
                                        InputProps: {
                                          sx: {
                                            borderRadius: "5px",
                                            backgroundColor: "#FFFFFF",
                                          },
                                        },
                                      },
                                    }}
                                  /> */}





                                      <FormHelperText sx={{ color: "#d31332" }}>
                                        {formErrorAssignmentState.end_date}
                                      </FormHelperText>
                                    </FormControl>
                                  </div>
                                  <div className="Create-Course-inner-input Create-Assignment-dropdown create-Assignment-popup-input-feild">
                                    <label>Choose Assignment type</label>
                                    <FormControl margin="none">
                                      <TextField
                                        name="type"
                                        defaultValue={"ddasas"}
                                        value={formAssignmentState.type}
                                        placeholder="Select Assignment type"
                                        variant="outlined"
                                        select
                                        size="small"
                                        error={!!formErrorAssignmentState.type}
                                        onChange={handleAssignmentInputChange}
                                        InputProps={{
                                          sx: {
                                            borderRadius: "6px",
                                            backgroundColor: "#FFFFFF",
                                            height: "35px",
                                            padding: "0px",
                                          },
                                        }}
                                      >
                                        {Object.values(AssignmentType).map(
                                          (type) => (
                                            <MenuItem
                                              key={type.name}
                                              value={type.value}
                                            >
                                              {type.name}
                                            </MenuItem>
                                          )
                                        )}
                                      </TextField>
                                      <FormHelperText sx={{ color: "#d31332" }}>
                                        {formErrorAssignmentState.type}
                                      </FormHelperText>
                                    </FormControl>
                                  </div>
                                </div>
                              </div>
                              <div className="promote_part_sec">
                                <TextField
                                  ref={textBoxRef}
                                  id="lesson_promote_flexible"
                                  name="chat_query"
                                  label=""
                                  variant="standard"
                                  placeholder={`Write all your prompts here to create ${isAssignment
                                    ? "assignment"
                                    : isRubric
                                      ? "rubric"
                                      : "lesson"
                                    }`}
                                  fullWidth
                                  multiline
                                  InputProps={{
                                    disableUnderline: true,
                                    startAdornment: (
                                      <AWSS3FileUpload
                                        selectedFiles={selectedFiles}
                                        setSelectedFiles={setSelectedFiles}
                                        setuploadedFileLink={
                                          setuploadedFileLink
                                        }
                                        disabled={isListening}
                                      />
                                    ),


                                    endAdornment: (
                                      <>
                                        {
                                          showCloseBTN && <> <button onClick={closeConnection} className="genrater-close-btn"><i class="fa-regular fa-circle-stop"></i></button> </>
                                        }
                                        <button type="button" id='mic-btn' className={`mic ${isListening ? 'listening' : ''}`} onClick={() => { startStopListening() }}>
                                          <i className={`fa-solid fa-microphone ${isListening ? 'hidden' : ''}`}></i>
                                          <div className={`boxContainer ${isListening ? '' : 'hidden'}`}>
                                            <div className="box box1"></div>
                                            <div className="box box2"></div>
                                            <div className="box box3"></div>
                                          </div>
                                        </button>
                                        <Tooltip
                                          id="speack-mic"
                                          place="bottom"
                                          content="Press Alt to unmute Mic"
                                          type="light"
                                          effect="float"
                                        />

                                        <button
                                          type="submit"
                                          disabled={
                                            // !formState.name || // Condition 1
                                            (globalState.subdomain_exists === undefined ? (globalState.userCurrentPlan?.currentSubscription !== null ? (globalState.userCurrentPlan?.currentSubscription?.remaining_credit === 0 ? true : false) : true) : false) ||
                                            isListening || !formState.chat_query ||
                                            formState.chat_query === null ||
                                            formState.chat_query === "" || // Condition 2
                                            selectedFiles.length !==
                                            progressTracker.length ||
                                            !progressTracker.every(
                                              (obj) =>
                                                obj.status === "Completed"
                                            ) // Condition 3
                                          }
                                          // data-tooltip-id={!globalState.subdomain_exists ? (globalState.userCurrentPlan?.currentSubscription ? (globalState.userCurrentPlan?.currentSubscription?.remaining_credit === 0 && "show-message") : "show-message") : ""}
                                          data-tooltip-id='GPT-prompt-validation'
                                          // style={{ backgroundColor: (globalState?.userCurrentPlan?.currentSubscription ? (globalState?.userCurrentPlan?.currentSubscription?.remaining_credit === 0) : true) && 'gray' }}
                                          // disabled={
                                          //   !formState.name ||
                                          //   (!formState.chat_query ??
                                          //   formState.chat_query === null ??
                                          //     formState.chat_query !== "") &&
                                          //   (selectedFiles.length !== progressTracker.length && progressTracker.every(obj => obj.status !== 'Completed'))
                                          // }
                                          className="go_cls_btn"
                                        >
                                          {
                                            (globalState.subdomain_exists === undefined ? (globalState.userCurrentPlan?.currentSubscription !== null ? (globalState.userCurrentPlan?.currentSubscription?.remaining_credit === 0 ? <img src={go_btn2} alt="" /> : <img src={go_btn} alt="" />) : <img src={go_btn2} alt="" />) : <img src={go_btn} alt="" />)
                                          }
                                          {/* {
                                            (!globalState.userCurrentPlan?.currentSubscription && globalState.subdomain_exists === undefined) ? <img src={go_btn2} alt="" /> :
                                              globalState.userCurrentPlan?.currentSubscription?.remaining_credit === 0 ? <img src={go_btn2} alt="" /> : <img src={go_btn} alt="" />
                                          } */}
                                        </button>
                                        <Tooltip
                                          id="GPT-prompt-validation"
                                          place="bottom"
                                          content={(globalState.subdomain_exists === undefined ? (globalState.userCurrentPlan?.currentSubscription !== null ? (globalState.userCurrentPlan?.currentSubscription?.remaining_credit === 0 ? "Upgrade Subscription Plan" : "") : "Upgrade Subscription Plan") : '')}
                                          type="light"
                                          effect="float"
                                        />

                                      </>
                                    ),
                                  }}
                                  maxRows={5}
                                  // value={isListening ? formState.chat_query + (transcript.length ? (formState.chat_query.length ? ' ' : '') + transcript : '') : formState.chat_query}
                                  // value={formState.chat_query}
                                  value={
                                    isListening
                                      ? formState.chat_query ? (`${formState.chat_query}${transcript ? ` ${transcript}` : ''}`) : transcript
                                      : formState.chat_query
                                  }
                                  disabled={isListening}
                                  onChange={handleInputKeyPress}
                                  // onKeyDown={handleInputKeyPress}
                                  onKeyDown={(globalState.subdomain_exists === undefined ? (globalState.userCurrentPlan?.currentSubscription !== null ? (globalState.userCurrentPlan?.currentSubscription?.remaining_credit === 0 ? null : handleInputKeyPress) : null) : handleInputKeyPress)}
                                />
                                <Tooltip
                                  id="my-tooltip-click"
                                  content="Prompt here"
                                  events={["click"]}
                                />
                              </div>
                            </form>
                          </div>
                        </div>
                        <p className="AI-disclamer">
                          AI can make mistakes. Consider checking important
                          information
                        </p>
                      </div>
                    </Box>
                    <Box
                      className="lesson_generator_body_sec_right"
                      width={isSmallScreen ? "100%" : "50%"}
                      sx={{

                        display: {
                          xs: activeTab === "workboard" ? "block" : "none",
                          lg: "block"
                        },
                      }}
                    >
                      <div className="create-lesson-Personalize-contaiber">
                        <h6>Workboard</h6>
                        <h6>
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault(); // This prevents the default anchor link behavior
                              // setopenStudentView(!openStudentView);
                              showstudentviewDtls();
                            }}
                          >
                            Student Preview
                          </a>
                        </h6>
                        <div className="Preview-lesson-Content">
                          <div className="Preview-lesson-text-editor-features-personalize">
                            <h2>Personalize</h2>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={isSwitchOn}
                                onClick={handleSwitchToggle}
                              />
                              <span className="slider round"></span>
                            </label>
                            {showPopup && (
                              <p className="personlized_popup">
                                The content will be personalized to match the
                                student's specific interests
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="lesson_generator_body_sec_right_body">
                        <div className="lesson_generator_editor_toos-folder">
                          <UpdateActiveWorkbaordPlugin assignmentsCount={assignmentsCount} setAssignmentCounts={setAssignmentCounts} showAssingment={showAssingment}
                            setShowAssingment={setShowAssingment} selectedAssignment={selectedAssignment} setSelectedAssignment={setSelectedAssignment}
                            isPopupVisible={isPopupVisible} setPopupVisible={setPopupVisible} setIsAssignment={setIsAssignment} onChangeAssignmentData={onChangeAssignmentData}
                            setOnChangeAssignmentData={setOnChangeAssignmentData} setAssignmentCountsTwo={setAssignmentCountsTwo} editWorkboardStatues={editWorkboardStatues}
                            setEditWorkboardStatues={setEditWorkboardStatues} assignmentsCountTwo={assignmentsCountTwo} showDropdownRubric={showDropdownRubric}
                            setShowDropdownRubric={setShowDropdownRubric} rubricCount={rubricCount} setRubricCount={setRubricCount} rubricCounttTwo={rubricCounttTwo}
                            setRubricCountTwo={setRubricCountTwo} onChangeRubricData={onChangeRubricData} setOnChangeRubricData={setOnChangeRubricData}
                            selectedRubric={selectedRubric} setSelectedRubric={setSelectedRubric} previesCountAssignment={previesAssignment} setPreviesAssignment={setPreviesAssignment} setPreviesRubric={setPreviesRubric}
                            setFormAssignmentState={setFormAssignmentState} updateFuntion={updateFuntion} setUpdateFuntion={setUpdateFuntion} isRubricConfirmModalData={isRubricConfirmModalData}
                            setIsRubricConfirmModalData={setIsRubricConfirmModalData} afterConfirmation={afterConfirmation} setAfterConfirmation={setAfterConfirmation}
                            isAssingmentConfirmModalData={isAssingmentConfirmModalData} setIsAssingmentConfirmModalData={setIsAssingmentConfirmModalData} callAddAssignmentFun={callAddAssignmentFun}
                            setCallAddAssignmentFun={setCallAddAssignmentFun} />
                          <div className="all-editer-tools-section">
                            <div>
                              <span>
                                {isCopied ? (
                                  <i className="fa-solid fa-check"></i>
                                ) : (
                                  <i
                                    data-tooltip-id="copy-data-workboard"
                                    onClick={() =>
                                      onCopyClicked({
                                        answer: document.querySelector(
                                          ".editor-input"
                                        ).innerHTML,
                                      })
                                    }
                                    className="fa-regular fa-clipboard"
                                  ></i>
                                )}
                                <Tooltip
                                  id="copy-data-workboard"
                                  place="top"
                                  content="Copy"
                                  type="light"
                                  effect="float"
                                />
                              </span>
                            </div>
                            {/* <span>
                                      <i
                                        onClick={() =>
                                          onCopyClicked({
                                            answer: document.querySelector(
                                              ".editor-input"
                                            ).innerHTML,
                                          })
                                        }
                                        className="fa-regular fa-clipboard"
                                      ></i>
                                    </span> */}
                            {/* <span onClick={toggleDropdown}>
                                      <i className="fa-solid fa-share-nodes"></i>
                                    </span> */}
                            <span onClick={openModal}>
                              <i
                                className="fa-solid fa-download"
                                data-tooltip-id="download-data-workboard"
                              ></i>
                              <Tooltip
                                id="download-data-workboard"
                                place="top"
                                content="Download"
                                type="light"
                                effect="float"
                              />
                            </span>

                            {showDropdown && (
                              <div className="view-course-details-share-form">
                                <div
                                  className="view-course-details-share-folder"
                                  data-aos="zoom-in"
                                >
                                  <h2>Share Lesson</h2>
                                  <div className="view-course-details-inner-wrapper">
                                    <p>Share this link via</p>
                                    <div className="view-course-social-links-folder">
                                      {/* <div
                                                   onClick={() =>
                                                     onShare(getPlatform.WHATSAPP)
                                                   }
                                                   className="view-course-social-links-image"
                                                 >
                                                   <img src={imgs.png.Whatsapp} alt="" />
                                                 </div>
                                                 <div
                                                   onClick={() =>
                                                     onShare(getPlatform.INSTAGRAM)
                                                   }
                                                   className="view-course-social-links-image"
                                                 >
                                                   <img src={imgs.png.Instagram} alt="" />
                                                 </div>
                                                 <div
                                                   onClick={() =>
                                                     onShare(getPlatform.FACEBOOK)
                                                   }
                                                   className="view-course-social-links-image"
                                                 >
                                                   <img src={imgs.png.Facebook} alt="" />
                                                 </div>
                                                 <div
                                                   onClick={() =>
                                                     onShare(getPlatform.TWITTER)
                                                   }
                                                   className="view-course-social-links-image"
                                                 >
                                                   <img src={twitter_icon} alt="" />
                                                 </div> */}
                                      {showDropdown && (
                                        <GoogleDocFile
                                          nameValue={formState.name}
                                        />
                                      )}

                                      {/* <GoogleLogin
                                            clientId={clientId}
                                            buttonText="Login"
                                            onSuccess={onSuccess}
                                            onFailure={onFailure}
                                            cookiePolicy={'single_host_origin'}
                                            isSignedIn={true}
                                          /> */}
                                    </div>
                                    {/* <p>Or copy link</p>
                                            <div className="copy-link-or-path-folder">
                                              <input
                                                type="text"
                                                value={sharelink}
                                                placeholder="example.com/share-link"
                                                disabled
                                              />
                                              <button type="button" onClick={onClickCopy}>
                                                Copy
                                              </button>
                                            </div> */}

                                    <i
                                      // onClick={toggleDropdown}
                                      id="create-lesson-share-canle-btn"
                                      className="fa-solid fa-xmark shocial-cross-btn"
                                    />
                                  </div>
                                </div>
                              </div>
                            )}

                            {showDownloadFile && (
                              <div
                                ref={modalRef}
                                className="Download-File-popup-wrappper"
                                id="create-lesson-downloode-wrapper"
                              >
                                <div
                                  ref={modalContentRef}
                                  className="Download-File-popup-container"
                                  data-aos="zoom-in"
                                >
                                  <div className="download-popup">
                                    <h2>Download File as</h2>
                                    <button
                                      className="close-btn"
                                      onClick={toggledownlodefile}
                                    >
                                      ×
                                    </button>
                                    <div className="input-section">
                                      <label>
                                        File Name *
                                        <input
                                          type="text"
                                          name="namefiletodnld"
                                          placeholder="Enter File Name"
                                          value={fileNameDownload}
                                          onChange={handleFileNameChange}
                                        />
                                        {fileNameError && (
                                          <p style={{ color: "red" }}>
                                            {fileNameError}
                                          </p>
                                        )}
                                      </label>
                                    </div>
                                    <div className="button-section">
                                      <button
                                        className="spreadsheet-btn"
                                        onClick={exportToWord}
                                      >
                                        Word File(.doc){" "}
                                        <i
                                          className="fa-solid fa-file-lines"
                                          style={{
                                            color: "#ffffff",
                                            fontSize: "16px",
                                          }}
                                        ></i>
                                      </button>
                                      <span>Or</span>
                                      <button
                                        className="pdf-btn"
                                        onClick={downloadAsPdf}
                                      >
                                        PDF (.pdf){" "}
                                        <i
                                          className="fa-solid fa-file-lines"
                                          style={{
                                            color: "#ffffff",
                                            fontSize: "16px",
                                          }}
                                        ></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="editor-container">
                          <ToolbarPlugin />
                          <div className="editor-inner">
                            <RichTextPlugin
                              contentEditable={
                                <ContentEditable className="editor-input" />
                              }
                              placeholder={<Placeholder />}
                            // ErrorBoundary={LexicalErrorBoundary}
                            />
                            {
                              activeWorkboard.name === 'Rubric' ? <OnChangeRubricPlugin onChangeRubricData={onChangeRubricData} rubricCounttTwo={rubricCounttTwo} /> : <OnChangePlugin />
                            }
                            <HistoryPlugin />
                            <AutoFocusPlugin />
                            <ClearEditorPlugin />
                            <HtmlPlugin
                              initialHtml={formState?.description || ""}
                              onHtmlChanged={(e) => { }}
                            />
                            <CodeHighlightPlugin />
                            <ListPlugin />
                            <LinkPlugin />
                            <AutoLinkPlugin />
                            <ListMaxIndentLevelPlugin maxDepth={7} />
                            <MarkdownShortcutPlugin
                              transformers={TRANSFORMERS}
                            />
                          </div>

                        </div>
                        {/* {loading ? (
                          <div className="loading-spinner-lesson" />
                        ) : lesson_id !== "" ? ( */}
                        {lesson_id !== "" ? (
                          <div className="create-all-btns-container">
                            {/* <div className="create-all-btns"> */}
                            <button
                              onClick={handleAssinmentRubricSubmit}
                              className="proc_btn_new"
                              disabled={
                                // make this form sir
                                isAssignment
                                  ? workboards.ASSIGNMENT.data ===
                                  defaultWorkboardContent ||
                                  workboards.ASSIGNMENT.data === "" ||
                                  defaultWorkboardData.ASSIGNMENT?.data ===
                                  workboards.ASSIGNMENT?.data ||
                                  workboards.ASSIGNMENT.data === onChangeAssignmentData[assignmentsCountTwo] ||
                                  (globalState.subdomain_exists === undefined ? (globalState.userCurrentPlan?.currentSubscription !== null ? (globalState.userCurrentPlan?.currentSubscription?.plan_data?.work_board_access ? (globalState.userCurrentPlan?.currentSubscription?.remaining_credit === 0 ? true : false) : true) : true) : false)
                                  : isRubric
                                    ? workboards.RUBRIC.data ===
                                    defaultWorkboardContent ||
                                    workboards.RUBRIC.data === "" ||
                                    defaultWorkboardData.RUBRIC?.data ===
                                    workboards.RUBRIC?.data ||
                                    workboards.RUBRIC.data === onChangeRubricData[rubricCounttTwo] ||
                                    (globalState.subdomain_exists === undefined ? (globalState.userCurrentPlan?.currentSubscription !== null ? (globalState.userCurrentPlan?.currentSubscription?.plan_data?.work_board_access ? (globalState.userCurrentPlan?.currentSubscription?.remaining_credit === 0 ? true : false) : true) : true) : false) ||
                                    disabledRubric
                                    : workboards.LESSON.data ===
                                    defaultWorkboardContent ||
                                    workboards.LESSON.data === "" ||
                                    defaultWorkboardData.LESSON?.data ===
                                    workboards.LESSON?.data ||
                                    (globalState.subdomain_exists === undefined ? (globalState.userCurrentPlan?.currentSubscription !== null ? (globalState.userCurrentPlan?.currentSubscription?.plan_data?.work_board_access ? (globalState.userCurrentPlan?.currentSubscription?.remaining_credit === 0 ? true : false) : true) : true) : false)
                              }
                              // data-tooltip-id={!globalState.subdomain_exists ? (globalState.userCurrentPlan?.currentSubscription ? (globalState.userCurrentPlan?.currentSubscription?.remaining_credit === 0 && "show-message") : "show-message") : ""}
                              data-tooltip-id='Work-Board-Access-valication1'
                              style={{ backgroundColor: (globalState.subdomain_exists === undefined ? (globalState.userCurrentPlan?.currentSubscription !== null ? (globalState.userCurrentPlan?.currentSubscription?.plan_data?.work_board_access ? (globalState.userCurrentPlan?.currentSubscription?.remaining_credit === 0 ? 'gray' : '') : 'gray') : 'gray') : '') }}>
                              {isAssignment
                                // ? `${onChangeAssignmentData[assignmentsCountTwo] ===
                                ? `${defaultWorkboardData.ASSIGNMENT?.data !==
                                  workboards.ASSIGNMENT?.data
                                  ? "Save"
                                  : "Changes Saved"
                                }`
                                : isRubric
                                  ? `${defaultWorkboardData.RUBRIC?.data !==
                                    workboards.RUBRIC?.data
                                    ? "Save"
                                    : "Changes Saved"
                                  }`
                                  : `${defaultWorkboardData.LESSON?.data !==
                                    workboards.LESSON?.data
                                    ? "Save"
                                    : "Changes Saved"
                                  }`}
                            </button>
                            <Tooltip
                              id="Work-Board-Access-valication1"
                              place="bottom"
                              content={(globalState.subdomain_exists === undefined ? (globalState.userCurrentPlan?.currentSubscription !== null ? (globalState.userCurrentPlan?.currentSubscription?.plan_data?.work_board_access ? (globalState.userCurrentPlan?.currentSubscription?.remaining_credit === 0 ? "Upgrade Subscription Plan" : '') : "Upgrade Subscription Plan") : "Upgrade Subscription Plan") : '')}
                              type="light"
                              effect="float"
                            />
                            {defaultWorkboardData.RUBRIC?.data ===
                              workboards.RUBRIC?.data &&
                              defaultWorkboardData.LESSON?.data ===
                              workboards.LESSON?.data &&
                              defaultWorkboardData.ASSIGNMENT?.data ===
                              workboards.ASSIGNMENT.data && (
                                <button
                                  onClick={(e) => {
                                    navigate(-1);
                                  }}
                                >
                                  Back to Course
                                </button>
                              )}
                            {/* </div> */}
                          </div>
                        ) : (
                          <button
                            disabled={
                              workboards.LESSON.data === defaultWorkboardContent ||
                              workboards.LESSON.data === '' ||
                              (globalState.subdomain_exists === undefined ? (globalState.userCurrentPlan?.currentSubscription !== null ? (globalState.userCurrentPlan?.currentSubscription?.plan_data?.work_board_access ? (globalState.userCurrentPlan?.currentSubscription?.remaining_credit === 0 ? true : false) : true) : true) : false)
                            }
                            onClick={handleForm1Submit}
                            className="proc_btn_new"
                            data-tooltip-id='Work-Board-Access-valication2'
                            style={{ backgroundColor: (globalState.subdomain_exists === undefined ? (globalState.userCurrentPlan?.currentSubscription !== null ? (globalState.userCurrentPlan?.currentSubscription?.plan_data?.work_board_access ? (globalState.userCurrentPlan?.currentSubscription?.remaining_credit === 0 ? 'gray' : '') : 'gray') : 'gray') : '') }}>

                            Proceed
                          </button>
                        )}
                        <Tooltip
                          id="Work-Board-Access-valication2"
                          place="bottom"
                          content={(globalState.subdomain_exists === undefined ? (globalState.userCurrentPlan?.currentSubscription !== null ? (globalState.userCurrentPlan?.currentSubscription?.plan_data?.work_board_access ? (globalState.userCurrentPlan?.currentSubscription?.remaining_credit === 0 ? "Upgrade Subscription Plan" : '') : "Upgrade Subscription Plan") : "Upgrade Subscription Plan") : '')}
                          type="light"
                          effect="float"
                        />
                      </div>
                    </Box>
                  </div>
                </div>
              </div>
            </div>

            {showCourseSuccessfully && (
              <div className="preview-lession-course-created-successfully-wrapper">
                <div className="Course-created-successfully-container">
                  <div className="Course-created-successfully-folder">
                    <div className="Course-created-successfully-image">
                      {/* Replace with the actual source URL */}
                      <img
                        src={Course_created_successfully_image}
                        alt="Course Created Successfully"
                      />
                    </div>
                    <h2>
                      {isAssignment
                        ? "Assignment "
                        : isRubric
                          ? "Rubric "
                          : "Lesson "}
                      has been{" "}
                      {/* {(isAssignment
                        ? assignment_id !== ""
                        : isRubric
                          ? rubric_id !== ""
                          : lesson_id !== "")
                        ? "updated"
                        : "created"}{" "} */}
                      {(isAssignment
                        ? previesAssignment
                        : isRubric
                          ? previesRubric
                          : lesson_id !== "")
                        ? "updated"
                        : "created"}{" "}
                      successfully
                    </h2>
                  </div>
                </div>
              </div>
            )}
          </section >
        </LexicalComposer >
      </LocalizationProvider >
      {openStudentView && (
        <div className="preview-student-wrapper">
          <div
            style={{ padding: "30px 20px" }}
            className="Course-created-successfully-container"
          >
            {/* dismiss modal */}
            <div
              className="close-modal-btn-student-view"
              onClick={() => setopenStudentView(false)}
            >
              <a>
                <i className="fa-solid fa-xmark dismis-close"></i>
              </a>
            </div>

            <div
              style={{ height: "482px", zIndex: 999, overflow: "auto" }}
              className="Course-created-successfully-folder"
            >
              <h4>
                {isAssignment ? "Assignment" : isRubric ? "Rubric" : "Lesson"}
              </h4>
              <PreprocessAndRenderLatex
                content={preprocessLatex(previewDtls)}
                isBlockMath={true}
              />
            </div>
          </div>
        </div>
      )}

      {/* <div className="rubric-popup-wrapper" style={{ zIndex: '10', position: 'absolute', top: '200px', left: '300px', background: 'red', width: '600px', textAlign: 'center', display: (showRubricPopUp && !hideRubricPopUp) ? 'block' : 'none' }}> */}
      <div className="rubric-popup-wrapper" style={{ display: (showRubricPopUp && !hideRubricPopUp) ? 'flex' : 'none' }}>
        <div className="rubric-popup-container">
          <h2>In the absence of a teacher-created rubric, the system will generate a default one.</h2>
          <button type="button" className="" onClick={() => setHideRubricPopUp(true)}>Continue</button>
        </div>
      </div>
      <UniversalChatbot />
      {/* <NotSavePopUp isOpen={isNotSavePopUp} onClose={handleCancelPopUp} onConfirm={handleSavePopUp} /> */}
      {/* <ConfirmModal isOpen={true} onConfirm={handleConfirm} onClose={handleCancel} /> */}
    </>
  );
};

export default NewLessons;




function NotSavePopUp({ isOpen, onClose, onConfirm }) {
  if (!isOpen) return null;

  return (
    <div className="save-assignment-popup-wrapper">
      <div className="save-assignment-popup-cotainer">
        <h2>You want to Save your Changes</h2>
        <div className="save-assignment-popup-btn">
          <button onClick={onConfirm}>Yes</button>
          <button onClick={onClose}>No</button>
        </div>
      </div>
    </div>
  );
}



