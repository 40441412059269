import React from "react";
import FilteredTextarea from "../../../../../../components/FilteredInput/FilteredTextArea";

function DescriptionCoursePopup({ title, data, onClick}){
    return(
        <>
            <div className="teacherviewcoursedetails-inner-popup-wrappper">
                <div className="teacherviewcoursedetails-inner-popup-folder">
                    <h2>{title}</h2>
                    <textarea>{data}</textarea>
                    <span className="my-course-close-btn" onClick={onClick}>
                        <i className="fa-solid fa-x"></i>
                    </span>
                </div>
            </div>
        </>
    )
}

export default DescriptionCoursePopup;