import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import imgs from "../../../../../../assets/images/imgs";
import { Chip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { addCourseToCart } from "../../../../../../state/actions/addtoCartAction";
import { useAuth } from "../../../../../../features/auth/AuthContext";
import CancelPopup from "../../../../../../components/CancelPopup/CancelPopup";
import { Modal } from "react-responsive-modal";
import { Tooltip } from "react-tooltip";

const StudentSuggesionCourseData = ({ onNavigateAway, course }) => {
  const { token } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [inCart, setInCart] = useState(course?.in_cart);
  const [item_id, setitem_id] = useState();
  const globalState = useSelector((state) => state.globalState);


  const getLabel = (status) => {
    switch (status) {
      case "Pending":
        return "Request Sent";
      case "Active":
        return "Go To Course";
      case "Complete":
        return "Go To Course";
      default:
        return "View Course";
    }
  };

  function addToCartRequest() {
    // dispatch(addCourseToCart(state,token))
    setOpenDialog(!openDialog);
  }

  const show = () => {
    setInCart(true)
    setOpenDialog(false);
    dispatch(addCourseToCart(item_id, token));
    //navigate(`/cart`);
  };

  const close = (id) => {
    setitem_id(id);
    setOpenDialog(!openDialog); // This will close the popup
  };

  const buttonRef = useRef(null);
  const marketplace_access = globalState.userCurrentPlan?.currentSubscription && globalState.userCurrentPlan?.currentSubscription?.plan_data.marketplace_access

  useEffect(() => {
    if (buttonRef.current && !marketplace_access) {
      buttonRef.current.style.setProperty('background-color', 'gray', 'important');
      buttonRef.current.style.setProperty('color', 'white', 'important');
      buttonRef.current.style.setProperty('border', 'none', 'important');
      buttonRef.current.style.setProperty('cursor', 'not-allowed', 'important');
    } else if (buttonRef.current && marketplace_access) {
      // Reset the button styles if marketplace access is enabled
      buttonRef.current.style.setProperty('background-color', '#007bff', 'important');
      buttonRef.current.style.setProperty('color', 'white', 'important');
      buttonRef.current.style.setProperty('border', '1px solid #007bff', 'important');
      buttonRef.current.style.setProperty('cursor', 'pointer', 'important');
    }
  }, [marketplace_access, course]);


  // console.warn(globalState);
  const getButton = (status, course_id) => {
    if (!globalState.subdomain_exists) {
      return <>
        {(course?.price === null && course?.discounted_price === null) ? <button
          type="button"
          onClick={() => {
            navigate(`/course/${course_id}/view`, {
              state: { course: course, isFromInvite: false, isFreeCourse: (course?.price === null && course?.discounted_price === null) ? true : false },
            });
            if (typeof onNavigateAway === "function") {
              onNavigateAway();
            }
          }}
        >
          View Course
        </button> :
          <>
            <button
              type="button"
              onClick={() => {
                navigate(`/course/${course_id}/view`, {
                  state: { course: course, isFromInvite: false, isFreeCourse: (course?.price === null && course?.discounted_price === null) ? true : false },
                });
                if (typeof onNavigateAway === "function") {
                  onNavigateAway();
                }
              }}
            >
              View Course
            </button>
            {inCart ? <button type="button" onClick={() => navigate('/cart')}>
              Go To Cart
            </button> :
              // <button type="button" onClick={() => close(course_id)}>
              //   Add To Cart
              // </button>
              <button
              ref={buttonRef}
                type="button"
                onClick={() => {
                  if (marketplace_access) {
                    close(course_id);
                  }
                }}
                disabled={!marketplace_access}
                data-tooltip-id={!marketplace_access ? "show-message" : ""}
              >
                Add To Cart
              </button>
              }
             {!marketplace_access && (
                    <Tooltip
                      id="show-message"
                      place="bottom"
                      content="Upgrade Subscription Plan"
                      type="light"
                      effect="float"
                    />
                  )}
          </>
        }
      </>
    } else {
      return <>
        <button
          type="button"
          onClick={() => {
            if (status === "Active" || "Completed") {
              navigate(`/course/${course_id}`, {
                state: { course: course },
              });
            } else {
              navigate(`/course/${course_id}/view`, {
                state: { course: course, isFromInvite: false },
              });
            }
            if (typeof onNavigateAway === "function") {
              onNavigateAway();
            }
          }}
          disabled={status === "Pending" ? true : false}
        >
          {getLabel(status)}
        </button>
      </>
    }
  }

  return (
    <div className="studentDashboar-Suggested-Courses-folder">
      <div
        className="studentDashboar-Suggested-Courses-image"
        onClick={() => {
          if (!globalState.subdomain_exists) {
            navigate(`/course/${course?.course_id}/view`, {
              state: { course: course, isFromInvite: false },
            });
          }
          else {
            if (course?.status === "Active" || "Completed") {
              navigate(`/course/${course?.course_id}`, {
                state: { course: course },
              });
            } else {
              navigate(`/course/${course?.course_id}/view`, {
                state: { course: course, isFromInvite: false },
              });
            }
          }
          if (typeof onNavigateAway === "function") {
            onNavigateAway();
          }
        }}
      >
        <img src={course?.image_url || imgs.jpg.course_demo_image} alt="" />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <NavLink to={`/teacher/${course?.teacher}`}>
          <div className="studentDashboar-Suggested-Courses-profile-folder">
            <div className="studentDashboar-Suggested-Courses-profile">
              <div className="studentDashboar-profile-image">
                <img
                  src={course?.ai_avatar ?? imgs.png.tearcher_profile_image}
                  alt=""
                />
              </div>
              <div className="studentDashboar-Suggested-Courses-profile-title">
                <h2 className="tudentDashboar-profile-card-title">
                  {course?.teacher_name}
                </h2>
                <p>Teacher</p>
              </div>
            </div>
          </div>
        </NavLink>
        <div className="enroll-container">
          <div className="Enrolled-btn">
            {course?.source && <Chip label={"Enrolled"} />}
          </div>
          {/* Hidden for subdomain */}
          {!globalState.subdomain_exists && course?.ratings_count > 0 && (
            <div className="studentDashboar-Suggested-Courses-profile-rate" onClick={() => navigate(`/ratings/${course?.course_id}/view`)}>
              {course?.average_rating > 0 && (
                <>
                  <a>
                    <i className="fa-solid fa-star"></i>
                  </a>
                  <p className="rate">{course?.average_rating.toFixed(1)}</p>
                </>
              )}
              {course?.ratings_count > 0 && (
                <p className="number">({parseInt(course?.ratings_count)})</p>
              )}
            </div>
          )}
          {/* Hidden for subdomain */}
        </div>
      </div>
      <h2
        className="studentDashboar-profile-course-title"
        onClick={() => {
          if (!globalState.subdomain_exists) {
            navigate(`/course/${course?.course_id}/view`, {
              state: { course: course, isFromInvite: false },
            });
          }
          else {
            if (course?.status === "Active" || "Completed") {
              navigate(`/course/${course?.course_id}`, {
                state: { course: course },
              });
            } else {
              navigate(`/course/${course?.course_id}/view`, {
                state: { course: course, isFromInvite: false },
              });
            }
          }
          if (typeof onNavigateAway === "function") {
            onNavigateAway();
          }
        }}
      >
        {course?.name}
      </h2>
      {!globalState.subdomain_exists && <div className="disscount-course-price-folder">
        {course?.discounted_price != course?.price && <p>${course?.price ?? 0}</p>}
        {/* <span className="course-price">${course?.discounted_price ?? 0}</span> */}
        <span className="course-price">
          {course?.discounted_price != null ? 
          `$${Number.isInteger(course?.discounted_price) ? 
            course?.discounted_price : 
            course?.discounted_price.toFixed(2)}` 
          : 'Free'}
        </span>
      </div>}
      <div className="studentDashboar-profile-btn-folder">
        {getButton(course?.status, course?.course_id)}
      </div>
      {openDialog && (
        <Modal
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          center
          showCloseIcon={false}
          classNames={{
            modal: 'Cancel-Popup',
          }}
        >
          <CancelPopup
            title="Are you sure you want to add this course to cart?"
            No="No"
            Yes="Yes"
            show={show} // Assuming you have a state or value to pass
            close={close} // Pass the reference, not the invocation
          />
        </Modal>
      )}
    </div>
  );
};

export default StudentSuggesionCourseData;