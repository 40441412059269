import React, { createContext, useContext, useState, useCallback } from "react";

const ProfanityFilterContext = createContext();

export const useProfanityFilter = () => useContext(ProfanityFilterContext);

export const ProfanityFilterProvider = ({ children }) => {
  const [filter, setFilter] = useState(null);

  const loadFilter = useCallback(async () => {
    if (!filter) {
      const Filter = (await import("bad-words")).default;
      setFilter(new Filter());
    }
  }, [filter]);

  const clean = useCallback(
    (text) => {
      // Ensure the text is a string and not null/undefined
      if (typeof text !== 'string' || !filter) {
        return text || '';
      }
      try {
        return filter.clean(text);
      } catch (error) {
        console.error('Error cleaning text:', error);
        return text;  // Fallback to original text in case of error
      }
    },
    [filter]
  );

  return (
    <ProfanityFilterContext.Provider value={{ loadFilter, clean }}>
      {children}
    </ProfanityFilterContext.Provider>
  );
};
