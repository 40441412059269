import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface practiceAssignmentPayment {
    isLoading: boolean;
    error: string | null;
    message: string | null;
    order_id: string | null;
    client_secret: string | null;
}

const initialState: practiceAssignmentPayment = {
    message: null,
    isLoading: false,
    error: null,
    order_id: null,
    client_secret: null,
};

const practiceAssignmentPayment = createSlice({
    name: 'PracticeAssignmentPayment',
    initialState,
    reducers: {
        setPracticeAssignmentPayment: (state: practiceAssignmentPayment, action: PayloadAction<string>) => {
            state.order_id = action.payload;
            state.client_secret = action.payload
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: practiceAssignmentPayment, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: practiceAssignmentPayment, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetPracticeAssignmentPayment: () => {
            return initialState;
        },
    },
});

export const { setPracticeAssignmentPayment, setloading, seterror, resetPracticeAssignmentPayment } = practiceAssignmentPayment.actions;

export default practiceAssignmentPayment.reducer;
