import React from "react";
import { massege_icon } from "../assets/images/images";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";

const StudentTeacherprofileChatbot = ({
  teacherTitleValue,
  studentDashboar_teacher_image,
  onClickTeacher,
  teacherName,
  studentDashboar_teacher_inner_des,
  closeTeacherProfile,
  teacherId,
}) => {
  const name = `${teacherName?.split(" ")[0] ?? teacherName}`;
  const globalState = useSelector((state) => state.globalState);
  const ai_assistant = globalState.userCurrentPlan?.currentSubscription && globalState.userCurrentPlan.currentSubscription.plan_data.ai_assistant
  
  return (
    <>
      <div className="studentDashboar-teacher-popup-folder">
        <div className="studentDashboar-teacher-image">
          <img src={studentDashboar_teacher_image} alt="" />
        </div>
        <div className="studentDashboar-teacher-des-folder">
          <div className="studentDashboar-teacher-des-inner-title-folder">
            <h2 className="studentDashboar-teacher-des-inner-name">
              {`${teacherTitleValue} ${name}`}
            </h2>
            <div className="studentDashboar-teacher-des-inner-title-rate-wrapper"></div>
          </div>
          <div className="studentDashboar-teacher-des-view-profile-wrapper">
            <div className="studentDashboar-teacher-des-view-profile-folder">
              <span>
                <i className="fa-solid fa-user"></i>
              </span>
              <NavLink to={`/teacher/${teacherId}`}>
                <p>View Profile</p>
              </NavLink>
            </div>

            <div
              className="studentDashboar-teacher-des-view-profile-folder"
              onClick={() => {
                onClickTeacher();
                closeTeacherProfile();
              }}
            >
              <span>
                <img src={massege_icon} alt="" />
              </span>
              <p>Chat</p>
            </div>
            {/* <div
              className={`studentDashboar-teacher-des-view-profile-folder ${!ai_assistant ? 'disabled' : ''}`}
              onClick={() => {
                if (ai_assistant) {
                  onClickTeacher();
                  closeTeacherProfile();
                }
              }}
              data-tooltip-id={!ai_assistant && !globalState.subdomain_exists ? "show-message" : ""}
              style={{
                cursor: !ai_assistant && !globalState.subdomain_exists ? "not-allowed" : "",
              }}
            >
              <span>
                <img src={massege_icon} alt="" />
              </span>
              <p>Chat</p>
            </div>
            {!ai_assistant && !globalState.subdomain_exists && ( 
              <Tooltip
                id="show-message"
                place="bottom"
                content="Upgrade Subscription Plan"
                type="light"
                effect="float"
              />
            )}   */}
          </div>
        </div>
      </div>
      <button
        onClick={closeTeacherProfile}
        className="studentDashboar-teacher-popup-close-btn"
      >
        <i className="fa-solid fa-xmark"></i>
      </button>
    </>
  );
};

export default StudentTeacherprofileChatbot;
