import React, { useEffect } from 'react';
import { welcome_polly_mage } from '../../../assets/images/images';
import { useSelector, useDispatch } from 'react-redux';
import { updateGlobalState } from '../../../state/global/slice.tsx';
import AOS from "aos";
import "aos/dist/aos.css";
const Welcome = (props) => {
    const dispatch = useDispatch();
    const globalState = useSelector((state) => state.globalState);
    const updateState = (key, value) => {
        dispatch(updateGlobalState({ key: key, value: value }));
    };
    const profileImage =
        (globalState?.role === 'Student') ?
            (globalState?.student_details?.data?.student_profile_pic ?? welcome_polly_mage) :
            (globalState?.teacher_details?.data?.ai_avatar ?? welcome_polly_mage);
    const profileName =
        (globalState?.role === 'Student') ?
            (globalState?.student_details?.data?.name ?? globalState?.role) :
            (globalState?.teacher_details?.data?.name ?? globalState?.role);

    useEffect(() => {
        AOS.init({
            duration: 500,
        });
    }, []);

    return (
        <>
            <div className='welcome-polly-popup-wrapper'>
                <div className='welcome-polly-popup-container' data-aos="zoom-in">
                    <div className='welcome-polly-popup-image'>
                        <img src={profileImage ?? welcome_polly_mage} alt="Profile" />
                    </div>
                    <h2>Welcome {profileName}!</h2>
                    <p>Get ready for a transformative learning experience, Let's start learning!</p>
                    <span className='welcome-polly-cross-btn' onClick={() => { props.setWelcomePopupStatus(false); props.setShowTooltip(true); updateState('has_profile', true); document.body.style.overflow = 'auto'; }}>
                        <i className="fa-solid fa-xmark"></i>
                    </span>
                </div>
            </div>
        </>
    )
}

export default Welcome