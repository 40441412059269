import { Dispatch } from "redux";
import api from "../../api/api";
import {
    setLessonStats,
    setloading,
    seterror,
    setPagination,
    resetLessonStats,
} from "../reducers/getLessonStatsReducer";

const getLessonStats = (course_id: any, student_id: any, token: any, search: any, filter: any, sort: any) => async (
    dispatch: Dispatch
) => {
    dispatch(setloading(true));
    try {
        const result = await api.get(`/school-admin/school/lesson-state/${course_id}/?student_id=${student_id}`, {
            params: {
                search: search,
                filter: filter,
                sort: sort,
            },
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Token ${token}`,
            },
        });

        if (result) {
            dispatch(setloading(false));
            if (result.data?.status === 200) {
                dispatch(setLessonStats(result.data.data));
                dispatch(setPagination(result.data))
            } else {
                dispatch(seterror(result.data?.message ?? "Something Went Wrong!"));
            }
        }
    } catch (error: any) {
        dispatch(seterror(error.response.data.message));
    }
};

export { getLessonStats, resetLessonStats };

