import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IndividualProfile {
    isLoading: boolean;
    error: string | null;
    message: string | null;
}

const initialState: IndividualProfile = {
    message: null,
    isLoading: false,
    error: null,
};

const individualProfileslice = createSlice({
    name: 'individualProfile',
    initialState,
    reducers: {
        setindividualProfile: (state : IndividualProfile, action: PayloadAction<IndividualProfile>) => {
            state.message = action.payload.message;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: IndividualProfile, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null; 
        },
        seterror: (state : IndividualProfile, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetindividualProfile: () => {
            return initialState;
        },
    },
});

export const { setindividualProfile, setloading, seterror, resetindividualProfile } = individualProfileslice.actions;

export default individualProfileslice.reducer;
