import { CircularProgress, Grid } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../api/api";
import {
  studentDashboard_AI_chatbot_image
} from "../../assets/images/images";
import { useAuth } from "../../features/auth/AuthContext";
import { updateGlobalState } from "../../state/global/slice";
import BackButton from "../BackButton/BackButton";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import "./TeacherStudentList.css";

const TeacherStudentList = () => {
  const navigate = useNavigate();
  const { token } = useAuth();
  //   const lessons = [
  //     { name: "Lesson 01", description: "Basic structure of neurons" },
  //     { name: "Lesson 02", description: "Basic structure of akdadsasd" },
  //   ];
  const itemsPerPage = 12;
  const [selLession, setSelLession] = useState(-1);
  const [totalPages, setTotalPages] = useState(0);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const onExpand = (i) => {
    setSelLession(selLession === i ? -1 : i);
  };
  const [lessonsDtls, setLessonsDtls] = useState();
  const inial_value = (currentPage - 1) * itemsPerPage;
  const [deleteCourse, setdeleteCourse] = useState(false);
  const [loading, setLoading] = useState(false);
  const globalState = useSelector((state) => state.globalState);
    const dispatch = useDispatch();
    const updateState = (key, value) => {
      dispatch(updateGlobalState({ key: key, value: value }));
    };
  const toggleDeleteCourse = () => {
    setdeleteCourse(!deleteCourse);
    setLoading(false);
    document.body.style.overflow = "auto";
  };

  // const onStudentViewProfile = (student) => {
    // navigate(`/course/${course?.course_id}/student/${student?.student_id}`, {
    //   state: { course, student },
    // });
  // };


  const [studentsRequest, setstudentsRequest] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const getStudnetDtails = async (page) => {
    setLoading(true);
    document.body.style.overflow = "auto";
    try {
      const response = await api.get(
        `/auth/get-student-by-teacher-course/`,
        {
          params: {
            page: page,
          },
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setLoading(false);
        setstudentsRequest(response.data.data);
        setTotalPages(
          response.data.count >= 12
            ? Math.ceil(response.data.count / itemsPerPage)
            : Math.floor(response.data.count / itemsPerPage)
        ); // Calculate total pages

        setNextUrl(response.data.next); // Update next URL
        setPrevUrl(response.data.previous); // Update previous URL
      } else {
        setLoading(false);
        toast.error("Something went wrong.", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => { 
    getStudnetDtails(currentPage);
  },[currentPage]);
   const handlePageChange = (event, value) => {
    window.scrollTo(0, 0);
    setCurrentPage(value);
  };
  function formatDate(isoDate) {
    const date = new Date(isoDate);

    // Get individual date components
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" }); // 'Jan', 'Feb', 'Mar' etc.
    const year = date.getFullYear();

    // Get the suffix for the day
    let suffix = "th";
    if (day % 10 === 1 && day !== 11) {
      suffix = "st";
    } else if (day % 10 === 2 && day !== 12) {
      suffix = "nd";
    } else if (day % 10 === 3 && day !== 13) {
      suffix = "rd";
    }

    return `${day}${suffix} ${month}, ${year}`;
  }
  const onStudentViewProfile = (student) => {
    navigate(`/student/${student?.student_id}`);
  };
  return (
    <>
      <div id="StudentList-section">
        <div className="StudentList-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <BackButton buttonName={"Back"} />
                <div className="StudentList-wrapper">
                  <div className="list-of-students-wrappper student-request-wrapper">
                      <table>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Id</th>
                            <th>Grade</th>
                            <th>Enroll On</th>
                            {/* <th>Overall Progress</th> */}
                          </tr>
                          <div className="list-of-students-gap"></div>
                        </thead>
                        <tbody>
                          {loading ?
                            <LoadingComponent
                              loading_text = "Educating the Future, Loading Now."
                            /> :
                          studentsRequest.length > 0 ? (
                            studentsRequest.map((student, index) => (
                              <>
                                <tr key={index}>
                                  <td
                                    // onClick={() => {
                                    //   onStudentViewProfile(student);
                                    // }}
                                  >
                                    <div className="studentrequest-profile">
                                      <div className="studentrequest-profile-image">
                                        <img
                                          src={
                                            student?.student_profile_pic ||
                                            studentDashboard_AI_chatbot_image
                                          }
                                          alt="Student"
                                        />
                                      </div>
                                      <div className="studentrequest-profile-name">
                                        {student?.name}
                                      </div>
                                    </div>
                                  </td>
                                  <td>{student?.student_id}</td>
                                  <td>{student?.grade}</td>
                                  <td>{formatDate(student?.enrolled_time)}</td>
                                  <td> <a
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault(); // This prevents the default anchor link behavior
                                        onStudentViewProfile(student);
                                      }}
                                    >
                                      View Profile
                                    </a></td>
                                </tr>
                                <div className="blank-div"></div>
                              </>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="5">No students request found.</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                  </div>
                  <Grid xs={12} container justifyContent={"center"}>
                    <Pagination
                      count={totalPages}
                      page={currentPage}
                      onChange={handlePageChange}
                      color="primary"
                      hideNextButton={
                        nextUrl === null || currentPage === totalPages
                      } // Disable if no next URL and on the last page
                      hidePrevButton={prevUrl === null || currentPage === 1} // Hide if no previous URL and on the first page
                    />
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeacherStudentList;
