import React from "react";
import "./ViewMoreComponent.css";
import PreprocessAndRenderLatex from "../../components/ProcessRenderLatex";
import { marked } from "marked";
import {
    Regenerate_course_image,
} from "../../assets/images/images";
import { Tooltip } from "react-tooltip";


const renderPlainHtml = (htmlString) => {

    const doc = new DOMParser().parseFromString(htmlString, "text/html");

    return doc.body.textContent || "";
};


const ViewMoreComponent = ({ showMore, handleViewMoreClick, aiAssessment, header_image, header_text, charLimit = 271, regenerateFunc, enableRegenerate, regenerateButtonState, regenerateMessage }) => (



    < div className="teacher-description-wrapper" >
        <div className="ai-assessment-headre">
            <label className="teacher-description-container-head">
                <img src={header_image} alt={`${header_text} icon`} />
                {header_text}
            </label>
            <div className="ai-assessment-regenerate-btn" style={{ cursor: enableRegenerate ? "pointer" : "" }} onClick={enableRegenerate ? () => regenerateFunc() : () => { }} data-tooltip-id={regenerateMessage}>
                {
                    <img
                        className={`ai-assessment-regenerate-img ${regenerateButtonState ? "spinning" : ""
                            }`}
                        src={Regenerate_course_image}
                        alt=""
                    />
                }
                <span>Regenerate</span>

            </div>
        </div>
        {
            !enableRegenerate && <Tooltip
                id={regenerateMessage}
                place="top"
                content={regenerateMessage}
                type="light"
                effect="float"
            />
        }

        <div className="Teacher-student-edit-profile-inner-des-wrapper teacher-description-container">
            <p className="teacher-description-container-p">
                {/* {data && (showMore ? data : data?.slice(0, charLimit))} */}

                <PreprocessAndRenderLatex
                    content={aiAssessment && (showMore ? marked(aiAssessment) : marked(
                        aiAssessment
                            .split(" ") // Split content by spaces to get words
                            .slice(0, 50) // Limit to 100 words
                            .join(" ") + (aiAssessment.split(" ").length > 50 ? " ..." : "") // Join the words back into a string
                    ))}
                    isBlockMath={false}
                />
            </p>
            <div>
                <button
                    className="teacher-description-container-btn"
                    onClick={handleViewMoreClick}
                >
                    {showMore ? "View less" : "View more"}
                </button>
            </div>
        </div>
    </div >
);

export default ViewMoreComponent;
