// src/components/CommonInput.js

import React, { useEffect, useState } from 'react';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { InputAdornment, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
const CommonTextField = ({ id, submitted, name, multiline, errorFontSize = 10, backgroundColor, startAdornment, margin, cols, rows, label, select, placeholder, defaultValue, showLabel = true, borderRadius = 35, height, boxShadow, border, SelectProps, type, value, validate, variant, onChange, className, handleClickShowPassword, showPassword, handleMouseDownPassword }) => {
    const [error, setError] = useState(false);
    const [helperText, setHelperText] = useState('');

    useEffect(() => {
        if (submitted && validate) {
            const validationErrors = validate(value);
            if (Array.isArray(validationErrors)) {
                const isValid = validationErrors?.length === 0;
                setError(!isValid);
                setHelperText(isValid ? '' : validationErrors.join(' '));
            }
        }
    }, [submitted]);


    const handleChange = (event) => {
        const { value } = event.target;

        if (onChange) {
            onChange(event);
        }

        if (validate) {
            const validationErrors = validate(value);

            if (Array.isArray(validationErrors)) {
                const isValid = validationErrors?.length === 0;
                setError(!isValid);
                setHelperText(isValid ? '' : validationErrors.join(' '));
            }
        }
    };
    const sx = {
        borderRadius: borderRadius, backgroundColor: backgroundColor, height: height, boxShadow: boxShadow, border: border
    };

    const FormHelperTextPropsStyle = {
        sx: {
            fontSize: errorFontSize,
        }
    }

    return (
        <TextField
            name={name}
            select={select}
            value={value}
            id={id}
            rows={rows}
            margin={margin}
            cols={cols}
            multiline={multiline}
            placeholder={placeholder}
            defaultValue={defaultValue}
            SelectProps={SelectProps}
            className={className}
            label={showLabel ? label : null}
            type={type}
            variant={variant}
            onChange={handleChange}
            error={error}
            helperText={helperText}
            fullWidth
            FormHelperTextProps={FormHelperTextPropsStyle}
            InputProps={
                showPassword === undefined ? { sx: sx, startAdornment: startAdornment } : {
                    endAdornment: <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>,
                    sx: sx
                }}

        />
    );
};

export default CommonTextField;
