import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface cancelSubscription {
    isLoading: boolean;
    error: string | null;
    plan: any | null;
}

const initialState: cancelSubscription = {
    plan: null,
    isLoading: false,
    error: null,
};


const cancelSubsciptionSlice = createSlice({
    name: 'cancelSubscription',
    initialState,
    reducers: {
        setCancelSubscription: (state: cancelSubscription, action: PayloadAction<cancelSubscription>) => {
            state.plan = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: cancelSubscription, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: cancelSubscription, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetCancelSubscription: () => {
            return initialState;
        },
    },
});

export const { setCancelSubscription, setloading, seterror, resetCancelSubscription } = cancelSubsciptionSlice.actions;

export default cancelSubsciptionSlice.reducer;










































