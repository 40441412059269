import React, { useState, useRef, useEffect } from 'react';
import './CustomDropdown.css'; // Make sure to create the CSS file

const CustomDropdown = ({ options, setTeacherCardClass }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const dropdownRef = useRef(null);

  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = value => () => {
    setSelectedOption(value);
    setIsOpen(false);
    setTeacherCardClass(`teacher-card-${value.id}`);
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="dropdown-container" ref={dropdownRef}>
      <div className="dropdown-tiles" onClick={toggling}>
        <img className="selected-image" src={selectedOption.img} alt="Selected" />
        <span className="selected-text">{selectedOption.label}</span>
        <span className="dropdown-arrow">{isOpen ? '▲' : '▼'}</span>
      </div>
      {isOpen && (
        <div className="dropdown-list-container">
          <ul className="dropdown-list">
            {options.map(option => (
              <li className="dropdown-list-item" key={option.id} onClick={onOptionClicked(option)}>
                <img className="dropdown-image" src={option.img} alt={option.label} />
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;