import React from 'react';
import { delete_alert_popup } from "../../assets/images/images";
import "./RemovePopup.css";

const RemovePopup = ({ title, cancel, submit, onclick, onClose }) => { // Add onClose prop

    return (
        <>
            <div className='are-you-sure-delete-popup-section' id='remove-popup-section'>
                <div className="are-you-sure-delete-popup-section-container">
                    <div className="are-you-sure-delete-popup-section-folder">
                        <div className='delete-alert-popup'>
                            <img src={delete_alert_popup} />
                        </div>
                        <h2>{title}</h2>
                        <div className="Course-close-btn">
                            <button type="button" onClick={onClose}>{cancel}</button> {/* Add onClick handler */}
                            <button type="submit" className="Course-close" onClick={onclick}>{submit}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default RemovePopup;