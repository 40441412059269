export function formatApiErrors(apiResponse) {
    const errors = apiResponse?.errors;
    const message = apiResponse?.message;
    if (errors && errors && typeof errors === 'object') {
        const formattedMessages = [];

        for (const field in errors) {
            if (errors.hasOwnProperty(field)) {
                const messages = errors[field];
                const formattedField = field.charAt(0).toUpperCase() + field.slice(1);
                const formattedMessage = `${formattedField}: ${messages.join(' & ')}`;
                formattedMessages.push(formattedMessage);
            }
        }

        return formattedMessages.join(' & ');
    } else {
        // Handle cases where the API response format is unexpected.
        return message || 'An error occurred.'
    }
}