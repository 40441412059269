import React from 'react';




const TeacherViewLession = () => {
  return (
    <>
        <section className='fundat_sec_new'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-lg-12 col-md-12'>
                        <div className='fundat_sec_new_inner'>
                            <h6>Fundamental of Neuroscience</h6>
                            <p>Science, spanning fields like biology and physics, systematically explores the natural world through observation and experimentation, revealing its fundamental laws. Beyond unraveling how things work, it fosters innovation and a profound understanding of our universe's intricacies, shaping our world and future.</p>
                            <div className='fundat_sec_new_inner_text'>
                                <h5>I . Neurons: The Building Blocks of the Nervous System</h5>
                                <span>A. Neuron Structure</span>
                                <ul>
                                    <li>Cell Body (Soma): Contains the nucleus and essential organelles.</li>
                                    <li>Dendrites: Receive incoming signals from other neurons or sensory receptors.</li>
                                    <li>Axon: Transmits electrical signals (action potentials) away from the cell body.</li>
                                    <li>Myelin Sheath: Insulates and speeds up signal transmission along the axon.</li>
                                    <li>Axon Terminals: Release neurotransmitters to communicate with other neurons or target cells.</li>
                                </ul>
                                <span>B. Neuron Function</span>
                                <ul>
                                    <li>Neurons are the functional units of the nervous system.</li>
                                    <li>They transmit and process information through electrical and chemical signals.</li>
                                </ul>
                                <span>C. Neural Impulse</span>
                                <ul>
                                    <li> Action Potential: A rapid, temporary change in electrical charge along the axon.</li>
                                    <li>Action potentials are initiated when a stimulus reaches a threshold.</li>
                                    <li>Sodium (Na+) and potassium (K+) ion channels play a crucial role in generating action potentials.</li>
                                    <li> Sodium-potassium pumps maintain ion balance within the neuron.</li>
                                </ul>

                                <h5>2. Neural Communication</h5>
                                <span>A. Synapses</span>
                                <ul>
                                    <li>Neurons communicate at synapses, which are junctions between two neurons.</li>
                                    <li>The presynaptic neuron sends signals, while the postsynaptic neuron receives signals.</li>
                                </ul>
                                <span>B. Neurotransmitters</span>
                                <ul>
                                    <li>Neurotransmitters are chemical messengers that transmit signals across synapses.</li>
                                    <li>Common neurotransmitters include dopamine, serotonin, and acetylcholine.</li>
                                    <li>Neurotransmitters are stored in vesicles within axon terminals.</li>
                                </ul>
                                <span>C. Neural Signal Transmission</span>
                                <ul>
                                    <li>Action potentials travel down the axon to axon terminals.</li>
                                    <li>When the action potential reaches axon terminals, it triggers the release of neurotransmitters.</li>
                                    <li>Neurotransmitters cross the synaptic cleft and bind to receptors on the postsynaptic neuron.This binding can lead to excitation (depolarization) or inhibition (hyperpolarization) of the postsynaptic neuron.</li>
                                </ul>
                                <span>D. Reuptake</span>
                                <ul>
                                    <li>Neurotransmitter molecules can be reabsorbed by the presynaptic neuron through a process called reuptake.</li>
                                    <li>Reuptake helps regulate neurotransmitter levels in the synaptic cleft.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default TeacherViewLession