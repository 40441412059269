import { Dispatch } from "redux";
import api from "../../api/api";
import {
    setTeacherGeneraterRevenue, setPagination, setloading, seterror, resetTeacherGeneraterRevenue
} from "../reducers/teacherGeneraterRevenueReducer";

const getTeacherGeneraterRevenueReducer = (month: any, page: any, sort: any, search: any, token: any, year: any, option: any) => async (dispatch: Dispatch) => {
    dispatch(setloading(true));
    try {
        const result = await api
            .get(
                `courses/course-revenue-detail/`,
                {
                    params: {
                        search: search,
                        page: page,
                        sort:sort,
                        month: option === "Monthly" ? month : "",
                        year: option === "Yearly" ? year : "",
                    },
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Token ${token}`
                    },
                }
            )

        if (result) {
            dispatch(setloading(false));
            if (result.data?.status === 200) {
                dispatch(setTeacherGeneraterRevenue(result.data.data));
                dispatch(setPagination(result.data));
            }
            else {
                dispatch(seterror(result.data?.message ?? "Something Went Wrong!"));
            }
        }
    }
    catch (error: any) {
        dispatch(seterror(error.response.data.message));
    }
}

export { getTeacherGeneraterRevenueReducer, resetTeacherGeneraterRevenue }

