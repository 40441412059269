import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Lesson {
    lesson: any;
    next: string | null;
    previous: string | null;
    count: number | null;
    totalPages: number | null;
    isLoading: boolean;
    error: string | null;
}

const initialState: Lesson = {
    lesson: [],
    next: null,
    previous: null,
    count: 0,
    totalPages: 0,
    isLoading: false,
    error: null,
};

const lessonSlice = createSlice({
    name: "lessonData",
    initialState,
    reducers: {
        setgetLesson: (state: Lesson, action: PayloadAction<Lesson>) => {
            state.lesson = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: Lesson, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: Lesson, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetgetLesson: () => {
            return initialState;
        },
    },
});

export const {
    setgetLesson,
    setloading,
    seterror,
    resetgetLesson,
} = lessonSlice.actions;

export default lessonSlice.reducer;
