import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Course {
    course_id: number;
    created_at: string;
    end_date: string;
    enrolled_students: number;
    from_grade: string;
    description: string;
    image: null | string; // It can be either null or a string
    lesson_count: number;
    name: string;
    price: string;
    start_date: string;
    student_request_count: number;
    subject: string;
    teacher: string;
    teacher_id: string;
    to_grade: string;
    updated_at: string;
}

interface CourseState {
    courses: Course[];
    next: string | null;
    previous: string | null;
    count: number | null;
    totalPages: number | null;
    isLoading: boolean;
    error: string | null;
}

const initialState: CourseState = {
    courses: [],
    next: null,
    previous: null,
    count: 0,
    totalPages: 0,
    isLoading: false,
    error: null,
};

const courseListslice = createSlice({
    name: "courseList",
    initialState,
    reducers: {
        setCourseList: (state: CourseState, action: PayloadAction<Course[]>) => {
            state.courses = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        setPagination: (state: CourseState, action: PayloadAction<CourseState>) => {
            state.next = action.payload.next;
            state.previous = action.payload.previous;
            state.count = action.payload.count;
            state.totalPages = Math.ceil((action.payload.count ?? 0) / 12);
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: CourseState, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: CourseState, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetCourseList: () => {
            return initialState;
        },
    },
});

export const {
    setCourseList,
    setloading,
    seterror,
    setPagination,
    resetCourseList,
} = courseListslice.actions;

export default courseListslice.reducer;
