import React, { useEffect, useState } from 'react';
import "./SubscriptionComparisonTable.css";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SavePopUp from '../../SavePopUp/SavePopUp';

function SubscriptionComparisonTable({ title }) {

    const navigate = useNavigate();
    const teacherCurrentPlan = useSelector((state) => state.teacherCurrentPlan);
    const studentCurrentPlan = useSelector((state) => state.studentCurrentPlan);
    const [current_plan, setCurrent_plan] = useState(null)
    const [current_plan_id, setCurrent_plan_id] = useState(null)

    const { teachersubscription, isLoading, error } = useSelector((state) => state.teacherSubscription);
    const { studentSubscription } = useSelector((state) => state.studentSubscription);

    const [previesPlanId, setPreviesPlanId] = useState(null)
    const globalState = useSelector((state) => state.globalState);
    const [userType, setUserType] = useState([])



    useEffect(() => {
        if (globalState.role === 'Teacher') {
            setUserType(teachersubscription)
        } else {
            setUserType(studentSubscription)
        }

    }, [teachersubscription, studentSubscription])


    const [isPopUp, setIsPopUp] = useState({
        open: false,
        text: null
    })
    const onClose = () => {
        setIsPopUp({
            open: false,
            text: null
        })
    }

    const [yearllyPlan, setYearllyPlan] = useState({
        plan_id: null,
        plan_type: null,
        index: null
    })




    const plans = [
        {
            name: 'Basic',
            price: '$96 Billed Yearly',
            features: {
                'Course Description': true,
                'Share Course': true,
                'Invite Student for Course': true,
                'Image Generation': false,
                'Lesson Creation with File Upload (up to 3 files, each up to 25MB, total 75MB)': false,
                'Assignment Creation (1 or Multiple)': false,
                'Student Assessment': false,
                'Assignment Grading': false,
                'Work Board Access': false,
                'Download / Save / Copy Content': false,
                'Upload Course Price': true,
                'Create AI Assistant': false,
            },
        },
        {
            name: 'Balanced',
            price: '$240 Billed Yearly',
            features: {
                'Course Description': true,
                'Share Course': true,
                'Invite Student for Course': true,
                'Image Generation': true,
                'Lesson Creation with File Upload (up to 3 files, each up to 25MB, total 75MB)': true,
                'Assignment Creation (1 or Multiple)': true,
                'Student Assessment': true,
                'Assignment Grading': true,
                'Work Board Access': false,
                'Download / Save / Copy Content': true,
                'Upload Course Price': true,
                'Create AI Assistant': true,
            },
        },
        {
            name: 'Best',
            price: '$480 Billed Yearly',
            features: {
                'Course Description': true,
                'Share Course': true,
                'Invite Student for Course': true,
                'Image Generation': true,
                'Lesson Creation with File Upload (up to 3 files, each up to 25MB, total 75MB)': true,
                'Assignment Creation (1 or Multiple)': true,
                'Student Assessment': true,
                'Assignment Grading': true,
                'Work Board Access': true,
                'Download / Save / Copy Content': true,
                'Upload Course Price': true,
                'Create AI Assistant': true,
            },
        },
    ];

    const featuresList = [
        'Course Description',
        'Share Course',
        'Invite Student for Course',
        'Image Generation',
        'Lesson Creation with File Upload (up to 3 files, each up to 25MB, total 75MB)',
        'Assignment Creation (1 or Multiple)',
        'Student Assessment',
        'Assignment Grading',
        'Work Board Access',
        'Download / Save / Copy Content',
        'Upload Course Price',
        'Create AI Assistant',
    ];
    const featuresList_With_Key_Name = {
        'Course Description': 'course_description',
        'Share Course': 'share_course',
        'Invite Student for Course': 'invite_student',
        'Image Generation': 'image_generation',
        'Lesson Creation with File Upload (up to 3 files, each up to 25MB, total 75MB)': 'lesson_creation',
        'Assignment Creation (1 or Multiple)': 'assignment_creation',
        'Student Assessment': 'student_assessment',
        'Assignment Grading': 'assignment_grading',
        'Work Board Access': 'work_board_access',
        'Download / Save / Copy Content': 'download_save_copy',
        'Upload Course Price': 'upload_course_price',
        'Create AI Assistant': 'create_ai_assessment',
    };
    const Student_featuresList_With_Key_Name = {
        'AI Assistant': 'ai_assistant',
        'Universal Chat Bot': 'universal_chat_bot',
        'Practice Assignment': 'practice_assignment',
        'Marketplace Access': 'marketplace_access',
        'Download File': 'download_file',
    };




    useEffect(() => {
        if (globalState.role === 'Student') {
            if (globalState?.userCurrentPlan?.error === null) {
                setCurrent_plan(globalState?.userCurrentPlan?.currentSubscription?.subscription_id)
                setCurrent_plan_id(globalState?.userCurrentPlan?.currentSubscription?.plan_id)
            }
        } else {
            if (globalState?.userCurrentPlan?.error === null) {
                setCurrent_plan(globalState?.userCurrentPlan?.currentSubscription?.subscription_id)
                setCurrent_plan_id(globalState?.userCurrentPlan?.currentSubscription?.plan_id)
            }
        }
    }, [globalState.userCurrentPlan])




    function handleClick(plan, index, userType, current_plan_id, plan_type) {


        let status

        if (current_plan) {
            if (previesPlanId < index) {
                status = 6
            } else {
                status = 5
            }
        }

        navigate('/subscription-plan-payment', { state: { plans: title, planDetails: plan, current_plan: current_plan, status: status, AllPlans: userType, current_plan_id: current_plan_id, plan_type } })

    }



    useEffect(() => {
        if (current_plan_id !== null) {
            for (let index = 0; index < userType.length; index++) {

                if (current_plan_id === userType[index].plan_id) {
                    setYearllyPlan({
                        plan_id: userType[index].plan_id,
                        plan_type: globalState.userCurrentPlan.currentSubscription ? globalState.userCurrentPlan.currentSubscription.type == 1 ? 'Monthly' : 'Yearly' : null,
                        index: index
                    })
                    break
                } else {
                    setYearllyPlan({
                        plan_id: null,
                        plan_type: globalState.userCurrentPlan.currentSubscription ? globalState.userCurrentPlan.currentSubscription.type == 1 ? 'Monthly' : 'Yearly' : null,
                        index: index
                    })
                }
            }
        }
    }, [globalState?.role, current_plan_id, userType])


    useEffect(() => {

        userType.forEach((plans, index) => {
            if (current_plan_id === plans.plan_id) {
                setPreviesPlanId(index);
            }
        });
    }, [current_plan_id, teachersubscription, studentSubscription]);







    return (
        <>
            <div className='Subscription-Comparison-Table-section'>
                <div className='Subscription-Comparison-Table-wrapper'>
                    <h2>See Full Comparison</h2>
                    <div className="comparison-table">
                        <div className='Subscription-Comparison-Table'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            <div className='comparison-table-inner-header'>
                                                <h2>Comparison Table</h2>
                                                <p>{globalState?.role}</p>
                                            </div>
                                        </th>

                                        {
                                            userType.map((plan, index) => (
                                                <th key={index} className="plan">
                                                    <div className="plan-header">
                                                        <span>{plan.name}</span>
                                                        <button className={current_plan_id === plan.plan_id ? 'active' : ''} disabled={current_plan_id === plan.plan_id}
                                                            onClick={() => {


                                                                if (!current_plan_id) {
                                                                    handleClick(plan, index, userType, current_plan_id, yearllyPlan.plan_type)
                                                                } else if ((yearllyPlan.plan_type === 'Yearly' && title)) {
                                                                    setIsPopUp({
                                                                        open: true,
                                                                        text: "you can't degrade the plan"
                                                                    })
                                                                } else if ((yearllyPlan.plan_type === 'Monthly')) {
                                                                    if (title && yearllyPlan.index >= index) {
                                                                        setIsPopUp({
                                                                            open: true,
                                                                            text: "you can't degrade the plan"
                                                                        })
                                                                    } else {
                                                                        handleClick(plan, index, userType, current_plan_id, yearllyPlan.plan_type)
                                                                    }
                                                                } else if (yearllyPlan.plan_type === 'Yearly') {
                                                                    if (!title && yearllyPlan.index >= index) {
                                                                        setIsPopUp({
                                                                            open: true,
                                                                            text: "you can't degrade the plan"
                                                                        })
                                                                    } else {
                                                                        handleClick(plan, index, userType, current_plan_id, yearllyPlan.plan_type)
                                                                    }
                                                                } else {
                                                                    handleClick(plan, index, userType, current_plan_id, yearllyPlan.plan_type)
                                                                }
                                                            }}
                                                        >
                                                            {current_plan_id ? current_plan_id === plan.plan_id ? 'Active Plan' : 'Upgrade' : 'Subscribe'}
                                                        </button>
                                                        <span>${plan.price}</span>
                                                    </div>
                                                </th>
                                            ))
                                        }


                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        Object.keys(globalState.role === 'Student' ? Student_featuresList_With_Key_Name : featuresList_With_Key_Name).map((feature, featureIndex) => (
                                            <tr key={featureIndex} className={featureIndex % 2 === 1 ? 'highlight' : ''}>
                                                <td>
                                                    <ul className='feature-points'>
                                                        <li>{feature}</li>
                                                    </ul>
                                                </td>
                                                {
                                                    userType.length > 0 && userType.map((plan, planIndex) => (
                                                        <td
                                                            key={planIndex}
                                                            className={plan[globalState.role === 'Student' ? Student_featuresList_With_Key_Name[feature] : featuresList_With_Key_Name[feature]] ? 'right-icon' : 'wrong-icon'}
                                                            dangerouslySetInnerHTML={{
                                                                __html: plan[globalState.role === 'Student' ? Student_featuresList_With_Key_Name[feature] : featuresList_With_Key_Name[feature]]
                                                                    ? '<i class="fa-solid fa-check"></i>'
                                                                    : '<i class="fa-solid fa-x"></i>',
                                                            }}
                                                        ></td>
                                                    ))
                                                }
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </div >
            <SavePopUp isOpen={isPopUp.open} onClose={onClose} text={isPopUp.text} />
        </>
    );
}

export default SubscriptionComparisonTable;
