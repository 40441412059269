const CourseReducer = (state, action) => {
    switch (action.type) {
        case "SET_LOADING":
            // call api using axios
            return {
                ...state,
                loading: true,
                filter_data: [],
                all_data: [],
                total:0,
            };
        case "SET_API_DATA":
            return {
                ...state,
                all_data : action.payload,
                filter_data : action.payload,
                total : action.payload.length,
                loading: false,
                error: false,
                errormsg: ''
            };
        case "API_ERROR":
            return {
                ...state,
                loading: false,
                errormsg : action.errormsg
            };
        case "UPDATE_LESSION_FILTER":
            const { name, value } = action.payload;
            let tempFilterData = [...state.all_data];
            if (name!=='' && value!=='') {
                tempFilterData = tempFilterData.filter((curElem) => {
                    return curElem.student_name.toLowerCase().includes(value);
                });
            }
            return {
                ...state,
                filter_data: tempFilterData,
                filters: {
                    ...state.filters,
                    [name]: value,
                },
            };
        default:
            return state;
    }
}

export default CourseReducer