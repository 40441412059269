class Helper {
    
    static formatDateTime(datetimeStr) {
      const dateTime = new Date(datetimeStr);
    
      // Months mapping
      const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
      ];
    
      // Get date in "Month Day, Year" format
      const formattedDate = `${months[dateTime.getMonth()]} ${dateTime.getDate()}, ${dateTime.getFullYear()}`;
    
      // Get time in "h:mm AM/PM" format
      const hours = dateTime.getHours();
      const minutes = dateTime.getMinutes();
      const seconds = dateTime.getSeconds();
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 === 0 ? 12 : hours % 12; // Convert to 12-hour format
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    
      const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;
      return { date: formattedDate, time: formattedTime };
    }
    static getClassNameByStatus(status) {
      switch (status) {
        case 'Completed':
          return 'completed-class';
        case 'Pending':
          return 'pending-class';
        case 'In progress':
          return 'in-progress-class';
        case 'Overdue':
          return 'overdue-class';
        // Add more cases as needed
        default:
          return 'default-class';
      }
    }
    static formatSecondsToDuration(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
    
      if (minutes > 0 && remainingSeconds > 0) {
        return `${minutes} min ${remainingSeconds} sec`;
      } else if (minutes > 0) {
        return `${minutes} min`;
      } else {
        return `${remainingSeconds} sec`;
      }
    }

  static months = [
    { month: 1, name: "Jan" },
    { month: 2, name: "Feb" },
    { month: 3, name: "Mar" },
    { month: 4, name: "Apr" },
    { month: 5, name: "May" },
    { month: 6, name: "Jun" },
    { month: 7, name: "Jul" },
    { month: 8, name: "Aug" },
    { month: 9, name: "Sep" },
    { month: 10, name: "Oct" },
    { month: 11, name: "Nov" },
    { month: 12, name: "Dec" },
  ];

    
}
  
export default Helper;