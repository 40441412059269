import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UpdateSchool {
    school: any
    isLoading: boolean;
    error: string | null;
    message: string | null;
    school_id: number | null;
}

const initialState: UpdateSchool = {
    school: {},
    message: null,
    isLoading: false,
    error: null,
    school_id: null,
};

const updateschoolSlice = createSlice({
    name: 'updateSchool',
    initialState,
    reducers: {
        setUpdateSchool: (state: UpdateSchool, action: PayloadAction<UpdateSchool>) => {
            state.school = action.payload;
            state.message = "Update Successfull";
            state.isLoading = false;
            state.error = null;
        },
        setSchoolId: (state: UpdateSchool, action: PayloadAction<UpdateSchool>) => {
            state.school_id = action.payload.school_id;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: UpdateSchool, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: UpdateSchool, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetUpdateSchool: () => {
            return initialState;
        },
    },
});

export const { setUpdateSchool, setSchoolId, setloading, seterror, resetUpdateSchool } = updateschoolSlice.actions;

export default updateschoolSlice.reducer;
