import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Notifications {
    notifications: any;
    isLoading: Boolean;
    error: string | null;
}

const initialState: Notifications = {
    notifications: [],
    isLoading: false,
    error: null,
};

const notificationListSlice = createSlice({
    name: 'notificationLists',
    initialState,
    reducers: {
        setNotificationList: (state : Notifications, action: PayloadAction<Notifications>) => {
            state.notifications = state.notifications.concat(action.payload);
            state.isLoading = false;
            state.error = null;
        },
        isloading: (state: Notifications, action: PayloadAction<Boolean>) => {
            state.isLoading = action.payload;
            state.error = null; 
        },
        seterror: (state : Notifications, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetNotificationList: () => {
            return initialState;
        },
    },
});

export const { setNotificationList, isloading, seterror, resetNotificationList } = notificationListSlice.actions;

export default notificationListSlice.reducer;
