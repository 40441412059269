import { Dispatch } from "redux";
import api from "../../api/api";
import {
    setAssignmentsList,
    setloading,
    seterror,
    setPagination,
    resetAssignmentsList,
} from "../reducers/getAssignmentsByLessonReducer";

const getAssignmentsByLesson = (id: any, page: any, token: any, search: any, sort: any) => async (
    dispatch: Dispatch
) => {
    dispatch(setloading(true));
    try {
        const result = await api.get(`/assignments/get-assignments-by-lesson/${id}/`, {
            params: {
                page: page,
                search: search,
                sort: sort
            },
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Token ${token}`,
            },
        });

        if (result) {
            dispatch(setloading(false));
            if (result.data?.status === 200) {
                dispatch(setAssignmentsList(result.data.data));
                dispatch(setPagination(result.data));
            } else {
                dispatch(seterror(result.data?.message ?? "Something Went Wrong!"));
            }
        }
    } catch (error: any) {
        //dispatch(seterror(error.response.data.message));
    }
};

export { getAssignmentsByLesson, resetAssignmentsList };

