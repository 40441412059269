// import React from "react";
// import { lesson_list_assignment_count_image } from "../../assets/images/images";
// import "./LessonList.css";

//   const LessonList = () => {

//     return (
//       <div className="view-lessons-created-course-bg-wrapper">
//         <div className="lesson-list-wrapper">
//           <div className="lesson-list-container">
//             <div
//               className="lesson-list-preview-title-folder">
//               <i class="fa-solid fa-grip-vertical"></i>
//               <p>Lesson <span>01</span></p>
//               <div className="lesson-list-edit-course-title">
//                 <input type="text"/>
//                 <i class="fa-solid fa-pen"></i>
//               </div>
//             </div>
//             <div className="lesson-list-btn-folder">
//               <div className="lesson-list-assignment-count">
//                 <div className="lesson-list-assignment-count-image">
//                     <img src={lesson_list_assignment_count_image}/>
//                 </div>
//                 <p><span>5</span> assignment</p>
//               </div>
//               <div className="View-Progress-btn">
//                 Lesson Progress
//               </div>
//               <button
//                 type="button" className="lesson-list-edit-btn"><i class="fa-solid fa-pen"></i> Edit</button>
//                 <div className="lesson-delete-btn">
//                     <i class="fa-solid fa-trash" data-tooltip-id="delete-lesson"></i>
//                 </div>
//               <Tooltip
//                 id="delete-lesson"i
//                 place="bottom"
//                 content="Delete Lesson"
//                 type="light"
//                 effect="float"
//               />
//             </div>
//           </div>
//           <div className="lesson-list-container">
//             <div
//               className="lesson-list-preview-title-folder">
//               <i class="fa-solid fa-grip-vertical"></i>
//               <p>Lesson <span>01</span></p>
//               <div className="lesson-list-edit-course-title">
//                 <input type="text"/>
//                 <i class="fa-solid fa-pen"></i>
//               </div>
//             </div>
//             <div className="lesson-list-btn-folder">
//               <div className="lesson-list-assignment-count">
//                 <div className="lesson-list-assignment-count-image">
//                     <img src={lesson_list_assignment_count_image}/>
//                 </div>
//                 <p><span>5</span> assignment</p>
//               </div>
//               <div className="View-Progress-btn">
//                 Lesson Progress
//               </div>
//               <button
//                 type="button" className="lesson-list-edit-btn"><i class="fa-solid fa-pen"></i> Edit</button>
//                 <div className="lesson-delete-btn">
//                     <i class="fa-solid fa-trash" data-tooltip-id="delete-lesson"></i>
//                 </div>
//               <Tooltip
//                 id="delete-lesson"i
//                 place="bottom"
//                 content="Delete Lesson"
//                 type="light"
//                 effect="float"
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   };

// export default LessonList;

// import React, { useState } from "react";
// import { lesson_list_assignment_count_image } from "../../assets/images/images";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import "./LessonList.css";

// const LessonList = () => {
//   const initialLessons = [
//     { id: "lesson-1", title: "Basic structure of neurons", assignmentCount: 5 },
//     { id: "lesson-2", title: "Advanced structure of neurons", assignmentCount: 3 },
//     { id: "lesson-3", title: "Advanced structure of neurons", assignmentCount: 3 },
//   ];

//   const [lessons, setLessons] = useState(initialLessons);

//   const handleDragEnd = (result) => {
//     if (!result.destination) return;

//     const updatedLessons = Array.from(lessons);
//     const [reorderedItem] = updatedLessons.splice(result.source.index, 1);
//     updatedLessons.splice(result.destination.index, 0, reorderedItem);

//     setLessons(updatedLessons);
//   };

//   return (
//     <DragDropContext onDragEnd={handleDragEnd}>
//       <div className="view-lessons-created-course-bg-wrapper">
//         <Droppable droppableId="lesson-list">
//           {(provided, snapshot) => (
//             <div
//               className="lesson-list-wrapper"
//               {...provided.droppableProps}
//               ref={provided.innerRef}
//             >
//               {lessons.map((lesson, index) => (
//                 <Draggable key={lesson.id} draggableId={lesson.id} index={index}>
//                   {(provided, snapshot) => (
//                     <div
//                       className="lesson-list-container"
//                       ref={provided.innerRef}
//                       {...provided.draggableProps}
//                       {...provided.dragHandleProps}
//                       style={{
//                         userSelect: "none",
//                         padding: 16,
//                         margin: `0 0 8px 0`,
//                         background: snapshot.isDragging ? "lightgreen" : "white",
//                         ...provided.draggableProps.style,
//                       }}
//                     >
//                       <div className="lesson-list-preview-title-folder">
//                         <i className="fa-solid fa-grip-vertical" style={{ cursor: "grab" }}></i>
//                         <p>{`Lesson ${index + 1}`}</p>
//                         <div className="lesson-list-edit-course-title">
//                           <input type="text" defaultValue={lesson.title} />
//                           <i className="fa-solid fa-pen"></i>
//                         </div>
//                       </div>
//                       <div className="lesson-list-btn-folder">
//                         <div className="lesson-list-assignment-count">
//                           <div className="lesson-list-assignment-count-image">
//                             <img src={lesson_list_assignment_count_image} alt="Assignment Count" />
//                           </div>
//                           <p>
//                             <span>{lesson.assignmentCount}</span> assignments
//                           </p>
//                         </div>
//                         <div className="View-Progress-btn">Lesson Progress</div>
//                         <button type="button" className="lesson-list-edit-btn">
//                           <i className="fa-solid fa-pen"></i> Edit
//                         </button>
//                         <div className="lesson-delete-btn">
//                           <i className="fa-solid fa-trash" data-tooltip-id="delete-lesson"></i>
//                         </div>
//                       </div>
//                     </div>
//                   )}
//                 </Draggable>
//               ))}
//               {provided.placeholder}
//             </div>
//           )}
//         </Droppable>
//       </div>
//     </DragDropContext>
//   );
// };

// export default LessonList;

import React, { useState, useRef, useEffect } from 'react';
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { lesson_list_assignment_count_image, lesson_list_view_list_icon } from "../../assets/images/images";
import "./LessonList.css";

const ItemType = 'LESSON';

const DraggableLesson = ({ lesson, index, moveLesson, initialValue = '' }) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (item) => {
      if (item.index !== index) {
        moveLesson(item.index, index);
        item.index = index;  // Update the index after move
      }
    },
  });

  const [inputValue, setInputValue] = useState(initialValue);
  const [inputWidth, setInputWidth] = useState(10); // Starting width of 10px
  const spanRef = useRef(null);

  const updateWidth = () => {
    if (spanRef.current) {
      const newWidth = Math.min(Math.max(spanRef.current.scrollWidth, 10), 240); // Min width 10px, Max width 240px
      setInputWidth(newWidth);
    }
  };

  useEffect(() => {
    updateWidth(); // Update width on mount for pre-filled values
  }, []);

  useEffect(() => {
    updateWidth(); // Update width on input value change
  }, [inputValue]);

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <div
      ref={(node) => drop(drag(node))}
      className="lesson-list-container"
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: "move",
      }}
    >
      <div className="lesson-list-preview-title-folder">
        <div className='lesson-list-grip-folder'>
          <div className="lesson-list-grip">
            {isDragging ? (
              <i className="fa-solid fa-arrows-up-down-left-right" style={{ cursor: "grab", visibility: "visible" }}></i>
            ) : (
              <i className="fa-solid fa-grip-vertical" style={{ cursor: "grab", visibility: "visible" }}></i>
            )}
            <p>{`Lesson ${index + 1}`}</p>
          </div>
          <div className="lesson-delete-btn">
            <i className="fa-solid fa-trash" data-tooltip-id="delete-lesson"></i>
          </div>
        </div>
        <div className="lesson-list-edit-course-title">
          {/* <input type="text" defaultValue={lesson.title} style={{ width: `${inputWidth}px`, transition: 'width 0.2s' }}/> */}
          <input type="text" value={inputValue} onChange={handleChange} style={{ width: `${inputWidth}px`, transition: 'width 0.2s' }}/>
          <span ref={spanRef} style={{ visibility: 'hidden', whiteSpace: 'pre', position: 'absolute' }}>
            {inputValue}
          </span>
          <i className="fa-solid fa-pen"></i>
        </div>
      </div>
      <div className="lesson-list-btn-folder">
        <div className="lesson-list-assignment-count">
          <div className="lesson-list-assignment-count-image">
            <img src={lesson_list_assignment_count_image} alt="Assignment Count" />
          </div>
          <p>
            <span>{lesson.assignmentCount}</span> assignments
          </p>
        </div>
        <div className="View-Progress-btn">Lesson Progress</div>
        <button type="button" className="lesson-list-edit-btn">
          <i className="fa-solid fa-pen"></i> Edit
        </button>
        {/* <button type="button" className="lesson-list-view-btn">
          <img src={lesson_list_view_list_icon}/> view
        </button> */} 
        <div className="lesson-delete-btn">
          <i className="fa-solid fa-trash" data-tooltip-id="delete-lesson"></i>
        </div>
      </div>
    </div>
  );
};

const LessonList = () => {
  const initialLessons = [
    { id: "abc", title: "Basic ", assignmentCount: 5 },
    { id: "edg", title: "dhAYUSDFDSBIUDBhiu", assignmentCount: 2 },
    { id: "hgthj", title: "Advanced structure of neurons", assignmentCount: 3 },
    { id: "abcrfwetrhdth", title: "Basic ", assignmentCount: 5 },
    { id: "abcfesgdhd", title: "Basic ", assignmentCount: 5 },
    { id: "abcdwqeqr3q", title: "Basic ", assignmentCount: 5 },
    { id: "abcgwrshyfmgkugh", title: "Basic ", assignmentCount: 5 },
  ];

  const [lessons, setLessons] = useState(initialLessons);

  const moveLesson = (fromIndex, toIndex) => {
    const updatedLessons = Array.from(lessons);
    const [movedLesson] = updatedLessons.splice(fromIndex, 1);
    updatedLessons.splice(toIndex, 0, movedLesson);
    setLessons(updatedLessons);
  };

  return (
    // <DndProvider backend={HTML5Backend}>
    //   <div className="view-lessons-created-course-bg-wrapper">
    //     {lessons.map((lesson, index) => (
    //       <DraggableLesson
    //         key={lesson.id}
    //         index={index}
    //         lesson={lesson}
    //         moveLesson={moveLesson}
    //       />
    //     ))}
    //   </div>
    // </DndProvider>
    <>
    </>
  );
};

export default LessonList;





// working with lesson number start
// import React, { useState } from "react";
// import { DndProvider, useDrag, useDrop } from "react-dnd";
// import { HTML5Backend } from "react-dnd-html5-backend";
// import { lesson_list_assignment_count_image } from "../../assets/images/images";
// import "./LessonList.css";

// const ItemType = 'LESSON';

// const DraggableLesson = ({ lesson, index, moveLesson }) => {
//   const [, ref] = useDrag({
//     type: ItemType,
//     item: { index },
//   });

//   const [, drop] = useDrop({
//     accept: ItemType,
//     hover: (item) => {
//       if (item.index !== index) {
//         moveLesson(item.index, index);
//         item.index = index;
//       }
//     },
//   });

//   return (
//     <div
//       ref={(node) => ref(drop(node))}
//       className="lesson-list-container"
//       style={{
//         userSelect: "none",
//         background: "white",
//       }}
//     >
//       <div className="lesson-list-preview-title-folder">
//         <i className="fa-solid fa-grip-vertical" style={{ cursor: "grab" }}></i>
//         <p>{`Lesson ${lesson.number}`}</p>  {/* Use lesson.number instead of index */}
//         <div className="lesson-list-edit-course-title">
//           <input type="text" defaultValue={lesson.title} />
//           <i className="fa-solid fa-pen"></i>
//         </div>
//       </div>
//       <div className="lesson-list-btn-folder">
//         <div className="lesson-list-assignment-count">
//           <div className="lesson-list-assignment-count-image">
//             <img src={lesson_list_assignment_count_image} alt="Assignment Count" />
//           </div>
//           <p>
//             <span>{lesson.assignmentCount}</span> assignments
//           </p>
//         </div>
//         <div className="View-Progress-btn">Lesson Progress</div>
//         <button type="button" className="lesson-list-edit-btn">
//           <i className="fa-solid fa-pen"></i> Edit
//         </button>
//         <div className="lesson-delete-btn">
//           <i className="fa-solid fa-trash" data-tooltip-id="delete-lesson"></i>
//         </div>
//       </div>
//     </div>
//   );
// };

// const LessonList = () => {
//   const initialLessons = [
//     { id: "abc", number: 1, title: "Basic structure of neurons", assignmentCount: 5 },
//     { id: "edg", number: 2, title: "Advanced structure of neurons", assignmentCount: 2 },
//     { id: "hgthj", number: 3, title: "Advanced structure of neurons", assignmentCount: 3 },
//   ];

//   const [lessons, setLessons] = useState(initialLessons);

//   const moveLesson = (fromIndex, toIndex) => {
//     const updatedLessons = Array.from(lessons);
//     const [movedLesson] = updatedLessons.splice(fromIndex, 1);
//     updatedLessons.splice(toIndex, 0, movedLesson);
//     setLessons(updatedLessons);
//   };

//   return (
//     <DndProvider backend={HTML5Backend}>
//       <div className="view-lessons-created-course-bg-wrapper">
//         {lessons.map((lesson, index) => (
//           <DraggableLesson
//             key={lesson.id}
//             index={index}
//             lesson={lesson}
//             moveLesson={moveLesson}
//           />
//         ))}
//       </div>
//     </DndProvider>
//   );
// };

// export default LessonList;
// working with lesson number end