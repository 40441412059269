import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";

const PaginationView = (props) => {
    const handlePageChange = (event, value) => {
        props.setCurrentPage(value);
    };

    return (
        <Grid xs={12} container justifyContent={"center"}>
            <Pagination
                count={props.totalPages}
                page={props.currentPage}
                onChange={handlePageChange}
                color="primary"
                hideNextButton={
                    props.next === null || props.currentPage === props.totalPages
                } // Disable if no next URL and on the last page
                hidePrevButton={props.previous === null || props.currentPage === 1} // Hide if no previous URL and on the first page
            />
        </Grid>
    );
};

export default PaginationView;