import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ProfileLogin {
    school_id: string | null,
    token: string | null;
    first_login: boolean;
    user_id: string | null;
    isLoading: boolean;
    error: string | null;
    subdomain: string | null;
}

const initialState: ProfileLogin = {
    school_id: null,
    token: null,
    first_login: false,
    user_id: null,
    isLoading: false,
    error: null,
    subdomain: null,
};

const profileLoginslice = createSlice({
    name: 'profileLogin',
    initialState,
    reducers: {
        setProfileLogin: (state : ProfileLogin, action: PayloadAction<ProfileLogin>) => {
            state.school_id = action.payload.school_id;
            state.token = action.payload.token;
            state.subdomain = action.payload.subdomain;
            state.first_login = action.payload.first_login;
            state.user_id = action.payload.user_id;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: ProfileLogin, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null; 
        },
        seterror: (state : ProfileLogin, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetProfileLogin: () => {
            return initialState;
        },
    },
});

export const { setProfileLogin, setloading, seterror, resetProfileLogin } = profileLoginslice.actions;

export default profileLoginslice.reducer;
