import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FileUploadState {
    filesList: any;
    isLoading: boolean;
    error: string | null;
}

const initialState: FileUploadState = {
    filesList: [],
    isLoading: false,
    error: null,
};

const uploadFileSlice = createSlice({
    name: "uploadFile",
    initialState,
    reducers: {
        uploadfile: (state, action: PayloadAction<FileUploadState>) => {
            state.filesList = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        seterror: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetuploadFile: () => initialState,
    },
});

export const {
    uploadfile,
    setloading,
    seterror,
    resetuploadFile,
} = uploadFileSlice.actions;

export default uploadFileSlice.reducer;