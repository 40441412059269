import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ActiveTabState {
    activeTabState: string | null;
}

const initialState: ActiveTabState = {
    activeTabState: 'active',
};

const activeTabSlice = createSlice({
    name: 'activeTabState',
    initialState,
    reducers: {
        setActiveTabState: (state, action: PayloadAction<string>) => {
            state.activeTabState = action.payload;
        },
    },
});

export const { setActiveTabState } = activeTabSlice.actions;

export default activeTabSlice.reducer;
