import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import "./SubscriptionPlan.css";
import SubscriptionPlanStudentCard from './SubscriptionPlanStudentCard';
import SubscriptionPlanTeacherCard from './SubscriptionPlanTeacherCard';
import SubscriptionPlanEnterprice from './SubscriptionPlanEnterprice';
import SubscriptionComparisonTable from './SubscriptionComparisonTable/SubscriptionComparisonTable';
import BackButton from '../BackButton/BackButton';
import SubscriptionComparisonStudentTable from './SubscriptionComparisonStudentTable/SubscriptionComparisonStudentTable';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useAuth } from '../../features/auth/AuthContext';

function SubscriptionPlan({ setShowPricing }) {

    const globalState = useSelector((state) => state.globalState);
    const dispatch = useDispatch()
    const { token } = useAuth();

    // const [role, setRole] = useState('Student'); // Initialize with the first tab as active
    const [title, setTitle] = useState(true); // Initialize with the first tab as active

    const tabList = [
        {
            // title: "for students",
            // title: "Monthly",
            displayItem: <SubscriptionPlanStudentCard title={title} />,
            key: "Student",
            // route: "all-courses"
        },
        {
            // title: "for teachers",
            // title: "Yearly",
            displayItem: <SubscriptionPlanTeacherCard title={title} />,
            key: "Teacher",
            // route: "expired-courses"
        },
        // {
        //     title: "for schools",
        //     displayItem: <SubscriptionPlanEnterprice />,
        //     key: "for-schools",
        //     route: "inactive-courses"
        // },
    ];


    // useEffect(() => {
    //     if (token) {
    //         dispatch(userActivePlan(token))
    //     }
    // }, [])



    // useEffect(() => {
    //     if (globalState.role === 'Student') {
    //         setRole('Student')
    //     } else {
    //         setRole('Teacher')
    //     }
    // }, [])

    // Function to handle tab change
    const handleTabClick = (key, e) => {
        e.preventDefault(); // Prevent the default link behavior
        // setActiveTab(key); // Set the clicked tab as active
    };

    // Find the tab that matches the activeTab key to render its displayItem
    // const activeTabContent = tabList.find(tab => tab.key === role)?.displayItem;



    return (
        <>
            <div className='subscription-plan-section'>
                <div className='subscription-plan-wrapper'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12'>
                                <BackButton buttonName={"Back"} />
                                <div className='subscription-plan-container'>
                                    {/* <span className='subscription-plan-container-close' onClick={() => setShowPricing(false)}><i class="fa-solid fa-x"></i></span> */}
                                    {/* <h2>Choose your plan</h2> */}
                                    <div className='subscription-plan-tabs-folder'>
                                        <div className="studentDashboardSidePannel Custom_Tabs_section">
                                            <button
                                                className={`tab-link ${title ? 'active' : ''}`}
                                                onClick={() => setTitle(true)}
                                            >
                                                Monthly
                                            </button>
                                            <button
                                                className={`tab-link ${title ? '' : 'active'}`}
                                                onClick={() => setTitle(false)}
                                            >
                                                Yearly <span>-20%</span>
                                            </button>
                                            {/* {tabList.map((tab) => (
                                                <>
                                                    <button
                                                        className={`tab-link ${role === tab.key ? 'active' : ''}`}
                                                        onClick={setTitle(!title)}>
                                                        {title ? 'Monthly' : 'Yearly'}
                                                    </button>
                                                </>
                                                // <Link
                                                //     key={tab.key}
                                                //     to={`/dashboard/courses/${tab.route}`}
                                                //     className={`tab-link ${activeTab === tab.key ? 'active' : ''}`}
                                                //     onClick={(e) => handleTabClick(tab.key, e)}
                                                // >
                                                //     {tab.title}
                                                // </Link>
                                            ))} */}
                                        </div>
                                    </div>
                                    {globalState.role === 'Student' ? <SubscriptionPlanStudentCard title={title} /> : <SubscriptionPlanTeacherCard title={title} />}
                                    <SubscriptionComparisonTable title={title} />
                                    {/* <SubscriptionComparisonStudentTable/> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SubscriptionPlan;
