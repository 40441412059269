import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface GenerateAssignmentJson {
    isLoading: boolean;
    error: string | null;
    message: string | null;
    success: boolean;
}

const initialState: GenerateAssignmentJson = {
    message: null,
    isLoading: false,
    error: null,
    success: false,
};


const generateAssignmentJsonSlice = createSlice({
    name: 'generateAssignmentjson',
    initialState,
    reducers: {
        generateAssignJson: (state: GenerateAssignmentJson, action: PayloadAction<GenerateAssignmentJson>) => {
            state.message = action.payload.message;
            state.isLoading = false;
            state.error = null;
            state.success = true;
        },
        setloading: (state: GenerateAssignmentJson, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
            state.success = false;
        },
        seterror: (state: GenerateAssignmentJson, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
            state.success = false;
        },
        resetgenerateAssignJson: () => {
            return initialState;
        },
    },
});

export const { generateAssignJson, setloading, seterror, resetgenerateAssignJson } = generateAssignmentJsonSlice.actions;

export default generateAssignmentJsonSlice.reducer;