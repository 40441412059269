import "react-datepicker/dist/react-datepicker.css";
import "../../../pages/home/styles/dashboard.css";

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import api from "../../../api/api";
import { useAuth } from "../../../features/auth/AuthContext";

import "aos/dist/aos.css";
import BackButton from "../../../components/BackButton/BackButton";
import "./MessageChat.css"

const MessageChat = () => {
  const [getEnrollStudent, setGetEnrollStudent] = useState({});
  const [getuserid, setuserid] = useState();
  const [getstatuspopup, setstatuspopup] = useState(false);
  const [getstudentname, setstudentname] = useState("");
  const [getstudentimage, setstudentimage] = useState("");
  const [getchathistory, setchathistory] = useState([]);
  const { token } = useAuth();
  const globalState = useSelector((state) => state.globalState);
  const teacherProfile = globalState?.teacher_details?.data?.ai_avatar;
  const location = useLocation();
  const { studentName } = location.state || {};
  const { studentProfile } = location.state || {};
  const { studentId } = location.state || {};
  const { teacherId } = location.state || {};
  const [message, setMessage] = useState("");  // For handling input text

  useEffect(() => {
    // api
    //   .get("/auth/teacher/get-student-chat-list/", {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //       Authorization: `Token ${token}`,
    //     },
    //   })
    //   .then((response) => {
    //     if (response.status === 200) {
    //       //return 1;
    //     //   console.log(response);

    //       if (response?.data) {
    //         setGetEnrollStudent(response?.data?.data);
    //       }
    //       // localStorage.setItem('datalist', JSON.stringify(response?.data ?? ''));
    //     }
    //   })
    //   .catch((error) => {});

    api
      .get("/auth/get-user-id/", {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response?.data?.status === 200) {
          setuserid(response?.data?.user_id);
        }
      })
      .catch((error) => {
        //
      });
  }, []);
  const chatopenfn = (studentId, teacherId, studentName, studentProfile) => {

    setstudentname(studentName);
    setstudentimage(studentProfile);
    const datadtl = {
      student_user_id: studentId,
      teacher_user_id: teacherId,
    };
    console.log("teacher_user_id", datadtl.teacher_user_id);
    const url_chatbot = "/ai/api/student_chat_history/";
    api
      .post(url_chatbot, datadtl)
      .then((response) => {
        // setroomid(response?.data?.room_id);
        setchathistory(response?.data?.data);
        setstatuspopup(true);
      })
      .catch((error) => { });
  };

  // Function to handle message typing
  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  // Function to send message
  const sendMessage = (event) => {
    event.preventDefault(); // Prevent form submission from reloading the page
    // Update the chat history with the new message
    setchathistory([
      ...getchathistory,
      { answer: message } // Mocking the immediate response
    ]);
    // Clear the message input
    setMessage("");
  };

  useEffect(() => {
    if (studentId && studentName && studentProfile && getuserid) {
      chatopenfn(studentId, teacherId, studentName, studentProfile);
    }
  }, [studentId, teacherId, studentName, studentProfile, getuserid]);


  const [search, setSearch] = useState("");
  const serachhandleInputChange = (event) => {
    const { value } = event.target;
    setSearch(value);
  };



  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="msg_sec_new">
              <div className="view-course-Details-back-btn-folder">
                <BackButton buttonName={"Lesson Analytics"} />
                {/* <i
                          onClick={() => navigate(-1)}
                          className="fa-solid fa-arrow-left"
                        ></i>
                        <h2>Lesson Analytics</h2> */}
              </div>
              <div className="msg_sec_new_body_inner">
                <div className="msg_sec_new_body_inner_left">
                  {/* <div className="msg_left_search">
                    <i className="fas fa-search"></i>
                    <input
                      type="search"
                      value={search}
                      onChange={serachhandleInputChange}
                      placeholder="Search here"
                    />
                  </div> */}
                  <div className="msg_sec_new__left_body">
                    <div
                      className="msg_sec_new__left_single active"
                    >
                      <Link
                        to="#" // This keeps the link from navigating away if you don't need to change the page
                        onClick={(e) => {
                          e.preventDefault(); // Prevent the link from causing a page navigation
                          chatopenfn(studentId, studentName, studentProfile);
                        }}
                        className="msg_sec_new__left_single_inner"
                      >
                        <img src={studentProfile} alt="" />
                        <div className="msg_data_sec">
                          <h6>{studentName}</h6>
                          {/* More content can go here if needed */}
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="msg_sec_new_body_inner_right">
                  {/* {getstatuspopup ? ( */}
                  <>
                    <div className="right_chat_sec_new">
                      <div className="right_chat_sec_new_head">
                        <img src={studentProfile} alt="" />
                        <span>
                          <h6>{studentName}</h6>
                          {/* <p>Online</p> */}
                        </span>
                      </div>
                      <div className="right_chat_sec_new_body_sec">
                        {getchathistory.length > 0 ? (
                          getchathistory.map((chatdtls, index) => (
                            <>
                              <div
                                key={`left_${index}`}
                                className="right_chat_sec_left"
                              >
                                <img src={getstudentimage} alt="Chat" />
                                <span>{chatdtls.query}</span>
                              </div>
                              <div
                                key={`right_${index}`}
                                className="right_chat_sec_right"
                              >
                                <span>{chatdtls.answer}</span>
                                <img src={teacherProfile} alt="Teach" />
                              </div>
                            </>
                          ))
                        ) : (
                          <p>No Messages found.</p> // Or any other fallback UI
                        )}
                      </div>
                    </div>
                  </>
                  {/* ) : (
                    ""
                  )}   */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MessageChat;
