import React, { useRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css"; // Import the CSS for cropper.js
import { Link } from "react-router-dom";
import { FaTimesCircle, FaCheckCircle } from "react-icons/fa";

const CropperComponent = (props) => {
  const cropperRef = useRef(null);
  const onCropSave = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    if (cropper) {
      props.setFormState((prevFormState) => ({
        ...prevFormState,
        image_type: 2
      }))

      const croppedImageUrl = cropper
        .getCroppedCanvas()
        .toDataURL("image/jpeg");

      // console.log("Cropped image URL generated:", croppedImageUrl);

      props.setSrc(croppedImageUrl);
      props.setSelectedImage(croppedImageUrl);
      props.setUplodedImage(croppedImageUrl)


      // props.setFormState((prevFormState) => ({
      //   ...prevFormState,
      //   image_url: '',
      //   image: croppedImageUrl
      // }))


      cropper.getCroppedCanvas().toBlob((blob) => {
        console.log("Blob created from cropped image.");

        if (blob) {
          const file = new File([blob], "cropped_profile_image.jpeg", {
            type: "image/jpeg",
          });

          // const savedCourseData = JSON.parse(localStorage.getItem("courseFormState"));
          // savedCourseData.[props.fieldName] = file
          // localStorage.setItem("courseFormState", JSON.stringify(savedCourseData));
          props.setFormState((prevFormState) => ({
            ...prevFormState,
            // [props.fieldName]: file,
            [props.fieldName]: file,
          }));
        }


        // if (blob) {
        //   const file = new File([blob], "cropped_profile_image.jpeg", {
        //     type: "image/jpeg",
        //   });
        //   const reader = new FileReader();

        //   reader.onloadend = () => {
        //     const base64data = reader.result;

        //     // Save the base64 string to localStorage
        //     const savedCourseData = JSON.parse(localStorage.getItem("courseFormState")) || {};
        //     savedCourseData.image = base64data; // Store base64 string
        //     localStorage.setItem("courseFormState", JSON.stringify(savedCourseData));

        //     props.setFormState((prevFormState) => ({
        //       ...prevFormState,
        //       image: base64data,
        //     }));
        //   };

        //   reader.readAsDataURL(file);
        // }
      }, "image/jpeg");
      props.setImageUploaded(true);
      props.modalClose(true);  // Pass true to confirm chan// Use onSave to indicate saving the image
    }
    // props.setImageUploaded(true)
  };

  // Function to handle cancellation (reverts any changes made)
  const onCancel = () => {
    console.log("Cropping cancelled.");
    props.setFormState((prevFormState) => ({
      ...prevFormState,
      image_type: props.formState.image_type
    }))
    props.modalClose(false);  // Pass false to discard changes

  };


  return (
    <>
      <Cropper
        src={props.src}
        ref={cropperRef}
        style={{
          height: props.isSmallScreen ? 200 : 500,
          width: props.isSmallScreen ? "80%" : "100%",
        }}
        aspectRatio={props.aspectRatio ? props.aspectRatio : 1}
        guides={false}
        background={false} // Set to false to disable default background (if possible)
        checkCrossOrigin={false}
      />
      <div className="crop_btn_sec">
        {/* <Link className="cancel_btn" onClick={() => props.modalClose(false)}> */}
        <Link className="cancel_btn" onClick={onCancel}>
          <FaTimesCircle />
          <span>Cancel</span>
        </Link>

        <Link className="save_btn" onClick={onCropSave}>
          <FaCheckCircle />
          <span>Save</span>
        </Link>
      </div>
    </>
  );
};

export default CropperComponent;
