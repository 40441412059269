import { Dispatch } from "redux";
import api from "../../api/api";

import {
    setteacherRatingReply,
    setloading,
    seterror,
    resetteacherRatingReply,
} from '../reducers/teacherRatingReplyReducer'

type ActionType = 'GET' | 'POST' | 'DELETE';


const teacherRatingReply = (
     actionType: ActionType,
      rating_id: any,
       token: any,
       data?: any // Additional data for POST method
       ) => async (dispatch: Dispatch) => {
    dispatch(setloading(true));

    try {
    let response;
    switch (actionType) {
      case 'GET':
        response = await api.get(
          `rating/reply-rating-comment/${rating_id}/`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${token}`,
            },
          }
        );
        break;
      case 'POST':
        response = await api.post(
          `rating/reply-rating-comment/${rating_id}/`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${token}`,
            },
          }
        );
        break;
      case 'DELETE':
        response = await api.delete(
          `rating/reply-rating-comment/${rating_id}/`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${token}`,
            },
          }
        );
        break;
      default:
        throw new Error('Invalid action type');
    }

    dispatch(setloading(false));

            
        
     
        if (response.data?.status === '200') {
            if (actionType === 'GET' || actionType === 'POST') {
              dispatch(setteacherRatingReply(response?.data?.data));
            } else if (actionType === 'DELETE') {
              dispatch(resetteacherRatingReply());
            }
          } else {
            dispatch(seterror(response.data?.message ?? "Something Went Wrong!"));
          }
    }
    catch (error: any) {
        dispatch(setloading(false))
        dispatch(seterror(error.response?.data?.message ?? "Somethingsssss Went Wrong!"));
    }
 }


export { teacherRatingReply, resetteacherRatingReply}




















   //   const response = await api
    //      .get(
    //             `rating/reply-rating-comment/${rating_id}/`,
    //             {
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                     Authorization: `Token ${token}`,
    //                 },
    //             }
    //         )


   // if(response.data?.status === '200'){
        //  dispatch(setloading(false));
        //  if (response.data?.status === '200') {
        //          dispatch(setteacherRatingReply(response?.data?.data));
        //    }
        //    else {
        //      dispatch(seterror(response.data?.message ?? "Something Went Wrong!"));
        //    }
        // }