import React from "react";
import { StudentReview_image } from "../../../../../../assets/images/images";
import LinearProgress from '@mui/material/LinearProgress';
import BackButton from "../../../../../../components/BackButtonFolder/BackButtonFolder";
import "./studentReview.css";
import StudentReviewCard from "./StudentReviewCard";


function StudentReview() {

  return <>
    <div className="StudentReview-section">
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12 col-md-12">
                    <BackButton
                        buttonName="Back"
                    />
                    <div className="StudentReview-wrapper">
                        <div className="StudentReview-left-section">
                            <div className="StudentReview-left-container">
                                <div className="StudentReview-card-folder">
                                    <span className="Students-reviews-title">Students reviews & ratings </span>
                                    <h2>4.1</h2>
                                    <div className="StudentReview-star-folder">
                                        <span><i class="fa-solid fa-star"></i></span>
                                        <span><i class="fa-solid fa-star"></i></span>
                                        <span><i class="fa-solid fa-star"></i></span>
                                        <span><i class="fa-solid fa-star"></i></span>
                                        <span><i class="fa-solid fa-star"></i></span>
                                    </div>
                                    (<span className="Students-reviews-number-rating"><span>322</span>Ratings</span>)
                                </div>
                                <div className="StudentReview-progress-folder">
                                    <div className="StudentReview-progress">
                                        <span>5 Star</span>
                                        <LinearProgress sx={{ width:"69%", height: "17px", borderRadius: "25px" }} variant="determinate" value={50} />
                                        <span>100</span>
                                    </div>
                                    <div className="StudentReview-progress">
                                        <span>5 Star</span>
                                        <LinearProgress sx={{ width:"69%", height: "17px", borderRadius: "25px" }} variant="determinate" value={50} />
                                        <span>100</span>
                                    </div>
                                    <div className="StudentReview-progress">
                                        <span>5 Star</span>
                                        <LinearProgress sx={{ width:"69%", height: "17px", borderRadius: "25px" }} variant="determinate" value={50} />
                                        <span>100</span>
                                    </div>
                                    <div className="StudentReview-progress">
                                        <span>5 Star</span>
                                        <LinearProgress sx={{ width:"69%", height: "17px", borderRadius: "25px" }} variant="determinate" value={50} />
                                        <span>100</span>
                                    </div>
                                    <div className="StudentReview-progress">
                                        <span>5 Star</span>
                                        <LinearProgress sx={{ width:"69%", height: "17px", borderRadius: "25px" }} variant="determinate" value={50} />
                                        <span>100</span>
                                    </div>
                                </div>
                            </div>
                            <button className="StudentReview-btn">Add a Review</button>
                        </div>
                        <div className="StudentReview-right-section">
                            <h2>Top Reviews</h2>
                            <div className="Student-Review-cad0-wrapper">
                                <StudentReviewCard
                                    StudentReview_image={StudentReview_image}
                                    StudentReview_title="Charles Shelby"
                                    StudentReview_text="Enjoyed the engaging lectures and practical assignments. Valuable insights gained. Instructor was knowledgeable and responsive. Highly recommend!"
                                />
                                <StudentReviewCard
                                    StudentReview_image={StudentReview_image}
                                    StudentReview_title="Charles Shelby"
                                    StudentReview_text="Enjoyed the engaging lectures and practical assignments. Valuable insights gained. Instructor was knowledgeable and responsive. Highly recommend!"
                                />
                                <StudentReviewCard
                                    StudentReview_image={StudentReview_image}
                                    StudentReview_title="Charles Shelby"
                                    StudentReview_text="Enjoyed the engaging lectures and practical assignments. Valuable insights gained. Instructor was knowledgeable and responsive. Highly recommend!"
                                />
                                <StudentReviewCard
                                    StudentReview_image={StudentReview_image}
                                    StudentReview_title="Charles Shelby"
                                    StudentReview_text="Enjoyed the engaging lectures and practical assignments. Valuable insights gained. Instructor was knowledgeable and responsive. Highly recommend!"
                                />
                                <StudentReviewCard
                                    StudentReview_image={StudentReview_image}
                                    StudentReview_title="Charles Shelby"
                                    StudentReview_text="Enjoyed the engaging lectures and practical assignments. Valuable insights gained. Instructor was knowledgeable and responsive. Highly recommend!"
                                />
                                <StudentReviewCard
                                    StudentReview_image={StudentReview_image}
                                    StudentReview_title="Charles Shelby"
                                    StudentReview_text="Enjoyed the engaging lectures and practical assignments. Valuable insights gained. Instructor was knowledgeable and responsive. Highly recommend!"
                                />
                                <StudentReviewCard
                                    StudentReview_image={StudentReview_image}
                                    StudentReview_title="Charles Shelby"
                                    StudentReview_text="Enjoyed the engaging lectures and practical assignments. Valuable insights gained. Instructor was knowledgeable and responsive. Highly recommend!"
                                />
                                <StudentReviewCard
                                    StudentReview_image={StudentReview_image}
                                    StudentReview_title="Charles Shelby"
                                    StudentReview_text="Enjoyed the engaging lectures and practical assignments. Valuable insights gained. Instructor was knowledgeable and responsive. Highly recommend!"
                                />
                                <StudentReviewCard
                                    StudentReview_image={StudentReview_image}
                                    StudentReview_title="Charles Shelby"
                                    StudentReview_text="Enjoyed the engaging lectures and practical assignments. Valuable insights gained. Instructor was knowledgeable and responsive. Highly recommend!"
                                />
                                <StudentReviewCard
                                    StudentReview_image={StudentReview_image}
                                    StudentReview_title="Charles Shelby"
                                    StudentReview_text="Enjoyed the engaging lectures and practical assignments. Valuable insights gained. Instructor was knowledgeable and responsive. Highly recommend!"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </> 
}

export default StudentReview;