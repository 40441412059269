import PreprocessAndRenderLatex from "../../../../components/ProcessRenderLatex";

function MCQs({ options, selectedOption, correct, setSelectedOption }) {

  function escapeHtmlEntities(text) {
    // return text.replace(/</g, "&lt;").replace(/>/g, "&gt;");
    return text
    ?.replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/_/g, "&#95;");  // Escape underscores
  }

  return (
    <div className="AssingmentMCQs-mock-test-answer-container">
      <div className="options AssingmentMCQs-mock-test-answer-container">
        {options.map((option, index) => (
          <label
            key={index}
            className={`option AssingmentMCQs-mock-test-answer-folder ${
              index === selectedOption ? "selected" : ""
            } ${index === correct ? "correct" : ""}`}
          >
            <div
              style={{
                display: "flex",
              }}
            >
              <div style={{ paddingRight: "10px" }}>
                {String.fromCharCode(65 + index) + ")"}
              </div>
              <PreprocessAndRenderLatex content={escapeHtmlEntities(option)} />
            </div>
            <input
              type="radio"
              name="questionOption"
              value={index}
              checked={selectedOption === index}
              onChange={() => setSelectedOption(index)}
            />
          </label>
        ))}
      </div>
    </div>
  );
}

export default MCQs;
