import * as React from 'react';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#FFBB33' : '#FFF1D5',
  },
}));

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex',   alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <BorderLinearProgress variant="determinate"  {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}


const LinearWithValueLabel = (props) => {
    const [progress, setProgress] = React.useState(0);
 React.useEffect(() => {
  if(props?.value){
  setProgress(props?.value);
 }
}, [props?.value]);
    return (
        <Box sx={{ width: props?.width||'100%' }}>
        <LinearProgressWithLabel value={progress} />
      </Box>
    );
};

export default LinearWithValueLabel;
