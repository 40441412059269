import React from "react";

function BackButton({buttonName}) {

  return <>
    <div className="view-course-Details-back-btn-folder">
        <i className="fa-solid fa-arrow-left"
        ></i>
        <h2>{buttonName}</h2>
    </div>
  </> 
}

export default BackButton
;