import { Dispatch } from "redux";
import api from "../../api/api";
import {
    setTeacherBySchool,setStudentBySchool, setloading, seterror, resetAllTeacherBySchool, setPagination
} from "../reducers/getAllTeacherBySchoolReducer";

const fetchAllSchoolData = (page: any, id: any, token: any, type: any, role: any, search: any, filter: any, sort: any, grade: any, month: any, subject: any) => async (dispatch: Dispatch) => {
   dispatch(setloading(true));

  console.log("pagepagepagepage", page, id, token, type, role)
   try {
     let url = "";
     if (role === "SuperAdmin" && !id) {
       url = type === 'Teacher' ? `/super-admin/get-teacher-list/` : `/super-admin/get-student-list/`;
     } else if (role === "Admin") {
       url = type === 'Teacher' ? `/super-admin/school/get-teacher-list/${id}/` : `/super-admin/school/get-student-list/${id}/`;
     }
     console.log(url);
     const result = await api
       .get(url,{
          params: {
            page: page,
           search: search,
           filter: filter,
           sort: sort,
           grade: grade,
           month: month,
           subject: subject,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`
          },
        }
       )
       
       if(result){
        dispatch(setloading(false));
        if (result.data?.status === 200) {
            if (type === 'Teacher') {
              dispatch(setTeacherBySchool(result.data.data));
              dispatch(setPagination(result.data));
            } else {
              dispatch(setStudentBySchool(result.data.data));
              dispatch(setPagination(result.data));
            }
          }
          else {
            dispatch(seterror(result.data?.message ?? "Something Went Wrong!"));
          }
       }
   }
   catch (error: any) {
    dispatch(seterror(error.response.data.message));
   }
}

export { fetchAllSchoolData , resetAllTeacherBySchool} 

