import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AdminLogin {
    school_id: string | null,
    token: string | null;
    isLoading: boolean;
    error: string | null;
}

const initialState: AdminLogin = {
    school_id: null,
    token: null,
    isLoading: false,
    error: null,
};

const adminloginslice = createSlice({
    name: 'adminLogin',
    initialState,
    reducers: {
        setAdminLogin: (state : AdminLogin, action: PayloadAction<AdminLogin>) => {
            state.school_id = null;
            state.token = action.payload.token;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: AdminLogin, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null; 
        },
        seterror: (state : AdminLogin, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetadminlogin: () => {
            return initialState;
        },
    },
});

export const { setAdminLogin, setloading, seterror, resetadminlogin } = adminloginslice.actions;

export default adminloginslice.reducer;
