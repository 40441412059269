import React from "react";
import { Link } from "react-router-dom";

import chat_img from "../../../../../assets/images/chat-img-1.png";
import teach_img from "../../../../../assets/images/teach-img.png";

import link_img from "../../../../../assets/images/link-img.png";
import send_img from "../../../../../assets/images/send-img.png";
import FilteredInput from "../../../../../components/FilteredInput/FilteredInput";

const StudentTeacherChat = () => {
  return (
    <>
      <section className="student_teacher_chat_sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="msg_sec_new">
                <div className="msg_sec_new_head">
                  <Link className="msg_sec">
                    <i className="fas fa-chevron-left"></i>Messages
                  </Link>
                </div>
                <div className="msg_sec_new_body_inner">
                  <div className="msg_sec_new_body_inner_right">
                    <div className="right_chat_sec_new">
                      <div className="right_chat_sec_new_head">
                        <img src={chat_img} alt="" />
                        <span>
                          <h6>Charles Shelby</h6>
                          {/* <p>Online</p> */}
                        </span>
                      </div>
                      <div className="right_chat_sec_new_body_sec">
                        <div className="right_chat_sec_left">
                          <img src={chat_img}  alt=""/>
                          <span>You can change the order of algorithm</span>
                        </div>
                        <div className="right_chat_sec_right">
                          <span>
                            You can change the order of message of the algorithm
                            with the help of menu{" "}
                          </span>
                          <img src={teach_img} alt=""/>
                        </div>
                        <div className="right_chat_sec_left">
                          <img src={chat_img} alt="" />
                          <span>
                            You can change the order of algorithm by clicking on
                            the menu bar
                          </span>
                        </div>
                        <div className="right_chat_sec_right">
                          <span>
                            You can change the order of message with the help of{" "}
                          </span>
                          <img src={teach_img} alt=""/>
                        </div>
                        <div className="right_chat_sec_left">
                          <img src={chat_img} alt=""/>
                          <span>You can change the order of algorithm</span>
                        </div>
                        <div className="right_chat_sec_right">
                          <span>
                            You can change the order of message of the algorithm
                            with the help of menu{" "}
                          </span>
                          <img src={teach_img} alt=""/>
                        </div>
                      </div>
                      <div className="msg_type_box">
                        <input type="text" placeholder="Type a message" />
                        <span className="msg_cin_sec">
                          <Link>
                            <img src={link_img} alt=""/>
                          </Link>
                          <button>
                            <img src={send_img} alt=""/>
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default StudentTeacherChat;
