import { Dispatch } from "redux";
import api from "../../api/api";
import {
    setAddToCart, setloading, seterror, resetAddToCart
} from "../reducers/addToCartReducer";

const addCourseToCart = (data: any, token: any) => async (dispatch: Dispatch) => {
    dispatch(setloading(true));
    let payload = JSON.stringify({
        "course_id": data
    });
    try {
        const result = await api
            .post(
                `orders/cart/add-to-cart/`,
                    payload,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Token ${token}`
                    },
                }
            )

        if (result) {
            dispatch(setloading(false));
            if (result.data?.status === 200) {
                dispatch(setAddToCart(result.data));
            }
            else if(result.data?.status === 400){
                dispatch(seterror(result.data?.message));
            }
            else {
                dispatch(seterror(result.data?.message ?? "Something Went Wrong!"));
            }
        }
    }
    catch (error: any) {
        dispatch(seterror(error.response.data.message));
    }
}

export { addCourseToCart, resetAddToCart }

