// AuthContext.js
import React, { createContext, useContext, useState } from 'react';
import Cookies from 'js-cookie';
import { updateGlobalState, resetGlobalState } from '../../state/global/slice.tsx';
import { useDispatch } from 'react-redux';
import { resetTeacherCurrentSubscriptionPlan } from '../../state/reducers/teacherCurrentSubscriptionPlanReducer.ts';
import { resetStudentCurrentSubscriptionPlan } from '../../state/reducers/studentCurrentSubscriptionPlanReducer.ts';

const AuthContext = createContext();

export function AuthProvider({ children }) {
    const dispatch = useDispatch();
    const updateState = (key, value) => {
        dispatch(updateGlobalState({ key: key, value: value }));
    };
    const [token, setToken] = useState(Cookies.get('auth_token') || null);
    const login = (newToken, role, email, school_id = null) => {
        setToken(newToken);
        updateState("role", role);
        updateState("email", email);
        updateState("school_id", school_id);

        //  Cookies.set('auth_token', newToken, { expires: 7, domain: '.proschool.ai', secure: true, sameSite: 'None' }); // Store token in a cookie for 7 days
        Cookies.set('auth_token', newToken, { expires: 7, domain: '.mycro.news', secure: true, sameSite: 'None' }); // Store token in a cookie for 7 days
        // Cookies.set('auth_token', newToken, { expires: 7 }); 
    };

    const logout = () => {
        setToken(null);
        dispatch(resetGlobalState());
        // localStorage.clear();
        dispatch(resetTeacherCurrentSubscriptionPlan());
        dispatch(resetStudentCurrentSubscriptionPlan());
        localStorage.removeItem("studentHomePageNumber");
        localStorage.removeItem("studentHomeScrollPosition");
        localStorage.removeItem("teacherHomeScrollPosition");
        localStorage.removeItem("teacherHomePageNumber");
        localStorage.removeItem("courseSubjFilter");
        //  Cookies.remove('auth_token', { domain: '.proschool.ai', secure: true });
        Cookies.remove('auth_token', { domain: '.mycro.news', secure: true });
        // Cookies.remove('auth_token');

        if ('caches' in window) {

            caches.keys().then(function (names) {
                for (let name of names) caches.delete(name);
            });
        }

        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistrations().then(function (registrations) {

                for (let registration of registrations) {
                    registration.unregister();
                }
            });
        }


        // QueryClient.clear();

        indexedDB.databases().then((databases) => {

            databases.forEach((db) => {
                indexedDB.deleteDatabase(db.name);
            });
        });

    };

    return (
        <AuthContext.Provider value={{ token, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
}

export function useAuth() {
    return useContext(AuthContext);
}
