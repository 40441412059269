import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SchoolById {
    name:string | null;
    school_id:number | null;
    location:string | null;
    description:string | null;
    joined_at:string | null;
    image:string | null;
    total_teachers:number | null;
    total_students:number | null;
    total_courses:number | null;
    isLoading: boolean;
    error: string | null;
}

const initialState: SchoolById = {
    name: null,
    school_id:null,
    location: null,
    description: null,
    joined_at: null,
    image: null,
    total_teachers: null,
    total_students: null,
    total_courses: null,
    isLoading:false,
    error:null
};

const schoolbyidslice = createSlice({
    name: 'schoolbyid',
    initialState,
    reducers: {
        setSchoolById: (state: SchoolById, action: PayloadAction<SchoolById>) => {
            state.name = action.payload.name;
            state.isLoading = false;
            state.error = null;
            state.school_id = action.payload.school_id;
            state.location = action.payload.location;
            state.description = action.payload.description;
            state.joined_at = action.payload.joined_at;
            state.image = action.payload.image;
            state.total_teachers = action.payload.total_teachers;
            state.total_students = action.payload.total_students;
            state.total_courses = action.payload.total_courses;
          },
          
        setloading: (state: SchoolById, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null; 
        },
        seterror: (state : SchoolById, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetschoolbyid: () => {
            return initialState;
        },
    },
});

export const { setSchoolById, setloading, seterror, resetschoolbyid } = schoolbyidslice.actions;

export default schoolbyidslice.reducer;
