import React from 'react';
import HomeNavbar from '../home/components/HomeNavbar';
const Contact = () => {
    return (
        <div className="contact">
            <HomeNavbar />
            <h1>Contact</h1>
        </div>
    );
};

export default Contact;
