import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import {
  deleteAssignmentById,
  resetdeleteAssignment,
} from "../../state/actions/deleteAssignmentAction";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../features/auth/AuthContext";
import { alert_image } from "../../assets/images/images";
import { deleteLessonById } from "../../state/actions/deleteLessonAction";
import { toast } from "react-toastify";

const LessonAndAssingmentTab = ({
  type,
  MainTitle,
  title,
  Coursename,
  start_date,
  end_date,
  Progress_btn,
  Lessonname,
  subjecttitle,
  titleHeader,
  isAssignmentTab,
  course,
  lesson,
  assignment,
  teacher_id,
  Editbutton,
  refreshAssignments
}) => {
  const navigate = useNavigate();
  const { token } = useAuth();
  const dispatch = useDispatch();
  const deleteAssignmentState = useSelector((state) => state.deleteAssignment);
  const [deleteAssignment, setdeleteAssignment] = useState(false);

  const getassignmentcount = (counter) => {
    let value = counter + 1;
    return " " + value;
  };

  // const deleteAssignmentReq = (id) => {
  //   dispatch(deleteAssignmentById(id, token));
  //   setdeleteAssignment(false);
  //     refreshAssignments(); 
  // };

  // const deleteAssignmentReq = (id) => {
  //   dispatch(deleteAssignmentById(id, token)).then(() => {
  //     setdeleteAssignment(false);
  //     // refreshAssignments();
  //   });
  // };

  const deleteAssignmentReq = (id) => {
    const lessonId = lesson?.lesson_id;  // Get lesson_id
    const assignmentId = assignment?.assignment_id;  // Get assignment_id

    if (type === "Lesson") {
      // Dispatch action for deleting a lesson
      dispatch(deleteLessonById(lessonId, token)).then(() => {
        setdeleteAssignment(false);
        refreshAssignments();  // Pass lesson_id to refresh after deletion
        toast.success("Lesson has been deleted successfully.", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
    } else {
      // Dispatch action for deleting an assignment
      dispatch(deleteAssignmentById(assignmentId, token)).then(() => {
        setdeleteAssignment(false);
        refreshAssignments();  // Pass lesson_id to refresh after deletion
        toast.success("Assignment has been deleted successfully.", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
    }
  };

  console.log("assignment", assignment);    
  console.log("lesson", lesson);
  
  

  return (
    <>
      <div className="Student-Active-Lesson-container" id="Lesson_Container">
        <div className="Student-Active-main-wrapper">
          <div className="Student-Active-Lesson-folder active_assignment_folder">
            <div className="Student-Active-Lesson-title-folder">
              <h2>  
                {type+" "+getassignmentcount(titleHeader)+" : "}
                <span> {title}</span>
              </h2>
              {isAssignmentTab && (
                <span className="Student-Active-Assignment-des">
                  <span>{subjecttitle}</span>
                  {Lessonname}
                </span>
              )}
              <span className="Student-Active-Assignment-des">
                <span>Course: </span>
                {Coursename}
              </span>
            </div>
            <div className="view-course-details-container">
              <div className="view-Students-course-details-folder">
                <span>{type} Start Date:</span>
                <div className="view-Students-course-details-counts">
                  <p>{start_date}</p>
                </div>
              </div>
              <div className="view-Students-course-details-folder">
                <span>{type} End Date:</span>
                <div className="view-Students-course-details-counts">
                  <p>{end_date}</p>
                </div>
              </div>
            </div>
            <div className="button_tab_wrapper">
              {type == "Lesson" ? (
                <button
                  type="button"
                  className="button_tab_edit_btn"
                  onClick={(e) => {
                    navigate("/NewLessons", {
                      state: { course, teacher_id, lesson },
                    });
                  }}
                >
                  <span>
                    <i className="fa-solid fa-pencil"></i>
                  </span>
                  <p>Edit</p>
                </button>
              ) : Editbutton ? (
                <button
                  type="button"
                  className="button_tab_edit_btn"
                  onClick={(e) => {
                    navigate("/NewLessons", {
                      state: { course, teacher_id, lesson },
                    });
                  }}
                >
                  <span>
                    <i className="fa-solid fa-pencil"></i>
                  </span>
                  <p>Edit</p>
                </button>
              ) : (
                <button
                  type="button"
                  className="button_tab_edit_btn"
                  onClick={(e) => {
                    e.preventDefault();
                    const course_id = course?.course_id;
                    const lesson_id = lesson?.lesson_id;
                    const assignment_id = assignment?.assignment_id;
                    navigate(
                      `/course/${course_id}/lesson/${lesson_id}/assignment/${assignment_id}/preview`,
                      {
                        state: {
                          course,
                          teacher_id,
                          lesson,
                          assignment,
                          isTeacher: true,
                        },
                      }
                    );
                  }}
                >
                  <span>
                    <i className="fa-solid fa-pencil"></i>
                  </span>
                  <p>Preview Assignment</p>
                </button>
              )}

              <button
                className="Student-Active-start-Lesson"
                onClick={(e) => {
                  if (type == "Lesson") {
                    const course_id = course?.course_id;
                    const lesson_id = lesson?.lesson_id;
                    navigate(
                      `/course/${course_id}/lesson/${lesson_id}/progress`,
                      { state: { course, teacher_id, lesson } }
                    );
                  } else {
                    const course_id = course?.course_id;
                    const lesson_id = lesson?.lesson_id;
                    const assignment_id = assignment?.assignment_id;
                    const tabValue = "assignments";
                    const assignment_name = assignment?.name;
                    const assignment_start_date = assignment?.start_date;
                    const assignment_end_date = assignment?.end_date;
                    // navigate(
                    //   `/course/${course_id}/lesson/${lesson_id}/assignment/${assignment_id}/progress`,
                    //   { state: { course, teacher_id, lesson, assignment } }
                    // );
                    navigate(
                      `/course/${course_id}/lesson/${lesson_id}/progress`,
                      { state: { course, teacher_id, lesson,assignment_id,tabValue,assignment_name,assignment_start_date,assignment_end_date } }
                    );
                  }
                }}
              >
                {Progress_btn}
              </button>
              {true && (
                <>
                  <i
                    class="fa-solid fa-trash"
                    data-tooltip-id="delete-assignment"
                    onClick={() => setdeleteAssignment(true)}
                  ></i>
                  <Tooltip
                    id="delete-assignment"
                    place="bottom"
                    content="Delete Assignment"
                    type="light"
                    effect="float"
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {deleteAssignment && (
        <form action="" className="delete-course-form">
          <div className="delete-course-form-container" data-aos="zoom-in">
            <div className="delete-course-alert-image">
              <img src={alert_image} alt="" />
            </div>
            <h2>Are you sure you want to delete this assignment?</h2>
            <div className="delete-course-form-btn-folder">
              <button
                type="button"
                onClick={() => setdeleteAssignment(false)}
                className="not-delete-btn"
              >
                No
              </button>
              {deleteAssignmentState?.isLoading ? (
                <div className="loading-spinner" />
              ) : (
                <button
                  type="button"
                  // onClick={() => deleteAssignmentReq(assignment?.assignment_id)}
                  onClick={() => {
                    const idToDelete = type === "Lesson" ? lesson?.lesson_id : assignment?.assignment_id;
                    deleteAssignmentReq(idToDelete);
                  }}
                
                  disabled={deleteAssignmentState?.isLoading}
                  className="delete-btn"
                >
                  Delete
                </button>
              )}
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default LessonAndAssingmentTab;
