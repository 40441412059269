import { useEffect, useRef, useState } from "react";
import OverviewLesson from "../../pages/home/components/dashboard/teacher/lessons/OverviewLesson";
import MaltipleAssignment from "../MaltipleAssignment/MaltipleAssignment";
import "./LessonAndAssignmentTab.css";
import BackButton from "../../components/BackButton/BackButton";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../../api/api";
import { useAuth } from "../../features/auth/AuthContext";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import AiVoiceanimation from "../../components/AiVoiceanimation/AiVoiceanimation";
import { resetGeneratePracticeAssignment } from "../../state/actions/generatePracticeAssignmentAction";
import { useDispatch, useSelector } from "react-redux";

function LessonAndAssignmentTab() {


  const location = useLocation();
  const navigate = useNavigate();
  const { token } = useAuth();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(location?.state?.activeTab || "lessons"); // Default active tab is 'lessons'
  const [lessonParent, setlessonParent] = useState(location?.state?.lesson || {}); // Default active tab is 'lessons'
  const [courseDetails, setcourseDetails] = useState(location?.state?.course || {});
  const [rubricDetails, setrubricDetails] = useState({});
  const [assignmentCount, setassignmentCount] = useState(location?.state?.lesson?.assignment_count)
  const [showDownloadFile, setshowDownloadFile] = useState(false);
  const modalContentRef = useRef();
  const [canDownload, setCanDownload] = useState(false);
  const [lessonFiles, setLessonFiles] = useState([]);
  const courseId = location?.state?.course?.course_id
  // console.log("courseId", courseId); 

  const [isPlaying, setIsPlaying] = useState(false);
  // console.log("location?.state in lessson tab", location?.state)

  const globalState = useSelector((state) => state.globalState);
  const download_file = globalState.userCurrentPlan?.currentSubscription && globalState?.userCurrentPlan?.currentSubscription?.plan_data?.download_file

  // Toggle the play state.
  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };
  const openModal = () => {
    setshowDownloadFile(true);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        modalContentRef.current &&
        !modalContentRef.current.contains(event.target)
      ) {
        toggledownlodefile();
      }
    };

    // Attach the event listener
    if (showDownloadFile) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDownloadFile]);

  const toggledownlodefile = () => {
    setshowDownloadFile(false);
    // setShowdownlodefile(!showdownlodefile); // Toggle the visibility state
  };


  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    if (tabName === "Assignments") {

    }
    // if (tabName === "studentsRequest") {

    // }
  };


  const downloadAsPdf = async () => {
    // if (!fileNameDownload) {
    //     toast.error("Please enter a filename");
    //     return;
    // }

    try {
      const response = await api.post(`auth/convert-html-to-pdf/`, { html_text: lessonParent.description }, {
        responseType: 'blob',  // Important for handling binary data
        headers: {
          Authorization: `Token ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.data) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${lessonParent?.name}.pdf`); // or any other extension
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        toast.success("Download successful!");
      }
    } catch (error) {
      console.error('Download error:', error);
      toast.error("Download failed.");
    }
  };

  const initiateDownload = (file) => {
    fetch(file.file_url)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        link.download = file.original_name;
        document.body.appendChild(link);
        link.dispatchEvent(new MouseEvent('click'));
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      })
      .catch(error => console.error('Download failed:', error));
  };


  const downloadAllFiles = () => {
    if (!canDownload) {
      toast.error("Downloading is disabled for this lesson.");
      return;
    }

    if (lessonFiles.length === 0) {
      toast.error("No files to download.");
      return;
    }

    toast.info(`Starting download of ${lessonFiles.length} files...`);

    lessonFiles.forEach((file, index) => {
      // Stagger downloads with a minimal delay between each
      setTimeout(() => {
        initiateDownload(file);
      }, index * 100);  // Small delay between each download (100 ms)
    });
  };


  // useEffect(() => {
  //   return () => {
  //     dispatch(resetGeneratePracticeAssignment());
  //   }
  // }, [])

  return (
    <>
      <div className="lesson-andassignment-tab-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              {
                <div className="Overview-Lesson-top-header" >
                  <div class="Back_button" onClick={() => navigate(`/course/${courseId}/`)}>
                    <i class="fa-solid fa-chevron-left"></i>
                    <span>{lessonParent?.name}</span>
                  </div>
                    {/* <BackButton buttonName={lessonParent?.name} /> */}

                  <div className="lesson-andassignment-tab-container">
                    <button
                      className={`tab-button ${activeTab === "lessons" ? "active" : ""
                        }`}
                      onClick={() => handleTabClick("lessons")}
                    >
                      Lesson
                    </button>
                    <button
                      className={`tab-button ${activeTab === "Assignments" ? "active" : ""
                        }`}
                      onClick={() => handleTabClick("Assignments")}
                    >
                      {assignmentCount > 0 ? (assignmentCount == 1 ? `Assignment: ${assignmentCount}` : `Assignments: ${assignmentCount}`) : `Assignments: 0`}
                    </button>
                  </div>

                  {
                    (activeTab === 'lessons') && <div className="Overview-Lesson-ai-voice-animation-folder" >
                      {/* <div
                        className="Overview-Lesson-downlode-pdf-btn"
                        // onClick={toggledownlodefile}
                      >
                        <span>
                        <i class="fa-solid fa-file"></i>
                        </span>
                      </div> */}
                      <div className="view-course-Details-back-btn-folder">
                        {
                          lessonFiles.length > 1 ? (
                            <div className="Overview-Lesson-downlode-btn">
                              <span onClick={downloadAllFiles}>
                                <i
                                  className="fa-regular fa-file"
                                  data-tooltip-id="download-data-workboardd222"
                                ></i>
                                <Tooltip
                                  id="download-data-workboardd222"
                                  place="top"
                                  content={lessonFiles[0].original_name}
                                  type="light"
                                  effect="float"
                                />
                              </span>
                            </div>
                          ) : (canDownload &&
                            lessonFiles.map((file, index) => (
                              (
                                <div
                                  className="Overview-Lesson-downlode-btn"
                                  // onClick={() => downloadFile(file.file_url, file.original_name)}
                                  onClick={downloadAllFiles}
                                  key={index}
                                >
                                  <span>
                                    <i
                                      className="fa-regular fa-file"
                                      data-tooltip-id={`download-data-workboardd${index}`}
                                    ></i>
                                    <Tooltip
                                      id={`download-data-workboardd${index}`}
                                      place="top"
                                      content={file.original_name}
                                      type="light"
                                      effect="float"
                                    />
                                  </span>
                                </div>
                              )
                            ))
                          )
                        }
                      </div>




                      {/* <div>
                        {lessonFiles.length > 1 ? (
                          // <div className="pdf-file-container">
                          <span onClick={downloadAllFiles}>
                            <i
                              className="fa-regular fa-file"
                              data-tooltip-id="download-data-workboardd222"
                            ></i>
                            <Tooltip
                              id="download-data-workboardd222"
                              place="top"
                              content={lessonFiles[0].original_name}
                              // content='omkar'
                              type="light"
                              effect="float"
                            />
                          </span>

                        ) : (canDownload &&
                          lessonFiles.map((file, index) => (
                            (
                              <div
                                // className="Overview-Lesson-downlode-btn"
                                // onClick={() => downloadFile(file.file_url, file.original_name)}
                                onClick={downloadAllFiles}
                                key={index}
                              >
                                <span>
                                  <i
                                    className="fa-regular fa-file"
                                    data-tooltip-id={`download-data-workboardd${index}`}
                                  ></i>
                                  <Tooltip
                                    id={`download-data-workboardd${index}`}
                                    place="top"
                                    content={file.original_name}
                                    // content='omkar'
                                    type="light"
                                    effect="float"
                                  />
                                </span>
                              </div>
                            )
                          ))
                        )}
                      </div> */}



                      <div
                        className={`Overview-Lesson-downlode-btn ${download_file ? '' : 'disabled'}`}
                        onClick={download_file ? downloadAsPdf : null}
                        style={{
                          cursor: download_file && !globalState.subdomain_exists ? "" : "not-allowed",
                          }}
                      >
                        <span onClick={download_file ? openModal : null}>
                          <i
                            className="fa-solid fa-download"
                            data-tooltip-id="download-data-workboard"
                          ></i>
                          <Tooltip
                            id="download-data-workboard"
                            place="top"
                            content= {download_file ? "Download lesson" : "Upgrade Subscription Plan"}
                            type="light"
                            effect="float"
                          />
                        </span>
                      </div>

                      <div className="play-pause-btn-Overview-lesson-generator" onClick={togglePlay}>
                         <span>
                            <i className={isPlaying ? "fa-regular fa-circle-pause" : "fa-regular fa-circle-play"} data-tooltip-id="play-pause-tooltip"></i>
                            <Tooltip
                              id="play-pause-tooltip"
                              place="top"
                              content="Play lesson"
                              type="light"
                              effect="float"
                            />
                          </span>
                      </div>


                      {/* Hide Start Date for now  */}
                      {/* 
                       <div className="Overview-Lesson-date-container">
                         <div className="Overview-Lesson-date">
                           <span>Lesson Start Date:</span>
                           <div className="Overview-Lesson-date-counts">
                             <p>{lessonParent?.start_date}</p>
                           </div>
                         </div>
                       </div> */}



                    </div>
                  }

                </div>
              }
            </div>
          </div>
        </div>
        <div className="tab-content">
          {activeTab === "lessons" && (
            <>
              <OverviewLesson setlessonParent={setlessonParent} setCanDownloadParent={setCanDownload} setLessonFilesParent={setLessonFiles} setrubricDetails={setrubricDetails} />
            </>
          )}
          {activeTab === "Assignments" && (
            <>
              <MaltipleAssignment lesson={lessonParent} course={courseDetails} rubrics={rubricDetails} setassignmentCount={setassignmentCount} />
            </>
          )}
        </div>

        {isPlaying && <AiVoiceanimation togglePlay={togglePlay} />}
      </div>
    </>
  );
}

export default LessonAndAssignmentTab;

