import { Dispatch } from "redux";
import api from "../../api/api";
import { setcartSuggesteDashboardCoursesList, setloading, seterror, resetSuggesteDashboardCoursesList, setPagination } from "../reducers/studentSuggestedDashboardCoursesReducer";

const getSuggestedDashboardCourses = (page: any, search: any, token: any, filter: any) => async (dispatch: Dispatch) => {
    dispatch(setloading(true));
    try {
        const result = await api
            .get(
                `/courses/dashboard-courses-with-price/?search=${search}&page=${page}&filter=${filter}`,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Token ${token}`
                    },
                }
            )
        if (result) {
            dispatch(setloading(false));
            if (result?.data?.message === "Success") {
                // console.log(result?.data?.data);
                dispatch(setcartSuggesteDashboardCoursesList(result?.data?.data));
                dispatch(setPagination(result?.data));
            }
            else {
                dispatch(seterror(result?.data?.message ?? "Something Went Wrong!"));
            }
        }
    }
    catch (error: any) {
        dispatch(seterror(error.response.data.message));
    }
}

export { getSuggestedDashboardCourses, resetSuggesteDashboardCoursesList }

