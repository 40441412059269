import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AssignmentAnalytics {
    analytics: any;
    isLoading: Boolean;
    error: string | null;
}

const initialState: AssignmentAnalytics = {
    analytics: null,
    isLoading: false,
    error: null,
};

const assignmentAnalyticsslice = createSlice({
    name: 'assignmentAnalytics',
    initialState,
    reducers: {
        setassignmentAnalytics: (state : AssignmentAnalytics, action: PayloadAction<AssignmentAnalytics>) => {
            state.analytics = action.payload.analytics;
            state.isLoading = false;
            state.error = null;
        },
        loading: (state: AssignmentAnalytics, action: PayloadAction<Boolean>) => {
            state.isLoading = action.payload;
            state.error = null; 
        },
        seterror: (state : AssignmentAnalytics, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetsetassignmentAnalytics: () => {
            return initialState;
        },
    },
});

export const { setassignmentAnalytics, loading, seterror, resetsetassignmentAnalytics } = assignmentAnalyticsslice.actions;

export default assignmentAnalyticsslice.reducer;
