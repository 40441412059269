import React, { useEffect } from "react";
import BackButton from "../BackButton/BackButton";
import { notification_profile } from "../../assets/images/images";
import { getNotificationList } from "../../state/actions/notificationListAction";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../features/auth/AuthContext";
import { resetNotificationList } from '../../state/reducers/notificationListReducer';
import { CircularProgress, Grid } from "@mui/material";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import "./NotificationPage.css";

const NotificationPage = () => {

  const { token } = useAuth();
  const dispatch = useDispatch();

  const { notifications, isloading } = useSelector((state) => state.notificationList);

  const formatTimestamp = (isoTimestamp) => {
    const fixedDateTime = new Date(isoTimestamp);
    const readableFormat = fixedDateTime.toLocaleString('en-US', {
      hour: 'numeric', // Numeric hour
      minute: '2-digit', // 2-digit minute
      hour12: false, // Use 12-hour time with AM/PM
    }) + ', ' + fixedDateTime.toLocaleString('en-US', {
      month: 'short', // Short name of the month
      day: 'numeric', // Numeric day of the month
      year: 'numeric', // Numeric year
    });
    return readableFormat;
  }

  useEffect(() => {
    dispatch(getNotificationList(token))

    return () => {
      dispatch(resetNotificationList());
    }
  }, [])

  return (
    <>
      <div id="NotificationPage-section">
        <div className="NotificationPage-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="view-course-Details-back-btn-folder">
                  <BackButton buttonName={"Notifications"} />
                </div>

                {isloading ?
                  <LoadingComponent
                    loading_text = "Educating the Future, Loading Now."
                  /> :
                notifications?.length > 0 ? notifications?.map((notification, i) => (
                  <div className="notifications-all-container">
                    <div className="notificationPage-folder">
                      <div key={i} className="notifications-folder">
                        <div className="notifications-inner-folder">
                          <div className="notifications-image">
                            {notification.profile_pic ? (
                              <img
                                src={notification.profile_pic}
                                alt="Profile Pic"
                              />
                            ) : (
                              <div className="defult-proifle-pic">
                                <i class="fa-solid fa-user"></i>
                              </div>
                            )}
                          </div>
                          <p>{notification.notification_message}</p>
                        </div>
                        <div className="notification-time-folder">
                          <span>{formatTimestamp(notification.created_at)}</span>
                          {/* <span>12 Oct 2023</span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                )) : "No Notifications Found"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationPage;


