import { Dispatch } from "redux";
import api from "../../api/api";
import {
    setstudentDetails,
    setloading,
    seterror,
    resetstudentDetails,
} from "../reducers/getStudentByStudentIdReducer";

const getStudentDetails = (id: any, token: any) => async (
    dispatch: Dispatch
) => {
    dispatch(setloading(true));
    try {
        const result = await api.get(`school-admin/school/get-student/${id}/`, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Token ${token}`,
            },
        });

        if (result) {
            dispatch(setloading(false));
            if (result.data?.status === 200) {
                dispatch(setstudentDetails(result.data));
            } else {
                dispatch(seterror(result.data?.message ?? "Something Went Wrong!"));
            }
        }
    } catch (error: any) {
        dispatch(seterror(error.response.data.message));
    }
};

export { getStudentDetails, resetstudentDetails };