import React, { useEffect, useState } from "react";
import { user_activity_log_card_image , note_text} from "../../assets/images/images";
import "./UserActivityLog.css";
import { useDispatch, useSelector } from "react-redux";
import {userActivityLogs, resetuseractivitylogs} from "../../state/actions/userActivityLogsAction"
import { useAuth } from "../../features/auth/AuthContext";
import { useNavigate } from "react-router-dom";
import { formatDistanceToNow, parseISO } from 'date-fns';




function Useractivitylog() {

  const dispatch = useDispatch()
  const {token} = useAuth();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const activityLog = useSelector((state) => state.userActivityLogs);

  const userActivity =()=>{
    dispatch(userActivityLogs(currentPage, token))
  }


  useEffect(()=>{
      userActivity();

      return () =>{
          dispatch(resetuseractivitylogs());
      }
  }, [])

  // console.log(activityLog);

  const activityLogs = [
    {
      user_activity_log_card_image: user_activity_log_card_image,
      title: 'Eleanor Pena',
      time: '7 min ago',
      date: 'Feb-27-2024',
      des: 'Jane Doe (Student) Joined your course (Fundaments of neuroscience).',
    },
    
  ];

    // Assuming activityLog.activity is an array of activity objects
    const sortedActivities = [...activityLog.activity]
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    .slice(0, 5);

  // Utility function to format date
  function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit'
    })
    .replace(',', '')  // Removes the comma found after the day
    .replace(/ /g, '-'); // Replaces remaining spaces with dashes
  }

// Utility function to format time
  function formatTime(dateString) {
    const date = parseISO(dateString); // Converts the ISO string to a Date object
    return formatDistanceToNow(date, { addSuffix: true }); // "6 minutes ago"
  }

// Utility function to format time
// function formatTime(dateString) {
//   const date = new Date(dateString);
//   return date.toLocaleTimeString([], { timeStyle: 'short' }); // Output format example: "12:00 AM"
// }



  return (
    <>
      <div className="user-activity-log-section">
        <div className="user-activity-log-wrapper">
          <div className="user-activity-log-container">
            <div className="user-activity-log-title">
              <img src={note_text}/>
              <h2>User Activity Log</h2>
            </div>
            <div className="user-activity-log-card-container">
              {sortedActivities.map((item, index) => (
                <div className="user-activity-log-card">
                    <div key={index} className="user-activity-log-card-folder">
                        <div className="user-activity-log-card-image">
                            <img src={item.user_detail.profile_pic} alt="Activity"/>
                        </div>
                        <div className="user-activity-log-card-des-folder">
                            <div className="user-activity-log-card-des-title-wrapper">
                            <div className="user-activity-log-card-des-title">
                                <p className="user-activity-log-inner-title">{item.user_detail.name}</p>
                                <p className="user-activity-log-time">{formatTime(item.created_at)}</p>
                            </div>
                            <span>{formatDate(item.created_at)}</span>
                            </div>
                            <p className="user-activity-log-card-des">{item.message}</p>
                        </div>
                    </div>
                    <p className="user-activity-log-card-des user-activity-log-des">{item.message}</p>
                </div>
              ))}
            </div>
          </div>
          <button type="button" className="user-activity-log-view-all-btn" onClick={()=> navigate(`/live-activitylog`)} >View All</button>
       
        </div>
      </div>
    </>
  );
}

export default Useractivitylog;
