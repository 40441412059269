import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface BookDemo {
    isLoading: boolean;
    error: string | null;
    message: string | null;
}

const initialState: BookDemo = {
    message: null,
    isLoading: false,
    error: null,
};

const bookademoSlice = createSlice({
    name: 'bookADemo',
    initialState,
    reducers: {
        setBookADemo: (state: BookDemo, action: PayloadAction<BookDemo>) => {
            state.message = action.payload.message;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: BookDemo, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: BookDemo, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetBookADemo: () => {
            return initialState;
        },
    },
});

export const { setBookADemo, setloading, seterror, resetBookADemo } = bookademoSlice.actions;

export default bookademoSlice.reducer;
