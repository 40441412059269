import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Count {
    schools_count: string | null;
    teacher_count: string | null;
    students_count: string | null;
    courses_count: string | null;
    lessons_count: string | null;
    isLoading: boolean;
    error: string | null;
}

const initialState: Count = {
    schools_count: null,
    teacher_count: null,
    students_count: null,
    courses_count: null,
    lessons_count: null, 
    isLoading: false,
    error: null,
};

const admindashboardcountslice = createSlice({
    name: 'admindashboardcount',
    initialState,
    reducers: {
        setdashboardcount: (state : Count, action: PayloadAction<Count>) => {
            state.schools_count= action.payload.schools_count;
            state.teacher_count= action.payload.teacher_count;
            state.students_count = action.payload.students_count;
            state.courses_count= action.payload.courses_count;
            state.lessons_count= action.payload.lessons_count;
            state.isLoading= false;
            state.error= null;
        },
        setloading: (state: Count, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null; 
        },
        seterror: (state : Count, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetdashboardcount: () => {
            return initialState;
        },
    },
});

export const { setdashboardcount, setloading, seterror, resetdashboardcount } = admindashboardcountslice.actions;

export default admindashboardcountslice.reducer;
