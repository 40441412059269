import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AdminCurrentSubscriptionPlan {
    isLoading: boolean;
    error: string | null;
    currentSubscription: any;
}

const initialState: AdminCurrentSubscriptionPlan = {
    currentSubscription: null,
    isLoading: false,
    error: null,
};

const AdminCurrentSubscriptionPlanSlice = createSlice({
    name: 'AdminCurrentSubscriptionPlan',
    initialState,
    reducers: {
        setAdminCurrentSubscriptionPlan: (state: AdminCurrentSubscriptionPlan, action: PayloadAction<AdminCurrentSubscriptionPlan>) => {
            state.currentSubscription = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: AdminCurrentSubscriptionPlan, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: AdminCurrentSubscriptionPlan, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetAdminCurrentSubscriptionPlan: () => {
            return initialState;
        },
    },
});

export const { setAdminCurrentSubscriptionPlan, setloading, seterror, resetAdminCurrentSubscriptionPlan } = AdminCurrentSubscriptionPlanSlice.actions;

export default AdminCurrentSubscriptionPlanSlice.reducer;
