import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Course {
  school_id: string;
  uid: string;
  name: string;
  location: string;
  description: string;
  joined_at: string;
  image: null | string; // It can be either null or a string
}

interface CourseList {
  courses: Course[];
  next: string | null;
  previous: string | null;
  count: number | null;
  totalPages: number | null;
  isLoading: boolean;
  error: string | null;
}

const initialState: CourseList = {
  courses: [],
  next: null,
  previous: null,
  count: 0,
  totalPages: 0,
  isLoading: false,
  error: null,
};

const coursesBySchoolID = createSlice({
  name: "getCoursesBySchoolID",
  initialState,
  reducers: {
    setCourseListBySchoolId: (
      state: CourseList,
      action: PayloadAction<Course[]>
    ) => {
      state.courses = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    setPagination: (state: CourseList, action: PayloadAction<CourseList>) => {
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.count = action.payload.count;
      state.totalPages = Math.ceil((action.payload.count ?? 0) / 12);
      state.isLoading = false;
      state.error = null;
    },
    setloading: (state: CourseList, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
      state.error = null;
    },
    seterror: (state: CourseList, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    resetCourseListBySchoolId: () => {
      return initialState;
    },
  },
});

export const {
  setCourseListBySchoolId,
  setloading,
  seterror,
  setPagination,
  resetCourseListBySchoolId,
} = coursesBySchoolID.actions;

export default coursesBySchoolID.reducer;
