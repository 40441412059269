import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AssignmentStats {
    assignStats: any;
    next: string | null;
    previous: string | null;
    count: number | null;
    totalPages: number | null;
    isLoading: boolean;
    error: string | null;
}

const initialState: AssignmentStats = {
    assignStats: [],
    next: null,
    previous: null,
    count: 0,
    totalPages: 0,
    isLoading: false,
    error: null,
};

const getAssignmentStatsSlice = createSlice({
    name: "assignmentStats",
    initialState,
    reducers: {
        setAssignmentStats: (state: AssignmentStats, action: PayloadAction<AssignmentStats>) => {
            state.assignStats = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        setPagination: (state: AssignmentStats, action: PayloadAction<AssignmentStats>) => {
            state.next = action.payload.next;
            state.previous = action.payload.previous;
            state.count = action.payload.count;
            state.totalPages = Math.ceil((action.payload.count ?? 0) / 12);
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: AssignmentStats, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: AssignmentStats, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetAssignmentStats: () => {
            return initialState;
        },
    },
});

export const {
    setAssignmentStats,
    setloading,
    seterror,
    setPagination,
    resetAssignmentStats,
} = getAssignmentStatsSlice.actions;

export default getAssignmentStatsSlice.reducer;
