import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SetTeacherState {
    setTeacher: any;
    isLoading: boolean;
    error: string | null;
}

const initialState: SetTeacherState = {
    setTeacher: null,
    isLoading: false,
    error: null,
};

const setTeacherSubscriptionPlanSlice = createSlice({
    name: 'setTeacherSubscription',
    initialState,
    reducers: {
        setTeacherSubscriptionPlan: (state: SetTeacherState, action: PayloadAction<any>) => {
            state.setTeacher = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        setLoadingPlan: (state: SetTeacherState, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        setErrorPlan: (state: SetTeacherState, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetTeacherDetailsPlan: () => initialState,
    },
});

export const { setTeacherSubscriptionPlan, setLoadingPlan, setErrorPlan, resetTeacherDetailsPlan } = setTeacherSubscriptionPlanSlice.actions;

export default setTeacherSubscriptionPlanSlice.reducer;
