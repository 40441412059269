import { $generateHtmlFromNodes, $generateNodesFromDOM } from "@lexical/html";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { $insertNodes } from "lexical";
import React, { useEffect, useState } from "react";

interface Props {
    initialHtml?: string;
    onHtmlChanged: (html: string) => void;
}

const HtmlPlugin = ({ initialHtml, onHtmlChanged }: Props) => {
    const [editor] = useLexicalComposerContext();
    const [isFirstRender, setIsFirstRender] = useState(true);

    useEffect(() => {
        if (!initialHtml || !isFirstRender) return;

        setIsFirstRender(false);
        editor.update(() => {
            const parser = new DOMParser();
            try {
                const dom = parser.parseFromString(initialHtml, "text/html");
                const nodes = $generateNodesFromDOM(editor, dom);
                $insertNodes(nodes);
            } catch (error) {
                console.error("Error parsing or inserting HTML:", error);
            }
        });
    }, [initialHtml, isFirstRender]);

    return (
        <OnChangePlugin
            onChange={(editorState) => {
                editorState.read(() => {
                    onHtmlChanged($generateHtmlFromNodes(editor));
                });
            }}
        />
    );
};

export default HtmlPlugin;

// import { $generateHtmlFromNodes, $generateNodesFromDOM } from "@lexical/html";
// import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
// import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
// import { $insertNodes } from "lexical";
// import React, { useEffect, useState } from "react";

// interface Props {
//     initialHtml?: string;
//     onHtmlChanged: (html: string) => void;
// }

// const HtmlPlugin = ({ initialHtml, onHtmlChanged }: Props) => {
//     const [editor] = useLexicalComposerContext();

//     const [isFirstRender, setIsFirstRender] = useState(true);

//     useEffect(() => {
//         if (!initialHtml || !isFirstRender) return;

//         setIsFirstRender(false);

//         editor.update(() => {
//             const parser = new DOMParser();
//             const dom = parser.parseFromString(initialHtml, "text/html");
//             const nodes = $generateNodesFromDOM(editor, dom);
//             $insertNodes(nodes);
//         });
//     }, []);



//     return (
//         <OnChangePlugin
//             onChange={(editorState) => {
//                 editorState.read(() => {
//                     onHtmlChanged($generateHtmlFromNodes(editor));
//                 });
//             }}
//         />
//     );
// };

// export default HtmlPlugin;