import React from "react";
import "./SavePopUp.css";

function SavePopUp({ isOpen, onClose, text }) {
    if (!isOpen) return null;

    return (
        <div className="save-popup-wrapper">
            <div className="save-popup-cotainer">
                <h2>{text}</h2>
                <div className="save-popup-btn">
                    <button onClick={onClose}>Ok</button>
                </div>
            </div>
        </div>
    );
}

export default SavePopUp;