import React from 'react';
import { course_demo_image, search , srot} from "../../../assets/images/images"; 
import { ReactSVG } from 'react-svg';
import { useNavigate } from 'react-router-dom';

function RatingCard() {
    const navigate= useNavigate();

    return (
        <>  
           <div className='ratingCard-section'>
                <div className="search_header-container">
                    <div className="search-list">
                    <ReactSVG className="search-icon" src={search} />
                    <input type="text" className="search-input" placeholder="Search" />
                    </div>
                    <div className="filter-wrapper">
                    <div className="lessonviewprogress-filder-tab">
                        <span>
                        <i className="fa-solid fa-sliders"></i>
                        </span>
                        <p>Filter</p>
                    </div>
                    <div className="lessonviewprogress-filder-tab">
                        <span>
                            {/* <img src={srot}/> */}
                            <i class="fa-solid fa-arrow-down-wide-short"></i>
                        </span>
                        <p>Sort</p>
                    </div>
                    </div>
                </div>
                <div className='ratingCard-wrapper'>
                    <div className="studentDashboar-Suggested-Courses-folder" id="studentCourseCard-folder">
                        <div className="studentDashboar-Suggested-Courses-image">
                            <img src={course_demo_image} alt="" />
                        </div>
                        <div className="studentDashboar-Suggested-Courses-profile-folder">
                            <div className="studentDashboar-Suggested-Courses-profile">
                                {/* <div className="studentDashboar-profile-image">
                                    <img src={ai_avatar} alt="" />
                                </div> */}
                                <div className="studentDashboar-Suggested-Courses-profile-title">
                                    <h2 className="tudentDashboar-profile-card-title">Rehan blackman</h2>
                                    <p>Teacher</p>
                                </div>
                            </div>
                             {/* Hidden for subdomain */}
                            {/* <div className="studentDashboar-Suggested-Courses-profile-rate">
                                <a href="#"><i className="fa-solid fa-star"></i></a>
                                <p className="rate">4.1</p>
                                <p className="number">(322)</p>
                            </div> */}
                             {/* Hidden for subdomain */}
                        </div>
                        <p className="studentDashboar-profile-course-name">
                            Science
                        </p>
                        <h2 className="studentDashboar-profile-course-title">
                            Fundamental of Neuroscience
                        </h2>
                        <span>$20</span>
                        <div className="studentDashboar-profile-btn-folder">
                            <button type="button" onClick={()=>navigate("/ratings/123/view")}>View Ratings</button>
                        </div>
                    </div>
                    <div className="studentDashboar-Suggested-Courses-folder" id="studentCourseCard-folder">
                        <div className="studentDashboar-Suggested-Courses-image">
                            <img src={course_demo_image} alt="" />
                        </div>
                        <div className="studentDashboar-Suggested-Courses-profile-folder">
                            <div className="studentDashboar-Suggested-Courses-profile">
                                {/* <div className="studentDashboar-profile-image">
                                    <img src={ai_avatar} alt="" />
                                </div> */}
                                <div className="studentDashboar-Suggested-Courses-profile-title">
                                    <h2 className="tudentDashboar-profile-card-title">Rehan blackman</h2>
                                    <p>Teacher</p>
                                </div>
                            </div>
                             {/* Hidden for subdomain */}
                            {/* <div className="studentDashboar-Suggested-Courses-profile-rate">
                                <a href="#"><i className="fa-solid fa-star"></i></a>
                                <p className="rate">4.1</p>
                                <p className="number">(322)</p>
                            </div> */}
                             {/* Hidden for subdomain */}
                        </div>
                        <p className="studentDashboar-profile-course-name">
                            Science
                        </p>
                        <h2 className="studentDashboar-profile-course-title">
                            Fundamental of Neuroscience
                        </h2>
                        <span>$20</span>
                        <div className="studentDashboar-profile-btn-folder">
                            <button type="button" onClick={()=>navigate("/ratings/123/view")}>View Ratings</button>
                        </div>
                    </div>
                </div>
           </div>
        </>
    )
}

export default RatingCard