import { Dispatch } from "redux";
import api from "../../api/api";

import {
    setuseractivitylogs,
    setPagination,
    setloading,
    seterror,
    resetuseractivitylogs,
} from '../reducers/userActivityLogsReducer'


const userActivityLogs = (page: any,token: any) => async (dispatch: Dispatch) => {
    dispatch(setloading(true));
    try {
      const result = await api
         .get(
                `activity-logs/`,
                {
                    params: {
                        page: page,
                    },
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Token ${token}`,
                    },
                }
            )
        
        if(result){
         dispatch(setloading(false));
         if (result.data?.status === 200) {
                 dispatch(setuseractivitylogs(result?.data?.data));
                dispatch(setPagination(result.data));
           }
           else {
             dispatch(seterror(result.data?.message ?? "Something Went Wrong!"));
           }
        }
    }
    catch (error: any) {
     dispatch(seterror(error.response.data.message));
    }
 }
   
 export { userActivityLogs, resetuseractivitylogs} 