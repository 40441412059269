import { Dispatch } from "redux";
import api from "../../api/api";
import {
    setPlatformFee, isloading, seterror, resetPlatformFee
} from "../reducers/getPlatformFeeReducer";

const getPlatformFee = (token: any) => async (dispatch: Dispatch) => {
    dispatch(isloading(true));
    try {
        const result = await api
            .get(
                `/taxes/get-platform-fee/`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Token ${token}`,
                    },
                }
            )
        if (result) {
            dispatch(isloading(false));
            if (result.data?.status === 200) {
                dispatch(setPlatformFee(result?.data?.data));
            }
            else {
                dispatch(seterror(result.data?.status ?? "Something went wrong"));
            }
        }
    }
    catch (error: any) {
        dispatch(seterror(error.response.data.message || "Something went wrong"));
    }
}

export { resetPlatformFee, getPlatformFee };



