import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TeacherSubscription {
    teachersubscription: any;
    isLoading: Boolean;
    error: string | null;
}

const initialState: TeacherSubscription = {
    teachersubscription: [],
    isLoading: false,
    error: null,
};

const teachersubscriptionSlice = createSlice({
    name: 'teachersubscription',
    initialState,
    reducers: {
        setTeacherSubscription: (state: TeacherSubscription, action: PayloadAction<TeacherSubscription>) => {
            state.teachersubscription = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        isloading: (state: TeacherSubscription, action: PayloadAction<Boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: TeacherSubscription, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetTeacherSubscription: () => {
            return initialState;
        },
    },
});

export const { setTeacherSubscription, isloading, seterror, resetTeacherSubscription } = teachersubscriptionSlice.actions;

export default teachersubscriptionSlice.reducer;
