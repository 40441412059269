import { Dispatch } from "redux";
import api from "../../api/api";
import {
    setupdateCourse, setloading, seterror, resetupdateCourse
} from "../reducers/updateCourseReducer";

const updateCourse = (data: any, token: any, id: any) => async (dispatch: Dispatch) => {
    dispatch(setloading(true));
    try {
        const result = await api
            .put(
                `/courses/update-course/${id}/`,
                data,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Token ${token}`
                    },
                }
            )

        if (result) {
            dispatch(setloading(false));
            if (result.data?.status === 200) {
                dispatch(setupdateCourse(result?.data?.data));
            }
            else {
                dispatch(seterror(result.data?.message ?? "Something Went Wrong!"));
            }
        }
    }
    catch (error: any) {
        dispatch(seterror(error.response.data.message));
    }
}

export { updateCourse, resetupdateCourse }

