import { useSelector } from "react-redux";
import PreprocessAndRenderLatex from "../../../../components/ProcessRenderLatex";

function MCQsView({ options, student_answer, ai_answer }) {
  const globalState = useSelector((state) => state.globalState);
  return (
  
    <div className="AssingmentMCQs-mock-test-answer-container">
       {options.includes(student_answer) && student_answer === ai_answer ? (

        <p className= {globalState?.role === "Student" ? 'AssingmentMCQs-answer-title' : 'AssingmentMCQs-answer-title-teacher'}>
         {globalState?.role === "Student" ? 'Your' : 'Student'} Answer - {String.fromCharCode(65 + options.indexOf(student_answer))}
        </p>
      ) : (
        <p className={globalState?.role === "Student" ? 'AssingmentMCQs-wrong-answer-title' : 'AssingmentMCQs-wrong-answer-title-teacher'}>
          {globalState?.role === "Student" ? 'Your' : 'Student'} Answer - {String.fromCharCode(65 + options.indexOf(student_answer))}
        </p>
      )}
      <div className="options AssingmentMCQs-mock-test-answer-container">
        {options.map((option, index) => (
          <>
          
          <div
            className={`option AssignmentViewProgress-mock-test-answer-folder ${
              option === ai_answer
                ? "correct !important"
                // : option === student_answer
                // ? "incorrect !important"
                : "incorrect !important"
            }`}
            key={index}
          >
            <div style={{ paddingRight: "10px" }}>
              {String.fromCharCode(65 + index) + ")"}
            </div>
            <div className="AssingmentMCQs-answer">
              <PreprocessAndRenderLatex content={option} />
              {option === ai_answer
                ? <i class="fa-solid fa-check"></i>
                : <i class="fa-solid fa-x"></i>}
            </div>
          </div>
          </>
          
        ))}
      </div>
    </div>
  );
}

export default MCQsView;
