import React, { useEffect, useState } from 'react';
import CustomTabs from '../../CustomTabs/CustomTabs';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../features/auth/AuthContext';
import { getAllCoursesByTeacherId, resetCourseList } from "../../../state/actions/getAllCoursesByTeacherIdAction";
import { useDispatch, useSelector } from 'react-redux';
import CourseCard from '../../Courses/CourseCard';
import { ReactSVG } from 'react-svg';
import { search , srot } from "../../../assets/images/images"; 
import SearchComponent from '../../SearchComponent/SearchComponent';
import SortPopup from '../../../pages/customSort/SortPopup';
import PaginationView from '../../Pagination/PaginationView';
import { CircularProgress, Grid } from '@mui/material';
import LoadingComponent from '../../LoadingComponent/LoadingComponent';

function CreatedCourses() {

    const { token } = useAuth();
    const { teacher_id } = useParams();
    const [currentPage, setCurrentPage] = useState(1);
    const [searchOption, setsearchOption] = useState("");
    const [sortOption, setSortOption] = useState("");
    const [isVisible, setIsVisible] = useState(false);
    const [activeTab, setActiveTab] = useState("School"); // Default active tab is 'lessons'
    const globalState = useSelector((state) => state.globalState);
    const { courses, next, previous, totalPages, isLoading } = useSelector((state) => state.getallCoursesByTeacherId);

    const dispatch = useDispatch();

    const handleSearch = (e) => {
        setsearchOption(e.target.value)
    }

    const handleSortOptionChange = (newSortOption) => {
        setSortOption(newSortOption);
    };

    const handleTabClick = (newActiveTab) => {
        setActiveTab(newActiveTab);
    };

    const getAllCoursesRequest = () => {
        dispatch(getAllCoursesByTeacherId(currentPage, teacher_id, token, searchOption, sortOption))
    }

    useEffect(() => {
        getAllCoursesRequest();
        return () => {
            dispatch(resetCourseList());
        }
    }, [currentPage, searchOption, sortOption])

    return (
        <>  
            <div className="search_header-container">
                <SearchComponent
                    search={search}
                    searchPlaceholder="Search"
                    searchValue="search"
                    onchange={handleSearch}
                    name="searchOption"
                    value={searchOption}
                />
                <div className="filter-wrapper">
                    <div className="lessonviewprogress-filder-tab"
                        onClick={() => {
                            setIsVisible(!isVisible)
                        }}
                    >
                        <span>
                            {/* <img src={srot}/> */}
                            <i class="fa-solid fa-arrow-down-wide-short"></i>
                        </span>
                        <p>Sort</p>
                        <SortPopup
                            isVisible={isVisible}
                            handleApplyClick={() => { }}
                            sortOption={sortOption}
                            onSortOptionChange={handleSortOptionChange}
                        />
                    </div>
                </div>
            </div>

            {isLoading ?
            <>
                <LoadingComponent
                loading_text = "Educating the Future, Loading Now."
              /> 
            </>
              : courses && courses?.length > 0 ? 
              courses?.map((data, index) => (
                <CourseCard
                    key={index}
                    courseimage={data.image}
                    coursename={data.name}
                    enrolled_students={data.enrolled_students}
                    lesson_count={data.lesson_count}
                    start_date={data.start_date}
                    end_date={data.end_date}
                    description={data.description}
                    course_info_image={globalState.teacher_details?.data?.ai_avatar}
                    Teacher_title={"Teacher"}
                    Teacher_name={data.teacher}
                    teacher_id = {data.teacher_id}
                    course = {data}
                // Sales_Courses_title={data.Sales_Courses_title}
                // checkbox={data.checkbox}
                //location={data.location}
                //location_name={data.location_name}
                />
            )) : 
                <p style={{ textAlign: "center", marginTop: "10px", margin: "0px auto" }}>No Courses Found</p>
             }

            {/* {!courses.length && <p
                style={{ textAlign: "center" }}
            >No Courses Found</p>} */}

            {totalPages > 1 && (
                <PaginationView
                    totalPages={totalPages}
                    next={next}
                    previous={previous}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                />
            )}

        </>

        // <div className="Dashboard-List-Table">
        //     <div className="Dashboard-List-Table-wrapper">
        //         <div className="view-lessons-section">
        //             <div className="view-lessons-wrapper">
        //                 {
        //                     <div className="view-lessons-btn-container teacehr-tab-setion">
        //                         <button
        //                             className={`tab-button ${activeTab === "School" ? "active" : ""
        //                                 }`}
        //                             onClick={() => handleTabClick("School")}
        //                         >
        //                             School Courses
        //                         </button>
        //                         <button
        //                             className={`tab-button ${activeTab === "Sale" ? "active" : ""
        //                                 }`}
        //                             onClick={() => handleTabClick("Sale")}
        //                         >
        //                             Sales Courses
        //                         </button>
        //                     </div>
        //                 }

        //                 <div className="tab-content">
        //                     {activeTab === "School" && (
        //                         <div className="Dashboard-List">
        //                             <div className="Dashboard-List-wrapper">
        //                                 hello
        //                             </div>
        //                         </div>
        //                     )}

        //                     {activeTab === "Sale" && (
        //                         <div className="Dashboard-List">
        //                             <div className="Dashboard-List-wrapper">
        //                                 hello
        //                             </div>
        //                         </div>
        //                     )}
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}

export default CreatedCourses