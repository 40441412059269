import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UpdateFileUrl {
    message: any,
    isLoading: boolean;
    error: string | null;
}

const initialState: UpdateFileUrl = {
    message: null,
    isLoading: false,
    error: null,
};

const updateFileUrlSlice = createSlice({
    name: "updateFileUrl",
    initialState,
    reducers: {
        updatefileurl: (state: UpdateFileUrl, action: PayloadAction<UpdateFileUrl>) => {
            state.message = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: UpdateFileUrl, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: UpdateFileUrl, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetupdateFileUrl: () => {
            return initialState;
        },
    },
});

export const {
    updatefileurl,
    setloading,
    seterror,
    resetupdateFileUrl,
} = updateFileUrlSlice.actions;

export default updateFileUrlSlice.reducer;