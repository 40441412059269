import React, { useState } from 'react';
import {
    material_symbols_light_upload
} from "../../assets/images/images";

function DraggableFileUpload({ handleFileChange, fileInputRef }) {

    const [files, setFiles] = useState([]);
    const [isDragging, setIsDragging] = useState(false);

    const handleDragOver = (event) => {
        event.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = (event) => {
        // event.preventDefault();
        // setIsDragging(false);
        // const droppedFiles = Array.from(event.dataTransfer.files);
        // handleFiles(droppedFiles);


        event.preventDefault();
        setIsDragging(false);

        // Extract files from dataTransfer
        const droppedFiles = Array.from(event.dataTransfer.files);

        // Convert droppedFiles to target.files-like structure
        const dataTransfer = new DataTransfer();

        droppedFiles.forEach(file => {
            dataTransfer.items.add(file);
        });

        // Create a simulated event for input element
        const inputEvent = new Event('change', { bubbles: true });
        Object.defineProperty(inputEvent, 'target', {
            writable: true,
            value: { files: dataTransfer.files },
        });

        handleFileChange(inputEvent);
    };

    return (

        // <div class="upload-files-container"
        //     onDragOver={handleDragOver}
        //     onDragLeave={handleDragLeave}
        //     onDrop={handleDrop}
        // >
        //     <div class="drag-file-area">
        //         <div className="material_symbols_light_upload">
        //             <img src={material_symbols_light_upload} onChange={handleFileChange} />
        //         </div>
        //         <h2 class="drag-file-here-text">Drag file Here</h2>
        //         <h2 class="drag-file-here-text">
        //             {" "}
        //             or{" "}
        //             <span class="browse-files">
        //                 <input type="file" class="default-file-input" />
        //             </span>
        //             <span className="Browse">Browse</span>
        //         </h2>
        //         <span class="material-icons-outlined">
        //             PDF, JPEG, DOCX, XLSX Formats, up to 40 MB
        //         </span>
        //     </div>
        // </div>

        <div class="upload-files-container"
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            style={{
                border: '2px dashed #cccccc',
                padding: '20px',
                width: '300px',
                textAlign: 'center',
                margin: 'auto',
                borderRadius: '5px',
                backgroundColor: isDragging ? '#e0e0e0' : '#f9f9f9',
            }}
        >
            <div className="material_symbols_light_upload">
                <img src={material_symbols_light_upload} onChange={handleFileChange} />
            </div>
            <p>Drag file Here</p>
            <input
                type="file"
                multiple
                onChange={handleFileChange}
                style={{ display: 'none' }}
                id="fileInput"
                ref={fileInputRef}
            />
            <label htmlFor="fileInput" style={{ cursor: 'pointer' }}>
                <span>Or</span> Browse
            </label>
            <div>
                <span class="material-icons-outlined">
                    PDF, JPEG, DOCX, XLSX Formats, up to 40 MB
                </span>
            </div>

            <ul>
                {files.map((file, index) => (
                    <li key={index}>{file.name}</li>
                ))}
            </ul>
        </div>
    )
}

export default DraggableFileUpload