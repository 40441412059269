import React, { useEffect, useState } from 'react';
import "./MyOrders.css"
import BackButton from '../BackButton/BackButton';
import { getPurchaseHistory, resetPurchaseHistory } from "../../state/actions/purchaseHistoryAction";
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from "../../features/auth/AuthContext";
import PaginationView from "../../components/PaginationView/PaginationView";
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import { useNavigate } from 'react-router-dom';

function MyOrders() {

    const [search, setsearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { token } = useAuth();
    const { count,
        next,
        previous,
        totalPages,
        error, purchaseHistoryList, isLoading } = useSelector((state) => state.purchaseHistory);

    const formatDate = (isoTimestamp) => {
        const fixedDateTime = new Date(isoTimestamp);

        // Get individual date components
        const readableFormat = fixedDateTime.toLocaleString('en-US', {
            month: 'short', // Short name of the month
            day: 'numeric', // Numeric day of the month
            year: 'numeric', // Numeric year
        });
        return readableFormat;
    }

    useEffect(() => {
        dispatch(getPurchaseHistory(currentPage, search, token));
    }, [currentPage, search])

    useEffect(() => {
        return () => {
            dispatch(resetPurchaseHistory());
        }
    }, [])
    // const ordersData = [
    //     {
    //         transactionDate: "Jan-20-2024",
    //         items: ["Fundamental of Neuroscience", "Fundamental of Neuroscience", "Fundamental of Neuroscience", "Fundamental of Neuroscience"],
    //         prices: ["$52", "$28", "$52", "$28"]
    //     },
    //     {
    //         transactionDate: "Jan-20-2024",
    //         items: ["Fundamental of Neuroscience", "Fundamental of Neuroscience"],
    //         prices: ["$52", "$28"]
    //     },
    //     {
    //         transactionDate: "Jan-20-2024",
    //         items: ["Fundamental of Neuroscience", "Fundamental of Neuroscience"],
    //         prices: ["$52", "$28"]
    //     },
    // ];
    return (
        <>
            <div className='my-orders-section'>
                <div className='my-orders-wrapper'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12'>
                                <BackButton buttonName={"My Orders"} />
                                <div className='my-orders-serach'>
                                    <input type='text' placeholder='Search items' name="search" value={search} onChange={(e) => setsearch(e.target.value)} />
                                    <span><i class="fa-solid fa-magnifying-glass"></i></span>
                                </div>
                                <div className='my-orders-table'>
                                    <table>
                                        <thead>
                                            <th>Transaction Date</th>
                                            <th>Items</th>
                                            <th>Price</th>
                                            <th></th>
                                        </thead>
                                        <div className='my-orders-line'></div>
                                        <tbody>
                                            {
                                                isLoading ? <LoadingComponent
                                                    loading_text="Educating the Future, Loading Now."
                                                />
                                                    :
                                                    purchaseHistoryList && purchaseHistoryList.length > 0 ?
                                                        purchaseHistoryList.map((order, index) => (
                                                            <>
                                                                <tr key={index}>
                                                                    <td><p>{formatDate(order.transaction_date)}</p></td>
                                                                    <td>
                                                                        <div className='my-orders-course'>
                                                                            {order.items.map((item, i) => (
                                                                                <button key={i} type='button' 
                                                                                        onClick={() => {
                                                                                            navigate(`/course/${item.course_id}`,
                                                                                                { state: { orderpathname: "my-orders" } }
                                                                                            );
                                                                                            // if (item.id) {
                                                                                            //     // navigate(`/course/${item.course_id}/lesson/${item.lesson_id}/overview`);
                                                                                            //     navigate(`/course/${item.course_id}`,
                                                                                            //         { state: { orderpathname: "my-orders" } }
                                                                                            //     );
                                                                                            // } else if (item.course_id) {
                                                                                            //     navigate(`/course/${item.course_id}`);
                                                                                            // }
                                                                                        }}
                                                                                >{item.name}</button>
                                                                            ))} 
                                                                        </div>
                                                                    </td>
                                                                    <td data-th="Price">
                                                                        <div className='my-orders-price'>
                                                                            {order.items.map((item, i) => (
                                                                                <span key={i}>$ {item.price}</span>
                                                                            ))}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <a className='my-orders-Invoice-btn' href={order.invoice_link} target="_blank">Invoice</a>
                                                                    </td>
                                                                </tr>
                                                                <div className='my-orders-space'></div>
                                                            </>
                                                        )) : <p style={{ textAlign: "center", marginTop: "10px", margin: "0px auto" }}>No Purchase History Found</p>}
                                        </tbody>
                                    </table>
                                </div>
                                {totalPages > 1 && (
                                    <PaginationView
                                        totalPages={totalPages}
                                        next={next}
                                        previous={previous}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MyOrders