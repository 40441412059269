import React from 'react';
import { ReactSVG } from "react-svg";
import 'animate.css';
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";

const ChooseyourroleData = ({ des, image, onClick, data_wow_duration }) => {
    return (

        <div className='choose-your-role-folder wow animate__fadeInUpBig' data-wow-duration={data_wow_duration}>
            <div className='choose-your-role-image'>
                <img src={image} alt='' />
            </div>
            <div className='choose-your-role-btn-folder'>
                <button onClick={onClick}>
                    <p>{des}</p>
                    <span><i className="fa-solid fa-arrow-right"></i></span>
                </button>
            </div>
        </div>
    )
}

export default ChooseyourroleData;