import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { useAuth } from "../../../../../../features/auth/AuthContext";
import { useSelector } from "react-redux";
import api, {wsURL} from "../../../../../../api/api";
import { Link } from "react-router-dom";
import FilteredInput from "../../../../../../components/FilteredInput/FilteredInput";
import { changeChatStatus } from "../../../../../../api/change_chat_status";
import { createChatRoom } from "../../../../../../api/create_chat_room";
import { getUserId } from "../../../../../../api/get_user_id";


const Message = () => {
    const [getEnrollStudent, setGetEnrollStudent] = useState({});
    const [getuserid, setuserid] = useState();
    const [getstatuspopup, setstatuspopup] = useState(false);
    const [getstudentname, setstudentname] = useState("");
    const [getstudentimage, setstudentimage] = useState("");
    const [getchathistory, setchathistory] = useState([]);
    const [studentUserId, setStudentUserId] = useState("")
    const [message, setMessage] = useState("");  // For handling input text
    const { token } = useAuth();
    const globalState = useSelector((state) => state.globalState);
    const teacherProfile = globalState?.teacher_details?.data?.ai_avatar;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [isSwitchOn, setIsSwitchOn] = useState(false);
  const messagesEndRef = useRef(null);
  const [roomId, setRoomId] = useState();
  const [aichatbotquery, setAichatbotquery] = useState("");
  const [getSocket, setSocket] = useState(null);
  const [query, setQuery] = useState("");
  const [userId, setUserId] = useState();

  // const [chatHist, setChatHist] = useState(
  //   getchathistory !== null && getchathistory?.length > 0
  //     ? getchathistory
  //     : [
  //         {
  //           query: "",
  //           answer: "",
  //             // ai_welcome_msg !== undefined
  //             //   ? ai_welcome_msg
  //             //   : "Hi, I am your Ai Assistant, How may I help you?",
  //         },
  //       ]
  // );


  // const socketUrl = `${wsURL}ws/course_chat/${roomId}/?token=${token}`;
// console.log("roomId: ", roomId);
// console.log("setRoomId: ", setRoomId);

  const payload = {
    student_user_id: studentUserId,
    course_id: "",
    current_lesson: "",
    lesson_id: "",
    assignment_id: "",
    query: query,
    switch_to : "AI",
    message: message
  };
//  console.log("studentUserId:", studentUserId);

    useEffect(() => {
      api
        .get("/auth/teacher/get-student-chat-list/", {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            //return 1;
  
            if (response?.data) {
              setGetEnrollStudent(response?.data?.data);
            }
            // localStorage.setItem('datalist', JSON.stringify(response?.data ?? ''));
          }
        })
        .catch((error) => {});
  
      api
        .get("/auth/get-user-id/", {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        })
        .then((response) => {
          if (response?.data?.status === 200) {
            setuserid(response?.data?.user_id);
          }
        })
        .catch((error) => {
          //
        });
    }, []);
    const chatopenfn = async (student_id, studentname, student_image) => {
      setstudentname(studentname);
      setstudentimage(student_image);
      setStudentUserId(student_id)
      const datadtl = {
        student_user_id: student_id,
        teacher_user_id: getuserid,
      };

      console.log("student_id", student_id);
      console.log("getuserid", getuserid);

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };

      const url_chatbot = "/ai/api/student_chat_history/";
      api
        .post(url_chatbot, datadtl)
        .then((response) => {
          // setroomid(response?.data?.room_id);
          setchathistory(response?.data?.data);
          setstatuspopup(true);
          if (isMobile) {
            setShowLeftPanel(false); // Hide left panel on mobile when chat is opened
          }
        })
        .catch((error) => {});

        const payload = {
          user_id: getuserid,
          against_user_id: student_id,
        };

        console.log("user_id", getuserid);
        console.log("against_user_id", student_id);
    
      
        try {
          const response = await api.post(
            `ai/api/create_chat_room/`,
            payload,
            headers
          );
          if (response.status === 200 || response.status === 201) {
            setRoomId(response?.data?.room_id);
            console.log("response?.data?.room_id: ", response?.data?.room_id);
            initializeWebSocket(response.data.room_id);
            return response?.data?.room_id;
          } else {
            return null;
          }
        } catch (error) {
          console.log(error);
          return null;
        }
    };
  
    const [search, setSearch] = useState("");
    const serachhandleInputChange = (event) => {
      const { value } = event.target;
      setSearch(value);
    };

    const [showLeftPanel, setShowLeftPanel] = useState(true); // New state for controlling visibility
    
    const handleBackButtonClick = () => {
      setstatuspopup(false); // Hide chat section
      setShowLeftPanel(true); // Show left panel, especially for mobile
    };

    // Function to handle message typing
   const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };


  const handlechatbotSubmit = async (e) => {
    e.preventDefault();  // Add this to prevent any default form submission behavior
  
    // This is if you want to send a response immediately upon submitting the form.
    if (message.trim() !== "") {
      // const newChatHistory = [...chatHist, { query: "", answer: message }];
      // setchathistory(newChatHistory);
      const newChatHistory = getchathistory.map((chat)=>{
        return {
          query: Array(chat.query)
          ?.map((item) => item)
          .join(""),
          answer: Array(chat.answer)
          ?.map((item) => item)
          .join(""),
        
        }
      })
  
      try {
        if (getSocket) {
          const newPayload = {
            ...payload,
            answer: message, // Send as an 'answer' not 'query'
            messages: newChatHistory ?? [], // Include the full chat history
            school_name: "Proschool" ?? "Proschool",
          };
          console.log("message:", message);
          const payloadJSON = JSON.stringify(newPayload);
          setchathistory((prevChatHist) => {
            if (Array.isArray(prevChatHist)) {
              return [...prevChatHist, {query: prevChatHist.query, answer: newPayload.answer}];
            } else {
              return [{query: "", answer: newPayload.answer}]
            }
          })
          console.log("newPayload.query:", newPayload.query);
          console.log("newPayload.answer:", newPayload.answer);
          getSocket.send(payloadJSON);  // Sending the payload to the WebSocket server
        }
      } catch (error) {
        console.error("Error sending message through WebSocket:", error);
      }
  
      setMessage(""); // Clear the message input after sending
    }
  };
  

  // const handleSwitchToggle = () => {
  //   if (!isSwitchOn) {
  //   console.log("Teacher active");
  //   }
  //   else{
  //     console.log("AI active");
  //   }
  //   setIsSwitchOn(!isSwitchOn);
  // };

  const handleSwitchToggle = async () => {
    const newStatus = !isSwitchOn; // Determine the new status based on the old status
    setIsSwitchOn(newStatus); // Update the state
    
    const payload = {
        room_id: roomId,
        chat_user_status: newStatus
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };

    try {
        const response = await api.post(
          `ai/api/change-chat-status/`,
          payload,
          headers
        );

        const data = await response.json();
        if (!response.ok) {
            throw new Error(data.message || "An error occurred while updating chat status.");
            
        }
        console.log(wsURL);  // Log the base URL to verify it
        console.log(`ai/api/change-chat-status/`);  // Confirm endpoint path

        console.log("Status updated successfully:", data);
    } catch (error) {
        console.error("Failed to update status:", error.message);
    }
};


  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  let combinedMessage = [];

  // useEffect(() => {
  //   const socket = new WebSocket(socketUrl);

  //   setSocket(socket);

  //   socket.onopen = () => {
  //     console.log("WebSocket connection opened");
  //   };

  //   socket.onmessage = (event) => {
  //     try {
  //       const data = JSON.parse(event.data);

  //       if (data && data.message) {
  //         const message = data.message;

  //         combinedMessage.push(message);
  //         let status = false;
  //         let chatdatares = combinedMessage.join("");

  //         if (
  //           chatdatares.includes("<STARTOFTURN>") ||
  //           chatdatares.includes("<ENDOFTURN>")
  //         ) {
  //           chatdatares = chatdatares
  //             .replace(/<STARTOFTURN>/g, "")
  //             .replace(/<ENDOFTURN>/g, "");

  //           if (combinedMessage.join("").includes("<ENDOFTURN>")) {
  //             status = true;
  //             combinedMessage.length = 0;
  //             combinedMessage.push(chatdatares);
  //           }
  //         }
  //         setChatHist((prevChatHist) => {
  //           const updatedChatHist = Array.isArray(prevChatHist)
  //             ? [...prevChatHist]
  //             : [];
  //           if (updatedChatHist.length > 0) {
  //             const lastQuery = updatedChatHist[updatedChatHist.length - 1];
  //             if (lastQuery) {
  //               lastQuery.answer = chatdatares;
  //             }
  //           }
  //           if (status) {
  //             combinedMessage.length = 0;
  //           }
  //           return updatedChatHist;
  //         });
  //         scrollToBottom();
  //       } else {
  //       }
  //     } catch (error) {}
  //   };

  //   socket.onerror = (error) => {
  //     console.error("WebSocket error:", error);
  //   };

  //   socket.onclose = (event) => {
  //     console.log("WebSocket connection closed:", event);
  //   };
  //   messagesEndRef.current?.scrollIntoView({
  //     inline: "center",
  //     behavior: "smooth",
  //   });

  //   return () => {
  //     if (socket.readyState === 1) {
  //       // <-- This is important
  //       socket.close();
  //     }
  //   };
  // }, []);

  

    // Function to initialize WebSocket
    const initializeWebSocket = (roomId) => {
      const socketUrl = `${wsURL}ws/course_chat/${roomId}/?token=${token}`;
              console.log("roomId: ", roomId);

      const socket = new WebSocket(socketUrl);
      setSocket(socket);
  
      socket.onopen = () => {
        console.log("WebSocket connection opened");
      };
  
      socket.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
  
          if (data && data.message) {
            const message = data.message;
  
            combinedMessage.push(message);
            let status = false;
            let chatdatares = combinedMessage.join("");
  
            if (
              chatdatares.includes("<STARTOFTURN>") ||
              chatdatares.includes("<ENDOFTURN>")
            ) {
              chatdatares = chatdatares
                .replace(/<STARTOFTURN>/g, "")
                .replace(/<ENDOFTURN>/g, "");
  
              if (combinedMessage.join("").includes("<ENDOFTURN>")) {
                status = true;
                combinedMessage.length = 0;
                combinedMessage.push(chatdatares);
              }
            }
            // setchathistory(prev => [...prev, { query: data.query, answer: data.answer }]);
            setchathistory((prevChatHist) => {
              const updatedChatHist = Array.isArray(prevChatHist)
                ? [...prevChatHist]
                : [];
              if (updatedChatHist.length > 0) {
                const lastQuery = updatedChatHist[updatedChatHist.length - 1];
                if (lastQuery) {
                  lastQuery.query = chatdatares;
                }
              }
              if (status) {
                combinedMessage.length = 0;
              }
              return updatedChatHist;
            });
            scrollToBottom();
          } else {
          }
        } catch (error) {}
      };
  
      socket.onerror = (error) => {
        console.error("WebSocket error:", error);
      };
  
      socket.onclose = (event) => {
        console.log("WebSocket connection closed:", event);
      };

      messagesEndRef.current?.scrollIntoView({
        inline: "center",
        behavior: "smooth",
      });
  
      return () => {
        if (socket.readyState === 1) {
          // <-- This is important
          socket.close();
        }
      };
    };

  // useEffect(() => {
  //   getUserId(token).then((user_id, student_id) => {
  //     if (user_id !== null) {
  //       createChatRoom({
  //         token: token,
  //         student_id: student_id,
  //         teacher_id: user_id}); 
  //         console.log("user_id: ", user_id);
  //         console.log("student_id: ", student_id);
  //     }
  //   });
  // }, []);

    return (
      <>
        <div className="msg_sec_new">
          <div className="msg_sec_new_body_inner">
          {(!isMobile || showLeftPanel) && (
            <div className="msg_sec_new_body_inner_left">
              <div className="msg_left_search">
                <i className="fas fa-search"></i>
                <input
                  type="search"
                  value={search}
                  onChange={serachhandleInputChange}
                  placeholder="Search here"
                />
              </div>
              <div className="msg_sec_new__left_body">
                {Array.isArray(getEnrollStudent) ? (
                  getEnrollStudent
                    .filter((studentdts) => {
                      // Filter by search
                      let searchMatch = true;
                      if (search) {
                        searchMatch = studentdts?.name
                          ?.toLowerCase()
                          .includes(search?.toLowerCase());
                      }
  
                      return searchMatch;
                    })
                    .map((studentdts, index) => (
                      <div
                        className="msg_sec_new__left_single active"
                        onClick={() =>
                          chatopenfn(
                            studentdts.user,
                            studentdts.name,
                            studentdts.student_profile_pic
                          )
                        }
                      >
                        <Link className="msg_sec_new__left_single_inner">
                          <img src={studentdts.student_profile_pic} alt="" />
                          <div className="msg_data_sec">
                            <h6>{studentdts.name}</h6>
                            {/* <p>On going class</p> */}
                          </div>
                          {/* <span>11:11</span> */}
                        </Link>
                      </div>
                    ))
                ) : (
                  <p>No chat found.</p> // Or any other fallback UI
                )}
              </div>
            </div>
          )}
            <div className="msg_sec_new_body_inner_right">
              {getstatuspopup ? (
                <>
                  <div className="right_chat_sec_new">
                    <div className="right_chat_sec-profile">
                      <i class="fa-solid fa-chevron-left" onClick={handleBackButtonClick}></i>
                      <div className="right_chat_sec_new_head">
                        <img src={getstudentimage} alt="" />
                        <span>
                          <h6>{getstudentname}</h6>
                          <p>Online</p>
                        </span>
                      </div>
                      {/* <div>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={isSwitchOn}
                                onChange={handleSwitchToggle}
                              />
                              <span className="slider round"></span>
                            </label>
                      </div> */}
                    </div>
                    <div className="right_chat_sec_new_body_sec">
                      {getchathistory.length > 0 ? (
                        getchathistory.map((chatdtls, index) => (
                          <>
                            {chatdtls.query && ( // Only render if query is not null and not an empty string
                              <div key={`left_${index}`} className="right_chat_sec_left">
                                <img src={getstudentimage} alt="Chat" />
                                <span>{chatdtls.query}</span>
                              </div>
                            )}
                            <div
                              key={`right_${index}`}
                              className="right_chat_sec_right"
                            >
                              <span>{chatdtls.answer}</span>
                              <img src={teacherProfile} alt="Teach" />
                            </div>
                          </>
                        ))
                      ) : (
                        <p>No Messages found.</p> // Or any other fallback UI
                      )}
                    </div>
                        {/* <div class="input-group">
                            <input type="text" id="user-input" class="form-control" placeholder="Type your message..."
                            value={message} onChange={handleMessageChange}/>
                            <button type="submit" class="send-button" onClick={handlechatbotSubmit}>Send</button>
                        </div> */}
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  export default Message;


