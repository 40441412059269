import React from "react";

function PaymentInputFeilds({cardNumber, placeholder}) {

  return <>
    <div className="payment-details-inner-input">
        <label>{cardNumber}</label>
        <input type="text" placeholder={placeholder}/>
    </div>
  </> 
}

export default PaymentInputFeilds;