import React, { useEffect, useState } from 'react';
import { FormControl, FormHelperText, Radio, TextField } from "@mui/material";
import "./PracticeAssignmentPopup.css";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    benefits_practice_assignments_image_1,
    benefits_practice_assignments_image_2,
    benefits_practice_assignments_image_3,
    benefits_practice_assignments_image_4,
    benefits_practice_assignments_image_5,
    benefits_practice_assignments_image_6
} from "../../../../../../../assets/images/images";
import { useDispatch, useSelector } from 'react-redux';
import {generatePracticeAssignment} from '../../../../../../../state/actions/generatePracticeAssignmentAction'
import { AssignmentType } from "../../../../../../../utils/consts";
import { useAuth } from '../../../../../../../features/auth/AuthContext';

const getAssignmentTypeName = (type) => {
    return Object.values(AssignmentType).find((item) => {
      return item.value === type;
    })?.name;
  };


const assignmentTypes = [
    'Multiple Choice Questions',
    'True/False',
    'Fill in the Blanks',
    'Short Answer Questions',
    'Essay Questions',
    'Mixed',
    'Other',
];

// const difficultyLevels = [
//     'Easy',
//     'Moderate',
//     'Difficult',
// ];

const difficultyLevels = [
    { label: 'Easy', value: 1 },
    { label: 'Moderate', value: 2 },
    { label: 'Difficult', value: 3 },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function PracticeAssignmentPopup({assignment, onClose }) {

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const course = location?.state?.course;
    const lesson = location?.state?.lesson;
    const { token } = useAuth();
    const lessonName = location?.state?.lesson.name;
    const courseName = location?.state?.course.name;
    const courseId = location?.state?.course.course_id;
    const lessonId = location?.state?.lesson.lesson_id;
    // console.log("courseId", courseId);
    // console.log("lessonId", lessonId);
    const [personName, setPersonName] = React.useState([]);
    const [selectedAssignmentType, setSelectedAssignmentType] = useState('');
    const [selectedDifficultyLevel, setSelectedDifficultyLevel] = useState('');
    const generatepracticeassignment = useSelector((state) => state.generatePracticeAssignment);

    const handleAssignmentTypeChange = (event) => {
        setSelectedAssignmentType(event.target.value);
        console.log("Assignment Type", event.target.value);
    };

    const handleDifficultyLevelChange = (event) => {
        setSelectedDifficultyLevel(event.target.value);
        console.log("Difficulty Level", event.target.value);
    };

    const [formErrorAssignmentState, setFormErrorAssignmentState] = useState({});

    const handleProceedToPay = () => {
        const payload = {
            assignment_id: assignment?.assignment_id,
            price: 1, // Assuming price is fixed for demonstration
            type: selectedAssignmentType,
            level: selectedDifficultyLevel,
            name: assignment?.name,
            // submission_id: assignment?.submission?.submission_id,
        };

        dispatch(generatePracticeAssignment(payload, token));
    };

    const isButtonDisabled = !selectedAssignmentType || !selectedDifficultyLevel;

    useEffect(() => {
        if (generatepracticeassignment.order_id) {

            const assignJson = {
                practice_id: generatepracticeassignment.practice_assignment_id,
                submission_id: assignment?.submission?.submission_id,
                level: selectedDifficultyLevel,
                type: selectedAssignmentType,
            }

            navigate('/practice-assignment-payment', {
                state: {
                    courseId: courseId,
                    lessonId: lessonId,
                    course: course,
                    lesson: lesson,
                    pracAssignJson: assignJson,
                }
            });
        }
    }, [generatepracticeassignment])

    return (
        <>
            <div className="popup-wrapper">
                <div className="popup-container">
                    <div className="practice-assignment-popup">
                        <h2>Practice Assignment : <span>{assignment?.name}</span></h2>
                        <p><span>Lesson:</span> {lessonName}</p>
                        <p><span>Course:</span> {courseName}</p>
                        <div className="practice-assignment-input-folder">
                            <div className="practice-assignment-inner-input">
                                <label>Assignment type</label>
                                <FormControl margin="none">
                                    <Select
                                        // name="type"
                                        // placeholder="Select Assignment type"
                                        // variant="outlined"
                                        // select
                                        // size="small"
                                        value={selectedAssignmentType}
                                        onChange={handleAssignmentTypeChange}
                                        displayEmpty
                                        // renderValue={(selected) => Object.values(AssignmentType).find(type => type.value === selected)?.name}
                                        renderValue={(selected) => {
                                            if (!selected) {
                                                return <p className='select-assignment-type'>Select assignment type</p>;
                                            }
                                            return Object.values(AssignmentType).find(type => type.value === selected)?.name;
                                        }}
                                        MenuProps={MenuProps}
                                        sx={{
                                            height: 42,
                                            "& .MuiInputBase-input": {
                                                height: "30px",
                                                padding: "6px 14px",
                                            },
                                            "& .MuiOutlinedInput-root": {
                                                borderRadius: "4px",
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                fontFamily: "inherit",
                                                background: "rgba(248, 248, 248, 1)",
                                            },
                                        }}
                                    >
                                        {Object.values(AssignmentType).map((type) => (
                                            <MenuItem key={type.name} value={type.value}>
                                                {/* {type.name} */}
                                                <ListItemText primary={type.name} />
                                                <Radio checked={selectedAssignmentType === type.value} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                        {/* <FormHelperText sx={{ color: "#d31332" }}>
                                        {formErrorAssignmentState.type}
                                      </FormHelperText> */}
                                    </FormControl>
                            </div>

                            <div className="practice-assignment-inner-input">
                                <label>Difficulty Level</label>                              
                                <FormControl>
                                <Select
                                    value={selectedDifficultyLevel}
                                    onChange={handleDifficultyLevelChange}
                                    displayEmpty
                                    // renderValue={(selected) => difficultyLevels.find(level => level.value === selected)?.label}
                                    renderValue={(selected) => {
                                        if (!selected) {
                                            return <p className='select-difficulty-level'>Select difficulty level</p>;
                                        }
                                        return difficultyLevels.find(level => level.value === selected)?.label;
                                    }}
                                    MenuProps={MenuProps}
                                    sx={{
                                        height: 42,
                                        "& .MuiInputBase-input": {
                                            height: "30px",
                                            padding: "6px 14px",
                                        },
                                        "& .MuiOutlinedInput-root": {
                                            borderRadius: "4px",
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            fontFamily: "inherit",
                                            background: "rgba(248, 248, 248, 1)",
                                        },
                                    }}
                                >
                                    {difficultyLevels.map((level) => (
                                        <MenuItem key={level.value} value={level.value}>
                                            <ListItemText primary={level.label} />
                                            <Radio checked={selectedDifficultyLevel === level.value} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            </div>
                            {/* <div className="practice-assignment-inner-input">
                                <label>Number of Assignment</label>
                                <FormControl margin="none">
                                    <div className="time-field">
                                        <button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                decrementAssignment();
                                            }}
                                        >
                                            -
                                        </button>
                                        <span>{assignmentCount}</span>
                                        <button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                incrementAssignment();
                                            }}
                                        >
                                            +
                                        </button>
                                    </div>
                                    <FormHelperText sx={{ color: "#d31332" }}>
                                        {formErrorAssignmentState.duration_minutes}
                                    </FormHelperText>
                                </FormControl>
                            </div> */}
                        </div>
                        <div className='benefits-practice-assignments-feature-container'>
                            <h2 className='benefits-practice-assignments-feature-title'>Benefits of the "Practice Assignments" Feature:</h2>
                            <div className='benefits-practice-assignments-feature-folder'>
                                <div className='benefits-practice-assignments-inner-feature-folder'>
                                    <div className='benefits-practice-assignments-image'>
                                        <img src={benefits_practice_assignments_image_1}/>
                                    </div>
                                    <h2>Personalized Learning: <span>Tailor assignments based on past performance.</span></h2>
                                </div>
                                <div className='benefits-practice-assignments-inner-feature-folder'>
                                    <div className='benefits-practice-assignments-image'>
                                        <img src={benefits_practice_assignments_image_2}/>
                                    </div>
                                    <h2>Skill Improvement: <span>Focus on areas needing more practice.</span></h2>
                                </div>
                                <div className='benefits-practice-assignments-inner-feature-folder'>
                                    <div className='benefits-practice-assignments-image'>
                                        <img src={benefits_practice_assignments_image_3}/>
                                    </div>
                                    <h2>Flexible Difficulty Levels: <span>Choose difficulty to match your skills</span></h2>
                                </div>
                                <div className='benefits-practice-assignments-inner-feature-folder'>
                                    <div className='benefits-practice-assignments-image'>
                                        <img src={benefits_practice_assignments_image_4}/>
                                    </div>
                                    <h2>Self-Paced Progress: <span>Complete assignments at your own pace.</span></h2>
                                </div>
                                <div className='benefits-practice-assignments-inner-feature-folder'>
                                    <div className='benefits-practice-assignments-image'>
                                        <img src={benefits_practice_assignments_image_5}/>
                                    </div>
                                    <h2>Enhanced Subject Mastery: <span>Target specific subjects for better mastery</span></h2>
                                </div>
                                <div className='benefits-practice-assignments-inner-feature-folder'>
                                    <div className='benefits-practice-assignments-image'>
                                        <img src={benefits_practice_assignments_image_6}/>
                                    </div>
                                    <h2>Performance Tracking: <span>Monitor progress and improvement over time</span></h2>
                                </div>
                            </div>
                        </div>
                        {/* <button type="button" className="practice-assignment-button" onClick={() => navigate(`/practice-assignment-payment`)}>Proceed to Pay</button> */}
                        <button
                        type="button"
                        className="practice-assignment-button"
                        onClick={handleProceedToPay} 
                        disabled={isButtonDisabled} 
                        style={{
                                backgroundColor: isButtonDisabled ? 'gray' : '#0068FF'
                            }}>
                        Proceed to Pay
                        </button>
                    </div>
                    <span className="practice-assignment-close" onClick={onClose}><i class="fa-solid fa-x"></i></span>
                </div>
            </div>
        </>
    )
}

export default PracticeAssignmentPopup;