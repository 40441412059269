import React from "react";
import StripeCheckout from "./stripePayment";

const Checkout = () => {

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <StripeCheckout/>
          </div>
        </div>
      </div>  
    </>
  );
};

export default Checkout;