import { useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import api, { wsURL } from "../../../../../../api/api";
import { getUserId } from "../../../../../../api/get_user_id";
import { useAuth } from "../../../../../../features/auth/AuthContext";

function MyTeacher({ profileFirstName }) {
  const [getEnrollTeacher, setGetEnrollTeacher] = useState({});
  const [userId, setUserId] = useState();
  const [getstatuspopup, setstatuspopup] = useState(false);
  const [getstudentname, setstudentname] = useState("");
  const [getstudentimage, setstudentimage] = useState("");
  const [getchathistory, setchathistory] = useState([]);
  const { token } = useAuth();
  const globalState = useSelector((state) => state.globalState);
  const studentProfile =
    globalState?.student_details?.data?.student_profile_pic;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [showLeftPanel, setShowLeftPanel] = useState(true); // New state for controlling visibility
  const [message, setMessage] = useState("");  // For handling input text
  const [loading, setLoading] = useState(true);
  const [roomId, setRoomId] = useState();
  const [query, setQuery] = useState("");
  const [getSocket, setSocket] = useState(null);
  const messagesEndRef = useRef(null);

  
  // const [chatHist, setChatHist] = useState(
  //   getchathistory !== null && getchathistory?.length > 0
  //     ? getchathistory
  //     : [
  //         {
  //           query: "",
  //           answer: "",
  //             // ai_welcome_msg !== undefined
  //             //   ? ai_welcome_msg
  //             //   : "Hi, I am your Ai Assistant, How may I help you?",
  //         },
  //       ]
  // );



  // const newWebSocketURL = `${wsURL}ws/course_chat/${roomId}/`;
  // const newWebSocketURL = `${wsURL}ws/course_chat/${roomId}/?token=${token}`;

  console.log("roomId", roomId);
  const payload = {
    student_user_id: userId,
    course_id: "",
    current_lesson: "",
    lesson_id: "",
    assignment_id: "",
    query: query,
    switch_to : "AI"
  };

  useEffect(() => {
    api
      .get("/students/get-teacher-chat-list/", {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          if (response?.data) {
            setGetEnrollTeacher(response?.data?.data);
          }
        }
      })
      .catch((error) => {});

    getUserId(token).then((user_id) => {
      if (user_id !== null) {
        setUserId(user_id);
      }
    });
  }, [token]);


  const [getTecherID, setTeacherId] = useState('');
  const chatopenfn = async (teacher_user_id, studentname, student_image,teacherId) => {
    setTeacherId(teacherId);
    setstudentname(studentname);
    setstudentimage(student_image);
    const datadtl = {
      student_user_id: userId,
      teacher_user_id: teacher_user_id,
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };

    console.log("teacherId, ", teacherId);
    console.log("userId", userId);
    console.log("teacher_user_id", teacher_user_id);

    const url_chatbot = "/ai/api/student_chat_history/";
    api
      .post(url_chatbot, datadtl, headers)
      .then((response) => {
        setchathistory(response?.data?.data);
        setLoading(false);
        setstatuspopup(true);
        // setIsMobileView(false);
        if (isMobile) {
          setShowLeftPanel(false); // Hide left panel on mobile when chat is opened
        }
      })
      .catch((error) => {});

      const payload = {
        user_id: userId,
        against_user_id: teacher_user_id,
      };
      // console.log("teacher_user_id", teacher_user_id);
  

      try {
        const response = await api.post(
          `ai/api/create_chat_room/`,
          payload,
          headers
        );
        if (response.status === 200 || response.status === 201) {
          setRoomId(response?.data?.room_id);
          console.log("response?.data?.room_id", response?.data?.room_id);
          initializeWebSocket(response.data.room_id);
          return response?.data?.room_id;
        } else {
          return null;
        }
      } catch (error) {
        console.log(error);
        return null;
      }
  };

  const handlechatbotSubmit = async (e) => {
    e.preventDefault();  // Add this to prevent any default form submission behavior
  
    // This is if you want to send a response immediately upon submitting the form.
    // if (message.trim() !== "") {
      // const newChatHistory = [...chatHist, { query: "", answer: message }];
      // setchathistory(newChatHistory);
      const newChatHistory = getchathistory.map((chat)=>{
        return {
          query: Array(chat.query)
          ?.map((item) => item)
          .join(""),
          answer: Array(chat.answer)
          ?.map((item) => item)
          .join(""),
        }
      })
  
      try {
        if (getSocket) {
          const newPayload = {
            ...payload,
            query: message, 
            messages: newChatHistory ?? [], // Include the full chat history
            school_name: "Proschool" ?? "Proschool",
          };
          console.log("message:", message);
          const payloadJSON = JSON.stringify(newPayload);
          setchathistory((prevChatHist) => {
            if (Array.isArray(prevChatHist)) {
              return [...prevChatHist, {query: newPayload.query , answer: ""}];
            } else {
              return [{query: newPayload.query , answer:""}]
            }
          })
          console.log("newPayload.query:", newPayload.query);
          console.log("newPayload.answer:", newPayload.answer);
          getSocket.send(payloadJSON);  // Sending the payload to the WebSocket server
        }
      } catch (error) {
        console.error("Error sending message through WebSocket:", error);
      }
  
      setMessage(""); // Clear the message input after sending
    // }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  let combinedMessage = [];

   // Function to initialize WebSocket
   const initializeWebSocket = (roomId) => {
    const socketUrl = `${wsURL}ws/course_chat/${roomId}/?token=${token}`;
            console.log("roomId: ", roomId);

    const socket = new WebSocket(socketUrl);
    setSocket(socket);

    socket.onopen = () => {
      console.log("WebSocket connection opened");
    };

    socket.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);

        if (data && data.message) {
          const message = data.message;

          combinedMessage.push(message);
          let status = false;
          let chatdatares = combinedMessage.join("");

          if (
            chatdatares.includes("<STARTOFTURN>") ||
            chatdatares.includes("<ENDOFTURN>")
          ) {
            chatdatares = chatdatares
              .replace(/<STARTOFTURN>/g, "")
              .replace(/<ENDOFTURN>/g, "");

            if (combinedMessage.join("").includes("<ENDOFTURN>")) {
              status = true;
              combinedMessage.length = 0;
              combinedMessage.push(chatdatares);
            }
          }
          // setchathistory(prev => [...prev, { query: data.query, answer: data.answer }]);
          setchathistory((prevChatHist) => {
            const updatedChatHist = Array.isArray(prevChatHist)
              ? [...prevChatHist]
              : [];
            if (updatedChatHist.length > 0) {
              const lastQuery = updatedChatHist[updatedChatHist.length - 1];
              if (lastQuery) {
                lastQuery.answer = chatdatares;
              }
            }
            if (status) {
              combinedMessage.length = 0;
            }
            return updatedChatHist;
          });
          scrollToBottom();
        } else {
        }
      } catch (error) {}
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    socket.onclose = (event) => {
      console.log("WebSocket connection closed:", event);
    };

    messagesEndRef.current?.scrollIntoView({
      inline: "center",
      behavior: "smooth",
    });

    return () => {
      if (socket.readyState === 1) {
        // <-- This is important
        socket.close();
      }
    };
  };

  const [search, setSearch] = useState("");
  const serachhandleInputChange = (event) => {
    const { value } = event.target;
    setSearch(value);
  };

  const handleBackButtonClick = () => {
    setstatuspopup(false); // Hide chat section
    setShowLeftPanel(true); // Show left panel, especially for mobile
  };

   // Function to handle message typing
   const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };


  return (
    <>
      <section className="msg_sec_new_inner_teacher">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="msg_sec_new_body_inner">
                {(!isMobile || showLeftPanel) && (
                  <div className="msg_sec_new_body_inner_left">
                    <div className="msg_left_search">
                      <i className="fas fa-search"></i>
                      <input
                        type="search"
                        value={search}
                        onChange={serachhandleInputChange}
                        placeholder="Search here"
                      />
                    </div>
                    <div className="msg_sec_new__left_body">
                      {Array.isArray(getEnrollTeacher) ? (
                        getEnrollTeacher
                          .filter((teacherdtls) => {
                            // Filter by search
                            let searchMatch = true;
                            if (search) {
                              searchMatch = teacherdtls?.name
                                ?.toLowerCase()
                                .includes(search?.toLowerCase());
                            }

                            return searchMatch;
                          })
                          .map((teacherdtls, index) => (
                            <div
                              className="msg_sec_new__left_single active"
                              onClick={() =>
                                chatopenfn(
                                  teacherdtls.user,
                                  teacherdtls.name,
                                  teacherdtls.ai_avatar,
                                  teacherdtls.teacher_id
                                )
                              }
                            >
                              <Link className="msg_sec_new__left_single_inner">
                                <img src={teacherdtls.ai_avatar} alt="" />
                                <div className="msg_data_sec">
                                  <h6>{teacherdtls.name}</h6>
                                  {/* <p>On going class</p> */}
                                </div>
                              </Link>
                            </div>
                          ))
                      ) : (
                        <p>No Teacher found.</p> // Or any other fallback UI
                      )}
                    </div>
                  </div>
                )}

                <div className="msg_sec_new_body_inner_right">
                  {getstatuspopup ? (
                    <>
                      <div className="right_chat_sec_new">
                        <div className="right_chat_sec-profile">
                          <i class="fa-solid fa-chevron-left" onClick={handleBackButtonClick}></i>
                          <NavLink to={`/teacher/${getTecherID}`}>
                            <div className="right_chat_sec_new_head">
                              <img src={getstudentimage} alt="" />
                              <span>
                                <h6>{getstudentname}</h6>
                                {/* <p>Online</p> */}
                              </span>
                            </div>
                          </NavLink>
                        </div>
                        <div className="right_chat_sec_new_body_sec">
                          {getchathistory.length > 0 ? (
                            getchathistory.map((chatdtls, index) => (
                              <>
                                <div
                                  key={`right_${index}`}
                                  className="right_chat_sec_right"
                                >
                                  <span>{chatdtls.query}</span>
                                  <img src={studentProfile} alt="Teach" />
                                </div>
                                {chatdtls.answer && (
                                  <div key={`left_${index}`} className="right_chat_sec_left">
                                    <img src={getstudentimage} alt="Chat" />
                                    <span>{chatdtls.answer}</span>
                                  </div>
                                )}
                              </>
                            ))
                          ) : (
                            <p>No Chat Found.</p> // Or any other fallback UI
                          )}
                        </div>
                        {/* <div class="input-group">
                            <input type="text" id="user-input" class="form-control" placeholder="Type your message..."
                            value={message} onChange={handleMessageChange}/>
                            <button type="submit" class="send-button" onClick={handlechatbotSubmit}>Send</button>
                        </div> */}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {profileFirstName}
             {/* {!loading ? (
             <CustomChatbot
                chatHistory={getchathistory}
                socketUrl={newWebSocketURL}
                socketPayload={payload}
                school_name={"ProSchool"}
              />
            ) : (
              <></>
            )} */}
    </>
  );
}

export default MyTeacher;