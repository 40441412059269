import { Grid, Switch } from "@mui/material";
import { allCountries } from "country-telephone-data";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import Datetime from "react-datetime";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import api from "../../../api/api";
import { wsURL } from "../../../api/api";
import {
  calender_image,
  dashboard_popup_teacher_profile_details_icon_plus_icon,
  email_image,
  gender_image,
  grade_image,
  language_image,
  location_image,
  profile_image,
  upload_image,
  devicon_linkedin
} from "../../../assets/images/images";
import imgs from "../../../assets/images/imgs";
import { useAuth } from "../../../features/auth/AuthContext";
import { updateGlobalState } from "../../../state/global/slice";
import { genders, titles } from "../../../utils/consts";
import {
  getIdsForLanguages,
  getKeyByValue,
  getKeyByDictionary,
} from "../../../utils/utlis";
import {
  isMaxChar,
  isRequired,
  isValidMobileNumber,
} from "../../../utils/validator";
import CropProfileImageModal from "../../../pages/home/components/dashboard/CropProfileImageModal";
import Autocomplete from "react-google-autocomplete";
import { GOOGLE_MAP_KEY } from "../../../consts/constants";
import EditSubject from "../../../components/EditSubject.js/EditSubject";
import { compressImage } from "../../../utils/CompressImage";
import { useParams } from "react-router-dom";
import Helper from "../../../utils/Helper";
import ViewMoreComponent from "../../ViewMoreStudentAssistmentComponent/ViewMoreComponent";

function TeacherProfile() {
  const { token } = useAuth();
  const { teacher_id } = useParams();
  const globalState = useSelector((state) => state.globalState);
  const isTeacher = true;

  const updateState = (key, value) => {
    dispatch(updateGlobalState({ key: key, value: value }));
  };
  const dispatch = useDispatch();
  const [imageUploaded, setImageUploaded] = useState(false);
  const [uplodedImage, setUplodedImage] = useState({})
  const today = new Date().toISOString().split("T")[0];
  const [isEditable, setIsEditable] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const getFormattedDate = (isoDate) => {
    const dateObj = new Date(isoDate);
    const options = { year: "numeric", month: "short", day: "numeric" };
    return dateObj.toLocaleDateString("en-US", options); // Converts to a format like "Oct 18, 2023"
  };
  const ai_avatar = isTeacher ?
    globalState.teacher_details?.data?.ai_avatar :
    globalState.student_details?.data?.student_profile_pic;
  const [AddtoLanguages, setAddtoLanguages] = useState([]);
  // const subjects = globalState.teacher_details?.data?.subjects;
  // const interests = globalState.student_details?.data?.interests;
  const [gradedetails, setGradedetails] = useState([]);
  const subjectsKey = isTeacher ? "subject_names" : "interest_names";

  const [formState, setFormState] = useState({
    name: isTeacher
      ? globalState.teacher_details?.data?.name
      : globalState.student_details?.data?.name,
    title: isTeacher
      ? Object.keys(globalState.teacher_details?.data?.title)[0]
      : globalState.student_details?.data?.title,
    grade: isTeacher
      ? null
      : Object.keys(globalState.student_details?.data?.grade)[0],
    introduction: isTeacher
      ? globalState.teacher_details?.data?.introduction
      : globalState.student_details?.data?.introduction,
    email: isTeacher ?
      globalState.teacher_details?.data?.email :
      globalState.student_details?.data?.email,
    [subjectsKey]: isTeacher
      ? Object.values(globalState.teacher_details?.data?.subjects)
      : Object.values(globalState.student_details?.data?.interest),
    // phone:
    //   globalState.teacher_details?.data?.phone ??
    //   globalState.student_details?.data?.phone,
    dob:
      globalState.teacher_details?.data?.dob ??
      globalState.student_details?.data?.dob,
    gender: Object.keys(
      isTeacher
        ? globalState.teacher_details?.data?.gender
        : globalState.student_details?.data?.gender
    )[0],
    language_ids:
      isTeacher && Object.values(globalState.datalist?.languages)
        ? getIdsForLanguages(
          Object.values(globalState.teacher_details?.data?.language),
          globalState.datalist?.languages
        )
        : null,
    language: !isTeacher
      ? Object.values(globalState.student_details?.data?.language)?.join(",")
      : null,
    location:
      globalState.teacher_details?.data?.location ??
      globalState.student_details?.data?.location,
    linkedin_url: isTeacher ?
      globalState.teacher_details?.data?.linkedin_url :
      null,
  });

  useEffect(() => {
    setFormState({
      name: isTeacher
        ? globalState.teacher_details?.data?.name
        : globalState.student_details?.data?.name,
      title: isTeacher
        ? Object.keys(globalState.teacher_details?.data?.title)[0]
        : globalState.student_details?.data?.title,
      grade: isTeacher
        ? null
        : Object.keys(globalState.student_details?.data?.grade)[0],
      introduction: isTeacher
        ? globalState.teacher_details?.data?.introduction
        : globalState.student_details?.data?.introduction,
      email: isTeacher
        ? globalState.teacher_details?.data?.email
        : globalState.student_details?.data?.email,
      [subjectsKey]: isTeacher
        ? Object.values(globalState.teacher_details?.data?.subjects)
        : Object.values(globalState.student_details?.data?.interest),
      dob: globalState.teacher_details?.data?.dob ?? globalState.student_details?.data?.dob,
      gender: Object.keys(
        isTeacher
          ? globalState.teacher_details?.data?.gender
          : globalState.student_details?.data?.gender
      )[0],
      language_ids: isTeacher && globalState.datalist?.languages
        ? getIdsForLanguages(
          Object.values(globalState.teacher_details?.data?.language),
          globalState.datalist?.languages
        )
        : null,
      language: !isTeacher
        ? Object.values(globalState.student_details?.data?.language)?.join(",")
        : null,
      location: globalState.teacher_details?.data?.location ?? globalState.student_details?.data?.location,
      linkedin_url: isTeacher ? globalState.teacher_details?.data?.linkedin_url : null,
    });
  }, [globalState, isTeacher]);

  const [formErrors, setFormErrors] = useState({
    name: "",
    grade: "",
    introduction: "",
    lastname: "",
    email: "",
    // phone: "",
    dob: "",
    gender: "",
    location: "",
    language: "",
    title: "",
  });

  const [selectedCountry, setSelectedCountry] = useState({
    name: "United States",
    iso2: "us",
    dialCode: globalState.teacher_details?.data?.country_code.replace(
      /\+/g,
      ""
    ),
    format: "+...-...-....",
    hasAreaCodes: false,
  });

  const handleCountryChange = (e) => {
    const country = allCountries.find((c) => c.iso2 === e.target.value);
    setSelectedCountry(country);

    setFormErrors({
      ...formErrors,
      country_code: isValidMobileNumber(
        formState.phone,
        selectedCountry.dialCode
      ),
    });
    setFormState({
      ...formState,
      country_code: `+${country.dialCode}`,
    });
  };
  function isValidDateTeacher(val) {
    let today = new Date();
    let twentyOneYearsAgo = new Date();
    twentyOneYearsAgo.setFullYear(today.getFullYear() - 21);
    twentyOneYearsAgo.setHours(0, 0, 0, 0); // Set time to the start of the day

    let selectedDate = new Date(val);
    selectedDate.setHours(0, 0, 0, 0); // Set time to the start of the day

    // Check if the selected date is after today or if it is less than 21 years ago
    if (selectedDate > today || selectedDate > twentyOneYearsAgo) {
      return false;
    }
    return true;
  }

  function isValidDateStudent(val) {
    let today = new Date();
    let twentyOneYearsAgo = new Date();
    twentyOneYearsAgo.setFullYear(today.getFullYear() - 4);
    twentyOneYearsAgo.setHours(0, 0, 0, 0); // Set time to the start of the day

    let selectedDate = new Date(val);
    selectedDate.setHours(0, 0, 0, 0); // Set time to the start of the day

    // Check if the selected date is after today or if it is less than 21 years ago
    if (selectedDate > today || selectedDate > twentyOneYearsAgo) {
      return false;
    }
    return true;
  }

  const handleDateChanged = (value) => {
    let error = "";
    error = isRequired(value);
    setFormErrors({
      ...formErrors,
      dob: error,
    });
    setFormState((prev) => ({
      ...prev,
      dob: format(new Date(value), "yyyy-MM-dd"),
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    let error = "";
    if (name === "name") {
      error = isRequired(value);
    } else if (name === "title") {
      error = value === "" || value === "0" ? "Please select title" : "";
    } else if (name === "introduction") {
      error = isRequired(value) || isMaxChar(value);
    } else if (name === "lastname") {
      error = isRequired(value);
    } else if (name === "grade") {
      error = isRequired(value);
    } else if (name === "email") {
      error = isRequired(value);
    }
    // else if (name === "phone") {
    //   error = isRequired(value);
    //   isValidMobileNumber(value, selectedCountry.dialCode);
    // }
    else if (name === "dob") {
      error = isRequired(value);
    } else if (name === "gender") {
      error = value === "" || value === "0" ? "Please select gender" : "";
    } else if (name === "location") {
      error = isRequired(value);
    } else if (name === "language") {
      error = isRequired(value);
    } else if (name === "country_code") {
      error = isValidMobileNumber(value, selectedCountry.dialCode);
    }
    setFormErrors({
      ...formErrors,
      [name]: error,
    });

    setFormState((prev) => ({ ...prev, [name]: value }));
  };

  const getUpdatedFields = () => {
    const updatedFields = Object.entries(formState).reduce(
      (result, [key, value]) => {
        const teacherValue = globalState.teacher_details?.data[key];
        const studentValue = globalState.student_details?.data[key];

        // Check if the value is not null and different from teacherValue and studentValue
        if (
          value !== teacherValue &&
          value !== studentValue &&
          value !== null
        ) {
          result[key] = value;
        }

        return result;
      },
      {}
    );

    return updatedFields;
  };
  const handleForm4Submit = async (e) => {
    await submitForm();
  };
  const submitForm = async () => {
    toast.dismiss();
    const updatedFormState = {
      ...getUpdatedFields(),
      [subjectsKey]: formState[subjectsKey]?.join(","),
    };
    if (isTeacher) {
      try {
        const response = await api.put(
          `auth/update-teacher/?teacher_id=${teacher_id}`,
          updatedFormState,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Token ${token}`,
            },
          }
        );

        if (response?.data?.status === 200) {
          updateState("teacher_details", response?.data ?? {});
          setIsEditable(false);

          toast.success(
            response?.data?.message ||
            "Teacher profile has been updated successfully.",
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
        } else {
          setIsEditable(false);
          toast.error(response?.data?.message || "Something went wrong!", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      } catch (error) {
        setIsEditable(false);
        toast.error(
          error?.response?.data?.message ||
          error?.response?.data?.error ||
          "Something went wrong!",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      }
    } else {
      const newFormState = {
        ...updatedFormState,
        language: parseInt(getKeyByValue(AddtoLanguages, formState.language)),
      };
      try {
        const response = await api.put(
          "students/update-student/",
          newFormState,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Token ${token}`,
            },
          }
        );

        if (response?.data?.status === 200) {
          updateState("student_details", response?.data ?? {});
          setIsEditable(false);

          toast.success(
            response?.data?.message ||
            "Student profile has been updated successfully.",
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
        } else {
          setIsEditable(false);
          toast.error(response?.data?.message || "Something went wrong!", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      } catch (error) {
        setIsEditable(false);
        toast.error(
          error?.response?.data?.message ||
          error?.response?.data?.error ||
          "Something went wrong!",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      }
    }
  };

  // const [hasSelectedPlace, setHasSelectedPlace] = useState(false);
  const [hasSelectedPlace, setHasSelectedPlace] = useState(globalState.teacher_details?.data?.location ?
    true : false);
  const handlePlaceSelect = (place) => {
    if (place && place.formatted_address) {
      setFormState((prev) => ({
        ...prev,
        location: place.formatted_address,
      }));
      setFormErrors({ location: "" });
      setHasSelectedPlace(true);
    } else {
      setFormErrors({ location: "Please enter a valid location" });
    }
  };

  const handleAutocompleteInputChange = (event) => {
    setFormState((prev) => ({
      ...prev,
      location: event.target.value,
    }));
    if (event.target.value === "" || !hasSelectedPlace) {
      setFormErrors({ location: "Please enter a valid location" });
    } else {
      setFormErrors({ location: "" });
    }
  };

  const handleInputBlur = () => {
    if (!hasSelectedPlace && formState.location) {
      setFormState((prev) => ({
        ...prev,
        location: "",
      }));
      setFormErrors({ location: "Please enter a valid location" });
    }
  };

  const addLanguage = (selectedLanguage) => {
    let error = "";
    error = isRequired(selectedLanguage);
    setFormErrors({
      ...formErrors,
      language_ids: error,
    });
    // add  language to formState.language string separated by comma
    setFormState((prev) => ({
      ...prev,
      language_ids: prev.language_ids
        ? prev.language_ids + "," + selectedLanguage
        : selectedLanguage,
    }));
  };
  const removeLanguage = (selectedLanguage) => {
    let error = isRequired(selectedLanguage);
    setFormErrors({
      ...formErrors,
      language_ids: error,
    });

    // Remove the selected language from formState.language string separated by a comma
    setFormState((prev) => {
      const updatedLanguageIds = prev.language_ids
        ?.split(",")
        ?.filter((item) => item !== selectedLanguage)
        .join(",");
      return {
        ...prev,
        language_ids: updatedLanguageIds,
      };
    });
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isAddToSubjetModelOpen, setIsAddToSubjetModelOpen] = useState(false);
  const [src, setSrc] = useState(null);
  const handleFileInput = async (event) => {
    const errorKey = isTeacher ? "ai_avatar" : "student_profile_pic";
    const file = event.target.files[0];

    if (!file) {
      setSrc(null);
      return;
    }

    if (file.size > 31457280) {
      // Handle the case where the file size is too large
      toast.error("The file size exceeds the maximum limit of 1 MB");
      setFormErrors({
        ...formErrors,
        [errorKey]: "The file size exceeds the maximum limit of 1 MB",
      });
      setSrc(null);
      return; // Exit the function
    }

    const compressedImage = await compressImage(file);
    setSrc(URL.createObjectURL(compressedImage));
    onOpenModal();

    let error = "";
    error = isRequired(file);
    setFormErrors({
      ...formErrors,
      [errorKey]: error,
    });
    event.target.value = null;
  };

  const onOpenModal = () => {
    setModalIsOpen(true);
  };

  const onCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleSaveForm = () => {
    handleForm4Submit();
  };

  useEffect(() => {
    if (!isTeacher) {
      api
        .get("/courses/get-grade-list/", {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            //
            setGradedetails(response.data.grade);
            // setFormState((prev) => ({
            //   ...prev,
            //   grade: getKeyByDictionary(
            //     response.data.grade,
            //     globalState?.student_details?.data?.grade
            //   ),
            // }));
          }
        })
        .catch((error) => {
          //
        });
    }
    api
      .get("/auth/get-datalist/")
      .then((response) => {
        setAddtoLanguages(response.data.languages);
      })
      .catch((error) => { });
  }, []);
  const lastGradeUpdate = new Date(
    globalState?.student_details?.data?.grade_last_update
  );
  const currentDate = new Date();

  // Calculate the difference in time
  const timeDiff = currentDate.getTime() - lastGradeUpdate.getTime();

  // Convert time difference to days
  const daysDiff = timeDiff / (1000 * 3600 * 24);

  // Determine if the select should be read-only
  const isReadOnly = daysDiff < 14;


  // const [isActive,setIsActive] 

  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const [openDialog, setOpenDialog] = useState(false);
  const [isActive, setIsActive] = useState(isTeacher
    ? globalState.teacher_details?.data?.active
    : globalState.student_details?.data?.active);
  const handleSwitchToggle = () => {
    setOpenDialog(true);
  }
  const handleActiveinactive = () => {
    setIsActive(!isActive);
    // console.log(isActive);
    const payload = {
      user_id: isTeacher
        ? globalState.teacher_details?.data?.user_id
        : globalState.student_details?.data?.user_id,
      active: isActive
    };

    api
      .post(`super-admin/school/active-deactive/`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`
        }
      })
      .then((response) => {
        if (response.status === 200) {
          setOpenDialog(false);
        }
      })
      .catch((error) => {
        // Handle error
      });


  };
  const cancelRegistration = () => {
    setOpenDialog(false);
    document.body.style.overflow = "auto";
    // Reset the selected role or handle cancellation
  };

  const [showMore1, setShowMore1] = useState(false);
  const [showMore2, setShowMore2] = useState(false);

  const handleViewMoreClick1 = () => {
    setShowMore1(!showMore1);
    // console.log("ai assessment 2");
  };

  const handleViewMoreClick2 = () => {
    setShowMore2(!showMore2);
  };

  const [aiAssessment, setaiAssessment] = useState(
    formState.ai_assessment_data ||
    globalState?.teacher_details?.data?.ai_assessment_data);
  const [isTeacherAssesmentRegenerate, setisTeacherAssesmentRegenerate] = useState(false);

  const [aiRecommendation, setaiRecommendation] = useState(
    formState.ai_recommendation_data ||
    globalState?.teacher_details?.data?.ai_recommendation_data);
  const [isTeacherRecommendationRegenerate, setisTeacherRecommendationRegenerate] = useState(false);

  const [assessmentRefresh, setassessmentRefresh] = useState(formState.ai_assessment_refresh ||
    globalState?.teacher_details?.data?.ai_assessment_refresh);
  const [recommendationRefresh, setrecommendationRefresh] = useState(formState.ai_recommendation_refresh ||
    globalState?.teacher_details?.data?.ai_recommendation_refresh);


  const onRegenerateTeacherAssesment = () => {
    setisTeacherAssesmentRegenerate(true);
    const newWebSocketURL = `${wsURL}ws/teacher-assessment-refresh/${globalState?.teacher_details?.data?.teacher_id}/?token=${token}`;
    let reconnectInterval = 1000; // Initial reconnect interval in ms
    const maxReconnectInterval = 30000; // Max reconnect interval
    let reconnectAttempts = 0; // Number of reconnect attempts
    const reconnectDecay = 1.5; // Rate of increase of the reconnect delay
    const maxReconnectAttempts = 3; // Maximum number of reconnect attempts

    if (isTeacher && globalState?.teacher_details?.data?.teacher_id) {
      const socket = new WebSocket(newWebSocketURL);
      // setWs(socket);

      socket.onopen = () => {
        console.log("WebSocket connected");
        reconnectAttempts = 0; // Reset reconnect attempts on successful connection
        reconnectInterval = 1000; // Reset reconnect interval
        socket.send(JSON.stringify({ teacher_id: globalState?.teacher_details?.data?.teacher_id }));
      };

      socket.onmessage = (event) => {
        console.log("WebSocket message received:", event.data);
        const data = JSON.parse(event.data);
        setaiAssessment(data.ai_assessment);
        setassessmentRefresh(false);
        setisTeacherAssesmentRegenerate(false);
        // const data = JSON.parse(event.data);
        // if (data.status === 200) {
        //   console.log("student ass: ", event.data)
        // }
      };

      socket.onclose = () => {
        console.log("WebSocket disconnected");
        if (reconnectAttempts < maxReconnectAttempts) {
          setTimeout(() => {
            reconnectAttempts++;
            socket.close(); // Close old sockets if not yet closed
            socket = new WebSocket(newWebSocketURL); // Create a new socket and attempt to connect
            reconnectInterval = Math.min(reconnectInterval * reconnectDecay, maxReconnectInterval);
          }, reconnectInterval);
        } else {
          console.log("Max reconnect attempts reached, will not attempt further reconnects.");
        }
      };

      socket.onerror = (error) => {
        setaiAssessment(formState.ai_assessment_data ||
          globalState?.teacher_details?.data?.ai_assessment_data);
        setisTeacherAssesmentRegenerate(false);
        console.error("WebSocket error:", error);
        socket.close();
      };

  // return () => {
  //   if (socket.readyState === 1) {
  //     socket.close();
  //   }
  // };
    }
  }

  const onRegenerateTeacherRecommendation = () => {
    setisTeacherRecommendationRegenerate(true);
    const newWebSocketURL = `${wsURL}ws/student-recommendation-refresh/${globalState?.teacher_details?.data?.teacher_id}/?token=${token}`;
    let reconnectInterval = 1000; // Initial reconnect interval in ms
    const maxReconnectInterval = 30000; // Max reconnect interval
    let reconnectAttempts = 0; // Number of reconnect attempts
    const reconnectDecay = 1.5; // Rate of increase of the reconnect delay
    const maxReconnectAttempts = 3; // Maximum number of reconnect attempts

    if (isTeacher && globalState?.teacher_details?.data?.teacher_id) {
      const socket = new WebSocket(newWebSocketURL);
      // setWs(socket);

      socket.onopen = () => {
        console.log("WebSocket connected");
        reconnectAttempts = 0; // Reset reconnect attempts on successful connection
        reconnectInterval = 1000; // Reset reconnect interval
        socket.send(JSON.stringify({ teacher_id: globalState?.teacher_details?.data?.teacher_id }));
      };

      socket.onmessage = (event) => {
        console.log("WebSocket message received:", event.data);
        const data = JSON.parse(event.data);
        setaiRecommendation(data.ai_recommendation);
        setrecommendationRefresh(false)
        setisTeacherRecommendationRegenerate(false);
        // const data = JSON.parse(event.data);
        // if (data.status === 200) {
        //   console.log("student ass: ", event.data)
        // }
      };

      socket.onclose = () => {
        console.log("WebSocket disconnected");
        if (reconnectAttempts < maxReconnectAttempts) {
          setTimeout(() => {
            reconnectAttempts++;
            socket.close(); // Close old sockets if not yet closed
            socket = new WebSocket(newWebSocketURL); // Create a new socket and attempt to connect
            reconnectInterval = Math.min(reconnectInterval * reconnectDecay, maxReconnectInterval);
          }, reconnectInterval);
        } else {
          console.log("Max reconnect attempts reached, will not attempt further reconnects.");
        }
      };

      socket.onerror = (error) => {
        setaiRecommendation(formState.ai_assessment_data ||
          globalState?.teacher_details?.data?.ai_assessment_data);
        setisTeacherRecommendationRegenerate(false);
        console.error("WebSocket error:", error);
        socket.close();
      };

      // return () => {
      //   if (socket.readyState === 1) {
      //     socket.close();
      //   }
      // };
    }
  }

  return (
    <div className="account_setting_tab_sec">
      <div className="account_setting_tab_sec_head">
        <h6>{isTeacher ? globalState.teacher_details?.data?.name : globalState.student_details?.data?.name} Details</h6>
        {/* <button className="edit_btn_set" onClick={() => setIsEditable(true)}>
          Active
          </button>
          <button className="edit_btn_set" onClick={() => setIsEditable(true)}>
          Inactive
        </button> */}
        <div className="Switch_tab_wrapper">
          {/* <Switch
              {...label}
              defaultChecked={isActive}
              onChange={handleSwitchToggle}
            /> */}
          {isEditable ? (
            <button
              className="save_btn_set"
              onClick={handleSaveForm}
              disabled={!!formErrors.introduction}
            >
              Save
            </button>
          ) : (
            <button className="edit_btn_set" onClick={() => setIsEditable(true)}>
              <i class="fa-solid fa-pen"></i> Edit
            </button>
          )}
        </div>
      </div>
      <div
        style={{
          pointerEvents: isEditable ? "auto" : "none",
        }}
      >
        <div className="about_teach_main_profile">
          <div className="about_teach_sec_new">
            <div className="about_teach_sec_new_left">
              <div className="Personalize-your-AI-Assistant-uplode-image">
                <img src={selectedImage ?? ai_avatar ?? upload_image} alt="" />
                <div
                  className="dashboard-popup-teacher-profile-details-image-plus-btn black-plus-icon"
                  onChange={() => { }}
                  style={{ cursor: "pointer" }}
                >
                  {isEditable && (
                    <span>
                      <i class="fa-solid fa-plus"></i>
                    </span>
                  )}
                  {/* <img
                    src={dashboard_popup_teacher_profile_details_icon_plus_icon}
                    alt=""
                  /> */}
                </div>
                <input
                  type="file"
                  id="choos-profile-image"
                  name="student_profile_pic"
                  accept="image/png, image/jpeg"
                  style={{
                    height: 106,
                    width: 106,
                  }}
                  onChange={handleFileInput}
                />
                {/* {isEditable && (
                  // <p style={{ fontSize: 10, textAlign: "center" }}>
                  //   Upload an image for your AI assistant
                  // </p>
                )} */}
              </div>
              <h5 style={{ textAlign: "center" }}>{formState.name}</h5>
              <h6 style={{ textAlign: "center", fontSize: "10px", margin: "6px 0px 0px 0px" }}>{isTeacher ? globalState.teacher_details?.data?.uid : globalState.student_details?.data?.uid}</h6>
              {/* <p className="teacher-Profile-joining-date" style={{ textAlign: "center", fontSize: "10px"}}>Joining Date: <span>{Helper.formatDateTime(
                                  globalState.teacher_details?.data?.uid
                                ).date}</span></p> */}
            </div>
            <div className="about_teach_sec_new_right">
              <h4> {isTeacher ? "About Teacher" : "About Student"}</h4>
              <textarea
                name="introduction"
                style={
                  formErrors.introduction ? { border: "1px solid red" } : null
                }
                value={formState.introduction || ""}
                onChange={handleInputChange}
                className="introduce-yourself-text"
                maxlength="1000"
                type="text"
                cols="20"
                rows="5"
                placeholder={`For Example:\n• Tell us about yourself.\n• What subjects do you teach?\n• What grades do you teach?\n• Do you teach at a school? If so, which one?`}
                inputprops={{
                  style: { color: "red" },
                }}
              ></textarea>
              <p className="errorState">{formErrors.introduction}</p>
            </div>
          </div>
          {isTeacher &&
            <div className="profile_linkedin"
              style={{
                pointerEvents: "auto"
              }}
            >
              <div className="profile_linkedin_image">
                <img src={devicon_linkedin} />
              </div>
              {isEditable ?
                <input
                  type="text"
                  placeholder="linkedin"
                  name="linkedin_url"
                  // style={
                  //   formErrors.email
                  //     ? { border: "1px solid red" }
                  //     : null
                  // }
                  value={formState.linkedin_url || ""}
                  onChange={handleInputChange}
                />
                : <a href={formState.linkedin_url} target="_blank">
                  {formState?.linkedin_url ?? "-"}</a>}
            </div>}
        </div>
        {isTeacher && (
          <div className="ai-student-assessment-container">
            {aiAssessment !== null && <ViewMoreComponent
              showMore={showMore1}
              handleViewMoreClick={handleViewMoreClick1}
              header_image={imgs.png.award}
              header_text="Teacher Assessment (AI)"
              aiAssessment={aiAssessment}
              regenerateFunc={onRegenerateTeacherAssesment}
              enableRegenerate={assessmentRefresh}
              regenerateButtonState={isTeacherAssesmentRegenerate}
              regenerateMessage={"Assessment can be generated once a week"}
            />}
            {/* {aiRecommendation !== null &&
              <ViewMoreComponent
                showMore={showMore2}
                handleViewMoreClick={handleViewMoreClick2}
              header_image={imgs.png.book_2}
              header_text="AI Recommendation"
                aiAssessment={aiRecommendation}
                regenerateFunc={onRegenerateTeacherRecommendation}
                enableRegenerate={recommendationRefresh}
                regenerateButtonState={isTeacherRecommendationRegenerate}
                regenerateMessage={"Recommendation can be generated once a week"}
              />} */}
          </div>
        )}
        <div className="subject_special">
          <Grid container xs={12} justifyContent={"space-between"}>
            <h5>Subjects</h5>
            {isEditable && (
              <button
                className="edit_btn_set"
                onClick={() => setIsAddToSubjetModelOpen(true)}
              >
                Add Subject
              </button>
            )}
          </Grid>
          <ul className="sub_special">
            {isTeacher
              ? formState.subject_names &&
              formState.subject_names?.map((subject) => (
                <li key={subject}>{subject}</li> // Use the subject as key if it's unique
              ))
              : formState.interest_names &&
              formState.interest_names?.map((subject) => (
                <li key={subject}>{subject}</li> // Use the subject as key if it's unique
              ))}
          </ul>
        </div>
        <div className="form_sec_settings">
          <div
            className="dashboard-popup-teacher-profile-details-form"
            style={{
              backgroundColor: "transparent",
              width: "60%",
              padding: "0px",
            }}
          >
            <div className="dashboard-popup-teacher-profile-details-form-container">
              <div className="dashboard-popup-teacher-profile-details-input">
                <label>Name</label>
                <input
                  id="teacher-create-profile-input"
                  type="name"
                  name="name"
                  style={formErrors.name ? { border: "1px solid red" } : null}
                  value={formState.name || ""}
                  placeholder="Full name"
                  onChange={handleInputChange}
                />
                <div className="teacher-create-popup-gender-input-folder">
                  <div className="form-icon">
                    <img src={profile_image} alt="" />
                  </div>
                  <div className="setect-genter-list-dropdown">
                    {isTeacher && (
                      <select
                        name="title"
                        className="teacher-create-popup-gender-input"
                        style={
                          formErrors.title ? { border: "1px solid red" } : null
                        }
                        value={formState.title || ""}
                        onChange={handleInputChange}
                      >
                        {titles.map((title) => {
                          return (
                            <option key={title.value} value={title.value}>
                              {title.name}
                            </option>
                          );
                        })}
                      </select>
                    )}
                    {/* <div className="list-dropdown-icon">
                      <span><i class="fa-solid fa-caret-down"></i></span>
                    </div> */}
                  </div>
                </div>
                <p>{formErrors.name}</p>
              </div>
              {!isTeacher && (
                <div className="dashboard-popup-teacher-profile-details-input">
                  <label>
                    Grade
                    {/* (Update grade after 14 days since last update) */}
                  </label>
                  <select
                    name="grade"
                    style={
                      formErrors.grade
                        ? {
                          border: "1px solid red",
                          borderRadius: 6,
                        }
                        : {
                          border: "1px solid #BDBDBD",
                          borderRadius: 6,
                        }
                    }
                    value={formState.grade || ""}
                    onChange={handleInputChange}
                    readOnly={isReadOnly}
                    disabled={isReadOnly}
                  >
                    <option value="">Select Grade</option>
                    {Object.keys(gradedetails).map((key) => (
                      <option key={`gradedetails${key}`} value={key}>
                        {gradedetails[key]}
                      </option>
                    ))}
                  </select>
                  <div className="form-icon">
                    <img src={grade_image} alt="" />
                  </div>
                  <p>{formErrors.grade}</p>
                </div>
              )}
              <div className="dashboard-popup-teacher-profile-details-input">
                <label>Email</label>
                <input
                  type="text"
                  placeholder="email id"
                  name="email"
                  readOnly
                  style={formErrors.email ? { border: "1px solid red" } : null}
                  value={formState.email || ""}
                  onChange={handleInputChange}
                />
                <div className="form-icon">
                  <img src={email_image} alt="" />
                </div>
                <p>{formErrors.email}</p>
              </div>

              {/* <div className="dashboard-popup-teacher-profile-details-input">
                <label>Mobile Number</label>
                <input
                  type="text"
                  placeholder="Mobile Number"
                  id="teacher-create-profile-input"
                  name="phone"
                  style={formErrors.phone ? { border: "1px solid red" } : null}
                  value={formState.phone || ""}
                  onChange={handleInputChange}
                />
                <div className="teacher-create-popup-gender-input-folder">
                  <div className="form-icon">
                    <img src={call_image} alt="" />
                  </div>
                  <select
                    className="teacher-create-popup-gender-input"
                    onChange={handleCountryChange}
                    value={selectedCountry.iso2}
                  >
                    {allCountries.map((country, index) => (
                      <option key={index} value={country.iso2}>
                        {selectedCountry.iso2 === country.iso2
                          ? `+${country.dialCode}`
                          : `${country.name} +${country.dialCode}`}
                      </option>
                    ))}
                  </select>
                </div>
                <p>{formErrors.phone}</p>
              </div> */}
              <div className="dashboard-popup-teacher-profile-details-input">
                <label>Birth Date</label>
                <Datetime
                  name="dob"
                  className={formErrors.dob ? "datetime-error" : "datetime"}
                  timeFormat={false}
                  inputProps={{
                    placeholder: "MM:DD:YYYY",
                    readOnly: true,
                  }}
                  max={today}
                  value={getFormattedDate(formState.dob) || ""}
                  closeOnSelect={true}
                  onChange={handleDateChanged}
                  id="Birth-Date-input"
                  dateFormat="MMM DD, YYYY"
                  isValidDate={
                    isTeacher ? isValidDateTeacher : isValidDateStudent
                  }
                />
                <div className="form-icon">
                  <img src={calender_image} alt="" />
                </div>
                <p>{formErrors.dob}</p>
              </div>
              <div className="dashboard-popup-teacher-profile-details-input">
                <label>Gender</label>
                <div className="dashboard-popup-teacher-profile-gender-folder">
                  <select
                    name="gender"
                    style={
                      formErrors.gender ? { border: "1px solid red" } : null
                    }
                    value={
                      Number.isInteger(parseInt(formState.gender))
                        ? formState.gender
                        : getKeyByValue(
                          ["Choose Other", "Male", "Female", "Other"],
                          formState.gender
                        ) || ""
                    }
                    onChange={handleInputChange}
                  >
                    {genders.map((gender) => {
                      return (
                        <option key={gender.value} value={gender.value}>
                          {gender.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-icon">
                  <img src={gender_image} alt="" />
                </div>
                <p>{formErrors.gender}</p>
              </div>
              {!isTeacher && (
                <div className="dashboard-popup-teacher-profile-details-input">
                  <label>Language</label>
                  <div className="dashboard-popup-teacher-profile-gender-folder">
                    <select
                      name="language"
                      style={
                        formErrors.language
                          ? {
                            border: "1px solid red",
                            borderRadius: 6,
                          }
                          : {
                            border: "1px solid #BDBDBD",
                            borderRadius: 6,
                          }
                      }
                      value={formState.language || ""}
                      onChange={handleInputChange}
                    >
                      <option value="">Select Language</option>
                      {Object.keys(AddtoLanguages)
                        .map((key) => key)
                        .map((key) => (
                          <option key={key} value={AddtoLanguages[key]}>
                            {AddtoLanguages[key]}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="form-icon">
                    <img src={language_image} alt="" />
                  </div>
                  <p>{formErrors.language}</p>{" "}
                </div>
              )}
              <div className="dashboard-popup-teacher-profile-details-input">
                <label>Location</label>
                <Autocomplete
                  apiKey={GOOGLE_MAP_KEY}
                  onPlaceSelected={handlePlaceSelect}
                  defaultValue={formState.location}
                  onChange={handleAutocompleteInputChange}
                  onBlur={handleInputBlur}
                  options={{
                    types: ["(cities)"],
                  }}
                />
                <div className="form-icon location-icon">
                  <img src={location_image} alt="" />
                </div>
                <p>{formErrors.location}</p>
              </div>
            </div>
            {isTeacher && (
              <div className="dashboard-popup-teacher-profile-details-input location-input">
                <label>
                  Language {isEditable && <span>(Select Language)</span>}
                </label>
                <div className="dashboard-popup-teacher-profile-select-Language-container">
                  {isEditable && (
                    <div className="Subjects-you-teach-suggestions-folder">
                      {Object.keys(globalState?.datalist?.languages)
                        .filter((key) => {
                          const languageName =
                            globalState.datalist.languages[key];
                          const languageId = parseInt(key);
                          return (
                            !formState.language_ids.includes(languageName) &&
                            !formState.language_ids.includes(languageId)
                          );
                        })
                        .map((key) => (
                          <a
                            // href="#"
                            key={key}
                            onClick={() => addLanguage(key)}
                          >
                            {globalState?.datalist?.languages[key]}
                          </a>
                        ))}

                      {/* <span>More Languages coming soon</span> */}
                    </div>
                  )}
                  {formState.language_ids
                    .split(",")
                    .filter((language) => language !== "").length > 0 && (
                      <div className="dashboard-popup-teacher-profile-selected-Language-folder">
                        <div className="Subjects-you-teach-feilds-folder">
                          {formState.language_ids
                            .split(",")
                            .filter((language) => language !== "")
                            .map((language) => (
                              <div
                                className="Subjects-you-teach-feilds"
                                key={language}
                              >
                                <p>
                                  {globalState?.datalist?.languages[language] ??
                                    globalState?.datalist?.languages[
                                    getKeyByValue(
                                      globalState?.datalist?.languages,
                                      language
                                    )
                                    ]}
                                </p>
                                {isEditable && (
                                  <a
                                    // href="#"
                                    className="subject-delete-btn"
                                    onClick={() => removeLanguage(language)}
                                  >
                                    <i className="fa-solid fa-xmark"></i>
                                  </a>
                                )}
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                </div>
                <p>{formErrors.language}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      {openDialog && (
        <div className="Course-created-successfully-main">
          <div className="Course-created-successfully-container">
            <div className="Course-created-successfully-folder">
              {/* <div className="Course-created-successfully-image">
                                              <img src={Course_created_successfully_image} alt="" />
                                            </div> */}
              <h2>Are you sure you want to {isActive == true ? 'disable ' : 'enable'} the teacher account?</h2>
              <div className="Course-close-btn">
                <button type="submit" onClick={cancelRegistration}>
                  No
                </button>
                <button
                  type="submit"
                  onClick={() => handleActiveinactive(isActive)}
                  className="Course-close"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {modalIsOpen && (
        <CropProfileImageModal
          src={src}
          setSrc={setSrc}
          setSelectedImage={setSelectedImage}
          setImageUploaded={setImageUploaded}
          setUplodedImage={setUplodedImage}
          modalStatus={modalIsOpen}
          setModalClose={onCloseModal}
          fieldName={isTeacher ? "ai_avatar" : "student_profile_pic"}
          setFormState={setFormState}
          formState={formState}
        />
      )}
      {isAddToSubjetModelOpen && (
        <EditSubject
          subject_title="Add the subjects you teach"
          modalStatus={isAddToSubjetModelOpen}
          subjectlist={Object.values(
            isTeacher
              ? Object.values(globalState.teacher_details?.data?.subjects)
              : Object.values(globalState.student_details?.data?.interest)
          )}
          setModalClose={() => setIsAddToSubjetModelOpen(false)}
          onClose={() => setIsAddToSubjetModelOpen(false)}
          onSubmit={(subjects) => {
            setFormState((prev) => ({
              ...prev,
              [subjectsKey]: subjects,
            }));
            setIsAddToSubjetModelOpen(false);
          }}
        />
      )}
    </div>
  );
}

export default TeacherProfile;
