import React, { useState, useEffect, useRef } from "react";
import { visa_image, credit_card, visa_image2, visa_image3, visa_image4, check_image, Subscribed_unlock_premium_content_check_icon } from "../../assets/images/images";
import { loadStripe } from '@stripe/stripe-js';
import { removeCartData, resetRemoveCart } from '../../state/actions/removeCartAction';
import { Elements, CardNumberElement, CardExpiryElement, CardCvcElement, cardholderName, useStripe, useElements } from '@stripe/react-stripe-js';
// import '../../components/PaymentStrip/stripePayment.css'; // Make sure to create a CSS file for styles
import BackButton from '../BackButton/BackButton';
// import SuccessModal from "./OrderSucess";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from '../../features/auth/AuthContext';
import api from '../../api/api';
import { proceedToPayData, resetProceedToPay } from '../../state/actions/coursePaymentAction';
import CongratulationsPopup from "../CongratulationPopup/Congratulations";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { Modal } from "react-responsive-modal";
import CancelPopup from '../CancelPopup/CancelPopup';
import { setTeacherSubscriptionList, resetTeacherDetailsPlan } from "../../state/actions/setTeacherSubsciptionPlanAction";
import {
    cartList,
    resetCartList
} from "../../state/actions/cartlistAction";
import "./SubscriptionPlanPayment.css";
import SubscriptionPlanPaymentCard from "./SubscriptionPlanPaymentCard";
import SubscriptionPlanPaymentBilling from "./SubscriptionPlanPaymentBilling";

// const stripePromise = loadStripe(process.env.REACT_APP_PUBLISH_KEY); // Replace with your Stripe publishable key

// const CARD_ELEMENT_OPTIONS = {
//   style: {
//     base: {
//       fontSize: '16px',
//       color: '#424770',
//       '::placeholder': {
//         color: '#aab7c4',
//       },
//     },
//     invalid: {
//       color: '#9e2146',
//     },
//     placeholder: {
//       content: 'Enter card number'
//     },
//   },
// };

function CheckoutForm() {

    const monthlyPlanPrise = '$10'
    const yearlyPlanPrise = '$120'


    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [paymentStatus, setPaymentStatus] = useState('');
    const [paymentSuccessPopup, setpaymentSuccessPopup] = useState(false);
    const [cardholderName, setCardholderName] = useState(''); // State to hold the cardholder's name
    const [isCardNumberValid, setCardNumberValid] = useState(false);
    const [isCardExpiryValid, setCardExpiryValid] = useState(false);
    const [isCardCvcValid, setCardCvcValid] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [item_id, setitem_id] = useState();
    const formRef = useRef(null); // Ref for the form
    const { token } = useAuth();
    const dispatch = useDispatch();
    const removeCart = useSelector((state) => state.removeCartData);

    const globalState = useSelector((state) => state.globalState);
    const location = useLocation()
    const [selectedPlanPrice, setSelectedPlanPrice] = useState(location.state.planDetails.price);
    const [currentPlan, setCurrentPlan] = useState(location.state.plans);
    const [disabledCurrentPlan, setDisabledCurrentPlan] = useState({
        planIs: null,
        planIndex: null
    });
    const [updatesPlan, setUpdatesPlan] = useState({
        planName: location.state.planDetails.name,
        planMonthlyPrice: location.state.plans ? location.state.planDetails.price : location.state.planDetails.monthly_price,
        planYearlyPrice: location.state.plans ? location.state.planDetails.yearly_price : location.state.planDetails.price,
        monthlyPlanId: location.state.plans ? location.state.planDetails.plan_id : location.state.planDetails.monthly_plan_id,
        yeatlyPlanId: location.state.plans ? location.state.planDetails.yearly_plan_id : location.state.planDetails.plan_id,

    });



    const remaining_credit = globalState?.userCurrentPlan?.currentSubscription?.remaining_credit



    const [plan_id, setPlan_id] = useState(location.state.planDetails.plan_id);



    const proceedToPay = useSelector((state) => state.proceedToPay);
    // console.log(proceedToPay);

    const proceedToPaybtn = () => {
        dispatch(proceedToPayData(token));
    };


    const paymenPricedata = useSelector((state) => state.cartlist);
    // console.log(paymenPricedata);
    // const dispatch = useDispatch();

    // Calculate total price
    const calculateTotalPrice = () => {
        let totalPrice = 0;
        paymenPricedata.cartList.forEach(item => {
            totalPrice += item.price;
        });
        return totalPrice.toFixed(2); // Format to 2 decimal places
    }

    // Function to dynamically adjust CARD_ELEMENT_OPTIONS based on screen width
    const getCardElementOptions = () => {
        // Default fontSize
        let fontSize = '16px';

        // Check if the screen width is less than or equal to 600px (common breakpoint for mobile devices)
        // if (window.innerWidth <= 600) {
        //   // Adjust fontSize for mobile screens
        //   fontSize = '12px';
        // }
        if (window.innerWidth <= 768) {
            // Adjust fontSize for mobile screens
            fontSize = '12px';
        }

        // Return the CARD_ELEMENT_OPTIONS with the adjusted fontSize
        return {
            style: {
                base: {
                    fontSize: fontSize,
                    color: '#424770', // Text color
                    '::placeholder': {
                        color: '#808080', // Placeholder text color
                    },
                },
                invalid: {
                    color: '#9e2146', // Color for invalid input
                },
            },
        };
    };

    // Use this function to get the dynamically adjusted options
    const CARD_ELEMENT_OPTIONS = getCardElementOptions();


    const [fontSize, setFontSize] = useState('14px'); // Default to mobile view

    useEffect(() => {
        const updateFontSize = () => {
            const screenWidth = window.innerWidth;
            if (screenWidth > 1024) {
                setFontSize('16px'); // Desktop
            } else if (screenWidth > 768) {
                setFontSize('15px'); // Tablet
            } else {
                setFontSize('14px'); // Mobile
            }
        };

        window.addEventListener('resize', updateFontSize);
        updateFontSize(); // Initialize font size

        return () => window.removeEventListener('resize', updateFontSize);
    }, []);

    const handleSubmit = async () => {
        //event.preventDefault();

        if (!stripe || !elements) {
            setError('Stripe has not loaded yet.');
            return;
        }

        try {
            const cardElement = elements.getElement(CardNumberElement);
            const payload = {
                order_id: proceedToPay.order_id
            };
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                }
            };
            const paymentIntentResponse = await api.post('payments/payment/', payload, config);
            const clientSecret = paymentIntentResponse.data.client_secret

            const result = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: cardElement,
                    billing_details: {
                        name: cardholderName,
                    },
                },
            });

            console.log("OnConfirm")

            if (result.error) {
                setError(result.error.message);
            } else {
                if (result.paymentIntent.status === 'succeeded') {
                    setPaymentStatus('Payment succeeded!');
                    setpaymentSuccessPopup(true);
                }
            }
        } catch (err) {
            setError('An error occurred.');
            console.error(err);
        }
    };


    useEffect(() => {

        if (proceedToPay.order_id != null) {
            handleSubmit();
        }

    }, [proceedToPay.order_id])

    useEffect(() => {
        if (paymentSuccessPopup) {
            setTimeout(() => {
                dispatch(cartList(token));
                setpaymentSuccessPopup(false)
                navigate(`/`);
            }, 10000)
        }
    }, [paymentSuccessPopup])


    useEffect(() => {
        if (removeCart?.message?.length) {
            toast.success(removeCart?.message);
        }
        if (removeCart?.error?.length) {
            toast.error(removeCart?.error)
        }
        return () => {
            dispatch(resetRemoveCart());
        }
    }, [removeCart]);

    // Trigger form submission from the "Proceed to Pay" button
    const handleClick = () => {
        if (formRef.current) {
            formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
        }
    };

    // const handleCloseModal = () => {
    //   setShowSuccessModal(false);
    // };

    // const [showSuccessModal, setShowSuccessModal] = useState(false);

    const handleChange = (event) => {
        // Update the validity state based on the element's type
        switch (event.elementType) {
            case 'cardNumber':
                setCardNumberValid(event.complete);
                break;
            case 'cardExpiry':
                setCardExpiryValid(event.complete);
                break;
            case 'cardCvc':
                setCardCvcValid(event.complete);
                break;
            default:
                break;
        }
    };

    const isFormValid = () => {
        return isCardNumberValid && isCardExpiryValid && isCardCvcValid && cardholderName.trim() !== '' && paymenPricedata?.cartList.length > 0;
    };

    const show = () => {
        setOpenDialog(false)
        const req = {
            cart_item_id: item_id
        };
        dispatch(removeCartData(req, token));
    };

    const close = (id) => {
        setitem_id(id)
        setOpenDialog(!openDialog); // This will close the popup
    };


    const [selectedPlan, setSelectedPlan] = useState(location.state.plans ? 'monthly' : 'yearly');

    const handlePlanSelect = (plan) => {
        setSelectedPlan(plan);
        if (plan === 'monthly') {
            setCurrentPlan(true)
            // setSelectedPlanPrice(location.state.plans ? location.state.planDetails.price : location.state.planDetails.monthly_price)
            setSelectedPlanPrice(updatesPlan.planMonthlyPrice)
            setPlan_id(location.state.plans ? location.state.planDetails.plan_id : location.state.planDetails.monthly_plan_id)
        } else {
            setCurrentPlan(false)
            // setSelectedPlanPrice(location.state.plans ? location.state.planDetails.yearly_price : location.state.planDetails.price)
            setSelectedPlanPrice(updatesPlan.planYearlyPrice)
            setPlan_id(location.state.plans ? location.state.planDetails.yearly_plan_id : location.state.planDetails.plan_id)
        }
    };

    useEffect(() => {
        if (selectedPlan === 'monthly') {
            setSelectedPlanPrice(updatesPlan.planMonthlyPrice)
        } else {
            setSelectedPlanPrice(updatesPlan.planYearlyPrice)
        }
    }, [updatesPlan.planName])


    useEffect(() => {
        return () => {
            dispatch(resetTeacherDetailsPlan());
        };
    }, []);

    useEffect(() => {
        if (location.state.plans) {
            setSelectedPlan('monthly');
        } else {
            setSelectedPlan('yearly');
        }
    }, [location.state.plans])


    useEffect(() => {
        if (location?.state?.current_plan_id !== null) {
            const allPlans = location?.state?.AllPlans;
            for (let index = 0; index < allPlans.length; index++) {
                const plan = allPlans[index];
                if (location.state.plans) {
                    if (location?.state?.current_plan_id === allPlans[index]?.plan_id) {
                        setDisabledCurrentPlan({
                            planIs: allPlans[index]?.name,
                            planIndex: index
                        });
                        break;
                    } else if (location?.state?.current_plan_id === allPlans[index]?.yearly_plan_id) {
                        setDisabledCurrentPlan({
                            planIs: allPlans[index]?.name,
                            planIndex: index
                        });
                        break;
                    }
                } else {
                    if (location?.state?.current_plan_id === allPlans[index]?.plan_id) {
                        setDisabledCurrentPlan({
                            planIs: allPlans[index]?.name,
                            planIndex: index
                        });
                        break;
                    } else if (location?.state?.current_plan_id === allPlans[index]?.monthly_price) {
                        setDisabledCurrentPlan({
                            planIs: allPlans[index]?.name,
                            planIndex: index
                        });
                        break;
                    }
                }
            }
        }
    }, [location?.state?.current_plan_id])


    return (
        <div className="subscription-plan-payment-section">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className='paymentStripe-section'>
                            <BackButton buttonName="Choose your payment method" />
                            <div className='paymentStripe-wrapper'>
                                <div className="subscription-paymentStrip-left-section">
                                    <div className='select-plan-container'>
                                        <div className="subscription-select-your-plan-container">
                                            <h2>Select your Plan</h2>
                                            <div className="select-plan-folder">
                                                {
                                                    location?.state?.AllPlans.map((plan, index) => {

                                                        //                                                         const isDisabled =
                                                        //                                                             disabledCurrentPlan.planIs !== null &&
                                                        //                                                             disabledCurrentPlan.planIs !== plan.name &&
                                                        //                                                             (remaining_credit === undefined || remaining_credit === 0 || (disabledCurrentPlan.planIndex !== null && disabledCurrentPlan.planIndex >= index));

                                                        const isDisabled = disabledCurrentPlan.planIs === plan.name || disabledCurrentPlan.planIndex > index || remaining_credit === 0

                                                        return <div
                                                            className={`select-plan-tab-folder ${updatesPlan.planName === plan.name ? 'selected' : ''}`}
                                                            onClick={() => {
                                                                if (!isDisabled) {
                                                                    setUpdatesPlan({
                                                                        planName: plan.name,
                                                                        planMonthlyPrice: location.state.plans ? plan.price : plan.monthly_price,
                                                                        planYearlyPrice: location.state.plans ? plan.yearly_price : plan.price,
                                                                        monthlyPlanId: location.state.plans ? plan.plan_id : plan.monthly_plan_id,
                                                                        yeatlyPlanId: location.state.plans ? plan.yearly_plan_id : plan.plan_id
                                                                    });
                                                                }
                                                            }}

                                                            style={{
                                                                cursor: isDisabled ? 'not-allowed' : 'pointer',
                                                                color: isDisabled ? '#ccc' : 'black'
                                                            }}


                                                        // style={{
                                                        //     cursor: ((disabledCurrentPlan.planIs !== null && disabledCurrentPlan.planIs !== plan.name) && (remaining_credit !== undefined && remaining_credit !== 0) && (disabledCurrentPlan.planIndex !== null && disabledCurrentPlan.planIndex < index)) ? "pointer" : 'not-allowed',
                                                        //     color: ((disabledCurrentPlan.planIs !== null && disabledCurrentPlan.planIs !== plan.name) && (remaining_credit !== undefined && remaining_credit !== 0) && (disabledCurrentPlan.planIndex !== null && disabledCurrentPlan.planIndex < index)) ? 'black' : '#ccc'
                                                        // }}
                                                        >
                                                            <div className="subscription-select-your-plan-tab" >
                                                                <input type="radio" checked={updatesPlan.planName === plan.name} readOnly />
                                                                <div className="subscription-select-your-inner-plan">
                                                                    <span>{plan.name}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>


                                    </div>
                                    <div className='paymentStrip-left-section'>
                                        {/* <SubscriptionPlanPaymentCard /> */}


                                        <div className="subscription-select-your-plan-container">
                                            <h2>Select your Plan</h2>
                                            <div className="subscription-select-your-plan-folder">
                                                <div
                                                    className={`subscription-select-your-plan-tab-folder ${selectedPlan === 'monthly' ? 'selected' : ''
                                                        }`}
                                                    onClick={() => {
                                                        if (location.state.plan_type !== 'Yearly') {
                                                            handlePlanSelect('monthly')
                                                        }
                                                    }}
                                                    style={{
                                                        cursor: location.state.plan_type === 'Yearly' ? 'not-allowed' : 'pointer',
                                                        color: location.state.plan_type === 'Yearly' ? '#ccc' : 'black'
                                                    }}
                                                >
                                                    <div className="subscription-select-your-plan-tab">
                                                        <input type="radio" checked={selectedPlan === 'monthly'} readOnly />
                                                        <div className="subscription-select-your-inner-plan">
                                                            <span>Paid Monthly</span>
                                                            {/* {
                                                                location.state.plans ? <p>${location.state.planDetails.price} / Month</p> : <p>${location.state.planDetails.monthly_price} / Month</p>
                                                            } */}
                                                            {
                                                                <p>${updatesPlan.planMonthlyPrice} / Month</p>
                                                            }
                                                        </div>
                                                    </div>
                                                    {/* {
                                                        location.state.plans ? <h2>${location.state.planDetails.price}</h2> : <h2>${location.state.planDetails.monthly_price}</h2>
                                                    } */}
                                                    {
                                                        <h2>${updatesPlan.planMonthlyPrice}</h2>
                                                    }
                                                </div>
                                                <div
                                                    className={`subscription-select-your-plan-tab-folder ${selectedPlan === 'yearly' ? 'selected' : ''
                                                        }`}
                                                    onClick={() => handlePlanSelect('yearly')}
                                                >
                                                    <div className="subscription-select-your-plan-tab">
                                                        <input type="radio" checked={selectedPlan === 'yearly'} readOnly />
                                                        <div className="subscription-select-your-inner-plan">
                                                            <span>
                                                                Paid Yearly <span className="subscription-select-your-inner-plan-offer">Save 20%</span>
                                                            </span>
                                                            {/* {
                                                                location.state.plans ? <p>${location.state.planDetails.price - ((20 / 100) * (location.state.planDetails.price))} X 12 Months</p> : <p>${location.state.planDetails.yearly_price - ((20 / 100) * (location.state.planDetails.yearly_price))} X 12 Months</p>
                                                            } */}
                                                            {
                                                                <p>${updatesPlan.planMonthlyPrice - ((20 / 100) * (updatesPlan.planMonthlyPrice))} X 12 Months</p>
                                                            }
                                                        </div>
                                                    </div>
                                                    {/* {
                                                        location.state.plans ? <h2>${location.state.planDetails.yearly_price}</h2> : <h2>${location.state.planDetails.price}</h2>
                                                    } */}
                                                    {
                                                        <h2>${updatesPlan.planYearlyPrice}</h2>
                                                    }
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                                <div className='paymentStrip-right-section'>
                                    <SubscriptionPlanPaymentBilling selectedPlanPrice={selectedPlanPrice} planType={updatesPlan.planName} plan={location.state.planDetails}
                                        currentPlan={currentPlan} plans={location.state.plans} plan_id={selectedPlan === 'monthly' ? updatesPlan.monthlyPlanId : updatesPlan.yeatlyPlanId} current_plan={location.state.current_plan} status={location?.state?.status}
                                        updatesPlan={updatesPlan} />
                                </div>
                                <div className='paymentStripe-section'>
                                    {/* Your existing JSX code */}

                                    {/* <SuccessModal isOpen={showSuccessModal} onClose={handleCloseModal} /> */}
                                </div>
                            </div>
                            {paymentSuccessPopup && <CongratulationsPopup
                                check_image={check_image}
                                des="Congratulations! You've successfully purchased the courses."
                            />}

                            {openDialog && (
                                <Modal
                                    open={openDialog}
                                    onClose={() => setOpenDialog(false)}
                                    center
                                    showCloseIcon={false}
                                    classNames={{
                                        modal: 'Cancel-Popup',
                                    }}
                                >
                                    <CancelPopup
                                        title="Are you sure you want to remove this course from the cart?"
                                        No="No"
                                        Yes="Yes"
                                        show={show} // Assuming you have a state or value to pass 
                                        close={close} // Pass the reference, not the invocation
                                    />
                                </Modal>
                            )}
                            {/* <div className="Subscribed-unlock-premium-content-popup-wrapper">
                                <div className="Subscribed-unlock-premium-content-popup-container">
                                    <div className="Subscribed-unlock-premium-content-check-icon">
                                        <img src={Subscribed_unlock_premium_content_check_icon}/>
                                    </div>
                                    <h2>Subscribed! Unlock premium content, excel ahead!</h2>
                                    <div className="Subscribed-unlock-premium-content-btn-folder">
                                        <button type="button">Back to Dashboard</button>
                                        <button type="button">Create Course</button>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

function SubscriptionPlanPayment() {

    const globalState = useSelector((state) => state.globalState);


    const stripePromise = loadStripe(globalState.is_test ? process.env.REACT_APP_TEST_PUBLISH_KEY : process.env.REACT_APP_PUBLISH_KEY); // Replace with your Stripe publishable key


    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm />
        </Elements>
    );
}


export default SubscriptionPlanPayment;

