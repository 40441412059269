import React, { useEffect, useState, useRef } from "react";
import { useAuth } from "../../../features/auth/AuthContext";
import { useDispatch, useSelector } from "react-redux";
import CoursesList from "./CoursesList";
import SearchComponent from "../../../components/SearchComponent/SearchComponent";
import { search } from "../../../assets/images/images";
import { toast } from "react-toastify";
import SortPopup from "../../../pages/customSort/SortPopup";
import {
  getCourseBySchoolId,
  resetCourseListBySchoolId,
} from "../../../state/actions/getCourseListBySchoolIDAction";
import { addToHistory } from "../../../state/reducers/historyReducer";
import { Link, Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

function AllCourses() {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchOption, setsearchOption] = useState("");
  const [sortOption, setSortOption] = useState("");
  const [isVisible, setIsVisible] = useState(false);

  const handleRefresh = () => {
    getCourseBySchoolIdRequest();
  };

  const tabList = [
    {
      title: "Courses",
      displayItem: (
        <CoursesList
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          type={"active"}
          handleRefresh={handleRefresh}
        />
      ),
      key: "courses", // Add the title property
      route: "all-courses"
    },
    // {
    //     title: "Sales Courses",
    //     displayItem: <CoursesList currentPage={currentPage} setCurrentPage={setCurrentPage} />,
    //     key: "sales",
    // },
    // {
    //     title: "Individual Courses",
    //     displayItem: <CoursesList currentPage={currentPage} setCurrentPage={setCurrentPage} />,
    //     key: "individual",
    // },
    {
      title: "Expired Courses",
      displayItem: (
        <CoursesList
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          type={"expired"}
          handleRefresh={handleRefresh}
        />
      ),
      key: "expired",
      route: "expired-courses"
    },
    {
      title: "Inactive Courses",
      displayItem: <CoursesList currentPage={currentPage} setCurrentPage={setCurrentPage} type={"inactive"} handleRefresh={handleRefresh} />,
      key: "inactive",
      route: "inactive-courses"
    },
  ];

  const location = useLocation();
  const pathParts = location.pathname.split("/");
  const lastPathPart = pathParts[pathParts.length - 1];

  console.log("pathParts", pathParts, lastPathPart)

  let selectedTab = lastPathPart.length ? tabList.find((tab) => tab.route === lastPathPart)?.key : tabList[0].key;

  const [activeTab, setActiveTab] = useState(selectedTab);
  const { token } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { role } = useSelector((state) => state.globalState);
  const deleteCourseById = useSelector((state) => state.deleteCourseById);
  const globalState = useSelector((state) => state.globalState);
  const school_id = globalState.school_id;

  const handleSearch = (e) => {
    setsearchOption(e.target.value);
  };

  const handleSortOptionChange = (newSortOption) => {
    setSortOption(newSortOption);
  };

  const getCourseBySchoolIdRequest = () => {
    dispatch(
      getCourseBySchoolId(
        role,
        school_id,
        token,
        currentPage,
        searchOption,
        sortOption,
        activeTab
      )
    );
  };

  useEffect(() => {
    getCourseBySchoolIdRequest();

    if (deleteCourseById?.message?.length) {
      toast.success(deleteCourseById?.message);
    }
  }, [activeTab, currentPage, searchOption, sortOption, deleteCourseById]);

  useEffect(() => {
    return () => {
      dispatch(resetCourseListBySchoolId());
    };
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [activeTab]);

  useEffect(() => {
    dispatch(addToHistory("/dashboard/courses/all-courses"));
  }, []);

  useEffect(() => {
    navigate('/dashboard/courses/all-courses');
  }, []);

  useEffect(() => {
    if (lastPathPart === "*") navigate('/dashboard/courses/all-courses');
  }, [lastPathPart]);

  const renderActiveTabContent = () => {
    const activeTabData = tabList.find((tab) => tab.key === activeTab);
    return activeTabData ? activeTabData.displayItem : null;
  };

  const sortDropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sortDropdownRef.current &&
        !sortDropdownRef.current.contains(event.target)
      ) {
        setIsVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="row">
      <div className="col-lg-12 col-md-12">
        <div className="search_header-container">
          <SearchComponent
            search={search}
            searchPlaceholder="Search"
            searchValue="search"
            onchange={handleSearch}
            name="searchOption"
            value={searchOption}
          />
          <div className="filter-wrapper">
            <div className="course-sortby-popup-wrapper" ref={sortDropdownRef}>
              <div
                className="lessonviewprogress-filder-tab"
                onClick={() => {
                  setIsVisible(!isVisible);
                }}
              >
                <span>
                  <i class="fa-solid fa-arrow-down-wide-short"></i>
                </span>
                <p>Sort</p>
              </div>
              {isVisible && (
                <SortPopup
                  isVisible={isVisible}
                  handleApplyClick={() => setIsVisible(!isVisible)}
                  sortOption={sortOption}
                  onSortOptionChange={handleSortOptionChange}
                />
              )}
            </div>
          </div>
        </div>
        {/* <div className="studentDashboardSidePannel Custom_Tabs_section"> */}
        {/* <div className="view-lessons-btn-container teacehr-tab-setion"> */}
        <div className="studentDashboardSidePannel Custom_Tabs_section">
          {tabList.map((tab) => (
            <Link
              key={tab.key}
              to={`/dashboard/courses/${tab.route}`}
              className={`tab-link ${selectedTab === tab.key ? 'active' : ''}`}
              onClick={(e) => {
                // e.preventDefault(); // Prevent the default link behavior
                setActiveTab(tab.key);
              }}
            >{tab.title}
              {/* <Tab isActive={activeTab === tab.key}>
            {tab.key}
          </Tab> */}
            </Link>
          ))}
          {/* {tabList?.map((tab) => (
            <button
              key={tab.key}
              className={`tab-link ${activeTab === tab.key ? "active" : ""}`}
              onClick={(e) => {
                e.preventDefault();
                setActiveTab(tab.key);
              }}
            >
              {tab.title}
            </button>
          ))} */}
        </div>
        
        {/* </div> */}
        {/* <div className="studentDashboardMainBody">
          <div className="created-my-courses-container"> */}
        <Routes>
          <Route path={`/`} element={<Navigate to={`/dashboard/courses/all-courses`} />} />
          {tabList.map((tab) => (
            <Route
              key={tab.key}
              path={tab.route}
              element={tab.displayItem}
            />
          ))}
        </Routes>
        {/* {renderActiveTabContent()} */}
        {/* </div>
        </div> */}
      </div>
    </div>
  );
}

export default AllCourses;
