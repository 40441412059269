import React, { useEffect, useState } from 'react';
import {
    subscription_plan_background1,
    subscription_plan_background2,
    airbalon,
    subscription_plan_background3,
    subscription_plan_background4,
    subscription_plan_background5,
    subscription_plan_background6,
    plan,
    roket,
} from "../../assets/images/images";
import { useNavigate } from 'react-router-dom';
import { getTeacherSubscriptionList, resetTeacherSubscription } from "../../state/actions/getTeacherSubsciptionPlanAction";
import { useAuth } from '../../features/auth/AuthContext';
import { useDispatch, useSelector } from 'react-redux';
import LoadingComponent from '../LoadingComponent/LoadingComponent';

import { setStudentSubscriptionList, resetstudentSubscriptionplan } from "../../state/actions/setTeacherSubsciptionPlanAction";
import { Visibility } from '@mui/icons-material';
import CongratulationsPopup from '../CongratulationPopup/Congratulations';
import SavePopUp from '../SavePopUp/SavePopUp';

function SubscriptionPlanTeacherCard({ title }) {

    const navigate = useNavigate();
    const { token } = useAuth();
    const dispatch = useDispatch();
    const { teachersubscription, isLoading, error } = useSelector((state) => state.teacherSubscription);
    const [toggle, setToggle] = useState(false)
    const [previesPlanId, setPreviesPlanId] = useState(null)
    const setTeacherSubsciptionPlan = useSelector((state) => state.setTeacherSubsciptionPlan);
    const userPlan = useSelector((state) => state.userActivePlan);
    const globalState = useSelector((state) => state.globalState);

    const teacherCurrentPlan = useSelector((state) => state.teacherCurrentPlan);
    const [current_plan, setCurrent_plan] = useState(null)
    const [yearllyPlan, setYearllyPlan] = useState({
        plan_id: null,
        plan_type: null,
        index: null
    })





    const [isPopUp, setIsPopUp] = useState({
        open: false,
        text: null
    })
    const [current_plan_id, setCurrent_plan_id] = useState(null)

    useEffect(() => {
        if (globalState.userCurrentPlan.currentSubscription !== null) {
            setCurrent_plan(globalState?.userCurrentPlan?.currentSubscription?.subscription_id)
            setCurrent_plan_id(globalState?.userCurrentPlan?.currentSubscription?.plan_id)
        }
    }, [globalState?.userCurrentPlan])

    useEffect(() => {
        const type = title ? 1 : 2;

        dispatch(getTeacherSubscriptionList(token, type));

        //   dispatch(updateGlobalState({ key: 'userCurrentPlan', value: userPlan }));

        return () => {
            dispatch(resetTeacherSubscription());
        }
    }, [title, token])



    function handleClick(plan, index, teachersubscription, current_plan_id, plan_type) {
        // const req = {
        //     plan_type: "teacher",
        //     plan_id: plan.plan_id
        // }
        // dispatch(setStudentSubscriptionList(req, token))
        // setToggle(true)
        let status

        if (current_plan) {
            if (previesPlanId < index) {
                status = 6
            } else {
                status = 5
            }
        }

        navigate('/subscription-plan-payment', { state: { plans: title, planDetails: plan, current_plan: current_plan, status: status, AllPlans: teachersubscription, current_plan_id: current_plan_id, plan_type: plan_type } })

    }



    useEffect(() => {
        if (setTeacherSubsciptionPlan?.setTeacher?.session_url) {
            if (toggle) {
                window.open(setTeacherSubsciptionPlan?.setTeacher?.session_url, '_blank');
                setToggle(false)
            }
        }
    }, [setTeacherSubsciptionPlan])



    useEffect(() => {
        if (current_plan_id !== null) {
            for (let index = 0; index < teachersubscription.length; index++) {
                if (current_plan_id === teachersubscription[index].plan_id) {
                    setYearllyPlan({
                        plan_id: teachersubscription[index].plan_id,
                        plan_type: globalState.userCurrentPlan.currentSubscription ? globalState.userCurrentPlan.currentSubscription.type == 1 ? 'Monthly' : 'Yearly' : null,
                        index: index
                    })
                    break
                } else {
                    setYearllyPlan({
                        plan_id: null,
                        plan_type: globalState.userCurrentPlan.currentSubscription ? globalState.userCurrentPlan.currentSubscription.type == 1 ? 'Monthly' : 'Yearly' : null,
                        index: index
                    })
                }
            }
        }
    }, [teachersubscription])





    useEffect(() => {
        if (current_plan_id !== null) {
            teachersubscription.forEach((plans, index) => {
                if (current_plan_id === plans.plan_id) {
                    setPreviesPlanId(index);
                }
            });
        }
    }, [current_plan_id, teachersubscription]);

    const onClose = () => {
        setIsPopUp({
            open: false,
            text: null
        })
    }



    return (
        <>
            {isLoading ?
                <LoadingComponent
                    loading_text="Educating the Future, Loading Now."
                />
                : teachersubscription.length > 0 &&
                <div className='subscription-plan-card-container'>
                    {
                        teachersubscription.map((plans, index) => {
                            return <div className='subscription-plan-card-folder' key={index}>
                                <button className='Subscribe-active-plan-btn' style={{ display: plans.plan_id == current_plan_id ? 'block' : 'none' }}>Active Plan</button>
                                <div className='subscription-plan-price-folder'>
                                    <span className='basic' style={{ color: index === 0 ? '#6E56FB' : index === 1 ? '#D63AE1' : '#3AABCA' }} >{plans.name}</span>
                                    <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '8px' }}>
                                        {
                                            title ? <h2>${plans.price}</h2> : <h2>${plans.monthly_price - (20 / 100) * plans.monthly_price}</h2>
                                        }
                                        <div style={{ fontSize: '5px' }}>
                                            <p style={{ textDecoration: 'line-through', visibility: !title ? 'visible' : 'hidden' }}>${plans.monthly_price}</p>
                                            <p style={{ color: 'black' }}>/MO</p>
                                        </div>
                                    </div>
                                    <p style={{ display: !title ? 'block' : 'none' }}>${plans.price} Billed Yearly </p>
                                    <span className='circle1'></span>
                                    <span className='circle2'></span>
                                    <span className='circle3'></span>
                                    <span className='circle4'></span>
                                    <div className='subscription-price-icon'>
                                        <img src={index === 0 ? airbalon : index === 1 ? plan : roket} />
                                    </div>
                                </div>
                                <div className='subscription-plan-list-folder'>
                                    <ul className='subscription-plan-list'>
                                        {/* <li>{plans[0].llm_type}</li>
                                    <li>GPT {plans[0].gpt_version} or Equivalent</li>
                                    <li>{plans[0].lessons_assignments} Lessons/Assignments*</li>
                                    <li>ProSchool Support Bot</li> */}
                                        <li>{plans.llm_type}</li>
                                        <li>{plans.lessons_assignments}</li>
                                        <li>{plans.support_bot}</li>
                                        <li>{plans.gpt_version}</li>
                                        {
                                            (index === 1 || index === 2) && <li>{plans.education_program_support}</li>
                                        }
                                        {/* <li>Support an underprivileged Childt</li> */}
                                    </ul>
                                </div>
                                <button className='Subscribe-btn' style={{ backgroundColor: index === 0 ? '#6970FC' : index === 1 ? '#D054CD' : '#3AABCA', display: plans.plan_id == current_plan_id ? 'none' : 'block' }} type='button'
                                    onClick={() => {


                                        if (!current_plan_id) {
                                            handleClick(plans, index, teachersubscription, current_plan_id, yearllyPlan.plan_type)
                                        } else if ((yearllyPlan.plan_type === 'Yearly' && title)) {
                                            setIsPopUp({
                                                open: true,
                                                text: "you can't degrade the plan"
                                            })
                                        } else if ((yearllyPlan.plan_type === 'Monthly')) {
                                            if (title && yearllyPlan.index >= index) {
                                                setIsPopUp({
                                                    open: true,
                                                    text: "you can't degrade the plan"
                                                })
                                            } else {
                                                handleClick(plans, index, teachersubscription, current_plan_id, yearllyPlan.plan_type)
                                            }
                                        } else if (yearllyPlan.plan_type === 'Yearly') {
                                            if (!title && yearllyPlan.index >= index) {
                                                setIsPopUp({
                                                    open: true,
                                                    text: "you can't degrade the plan"
                                                })
                                            } else {
                                                handleClick(plans, index, teachersubscription, current_plan_id, yearllyPlan.plan_type)
                                            }
                                        } else {
                                            handleClick(plans, index, teachersubscription, current_plan_id, yearllyPlan.plan_type)
                                        }
                                    }
                                    }
                                    disabled={plans.plan_id == current_plan_id}
                                >
                                    {
                                        current_plan_id ? 'Upgrade' : 'Subscribe'
                                    }
                                    {/* {
                                        current_plan_id ? (plans.plan_id == current_plan_id ? 'Active plan' : 'Upgrade') : 'Subscribe'
                                    } */}
                                </button>
                                <div className='subscription-plan-background1'>
                                    <img src={index === 0 ? subscription_plan_background1 : index === 1 ? subscription_plan_background4 : subscription_plan_background6} />
                                </div>
                                <div className='subscription-plan-background2'>
                                    <img src={index === 0 ? subscription_plan_background2 : index === 1 ? subscription_plan_background3 : subscription_plan_background5} />
                                </div>
                            </div>
                        })
                    }
                </div >}
            <SavePopUp isOpen={isPopUp.open} onClose={onClose} text={isPopUp.text} />
            {/* <SavePopUp isOpen={true} onClose={onClose} text={'OMKAR HEELO'} /> */}
        </>
    )
}




export default SubscriptionPlanTeacherCard;