import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../../../../api/api";
import ForgetPassword from "../../../../../components/ForgetPassword";
import { useAuth } from "../../../../../features/auth/AuthContext";
import {
  isDifferentFromCurrentPassword,
  isRequired,
  isSamePassword,
  isValidPassword,
} from "../../../../../utils/validator";
const ChangePassword = (props) => {
  const { token } = useAuth();
  const [formErrors, setFormErrors] = useState({
    current_password: "",
    new_password: "",
    confirm_password: "",
  });
  const [formValues, setFormValues] = useState({
    current_password: "",
    new_password: "",
    confirm_password: "",
  });

  const [showPassword, setShowPassword] = useState(false);

  const [showPassword2, setShowPassword2] = useState(false);

  const [showPassword3, setShowPassword3] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let error = "";
    if (name === "current_password") {
      error = isRequired(value) || isValidPassword(value);
    } else if (name === "new_password") {
      error =
        isRequired(value) ||
        isValidPassword(value) ||
        isDifferentFromCurrentPassword(formValues.current_password, value);
    } else if (name === "confirm_password") {
      error =
        isRequired(value) ||
        isValidPassword(value) ||
        isSamePassword(formValues.new_password, value) ||
        isDifferentFromCurrentPassword(formValues.current_password, value);
    }

    setFormErrors({
      ...formErrors,
      [name]: error,
    });
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };
  const handleResetSubmit = async (e) => {
    e.preventDefault();

    let errors = {};

    errors.current_password =
      isRequired(formValues.current_password) ||
      isValidPassword(formValues.current_password);

    errors.new_password =
      isRequired(formValues.new_password) ||
      isValidPassword(formValues.new_password) ||
      isDifferentFromCurrentPassword(
        formValues.current_password,
        formValues.new_password
      );
    errors.confirm_password =
      isRequired(formValues.confirm_password) ||
      isValidPassword(formValues.confirm_password) ||
      isSamePassword(formValues.new_password, formValues.confirm_password) ||
      isDifferentFromCurrentPassword(
        formValues.current_password,
        formValues.confirm_password
      );

    setFormErrors(errors);

    if (!Object.values(errors).some((error) => error)) {
      await changePassword(
        formValues.current_password,
        formValues.new_password,
        formValues.confirm_password
      );
    }
  };

  const changePassword = async (
    current_password,
    new_password,
    confirm_password
  ) => {
    toast.dismiss();
    const data = {
      current_password,
      new_password,
      confirm_password,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    await api
      .post("auth/change-password/", data, {
        headers: headers,
      })
      .then((res) => {
        if (res.data.status === 200) {
          toast.success(res?.data?.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          setFormValues({
            current_password: "",
            new_password: "",
            confirm_password: "",
          });
        } else {
          toast.error(res?.data?.message ?? "Failed to change password", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.message ?? "Failed to change password",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      });
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <form action="" onSubmit={handleResetSubmit}>
        <div className="account_setting_tab_sec">
          <div className="account_setting_tab_sec_head">
            <h6>Change Password</h6>
          </div>
          <div className="reset_password_body">
            <div className="reset_password_body_inner">
              <div className="reset_password_body_single">
                <label>Existing password</label>
                <input
                  type={showPassword3 ? "password" : "text "}
                  value={formValues.current_password}
                  style={
                    formErrors.current_password
                      ? { border: "1px solid red" }
                      : null
                  }
                  placeholder="Enter Existing password"
                  onChange={handleInputChange}
                  name="current_password"
                  id=""
                />
                <div className="forgot-password-folder">
                  <div
                    className="forget-password-btn dashboard-setting-inner-forget-password"
                  >
                    <a  onClick={() => setIsModalOpen(true)}>
                    Forget Password?
                    </a>
                  </div>
                </div>
                <ul className="error-text">
                  {formErrors.current_password.split(".").map((e) => (
                    <li>{e}</li>
                  ))}
                </ul>
                <Link
                  onClick={() =>
                    setShowPassword3((prevShowPassword) => !prevShowPassword)
                  }
                  className="eye_sec"
                >
                  <i
                    className={`fas ${
                      showPassword ? "fa-eye" : "fa-eye-slash"
                    } toggle-password `}
                  ></i>
                </Link>
              </div>
              <div className="reset_password_body_single">
                <label>New password</label>
                <input
                  type={showPassword ? "password" : "text "}
                  value={formValues.new_password}
                  style={
                    formErrors.new_password ? { border: "1px solid red" } : null
                  }
                  placeholder="Enter new password"
                  onChange={handleInputChange}
                  name="new_password"
                  id=""
                />

                <ul className="error-text">
                  {formErrors.new_password.split(".").map((e) => (
                    <li>{e}</li>
                  ))}
                </ul>

                <Link
                  onClick={() =>
                    setShowPassword((prevShowPassword) => !prevShowPassword)
                  }
                  className="eye_sec"
                >
                  <i
                    className={`fas ${
                      showPassword ? "fa-eye" : "fa-eye-slash"
                    } toggle-password `}
                  ></i>
                </Link>
              </div>
              <div className="reset_password_body_single">
                <label>Confirm new password</label>
                <input
                  type={showPassword2 ? "password" : "text "}
                  value={formValues.confirm_password}
                  style={
                    formErrors.confirm_password
                      ? { border: "1px solid red" }
                      : null
                  }
                  placeholder="Confirm new password"
                  onChange={handleInputChange}
                  name="confirm_password"
                  id=""
                />

                <ul className="error-text">
                  {formErrors.confirm_password.split(".").map((e) => (
                    <li>{e}</li>
                  ))}
                </ul>
                <Link
                  onClick={() =>
                    setShowPassword2((prevShowPassword) => !prevShowPassword)
                  }
                  className="eye_sec"
                >
                  <i
                    className={`fas ${
                      showPassword2 ? "fa-eye" : "fa-eye-slash"
                    } toggle-password `}
                  ></i>
                </Link>
              </div>
              <div className="reset_pass">
                <button type="submit">Reset Password</button>
              </div>
              <div className="password_req">
                <ul>
                  <li>One Lowercase character</li>
                  <li>One Special character </li>
                  <li>One Uppercase character </li>
                  <li>8 Character minimum</li>
                  <li>One number </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </form>
      {isModalOpen && (
        <ForgetPassword
          isModalOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </>
  );
};

export default ChangePassword;
