import React from "react";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { updateGlobalState } from "../../state/global/slice";

const CustomTooltip = ({ title, children, isVisible, onClose }) => {
  const dispatch = useDispatch();
  const globalState = useSelector((state) => state.globalState);
  const updateState = (key, value) => {
    dispatch(updateGlobalState({ key: key, value: value }));
  };
  const [isGlobalVisible, setIsGlobalVisible] = React.useState(
    globalState?.isTooltipVisible ?? isVisible
  );

  React.useEffect(() => {
    if (isVisible === false) {
      setIsGlobalVisible(false);
      updateState("isTooltipVisible", false);
    }
  }, [isVisible]);

  return (
    <div>
      {isGlobalVisible && (
        <div className="custom-tooltip custom-tooltip2">
          {title}
          <Button
            id="custom_chatbot_close_btn"
            onClick={(e) => onClose(e)}
            size="small"
          >
            <i class="fa-solid fa-x"></i>
          </Button>
        </div>
      )}
      {children}
    </div>
  );
};

export default CustomTooltip;
