import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PlatformFee {
    platformfee: any;
    isLoading: Boolean;
    error: string | null;
}

const initialState: PlatformFee = {
    platformfee: null,
    isLoading: false,
    error: null,
};

const platformFeeSlice = createSlice({
    name: 'platformFee',
    initialState,
    reducers: {
        setPlatformFee: (state: PlatformFee, action: PayloadAction<PlatformFee>) => {
            state.platformfee = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        isloading: (state: PlatformFee, action: PayloadAction<Boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: PlatformFee, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetPlatformFee: () => {
            return initialState;
        },
    },
});

export const { setPlatformFee, isloading, seterror, resetPlatformFee } = platformFeeSlice.actions;

export default platformFeeSlice.reducer;
