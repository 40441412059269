import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import api from "../../../../../../api/api";
import { useAuth } from "../../../../../../features/auth/AuthContext";
import "../../../../styles/dashboard.css";
import "../../../../styles/r-style.css";
import { devicon_linkedin } from "../../../../../../assets/images/images";
import imgs from "../../../../../../assets/images/imgs";
import { updateGlobalState } from "../../../../../../state/global/slice";
import { useDispatch, useSelector } from "react-redux";
import LoadingComponent from "../../../../../../components/LoadingComponent/LoadingComponent";

const TeacherProfileSetting = () => {
  const navigate = useNavigate();
  const { token } = useAuth();
  const { teacher_id } = useParams();
  const [getinfotecher, setinfoteacher] = useState({});
  const [gettechercoursedtls, settechercoursedtls] = useState({});
  const [loading, setLoading] = useState(false);
  const globalState = useSelector((state) => state.globalState);

  const dispatch = useDispatch();

  const updateState = (key, value) => {
    dispatch(updateGlobalState({ key: key, value: value }));
  };

  useEffect(() => {
    document.body.style.overflow = "auto";
    api
      .get(`/auth/get-teacher-by-teacher-id/${teacher_id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          updateState("teacher_details", response?.data ?? {});
          setinfoteacher(response?.data?.data ?? []);
        }
      })
      .catch((error) => {
        //
      });

    api
      .get(`/courses/get-all-courses/teacher/${teacher_id}/?type=active`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          settechercoursedtls(response?.data?.data ?? []);
        }
      })
      .catch((error) => {
        //
      });
  }, [teacher_id]);

  return (
    <>
      <section className="teacher_profile_set_sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="teacher_profile_set_sec_inner">
                <Link to={`/dashboard/home`} className="back_btn_st">
                  <i className="fa-solid fa-chevron-left"></i>Back
                </Link>
                <div className="teacher_profile_body">
                  <h6>Teacher Profile</h6>
                  <div className="about_teach_main_profile">
                    <div className="teacher_profile_about_teacher">
                      <div className="about_teacher_img">
                        <div className="about_teach_sec_new_left">
                          <div className="about_left_pic">
                            <img
                              src={getinfotecher?.ai_avatar || imgs.png.ab_img}
                              alt=""
                            />
                          </div>
                          <h5>{getinfotecher?.name}</h5>
                        </div>
                      </div>
                      <div className="about_teacher_cont">
                        <h5>About Teacher</h5>
                        <p>{getinfotecher?.introduction}</p>
                      </div>
                    </div>
                    <div className="profile_linkedin">
                      <div className="profile_linkedin_image">
                        <img src={devicon_linkedin}/>
                      </div>
                      <a href={getinfotecher.linkedin_url} target="_blank">{getinfotecher?.linkedin_url ?? "-"}</a>
                    </div>
                  </div>
                  <div className="subject_special_sec">
                    <h5>Subject Specialization</h5>
                    <ul>
                      {getinfotecher?.subjects &&
                      Object.values(getinfotecher?.subjects).length > 0 ? (
                        Object.values(
                          getinfotecher?.subjects
                        ).map((subject, index) => <li>{subject}</li>)
                      ) : (
                        <h6>No Specialization are selected</h6>
                      )}
                    </ul>
                  </div>
                  <div className="gray_house_sec">
                    <h5>Teacher Courses</h5>
                    <div className="gray_house_sec_inner">
                      {loading ? 
                        <LoadingComponent
                          loading_text = "Educating the Future, Loading Now."
                        /> :
                      gettechercoursedtls && gettechercoursedtls.length > 0 ? (
                        gettechercoursedtls.map((value, index) => (
                          <div className="gray_house_sec_single">
                            <div className="gray_house_sec_single_img">
                              <img src={value?.image} alt="" />
                            </div>
                            <div className="gray_house_sec_single_cont">
                              <div className="card-rating-container">
                                <div className="gray_house_teacher">
                                  <img
                                    src={
                                      getinfotecher?.ai_avatar || imgs.png.ab_img
                                    }
                                    alt=""
                                  />
                                  <span>
                                    <h6>{getinfotecher?.name}</h6>
                                    <p>Teacher</p>
                                  </span>
                                </div>
                                {/* Hidden for June */}
                                {/* {!globalState.subdomain_exists && value?.ratings_count > 0 && (
                                  <div className="studentDashboar-Suggested-Courses-profile-rate" onClick={() => navigate(`/ratings/${value?.course_id}/view`)}>
                                    {value?.average_rating > 0 && (
                                      <>
                                        <a>
                                          <i className="fa-solid fa-star"></i>
                                        </a>
                                        <p className="rate">{value?.average_rating.toFixed(1)}</p>
                                      </>
                                    )}
                                    {value?.ratings_count > 0 && (
                                      <p className="number">({parseInt(value?.ratings_count)})</p>
                                    )}
                                  </div>
                                )} */}
                                {/* Hidden for June */}
                              </div>
                              <div className="gray_house_teacher_cont_sec_new">
                                <h4>{value?.subject}</h4>
                                <h3>{value?.name}</h3>
                                {/* Hidden for June */}
                                {/* {!globalState.subdomain_exists && <div className="disscount-course-price-folder">
                                  {value?.discounted_price != value?.price && <p>${value?.price ?? 0}</p>}
                                  <span className="course-price">${value?.discounted_price ?? 0}</span>
                                </div>} */}
                                {/* Hidden for June */}
                                <div className="view_cose_det_sec">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      navigate(
                                        `/course/${value?.course_id}/view`,
                                        {
                                          state: {
                                            course: getinfotecher,
                                            isFromInvite: false,
                                          },
                                        }
                                      );
                                    }}
                                    className="view_course_sec"
                                  >
                                    View Course
                                  </button>
                                  {/* <button type='button' className='enroll_btn_sec'>Enroll</button> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <h6>Course not uploaded yet</h6>
                      )}
                      {/* <div className='gray_house_sec_single'>
                                            <div className='gray_house_sec_single_img'>
                                                <img src={gray_2} />
                                            </div>
                                            <div className='gray_house_teacher_cont_sec_new'>
                                                <h4>Science</h4>
                                                <h3>Fundamental of Neuroscience</h3>
                                                <div className='view_cose_det_sec'>
                                                    <button type='button' className='view_course_sec'>View Course</button>
                                                    <button type='button' className='enroll_btn_sec'>Enroll</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='gray_house_sec_single'>
                                        <div className='gray_house_sec_single_img'>
                                            <img src={gray_3} />
                                        </div>
                                        <div className='gray_house_sec_single_cont'>
                                            <div className='gray_house_teacher'>
                                                <img src={profile_1} />
                                                <span>
                                                    <h6>Rehan blackman</h6>
                                                    <p>Teacher</p>
                                                </span>
                                            </div>
                                            <div className='gray_house_teacher_cont_sec_new'>
                                                <h4>Science</h4>
                                                <h3>Fundamental of Neuroscience</h3>
                                                <div className='view_cose_det_sec'>
                                                    <button type='button' className='view_course_sec'>View Course</button>
                                                    <button type='button' className='enroll_btn_sec'>Enroll</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='gray_house_sec_single'>
                                        <div className='gray_house_sec_single_img'>
                                            <img src={gray_4} />
                                        </div>
                                        <div className='gray_house_sec_single_cont'>
                                            <div className='gray_house_teacher'>
                                                <img src={profile_1} />
                                                <span>
                                                    <h6>Rehan blackman</h6>
                                                    <p>Teacher</p>
                                                </span>
                                            </div>
                                            <div className='gray_house_teacher_cont_sec_new'>
                                                <h4>Science</h4>
                                                <h3>Fundamental of Neuroscience</h3>
                                                <div className='view_cose_det_sec'>
                                                    <button type='button' className='view_course_sec'>View Course</button>
                                                    <button type='button' className='enroll_btn_sec'>Enroll</button>
                                                </div>
                                            </div>
                                        </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TeacherProfileSetting;
