import React from "react";
import "./MaltipleAssignmentviewPopup.css";

function MaltipleAssignmentStaticContent() {

    return (
        <>  
            <div className="maltiple-assignment-static-content-container">
                <p>Instructions :</p>
                <ul className="maltiple-assignment-static-content-folder">
                    <li>Read the question carefully before selecting your answer.</li>
                    <li>Choose the letter corresponding to the option you believe is the correct answer.</li>
                    <li>You can only select one option per question.</li>
                    <li>There are no penalties for incorrect answers, so attempt all questions.</li>
                    <li>After completing the quiz, click the "Submit" button to view your score.</li>
                </ul>
            </div>
        </>
    );
}

export default MaltipleAssignmentStaticContent;

