import React from "react";
import imgs from "../../../../../../assets/images/imgs";
import { NavLink, useNavigate } from "react-router-dom";
import { addToHistory } from "../../../../../../state/reducers/historyReducer";
import { useDispatch } from "react-redux";
import { Chip } from "@mui/material";

const StudentCourseCard = ({ course }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isFavourite = course?.status === "Favourite";
  const isRequested = course?.status === "Pending";
  const isActive = course?.status === "Active";
  const isCompleted = course?.status === "Completed";

  const onGoToCourse = () => {
    if (isRequested) {
      navigate(`/course/${course?.course_id}/view`, {
        state: {
          course,
        },
      });
    } else {
      navigate(`/course/${course?.course_id}`, {
        state: {
          course,
          orderpathname: "activeCourse",
        },
      });
    }
    dispatch(addToHistory("/dashboard/my-courses/active-courses"));
  };

  return (
    <div className="StudentCourseCard-main-container">
      <div
        className="studentDashboar-Suggested-Courses-folder"
        id="studentCourseCard-folder"
      >
        <div
          className="studentDashboar-Suggested-Courses-image"
          onClick={onGoToCourse}
        >
          <img src={course?.image_url || imgs.jpg.course_demo_image} alt="" />
        </div>
        <div className="studentDashboar-Suggested-Courses-profile-folder">
          <>
            <NavLink to={`/teacher/${course?.teacher}`}>
              <div className="studentDashboar-Suggested-Courses-profile">
                <div className="studentDashboar-profile-image">
                  <img
                    src={course?.ai_avatar ?? imgs.png.tearcher_profile_image}
                    alt=""
                  />
                </div>
                <div className="studentDashboar-Suggested-Courses-profile-title">
                  <h2 className="tudentDashboar-profile-card-title">
                    {course.teacher_name}
                  </h2>
                  <p>Teacher</p>
                </div>
              </div>
            </NavLink>
            {!isRequested && (
              <div className="enroll-container">
                <div className="Enrolled-btn">
                  {/* {course?.source && course?.source === "Invited" && ( */}
                  <Chip label={"Enrolled"} />
                  {/* )} */}
                  {/* </div>
            <div className="Enrolled-btn"> */}
                  {/* {course?.source && course?.source === "Enrolled" && ( */}
                  {/* <Chip label={course?.source} /> */}
                  {/* )} */}
                </div>
                {/* Hidden for subdomain */}
                {/* {course?.rating_count > 0 && (
              <div className="studentDashboar-Suggested-Courses-profile-rate">
                {course?.average_rating > 0 && (
                  <>
                    <a href="#">
                      <i className="fa-solid fa-star"></i>
                    </a>
                    <p className="rate">
                      {course?.average_rating.toFixed(1)}
                    </p>
                  </>
                )}
                {course?.rating_count > 0 && (
                  <p className="number">({parseInt(course?.rating_count)})</p>
                )}
              </div>
            )} */}
                {/* Hidden for subdomain */}
              </div>
            )}
          </>
        </div>
        <p className="studentDashboar-profile-course-name">{course?.subject}</p>
        <h2
          className="studentDashboar-profile-course-title"
          onClick={onGoToCourse}
        >
          {course?.name}
        </h2>
        <div className="studentDashboar-profile-btn-folder">
          <button type="button" disabled={isRequested} onClick={onGoToCourse}>
            {isRequested && <i className="fa-solid fa-circle-check" />}
            {isRequested && "Request Sent"}
            {(isActive || isCompleted) && "Go to course"}
            {isFavourite && "View Course"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default StudentCourseCard;
