import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Graph {
    graph: any;
    isLoading: boolean;
    error: string | null;
}

const initialState: Graph = {
    graph: [],
    isLoading: false,
    error: null,
};

const adminGraphSlice = createSlice({
    name: "AdminGraph",
    initialState,
    reducers: {
        setAdminGraph: (state: Graph, action: PayloadAction<Graph>) => {
            state.graph = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: Graph, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: Graph, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetAdminGraph: () => {
            return initialState;
        },
    },
});

export const {
    setAdminGraph,
    setloading,
    seterror,
    resetAdminGraph,
} = adminGraphSlice.actions;

export default adminGraphSlice.reducer;
