import React from 'react';

import { CourseContextProvider } from "./context/CourseContext";

function CourseWrapper({ children }) {
  return (
    <CourseContextProvider>
    {children}
    </CourseContextProvider>
  );
}
export default CourseWrapper;
