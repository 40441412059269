import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TeacherCurrentSubscriptionPlan {
    isLoading: boolean;
    error: string | null;
    currentSubscription: any;
}

const initialState: TeacherCurrentSubscriptionPlan = {
    currentSubscription: null,
    isLoading: false,
    error: null,
};

const TeacherCurrentSubscriptionPlanSlice = createSlice({
    name: 'TeacherCurrentSubscriptionPlan',
    initialState,
    reducers: {
        setTeacherCurrentSubscriptionPlan: (state: TeacherCurrentSubscriptionPlan, action: PayloadAction<TeacherCurrentSubscriptionPlan>) => {
            state.currentSubscription = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: TeacherCurrentSubscriptionPlan, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: TeacherCurrentSubscriptionPlan, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetTeacherCurrentSubscriptionPlan: () => {
            return initialState;
        },
    },
});

export const { setTeacherCurrentSubscriptionPlan, setloading, seterror, resetTeacherCurrentSubscriptionPlan } = TeacherCurrentSubscriptionPlanSlice.actions;

export default TeacherCurrentSubscriptionPlanSlice.reducer;
