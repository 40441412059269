// Imports
import { Dispatch } from 'redux';
import axios from 'axios';
import {liveBaseURL} from "../../api/api";
import {
  setProceedToPay,
  setloading,
  seterror,
  resetProceedToPay,
} from '../reducers/coursePaymentReducer';

// Action Creator: proceedToPayData
const proceedToPayData = (token: string) => async (dispatch: Dispatch) => {
  dispatch(setloading(true));

  const config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${liveBaseURL}/orders/create-order/`,
    headers: {
      Authorization: `Token ${token}`,
      Cookie: 'Cookie_5=value',
    },
  };

  try {
    const response = await axios.request(config);
    console.log(JSON.stringify(response.data));

    dispatch(setloading(false));

    if (response.data?.status === 200) {
      dispatch(setProceedToPay(response.data));
    } else {
      dispatch(seterror(response.data?.message ?? "Something Went Wrong!"));
    }
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      dispatch(seterror(error.response.data.message ?? "An error occurred"));
    } else {
      dispatch(seterror("An unexpected error occurred"));
    }
    dispatch(setloading(false));
  }
};

// Exports
export { proceedToPayData, resetProceedToPay };
