import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface cancelAdminSubscription {
    isLoading: boolean;
    error: string | null;
    plan: any | null;
}

const initialState: cancelAdminSubscription = {
    plan: null,
    isLoading: false,
    error: null,
};


const cancelAdminSubscriptionSlice = createSlice({
    name: 'cancelAdminSubscription',
    initialState,
    reducers: {
        setCancelAdminSubscription: (state: cancelAdminSubscription, action: PayloadAction<cancelAdminSubscription>) => {
            state.plan = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: cancelAdminSubscription, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: cancelAdminSubscription, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetCancelAdminSubscription: () => {
            return initialState;
        },
    },
});

export const { setCancelAdminSubscription, setloading, seterror, resetCancelAdminSubscription } = cancelAdminSubscriptionSlice.actions;

export default cancelAdminSubscriptionSlice.reducer;










































