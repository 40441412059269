import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface LessonStats {
    lessonStats: any;
    next: string | null;
    previous: string | null;
    count: number | null;
    totalPages: number | null;
    isLoading: boolean;
    error: string | null;
}

const initialState: LessonStats = {
    lessonStats: [],
    next: null,
    previous: null,
    count: 0,
    totalPages: 0,
    isLoading: false,
    error: null,
};

const getlessonStatsSlice = createSlice({
    name: "lessonStats",
    initialState,
    reducers: {
        setLessonStats: (state: LessonStats, action: PayloadAction<LessonStats>) => {
            state.lessonStats = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        setPagination: (state: LessonStats, action: PayloadAction<LessonStats>) => {
            state.next = action.payload.next;
            state.previous = action.payload.previous;
            state.count = action.payload.count;
            state.totalPages = Math.ceil((action.payload.count ?? 0) / 12);
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: LessonStats, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: LessonStats, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetLessonStats: () => {
            return initialState;
        },
    },
});

export const {
    setLessonStats,
    setloading,
    seterror,
    setPagination,
    resetLessonStats,
} = getlessonStatsSlice.actions;

export default getlessonStatsSlice.reducer;
