import React from 'react'
import { useNavigate } from "react-router-dom";
import { upload_image } from "../../assets/images/images";
import { Switch } from "@mui/material";

function TeacherTable({ teachers }) {

    const navigate = useNavigate();
    const onteacherViewProfile = (tid) => {
        navigate(`/TeacherProfile/${tid}`);
    };

    return (
        <table className="Dashboard-Review-Card-table">
            <thead>
                <th></th>
                <th>Name</th>
                {/* <th></th> */}
                <th>Teacher ID</th>
                {/* <th>Grade</th> */}
                <th></th>
                <th></th>
            </thead>
            <tbody>
                {
                    teachers && teachers?.length > 0 ? teachers.map((data, index) => (
                        <>
                            <tr>
                                <td>
                                    <div className="Dashboard-Review-Card-image-folder" onClick={() => {
                                        onteacherViewProfile(data.tid);
                                    }}>
                                        <div className="Dashboard-Review-Card-table-image">
                                            <img src={data.ai_avatar ?? upload_image} alt="" />
                                        </div>
                                        {/* <div className="Dashboard-Review-Card-table-image-aword">
                                <img src={teacher_reviews_profile_aword} alt="" />
                            </div> */}
                                    </div>
                                    <div className="Dashboard-Review-Card-teacher-reviews">
                                        {/* <span><i class="fa-solid fa-star"></i></span> */}
                                        {/* <p>{teacher_reviews_star_number}</p> */}
                                    </div>
                                </td>
                                <td>
                                    <span>{data.name}</span>
                                </td>
                                {/* <td>
                                    <span>{data.school_name}</span>
                                </td> */}
                                <td>
                                    <span>{data.teacher_id}</span>
                                </td>
                                {/* <td>
                                    <span>{grade}</span>
                                </td> */}
                                <td>
                                    <button type="button" className="Dashboard-Review-Card-view-btn" onClick={() => {
                                        onteacherViewProfile(data.teacher_id);
                                    }}>View</button>
                                </td>
                                <td>
                                    {/* <Switch
                                    // {...label}
                                    // checked={isActive}
                                    // onChange={handleSwitchToggle}
                                    /> */}
                                </td>
                            </tr>
                            <div class="table_blank_row"></div>
                        </>
                    ))
                        : ""
                }
            </tbody>
        </table>
    )
}

export default TeacherTable