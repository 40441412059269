import { Dispatch } from "redux";
import api from "../../api/api";
import {
    setaddSchoolAdmin, setloading, seterror, resetaddSchoolAdmin
} from "../reducers/addSchoolAdminReducer";

const addSchoolAdminProfile = (data: any, token: any) => async (dispatch: Dispatch) => {
    dispatch(setloading(true));
    try {
        const result = await api
            .post(
                `school-admin/school/create-school-admin/`,
                data,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Token ${token}`
                    },
                }
            )

        if (result) {
            dispatch(setloading(false));
            if (result.data?.status === 200) {
                dispatch(setaddSchoolAdmin(result.data));
            }
            else {
                dispatch(seterror(result.data?.message ?? "Something Went Wrong!"));
            }
        }
    }
    catch (error: any) {
        dispatch(seterror(error.response.data.message));
    }
}

export { addSchoolAdminProfile, resetaddSchoolAdmin }

