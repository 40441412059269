import { Dispatch } from "redux";
import api from "../../api/api";
import {
    resetLetestTeacher, setLetestTeacherlist,setStudentBySchool, seterror, setloading
} from "../reducers/getLetestTeacherReducer";

const  LetestTeacher = ( token: any,type:any,id: any) => async (dispatch: Dispatch) => {
   dispatch(setloading(true));
   const url = type === 'Teacher' ? `school-admin/school/recent-techer-list/${id}/` : `school-admin/school/recent-student-list/${id}/`;
   try {
     const result = await api
       .get(url
        ,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`

          },
        }
       )
       if(result){
        dispatch(setloading(false));
       if (result?.status === 200) {
        if (type === 'Teacher') {
            dispatch(setLetestTeacherlist(result.data));
        } else {
            dispatch(setStudentBySchool(result.data));
        }
      }
      else {
        dispatch(seterror(result.data?.message ?? "Something Went Wrong!"));
      }
   }
   }
   catch (error: any) {
    dispatch(seterror(error.response.data.message));
   }
}


export { LetestTeacher, resetLetestTeacher };

