import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DataList {
    dataList: any,
    isLoading: boolean;
    error: string | null;
}

const initialState: DataList = {
    dataList: {},
    isLoading: false,
    error: null,
};

const dataListSlice = createSlice({
    name: "getdatalist",
    initialState,
    reducers: {
        setDataList: (state: DataList, action: PayloadAction<DataList>) => {
            state.dataList = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: DataList, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: DataList, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetDataList: () => {
            return initialState;
        },
    },
});

export const {
    setDataList,
    setloading,
    seterror,
    resetDataList,
} = dataListSlice.actions;

export default dataListSlice.reducer;
