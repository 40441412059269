import axios from "axios";
const localBaseURL = "http://13.232.24.20:4000";
const liveBaseURL = process.env.REACT_APP_BACKEND_APP_URL;

// Set the base URL for your API
const apiURL = liveBaseURL;
const shareUrl = process.env.REACT_APP_SHARE_URL;
const websocketURL = process.env.REACT_APP_WEBSOCKET_APP_URL;
// console.log(apiURL)
const baseURL = `${apiURL}/`;

const wsURL = `${websocketURL}/`;
//'http://mycro.news:4000';

const instance = axios.create({
  baseURL,
});

// also export the base URL
export { baseURL, wsURL, liveBaseURL, shareUrl };
export default instance;
