import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface StudentState {
    student: any,
    isLoading: boolean;
    error: string | null;
}

const initialState: StudentState = {
    student: {},
    isLoading: false,
    error: null,
};

const studentDetailsSlice = createSlice({
    name: "studentDetails",
    initialState,
    reducers: {
        setstudentDetails: (state: StudentState, action: PayloadAction<StudentState>) => {
            state.student = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: StudentState, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: StudentState, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetstudentDetails: () => {
            return initialState;
        },
    },
});

export const {
    setstudentDetails,
    setloading,
    seterror,
    resetstudentDetails,
} = studentDetailsSlice.actions;

export default studentDetailsSlice.reducer;
