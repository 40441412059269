import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface teacherRatingReply {
    reply: any;
    isLoading: boolean;
    error: string | null;
  }

  const initialState: teacherRatingReply = {
    reply: null,
    isLoading: false,
    error: null,
  };

  const teacherratingreplyslice = createSlice({
    name: "teacherRatingReply",
    initialState,
    reducers: {
      setteacherRatingReply: (
        state: teacherRatingReply,
        action: PayloadAction<teacherRatingReply>
      ) => {
        state.reply = action.payload;
        state.isLoading = false;
        state.error = null;
      },
      setloading: (state: teacherRatingReply, action: PayloadAction<boolean>) => {
        state.isLoading = action.payload;
        state.error = null;
      },
      seterror: (state: teacherRatingReply, action: PayloadAction<string>) => {
        state.error = action.payload;
        state.isLoading = false;
      },
      resetteacherRatingReply: () => {
        return initialState;
      },
    },
  });

  export const {
    setteacherRatingReply,
    setloading,
    seterror,
    resetteacherRatingReply,
  } = teacherratingreplyslice.actions;
  
  export default teacherratingreplyslice.reducer;
  