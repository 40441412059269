import React, { useRef, useState } from "react";
import "./LessonGenerator.css";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { Tooltip } from "react-tooltip";
import { ass_img, go_btn, Calimg, promote_suggention_star } from "../../assets/images/images";
import { FormControl, TextField } from "@mui/material";
import AWSS3FileUpload from "../AWSFileUpload/AWSS3FileUpload";
import { Button } from "react-scroll";
import FileUpload from "../FileUploadToS3/FileUpload";
import { AttachFileOutlined } from "@mui/icons-material";
function LessonGenratorChatBoard({ toggleChatBoard }) {
const fileInputRef = useRef(null);
    const [isListening, setIsListening] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploadedFileLink, setuploadedFileLink] = useState([]);
    const [showUploadPop, setShowUploadPop] = useState(false);
    
    const showUploadPopupcall = () => {
        if (showUploadPop) {
          setShowUploadPop(false);
        } else {
          setShowUploadPop(true);
        }
      };

    const handleButtonClick = () => {
        setIsListening(!isListening);
    };


    return (
        <>
            <div className="lesson_generator_body_sec_left_body">
                <span className="lesson-genrator-chatBoard-close-btn" onClick={toggleChatBoard}><i class="fa-solid fa-x"></i></span>
                <div className="lesson_generator_body_sec_left_text_part">
                    <h5>
                        <img src={ass_img} alt="" /> Hello Polly, I will
                        assist you to create amazing lesson for your course.
                    </h5>
                </div>
\

                <div className="chat-with-ai-lession-container">
                    <div>
                        <div className="chat-with-ai-lession-user-folder">
                            <div className="chat-with-ai-lession-user-sned-massege-container">
                                {/* {chat?.file?.length > 0 ? (
                                        chat?.file.map((file, index) => {
                                            return (
                                                <ListItem
                                                    key={index}
                                                    sx={{
                                                        backgroundColor: "white",
                                                        borderRadius: "6px",
                                                        marginBottom: "8px",
                                                        height: "48px",
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        <img
                                                            src={getFileIcon(
                                                                file?.original_name
                                                            )}
                                                            alt=""
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={file?.original_name}
                                                        secondary={getFileType(
                                                            file?.original_name
                                                        )}
                                                    />
                                                </ListItem>
                                            );
                                        })
                                    ) : (
                                        <></>
                                    )} */}
                                <p className="text-massege-user">generate a lesson on testng</p>
                            </div>
                            <div className="send-user-name">
                                <h2>T</h2>
                            </div>
                        </div>
                        <div className="chat-with-ai-lession-user-folder chat-with-ai-lession-AI-message">
                            <div className="send-user-name">
                                <h2>AI</h2>
                            </div>
                            {/* {chat.answer === "" && (
                                    <>
                                        <div className="loading-chat-answer">
                                            <Waveform />
                                        </div>
                                    </>
                                )} */}
                            <div className="read-chat-container">
                                <div className="chat-with-ai-lession-user-sned-massege-container">
                                    {/* <PreprocessAndRenderLatex
                                            content={chat.answer}
                                            isBlockMath={true}
                                        /> */}
                                    <p>Lesson on TestNG
                                        Introduction to TestNG
                                        TestNG is a testing framework inspired by JUnit and NUnit but introduces new functionalities that make it more powerful and easier to use.
                                        TestNG is designed to cover all categories of tests: unit, functional, end-to-end, integration, etc. It is widely used in the Java programming ecosystem
                                        for test automation.
                                        Lesson on TestNG
                                        Introduction to TestNG
                                        TestNG is a testing framework inspired by JUnit and NUnit but introduces new functionalities that make it more powerful and easier to use.
                                        TestNG is designed to cover all categories of tests: unit, functional, end-to-end, integration, etc. It is widely used in the Java programming ecosystem
                                        for test automation.
                                        Lesson on TestNG
                                        Introduction to TestNG
                                        TestNG is a testing framework inspired by JUnit and NUnit but introduces new functionalities that make it more powerful and easier to use.
                                        TestNG is designed to cover all categories of tests: unit, functional, end-to-end, integration, etc. It is widely used in the Java programming ecosystem
                                        for test automation.
                                        Lesson on TestNG
                                        Introduction to TestNG
                                        TestNG is a testing framework inspired by JUnit and NUnit but introduces new functionalities that make it more powerful and easier to use.
                                        TestNG is designed to cover all categories of tests: unit, functional, end-to-end, integration, etc. It is widely used in the Java programming ecosystem
                                        for test automation.
                                        Lesson on TestNG
                                        Introduction to TestNG
                                        TestNG is a testing framework inspired by JUnit and NUnit but introduces new functionalities that make it more powerful and easier to use.
                                        TestNG is designed to cover all categories of tests: unit, functional, end-to-end, integration, etc. It is widely used in the Java programming ecosystem
                                        for test automation.
                                        Lesson on TestNG
                                        Introduction to TestNG
                                        TestNG is a testing framework inspired by JUnit and NUnit but introduces new functionalities that make it more powerful and easier to use.
                                        TestNG is designed to cover all categories of tests: unit, functional, end-to-end, integration, etc. It is widely used in the Java programming ecosystem
                                        for test automation.
                                        Lesson on TestNG
                                        Introduction to TestNG
                                        TestNG is a testing framework inspired by JUnit and NUnit but introduces new functionalities that make it more powerful and easier to use.
                                        TestNG is designed to cover all categories of tests: unit, functional, end-to-end, integration, etc. It is widely used in the Java programming ecosystem
                                        for test automation.
                                        Lesson on TestNG
                                        Introduction to TestNG
                                        TestNG is a testing framework inspired by JUnit and NUnit but introduces new functionalities that make it more powerful and easier to use.
                                        TestNG is designed to cover all categories of tests: unit, functional, end-to-end, integration, etc. It is widely used in the Java programming ecosystem
                                        for test automation.
                                        Lesson on TestNG
                                        Introduction to TestNG
                                        TestNG is a testing framework inspired by JUnit and NUnit but introduces new functionalities that make it more powerful and easier to use.
                                        TestNG is designed to cover all categories of tests: unit, functional, end-to-end, integration, etc. It is widely used in the Java programming ecosystem
                                        for test automation.
                                        Lesson on TestNG
                                        Introduction to TestNG
                                        TestNG is a testing framework inspired by JUnit and NUnit but introduces new functionalities that make it more powerful and easier to use.
                                        TestNG is designed to cover all categories of tests: unit, functional, end-to-end, integration, etc. It is widely used in the Java programming ecosystem
                                        for test automation.
                                        Lesson on TestNG
                                        Introduction to TestNG
                                        TestNG is a testing framework inspired by JUnit and NUnit but introduces new functionalities that make it more powerful and easier to use.
                                        TestNG is designed to cover all categories of tests: unit, functional, end-to-end, integration, etc. It is widely used in the Java programming ecosystem
                                        for test automation.
                                        Lesson on TestNG
                                        Introduction to TestNG
                                        TestNG is a testing framework inspired by JUnit and NUnit but introduces new functionalities that make it more powerful and easier to use.
                                        TestNG is designed to cover all categories of tests: unit, functional, end-to-end, integration, etc. It is widely used in the Java programming ecosystem
                                        for test automation.
                                    </p>
                                </div>
                                <div className="copy-past-text-container">
                                    <div className="play-pause-btn-lesson-generator">
                                        {/* {chatAudioId === chat.uuid && texttospeech.isLoading ?
                                                <LoaderComponent /> :
                                                chatAudioId === chat.uuid && chatAudioId === texttospeech.generatorId && texttospeech.success ?
                                                    <audio controls src={texttospeech.voice.audio_url} />
                                                    :
                                                    <i className="fa-solid fa-circle-play"
                                                        onClick={() => generateAIVoice(chat.answer, chat.uuid)}
                                                    ></i>
                                            } */}
                                        <i className="fa-solid fa-circle-play"
                                        // onClick={() => generateAIVoice(chat.answer, chat.uuid)}
                                        ></i>
                                    </div>
                                    <i
                                        data-tooltip-id="copy-data"
                                        // onClick={() => onCopyClicked(chat)}
                                        className={"fa-regular fa-clipboard"}
                                    ></i>
                                    < i
                                        // onClick={() => onArrowRightClicked(chat)}
                                        className="fa-solid fa-arrow-right"></i>
                                    {/* <UpdatePlugin index={index} chat={chat} /> */}
                                    <Tooltip
                                        id="copy-data"
                                        place="bottom"
                                        content="Copy"
                                        type="light"
                                        effect="float"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div ref={messagesEndRef} /> */}
                </div>

                <div className="ganrate-lesson-text-feild-wrapper">
                    <div className="pdf_box_sec">
                        {/* <div className="pdf_box_sec_inner">
                                <AWSS3FileDelete
                                    selectedFiles={selectedFiles}
                                    setSelectedFiles={setSelectedFiles}
                                    getFileIcon={getFileIcon}
                                    uploadedFileLink={uploadedFileLink}
                                    setuploadedFileLink={setuploadedFileLink}
                                    progressTracker={progressTracker}
                                    setprogressTracker={setprogressTracker}
                                />
                            </div> */}
                        <form action="">
                            {/* <div
                                    className={`create-Assignment-container-popup 
                                         ${checkAssignmentopen ? "" : isPopupVisible ? "show" : ""}
                                        `}
                                    style={{ padding: `${checkAssignmentopen ? "" : isPopupVisible ? "10px" : "0px"}`,}}
                                > */}
                            <div
                                className={`create-Assignment-container-popup show`}
                                style={{ padding: "10px" }}
                            >
                                <div className="create-Assignment-container-popup-folder1">
                                    <div className="assign_main_box_left_single create-Assignment-input-feild">
                                        <label>Assignment Name</label>
                                        <FormControl
                                            margin="0px"
                                            fullWidth
                                        // error={!!formErrorAssignmentState.name}
                                        >
                                            <TextField
                                                name="name"
                                                size="small"
                                                defaultValue={""}
                                                placeholder="E.g. Neurons"
                                                className="custom-text-field"
                                                variant="outlined"
                                                // value={formAssignmentState.name}
                                                // // value={'hello'}
                                                // error={!!formErrorAssignmentState.name}
                                                // onChange={handleAssignmentInputChange}
                                                InputProps={{
                                                    sx: {
                                                        borderRadius: "6px",
                                                        backgroundColor: "#FFFFFF",
                                                        fontSize: "10px",
                                                        height: "35px",
                                                    },
                                                }}
                                            />
                                            {/* <FormHelperText sx={{ color: "#d31332" }}>
                                                    {formErrorAssignmentState.name}
                                                </FormHelperText> */}
                                        </FormControl>
                                    </div>
                                    <div className="Create-Course-inner-input Create-Assignment-dropdown">
                                        <label>Assignment timer</label>
                                        <FormControl margin="none">
                                            <div className="time-field">
                                                <button
                                                // onClick={(e) => {
                                                //     e.preventDefault();
                                                //     assignmentDurationChangeTime(-5);
                                                //     setAssignmentDurationCount(assignmentDurationCount > 0 ? assignmentDurationCount - 1 : 0)
                                                // }}
                                                >
                                                    -
                                                </button>
                                                <span>
                                                    {/* {`${assignmentDuration.minutes
                                                            .toString()
                                                            .padStart(2, "0")} Minute${assignmentDuration.minutes?.toString() >
                                                                1
                                                                ? "s"
                                                                : ""
                                                            }`} */}
                                                    00 Minute
                                                </span>
                                                <button
                                                // onClick={(e) => {
                                                //     e.preventDefault();
                                                //     assignmentDurationChangeTime(5);
                                                //     setAssignmentDurationCount(Number(assignmentDurationCount) + 1)
                                                // }}
                                                >
                                                    +
                                                </button>
                                            </div>

                                            {/* <FormHelperText sx={{ color: "#d31332" }}>
                                                    {
                                                        formErrorAssignmentState.duration_minutes
                                                    }
                                                </FormHelperText> */}
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="create-Assignment-container-popup-folder2">
                                    <div className="assign_main_box_right_date">
                                        <label>Start Date</label>
                                        <FormControl
                                            margin="none"
                                            fullWidth
                                        // error={
                                        //     !!formErrorAssignmentState.start_date
                                        // }
                                        >
                                            <img
                                                src={Calimg}
                                                className="cal_img_sec_assignment"
                                                alt=""
                                            />
                                            <Datetime
                                                // isValidDate={
                                                //     isValidDateAssignmentStartDate
                                                // }
                                                // min={minDate}
                                                timeFormat={false}
                                                inputProps={{
                                                    placeholder: "MM:DD:YYYY",
                                                    readOnly: true,
                                                }}
                                                closeOnSelect={true}
                                                dateFormat="MMM DD, YYYY"
                                                placeholder="E.g. Sep 10, 2023"
                                                name="start_date"
                                                // value={
                                                //     formAssignmentState.start_date
                                                //         ? dayjs(
                                                //             formAssignmentState.start_date
                                                //         ).format("MMM DD, YYYY")
                                                //         : ""
                                                // }
                                                // onChange={(date) =>
                                                //     handleAssignmentInputChangedate(
                                                //         "start_date",
                                                //         date
                                                //     )
                                                // }
                                                className="custom-datetime my-custom-picker"
                                            />
                                            {/* <FormHelperText sx={{ color: "#d31332" }}>
                                                    {formErrorAssignmentState.start_date}
                                                </FormHelperText> */}
                                        </FormControl>
                                    </div>
                                    <div className="assign_main_box_right_date">
                                        <label>Expiry Date</label>
                                        <FormControl
                                            margin="none"
                                            fullWidth
                                        // error={
                                        //     !!formErrorAssignmentState.start_date
                                        // }
                                        >
                                            <img
                                                src={Calimg}
                                                className="cal_img_sec_assignment"
                                                alt=""
                                            />

                                            <Datetime
                                                // isValidDate={
                                                //     isValidDateAssignmentEndDate
                                                // }
                                                // min={dayjs(
                                                //     formAssignmentState?.start_date
                                                //         ? formState.start_date
                                                //         : formAssignmentState?.start_date
                                                // )
                                                //     .add(1, "day")
                                                //     .format("MMM DD, YYYY")}
                                                timeFormat={false}
                                                inputProps={{
                                                    placeholder: "MM:DD:YYYY",
                                                    readOnly: true,
                                                }}
                                                // closeOnSelect={true}
                                                dateFormat="MMM DD, YYYY"
                                                placeholder="E.g. Sep 10, 2023"
                                                name="end_date"
                                                // value={formAssignmentState.end_date === null ? '' : dayjs(formAssignmentState.end_date).format("MMM DD, YYYY")}
                                                // onChange={(date) =>
                                                //     handleAssignmentInputChangedate(
                                                //         "end_date",
                                                //         date
                                                //     )
                                                // }
                                                className="custom-datetime my-custom-picker"
                                            // initialViewDate={dayjs(
                                            //     formAssignmentState?.start_date ??
                                            //     formState.start_date
                                            // )
                                            //     .add(1, "day")
                                            //     .toDate()}
                                            />
                                            {/* <FormHelperText sx={{ color: "#d31332" }}>
                                                    {formErrorAssignmentState.end_date}
                                                </FormHelperText> */}
                                        </FormControl>
                                    </div>
                                    <div className="Create-Course-inner-input Create-Assignment-dropdown create-Assignment-popup-input-feild">
                                        <label>Choose Assignment type</label>
                                        <FormControl margin="none">
                                            <TextField
                                                name="type"
                                                defaultValue={"ddasas"}
                                                // value={formAssignmentState.type}
                                                placeholder="Select Assignment type"
                                                variant="outlined"
                                                select
                                                size="small"
                                                // error={!!formErrorAssignmentState.type}
                                                // onChange={handleAssignmentInputChange}
                                                InputProps={{
                                                    sx: {
                                                        borderRadius: "6px",
                                                        backgroundColor: "#FFFFFF",
                                                        height: "35px",
                                                        padding: "0px",
                                                    },
                                                }}
                                            >
                                                {/* {Object.values(AssignmentType).map(
                                                        (type) => (
                                                            <MenuItem
                                                                key={type.name}
                                                                value={type.value}
                                                            >
                                                                {type.name}
                                                            </MenuItem>
                                                        )
                                                    )} */}
                                            </TextField>
                                            {/* <FormHelperText sx={{ color: "#d31332" }}>
                                                    {formErrorAssignmentState.type}
                                                </FormHelperText> */}
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <div className="promote-suggention-wrapper">
                                <div className="promote-suggention-folder">
                                    <div className="promote-suggention-star">
                                        <img src={promote_suggention_star}/>
                                    </div>
                                    <p>Make it shorter</p>
                                </div>
                                <div className="promote-suggention-folder">
                                    <div className="promote-suggention-star">
                                        <img src={promote_suggention_star}/>
                                    </div>
                                    <p>Make it simple</p>
                                </div>
                                <div className="promote-suggention-folder">
                                    <div className="promote-suggention-star">
                                        <img src={promote_suggention_star}/>
                                    </div>
                                    <p>Make it longer</p>
                                </div>
                                <div className="promote-suggention-folder">
                                    <div className="promote-suggention-star">
                                        <img src={promote_suggention_star}/>
                                    </div>
                                    <p>Give me more options</p>
                                </div>
                            </div>
                            
                            <div className="promote_part_sec">
                                <FileUpload
                                    showUploadPop={showUploadPop}
                                    setShowUploadPop={setShowUploadPop}
                                    selectedFiles={selectedFiles}
                                    setSelectedFiles={setSelectedFiles}
                                    setuploadedFileLink={setuploadedFileLink}
                                    fileInputRef={fileInputRef}
                                />
                                <TextField
                                    // ref={textBoxRef}
                                    id="lesson_promote_flexible"
                                    name="chat_query"
                                    label=""
                                    variant="standard"
                                    placeholder={`Write all your prompts here to create`}
                                    fullWidth
                                    multiline
                                InputProps={{
                                    disableUnderline: true,
                                    startAdornment: (
                                        // <AWSS3FileUpload
                                        //     selectedFiles={selectedFiles}
                                        //     setSelectedFiles={setSelectedFiles}
                                        //     setuploadedFileLink={
                                        //         setuploadedFileLink
                                        //     }
                                        //     disabled={false}
                                        // />

                                        <div>
                                            <AttachFileOutlined
                                            className="uplode-icon-genrator"
                                            onClick={showUploadPopupcall}
                                            style={{cursor: 'pointer'}}
                                            />
                                            </div>
                                    ),

                                    
                                    // endAdornment: (
                                    //     <>
                                    //         {
                                    //             showCloseBTN && <> <button onClick={closeConnection} className="genrater-close-btn"><i class="fa-regular fa-circle-stop"></i></button> </>
                                    //         }
                                    //         <button type="button" id='mic-btn' className={`mic ${isListening ? 'listening' : ''}`} onClick={() => { startStopListening() }}>
                                    //             <i className={`fa-solid fa-microphone ${isListening ? 'hidden' : ''}`}></i>
                                    //             <div className={`boxContainer ${isListening ? '' : 'hidden'}`}>
                                    //                 <div className="box box1"></div>
                                    //                 <div className="box box2"></div>
                                    //                 <div className="box box3"></div>
                                    //             </div>
                                    //         </button>
                                    //         <Tooltip
                                    //             id="speack-mic"
                                    //             place="bottom"
                                    //             content="Press Alt to unmute Mic"
                                    //             type="light"
                                    //             effect="float"
                                    //         />

                                    //         <button
                                    //             type="submit"
                                    //             disabled={
                                    //                 isListening || !formState.chat_query ||
                                    //                 formState.chat_query === null ||
                                    //                 formState.chat_query === "" || // Condition 2
                                    //                 selectedFiles.length !==
                                    //                 progressTracker.length ||
                                    //                 !progressTracker.every(
                                    //                     (obj) =>
                                    //                         obj.status === "Completed"
                                    //                 ) // Condition 3
                                    //             }
                                    //             className="go_cls_btn"
                                    //         >
                                    //             <img src={go_btn} alt="" />
                                    //         </button>

                                    //     </>
                                    // ),
                                }}
                                 maxRows={5}
                                // value={
                                //     isListening
                                //         ? formState.chat_query ? (`${formState.chat_query}${transcript ? ` ${transcript}` : ''}`) : transcript
                                //         : formState.chat_query
                                // }
                                // disabled={isListening}
                                // onChange={handleInputKeyPress}
                                // onKeyDown={handleInputKeyPress}
                                />
                                <button type="button" className="genrater-close-btn"><i class="fa-regular fa-circle-stop"></i></button>
                                <button type="button" id="mic-btn" className={`mic ${isListening ? 'listening' : ''}`} onClick={handleButtonClick}>
                                    <i className={`fa-solid fa-microphone ${isListening ? 'hidden' : ''}`}></i>
                                    <div className={`boxContainer ${isListening ? '' : 'hidden'}`}>
                                        <div className="box box1"></div>
                                        <div className="box box2"></div>
                                        <div className="box box3"></div>
                                    </div>
                                </button>
                                <button
                                    type="submit"
                                    // disabled={
                                    //     isListening || !formState.chat_query ||
                                    //     formState.chat_query === null ||
                                    //     formState.chat_query === "" || // Condition 2
                                    //     selectedFiles.length !==
                                    //     progressTracker.length ||
                                    //     !progressTracker.every(
                                    //         (obj) =>
                                    //             obj.status === "Completed"
                                    //     ) // Condition 3
                                    // }
                                    className="go_cls_btn"
                                >
                                    <img src={go_btn} alt="" />
                                </button>
                                {/* <Tooltip
                                        id="my-tooltip-click"
                                        content="Prompt here"
                                        events={["click"]}
                                    /> */}
                            </div>
                        </form>
                    </div>
                </div>
                <p className="AI-disclamer">
                    AI can make mistakes. Consider checking important
                    information
                </p>
            </div>
        </>
    );
}

export default LessonGenratorChatBoard;


