import { Dispatch } from "redux";
import api from "../../api/api";
// import {
//     setindividualProfile, setloading, seterror, resetindividualProfile
// } from "../reducers/addIndividualProfileReducer";

const updateSchoolId = (data: any,token:any) => async (dispatch: Dispatch) => {
//    dispatch(setloading(true));
   try {
     const result = await api
       .put(
        `auth/update-school-id/`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`
          },
        }
       )
       
       if(result){
        // dispatch(setloading(false));
        if (result.data?.status === 200) {
            // dispatch(setindividualProfile(result.data));
          }
          else {
            // dispatch(seterror(result.data?.message ?? "Something Went Wrong!"));
          }
       }
   }
   catch (error: any) {
    // dispatch(seterror(error.response.data.message));
   }
}

export {updateSchoolId};

// export { addindividualProfile, resetindividualProfile} 

