// GetCountryCode.js

import axios from "axios";

export default async function GetCountryCodeViaIp() {
  return await axios
    .get("https://ipapi.co/json/")
    .then((response) => {
      const locationData = {
        country_name: response?.data?.country_name,
        country_code: response?.data?.country_code,
        country_code_iso3: response?.data?.country_code_iso3,
        country_calling_code: response?.data?.country_calling_code,
        currency: response?.data?.currency,
        currency_name: response?.data?.currency_name,
        region: response?.data?.region,
        timezone: response?.data?.timezone,
        city: response?.data?.city,
      };
      return locationData;
    })
    .catch((error) => {
      console.error("Error fetching IP information:", error);
      return {};
    });
}
