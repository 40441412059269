import StudentAssignmentPreview from "../student/lessons/StudentAssignmentPreview";
import { useSelector } from 'react-redux';
import TeacherAssignmentPreview from "../teacher/lessons/TeacherAssignmentPreview";


function AssignmentPreview() {
    const role = useSelector((state) => state.globalState.role);
    return role === 'Student' ? <StudentAssignmentPreview /> : <TeacherAssignmentPreview />;
}

export default AssignmentPreview;