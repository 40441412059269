import { Dispatch } from "redux";
import api from "../../api/api";
import {
    setuserActivePlan, setloading, seterror, resetUserActivePlan
} from "../reducers/getUserActivePlanReducer";


const userActivePlan = (token: any) => async (dispatch: Dispatch) => {
    dispatch(setloading(true));
    try {
        const result = await api
            .get(`subscriptions/my-subscription/`
                , {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Token ${token}`
                    },
                }
            )

        if (result) {
            // console.warn(result);
            dispatch(setloading(false));
            if (result.data?.status === 200) {
                dispatch(setuserActivePlan(result.data.data));
            }
            else {
                dispatch(seterror(result.data?.message ?? "Something Went Wrong!"));
            }
        }
    }
    catch (error: any) {
        dispatch(seterror(error.response.data.message));
    }
}

export { userActivePlan, resetUserActivePlan }
































