import { Dispatch } from "redux";
import api from "../../api/api";
import {
    setindividualProfile, setloading, seterror, resetindividualProfile
} from "../reducers/addIndividualProfileReducer";

const addindividualProfile = (data: any,token:any) => async (dispatch: Dispatch) => {
   dispatch(setloading(true));
   try {
     const result = await api
       .post(
        `school-admin/school/individual/`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`
          },
        }
       )
       
       if(result){
        dispatch(setloading(false));
        if (result.data?.status === 200) {
            dispatch(setindividualProfile(result.data));
          }
          else {
            dispatch(seterror(result.data?.message ?? "Something Went Wrong!"));
          }
       }
   }
   catch (error: any) {
    dispatch(seterror(error.response.data.message));
   }
}

export { addindividualProfile, resetindividualProfile} 

