import React from 'react';
import HomeNavbar from '../pages/home/components/HomeNavbar';
const Features = () => {
    return (
        <div className="features">
            <HomeNavbar />
            <h1>Features</h1>
        </div>
    );
};

export default Features;
