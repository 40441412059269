import api from "../api/api";

const getChatHistory = async ({ token, student_id, teacher_id }) => {
  const payload = {
    student_user_id: student_id,
    teacher_user_id: teacher_id,
  };
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${token}`,
  };
  try {
    const response = await api.post(
      `ai/api/student_chat_history/`,
      payload,
      headers
    );
    if (response.status === 200 || response.status === 201) {
      const getPastHistory = response?.data?.data?.map((item) => {
        return {
          // uuid: item?.uuid,
          query: item?.query,
          answer: item?.answer.replace(/<STARTOFTURN>|<ENDOFTURN>/g, ""),
          // file: item?.file_uploads,
        };
      });
      return getPastHistory;
    }
  } catch (error) {
    console.log(error);
  }
};

export { getChatHistory };
