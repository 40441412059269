import api from "../api/api";

const createChatRoom = async ({ token, teacher_id, student_id }) => {
  const payload = {
    user_id: student_id,
    against_user_id: teacher_id,
  };
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${token}`,
  };
  try {
    const response = await api.post(
      `ai/api/create_chat_room/`,
      payload,
      headers
    );
    if (response.status === 200 || response.status === 201) {
      return response?.data?.room_id;
    } else {
      return null;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

export { createChatRoom };
