import React, { useState } from "react";

const FilterPopup = ({
  assignmentStatus,
  score,
  isVisiblefillter,
  handleApplyClick,
  handleAssignmentStatusChange,
  fillterstatus,
  fillterScore,
  handleScoreChange,
}) => {
  return (
    <>
      {isVisiblefillter && (
        <div className="sort-popup-assignprocess">
          <h3>Filter By:</h3>
          {fillterstatus && fillterstatus.length > 0 && (
            <div>
              <label>Status:</label>
              <select
                value={assignmentStatus}
                onChange={handleAssignmentStatusChange}
              >
                <option value="">All</option>
                {fillterstatus?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          )}
          {fillterScore && fillterScore.length > 0 && (
            <div>
              <label>Score:</label>
              <select value={score} onChange={handleScoreChange}>
                <option value="">All</option>
                {fillterScore?.map((item) => (
                  <option key={item.id} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          )}

          <button
            className="apply-button-assignprocess"
            onClick={handleApplyClick}
          >
            Apply
          </button>
        </div>
      )}
    </>
  );
};

export default FilterPopup;
