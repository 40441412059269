import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AddSchoolAdmin {
    isLoading: boolean;
    error: string | null;
    message: string | null;
}

const initialState: AddSchoolAdmin = {
    message: null,
    isLoading: false,
    error: null,
};

const addSchoolAdminSlice = createSlice({
    name: 'addSchoolAdmin',
    initialState,
    reducers: {
        setaddSchoolAdmin: (state: AddSchoolAdmin, action: PayloadAction<AddSchoolAdmin>) => {
            state.message = action.payload.message;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: AddSchoolAdmin, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: AddSchoolAdmin, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetaddSchoolAdmin: () => {
            return initialState;
        },
    },
});

export const { setaddSchoolAdmin, setloading, seterror, resetaddSchoolAdmin } = addSchoolAdminSlice.actions;

export default addSchoolAdminSlice.reducer;
