import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import CourseCard from "../../../components/CourseCard/CourseCard";
import {
    course_bg_image,
    course_info_image,
    search,
} from "../../../assets/images/images";
import { ReactSVG } from "react-svg";
import { useAuth } from "../../../features/auth/AuthContext";
import {
    getCourseBySchoolId,
    resetCourseListBySchoolId,
} from "../../../state/actions/getCourseListBySchoolIDAction";
import PaginationView from "../../../components/Pagination/PaginationView";

const CoursesList = ({ currentPage, setCurrentPage }) => {
    const { token } = useAuth();
    const dispatch = useDispatch();
    const { role } = useSelector((state) => state.globalState);
    const { courses, count, next, previous, totalPages, error } = useSelector(
        (state) => state.allcoursesList
    );
    const globalState = useSelector((state) => state.globalState);
    // const token = "e8e92304ad0b1e4370334cb4940e912b76eda727";
    const school_id = globalState.school_id;

    // const getCourseBySchoolIdRequest = () => {
    //     dispatch(getCourseBySchoolId(role, school_id, token));
    // };

    // useEffect(() => {
    //     getCourseBySchoolIdRequest();
    // }, []);

    // useEffect(() => {
    //     return () => {
    //         dispatch(resetCourseListBySchoolId());
    //     };
    // }, []);

    return (
        <>
            <div className="CoursesDashboard-section">
                <div className="CoursesDashboard-wrapper">
                    {/* <div className="search_header-container"> */}
                    {/* <div className="search-list">
                            <ReactSVG className="search-icon" src={search} />
                            <input
                                type="text"
                                className="search-input"
                                placeholder="Search"
                            />
                        </div> */}
                    {/* <div className="filter-wrapper">
                            <div className="lessonviewprogress-filder-tab">
                                <span>
                                    <i className="fa-solid fa-sliders"></i>
                                </span>
                                <p>Filter</p>
                            </div> */}
                    {/* <div className="lessonviewprogress-filder-tab">
                <span>
                  <i className="fa-solid fa-sliders"></i>
                </span>
                <p>Sort</p>
              </div> */}
                    {/* </div> */}
                    {/* </div> */}
                    <div className="School_Course_Card_container">
                        {courses?.map((data, index) => (
                            <CourseCard
                                key={index}
                                courseimage={data.image}
                                coursename={data.name}
                                totalstudents={data.students_enrolled}
                                totalteachers="1"
                                lesson_count={data.lessons_count}
                                start_date={data.start_date}
                                end_date={data.end_date}
                                description={data.description}
                                course_info_image={data.course_info_image}
                                Teacher_title="Teacher"
                                Teacher_name={data.teacher_name}
                                createdcourse="2024-01-20"
                                schooljoined="0"
                                course_details={data}
                            // Sales_Courses_title={data.Sales_Courses_title}
                            // checkbox={data.checkbox}
                            // location={data.location}
                            // location_name={data.location_name}
                            />
                        ))}
                        {!courses.length && (
                            <p style={{ textAlign: "center" }}>No Courses Found</p>
                        )}
                    </div>
                </div>
            </div>
            {totalPages > 1 && (
                <PaginationView
                    totalPages={totalPages}
                    next={next}
                    previous={previous}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                />
            )}
        </>
    );
};

export default CoursesList;
