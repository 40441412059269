import React, { useState, useEffect } from 'react';
import { course_demo_image, marketplace_Course_image } from "../../assets/images/images";
import { useNavigate } from 'react-router-dom';
import BackButton from '../BackButton/BackButton';
import MarketPlaceCourseCard from '../MarketPlaceCourse/MarketPlaceCourseCard';
import "./MarketPlaceShoppingCart.css"
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from '../../features/auth/AuthContext';
import { resetAddToCart } from "../../state/actions/addtoCartAction";
import {
    cartList,
    resetCartList
} from "../../state/actions/cartlistAction";
import MarketplacePriceChekoutList from './MarketplacePriceChekoutList';
import CancelPopup from '../CancelPopup/CancelPopup';
import { removeCartData, resetRemoveCart } from '../../state/actions/removeCartAction';
import { getSuggestedCourses, resetSuggestedCoursesList } from '../../state/actions/cartSuggestedCoursesAction'
import { toast } from 'react-toastify';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Modal } from "react-responsive-modal";
import LoadingComponent from '../LoadingComponent/LoadingComponent';


function MarketPlaceShoppingCart() {
    const [openDialog, setOpenDialog] = useState(false);
    const [item_id, setitem_id] = useState();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cartlistData = useSelector((state) => state.cartlist);
    const removeCart = useSelector((state) => state.removeCartData);
    const { suggestedCourses, isLoading } = useSelector((state) => state.suggestedCourses);
    const { token } = useAuth();
    const addToCartData = useSelector((state) => state.addToCart);
    const [loading, setLoading] = useState(false);

    // const cartListRequest = () => {
    //     dispatch(cartList(token));
    // };
    const handleNavigateAway = () => {
        localStorage.setItem("studentHomeScrollPosition", window.pageYOffset);
      };
    useEffect(() => {
        if (removeCart?.message?.length) {
            toast.success(removeCart?.message);
        }
        if (removeCart?.error?.length) {
            toast.error(removeCart?.error)
        }
        return () => {
            dispatch(resetRemoveCart());
        }
    }, [removeCart]);

    useEffect(() => {
        dispatch(getSuggestedCourses(token))
        return() =>{
            dispatch(resetSuggestedCoursesList())
        }
    }, [addToCartData])


    useEffect(() => {
        if (addToCartData?.message?.length) {
            toast.success(addToCartData?.message);
        }
        if (addToCartData?.error?.length) {
            toast.error(addToCartData?.error);
        }
        return () => {
            dispatch(resetAddToCart());
        };
    }, [addToCartData]);


    const show = () => {
        setOpenDialog(false)
        const req = {
            cart_item_id: item_id
        };
        dispatch(removeCartData(req, token));
    };

    const close = (id) => {
        setitem_id(id)
        setOpenDialog(!openDialog); // This will close the popup
    };

    // const settings = {
    //     dots: false,
    //     arrows: true,
    //     infinite: true,
    //     autoplay: false,
    //     speed: 1000,
    //     adaptiveHeight: true,
    //     slidesToShow: 3,
    //     slidesToScroll: 1,
    //     responsive: [
    //       {
    //         breakpoint: 767, // at max-width 768px
    //         settings: {
    //           slidesToShow: 1,
    //           slidesToScroll: 1,
    //           infinite: true,
    //           dots: false,
    //           arrows: true
    //         }
    //       },
    //       // You can add more breakpoints here if needed
    //     ]
    //   };

    return (
        <>
            <div className='marketplace-shopping-cart-section'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12'>
                            <div
                                className="view-course-Details-back-btn-folder"
                                onClick={() => {
                                    navigate("/dashboard/home")
                                }
                                }
                            >
                                <i class="fa-solid fa-chevron-left"></i>
                                <h2>Shopping Cart</h2>
                            </div>
                            {/* <BackButton buttonName="Shopping Cart" /> */}
                            {/* <h2 className='marketplace_course'>{cartlistData?.cartList?.length} Courses in cart</h2> */}
                            <div className='marketplace-shopping-cart-wrapper'>
                                <div className='marketplace-shopping-cart-left-main-wrapper'>
                                    <div className='marketplace-shopping-cart-left-container'>
                                        {/* {cartlistData?.cartList?.map((items, index) => (
                                            <>
                                                <div className='marketplace-shopping-cart-left-section' key={index}>
                                                    <div className="view-course-details-image course-bg-image">
                                                        <img src={items?.image_url} alt="" />
                                                    </div>
                                                    <div className='marketplace-shopping-cart-des-wrapper'>
                                                        <span className='delete' onClick={() => close(items?.cart_item_id)}><i class="fa-solid fa-trash"></i></span>
                                                        <p className='course-name'>{items?.subject}</p>
                                                        <h2>{items?.name}</h2>
                                                        <p className='teacher-name'>By {items?.teacher_name}</p>
                                                        <div className="studentDashboar-Suggested-Courses-profile-rate">
                                                            <a href="#"><i className="fa-solid fa-star"></i></a>
                                                            <p className="rate">4.1</p>
                                                            <p className="number">(322) View Ratings</p>
                                                        </div>
                                                        <div className='marketplace-shopping-cart-price-wrapper'>
                                                            <ul className='course-list-counts'>
                                                                <li>{items?.total_lessons} lessons</li>
                                                                <li>80 min of reading content</li>
                                                                <li>{items?.total_assignments} assignments</li>
                                                            </ul>
                                                            <div className='marketplace-shopping-cart-price-discount'>
                                                                <h2 className='marketplace-shopping-cart-price'>${items?.price}</h2>
                                                                <span className='marketplace-shopping-cart-price-discount'>${items?.price}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ))} */}
                                        <h2 className='title'>You might also like</h2>
                                        <div className='ratingCard-wrapper'>
                                            {isLoading ? 
                                            <LoadingComponent
                                                loading_text = "Educating the Future, Loading Now."
                                            /> :
                                                suggestedCourses.length > 0 ? (
                                                suggestedCourses.map((sugg_courses) => {
                                                    return <MarketPlaceCourseCard
                                                        course_demo_image={sugg_courses?.image_url}
                                                        title={sugg_courses?.teacher_name}
                                                        rate={sugg_courses?.average_rating}
                                                        number={sugg_courses?.ratings_count}
                                                        Course_name={sugg_courses?.subject}
                                                        course_title={sugg_courses?.name}
                                                        discounted_price={sugg_courses?.discounted_price}
                                                        price={sugg_courses?.price}
                                                        ai_avatar={sugg_courses?.ai_avatar}
                                                        button_name1="View Course"
                                                        button_name2="Add to Cart"
                                                        course_id={sugg_courses?.course_id}
                                                        course={sugg_courses}
                                                        onNavigateAway={handleNavigateAway}
                                                    />
                                                })
                                            ) : (
                                                <p>No Courses Found</p>
                                              )
                                            }
                                          
                                        </div>
                                    </div>
                                </div>
                                <MarketplacePriceChekoutList close={close} />
                                {openDialog && (
                                    <Modal
                                        open={openDialog}
                                        onClose={() => setOpenDialog(false)}
                                        center
                                        showCloseIcon={false}
                                        classNames={{
                                            modal: 'Cancel-Popup',
                                        }}
                                    >
                                        <CancelPopup
                                            title="Are you sure you want to remove this course from the cart?"
                                            No="No"
                                            Yes="Yes"
                                            show={show} // Assuming you have a state or value to pass 
                                            close={close} // Pass the reference, not the invocation
                                        />
                                    </Modal>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MarketPlaceShoppingCart;