import { Dispatch } from "redux";
import api from "../../api/api";
import {
    setstudentSubscription, isloading, seterror, resetstudentSubscription
} from "../reducers/getStudentSubsciptionPlanReducer";

const getStudentSubscriptionList = (token: any, type: any) => async (dispatch: Dispatch) => {
    dispatch(isloading(true));
    try {
        const result = await api
            .get(
                `/subscriptions/student-plans/?type=${type}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Token ${token}`,
                    },
                }
            )
        if (result) {
            dispatch(isloading(false));
            if (result.data?.status === 200) {
                dispatch(setstudentSubscription(result?.data?.data));
            }
            else {
                dispatch(seterror(result.data?.status ?? "Something went wrong"));
            }
        }
    }
    catch (error: any) {
        dispatch(seterror(error.response.data.message || "Something went wrong"));
    }
}

export { getStudentSubscriptionList, resetstudentSubscription };



