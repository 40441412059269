import React, { useEffect, useState } from 'react';
import CustomTabs from '../../CustomTabs/CustomTabs';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../features/auth/AuthContext';
import { getStudentEnrolledCourses, resetenrolledcourses } from "../../../state/actions/getStudentEnrolledCoursesAction";
import { useDispatch, useSelector } from 'react-redux';
import CourseCard from '../../../pages/home/components/dashboard/teacher/course/CourseCard';
import PaginationView from '../../Pagination/PaginationView';
import StudentCourseCard from '../../../pages/home/components/dashboard/teacher/course/CourseCard';
import { addToHistory } from '../../../state/reducers/historyReducer';
import { CircularProgress, Grid } from '@mui/material';
import LoadingComponent from '../../LoadingComponent/LoadingComponent';

function EnrolledCourses() {

    const { token } = useAuth();
    const { student_id } = useParams();
    const [currentPage, setCurrentPage] = useState(1);
    const globalState = useSelector((state) => state.globalState);
    const { enrolledCourses, next,
        previous,
        totalPages,
        isLoading
     } = useSelector((state) => state.getStudentEnrolledCourses);

    const dispatch = useDispatch();

    const getStudentEnrolledCoursesReq = () => {
        dispatch(getStudentEnrolledCourses(currentPage, student_id, token))
    }

    useEffect(() => {
        getStudentEnrolledCoursesReq();
        return () => {
            dispatch(resetenrolledcourses());
        }
    }, [currentPage])

    // useEffect(() => {
    //     dispatch(addToHistory(`/student-detail/${student_id}/courses`))
    // }, [])

    return (
        <>
            <div className='enrolled-courses'>

                {isLoading ?
                    <>
                        <LoadingComponent
                            loading_text = "Educating the Future, Loading Now."
                        />
                    </>
                    :
                    enrolledCourses && enrolledCourses.length > 0 ?
                    <>
                            {enrolledCourses?.map((course, id) => (

                                <CourseCard key={id} course={course} />
                            ))}
                    </>
                        :
                <p style={{ textAlign: "center", marginTop: "10px", margin: "0px auto" }}>No Course</p>
                }


                {/* {enrolledCourses.length > 0 ? (enrolledCourses?.map((course, id) => (
                    <CourseCard key={id} course={course} />
                )) ) : (
                    "No Course"
                )} */}
            </div>

            {totalPages > 1 && (
                <PaginationView
                    totalPages={totalPages}
                    next={next}
                    previous={previous}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                />
            )}
        </>
    )
}

export default EnrolledCourses