import Button from "@mui/material/Button";
import VisuallyHiddenInput from "./VisuallyHiddenInput";

const OutlineFIleInput = ({ label, handleFileInput }) => {

  const styles = {
    position: "absolute",
        right: "6%",
        top: "5%",
        zIndex: "2",
        backgroundColor: "white",
        width: "120px",
  }

  const defaultStyles = {
    border: "1px solid #0068FF",
    color: "#0068FF",
    width: "185px",
    backgroundColor: "transparent",
    textTransform: "capitalize",
    fontSize: "11px",
    fontFamily: "inherit",
    padding: "4px 23px",
    fontWeight: "inherit",
    boxShadow: "none",
  }

  return (
    <Button
      component={"label"}
      variant="contained"
      className="uploded_image_btn"
      style={{
        ...defaultStyles,
        ...(label === "Edit Image" ? styles : "")
      }}
    >
      {label}
      <VisuallyHiddenInput
        type="file"
        key={Math.random()} // Add a random key to reset the input field
        onChange={handleFileInput}
        accept="image/*" // Accept only image files
      />
    </Button>
  );
};

export default OutlineFIleInput;
