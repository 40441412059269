import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Modal } from "react-responsive-modal";
import { useAuth } from "../../../../../features/auth/AuthContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
    Course_created_successfully_image,
    Your_feedback_appreciated_icon,
  } from "../../../../../assets/images/images";

const CompleteRating = ({ setNewOpen, newOpen, showCloseButton }) => {
    const { token } = useAuth();
    const navigate = useNavigate();
    
  return (
    <Modal
      open={newOpen}
      center
      showCloseIcon={false}
      onClose={() => setNewOpen(false)}
      classNames={{
        modal:
          "Your-feedback-appreciated-popup Your-feedback-appreciated-popup-section",
      }}
    >
      <div className="Your-feedback-appreciated-popup-wrapper">
        <div className="Your-feedback-appreciated-popup">
          <div className="Your-feedback-appreciated-container">
            <div className="Your-feedback-appreciated-inner-folder">
              <div className="Your-feedback-appreciated-image">
                <img src={Your_feedback_appreciated_icon} />
              </div>
              <h2>Your feedback is appreciated.</h2>
            </div>
            {showCloseButton && <button
              type="button"
              onClick={() => {
                navigate(-1);
              }}
            >
              Back to Course
            </button>}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CompleteRating;
