import { Dispatch } from "redux";
import api from "../../api/api";
import {
    setuserRegistration, loading, seterror, resetuserRegistration
} from "../reducers/userRegistrationReducer";

const userRegistration = (data: any) => async (dispatch: Dispatch) => {
   dispatch(loading(true));
   try {
     const result = await api
       .post(
        `auth/register-user/`,
        data,
        {
          headers: {
            "Content-Type": "application/json"
          },
        }
       )
       
       if(result){
        dispatch(loading(false));
        if (result.data?.status === 200) {
            if (result.data?.data) {
                dispatch(setuserRegistration(result.data));
            } else {
                dispatch(seterror(result.data?.message ?? "Something Went Wrong!"));
            }
          }
          else {
            dispatch(seterror(result.data?.message ?? "Something Went Wrong!"));
          }
       }
   }
   catch (error: any) {
    dispatch(seterror(error.response.data.message));
   }
}

export { resetuserRegistration, userRegistration} 

