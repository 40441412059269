import React, { useState } from 'react';
import LessonAssignmentAnalyticsTab from '../LessonAssignmentAnalyticsTab/LessonAssignmentAnalyticsTab';

function LessonAssignmentAnalyticsTabPage() {

    return (
        <>
            <LessonAssignmentAnalyticsTab/>
        </>
    );
}

export default LessonAssignmentAnalyticsTabPage;
