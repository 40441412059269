import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ProceedToPay {
    isLoading: boolean;
    error: string | null;
    message: string | null;
    order_id: string | null;
}

const initialState: ProceedToPay = {
    message: null,
    isLoading: false,
    error: null,
    order_id: null,
};

const ProceedToPaySlice = createSlice({
    name: 'ProceedToPay',
    initialState,
    reducers: {
        setProceedToPay: (state: ProceedToPay, action: PayloadAction<ProceedToPay>) => {
            state.message = action.payload.message;
            state.order_id = action.payload.order_id;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: ProceedToPay, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: ProceedToPay, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetProceedToPay: () => {
            return initialState;
        },
    },
});

export const { setProceedToPay, setloading, seterror, resetProceedToPay } = ProceedToPaySlice.actions;

export default ProceedToPaySlice.reducer;
