import React, { useRef , useState } from "react";
import "./LessonGenerator.css";
import CommonTextField from "../../utils/CommonTextField";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import BackButton from "../BackButton/BackButton";
import { Tooltip } from "react-tooltip";
import LessonGenratorChatBoard from "./LessonGenratorChatBoard";
import LessonGenratorWorkBoard from "./LessonGenratorWorkBoard";
import { restrichtion_icon, downlode_pdf_image, material_symbols_light_upload, Calimg } from "../../assets/images/images";
import GoogleUploadPopup from "../GoogleUploadPopup/GoogleUploadPopup";
import FileUpload from "../FileUploadToS3/FileUpload";
// import Calimg from "../../../../../../assets/images/calendar.png";
// import CommonTextField from "../../../../../../utils/CommonTextField";

function LessonGenerator() {

    const [showChatBoard, setShowChatBoard] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploadedFileLink, setuploadedFileLink] = useState([]);
    const fileInputRef = useRef(null);

    const toggleChatBoard = () => {
        setShowChatBoard(!showChatBoard);
    };

    const [showUploadPop, setShowUploadPop] = useState(false);
    
    const showUploadPopupcall = () => {
        if (showUploadPop) {
          setShowUploadPop(false);
        } else {
          setShowUploadPop(true);
        }
      };

    return (
        <>
            <div className="lesson-generator-section">
                <div className="lesson-generator-wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="lesson-generator-container">
                                    <div className="lesson_generator_inner">
                                        <div className="view-course-Details-back-btn-folder">
                                            <BackButton buttonName={"Lesson Generator"} />
                                        </div>
                                        <div className="assign_main_box">
                                            <div className="assign_main_box_left">
                                                <div className="assign_main_box_left_single">
                                                    <label>Lesson Name</label>
                                                    <CommonTextField
                                                        type="text"
                                                        placeholder="E.g. Neurons"
                                                        name="name"
                                                        label="Lesson name"
                                                        showLabel={false}
                                                        variant="outlined"
                                                        // validate={(val) => isRequired(val, "Lesson name")}
                                                        size="sm"
                                                        className="custom-text-field"
                                                        // value={formState.name || ""}
                                                        // onChange={handleInputChange}
                                                        borderRadius={"6px"}
                                                        height={45}
                                                        border={"1px solid #ECECEC;"}
                                                        color="neutral"
                                                    />
                                                </div>
                                            </div>
                                            {/* {pop_up_for_lesson_start_date_note && (
                                            <div className="pop_up_for_lesson_start_date_note">
                                                <p>
                                                Students can start accessing the course content from{" "}
                                                {dayjs(formState.start_date).format("MMM DD, YYYY")}
                                                </p>
                                            </div>
                                        )} */}
                                            <div className="assign_main_box_right">
                                                <div className="assign_main_box_right_single">
                                                    <label>Lesson Start Date</label>
                                                    <div className="assing_cal_sec_new">
                                                        <img src={Calimg} className="cal_img_sec" alt="" />
                                                        <Datetime
                                                            //   isValidDate={isValidDateLessonStartDate}
                                                            //   min={lessonMinDate}
                                                            //   max={
                                                            //     course?.end_date === ""
                                                            //       ? null
                                                            //       : dayjs(course?.end_date)
                                                            //   }
                                                            timeFormat={false}
                                                            inputProps={{
                                                                placeholder: "MM:DD:YYYY",
                                                                readOnly: true,
                                                            }}
                                                            closeOnSelect={true}
                                                            dateFormat="MMM DD, YYYY"
                                                            placeholder="E.g. Sep 10, 2023"
                                                            name="start_date"
                                                            //   value={
                                                            //     formState.start_date
                                                            //       ? dayjs(formState.start_date).format(
                                                            //         "MMM DD, YYYY"
                                                            //       )
                                                            //       : ""
                                                            //   }
                                                            //   onChange={(date) =>
                                                            //     handleInputChangedate("start_date", date)
                                                            //   }
                                                            className="custom-datetime"
                                                        />
                                                    </div>

                                                    <div className="warning-message">
                                                        {/* {formLessonError?.start_date} */}
                                                    </div>
                                                </div>
                                                <div className="assign_main_box_right_single lesson-end-date">
                                                    <label>Lesson End Date (Optional)</label>
                                                    <div className="assing_cal_sec_new">
                                                        <img src={Calimg} className="cal_img_sec" alt="" />
                                                        <Datetime
                                                            //   isValidDate={isValidDateLessonEndDate}
                                                            //   max={dayjs(course?.end_date)}
                                                            timeFormat={false}
                                                            inputProps={{
                                                                placeholder: "MM:DD:YYYY",
                                                                readOnly: true,
                                                            }}
                                                            closeOnSelect={true}
                                                            dateFormat="MMM DD, YYYY"
                                                            placeholder="E.g. Sep 10, 2023"
                                                            name="end_date"
                                                            //   value={
                                                            //     formState.end_date
                                                            //       ? dayjs(formState.end_date).format(
                                                            //         "MMM DD, YYYY"
                                                            //       )
                                                            //       : ""
                                                            //   }
                                                            //   onChange={(date) =>
                                                            //     handleInputChangedate("end_date", date)
                                                            //   }
                                                            className="custom-datetime"
                                                        />
                                                    </div>

                                                    <div className="warning-message">
                                                        {/* {formLessonError?.end_date} */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="lesson-generator-workboard-wrapper">
                                        <div className="lesson-generator-massege-folder">
                                            <h6>Workboard</h6>
                                            <div className="lesson-genrater-toggle-folder-container">
                                                <div className="lesson-genrater-toggle-folder">
                                                    <h6>ChatBot</h6>
                                                    <label className="switch">
                                                        <input
                                                            type="checkbox"
                                                        // checked={isSwitchOnVirtualAssistant}
                                                        // onClick={handleSwitchToggleVirtualAssistant}
                                                        />
                                                        <span className="slider round"></span>
                                                    </label>
                                                    <img src={restrichtion_icon} data-tooltip-id="chatbot-data-workboard" />
                                                    <Tooltip
                                                        id="chatbot-data-workboard"
                                                        place="top"
                                                        // content="Show/Hide chatbot during assignments"
                                                        content="Enable to allow students to interact with the Teacher AI Chatbot for instant assistance."
                                                        type="light"
                                                        effect="float"
                                                    />
                                                </div>
                                                <div className="lesson-genrater-toggle-folder">
                                                    <h6>Lesson Preview</h6>
                                                    <label className="switch">
                                                        <input
                                                            type="checkbox"
                                                        // checked={isSwitchOnLessonPreview}
                                                        // onClick={handleSwitchToggleLessonPreview}
                                                        />
                                                        <span className="slider round"></span>
                                                    </label>
                                                    <img src={restrichtion_icon} data-tooltip-id="lessonpreview-data-workboard" />
                                                    <Tooltip
                                                        id="lessonpreview-data-workboard"
                                                        place="top"
                                                        // content="Show/Hide lesson preview during assignments"
                                                        content="Allow students to download your uploaded course materials."
                                                        type="light"
                                                        effect="float"
                                                    />
                                                </div>
                                                <div className="lesson-genrater-downlode">
                                                    <h6>Download Files</h6>
                                                    <label className="switch">
                                                        <input
                                                            type="checkbox"
                                                        // checked={isSwitchOn2}
                                                        // onClick={handleSwitchToggleDownload}
                                                        />
                                                        <span className="slider round"></span>
                                                    </label>
                                                    <img src={restrichtion_icon} data-tooltip-id="filedownload-data-workboard" />
                                                    <Tooltip
                                                        id="filedownload-data-workboard"
                                                        place="top"
                                                        // content="Allow download of uploaded files"
                                                        content="Enable to allow students to see personalized content based on their preferences and interests."
                                                        type="light"
                                                        effect="float"
                                                    />
                                                </div>
                                                <div className="Preview-lesson-Content">
                                                    <div className="Preview-lesson-text-editor-features-personalize">
                                                        <h6>Personalize</h6>
                                                        <label className="switch">
                                                            <input
                                                                type="checkbox"
                                                            // checked={isSwitchOn}
                                                            // onClick={handleSwitchToggle}
                                                            />
                                                            <span className="slider round"></span>
                                                        </label>
                                                        <img src={restrichtion_icon} data-tooltip-id="Enable-personalized" />
                                                        <Tooltip
                                                            id="Enable-personalized"
                                                            place="top"
                                                            // content="Enable to allow students to see personalized content based on their preferences and interests."
                                                            content="Enable to allow students to see a brief overview of the lesson while working on assignments."
                                                            type="light"
                                                            effect="float"
                                                        />
                                                        {/* {showPopup && (
                                                                <p className="personlized_popup">
                                                                    The content will be personalized to match the
                                                                    student's specific interests
                                                                </p>
                                                            )} */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="create-lesson-Personalize-contaiber">
                                                <i class="fa-solid fa-eye"></i>
                                                <h6>
                                                    <a
                                                        href="#"
                                                    // onClick={(e) => {
                                                    //     e.preventDefault(); // This prevents the default anchor link behavior
                                                    //     // setopenStudentView(!openStudentView);
                                                    //     showstudentviewDtls();
                                                    // }}
                                                    >
                                                        Student Preview
                                                    </a>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="lesson_generator_body_sec">
                                        <div
                                            className={`lesson_generator_body_sec_left ${showChatBoard ? '' : 'hidden'}`}
                                        >
                                            <LessonGenratorChatBoard toggleChatBoard={toggleChatBoard} />
                                        </div>
                                        <div
                                            className={`lesson_generator_body_sec_right ${showChatBoard ? '' : 'full-width'}`}
                                        >
                                            <LessonGenratorWorkBoard toggleChatBoard={toggleChatBoard} />
                                        </div>
                                        {/* <LessonGenratorChatBoard/>
                                        <LessonGenratorWorkBoard/> */}
                                    </div>
                                    {/* <div className="preview-student-wrapper">
                                        <div
                                            style={{ padding: "30px 20px", minHeight: "90%" }}
                                            className="Course-created-successfully-container"
                                        >
                                            <div
                                                className="close-modal-btn-student-view"
                                                onClick={() => setopenStudentView(false)}
                                            >
                                                <a>
                                                    <i className="fa-solid fa-xmark dismis-close"></i>
                                                </a>
                                            </div>

                                            <div
                                                style={{ height: "593px", zIndex: 999, overflow: "auto" }}
                                                className="Course-created-successfully-folder"
                                            >
                                                <h4>
                                                    Lesson
                                                    {isAssignment ? "Assignment" : isRubric ? "Rubric" : "Lesson"}
                                                </h4>
                                                <p>Lesson on TestNG
                                                    INTRODUCTION TO TESTNG
                                                    TestNG is a testing framework inspired by JUnit and NUnit but introduces new functionalities that make it more powerful and easier to use. TestNG is designed to cover all categories of tests: unit, functional, end-to-end, integration, etc. It is widely used in the Java programming ecosystem for test automation.

                                                    KEY FEATURES OF TESTNG
                                                    Annotations: TestNG uses annotations to configure and control the test methods.
                                                    Test Configuration: Allows for flexible test configuration, including setting up before and after test methods.
                                                    Parallel Execution: Supports running tests in parallel, which can significantly reduce the time required for test execution.
                                                    Data-Driven Testing: Facilitates data-driven testing using data providers.
                                                    Test Suite Management: Provides a way to manage test suites and test cases efficiently.
                                                    Reporting: Generates detailed HTML reports of test execution.
                                                    SETTING UP TESTNG
                                                    Prerequisites
                                                    Java Development Kit (JDK): Ensure that JDK is installed on your system.
                                                    Integrated Development Environment (IDE): Eclipse or IntelliJ IDEA is recommended for ease of use.
                                                    Installation Steps
                                                    Lesson on TestNG
                                                    INTRODUCTION TO TESTNG
                                                    TestNG is a testing framework inspired by JUnit and NUnit but introduces new functionalities that make it more powerful and easier to use. TestNG is designed to cover all categories of tests: unit, functional, end-to-end, integration, etc. It is widely used in the Java programming ecosystem for test automation.

                                                    KEY FEATURES OF TESTNG
                                                    Annotations: TestNG uses annotations to configure and control the test methods.
                                                    Test Configuration: Allows for flexible test configuration, including setting up before and after test methods.
                                                    Parallel Execution: Supports running tests in parallel, which can significantly reduce the time required for test execution.
                                                    Data-Driven Testing: Facilitates data-driven testing using data providers.
                                                    Test Suite Management: Provides a way to manage test suites and test cases efficiently.
                                                    Reporting: Generates detailed HTML reports of test execution.
                                                    SETTING UP TESTNG
                                                    Prerequisites
                                                    Java Development Kit (JDK): Ensure that JDK is installed on your system.
                                                    Integrated Development Environment (IDE): Eclipse or IntelliJ IDEA is recommended for ease of use.
                                                    Installation Steps
                                                    Lesson on TestNG
                                                    INTRODUCTION TO TESTNG
                                                    TestNG is a testing framework inspired by JUnit and NUnit but introduces new functionalities that make it more powerful and easier to use. TestNG is designed to cover all categories of tests: unit, functional, end-to-end, integration, etc. It is widely used in the Java programming ecosystem for test automation.

                                                    KEY FEATURES OF TESTNG
                                                    Annotations: TestNG uses annotations to configure and control the test methods.
                                                    Test Configuration: Allows for flexible test configuration, including setting up before and after test methods.
                                                    Parallel Execution: Supports running tests in parallel, which can significantly reduce the time required for test execution.
                                                    Data-Driven Testing: Facilitates data-driven testing using data providers.
                                                    Test Suite Management: Provides a way to manage test suites and test cases efficiently.
                                                    Reporting: Generates detailed HTML reports of test execution.
                                                    SETTING UP TESTNG
                                                    Prerequisites
                                                    Java Development Kit (JDK): Ensure that JDK is installed on your system.
                                                    Integrated Development Environment (IDE): Eclipse or IntelliJ IDEA is recommended for ease of use.
                                                    Installation Steps
                                                    Lesson on TestNG
                                                    INTRODUCTION TO TESTNG
                                                    TestNG is a testing framework inspired by JUnit and NUnit but introduces new functionalities that make it more powerful and easier to use. TestNG is designed to cover all categories of tests: unit, functional, end-to-end, integration, etc. It is widely used in the Java programming ecosystem for test automation.

                                                    KEY FEATURES OF TESTNG
                                                    Annotations: TestNG uses annotations to configure and control the test methods.
                                                    Test Configuration: Allows for flexible test configuration, including setting up before and after test methods.
                                                    Parallel Execution: Supports running tests in parallel, which can significantly reduce the time required for test execution.
                                                    Data-Driven Testing: Facilitates data-driven testing using data providers.
                                                    Test Suite Management: Provides a way to manage test suites and test cases efficiently.
                                                    Reporting: Generates detailed HTML reports of test execution.
                                                    SETTING UP TESTNG
                                                    Prerequisites
                                                    Java Development Kit (JDK): Ensure that JDK is installed on your system.
                                                    Integrated Development Environment (IDE): Eclipse or IntelliJ IDEA is recommended for ease of use.
                                                    Installation Steps
                                                    Lesson on TestNG
                                                    INTRODUCTION TO TESTNG
                                                    TestNG is a testing framework inspired by JUnit and NUnit but introduces new functionalities that make it more powerful and easier to use. TestNG is designed to cover all categories of tests: unit, functional, end-to-end, integration, etc. It is widely used in the Java programming ecosystem for test automation.

                                                    KEY FEATURES OF TESTNG
                                                    Annotations: TestNG uses annotations to configure and control the test methods.
                                                    Test Configuration: Allows for flexible test configuration, including setting up before and after test methods.
                                                    Parallel Execution: Supports running tests in parallel, which can significantly reduce the time required for test execution.
                                                    Data-Driven Testing: Facilitates data-driven testing using data providers.
                                                    Test Suite Management: Provides a way to manage test suites and test cases efficiently.
                                                    Reporting: Generates detailed HTML reports of test execution.
                                                    SETTING UP TESTNG
                                                    Prerequisites
                                                    Java Development Kit (JDK): Ensure that JDK is installed on your system.
                                                    Integrated Development Environment (IDE): Eclipse or IntelliJ IDEA is recommended for ease of use.
                                                    Installation Steps
                                                </p>
                                                <PreprocessAndRenderLatex
                                                    content={preprocessLatex(previewDtls)}
                                                    isBlockMath={true}
                                                />
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="select-assignments-allow-lesson-preview-popup-wrapper">
                                        <div className="select-assignments-allow-lesson-preview-popup-container">
                                            <i class="fa-solid fa-x"></i>
                                            <h2>Select Assignments to Allow Lesson Preview</h2>
                                            <div className="select-assignments-allow-lesson-preview-inner-popup-folder">
                                                <div className="select-assignments-allow-lesson-preview-scroller">
                                                    <div className="select-assignments">
                                                        <input type="checkbox"/>
                                                        <p>Assignment <span>01</span></p>
                                                    </div>
                                                    <div className="select-assignments">
                                                        <input type="checkbox"/>
                                                        <p>Assignment <span>01</span></p>
                                                    </div>
                                                    <div className="select-assignments">
                                                        <input type="checkbox"/>
                                                        <p>Assignment <span>01</span></p>
                                                    </div>
                                                    <div className="select-assignments">
                                                        <input type="checkbox"/>
                                                        <p>Assignment <span>01</span></p>
                                                    </div>
                                                    <div className="select-assignments">
                                                        <input type="checkbox"/>
                                                        <p>Assignment <span>01</span></p>
                                                    </div>
                                                </div>
                                                <div className="select-assignments-allow-lesson-preview-btn-folder">
                                                    <button type="button">Cancel</button>
                                                    <button type="button">Save</button>
                                                </div>
                                            </div>  
                                        </div>
                                    </div> */}
                                    {/* <div className="Select-files-popup-wrapper">
                                        <div className="select-Files-allow-download-popup-container">
                                            <i class="fa-solid fa-x select-Files-allow-download-popup-close-btn"></i>
                                            <h2>Select Files to Allow Download</h2>
                                            <div className="select-Files-allow-download-inner-popup-folder">
                                                <div className="select-Files-allow-download-popup-scroller">
                                                    <div className="select-assignments">
                                                        <input type="checkbox"/>
                                                        <div className="downlode-pdf-folder">
                                                            <div className="downlode-pdf-inner-folder">
                                                                <div className="downlode-pdf-image">
                                                                    <img src={downlode_pdf_image}/>
                                                                </div>
                                                                <div className="downlode-pdf-title-folder">
                                                                    <h2>Neurological Science.PDF</h2>
                                                                    <p>22.97 MB</p>
                                                                </div>
                                                            </div>
                                                            <i class="fa-solid fa-x downlode-pdf-cancle-btn"></i>
                                                        </div>
                                                    </div>
                                                    <div className="select-assignments">
                                                        <input type="checkbox"/>
                                                        <div className="downlode-pdf-folder">
                                                            <div className="downlode-pdf-inner-folder">
                                                                <div className="downlode-pdf-image">
                                                                    <img src={downlode_pdf_image}/>
                                                                </div>
                                                                <div className="downlode-pdf-title-folder">
                                                                    <h2>Neurological Science.PDF</h2>
                                                                    <p>22.97 MB</p>
                                                                </div>
                                                            </div>
                                                            <i class="fa-solid fa-x downlode-pdf-cancle-btn"></i>
                                                        </div>
                                                    </div>
                                                    <div className="select-assignments">
                                                        <input type="checkbox"/>
                                                        <div className="downlode-pdf-folder">
                                                            <div className="downlode-pdf-inner-folder">
                                                                <div className="downlode-pdf-image">
                                                                    <img src={downlode_pdf_image}/>
                                                                </div>
                                                                <div className="downlode-pdf-title-folder">
                                                                    <h2>Neurological Science.PDF</h2>
                                                                    <p>22.97 MB</p>
                                                                </div>
                                                            </div>
                                                            <i class="fa-solid fa-x downlode-pdf-cancle-btn"></i>
                                                        </div>
                                                    </div>
                                                    <div className="select-assignments">
                                                        <input type="checkbox"/>
                                                        <div className="downlode-pdf-folder">
                                                            <div className="downlode-pdf-inner-folder">
                                                                <div className="downlode-pdf-image">
                                                                    <img src={downlode_pdf_image}/>
                                                                </div>
                                                                <div className="downlode-pdf-title-folder">
                                                                    <h2>Neurological Science.PDF</h2>
                                                                    <p>22.97 MB</p>
                                                                </div>
                                                            </div>
                                                            <i class="fa-solid fa-x downlode-pdf-cancle-btn"></i>
                                                        </div>
                                                    </div>
                                                    <div className="select-assignments">
                                                        <input type="checkbox"/>
                                                        <div className="downlode-pdf-folder">
                                                            <div className="downlode-pdf-inner-folder">
                                                                <div className="downlode-pdf-image">
                                                                    <img src={downlode_pdf_image}/>
                                                                </div>
                                                                <div className="downlode-pdf-title-folder">
                                                                    <h2>Neurological Science.PDF</h2>
                                                                    <p>22.97 MB</p>
                                                                </div>
                                                            </div>
                                                            <i class="fa-solid fa-x downlode-pdf-cancle-btn"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="upload-files-container">
                                                    <div class="drag-file-area">
                                                        <div className="material_symbols_light_upload">
                                                            <img src={material_symbols_light_upload}/>
                                                        </div>
                                                        <h2 class="drag-file-here-text">Drag file Here</h2>
                                                        <h2 class="drag-file-here-text"> or <span class="browse-files"><input type="file" class="default-file-input"/></span><span className="Browse">Browse</span></h2>
                                                        <span class="material-icons-outlined">PDF, JPEG, PDG Formats, up to 75 MB</span>
                                                    </div>
                                                </div>
                                                <div className="select-assignments-allow-lesson-preview-btn-folder">
                                                    <button type="button">Cancel</button>
                                                    <button type="button">Save</button>
                                                </div>
                                            </div>  
                                        </div>
                                    </div> */}
                                    <FileUpload
                                        showUploadPop={showUploadPop}
                                        setShowUploadPop={setShowUploadPop}
                                        selectedFiles={selectedFiles}
                                        setSelectedFiles={setSelectedFiles}
                                        setuploadedFileLink={setuploadedFileLink}
                                        fileInputRef={fileInputRef}
                                    />
                                    {/* <GoogleUploadPopup/> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LessonGenerator;

