import { format } from "date-fns";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../../../../api/api";
import {
  calender_image,
  email_image,
  gender_image,
  location_image,
  profile_image,
  studentDashboard_AI_chatbot_image,
} from "../../../../../../assets/images/images";
import { useAuth } from "../../../../../../features/auth/AuthContext";

import imgs from "../../../../../../assets/images/imgs";
import {
  isRequired,
  isValidEmail,
  isValidMobileNumber,
} from "../../../../../../utils/validator";
import "../../../../styles/dateTime.css";
import BackButton from "../../../../../../components/BackButton/BackButton";
import StudentProfileDetails from "./StudentProfileDetails";
const StudentProfileSetting = ({
  course,
  teacher_id,
  setTotalLessons,
  setTotalStudent,
}) => {
  

  const [activeTab, setActiveTab] = useState("lessons"); // Default active tab is 'lessons'
  const { token } = useAuth();
  const navigate = useNavigate();
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    if (tabName === "students") {
      studentdetailsget(`1,4,5`);
    }
    if (tabName === "studentsRequest") {
      studentdetailsget(3);
    }
  };
  const [studentsRequest, setstudentsRequest] = useState([]);
  const [students, setstudents] = useState([]);
  const [lessondetails, setlessondetails] = useState(null);
  const [requestProcessMssg, setrequestProcessMssg] = useState(0);

  const studentdetailsget = (status) => {
    api
      .get(
        `courses/teacher/enroll-course-requests/?course_id=${course?.course_id}&status=${status}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          if (status === 1) {
            setstudents(response.data.data);
          }
          if (status === 3) {
            setstudentsRequest(response.data.data);
          }
        }
      })
      .catch((error) => {});
  };
  useEffect(() => {
    api
      .get(`courses/teacher//?course_id=${course?.course_id}&status='1,4,5'`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
          setstudents(response.data.data);
          setTotalStudent(response?.data?.data?.length);
        }
      })
      .catch((error) => {});
    api
      .get(`lessons/get-all-lessons/course/${course?.course_id}/`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`, // `Token ${token}`
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setlessondetails(response.data.data);
          setTotalLessons(response?.data?.data?.length);
        }
      })
      .catch((error) => {
        //
      });
  }, []);

  const [showDropdown2, setShowDropdown2] = useState(false); // State to control visibility
  const [showreuestsuccesfully, setshowreuestsuccesfully] = useState(false);
  const toggleDropdown2 = () => {
    setShowDropdown2(!showDropdown2); // Toggle the visibility state
  };
  const handleAcceptReject = (status, student_id) => {
    setrequestProcessMssg(status);
    const formData = new FormData();
    formData.append("status", status);
    formData.append("course_id", course.course_id);
    formData.append("student_id", student_id);
    // Perform the API call using Axios
    const url = "courses/teacher/enroll-course-requests/";
    const headers = {
      Authorization: `Token ${token}`,
    };
    api
      .post(url, formData, { headers })
      .then((response) => {
        if (response.data.status == 200) {
          const newStudentsRequest = studentsRequest.filter(
            (item) => item.student_id !== student_id
          );
          setstudentsRequest(newStudentsRequest);
          setshowreuestsuccesfully(true);
          setTimeout(() => {
            setshowreuestsuccesfully(false);
          }, 1000);
        }
      })
      .catch((error) => {});
  };
  function formatDate(isoDate) {
    const date = new Date(isoDate);

    // Get individual date components
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" }); // 'Jan', 'Feb', 'Mar' etc.
    const year = date.getFullYear();

    // Get the suffix for the day
    let suffix = "th";
    if (day % 10 === 1 && day !== 11) {
      suffix = "st";
    } else if (day % 10 === 2 && day !== 12) {
      suffix = "nd";
    } else if (day % 10 === 3 && day !== 13) {
      suffix = "rd";
    }

    return `${day}${suffix} ${month}, ${year}`;
  }
  const onStudentViewProfile = (student) => {
    navigate(`/student/${student?.student_id}`);
  };

  const [selectedCountry, setSelectedCountry] = useState({
    name: "United States",
    iso2: "us",
    dialCode: "1",
    format: "+...-...-....",
    hasAreaCodes: false,
  });





  // const intitalTab = [
  //   {
  //     name: courseTabNames.ENROLLED,
  //     courses: [
  //       {
  //         course_id: 492372,
  //         name: "English Literature",
  //         start_date: "2023-10-23",
  //         end_date: "2023-11-01",
  //         description:
  //           "Middle English Literature (1150-1485)\r\nSome scholars would argue that the Middle English period started as early as the 1100s. However, because the English language did not evolve into a dialect we could understand today until about the 12th century, 1150 seems like a better place to start.\r\ndefinition\r\nBy far, the most popular and influential writer during this period was Geoffrey Chaucer. He was considered the first great English poet. His works encompassed a variety of tones, styles, and genres. One of his most renowned works, The Canterbury Tales, is an epic story of pilgrims playfully pitted against each other in a storytelling contest. Each pilgrim's tale takes on a narrative of its own. The poem is structured as a frame narrative, or 'story within a story:' a literary device that would go on to become one of the most popular storytelling techniques in the history of literature. there are total 12 topics in English Literature and 2 sub topics",
  //         image_url: "http://proteacher.ai:4000/media/courses/English.png",
  //         teacher: "764962",
  //         teacher_name: "Prachi Tardekar",
  //       },
  //       {
  //         course_id: 679984,
  //         name: "refg",
  //         start_date: "2023-10-25",
  //         end_date: "2023-10-27",
  //         description:
  //           "Welcome to the fascinating world of Chemistry! This course is designed to introduce Grade 3 students to the basic principles of Chemistry through hands-on experiments and interactive activities. You'll explore the properties of matter, learn about the states of matter, and discover the elements that make up our world. Through inquiry-based learning, you'll develop critical thinking skills and learn to ask questions about the world around you. Our focus on safety means you'll learn to handle chemicals responsibly, while our emphasis on teamwork means you'll collaborate on group projects, learning to share ideas and solve problems collectively. By the end, you'll have a solid foundation in Chemistry and be ready to take on more advanced concepts in the future.",
  //         image_url:
  //           "http://proteacher.ai:4000/media/courses/course-bg-image_apXDFJv.png",
  //         teacher: "644076",
  //         teacher_name: "we",
  //       },
  //       {
  //         course_id: 141840,
  //         name: "SQLasd",
  //         start_date: "2023-10-31",
  //         end_date: "2023-11-30",
  //         description:
  //           "Welcome to an exciting journey into the world of data and databases! In this course, designed for 3rd graders, you'll learn the basics of Structured Query Language (SQL), a powerful tool used to manage and analyze data. Through interactive activities and games, you'll develop your problem-solving skills and logical thinking abilities. You'll also learn how to organize data, create tables, and retrieve information using SQL commands. But that's not all; you'll also explore the real-world applications of SQL, including how it's used in businesses and organizations. By the end of the course, you'll have a solid foundation in SQL and be ready to tackle more advanced concepts in data science and analytics.",
  //         image_url:
  //           "http://proteacher.ai:4000/media/courses/dylann_QUGkb5E.jpg",
  //         teacher: "555606",
  //         teacher_name: "Krishna Vishwakarma",
  //       },
  //       {
  //         course_id: 385247,
  //         name: "Mastering the Art of English: A Comprehensive Guide",
  //         start_date: "2023-10-30",
  //         end_date: "2023-11-30",
  //         description:
  //           "Welcome to a comprehensive guide to mastering the art of English! This course is designed specifically for 5th grade students to develop their reading, writing, and communication skills. Through engaging literature, creative writing exercises, and interactive discussions, students will explore the nuances of language and learn to express themselves with clarity and confidence. Our focus on grammar, vocabulary, and critical thinking will help students become effective communicators and analytical readers. Additionally, we will explore the cultural and historical context of English literature, broadening students' perspectives and enhancing their appreciation for the language. By the end of the course, students will have a strong foundation in English and be well-prepared for future academic and professional endeavors.",
  //         image_url:
  //           "http://proteacher.ai:4000/media/courses/gammer_e2ulhlK.webp",
  //         teacher: "892007",
  //         teacher_name: "Krishna Vishwakarma",
  //       },
  //       {
  //         course_id: 145983,
  //         name: "English",
  //         start_date: "2023-10-25",
  //         end_date: "2023-10-25",
  //         description: "hbkml,;",
  //         image_url: null,
  //         teacher: "775682",
  //         teacher_name: "Satyanarayan",
  //       },
  //     ],
  //     search: null,
  //     index_id: 1,
  //   },
  // ];
  // const [tab, setTab] = useState(intitalTab);
  // const updateTabCourseList = (tabName, newCourseList) => {
  //   // Use the enum to validate the tab name
  //   if (!Object.values(courseTabNames).includes(tabName)) {
  //     console.error(`Invalid tab name: ${tabName}`);
  //     return;
  //   }

  //   const tabIndex = tab.findIndex((selTab) => selTab.name === tabName);
  //   if (tabIndex === -1) {
  //     console.error(`Tab with the name ${tabName} not found.`);
  //     return;
  //   }

  //   const newTabs = [...tab];
  //   newTabs[tabIndex].courses = newCourseList;
  //   setTab(newTabs);
  // };
  const { course_id, student_id } = useParams();

  // useEffect(() => {
  //     tab.map((tab) => {
  //         console.log(tab.index_id);  // Logs the value of each key
  //         api.get(`courses/student/my-courses/?status=${intitalTab[0].index_id}`, {
  //             headers: {
  //                 "Content-Type": "multipart/form-data",
  //                 Authorization: `Token ${token}`,
  //             },
  //         })
  //             .then((response) => {
  //                 console.log(response);
  //                 if (response.data.status == 200) {
  //                     updateTabCourseList(tab.name, response.data.data);
  //                 }
  //             })
  //             .catch((error) => {
  //             });
  //     });
  // }, []);
  // useEffect(() => {
  //   api
  //     .get(`/students/teacher/get-student/?student_id=${student_id}`, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Token ${token}`,
  //       },
  //     })
  //     .then((response) => {
  //       if (response.status === 200) {
  //         setinfoStudent(response?.data?.data ?? []);
  //       }
  //     })
  //     .catch((error) => {
  //       //
  //     });
  // }, []);

  
  // const [showMore1, setShowMore1] = useState(false);
  // const [showMore2, setShowMore2] = useState(false);

  // const handleViewMoreClick1 = () => {
  //   setShowMore1(!showMore1);
  // };

  // const handleViewMoreClick2 = () => {
  //   setShowMore2(!showMore2);
  // };
  // const temp_student_data = {
  //   student_assessment:
  //     "Academic Excellence : Student consistently demonstrates outstanding performance in mathematics, maintaining a consistently high grade point average (GPA) of 4.0 in this subject.\n\nCritical Thinking : Student excels in analytical thinking, as evidenced by their ability to analyze complex problems and propose creative solutions in science experiments and class discussions",
  //   student_assessment2:
  //     "Academic Excellence : Student consistently demonstrates outstanding performance in mathematics, maintaining a consistently high grade point average (GPA) of 4.0 in this subject.\n\nCritical Thinking : Student excels in analytical thinking, as evidenced by their ability to analyze complex problems and propose creative solutions in science experiments and class discussions",
  // };

  // const ViewMoreComponent = ({
  //   showMore,
  //   handleViewMoreClick,
  //   data,
  //   header_image,
  //   header_text,
  //   charLimit = 200,
  // }) => (
  //   <div className="teacher-description-wrapper">
  //     <label className="teacher-description-container-head">
  //       <img src={header_image} alt={`${header_text} icon`} />
  //       {header_text}
  //     </label>
  //     <div className="Teacher-student-edit-profile-inner-des-wrapper teacher-description-container">
  //       <p className="teacher-description-container-p">
  //         {data && (showMore ? data : data?.slice(0, charLimit))}
  //       </p>
  //       <div>
  //         <button
  //           className="teacher-description-container-btn"
  //           onClick={handleViewMoreClick}
  //         >
  //           {showMore ? "View less" : "View more"}
  //         </button>
  //       </div>
  //     </div>
  //   </div>
  // );

  return (
    <>
      <div className="TeacherProfile" id="TeacherProfile-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="TeacherProfile-wrapper">
                <div className="TeacherProfile-btn-container">
                  <button
                    className={`tab-button ${
                      activeTab === "lessons" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("lessons")}
                  >
                    Student Profile
                  </button>
                  <button
                    className={`tab-button ${
                      activeTab === "students" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("students")}
                  >
                    Courses Enrolled
                  </button>
                </div>
                <div className="tab-content">
                 
                  {activeTab === "students" && (
                    <div className="list-of-students-wrappper">
                      <table>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Id</th>
                            <th>Grade</th>
                            <th>Enrolled On</th>
                            <th>Overall Progress</th>
                            <th></th>
                          </tr>
                          <div className="list-of-students-gap"></div>
                        </thead>
                        <tbody>
                          {students.length > 0 ? (
                            students.map((student, index) => (
                              <tr key={index}>
                                <td
                                  onClick={() => {
                                    onStudentViewProfile(student);
                                  }}
                                >
                                  <img
                                    src={
                                      student?.student_profile_pic ||
                                      studentDashboard_AI_chatbot_image
                                    }
                                    alt="Student"
                                  />

                                  {student?.student_name}
                                </td>
                                <td>{student?.student_id}</td>
                                <td>{student?.student_grade}</td>
                                <td>{formatDate(student?.enrolled_on)}</td>
                                {/* <td>
                                  <div className="progress">
                                    <div
                                      className="progress-bar"
                                      style={{ width: `30` }}
                                    ></div>
                                  </div>
                                </td> */}
                                <td>
                                  <a
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault(); // This prevents the default anchor link behavior
                                      onStudentViewProfile(student);
                                    }}
                                  >
                                    View Profile
                                  </a>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="5">No students records found.</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentProfileSetting;
