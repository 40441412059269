import React, {} from "react";
import FilteredTextarea from "../../../../../components/FilteredInput/FilteredTextArea";

const AssignmentDesFeild = () => {

  return (
    <>
      <div id="assigmentMCQs-inner-des-section">
        <div className="assigmentMCQs-inner-des-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="assigmentMCQs-inner-des">
                    <textarea rows="8" cols="30" placeholder="Type your answer here"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignmentDesFeild;
