import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface courseImageGenerate {
    message: any,
    isLoading: boolean;
    error: string | null;
}

const initialState: courseImageGenerate = {
    message: null,
    isLoading: false,
    error: null,
};

const courseImageGenerateSlice = createSlice({
    name: "courseimagegenerate",
    initialState,
    reducers: {
        setcourseImageGenerate: (state: courseImageGenerate, action: PayloadAction<courseImageGenerate>) => {
            state.message = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: courseImageGenerate, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: courseImageGenerate, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetCourseImageGenerate: () => {
            return initialState;
        },
    },
});

export const {
    setcourseImageGenerate,
    setloading,
    seterror,
    resetCourseImageGenerate,
} = courseImageGenerateSlice.actions;

export default courseImageGenerateSlice.reducer;