import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface teacherRating {
    rating: any;
    isLoading: boolean;
    error: string | null;
  next: string | null;
  previous: string | null;
  count: number | null;
  totalPages: number | null;
  }

  const initialState: teacherRating = {
    rating: null,
    isLoading: false,
    error: null,
    next: null,
    previous: null,
    count: 0,
    totalPages: 0,
  };

  const teacherratingslice = createSlice({
    name: "teacherRating",
    initialState,
    reducers: {
      setteacherRating: (
        state: teacherRating,
        action: PayloadAction<teacherRating>
      ) => {
        state.rating = action.payload;
        state.isLoading = false;
        state.error = null;
      },
      setPagination: (state: teacherRating, action: PayloadAction<teacherRating>) => {
        state.next = action.payload.next;
        state.previous = action.payload.previous;
        state.count = action.payload.count;
        state.totalPages = Math.ceil((action.payload.count ?? 0) / 12);
        state.isLoading = false;
        state.error = null;
      },
      setloading: (state: teacherRating, action: PayloadAction<boolean>) => {
        state.isLoading = action.payload;
        state.error = null;
      },
      seterror: (state: teacherRating, action: PayloadAction<string>) => {
        state.error = action.payload;
        state.isLoading = false;
      },
      resetteacherRating: () => {
        return initialState;
      },
    },
  });

  export const {
    setteacherRating,
    setloading,
    seterror,
    setPagination,
    resetteacherRating,
  } = teacherratingslice.actions;
  
  export default teacherratingslice.reducer;
  