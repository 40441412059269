import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const centerTextPlugin = {
  id: 'centerText',
  beforeDraw: (chart) => {
    const width = chart.width,
      height = chart.height,
      ctx = chart.ctx;

    ctx.restore();
    let fontSize = (height / 160).toFixed(2);
    ctx.font = fontSize + "em sans-serif";
    ctx.textBaseline = "middle";

    const text = "62%",
      textX = Math.round((width - ctx.measureText(text).width) / 2),
      textY = height / 2;

    ctx.fillText(text, textX, textY);
    ctx.save();
  }
};

const data = {
  labels: ['Red', 'Blue'],
  datasets: [{
    label: 'My First Dataset',
    data: [300, 200,],
    backgroundColor: [
      '#2B36FF',
      '#ABD9F5'
    ],
    hoverOffset: 4
  }]
};

const options = {
  plugins: {
    legend: {
      display: false // Hide legend if not needed
    },
    tooltip: {
      enabled: false // Hide tooltips if not needed
    },
  }
};

const DashboardDoughnutChart = () => {
  return (
    <div style={{ width: '230px', height: '230px', margin: "0px auto 18px auto", }}> {/* Set the width and height here */}
      <Doughnut data={data} options={options} plugins={[centerTextPlugin]} />
    </div>
  );
};

export default DashboardDoughnutChart;
