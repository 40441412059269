import React, { useEffect, useState } from "react";
import { dashboard_popup_teacher_profile_details_icon } from "../../assets/images/images";

import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useDispatch, useSelector } from "react-redux";

import api from "../../../src/api/api";
import { toast } from "react-toastify";
import { isRequired } from "../../utils/validator";
import { updateGlobalState } from "../../state/global/slice";
import { useAuth } from "../../features/auth/AuthContext";
import FilteredInput from "../FilteredInput/FilteredInput";

function EditSubject(props) {
  const dispatch = useDispatch();
  const globalState = useSelector((state) => state.globalState);
  const updateState = (key, value) => {
    dispatch(updateGlobalState({ key: key, value: value }));
  };
  const [formErrors, setFormErrors] = useState({
    subject_names: "",
  });
  const [formState, setFormState] = useState({
    subject_names: "",
  });

  const onSubjectSubmit = (e) => {
    e.preventDefault();
    const subject_names = formState.subject_names;
    if (subject_names.length > 0) {
      let errors = {};
      errors.subject_names = isRequired(subject_names);

      setFormErrors(errors); // Always update the error state regardless of whether there are errors
      // Check if there's an error message in any of the errors
      const hasErrors = Object.values(errors).some((error) => error !== "");

      if (hasErrors) {
        // Do not call props.setModalClose(); as there are errors
      } else {
        props.onSubmit(subject_names); // Call the onSubmit prop function
        props.setModalClose(); // Close the modal only if there are no errors
      }
    }
  };

  const [subjects, setSubjects] = useState(props?.subjectlist || []);
  const [inputappends, setInputappends] = useState([]);

  const [Addtosubjects, setAddtosubjects] = useState([]);

  const appendValue = (newValue) => {
    setInputappends([...inputappends, newValue]);
  };

  const removeValue = (valueToRemove) => {
    setInputappends(inputappends.filter((item) => item !== valueToRemove));
  };
  const { token } = useAuth();
  useEffect(() => {
    if (globalState?.datalist?.subjects === undefined) {
      api
        .get("/auth/get-datalist/", {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            //return 1;
            if (response?.data) {
              updateState("datalist", response?.data ?? {});
              if (response?.data?.subjects) {
                setAddtosubjects(
                  Object.values(response?.data.top_ten_subjects) ?? []
                );
              }
            }
            // localStorage.setItem('datalist', JSON.stringify(response?.data ?? ''));
          }
        })
        .catch((error) => {});
    }
    if (globalState?.datalist?.subjects) {
      setAddtosubjects(
        Object.values(globalState?.datalist?.top_ten_subjects) ?? []
      );
    }
  }, []);
  const addSuggestion = (suggestion) => {
    const exists = subjects.includes(suggestion);

    if (exists) {
    } else {
      appendValue(suggestion);
      const tempsubj = globalState?.datalist?.subjects;
      let etsval = "";
      for (const id in tempsubj) {
        if (tempsubj[id].toLowerCase() === suggestion.toLowerCase()) {
          etsval = id;
        }
      }
      appendValue(etsval);

      setSubjects([...subjects, suggestion]);
      setFormState((prev) => ({
        ...prev,
        subject_names: [...subjects, suggestion].filter(
          (subject) => subject !== ""
        ),
        // .join(",")
      }));
      let errors = {};
      if ([...subjects, suggestion].length < 1) {
        errors.subject_names = "Please enter alteast one subject.";
      }
      setFormErrors(errors);
    }
  };
  const removeSubject = (subjectToRemove) => {
    const tempsubj = globalState?.datalist?.subjects;
    let rmval = "";
    for (const id in tempsubj) {
      if (tempsubj[id] === subjectToRemove) {
        rmval = id;
      }
    }
    removeValue(rmval);
    const updatedSubjects = subjects.filter(
      (subject) => subject !== subjectToRemove
    );
    setSubjects(updatedSubjects);
    setFormState((prev) => ({
      ...prev,
      subject_names: updatedSubjects.filter((subject) => subject !== ""),
      // .join(",")
    }));
    let errors = {};
    if (updatedSubjects.length < 1) {
      errors.subject_names = "Please enter alteast one subject.";
    }
    setFormErrors(errors);
  };

  const handleInputKeyPress = async (event) => {
    const innerInputValue = event.target.value.trim().toLowerCase(); // Convert input value to lowercase

    if (event.key === "Enter" && innerInputValue !== "") {
      event.preventDefault();

      // Check if the lowercase input value already exists in the array (also converted to lowercase)
      const exists = subjects.some(
        (subject) => subject.trim().toLowerCase() === innerInputValue
      );

      if (!exists) {
        // Append the original (trimmed, but not lowercase) input value to the array
        // setSubjects([...subjects, event.target.value.trim()]);
        event.target.value = "";
        addSuggestion(innerInputValue.trim());
        // inputappends.push(event.target.value.trim());
        // appendValue(event.target.value.trim());
      } else {
        toast.dismiss();
        toast.error(`Subject already added.`, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let error = "";
    if (name === "subject_names") {
      error = isRequired(value);
    }
    setFormErrors({
      ...formErrors,
      [name]: error,
    });
    if (name === "subject_names") {
      setFormState((prev) => ({
        ...prev,
        subject_names: subjects.filter((subject) => subject !== ""),
        // .join(",")
      }));
    } else {
      setFormState((prev) => ({ ...prev, [name]: value }));
    }
  };
  const handleModalClose = () => {
    // Only close the modal if there are no errors
    if (!formErrors.subject_names) {
      props.setModalClose();
    }
  };
  const isTeacher = globalState.role === "Teacher";
  return (
    <Modal open={props.modalStatus} onClose={handleModalClose} center>
      <div className="dashboard-popup-teacher-profile-details-title">
        <div className="dashboard-popup-teacher-profile-details-image">
          <img src={dashboard_popup_teacher_profile_details_icon} alt="" />
        </div>
        <h2>{props.subject_title}</h2>
        {/* <h2>Subjects you {isTeacher ? "teach" : "are interested in"}</h2> */}
      </div>
      <div className="dashboard-popup-teacher-profile-details-form">
        {/* <p className="subjects-title">Write the subjects you teach</p> */}
        <div className="Subjects-you-teach">
          <input
            spellCheck
            type="text"
            style={
              formErrors.subject_names ? { border: "1px solid red" } : null
            }
            placeholder="E.g. Math, science, geography"
            onKeyPress={handleInputKeyPress}
            onChange={handleInputChange}
          />
        </div>
        <div className="Subjects-you-teach-feilds-folder">
          {subjects.map((subject, index) => (
            <div key={index} className="Subjects-you-teach-feilds">
              <p>{subject}</p>
              <a
                className="subject-delete-btn"
                onClick={() => removeSubject(subject)}
              >
                <i className="fa-solid fa-xmark"></i>
              </a>
            </div>
          ))}
        </div>
        <div className="Subjects-you-teach-suggestions-container">
          {Addtosubjects &&
            (Object.values(Addtosubjects).filter(
              (valsubject) =>
                valsubject !== "" && !subjects.includes(valsubject)
            ).length > 0 ? (
              <p className="Subjects-you-teach-suggestions-title">
                Suggestions
              </p>
            ) : null)}

          <div className="Subjects-you-teach-suggestions-folder">
            {Addtosubjects &&
              Object.values(Addtosubjects)
                .filter(
                  (valsubject) =>
                    valsubject !== "" && !subjects.includes(valsubject)
                )
                .map((valsubject, index) => (
                  <a
                    key={index}
                    onClick={() => addSuggestion(valsubject)}
                  >
                    {valsubject}
                  </a>
                ))}
          </div>
        </div>
        <br />
        <p className="errorState">{formErrors.subject_names}</p>
      </div>

      <div className="modal-content-btn">
        <button onClick={onSubjectSubmit} className="submin-btn">Submit</button>
      </div>
    </Modal>
  );
}

export default EditSubject;
