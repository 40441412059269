import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface RemoveCart {
    isLoading: boolean;
    error: string | null;
    message: string | null;
}

const initialState: RemoveCart = {
    message: null,
    isLoading: false,
    error: null,
};

const RemoveCartSlice = createSlice({
    name: 'RemoveCart',
    initialState,
    reducers: {
        setRemoveCart: (state: RemoveCart, action: PayloadAction<RemoveCart>) => {
            state.message = action.payload.message;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: RemoveCart, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: RemoveCart, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetRemoveCart: () => {
            return initialState;
        },
    },
});

export const { setRemoveCart, setloading, seterror, resetRemoveCart } = RemoveCartSlice.actions;

export default RemoveCartSlice.reducer;
