import React from 'react';
import { google , devicon_linkedin, apple_icon} from '../../assets/images/images';
import "./LinkedAccounts.css";

const LinkedAccounts=() => { return( 

    <div className='Linked-Accounts-connection-section'>
        <h2>Linked Accounts</h2>
        <div className='Linked-Accounts-wrapper'>
            <div className='Linked-Accounts-container'>
                <div className='Linked-Accounts-folder'>
                    <div className='Linked-Accounts-folder-icon'>
                        <img src={google} alt='Linked-Accounts-folder-icon' />
                    </div>
                    <p>Sign with Google</p>
                </div>
                <button>Disconnect</button>
            </div>
            <div className='Linked-Accounts-container'>
                <div className='Linked-Accounts-folder'>
                    <div className='Linked-Accounts-folder-icon'>
                        <img src={devicon_linkedin} alt='Linked-Accounts-folder-icon' />
                    </div>
                    <p>Connect with LinkedIn</p>
                </div>
                <button>Connect</button>
            </div>
            <div className='Linked-Accounts-container'>
                <div className='Linked-Accounts-folder'>
                    <div className='Linked-Accounts-folder-icon'>
                        <img src={apple_icon} alt='Linked-Accounts-folder-icon' />
                    </div>
                    <p>Sign with Apple</p>
                </div>
                <button>Disconnect</button>
            </div>
        </div>
    </div>
)}

export default LinkedAccounts;  