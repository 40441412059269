import React, { useEffect, useState } from "react";
import { FormControl, TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Modal } from "react-responsive-modal";
import { useLocation } from "react-router-dom";
import CourseDisplayCard from "./CourseDisplayCard";
import api from "../../api/api";
import { updateGlobalState } from "../../state/global/slice";
import { useAuth } from "../../features/auth/AuthContext";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import { setActiveTabState } from "../../state/reducers/activeTabReducer";
import CancelPopup from "../CancelPopup/CancelPopup";
import CongratulationsPopup from "../CongratulationPopup/Congratulations";
import check_image from "../../assets/images/check-icno-new.png";
import "./TeacheraMyCourseTab.css";
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import { inactive_image } from "../../assets/images/images";

function TeacherMyCourseTab() {
  const { token } = useAuth();
  const dispatch = useDispatch();
  const tabs = ["active", "inActive", "expired"];
  const location = useLocation();

  const globalState = useSelector((state) => state.globalState);
  const { activeTabState } = useSelector((state) => state.activeTabState);

  const [activeTab, setActiveTab] = useState(activeTabState);
  const [republishPopup, setRepublishPopup] = useState(false);
  const [republishConfirmPopup, setRepublishConfirmPopup] = useState(false);
  const [republishSuccessPopup, setRepublishSucessPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState({});
  const [emptyState, setEmptyState] = useState("");
  const [activate, setActivate] = useState(false);
  const [inActivate, setInActivate] = useState(false);
  const [activeCourseStatus, setActiveCourseStatus] = useState({});
  const [inactiveCourseStatus, setInActiveCourseStatus] = useState({});
  const [activeInactivePopup, setActiveInactivePopup] = useState(false);
  const [pageScroll, setPageScroll] = useState(0);

  const [popupId, setPopupId] = useState("");
  const [activeInactiveSuccessPopup, setActiveInactiveSuccessPopup] = useState(
    false
  );
  const [refreshCourses, setRefreshCourses] = useState(false);
  const [open2, setopen2] = useState(false);

  // useEffect(() => {
  //   // window.scrollTo(0, pageScroll);
  //   console.warn(window.scrollY);
  // }, [window])

  const getCoursesData = async (tabName) => {
    setLoading(true);
    const teacher_id = globalState.teacher_details?.data?.teacher_id ?? "";
    const url = `/courses/get-all-courses/teacher/${teacher_id}/?type=${tabName.toLowerCase()}`;
    api
      .get(url, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (Number(response.status) === 200) {
          if (response?.data) {
            setEmptyState("");
            updateState(
              "course_list",
              JSON.parse(JSON.stringify(response?.data))?.data ?? []
            );
          }
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        updateState("course_list", []);
        setEmptyState(error?.response?.data?.message);
        console.error(error);
      });
  };

  const handleRepublish = async () => {
    let payload = new FormData();
    payload.append(
      "end_date",
      format(new Date(formState.end_date ?? ""), "yyyy-MM-dd")
    );
    try {
      const response = await api.put(
        `/courses/update-course/${formState.course_id}/`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        setRepublishPopup(false);
        setRepublishSucessPopup(true);
        getCoursesData("expired");
        setTimeout(() => {
          setRepublishSucessPopup(false);
        }, 5000);
      } else {
        toast.error("Failed to republish course.", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateState = (key, value) => {
    dispatch(updateGlobalState({ key: key, value: value }));
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    dispatch(setActiveTabState(tabName));
  };

  const handleRepublishPopup = (course_details) => {
    setFormState(course_details);
    setRepublishConfirmPopup(true);
  };

  const handleActivate = (course_details) => {
    setPopupId(course_details.course_id);
    setActivate(true);
    setInActivate(false);
    setActiveInactivePopup(true);
  };

  const handleInActivate = (course_details) => {
    setPopupId(course_details.course_id);
    setInActivate(true);
    setActivate(false);
    setActiveInactivePopup(true);
  };

  const handleActivateInactivateAPI = async (activate, inActivate, popupId) => {
    let payload = new FormData();
    activate
      ? payload.append("is_active", true)
      : payload.append("is_active", false);
    try {
      const response = await api.put(
        `/courses/update-course/${popupId}/`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        activate
          ? setActiveCourseStatus((prevState) => ({
            ...prevState,
            [popupId]: !prevState[popupId],
          }))
          : setInActiveCourseStatus((prevState) => ({
            ...prevState,
            [popupId]: !prevState[popupId],
          }));
        setActiveInactivePopup(false);
        setActiveInactiveSuccessPopup(true);
        getCoursesData(activeTab);
        setTimeout(() => {
          setActiveInactiveSuccessPopup(false);
        }, 2000);
        setPopupId("");
      } else {
        toast.error("Failed to update course.", {
          position: toast.POSITION.TOP_CENTER,
        });
        setActiveInactivePopup(false);
        setPopupId("");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCoursesData(activeTab);
  }, [activeTab]);

  useEffect(() => {
    if (!location.pathname.includes("/dashboard") || refreshCourses) {
      getCoursesData(activeTab);
      setRefreshCourses(false);
    }
  });

  const handleClose = () => {
    setRepublishConfirmPopup(false); // Assuming you want to reset this as well
  };



  useEffect(() => {
    const updateScrollPosition = () => {
      setPageScroll(window.scrollY);
    };
    window.addEventListener('scroll', updateScrollPosition);

    return () => {
      window.removeEventListener('scroll', updateScrollPosition);
    };
  });

  // console.warn(pageScroll);

  useEffect(() => {
    window.scrollTo(0, pageScroll);
  }, [pageScroll]);

  return (
    <div className="Teacher_My_Course-section">
      <div className="Teacher_My_Course-tabs">
        <div className="view-lessons-wrapper">
          {
            <div className="view-lessons-btn-container teacehr-tab-setion">
              {tabs?.map((tabName) => (
                <button
                  key={tabName}
                  className={`tab-button ${activeTab === tabName ? "active" : ""
                    }`}
                  onClick={() => handleTabClick(tabName)}
                >
                  {tabName === "active"
                    ? "Active Courses"
                    : tabName === "inActive"
                      ? "Inactive Courses"
                      : "Expired Courses"}
                </button>
              ))}
            </div>
          }
          <div className="tab-content">
            <div className="Teacher-My-Course-Tab-card-wrapper">
              {loading ? (
                <LoadingComponent loading_text="Educating the Future, Loading Now." />
              ) : globalState?.course_list && emptyState?.length === 0 ? (
                globalState?.course_list?.map((course) => {
                  let active_or_inactive_tab;
                  let Republish_btn;
                  switch (activeTab) {
                    case "active":
                      active_or_inactive_tab = "Inactive Course";
                      Republish_btn = null;
                      break;
                    case "inActive":
                      active_or_inactive_tab = "Activate Course";
                      Republish_btn = null;
                      break;
                    case "expired":
                      active_or_inactive_tab = null;
                      Republish_btn = "Re-publish";
                      break;
                    default:
                      active_or_inactive_tab = null;
                      Republish_btn = null;
                  }
                  const {
                    course_id,
                    image: courseimage,
                    name: coursename,
                    enrolled_students: totalstudents,
                    lesson_count: totalLesson,
                    description,
                    teacher: Teacher_name,
                    start_date,
                    end_date,
                    teacher_profile,
                    rating_count,
                    course_rating,
                    price,
                    total_revenue,
                  } = course;

                  return (
                    <CourseDisplayCard
                      key={course_id}
                      course_id={course_id}
                      courseimage={courseimage}
                      coursename={coursename}
                      totalstudents={totalstudents}
                      totalLesson={totalLesson}
                      description={description}
                      Teacher_title="Teacher"
                      Teacher_name={Teacher_name}
                      createdcourse={dayjs(start_date).format("MMM DD, YYYY")}
                      course_details={course}
                      rate_number={rating_count}
                      rate_count={course_rating}
                      active_or_inactive_tab={active_or_inactive_tab}
                      Republish_btn={Republish_btn}
                      end_date={dayjs(end_date).format("MMM DD, YYYY")}
                      course_info_image={teacher_profile}
                      handleRepublishPopup={handleRepublishPopup}
                      handleActivate={handleActivate}
                      handleInActivate={handleInActivate}
                      activeCourseStatus={activeCourseStatus}
                      setActiveCourseStatus={setActiveCourseStatus}
                      inactiveCourseStatus={inactiveCourseStatus}
                      setInActiveCourseStatus={setInActiveCourseStatus}
                      setRefreshCourses={setRefreshCourses}
                      activeTab={activeTab}
                      pageScroll={pageScroll}
                      setPageScroll={setPageScroll}
                      price={price}
                      total_revenue={total_revenue}
                    />
                  );
                })
              ) : (
                <div className="inactive-image-folder">
                  <div className="inactive-image">
                    <img src={inactive_image} />
                  </div>
                      <p>No Courses Found</p>
                  {/* <p>{emptyState}</p> */}
                </div>
              )}
            </div>
          </div>
        </div>
        {(activate || inActivate) && activeInactivePopup && (
          <Modal
          open={activeInactivePopup}
          onClose={()=>setActiveInactivePopup(false)}
          center
          showCloseIcon={false}
          classNames={{
            modal: 'Cancel-Popup',
          }}
        >
          <CancelPopup
            title={`Are you sure you want to make this course ${
              activate ? "active" : inActivate ? "inactive" : ""
            } ?`}
            No="Back"
            Yes="Yes"
            show={() =>
              handleActivateInactivateAPI(activate, inActivate, popupId)
            }
            close={() => {
              setActiveInactivePopup(false);
              setInActiveCourseStatus((prevState) => ({
                ...prevState,
                [popupId]: !prevState[popupId],
              }));
              setActiveCourseStatus((prevState) => ({
                ...prevState,
                [popupId]: !prevState[popupId],
              }));
            }}
          />
          </Modal>
        )}
        {activeInactiveSuccessPopup && (
          <CongratulationsPopup
            check_image={check_image}
            des={`Your course has been successfully ${
              activate ? "activated" : inActivate ? "inactivated" : ""
            }.`}
          />
        )}
        {republishConfirmPopup && (
            <Modal
            open={republishConfirmPopup}
            onClose={()=>setRepublishConfirmPopup(false)}
            center
            showCloseIcon={false}
            classNames={{
              modal: 'Cancel-Popup',
            }}
          >
            <CancelPopup
              title={`Are you sure you want to make this course ${activate ? "active" : inActivate ? "inactive" : ""
                } ?`}
              No="Back"
              Yes="Yes"
              show={() =>
                handleActivateInactivateAPI(activate, inActivate, popupId)
              }
              close={() => {
                setActiveInactivePopup(false);
                setInActiveCourseStatus((prevState) => ({
                  ...prevState,
                  [popupId]: !prevState[popupId],
                }));
                setActiveCourseStatus((prevState) => ({
                  ...prevState,
                  [popupId]: !prevState[popupId],
                }));
              }}
            />
          </Modal>
        )}
        {activeInactiveSuccessPopup && (
          <CongratulationsPopup
            check_image={check_image}
            des={`Your course has been successfully ${activate ? "actived" : inActivate ? "inactived" : ""
              }.`}
          />
        )}
        {republishConfirmPopup && (
          <Modal
            open={republishConfirmPopup}
            onClose={() => setRepublishConfirmPopup(false)}
            center
            showCloseIcon={false}
            classNames={{
              modal: 'Cancel-Popup',
            }}
          >
            <CancelPopup
              title="Are you sure you want to re-publish this course?"
              No="Back"
              Yes="Yes"
              show={() => {
                setRepublishConfirmPopup(false);
                setRepublishPopup(true);
              }}
              close={() => {
                setRepublishConfirmPopup(false);
              }}
            />
          </Modal>
        )}
        {republishSuccessPopup && (
          <CongratulationsPopup
            check_image={check_image}
            des="Your course has been successfully re-published!"
          />
        )}
        {republishPopup && (
          <div className="Republish-course-popup-wrapper">
            <div className="Republish-course-popup-folder">
              <div className="Republish-course-popup-back-btn">
                <i
                  class="fa-solid fa-chevron-left"
                  onClick={() => setRepublishPopup(false)}
                ></i>
                <h2>Select course start and end date</h2>
              </div>
              <div className="Republish-course-date-container">
                {/* <div className="Republish-Start-Date-input">
                  <label>Course Start Date</label>
                  <FormControl margin="none" fullWidth>
                    <DesktopDatePicker
                      disabled
                      name="start_date"
                      variant="outlined"
                      value={
                        dayjs(formState.start_date)
                      }
                      // onChange={(date) =>
                      //   setFormState({ ...formState, start_date: date })
                      // }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          disabled
                          onKeyDown={(e) => e.preventDefault()}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              borderRadius: "12px",
                              backgroundColor: "#F5F5F5",
                            },
                          }}
                          size="small"
                        />
                      )}
                      slotProps={{
                        inputAdornment: {
                          position: "start",
                        },
                        textField: {
                          size: "small",
                          InputProps: {
                            sx: {
                              borderRadius: "12px",
                              backgroundColor: "#F5F5F5",
                            },
                          },
                        },
                      }}
                    />
                  </FormControl>
                </div> */}
                <div className="Republish-end-Date-input">
                  <label>Course End Date</label>
                  <FormControl margin="none" fullWidth>
                    <DesktopDatePicker
                      name="end_date"
                      variant="outlined"
                      value={
                        formState.end_date === ""
                          ? null
                          : dayjs(formState.end_date)
                      }
                      onChange={(date) =>
                        setFormState({ ...formState, end_date: date })
                      }
                      open={open2}
                      onOpen={() => setopen2(true)}
                      onClose={() => setopen2(false)}
                      minDate={dayjs(new Date())}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onKeyDown={(e) => e.preventDefault()}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              borderRadius: "12px",
                              backgroundColor: "#F5F5F5",
                            },
                          }}
                          size="small"
                        />
                      )}
                      slotProps={{
                        field: { setopen2 },
                        inputAdornment: {
                          position: "start",
                        },
                        textField: {
                          onClick: () => setopen2(true),
                          size: "small",
                          InputProps: {
                            sx: {
                              borderRadius: "12px",
                              backgroundColor: "#F5F5F5",
                            },
                          },
                        },
                      }}
                    />
                  </FormControl>
                </div>
              </div>
              <button
                type="button"
                className="Republish_btn"
                onClick={handleRepublish}
              >
                Republish
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default TeacherMyCourseTab;
