import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import api, { wsURL } from "../../../../../../api/api";
import AssignmentTimer from "../../../../../../components/assignmentTimer/AssignmentTimer";
import { useAuth } from "../../../../../../features/auth/AuthContext";
import useSubmitAssignment from "../../../../../../hooks/useAssignmentSubmission";
import useWebSocketConnection from "../../../../../../hooks/useWebSOcketConnection";
import Question from "../../../questions/Question";
import StudentAssignmentScore from "./StudentAssignmentScore";
import BackButton from "../../../../../../components/BackButton/BackButton";
import QuestionNew from "../../../questions/Question_new";
import { CircularProgress, Grid } from "@mui/material";
import { Modal } from "react-responsive-modal";
import AssignmentRatingPopup from "../../../../../../components/AssignmentRatingPopup/AssignmentRatingPopup";
import { toast } from "react-toastify";
import StudentLessonPreview from "./StudentLessonPreview";
import {
  getPracticeAssignments,
  resetPracticeAssignmentsList,
} from "../../../../../../state/actions/getPracticeAssignmentsAction";
import "./StudentAssignmentPreview.css";
import ShowRating from "../../../dashboard/ShowRating/ShowRating";
import CompleteRating from "../../../dashboard/ShowRating/CompleteRating";
import PracticeAssignmentPopup from "./PracticeAssignmentPopup/PracticeAssignmentPopup";

const StudentAssignmentPreview = () => {
  const globalState = useSelector((state) => state.globalState);
  const studentId = globalState.student_details?.data?.student_id ?? "";
  const navigate = useNavigate();
  const location = useLocation();
  const { course_id, lesson_id } = useParams();
  const dispatch = useDispatch();
  const { token } = useAuth();
  const practiceAssignments = useSelector(
    (state) => state.getPracticeAssignments
  );
  const [assignment, setAssignment] = useState(
    location?.state?.assignment || {}
  );


  // const [assignment, setpractices] = useState(
  //   location?.state?.type || {}
  // );
  const [assignmentDtls, setAssignmentDtls] = useState(
    location?.state?.assignmentDtls || {}
  );

  const assignment_id =
    location?.state?.type === 1 ? assignment?.assignment_id : assignment?.id;
  const lesson = location?.state?.lesson;
  const countCompletedLessons = (assignemnetsDtls) => {
    // if(assignemnetsDtls){
    //   return assignemnetsDtls.filter(assignemnet => assignemnet?.assignment_status?.status === "Completed").length;
    // }
    if (Array.isArray(assignemnetsDtls) && assignemnetsDtls.length) {
      return assignemnetsDtls.filter(
        (assignment) => assignment?.assignment_status?.status === "Completed"
      ).length;
    }
    return 0;
  };
  const [totalCompltedAssignment, setTotalCompletedAssignment] = useState(
    countCompletedLessons(assignmentDtls)
  );
  const [assignmentIndex, setAssignmentIndex] = useState(0);
  const [detailedResult, setdetailedResult] = useState(false);
  const [showScorePopup, setshowScorePopup] = useState(false);
  const [answeredList, setAnsweredList] = useState([]);
  const [getSubmissiondtls, setSubmissiondtls] = useState([]);
  const [congratulationMessgae, setcongratulationMessgae] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAssignmentLoading, setisAssignmentLoading] = useState(false);
  const [showSubmit, setShowPopup] = useState(false);
  const [assignmentClockTimer, setassignmentClockTimer] = useState(true);
  const [isTimerPaused, setIsTimerPaused] = useState(false);
  const [lessonRating, setLessonRating] = useState(0);
  const [lessonRatingComment, setLessonRatingComment] = useState("");
  const [currentStep, setCurrentStep] = useState(0);
  const [isLessonPreviewEnabled, setIsLessonPreviewEnabled] = useState(false);
  const [
    isVirtualAssistantPreviewEnabled,
    setIsVirtualAssistantPreviewEnabled,
  ] = useState(false);
  const [newOpen, setNewOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [timers, setTimers] = useState(
    Array(assignment?.assignment_json?.length || 0).fill(0)
  );
  // const [newOpen, setNewOpen] = useState(false);
  const assignmentJSON = assignment?.assignment_json?.map((item) => {
    return { ...item, student_answer: null, time_taken: "0" };
  });

  const [startIndex, setStartIndex] = useState(1);
  const [lastIndex, setLastIndex] = useState(assignmentJSON?.length);
  const [rangIndex, setRangIndex] = useState(4);
  useEffect(() => {
    const sAPDtls_AssignmentId = localStorage.getItem(
      `sAPDtls_AssignmentId${assignment_id}${token}`
    );
    if (sAPDtls_AssignmentId && sAPDtls_AssignmentId == assignment_id) {
      const sAProgressDtls = localStorage.getItem(
        `studentAssignmentProgressDtls${assignment_id}${token}`
      );
      if (sAProgressDtls) {
        setAnsweredList(JSON.parse(sAProgressDtls));
        const sAPCurrentStep = localStorage.getItem(
          `studentAssignmentProgressCurrentStep${assignment_id}${token}`
        );
        setCurrentStep(Number(sAPCurrentStep));
        const sAPCurrentStepForQuestion = localStorage.getItem(
          `studentAssignmentProgressCurrentStepForQuestion${assignment_id}${token}`
        );
        setStartIndex(sAPCurrentStepForQuestion);
        // const sAPFinalStep = localStorage.getItem(
        //   `studentAssignmentProgressFinalStep${assignment_id}`
        // );
        // if (sAPFinalStep) {
        //   setShowPopup(sAPFinalStep === "true");
        // }
      }
    }
  }, []);
  useEffect(() => {
    // console.log("assignment_id", assignment_id);
    if (location?.state?.type === 1) {
      if (assignment_id) {
        getAssignment(assignment_id);
      }
    }
    if (location?.state?.type === 2) {
      dispatch(
        getPracticeAssignments(
          location?.state?.assignment?.assignment_id,
          token
        )
      );
    }
    // countCompletedLessons(assignmentDtls);
  }, [lesson_id, assignment_id]);

  useEffect(() => {
    if (practiceAssignments.practiceassignments.length > 0) {
      setisAssignmentLoading(false);


      const matchingPracAssigns = practiceAssignments.practiceassignments.filter(
        (pracAssign) => pracAssign.id === location?.state?.assignment?.id
      );
      const totallengthAssignment = (matchingPracAssigns.length - 1);
      let pracAssign = matchingPracAssigns[totallengthAssignment];
      setAssignment(pracAssign);

      setIsLessonPreviewEnabled(pracAssign?.lesson_preview);
      setIsVirtualAssistantPreviewEnabled(pracAssign?.virtual_assistant);
      if (
        !pracAssign ||
        !pracAssign.assignment_status ||
        !pracAssign.assignment_status.status
      ) {
        // console.log("Triggering createAssignmentStatus");
        createAssignmentStatus(location?.state?.assignment?.id);
      }
    }
  }, [practiceAssignments]);

  const getAssignment = async (assignment_id) => {
    setisAssignmentLoading(true);
    await api
      .get(`assignments/get-assignment/${assignment_id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setisAssignmentLoading(false);
          setAssignment(response?.data?.data);
          setIsLessonPreviewEnabled(response?.data?.data?.lesson_preview);
          setIsVirtualAssistantPreviewEnabled(
            response?.data?.data?.virtual_assistant
          );
          const assignmentData = response?.data?.data;
          if (
            !assignmentData ||
            !assignmentData.assignment_status ||
            !assignmentData.assignment_status.status
          ) {
            // console.log("Triggering createAssignmentStatus");
            createAssignmentStatus(assignment_id);
          }
        }
      })
      .catch(() => {
        setisAssignmentLoading(false);
      });
  };

  const [submission_id, setSubmission_id] = useState(0);
  // const [timers, setTimers] = useState(Array(assignment?.assignment_json?.length).fill(0));
  const totalSteps = assignmentJSON?.length;
  const [getSocket, setSocket] = useState(null);

  const handleNext = async (answeredQuestion) => {
    const existingIndex = answeredList.findIndex(
      (question) => question.question_id === answeredQuestion.question_id
    );

    let updatedList;
    if (existingIndex !== -1) {
      updatedList = answeredList.map((question, index) =>
        index === existingIndex ? answeredQuestion : question
      );
    } else {
      updatedList = [...answeredList, answeredQuestion];
    }

    setAnsweredList(updatedList);
    localStorage.setItem(
      `sAPDtls_AssignmentId${assignment_id}${token}`,
      assignment_id
    );
    localStorage.setItem(
      `studentAssignmentProgressDtls${assignment_id}${token}`,
      JSON.stringify(updatedList)
    );
    if (currentStep < totalSteps - 1) {
      setCurrentStep(currentStep + 1);
      localStorage.setItem(
        `studentAssignmentProgressCurrentStep${assignment_id}${token}`,
        currentStep + 1
      );
      setStartIndex(startIndex + 1);
      localStorage.setItem(
        `studentAssignmentProgressCurrentStepForQuestion${assignment_id}${token}`,
        startIndex
      );
      // localStorage.setItem(
      //   `studentAssignmentProgressFinalStep${assignment_id}`,
      //   false
      // );
    } else {
      setShowPopup(true);
      // localStorage.setItem(
      //   `studentAssignmentProgressFinalStep${assignment_id}`,
      //   true
      // );
      setassignmentClockTimer((prevIsRunning) => !prevIsRunning);
    }
  };

  const socket = useWebSocketConnection(
    `${wsURL}//ws/assignment_submission/${parseInt(studentId)}/?token=${token}`
  );
  const { isLoadingAssignment, error, submitAssignment } = useSubmitAssignment(
    socket
  );

  // useEffect(() => {
  //   if (socket) {
  //     socket.onmessage = handleWebSocketMessage;
  //     socket.onerror = (event) => {
  //       setIsLoading(false);
  //       console.error("WebSocket error observed:", event.message);
  //     };
  //   }
  // }, [socket]);
  useEffect(() => {
    const initializeWebSocket = () => {
      if (socket) {
        let reconnectInterval = 1000; // Initial reconnect interval in ms
        const maxReconnectInterval = 30000; // Max reconnect interval
        let reconnectAttempts = 0; // Number of reconnect attempts
        const reconnectDecay = 1.5; // Rate of increase of the reconnect delay
        const maxReconnectAttempts = 3; // Maximum number of reconnect attempts

        //  Event handler for when the WebSocket connection is established
        socket.onopen = () => {
          console.log("WebSocket connection opened");
          reconnectAttempts = 0; // Reset reconnect attempts on successful connection
          reconnectInterval = 1000; // Reset reconnect interval
        };

        socket.onmessage = handleWebSocketMessage;

        socket.onclose = () => {
          if (reconnectAttempts < maxReconnectAttempts) {
            setTimeout(() => {
              reconnectAttempts++;
              socket.close(); // Make sure to close old sockets if not yet closed
              initializeWebSocket();
              reconnectInterval = Math.min(
                reconnectInterval * reconnectDecay,
                maxReconnectInterval
              );
            }, reconnectInterval);
          } else {
            console.log("WebSocket connection closed");
            console.log(
              "Max reconnect attempts reached, will not attempt further reconnects."
            );
          }
        };

        socket.onerror = (event) => {
          setIsLoading(false);
          // Handle WebSocket errors
          console.error("WebSocket error observed:", event.message);
        };
      }
    };
    initializeWebSocket();
  }, [socket]);

  const handleSubmitAssignment = async (questionAnswers) => {
    if (
      !globalState?.subdomain_exists &&
      assignment?.rating === null &&
      lesson?.last_lesson &&
      assignment?.last_assignment && location?.state?.type === 1
    ) {
      setOpen(true);
      setIsLoading(false);
    } else {
      handleAssignmentsubmission(questionAnswers);
    }

    //Websocket Flow of Submission
    // try {
    //   // Assuming you have a WebSocket connection stored in a variable named 'socket'
    //   submitAssignment(payload);
    //   localStorage.setItem("timerSeconds", null);
    // } catch (error) {
    //   setIsLoading(false);
    //   localStorage.setItem("timerSeconds", null);
    //   console.error("WebSocket error", error);
    // }
    //Websocket Flow of Submission
  };

  const handleAssignmentsubmission = async (questionAnswers) => {
    setIsLoading(true);
    setIsTimerPaused(true);

    const payload = {
      student_id: parseInt(studentId),
      assignment_id:
        location?.state?.type === 1
          ? assignment?.assignment_id
          : assignment?.id,
      rubric_id: assignment?.rubric_id ?? null,
      questions_answers: questionAnswers ?? answeredList,
      assignment_type: location?.state?.type,
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };

    try {
      submitAssignment(payload);
      // const response = await api.post(
      //   "/assignments/assignment_submission/",
      //   payload,
      //   { headers }
      // );

      // if (response.status === 200 || response.status === 201 || response.status === 202) {

      //   // const assignmentStatusId = localStorage.getItem("assignmentStatusId");
      //   const grade = '';
      //   await updateAssignmentStatus(assignment_id, grade);
      //   setIsLoading(false);
      //   setShowPopup(false);
      //   setcongratulationMessgae(true);
      // } else {
      //   setIsLoading(false);
      //   console.error("Submission failed", response);
      //   setShowPopup(false);
      // }
    } catch (error) {
      setIsLoading(false);
      console.error("Submission error", error);
      setShowPopup(false);
    }
  };

  console.log("assignment in Ass", assignment, location?.state?.assignment)

  const createAssignmentStatus = async (assignment_id) => {
    const payload = {
      student_id: globalState.student_details.data.student_id,
      assignment_id: assignment_id,
      status: 3,
      completed_name: assignment.name,
      assignment_type: location?.state?.type,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    await api
      .post(`assignments/create-assignment-status/`, payload, {
        headers,
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          localStorage.setItem("assignmentStatusId", response?.data?.data?.id);
        }
      })
      .catch(() => { });
  };

  const updateAssignmentStatus = async (status_id, score = "") => {
    const payload = {
      status: 1,
      score: score,
      completed_at: new Date().toISOString(),
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    await api
      .put(`assignments/update-assignment-status/${status_id}/`, payload, {
        headers,
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          localStorage.removeItem("assignmentStatusId");
        }
      })
      .catch(() => { });
  };

  const handleWebSocketMessage = async (event) => {
    try {
      // Parse the incoming JSON message
      const data = JSON.parse(event.data);
      // const assignmentStatusId = localStorage.getItem("assignmentStatusId");
      // Check if the message represents a successful submission
      setIsLoading(false);
      setShowPopup(false);
      setcongratulationMessgae(true);
      setSubmissiondtls(data);
      // const grade = Object.keys(data.grade)[0];
      if (location?.state?.type === 1) {
        console.log(location?.state?.assignment?.assignment_id);
        await getAssignment(location?.state?.assignment?.assignment_id);
      }
      if (location?.state?.type === 2) {
        dispatch(
          getPracticeAssignments(
            location?.state?.assignment?.assignment_id,
            token
          )
        );
        // let pracAssign = practiceAssignments.practiceassignments.find(
        //   (pracAssign) => pracAssign.id === location?.state?.assignment?.id
        // );
        // console.log('pracAssign',pracAssign);
        // console.log('location?.state?.assignment?.id',location?.state?.assignment?.id);
        // setAssignment(pracAssign);
      }
      // await updateAssignmentStatus(assignmentStatusId, grade);
      // You can call your putAssignmentStatus function here if needed
    } catch (error) {
      setIsLoading(false);
      console.error("WebSocket message error", error);
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      setStartIndex(startIndex - 1);
      setIsTimerPaused(false);
    }
  };

  const handleTimeUp = () => {
    // const resultList = assignmentJSON?.filter((item) => {
    //   // Check if the Question_id of the item is not in attemptedList
    //   return !answeredList.some(
    //     (attemptedItem) => attemptedItem.question_id === item.question_id
    //   );
    // });
    // setShowPopup(true);
    // localStorage.setItem("timerSeconds", null);
    // handleSubmitAssignment([...resultList, ...answeredList]);
  };

  const isLastQuestion = assignmentJSON?.length === currentStep + 1;

  const showScore = () => {
    setcongratulationMessgae(true);
  };

  const showDetailedResult = () => {
    setAssignmentIndex(0);
    setdetailedResult(!detailedResult);
    setshowScorePopup(false);
  };

  // const handleSubmitLessonRating = async (token) => {
  //   // setIsLoading(true)
  //   const payload = {
  //     teacher: lesson.teacher?.teacher_id,
  //     rating: lessonRating,
  //     course: course_id,
  //     comments: lessonRatingComment,
  //     lesson: lesson_id,
  //   };
  //   const headers = {
  //     "Content-Type": "application/json",
  //     Authorization: `Token ${token}`,
  //   };
  //   try {
  //     const response = await api.post(
  //       `/rating/teacher-rating-by-student/`,
  //       payload,
  //       { headers }
  //     );
  //     if (response.status === 200 || response.status === 201) {
  //       setNewOpen(false);
  //       handleAssignmentsubmission();
  //     } else {
  //       toast.error("Failed to rate the lesson. Please try again", {
  //         position: "top-center",
  //       });
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     return null;
  //   }
  // };

  const handleViewResultClicked = (lesson) => {
    console.log(lesson);
    setIsLoading(true);
    setNewOpen(false);
  };

  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [showStudentLessonPreview, setShowStudentLessonPreview] = useState(
    isSwitchOn
  );

  useEffect(() => {
    setShowStudentLessonPreview(isSwitchOn);
  }, [isSwitchOn]);

  const handleSwitchToggle = () => {
    setIsSwitchOn(!isSwitchOn);
  };

  // useEffect(() => {
  //   if (!isLoading && !showSubmit) {
  //     setcongratulationMessgae(true);
  //   }
  // }, [isLoading, showSubmit])

  const getStepNumbers = () => {
    const steps = [];
    const currentIndexVal = currentStep + 1;
    const middleRange = Math.floor(rangIndex / 2);
    let start = Math.max(1, currentIndexVal - middleRange);
    let end = Math.min(lastIndex, currentIndexVal + middleRange);

    if (currentIndexVal <= middleRange) {
      end = Math.min(rangIndex, lastIndex);
    } else if (currentIndexVal >= lastIndex - middleRange) {
      start = Math.max(1, lastIndex - rangIndex + 1);
    }

    if (start > 1) steps.push(1);
    if (start > 2) steps.push("...");

    for (let i = start; i <= end; i++) {
      steps.push(i);
    }

    if (end < lastIndex - 1) steps.push("...");
    if (end < lastIndex) steps.push(lastIndex);

    return steps;
  };

  useEffect(() => {
    if (newOpen) {
      handleAssignmentsubmission()
      setTimeout(() => {
        setNewOpen(false)
      }, 3000)
    }
  }, [newOpen])

  return (
    <>
      <div id="AssingmentMCQs-section">
        <div className="AssingmentMCQs-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="AssingmentMCQs-header-main-contaiiner">
                  <div
                    className="view-course-Details-back-btn-folder"
                    id="AssingmentMCQs-back-btn"
                  >
                    <BackButton buttonName={assignment?.name} />
                  </div>
                  {/* <h2>Assignment: {assignment.name}</h2> */}
                  {isLessonPreviewEnabled && (
                    <div className="AssingmentMCQs-show-lesson-preview">
                      <h2>Show Lesson Preview</h2>
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={isSwitchOn}
                          onChange={handleSwitchToggle} // Use onChange event
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  )}

                  {/* <AssignmentTimer
                    duration={(assignment?.duration_minutes ?? 0) * 60}
                    onTimeUp={handleTimeUp}
                    isTimerPaused={isTimerPaused}
                  /> */}
                </div>
                {isAssignmentLoading ? (
                  <>
                    <p style={{ textAlign: "center" }}>
                      Loading Assignment... Please wait!
                    </p>
                    <Grid
                      height={"50vh"}
                      xs={12}
                      container
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Grid item xs={12}>
                        <CircularProgress size={80} />
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <div className="AssingmentMCQs-container">
                    {showStudentLessonPreview && (
                      <StudentLessonPreview lesson={lesson} />
                    )}
                    <div
                      className={`AssingmentMCQs-right-section ${showStudentLessonPreview ? "" : "full-width"
                        }`}
                    >
                      <AssignmentTimer
                        duration={(assignment?.duration_minutes ?? 0) * 60}
                        allotted_time={assignment?.duration_minutes}
                        onTimeUp={handleTimeUp}
                        isTimerPaused={isTimerPaused}
                        timers={timers}
                        setTimers={setTimers}
                        currentStep={currentStep}
                        isAssignmentLoading={isAssignmentLoading}
                        assignmentClockTimer={assignmentClockTimer}
                        isPracticeAssignment={location?.state?.type === 1 ? true : false}
                      />
                      <div className="AssingmentMCQs-main-wrapper">
                        <div className="step-container">
                          {/* {assignmentJSON?.map((assignment, index) => (
                              <div
                                key={index + 1}
                                className={`step ${currentStep >= index ? "active" : ""
                                  }`}
                              >
                                {index + 1}
                              </div>
                            ))} */}
                          {getStepNumbers().map((step, index) => {
                            const isAnswered = answeredList.some(
                              (question) => question.question_id === step
                            );
                            const stepchecker =
                              currentStep + 1 === step ? true : false;
                            return (
                              <div
                                key={index}
                                className={`step ${stepchecker ? "active" : ""
                                  } ${isAnswered && !stepchecker ? "answered" : ""
                                  }`}
                              >
                                {step}
                              </div>
                            );
                          })}
                        </div>
                        {assignmentJSON?.map((assign, index) => (
                          <div key={index}>
                            <Question
                              duration={
                                (assignment?.duration_minutes ?? 0) * 60
                              }
                              assignment={assign}
                              index={index}
                              currentStep={currentStep}
                              handleBack={handleBack}
                              handleNext={handleNext}
                              isLast={isLastQuestion}
                              lesson={lesson ? lesson : location?.state?.lesson}
                              course_id={course_id}
                              lesson_id={lesson_id}
                              assignment_id={assignment_id}
                              timers={timers}
                              virtualAssistant={
                                isVirtualAssistantPreviewEnabled
                              }
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {open && (
          <ShowRating
            lesson={lesson}
            course_id={course_id}
            lesson_id={lesson_id}
            setOpen={setOpen}
            setNewOpen={setNewOpen}
            open={open}
          />
        )}
        {newOpen && (
          <CompleteRating
            lesson={lesson}
            course_id={course_id}
            lesson_id={lesson_id}
            setOpen={setOpen}
            setNewOpen={setNewOpen}
            newOpen={newOpen}
            showCloseButton={false}
          />
        )}
        {congratulationMessgae && (
          <StudentAssignmentScore
            showScore={showScore}
            getSubmissiondtls={getSubmissiondtls}
            onclick={() =>
              navigate(`/course/${course_id}/lesson/${lesson_id}/overview`, {
                state: {
                  course: location?.state?.course, // Passing student name
                  lesson: location?.state?.lesson,
                  activeTab: "Assignments",
                },
              })
            }
            // onclick={() => navigate(-2)}
            title={"Go to Assignments"}
            isCurrentAssignment={true}
            onclick2={showDetailedResult}
            title2={"View Result"}
            title3={"Practice Assignment"}
            assignment={assignment}
          />
        )}
        <div className="assignmen-submit-popup">
          {showSubmit && (
            <form
              action=""
              className="delete-course-form"
              id="delete-AssingmentMCQs-form"
            >
              <div className="delete-course-form-container">
                {isLoading ? (
                  <>
                    <p>
                      Submission is in progress. Please keep this browser tab
                      open to ensure your submission completes successfully.
                    </p>
                    <Grid
                      height={"50vh"}
                      xs={12}
                      container
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <CircularProgress size={80} />
                    </Grid>
                    {/* <p className="assignment_loading_text">
                      "Beyond Submission, Towards success"
                    </p> */}
                    {/* <button style={{marginTop:"25px"}}
                    onClick={() => navigate(-2)}
                    type="button"
                    className="View-Course"
                  >
                    Go to Course
                  </button> */}
                  </>
                ) : (
                  <>
                    <h2>Are you sure you want to submit the assignment?</h2>
                    <div className="delete-course-form-btn-folder">
                      <button
                        type="button"
                        disabled={isLoading}
                        onClick={() => {
                          setIsTimerPaused(false);
                          setShowPopup(false);
                          setassignmentClockTimer(true);
                        }}
                        className="not-delete-btn"
                      >
                        Back
                      </button>
                      {isLoading ? (
                        <div className="loading-spinner-course" />
                      ) : (
                        <button
                          onClick={() => {
                            handleSubmitAssignment();
                          }}
                          type="button"
                          className="delete-btn"
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </>
                )}
              </div>
            </form>
          )}
        </div>
      </div>

      {detailedResult && (
        <div className="AssignmentViewProgress-mock-test-wrapper">
          <div className="AssignmentViewProgress-mock-test-container">
            {
              <QuestionNew
                assignment={
                  assignment?.submission?.student_answers_json[assignmentIndex]
                }
                ai_evaluation_json={assignment?.submission?.ai_evaluation_json}
                index={assignmentIndex}
                currentStep={assignmentIndex + 1}
                totalSteps={assignment?.submission?.student_answers_json.length}
                handleNext={() =>
                  assignmentIndex <
                    assignment?.submission?.student_answers_json.length - 1
                    ? setAssignmentIndex((p) => p + 1)
                    : null
                }
                handleBack={() => setAssignmentIndex((p) => p - 1)}
                isLast={
                  assignmentIndex ===
                  assignment?.submission?.student_answers_json.length - 1
                }
                isFirst={assignmentIndex === 0}
                close={
                  <span
                    onClick={showDetailedResult}
                    className="AssignmentViewProgress-mock-test-close-btn"
                  >
                    <i className="fa-solid fa-x"></i>
                  </span>
                }
                questions={getSubmissiondtls?.questions}
              />
            }
          </div>
        </div>
      )}
      {/* <PracticeAssignmentPopup assignment={assignment}/> */}
    </>
  );
};

export default StudentAssignmentPreview;

// import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// import { useLocation, useNavigate, useParams } from "react-router-dom";
// import api, { wsURL } from "../../../../../../api/api";
// import AssignmentTimer from "../../../../../../components/assignmentTimer/AssignmentTimer";
// import { useAuth } from "../../../../../../features/auth/AuthContext";
// import useSubmitAssignment from "../../../../../../hooks/useAssignmentSubmission";
// import useWebSocketConnection from "../../../../../../hooks/useWebSOcketConnection";
// import Question from "../../../questions/Question";
// import StudentAssignmentScore from "./StudentAssignmentScore";
// import BackButton from "../../../../../../components/BackButton/BackButton";
// import QuestionNew from "../../../questions/Question_new";
// import { CircularProgress, Grid } from "@mui/material";
// import { Modal } from "react-responsive-modal";
// import AssignmentRatingPopup from "../../../../../../components/AssignmentRatingPopup/AssignmentRatingPopup";
// import { toast } from "react-toastify";
// import StudentLessonPreview from "./StudentLessonPreview";
// import "./StudentAssignmentPreview.css";
// import ShowRating from "../../../dashboard/ShowRating/ShowRating";
// import CompleteRating from "../../../dashboard/ShowRating/CompleteRating";
// import PlagiarismChecker from "./PlagiarismChecker";

// const StudentAssignmentPreview = () => {
//   const globalState = useSelector((state) => state.globalState);
//   const studentId = globalState.student_details?.data?.student_id ?? "";
//   const navigate = useNavigate();
//   const location = useLocation();
//   const { assignment_id, course_id, lesson_id } = useParams();
//   const { token } = useAuth();
//   const [assignment, setAssignment] = useState(
//     location?.state?.assignment || {}
//   );
//   const [assignmentDtls,setAssignmentDtls] = useState(
//     location?.state?.assignmentDtls || {}
//   );
//   const lesson = location?.state?.lesson;
//   const countCompletedLessons = (assignemnetsDtls) => {
//     // if(assignemnetsDtls){
//     //   return assignemnetsDtls.filter(assignemnet => assignemnet?.assignment_status?.status === "Completed").length;
//     // }
//     if (Array.isArray(assignemnetsDtls) && assignemnetsDtls.length) {
//       return assignemnetsDtls.filter(assignment => assignment?.assignment_status?.status === "Completed").length;
//   }
//   return 0;

// };
//   const [totalCompltedAssignment,setTotalCompletedAssignment] = useState(countCompletedLessons(assignmentDtls));
//   const [assignmentIndex, setAssignmentIndex] = useState(0);
//   const [detailedResult, setdetailedResult] = useState(false);
//   const [showScorePopup, setshowScorePopup] = useState(false);
//   const [answeredList, setAnsweredList] = useState([]);
//   const [getSubmissiondtls, setSubmissiondtls] = useState([]);
//   const [congratulationMessgae, setcongratulationMessgae] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const [isAssignmentLoading, setisAssignmentLoading] = useState(false)
//   const [showSubmit, setShowPopup] = useState(false);
//   const [isTimerPaused, setIsTimerPaused] = useState(false);
//   const [lessonRating, setLessonRating] = useState(0);
//   const [lessonRatingComment, setLessonRatingComment] = useState("");
//   const [currentStep, setCurrentStep] = useState(0);
//   // const [newOpen, setNewOpen] = useState(false);
//   // const [open, setOpen] = useState(false);
//   const [newOpen, setNewOpen] = useState(false);
//   const assignmentJSON = assignment?.assignment_json?.map((item) => {
//     return { ...item, student_answer: null, time_taken: "0" };
//   });

//   useEffect(() => {
//     getAssignment(lesson_id, assignment_id);
//     // countCompletedLessons(assignmentDtls);
//   }, [lesson_id, assignment_id]);

//   const getAssignment = async (lesson_id) => {
//     setisAssignmentLoading(true);
//     await api
//       .get(`assignments/get-assignments-by-lesson/${lesson_id}/`, {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Token ${token}`,
//         },
//       })
//       .then((response) => {
//         if (response.status === 200) {
//           setisAssignmentLoading(false);
//           setAssignment(response?.data?.data[0]);
//           const assignmentData = response?.data?.data[0];
//           if (!assignmentData || !assignmentData.assignment_status || !assignmentData.assignment_status.status) {
//             // console.log("Triggering createAssignmentStatus");
//             createAssignmentStatus(assignment_id);
//           }
//         }
//       })
//       .catch(() => { setisAssignmentLoading(false); });
//   };

//   const totalSteps = assignmentJSON?.length;

//   const handleNext = async (answeredQuestion) => {
//     const existingIndex = answeredList.findIndex(
//       (question) => question.question_id === answeredQuestion.question_id
//     );

//     let updatedList;
//     if (existingIndex !== -1) {
//       updatedList = answeredList.map((question, index) =>
//         index === existingIndex ? answeredQuestion : question
//       );
//     } else {
//       updatedList = [...answeredList, answeredQuestion];
//     }

//     setAnsweredList(updatedList);

//     if (currentStep < totalSteps - 1) {
//       setCurrentStep(currentStep + 1);
//     } else {
//       setShowPopup(true);
//     }
//   };

//   const socket = useWebSocketConnection(
//     `${wsURL}//ws/assignment_submission/${parseInt(studentId)}/`
//   );
//   const { submitAssignment } = useSubmitAssignment(socket);

//   useEffect(() => {
//     if (socket) {
//       socket.onmessage = handleWebSocketMessage;
//       socket.onerror = (event) => {
//         setIsLoading(false);
//         console.error("WebSocket error observed:", event.message);
//       };
//     }
//   }, [socket]);

//   const handleSubmitAssignment = async (questionAnswers) => {
//     if (!globalState.subdomain_exists && assignment?.rating === null && lesson?.last_lesson && assignment?.last_assignment) {
//       setNewOpen(true);
//     }

//     setIsTimerPaused(true);
//     setIsLoading(true);
//     const payload = {
//       student_id: parseInt(studentId),
//       assignment_id: assignment?.assignment_id,
//       rubric_id: assignment?.rubric_id ?? null,
//       questions_answers: questionAnswers ?? answeredList,
//     };

//     const headers = {
//       "Content-Type": "application/json",
//       Authorization: `Token ${token}`,
//     };

//     try {
//       const response = await api.post(
//         "/assignments/assignment_submission/",
//         payload,
//         { headers }
//       );

//       if (response.status === 200 || response.status === 201 || response.status === 202) {

//         // const assignmentStatusId = localStorage.getItem("assignmentStatusId");
//         const grade = '';
//         await updateAssignmentStatus(assignment_id,grade);
//         setIsLoading(false);
//         setShowPopup(false);
//         setcongratulationMessgae(true);
//       } else {
//         setIsLoading(false);
//         console.error("Submission failed", response);
//         setShowPopup(false);
//       }
//     } catch (error) {
//       setIsLoading(false);
//       console.error("Submission error", error);
//       setShowPopup(false);
//     }

//     //Websocket Flow of Submission
//     // try {
//     //   // Assuming you have a WebSocket connection stored in a variable named 'socket'
//     //   submitAssignment(payload);
//     //   localStorage.setItem("timerSeconds", null);
//     // } catch (error) {
//     //   setIsLoading(false);
//     //   localStorage.setItem("timerSeconds", null);
//     //   console.error("WebSocket error", error);
//     // }
//     //Websocket Flow of Submission
//   };

//   const createAssignmentStatus = async (assignment_id) => {
//     const payload = {
//       student_id: globalState.student_details.data.student_id,
//       assignment_id: assignment_id,
//       status: 3,
//       completed_name: assignment.name,
//     };
//     const headers = {
//       "Content-Type": "application/json",
//       Authorization: `Token ${token}`,
//     };
//     await api
//       .post(`assignments/create-assignment-status/`, payload, {
//         headers,
//       })
//       .then((response) => {
//         if (response.status === 200 || response.status === 201) {
//           localStorage.setItem("assignmentStatusId", response?.data?.data?.id);
//         }
//       })
//       .catch(() => {});
//   };

//   const updateAssignmentStatus = async (status_id, score = '') => {
//     const payload = {
//       status: 1,
//       score: score,
//       completed_at: new Date().toISOString(),
//     };
//     const headers = {
//       "Content-Type": "application/json",
//       Authorization: `Token ${token}`,
//     };
//     await api
//       .put(`assignments/update-assignment-status/${status_id}/`, payload, {
//         headers,
//       })
//       .then((response) => {
//         if (response.status === 200 || response.status === 201) {
//           localStorage.removeItem("assignmentStatusId");
//         }
//       })
//       .catch(() => {});
//   };

//   const handleWebSocketMessage = async (event) => {
//     try {
//       // Parse the incoming JSON message
//       const data = JSON.parse(event.data);
//       const assignmentStatusId = localStorage.getItem("assignmentStatusId");
//       // Check if the message represents a successful submission
//       setIsLoading(false);
//       setShowPopup(false);
//       setcongratulationMessgae(true);
//       setSubmissiondtls(data);
//       const grade = Object.keys(data.grade)[0];
//       await updateAssignmentStatus(assignmentStatusId, grade);
//       // You can call your putAssignmentStatus function here if needed
//     } catch (error) {
//       setIsLoading(false);
//       console.error("WebSocket message error", error);
//     }
//   };

//   const handleBack = () => {
//     if (currentStep > 0) {
//       setCurrentStep(currentStep - 1);
//     }
//   };

//   const handleTimeUp = () => {
//     const resultList = assignmentJSON?.filter((item) => {
//       // Check if the Question_id of the item is not in attemptedList
//       return !answeredList.some(
//         (attemptedItem) => attemptedItem.question_id === item.question_id
//       );
//     });
//     setShowPopup(true);
//     localStorage.setItem("timerSeconds", null);
//     handleSubmitAssignment([...resultList, ...answeredList]);
//   };

//   const isLastQuestion = assignmentJSON?.length === currentStep + 1;

//   const showScore = () => {
//     setcongratulationMessgae(true);
//   };

//   const showDetailedResult = () => {
//     setAssignmentIndex(0);
//     setdetailedResult(!detailedResult);
//     setshowScorePopup(false);
//   };

//   const handleSubmitLessonRating = async (token) => {
//     // setIsLoading(true)
//     const payload = {
//       teacher: lesson.teacher?.teacher_id,
//       rating: lessonRating,
//       course: course_id,
//       comments: lessonRatingComment,
//       lesson: lesson_id,
//     };
//     const headers = {
//       "Content-Type": "application/json",
//       Authorization: `Token ${token}`,
//     };
//     try {
//       const response = await api.post(
//         `/rating/teacher-rating-by-student/`,
//         payload,
//         { headers }
//       );
//       if (response.status === 200 || response.status === 201) {
//         setNewOpen(false);
//       } else {
//         toast.error("Failed to rate the lesson. Please try again", {
//           position: "top-center",
//         });
//       }
//     } catch (error) {
//       console.log(error);
//       return null;
//     }
//   };

//   const handleViewResultClicked = (lesson) =>{
//     console.log(lesson)
//     setIsLoading(true)
//     setNewOpen(false)
//   }

//   const [isSwitchOn, setIsSwitchOn] = useState(false);
//   const [showStudentLessonPreview, setShowStudentLessonPreview] = useState(isSwitchOn);

//   useEffect(() => {
//     setShowStudentLessonPreview(isSwitchOn);
//   }, [isSwitchOn]);

//   const handleSwitchToggle = () => {
//     setIsSwitchOn(!isSwitchOn);
//   };

//   // useEffect(() => {
//   //   if (!isLoading && !showSubmit) {
//   //     setcongratulationMessgae(true);
//   //   }
//   // }, [isLoading, showSubmit])

//   const [plagiarismText, setPlagiarismText] = useState('');
//   const [plagiarismResults, setPlagiarismResults] = useState(null);

//   const handleSubmit = () => {
//     // Get text from somewhere, e.g., state holding form inputs
//     setPlagiarismText('text to check for plagiarism'); // Adjust this to get actual text
//   };

//   const handlePlagiarismResults = (results) => {
//     setPlagiarismResults(results);
//     // Here you can handle what to do after the plagiarism results are received
//   };

//   return (
//     <>
//       <div id="AssingmentMCQs-section">
//         <div className="AssingmentMCQs-wrapper">
//           <div className="container-fluid">
//             <div className="row">
//               <div className="col-lg-12 col-md-12">
//                 <div className="AssingmentMCQs-header-main-contaiiner">
//                   <div
//                     className="view-course-Details-back-btn-folder"
//                     id="AssingmentMCQs-back-btn"
//                   >
//                     <BackButton buttonName={assignment?.name} />
//                   </div>
//                   {/* <PlagiarismChecker/> */}
//                   {/* <h2>Assignment: {assignment.name}</h2> */}
//                   <div className="AssingmentMCQs-show-lesson-preview">
//                     <h2>Show Lesson Preview</h2>
//                     <label className="switch">
//                       <input
//                         type="checkbox"
//                         checked={isSwitchOn}
//                         onChange={handleSwitchToggle} // Use onChange event
//                       />
//                       <span className="slider round"></span>
//                     </label>
//                   </div>

//                   {/* <AssignmentTimer
//                     duration={(assignment?.duration_minutes ?? 0) * 60}
//                     onTimeUp={handleTimeUp}
//                     isTimerPaused={isTimerPaused}
//                   /> */}
//                 </div>
//                 {
//                   isAssignmentLoading ?
//                     <>
//                       <p style={{ textAlign: "center" }}>Loading Assignment... Please wait!</p>
//                       <Grid
//                         height={"50vh"}
//                         xs={12}
//                         container
//                         justifyContent={"center"}
//                         alignItems={"center"}
//                       >
//                         <CircularProgress size={80} />
//                       </Grid>
//                     </>
//                     :
//                 <div className="AssingmentMCQs-container">
//                   {/* <button onClick={handleSubmit}>Submit Assignment</button>
//                   <PlagiarismChecker textToCheck={plagiarismText} onResults={handlePlagiarismResults} />
//                   {plagiarismResults && <div>Plagiarism Check Results: {JSON.stringify(plagiarismResults)}</div>} */}

//                   {showStudentLessonPreview && <StudentLessonPreview lesson={lesson}/>}
//                   <div className={`AssingmentMCQs-right-section ${showStudentLessonPreview ? '' : 'full-width'}`}>
//                     <AssignmentTimer
//                       duration={(assignment?.duration_minutes ?? 0) * 60}
//                       onTimeUp={handleTimeUp}
//                       isTimerPaused={isTimerPaused}
//                     />
//                     <div className="AssingmentMCQs-main-wrapper">
//                       <div className="step-container">
//                         {assignmentJSON?.map((assignment, index) => (
//                           <div
//                             key={index + 1}
//                             className={`step ${
//                               currentStep >= index ? "active" : ""
//                             }`}
//                           >
//                             {index + 1}
//                           </div>
//                         ))}
//                       </div>
//                       {assignmentJSON?.map((assign, index) => (
//                         <div key={index}>
//                           <Question
//                             duration={(assignment?.duration_minutes ?? 0) * 60}
//                             assignment={assign}
//                             index={index}
//                             currentStep={currentStep}
//                             handleBack={handleBack}
//                             handleNext={handleNext}
//                             isLast={isLastQuestion}
//                             lesson={lesson ? lesson : location?.state?.lesson}
//                             course_id={course_id}
//                             lesson_id={lesson_id}
//                             assignment_id={assignment_id}
//                           />
//                         </div>
//                       ))}
//                     </div>
//                   </div>
//                 </div>
//                 }
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default StudentAssignmentPreview;
