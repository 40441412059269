import React from "react";
import "./CancelPopup.css";

const CancelPopup = ({ title, No, Yes, show, close }) => {

  return (
    <>
          <div className="Course-created-successfully-folder">
            <h2>{title}</h2>
            <div className="Course-close-btn">
              <button type="submit" onClick={close}>{No}</button>
              <button type="submit" onClick={show} className="Course-close">{Yes}</button>
            </div>
          </div>
    </>
  );
};

export default CancelPopup;

