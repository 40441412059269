import React, { useRef } from 'react'

function UploadFile({ setBase64 }) {

    const fileInputRef = useRef(null);

    const handleClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setBase64(file);
    };

    return (
        <>
            <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} ref={fileInputRef} style={{ display: 'none' }} />
            <div className="BulkUplode-button-folder" onClick={handleClick}>
                <span><i class="fa-solid fa-upload"></i></span>
                <p>Upload Spreadsheet</p>
            </div>
        </>
    )
}

export default UploadFile