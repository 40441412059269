import React from 'react';
import "./DeletePopup.css";
 
const DeletePopup = ({ title, cancel, submit, onclick, onClose }) => { // Add onClose prop

  return (
    <>
      <div className='are-you-sure-delete-popup-section'>
        <div className="are-you-sure-delete-popup-section-container">
          <div className="are-you-sure-delete-popup-section-folder">
            <h2>{title}</h2>
            <div className="Course-close-btn">
              <button type="button" onClick={onClose}>{cancel}</button> {/* Add onClick handler */}
              <button type="submit" className="Course-close" onClick={onclick}>{submit}</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default DeletePopup;
