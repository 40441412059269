import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  TextField,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Ring } from "@uiball/loaders";
import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../api/api";
import { student_login_bg, teacher_login_bg } from "../../assets/images/images";
import ForgetPassword from "../../components/ForgetPassword"; // import the ForgotPassword component
import ResetPassword from "../../components/ResetPassword"; // import the ResetPassword component
import SwitchTab from "../../components/SwitchTab";
import { useAuth } from "../../features/auth/AuthContext";
import "../../index.css";
import { formatApiErrors } from "../../utils/formatApiErrors";
import {
  isRequired,
  isValidEmail,
  isValidPassword,
} from "../../utils/validator";

import check_icno_new from "../../assets/images/check-icno-new.png";
import ChangePassword from "../../components/ChangePassword/ChangePassword";
import GoogleInUp from "../GoogleInUp";
import EmailVerification from "../../components/EmailVerification";


const Login = () => {
  const [checkvalidationregisteris, setcheckvalidationregisteris] = useState(
    false
  );
  const globalState = useSelector((state) => state.globalState);
  const subadmin_details = globalState.subadmin_details;
  const { login } = useAuth();
  const location = useLocation();
  const dispatch = useDispatch();
  const roleFromState = location.state?.role || "";
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(roleFromState);
  const [changePassword, setchangePassword] = useState(false);
  const [userId, setuserId] = useState("");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const redirectPath = searchParams.get("redirect");
  if (redirectPath) {
    localStorage.setItem("redirectAfterSignup", redirectPath);
  }
  const redirectLink = localStorage.getItem("redirectAfterSignup");

  const redirectCourse = searchParams.get("redirectToCourse");
  if (redirectCourse) {
    localStorage.setItem("redirectToCourse", redirectCourse);
  }

  const redirectToCoursePath = localStorage.getItem("redirectToCourse");

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState({
    email: "",
    password: "",
  });
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    if (activeTab !== "" || activeTab !== null) {
      setcheckvalidationregisteris(false);
    }
  };

  const handleSignUpClick = (event) => {
    event.preventDefault();
    navigate("/signup", { state: { role: activeTab } });
  };

  const handleChange = (e) => {
    // Check if Enter key is pressed and ignore tab switch
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default behavior (e.g., form submission)
      handleSubmit(e); // Call the submit function
      return;
    }
    const { name, value } = e.target;
    let error = "";
    if (name === "email") {
      error = isRequired(value, "email") || isValidEmail(value);
    } else if (name === "password") {
      error = isRequired(value, "password") || isValidPassword(value);
    }

    setFormErrors({
      ...formErrors,
      [name]: error,
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {

    e.preventDefault();
    if (activeTab === "" || activeTab === null) {
      setcheckvalidationregisteris(true);
      return;
    }
    let errors = {};

    errors.email =
      isRequired(formData.email, "email") || isValidEmail(formData.email);
    errors.password =
      isRequired(formData.password, "password") ||
      isValidPassword(formData.password);

    setFormErrors(errors);

    if (!Object.values(errors).some((error) => error)) {
      await loginUser(formData.email.toLocaleLowerCase(), formData.password);
    }
  };

  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false); // To handle OTP modal visibility
  const [loginCredentials, setLoginCredentials] = useState({ email: "", password: "" }); // To store login credentials

  const loginUser = async (email, password, otp = null) => {


    const failedErrorMessage =
      "Oops, something went wrong. Please try again later.";
    let role = "student";
    if (activeTab === "Student") {
      role = "student";
    } else if (activeTab === "Teacher") {
      role = "teacher";
    } else if (activeTab === "Parent") {
      role = "parent";
    }
    try {

      // const response = await api.post(`/auth/login-user/?role=${role}`, {
      //   email,
      //   password, 
      // });
      const payload = otp ? { email, password, otp } : { email, password };


      const response = await api.post(`/auth/login-user/?role=${role}`, payload);

      if (response?.status === 400) {


        toast.dismiss();
        const error = formatApiErrors(response?.data);
        toast.error(error || response?.data?.message || failedErrorMessage, {
          position: toast.POSITION.TOP_CENTER,
        });

        setLoading(false);
        return;
      }
      if (response?.status === 200) {
        // if (
        //   response?.data?.first_login &&
        //   response?.data?.token === undefined
        // ) {
        //   toast.dismiss();
        //   setuserId(response?.data?.user_id);
        //   setchangePassword(true);
        // } else if (response?.data?.token === undefined) {
        //   toast.dismiss();
        //   const error = formatApiErrors(response?.data);
        //   toast.error(error || response?.data?.message || failedErrorMessage, {
        //     position: toast.POSITION.TOP_CENTER,
        //   });
        //   setLoading(false);
        //   return;
        if (response?.data?.message === "Password verified. Verification code has been sent to your email.") {
          // Open EmailVerification modal to input the OTP
          setIsOtpModalOpen(true);
          setuserId(response?.data?.user_id);
          setLoginCredentials({ email, password }); // Save the email and password to pass to the verification component
          setLoading(false);
          toast.success("Verification code sent successfully", {
            position: toast.POSITION.TOP_CENTER,
          });
          return;
        } else {
          login(response?.data?.token, activeTab, email || "");
          toast.dismiss();
          toast.success("Login successful.", {
            position: toast.POSITION.TOP_CENTER,
          });


          if (activeTab === "Student") {
            if (response?.data?.subdomain && response?.data?.subdomain !== 'none' && response?.data?.subdomain !== 'null' && response?.data?.subdomain !== 'undefined') {

              if (redirectLink !== null && redirectLink !== "") {
                navigate(redirectLink);
                return;
              }
              if (redirectToCoursePath !== null && redirectToCoursePath !== "") {
                navigate(redirectToCoursePath);
                return;
              }
              // const { pathname } = location.state || { from: "/dashboard/home" };
              // navigate(pathname || `${response?.data?.data?.subdomain}/dashboard/home`, {
              //   state: { role: activeTab },
              // });
              const path = `${response?.data?.subdomain}/dashboard/home`;
              const emaildtl = encodeURIComponent(email);  // Ensure 'email' is defined in your scope
              const role = encodeURIComponent(activeTab);
              const fullUrl = `${path}?email=${emaildtl}&redirectedrole=${role}`;

              // window.location.href = fullUrl;
              window.location.href = fullUrl;

              return;
            } else {
              if (redirectLink !== null && redirectLink !== "") {
                navigate(redirectLink);
                return;
              }
              if (redirectToCoursePath !== null && redirectToCoursePath !== "") {
                navigate(redirectToCoursePath);
                return;
              }
              const { pathname } = location.state || { from: "/dashboard/home" };
              // navigate(pathname || `${response?.data?.data?.subdomain}/dashboard/home`, {
              //   state: { role: activeTab },
              // });
              navigate(pathname || "/dashboard/home", {
                state: { role: activeTab },
              });
            }

          }
          else
            if (activeTab === "Teacher") {
              if (response?.data?.subdomain && response?.data?.subdomain !== 'none' && response?.data?.subdomain !== 'null' && response?.data?.subdomain !== 'undefined') {
                const path = `${response?.data?.subdomain}/dashboard/home`;
                const emaildtl = encodeURIComponent(email);
                const role = encodeURIComponent(activeTab);
                const fullUrl = `${path}?email=${emaildtl}&redirectedrole=${role}`;

                window.location.href = fullUrl;
              } else {
                navigate("/dashboard/home", { state: { role: activeTab } });
              }
            }
            else if (activeTab === "Parent") {
              role = "parent";
            }
          setLoading(false);
        }
      }
      // if (response?.status === 200) {
      //   // Check if the response includes the message for sending OTP
      //   if (response?.data?.message === "Password verified. Verification code has been sent to your email.") {
      //     // Open EmailVerification modal to input the OTP
      //     setIsOtpModalOpen(true);
      //     setLoginCredentials({ email, password }); // Save the email and password to pass to the verification component
      //     setLoading(false);
      //     return;
      //   }

      //   if (response?.data?.token) {
      //     login(response?.data?.token, activeTab, email || "");
      //     toast.dismiss();
      //     toast.success("Login successful.", {
      //       position: toast.POSITION.TOP_CENTER,
      //     });
      //     // Handle redirection logic based on role and other conditions
      //     // handlePostLoginNavigation(response?.data);
      //   }
      //   setLoading(false);
      // }
    } catch (error) {

      if (error?.response?.data?.message === 'Your account is locked. Please reset your password.') {
        setIsResetModalOpen(true)
      }

      toast.dismiss();
      const errorMessage = formatApiErrors(error?.response?.data);
      toast.error(errorMessage || failedErrorMessage, {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
    }
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isResetModalOpen, setIsResetModalOpen] = useState(false);

  const [completeModal, setcompleteModal] = useState(false);
  const customModalStyleNew = {
    modal: {
      background: "#F5F9FF",
      width: "650px",
      borderRadius: "10px",
    },
  };

  const [RequestVerification, setRequestVerification] = useState(false);

  // const toggleVerificationModal = () => {
  //   setRequestVerification(!RequestVerification);
  // };
  const customModalStyleNew1 = {
    modal: {
      hight: "650px",
      width: "650px",
      borderRadius: "10px",
    },
  };
  // const handleKeyPress = (e) => {
  //   if (e.key === "Enter") {
  //     e.preventDefault(); // Prevent the default Enter key behavior

  //     handleSubmit(e);
  //   }
  // };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default Enter key behavior
      if (isOtpModalOpen) {
        document.getElementById("confirm-otp-button").click();
      } else {
        handleSubmit(e);
      }
    }
  };
  

  const onChangePasswordClose = () => {
    setchangePassword(!changePassword);
  };

  return (
    <div className="login" onKeyPress={handleKeyPress}>
      <div className="login-wrapper">
        <form
          action=""
          className="signup-right-section"
          onSubmit={handleSubmit}
        >
          <div className="Register-container">
            <span>Welcome to</span>
            <h2 onClick={() => {
              navigate("/");
            }}>{subadmin_details ? subadmin_details?.name : 'ProSchool'}</h2>
            <div className="Register-tab-section">
              <p className="Register-title">Login as</p>
              <div className="signup-tab-section">
                <SwitchTab
                  activeTab={activeTab}
                  onTabChange={handleTabChange}
                />
              </div>
            </div>
            {checkvalidationregisteris && (
              <p className="regiterAsNote">
                Please select login as Student or Teacher.
              </p>
            )}
            <div className="signup-form">
              <p className="email-or-phoneNumber-title">Email</p>
              <div className="signup-input edit-email">
                <FormControl
                  margin="normal"
                  fullWidth
                  error={!!formErrors.email}
                >
                  <TextField
                    name="email"
                    size="small"
                    value={formData.email}
                    error={!!formErrors.email}
                    onChange={handleChange}
                    InputProps={{
                      sx: {
                        borderRadius: "35px",
                        backgroundColor: "#F5F5F5",
                        padding: "0px",
                        fontSize: "15px",
                      },
                    }}
                  />
                  <FormHelperText>{formErrors.email}</FormHelperText>
                </FormControl>
              </div>
              <p className="email-or-phoneNumber-title">Password</p>
              <div className="signup-input hide-show-password">
                <FormControl
                  margin="normal"
                  fullWidth
                  error={!!formErrors.password}
                >
                  <TextField
                    size="small"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={formData.password}
                    error={!!formErrors.password}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              setShowPassword(
                                (prevShowPassword) => !prevShowPassword
                              )
                            }
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      sx: {
                        borderRadius: "35px",
                        backgroundColor: "#F5F5F5",
                      },
                    }}
                  />
                  <FormHelperText>
                    <ul>
                      {formErrors.password.split(".").map((error) => (
                        <li key={error}>{error}</li>
                      ))}
                    </ul>
                  </FormHelperText>
                </FormControl>
              </div>
            </div>

            <div>
              <div className="forgot-password-folder">
                <div className="forget-password-btn"
                >
                  <a onClick={() => setIsModalOpen(true)}>
                    Forget Password?
                  </a>
                </div>
              </div>

              {isModalOpen && (
                <ForgetPassword
                  isModalOpen={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                />
              )}

              {isResetModalOpen && (
                <ResetPassword
                  isModalOpen={isResetModalOpen}
                  onClose={() => setIsResetModalOpen(false)}
                />
              )}

              {completeModal && (
                <Modal
                  open={completeModal}
                  onClose={() => setcompleteModal(false)}
                  center
                  styles={customModalStyleNew}
                >
                  <div className="passwor_update_succ">
                    <img src={check_icno_new} alt="" />
                    <p>Your Password has been updated successfully</p>
                  </div>
                </Modal>
              )}

              {isOtpModalOpen && (
                <EmailVerification
                  isModalOpen={isOtpModalOpen}
                  onClose={() => setIsOtpModalOpen(false)}
                  email={loginCredentials.email}
                  password={loginCredentials.password}
                  signupUser={loginUser}
                  isLogin={true}
                />
              )}

              {RequestVerification && (
                <Modal
                  open={RequestVerification}
                  onClose={() => setRequestVerification(false)}
                  center
                  styles={customModalStyleNew1}
                >
                  <div className="Request-Verification-Code-popup-wrapper">
                    <div className="Request-Verification-Code-inner-popup">
                      <h2>SEND VERIFICATION CODE</h2>
                      <div className="Request-Verification-Code-inner-input">
                        <label>Email</label>
                        <FormControl
                          margin="normal"
                          fullWidth
                        // error={!!formErrors.email}
                        >
                          <TextField
                            // label="Email"
                            name="email"
                            size="small"
                            // value={formData.email}
                            // error={!!formErrors.email}
                            onChange={handleChange}
                            InputProps={{
                              sx: {
                                borderRadius: "35px",
                                backgroundColor: "#F5F5F5",
                                padding: "0px",
                                fontSize: "15px",
                              },
                            }}
                          />
                          <FormHelperText>{formErrors.email}</FormHelperText>
                        </FormControl>
                      </div>
                      <button type="button" class="send-Verification-btn">
                        Send Verification Code
                      </button>
                      <div className="Back-To-Sign-In">
                        <a href="" onClick={e => e.preventDefault()}>Back To Sign In</a>
                      </div>
                    </div>
                  </div>
                </Modal>
              )}
            </div>

            <div className="signup-btn">
              {loading ? (
                <Ring />
              ) : (
                <button type="submit" disabled={loading}>
                  Login
                </button>
              )}
            </div>
            {/* <p className="signup-or-title">or login with</p> */}
            {/* <button type="button" className="Request-Verification-btn" onClick={toggleVerificationModal}>Request Verification Code</button> */}
            <div className="login-with-page-section">
              <div className="login-with-page-folder">
                {/* <a href="#" className="login-btns">
                  <ReactSVG src={google_logo} />
                  <p>Login with Google</p>
                </a> */}
                <GoogleInUp
                  forContent="Login with Google"
                  activeTab={activeTab}
                  loading={loading}
                  setRoleValid={setcheckvalidationregisteris}
                  setLoading={setLoading}
                  login={login}
                />

                {/* <a href="#" className="login-btns">
                  <ReactSVG src={apple_logo} />
                  <p>Login with Apple</p>
                </a> */}
              </div>
            </div>
            <div className="Already-have-an-accout-folder">
              <span>
                Don't have an account?{" "}
                <a href="#" onClick={handleSignUpClick}>
                  {" "}
                  Sign up
                </a>
              </span>
            </div>
            <p className="terms-and-Privacy-policy">
              By Logging In ProSchool school, you agree to our terms of
              use & Privacy policy
            </p>
            <div
              className="back-home-btn"
              onClick={() => {
                navigate("/");
              }}
            >
              <span>
                <i class="fa-solid fa-chevron-left"></i>
              </span>
              <span>Home</span>
            </div>
          </div>
        </form>
        <div className="signup-left-section">
          <div className="signup-image">
            <img
              src={
                activeTab === "Student" ? student_login_bg : teacher_login_bg
              }
              alt="Background"
            />
          </div>
        </div>
      </div>
      {changePassword && (
        <ChangePassword
          changePassword={changePassword}
          onChangePasswordClose={onChangePasswordClose}
          userId={userId}
        />
      )}
    </div>
  );
};

export default Login;