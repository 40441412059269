import { Dispatch } from "redux";
import api from "../../api/api";
import { setCartSuggestedCoursesList, setloading, seterror, resetSuggestedCoursesList } from "../reducers/cartSuggestedCoursesReducer";

const getSuggestedCourses = (token: any) => async (dispatch: Dispatch) => {
    dispatch(setloading(true));
    try {
        const result = await api
            .get(
                `/courses/suggested-courses/`,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Token ${token}`
                    },
                }
            )
        if (result) {
            dispatch(setloading(false));
            if (result?.data?.message === "Success") {
                dispatch(setCartSuggestedCoursesList(result?.data?.data));
            }
            else {
                dispatch(seterror(result?.data?.message ?? "Something Went Wrong!"));
            }
        }
    }
    catch (error: any) {
        dispatch(seterror(error.response.data.message));
    }
}

export { getSuggestedCourses, resetSuggestedCoursesList }

