import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { dashboard_popup_teacher_profile_details_icon, email_image } from "../../assets/images/images";
import { useAuth } from "../../features/auth/AuthContext";
import { addSchoolAdminProfile, resetaddSchoolAdmin } from "../../state/actions/addSchoolAdminAction";
import {
    isRequired,
    isValidEmail,
} from "../../utils/validator";
import "./addprofile.css";

const AddAdmin = ({ toggleAddAdmin }) => {

    const { token } = useAuth();

    const [formData, setFormData] = useState({
        //entityName: "",
        email: "",
    });
    const [formErrors, setFormErrors] = useState({
        //entityName: "",
        email: "",
    });

    const dispatch = useDispatch();
    const { school_id } = useSelector((state) => state.globalState);
    const addSchoolAdmin = useSelector((state) => state.addSchoolAdmin);

    const handleChange = (e) => {
        const { name, value } = e.target;

        let error = "";

        // if (name === "entityName") {
        //     error = isRequired(value, "name");
        // } else 
        if (name === "email") {
            error = isRequired(value, "email") || isValidEmail(value);
        }

        setFormErrors({
            ...formErrors,
            [name]: error,
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    }

    const submitHandler = async (e) => {
        e.preventDefault();
        let errors = {};

        errors.email =
            isRequired(formData.email, "email") || isValidEmail(formData.email);
        // errors.entityName =
        //     isRequired(formData.entityName, "name")

        setFormErrors(errors);

        if (!Object.values(errors).some((error) => error)) {
            await addAdminProfileRequest();
        }
    }

    const addAdminProfileRequest = () => {
        const req = {
            school_id,
            email: formData.email,
            //role: entity === "Student" ? 1 : 2,
        }

        dispatch(addSchoolAdminProfile(req, token))
    }

    useEffect(() => {
        const failedErrorMessage =
            "Oops, something went wrong. Please try again later.";

        if (addSchoolAdmin?.message?.length) {
            toast.success(addSchoolAdmin?.message, {
                position: toast.POSITION.TOP_CENTER,
            });
            toggleAddAdmin();
        } else if (addSchoolAdmin?.error?.length) {
            toast.error(addSchoolAdmin?.error || failedErrorMessage, {
                position: toast.POSITION.TOP_CENTER,
            });
            dispatch(resetaddSchoolAdmin());
        }
    }, [addSchoolAdmin])

    useEffect(() => {
        return () => {
            dispatch(resetaddSchoolAdmin());
        };
    }, []);

    return (
        <>
            <div className="dashboard-popup-section" id="AddProfilepopup">
                <div className="dashboard-popup-wrapper">
                    <div className="dashboard-popup-container">
                        <div className="dashboard-popup-form-wrapper">
                            <div className="dashboard-popup-teacher-profile-details-folder">
                                <div className="dashboard-popup-teacher-profile-details-title">
                                    <div className="dashboard-popup-teacher-profile-details-image">
                                        <img
                                            src={dashboard_popup_teacher_profile_details_icon}
                                            alt=""
                                        />
                                    </div>
                                    <h2>Add Admin</h2>
                                </div>
                                <div className="dashboard-popup-teacher-profile-details-form" id="add-admim-popup">
                                    <div className="Create-Lesson-section" id="CreateLesson-section">
                                        <div className="Create-Lesson-section-wrapper">
                                            <div className="view-lessons-section">
                                                <div className="view-lessons-wrapper">
                                                    <div className="tab-content">
                                                        <div className="dashboard-popup-teacher-profile-details-form-container" id="add-profile-form">
                                                            <div className="dashboard-popup-teacher-profile-details-input">
                                                                <label>Email</label>
                                                                <input type="text" placeholder="email id" name="email" value={formData.email} onChange={handleChange} error={!!formErrors.email} />
                                                                <div className="form-icon">
                                                                    <img src={email_image} alt="" />
                                                                </div>
                                                                <p>{formErrors.email}</p>
                                                            </div>
                                                            <div className="add-profile-btn-container">
                                                                <button type="button" className="add-profile-save-btn add-profile-close-btn" onClick={toggleAddAdmin}>Close</button>
                                                                <button type="button" className="add-profile-save-btn" onClick={submitHandler}>Save</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default AddAdmin;
