import React from "react";
import "katex/dist/katex.min.css";
import { BlockMath, InlineMath } from "react-katex";

const PreprocessAndRenderLatex = ({
  content,
  ref,
  isBlockMath,
  wrapperStyle,
}) => {
  // // Split the content into sections based on LaTeX delimiters ($$)
  // console.log(content)
  const preprocessedContent = content?.replace(/\\\(.*?\\\)/g, (match) => `$$${match.slice(2, -2)}$$`);
  
  // console.log(preprocessedContent)
  const sections = preprocessedContent?.split(/\$\$/);
  // const sections = oldsections
  // ?.map(section => section.split(/\n\n/))
  // .flat();
const renderPlainHtml = (htmlString) => {
  
  const doc = new DOMParser().parseFromString(htmlString, "text/html");
 
  return doc.body.textContent || "";
  
};
  return (
    <div ref={ref} style={wrapperStyle}>
      {sections?.map((section, index) => {
        // Check if the section is a LaTeX math expression
        if (index % 2 === 1) {
          // console.log(section);
          // Use inline styles to render LaTeX math
          // const checkforAssignment = preprocessedContent?.split(/\$\$/);
          return (
            <span
              key={index}
              className="loading-chat-answer"
              //   style={{ display: "inline-block", verticalAlign: "middle" }}
            >
              {isBlockMath ? (
                <BlockMath math={renderPlainHtml(section)} />
              ) : (
                <InlineMath math={renderPlainHtml(section)} />
              )}
            </span>
          );
        } else {
          // Treat the section as regular text
          return (
            <span
              key={index}
              className="loading-chat-answer"
              //   style={{ display: "inline-block", verticalAlign: "middle" }}
              dangerouslySetInnerHTML={{ __html: section }}
            ></span>
          );
        }
      })}
    </div>
  );
};

export default PreprocessAndRenderLatex;
