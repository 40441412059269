import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface assignmentFileUpload {
  extractedText: any;
    isLoading: boolean;
    error: string | null;
 
  }

  const initialState: assignmentFileUpload  = {
    extractedText: null,
    isLoading: false,
    error: null,
  };

  const assignmentfileuploadslice = createSlice({
    name: "assignmentFileUpload",
    initialState,
    reducers: {
      setExtractedText: (
        state: assignmentFileUpload,
        action: PayloadAction<assignmentFileUpload>
      ) => {
        state.extractedText = action.payload;
        state.isLoading = false;
        state.error = null;
      },
      setloading: (state: assignmentFileUpload, action: PayloadAction<boolean>) => {
        state.isLoading = action.payload;
        state.error = null;
      },
      seterror: (state: assignmentFileUpload, action: PayloadAction<string>) => {
        state.error = action.payload;
        state.isLoading = false;
      },
      resetassignmentfileupload: () => {
        return initialState;
      },
    },
  });

  export const {
    setExtractedText,
    setloading,
    seterror,
    resetassignmentfileupload,
  } = assignmentfileuploadslice.actions;
  
  export default assignmentfileuploadslice.reducer;
  