import { Dispatch } from "redux";
import api from "../../api/api";
import {
    setgetAllCoursesList,
    setloading,
    seterror,
    setPagination,
    resetgetAllCoursesList,
} from "../reducers/getAllCoursesReducer";

const getAllCourseList = (token: any, type: any, page: any) => async (
    dispatch: Dispatch
) => {
    dispatch(setloading(true));
    try {
        const result = await api.get(`super-admin/get-course-list/?type=${type}&page=${page}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
        });

        if (result) {
            dispatch(setloading(false));
            if (result.data?.status === 200) {
                dispatch(setgetAllCoursesList(result.data.data));
                dispatch(setPagination(result.data));
            } else {
                dispatch(seterror(result.data?.message ?? "Something Went Wrong!"));
            }
        }
    } catch (error: any) {
        dispatch(seterror(error.response.data.message));
    }
};

export { getAllCourseList, resetgetAllCoursesList };
