import React, { useEffect, useRef, useState } from 'react';
import AWS from 'aws-sdk'
import { truncateFileName } from '../../utils/truncate';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { deleteUploadedFile, resetdeleteUploadedFile } from "../../state/actions/deleteUploadedFileAction";
import { useAuth } from '../../features/auth/AuthContext';
import { Tooltip } from "react-tooltip";

AWS.config.update({
    region: process.env.REACT_APP_AWS_REGION,
    credentials: {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    }
})

const myBucket = new AWS.S3()

function AWSS3FileDelete({ selectedFiles, setSelectedFiles, getFileIcon, uploadedFileLink, setuploadedFileLink, progressTracker, setprogressTracker }) {

    const { token } = useAuth();
    //const [progressTracker, setprogressTracker] = useState([]);
    const dispatch = useDispatch();
    const { fileStatus, uploadProgress, isLoading, error, } = useSelector((state) => state.getFileUploadStatus);

    const handleS3FileDelete = (files) => {

        dispatch(deleteUploadedFile(files.map(file => file.file_id), token));

        // Construct the array of objects for deletion
        const objectsToDelete = files.map(file => ({ Key: file.key }));

        let params = {
            Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
            Delete: {
                Objects: objectsToDelete,
                Quiet: false
            }
        };

        myBucket.deleteObjects(params, function (err, data) {
            if (err) {
                console.log("Error", err);
            } else {
                console.log("Success", data);
            }
        });


    }

    const handleRemoveFile = (index) => {
        const updatedFiles = [...selectedFiles];
        updatedFiles.splice(index, 1);
        setSelectedFiles(updatedFiles);
        const deletedFiles = [...uploadedFileLink];
        const splicedArray = deletedFiles.splice(index, 1);
        setuploadedFileLink(deletedFiles);
        const del = [...progressTracker];
        del.splice(index, 1);
        setprogressTracker(del);
        handleS3FileDelete(splicedArray)
    };

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }


    useEffect(() => {
        if (progressTracker.length) {
            if (Object.entries(fileStatus).length && uploadProgress != null) {
                let updated = false;
                const newProgressTracker = progressTracker.map(p => {
                    if (p.uuid === fileStatus.uuid) {
                        updated = true;
                        return { ...p, ...fileStatus, uploadProgress };
                    }
                    return p;
                });

                if (!updated) {
                    newProgressTracker.push({ ...fileStatus, uploadProgress });
                }

                // Assuming setProgressTracker is the setter function for progressTracker
                setprogressTracker(newProgressTracker);
            }
            // if (Object.entries(fileStatus).length && uploadProgress != null) {
            //     progressTracker.map(p => {
            //         if (p.uuid != fileStatus.uuid){
            //             progressTracker.push({ ...fileStatus, uploadProgress });
            //         }
            //         else{

            //         }
            //     } )
            // }
        }
        else {
            if (Object.entries(fileStatus).length && uploadProgress != null) {
                setprogressTracker([{ ...fileStatus, uploadProgress }]);
            }
        }
    }, [fileStatus])

    useEffect(() => {
        return () => {
            setprogressTracker([]);
            dispatch(resetdeleteUploadedFile());
        }
    }, [])


    return (
        // Just an example button to trigger the deletion - replace or remove as needed
        <>

            {
                selectedFiles.length > 0 &&
                selectedFiles.map((file, index) => {
                    const fileURL = URL.createObjectURL(file);
                    return (
                        <>
                        <div
                                data-tooltip-id={file.name}
                            key={index}
                            className="pdf_box_sec_inner_single"
                            style={{ position: 'relative' }}

                            >
                                <a href={fileURL} target="_blank" rel="noopener noreferrer">
                            <span>
                                        <h5>{truncateFileName(file.name) + " (" + formatBytes(file.size) + ")"}</h5>
                            </span>
                                </a>
                            <i
                                className="fas fa-times"
                                onClick={() => handleRemoveFile(index)}
                            ></i>

                            {uploadedFileLink.length < selectedFiles.length &&
                                <div
                                    key={index}
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)'
                                    }}
                                >
                                    <CircularProgress variant="determinate" value={0} />
                                </div>
                            }
                            {progressTracker.length > 0 && progressTracker.map(tracker => {
                                return (
                                    <>
                                        {
                                            tracker.status !== "Completed" && (file?.name === tracker?.original_name) && (
                                                <div
                                                    key={index}
                                                    style={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        left: 0,
                                                        right: 0,
                                                        bottom: 0,
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                                        width: '100%',
                                                        height: '100%',
                                                        margin: '0px auto',

                                                    }}
                                                >
                                                    <CircularProgress variant="determinate" value={parseInt(tracker.uploadProgress)} />
                                                </div>
                                            )
                                        }

                                        {tracker.status === "Failed" && (file?.name === tracker?.original_name) && (
                                            <button className='s3_file_uploade_failed'
                                                key={`button-${index}`}
                                                onClick={() => handleRemoveFile(index)} // Pass tracker or any other relevant data
                                            >
                                                Failed to Upload
                                                <i
                                                    className="fas fa-times"
                                                ></i>
                                            </button>
                                        )}
                                    </>
                                );
                            })}

                            </div >
                            <Tooltip
                                id={file.name}
                                place="top"
                                content={`${file.name} (${formatBytes(file.size)})`}
                                type="light"
                                effect="float"
                            />
                        </>
                    );
                })
            }
        </>
    );
}

export default AWSS3FileDelete;