import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface HistoryState {
    history: string | null,
}

const initialState: HistoryState = {
    history: null,
};

const historySlice = createSlice({
    name: 'history',
    initialState,
    reducers: {
        addToHistory: (state, action: PayloadAction<string>) => {
            state.history = action.payload;
        }
    }
});

export const { addToHistory } = historySlice.actions;
export default historySlice.reducer;