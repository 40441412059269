import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface BulkProfile {
    isLoading: boolean;
    error: string | null;
    message: string | null;
}

const initialState: BulkProfile = {
    message: null,
    isLoading: false,
    error: null,
};

const addbulkProfileslice = createSlice({
    name: 'addbulkProfile',
    initialState,
    reducers: {
        setaddbulkProfile: (state : BulkProfile, action: PayloadAction<BulkProfile>) => {
            state.message = action.payload.message;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: BulkProfile, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null; 
        },
        seterror: (state : BulkProfile, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetaddbulkProfile: () => {
            return initialState;
        },
    },
});

export const { setaddbulkProfile, setloading, seterror, resetaddbulkProfile } = addbulkProfileslice.actions;

export default addbulkProfileslice.reducer;
