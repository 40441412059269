import React from 'react'

function TeacherFilter({
    gradeList,
    grade,
    setgrade,
    subjectList,
    subject,
    setsubject,
    isVisiblefillter,
    handleApplyClick,
}) {
    return (
        <>
            {isVisiblefillter && (
                <div className="sort-popup-assignprocess" style={{ right: "125px", top: "175px" }}>
                    <h3>Filter By:</h3>
                    {/* {Object.entries(gradeList).length > 0 && (
                        <div>
                            <label>Grade:</label>
                            <select name="grade"
                                value={grade || ""}
                                onChange={(e) => setgrade(e.target.value)}
                            >
                                <option value="">All</option>
                                {Object.keys(gradeList).map((key) => (
                                    <option
                                        key={`gradedetails${key}`}
                                        value={key}
                                    >
                                        {gradeList[key]}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )} */}

                    {Object.entries(subjectList).length > 0 && (
                        <div>
                            <label>Subject:</label>
                            <select id="subject" name="subject"
                                value={subject || ""}
                                onChange={(e) => setsubject(e.target.value)}
                            >
                                <option value="">All</option>
                                {Object.keys(subjectList).map((key) => (
                                    <option
                                        key={`subject${key}`}
                                        value={key}
                                    >
                                        {subjectList[key]}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}

                    {/* {fillterScore && fillterScore.length > 0 && (
                        <div>
                            <label>Score:</label>
                            <select value={score} onChange={handleScoreChange}>
                                <option value="">All</option>
                                {fillterScore?.map((item) => (
                                    <option key={item.id} value={item.name}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )} */}

                    {/* <button
                        className="apply-button-assignprocess"
                        onClick={handleApplyClick}
                    >
                        Apply
                    </button> */}
                </div>
            )}
        </>
    )
}

export default TeacherFilter