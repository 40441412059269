import { Dispatch } from "redux";
import api from "../../api/api";
import {
  setCourseListBySchoolId,
  setloading,
  seterror,
  resetCourseListBySchoolId,
  setPagination,
} from "../reducers/getCourseListBySchoolIDReducer";

const getCourseBySchoolId = (role: string, id: any, token: any, page: any, search: any, sort: any, type: any) => async (
  dispatch: Dispatch
) => {
  dispatch(setloading(true));
  let entityRole = "";
  if(role === "Admin"){
    entityRole = "school-admin";
  } else if(role === "SuperAdmin") {
    entityRole = "super-admin";
  }
  try {
    const result = await api.get(`${entityRole}/school/get-course-list/${id}/`, {
      params: {
        page: page,
        search: search,
        sort: sort,
        type: type,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });

    if (result) {
      dispatch(setloading(false));
      if (result.data?.status === 200) {
        dispatch(setCourseListBySchoolId(result.data.data));
        dispatch(setPagination(result.data));
      } else {
        dispatch(seterror(result.data?.message ?? "Something Went Wrong!"));
      }
    }
  } catch (error: any) {
    dispatch(seterror(error.response.data.message));
  }
};

export { getCourseBySchoolId, resetCourseListBySchoolId };
