import { Dispatch } from "redux";
import api from "../../api/api";
import {setloading, setUploadedCourseFiles, seterror, resetUploadedCourseFiles} from "../reducers/getCourseFileUploadsReducer"

const getUploadedCourseFiles = (id: any, token: any) => async (
    dispatch: Dispatch
) => {
    dispatch(setloading(true));
    try {
        const result = await api.get(`/courses/api/file-upload/?course_id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
        });

        if (result) {
            dispatch(setloading(false));
            dispatch(setUploadedCourseFiles(result?.data?.data?.data));
        }
    } catch (error: any) {
        dispatch(seterror(error?.response?.data?.message));
    }
};

export { getUploadedCourseFiles, resetUploadedCourseFiles };