import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Helper from "../../../../utils/Helper";
import { addToHistory } from '../../../../state/reducers/historyReducer';
import { useDispatch } from "react-redux";

const DashboardListTable = ({ role,avatar, name, teacherId, email, joiningDate,tid }) => {
  const profileNameInitial = (name) => {
    return String(name)
      ?.split(" ")
      .map((n) => n[0])
      .join("");
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addToHistory('/dashboard/home'))
  }, [])


    return (
      <>
        <tr>
            <td data-th="Name" className="userprofile">
            <NavLink to={role === "Student" ? `/student-detail/${tid}` : `/TeacherProfile/${tid}`}>
              <div className="Profile_wrapper">
                {
                  avatar ? <img src={avatar} alt="Avatar" /> : <p className="dashboardHeaderProfileNameInitial studentdashboardHeaderProfileNameInitial">
                  {profileNameInitial(name)}
                </p>
                }
                  
                <span className="userName">{name}</span>
              </div>
             
            </NavLink>
            </td>
          <td data-th="Teacher ID">{teacherId}</td>
          <td data-th="Email">{email}</td>
          <td data-th="Joining Date">{Helper.formatDateTime(joiningDate).date}  {Helper.formatDateTime(joiningDate).time}</td>
          <td data-th="Details">
              <NavLink to={role === "Student" ? `/student-detail/${tid}` : `/TeacherProfile/${tid}`}>
                <button>View</button>
              </NavLink></td>
        </tr>
        <div className="table_gap"></div>
      </>
    );
  };
  export default DashboardListTable;
  