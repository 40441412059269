import { Dispatch } from "redux";
import api from "../../api/api";
import {
    setCancelSubscription, setloading, seterror, resetCancelSubscription
} from "../reducers/cancelSubscriptionReducer";


const cancelSubscription = (token: any, subscription_id: any) => async (dispatch: Dispatch) => {
    dispatch(setloading(true));
    try {
        const result = await api
            .post(`subscriptions/cancel-subscription/`,
                { subscription_id },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Token ${token}`
                    },
                }
            )

        if (result) {
            console.warn(result);
            dispatch(setloading(false));
            if (result.data?.status === 200) {
                dispatch(setCancelSubscription(result.data));
            }
            else {
                dispatch(seterror(result.data?.message ?? "Something Went Wrong!"));
            }
        }
    }
    catch (error: any) {
        dispatch(seterror(error.response.data.message));
    }
}

export { cancelSubscription, resetCancelSubscription }
































