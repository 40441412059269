import React, { useEffect, useState } from 'react';
import BackButton from '../BackButton/BackButton';
import DropdownMenu from "../../components/DropdownMenu/DropdownMenu";
import { getTeacherGeneraterRevenueReducer, resetTeacherGeneraterRevenue } from "../../state/actions/teacherGeneraterRevenueAction";
import { getdownlodeTeacherRevenueAction, resetDownlodeTeacherRevenue } from "../../state/actions/downlodeTeacherRevenueAction";
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from "../../features/auth/AuthContext";
import { useNavigate } from 'react-router-dom';
import PaginationView from "../../components/PaginationView/PaginationView";
import "./CourseRevenue.css"
import DownlodeFile from "../../components/downlodeFile/DownlodeFile";
import LoadingComponent from '../LoadingComponent/LoadingComponent';

function CourseRevenue() {

    const course_revenue_Dropdownmenu = [
        {
            selectMenu: "Monthly",
            value: "Monthly"
        },
        {
            selectMenu: "Yearly",
            value: "Yearly",
        },
    ];

    const course_revenue_Dropdownmenu2 = [
        { selectMenu: "JANUARY", value: "Jan" },
        { selectMenu: "FEBRUARY", value: "Feb" },
        { selectMenu: "MARCH", value: "Mar" },
        { selectMenu: "APRIL", value: "Apr" },
        { selectMenu: "MAY", value: "May" },
        { selectMenu: "JUNE", value: "Jun" },
        { selectMenu: "JULY", value: "Jul" },
        { selectMenu: "AUGUST", value: "Aug" },
        { selectMenu: "SEPTEMBER", value: "Sept" },
        { selectMenu: "OCTOBER", value: "Oct" },
        { selectMenu: "NOVEMBER", value: "Nov" },
        { selectMenu: "DECEMBER", value: "Dec" },
    ];

    const course_revenue_Dropdownmenu3 = [
        { selectMenu: "2024", value: "2024" },
        { selectMenu: "2025", value: "2025" },
        { selectMenu: "2026", value: "2026" },
        { selectMenu: "2027", value: "2027" },
        { selectMenu: "2028", value: "2028" },
        { selectMenu: "2029", value: "2029" },
        { selectMenu: "2030", value: "2030" },
    ]

    const [showDownload, setShowDownload] = useState(false);
    const [showShort, setShowShort] = useState(false);
    const [sort, setSort] = useState("");
    const [option, setoption] = useState("");
    const [month, setMonth] = useState("");
    const [year, setYear] = useState("");
    const [fileName, setFileName] = useState("");
    const [search, setsearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const { token } = useAuth();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { count,
        next,
        previous,
        totalPages,
        error, teacherGeneraterRevenueList, isLoading } = useSelector((state) => state.teacherRevenueReducer);

    useEffect(() => {
        dispatch(getTeacherGeneraterRevenueReducer(month, currentPage, sort, search, token, year, option));
    }, [currentPage, month, search, sort, year])

    const { downlodeTeacherRevenue } = useSelector((state) => state.downlodeRevenue || {});

    useEffect(() => {
        if (downlodeTeacherRevenue.file_url) {
            setShowDownload(true);
        }
    }, [downlodeTeacherRevenue]);

    const handleDownloadClick = () => {
        dispatch(getdownlodeTeacherRevenueAction(token));
    };

    const handleDownloadClose = () => {
        setShowDownload(false);
    };

    const handleSortClick = () => {
        setShowShort(!showShort);
    };

    const downloadExcelFile = () => {
        const anchor = document.createElement('a');
        anchor.href = downlodeTeacherRevenue.file_url
        anchor.download = downlodeTeacherRevenue.file_url+'.xlsx';
        anchor.click();
        setShowDownload(false);
        setFileName("");
    };

    const Onclick_Word_File = () => {
        downloadExcelFile();
    };

    const handleChange = (e) => {
        setFileName(e.target.value);
    };

    useEffect(() => {
        return () => {
            dispatch(resetDownlodeTeacherRevenue());
        }
    }, [])

    return (
        <>
            <div className='course-revenue-section'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12'>
                            <div className='course-revenue-wrapper'>
                                <BackButton buttonName={"Course Revenue Detailed Analysis"} />
                                <div className='course-revenue-header-wrapper'>
                                    <div className='course-revenue-serach'>
                                        <input type='text' placeholder='Search items' name="search" value={search} onChange={(e) => setsearch(e.target.value)} />
                                        <span><i class="fa-solid fa-magnifying-glass"></i></span>
                                    </div>
                                    <div className='course-revenue-header-filter'>
                                        {showDownload && (
                                            <DownlodeFile
                                                Word_File="Excel sheet (.xlsx)"
                                                pdf_File=""
                                                Or=""
                                                onClose={handleDownloadClose}
                                                Onclick_Word_File={Onclick_Word_File}
                                                onChange={handleChange}
                                                fileName={fileName}
                                                rename_file={false}
                                            >
                                            </DownlodeFile>
                                        )}
                                        <div className='course-revenue-downlode-folder' onClick={handleDownloadClick}>
                                            <i class="fa-solid fa-download"></i>
                                        </div>
                                        <div className='course-revenue-dropdown-menu'>
                                            <DropdownMenu menuItems={course_revenue_Dropdownmenu} dropdownValue={option} setdropdownValue={setoption} />
                                        </div>
                                        <div className='course-revenue-dropdown-menu'>
                                            {option === "Monthly" ? <DropdownMenu menuItems={course_revenue_Dropdownmenu2} dropdownValue={month} setdropdownValue={setMonth} /> :
                                                <DropdownMenu menuItems={course_revenue_Dropdownmenu3} dropdownValue={year} setdropdownValue={setYear} />}
                                        </div>
                                        <div className="course-revenue-Sort-dropdown">
                                            <div className="lessonviewprogress-filder-tab" onClick={handleSortClick}>
                                                <span>
                                                    <i class="fa-solid fa-arrow-down-wide-short"></i>
                                                </span>
                                                <p>Sort</p>
                                            </div>
                                            {showShort &&
                                                <ul className='course-revenue-Sort'>
                                                    <li value={sort} onClick={() => setSort("All")}>All</li>
                                                    <li value={sort} onClick={() => setSort("Oldest First")}>Oldest First</li>
                                                    <li value={sort} onClick={() => setSort("Newest First")}>Newest First</li>
                                                    <li value={sort} onClick={() => setSort("Most Earning")}>Most Earning</li>
                                                    <li value={sort} onClick={() => setSort("Least Earning")}>Least Earning</li>
                                                </ul>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='course-revenue-table'>
                                    <table>
                                        <thead>
                                            <th>Course Name</th>
                                            <th>Created on</th>
                                            <th>Course Revenue</th>
                                            <th>Students Enrolled</th>
                                        </thead>
                                        <div className='my-orders-line'></div>
                                        <tbody>
                                            {
                                                isLoading ? <LoadingComponent loading_text="Educating the Future, Loading Now." /> :
                                                    teacherGeneraterRevenueList.length && teacherGeneraterRevenueList.length > 0 ?
                                                        teacherGeneraterRevenueList.map((order, index) => {
                                                            return (
                                                                <>
                                                                    <tr key={index}>
                                                                        <td data-th="Course Name">
                                                                            <button type='button' className='course-revenue-course-title' onClick={() => navigate(`/course/${order.course_id}/view`)}>{order.course_name}</button>
                                                                        </td>
                                                                        <td data-th="course_id">
                                                                            <span className='course-revenue-date'>{order.course_created_at}</span>
                                                                        </td>
                                                                        <td data-th="Created on">
                                                                            <span className='course-revenue-price'>${order.total_revenue}</span>
                                                                        </td>
                                                                        <td data-th="Course Revenue">
                                                                            <span className='course-revenue-students-enrolled'>{order.students_enrolled}</span>
                                                                        </td>
                                                                        <td data-th="Students Enrolled">
                                                                            <button type='button' className='course-revenue-view-course' onClick={() => navigate(`/course/${order.course_id}/view`)}>View Course</button>
                                                                        </td>
                                                                    </tr>
                                                                    <div className='my-orders-space'></div>
                                                                </>
                                                            );
                                                        }) : <tr>
                                                            <td colSpan={5}><p style={{ textAlign: "center", marginTop: "10px", margin: "0px auto" }}>No Revenue History Found</p></td>
                                                        </tr>
                                            }
                                            <div className='my-orders-space'></div>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {totalPages > 1 && (
                                <PaginationView
                                    totalPages={totalPages}
                                    next={next}
                                    previous={previous}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CourseRevenue;