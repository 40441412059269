import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addToHistory } from '../state/reducers/historyReducer';

export const useCustomNavigation = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {history} = useSelector((state) => state.navigateHistory);

    const navigateWithHistory = (path) => {
        //dispatch(addToHistory(path));
        navigate(path);
    };

    const navigateBackOrDashboard = () => {
        if (history != null) {
            navigateWithHistory(history); // Go back
        } else {
            navigateWithHistory(`/dashboard/home`); // Go to dashboard
        }
        // if (history.length > 1) {
        //     navigateWithHistory('-1'); // Go back
        // } else {
        //     navigateWithHistory('/dashboard/home'); // Go to dashboard
        // }
    };

    return { navigateBackOrDashboard };
};
