import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FileUpload {
    fileList: any,
    isLoading: boolean;
    error: string | null;
}

const initialState: FileUpload = {
    fileList: [],
    isLoading: false,
    error: null,
};

const fileIdByNameSlice = createSlice({
    name: "fileIdByName",
    initialState,
    reducers: {
        setFileIdByName: (state: FileUpload, action: PayloadAction<FileUpload>) => {
            state.fileList = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: FileUpload, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: FileUpload, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetFileIdByName: () => {
            return initialState;
        },
    },
});

export const {
    setFileIdByName,
    setloading,
    seterror,
    resetFileIdByName,
} = fileIdByNameSlice.actions;

export default fileIdByNameSlice.reducer;