import React, { useState } from "react";
import { MuiOtpInput } from "mui-one-time-password-input";
import { FormHelperText, Modal, styled } from "@mui/material";
import imgs from "../assets/images/imgs";
import api from "../api/api";
import {
  isRequired,
  isSamePassword,
  isValidEmail,
  isValidPassword,
} from "../utils/validator";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
const MuiOtpInputStyled = styled(MuiOtpInput)`
  display: flex;
  gap: 30px;
  max-width: 650px;
  margin-inline: auto;
`;
export default function ForgetPassword(props) {
  const [forgotPassFormStep, setForgotPassFormStep] = useState(1);
  const [isLoadingforgotPass, setisLoadingforgotPass] = useState(false);
  const [formDataForgotPass, setformDataForgotPass] = useState({
    email: "",
    code: "",
    new_password: "",
    confirm_password: "",
  });
  const [formErrorsForgotPass, setformErrorsForgotPass] = useState({
    email: "",
    code: "",
    new_password: "",
    confirm_password: "",
  });

  const handleChangeForgotPass = (e) => {
    const { name, value } = e.target;
    let error = "";
    if (name === "email") {
      error = isRequired(value, "email") || isValidEmail(value);
    } else if (name === "new_password") {
      error = isRequired(value, "password", true) || isValidPassword(value, true);
    } else if (name === "confirm_password") {
      error =
        isRequired(value, "password", true) ||
        isValidPassword(value, true) ||
        isSamePassword(formDataForgotPass.new_password, value, true);
    }

    setformErrorsForgotPass({
      ...formErrorsForgotPass,
      [name]: error,
    });

    setformDataForgotPass({
      ...formDataForgotPass,
      [name]: value,
    });
  };
  const handleOtpChange = (value) => {
    let error = "";

    error = isRequired(value, "code");

    setformErrorsForgotPass({
      ...formErrorsForgotPass,
      code: error,
    });

    setformDataForgotPass({
      ...formDataForgotPass,
      code: value,
    });
  };
  const { isModalOpen, onClose } = props;
  // const { token } = useAuth();
  const onSendEmailForgotPass = async (email) => {
    return await api
      .post("auth/forget-password/", {
        email,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err?.response;
      });
  };
  const onValidateOtpForgotPass = async (email, otp) => {
    return await api
      .post("auth/validate-otp/", {
        email,
        otp,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err?.response;
      });
  };
  const onResetPasswordForgotPass = async () => {
    return await api
      .post(
        "auth/reset-password/",
        {
          new_password: formDataForgotPass.new_password,
          confirm_password: formDataForgotPass.confirm_password,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err?.response;
      });
  };
  const handleEmailSubmit = (e) => {
    e.preventDefault();
    setisLoadingforgotPass(true);
    toast.dismiss();
    let err =
      isRequired(formDataForgotPass.email, "email") ||
      isValidEmail(formDataForgotPass.email);
    if (err) {
      setformErrorsForgotPass({
        ...formErrorsForgotPass,
        email: err,
      });
      console.log("err", err);
      setisLoadingforgotPass(false);
      return;
    }
    onSendEmailForgotPass(formDataForgotPass.email).then((res) => {
      if (res.status === 200) {
        toast.success("Verification code sent successfully");
        setForgotPassFormStep(2);
      } else {
        toast.error(res?.data?.message ?? "Failed to send verification code");
      }
    });
    setisLoadingforgotPass(false);
  };
  const [token, setToken] = useState(null);
  const handleCodeSubmit = (e) => {
    e.preventDefault();
    toast.dismiss();
    setisLoadingforgotPass(true);
    let err = isRequired(formDataForgotPass.code, "code");
    if (err) {
      setformErrorsForgotPass({
        ...formErrorsForgotPass,
        code: err,
      });
      console.log("err", err);
      setisLoadingforgotPass(false);
      return;
    }
    onValidateOtpForgotPass(
      formDataForgotPass.email,
      formDataForgotPass.code
    ).then((res) => {
      if (res.status === 200) {
        toast.success("Code validated successfully");
        setToken(res?.data?.token);
        setForgotPassFormStep(3);
      } else {
        const err = res?.data?.message ?? "Failed to validate code";
        toast.error(err === "Invalid OTP." ? "Invalid verification code" : err);
      }
    });
    setisLoadingforgotPass(false);
  };
  const handleResetSubmitForgotPass = (e) => {
    e.preventDefault();
    toast.dismiss();
    setisLoadingforgotPass(true);
    let errors = {};

    errors.new_password =
      isRequired(formDataForgotPass.new_password, "new_password", true) ||
      isValidPassword(formDataForgotPass.new_password);
    errors.confirm_password =
      isRequired(formDataForgotPass.confirm_password, "confirm_password", true) ||
      isValidPassword(formDataForgotPass.confirm_password, true) ||
      isSamePassword(
        formDataForgotPass.new_password,
        formDataForgotPass.confirm_password, true
      );

    setformErrorsForgotPass(errors);

    if (!Object.values(errors).some((error) => error)) {
      onResetPasswordForgotPass().then((res) => {
        if (res.status === 200) {
          toast.success("Password reset successfully");
          clearAllForgotPass();
        } else {
          toast.error(res?.data?.message ?? "Failed to reset password");
        }
      });
    }
    setisLoadingforgotPass(false);
  };

  const clearAllForgotPass = () => {
    onClose();
    //delay 1 second
    setTimeout(() => {
      setformDataForgotPass({
        email: "",
        code: "",
      });
      setForgotPassFormStep(1);
    }, 500);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [isNewPassword, setIsNewPassword] = useState(false);
  const [isConfirmPassword, setIsConfirmPassword] = useState(false);
  const customModalStyle = {
    modal: {
      background: "#F5F9FF",
      width: "560px",
      borderRadius: "10px",
    },
  };

  return (
    <Modal
      open={isModalOpen}
      onClose={clearAllForgotPass}
      center
      styles={customModalStyle}
    >
      <div className="forget_modal_sec_new">
        <Link onClick={clearAllForgotPass} className="chen_back_left">
          <i className="fas fa-chevron-left"></i>Back
        </Link>
        <h6>Forgot Password?</h6>
        <img src={imgs.png.frgt_img} alt=" " />
        <div className="forget_modal_sec_new_body_sec">
          {forgotPassFormStep === 1 && (
            <div className="email_add">
              <label>Enter Email Address</label>
              <input
                type="text"
                name="email"
                value={formDataForgotPass.email}
                onChange={handleChangeForgotPass}
                style={
                  formErrorsForgotPass.email
                    ? { border: "1px solid red" }
                    : null
                }
                placeholder="Email address"
              />
              {formErrorsForgotPass.email && (
                <FormHelperText error>
                  {formErrorsForgotPass.email}
                </FormHelperText>
              )}
              <div className="email_add_btn">
                <button
                  type="button"
                  onClick={handleEmailSubmit}
                  disabled={isLoadingforgotPass}
                >
                  Send Verification code
                </button>
              </div>
            </div>
          )}

          {forgotPassFormStep === 2 && (
            <div className="verification_code">
              <label>Enter verification code</label>
              <MuiOtpInputStyled
                label="6-Digit Code"
                fullWidth
                margin="normal"
                variant="outlined"
                length={6}
                name="code"
                TextFieldsProps={{ size: "small" }}
                error={!!formErrorsForgotPass.code}
                value={formDataForgotPass.code}
                onChange={handleOtpChange}
              />
              {formErrorsForgotPass.code && (
                <FormHelperText error>
                  {formErrorsForgotPass.code}
                </FormHelperText>
              )}

              <div className="email_add_btn">
                <button type="button" onClick={handleCodeSubmit}>
                  Confirm code
                </button>
              </div>
            </div>
          )}

          {forgotPassFormStep === 3 && (
            <div className="set_passcode_sec">
              <div className="set_passcode_sec_single">
                <label>Set New Password</label>
                <input
                  type="text"
                  name="new_password"
                  value={formDataForgotPass.new_password}
                  onChange={handleChangeForgotPass}
                  style={
                    formErrorsForgotPass.new_password
                      ? { border: "1px solid red" }
                      : null
                  }
                  placeholder="Enter new password"
                />
              </div>
              {formErrorsForgotPass.new_password && (
                <FormHelperText error>
                  {/* {formErrorsForgotPass.new_password} */}
                  <ul>
                    {formErrorsForgotPass.new_password.split(".").map((error) => (
                      <li key={error}>{error}</li>
                    ))}
                  </ul>
                </FormHelperText>
              )}
              <div className="set_passcode_sec_single">
                <label>Confirm New Password</label>
                <input
                  type="text"
                  placeholder="Enter new password"
                  name="confirm_password"
                  onChange={handleChangeForgotPass}
                  value={formDataForgotPass.confirm_password}
                  style={
                    formErrorsForgotPass.confirm_password
                      ? { border: "1px solid red" }
                      : null
                  }
                />
              </div>
              {formErrorsForgotPass.confirm_password && (
                <FormHelperText error>
                  {/* {formErrorsForgotPass.confirm_password} */}
                  <ul>
                    {formErrorsForgotPass.new_password.split(".").map((error) => (
                      <li key={error}>{error}</li>
                    ))}
                  </ul>
                </FormHelperText>
              )}
              <div className="email_add_btn">
                <button
                  type="button"
                  onClick={handleResetSubmitForgotPass}
                  disabled={isLoadingforgotPass}
                >
                  Continue
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
