import React from "react";
import { useNavigate } from "react-router-dom";

import { CircularProgress, Grid } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { useEffect, useState } from "react";
import api from "../../../../../../api/api";
import { useAuth } from "../../../../../../features/auth/AuthContext";
import LinearWithValueLabel from "../../../../../../utils/LinearProgresslabel";
import BackButton from "../../../../../../components/BackButton/BackButton";
import LoadingComponent from "../../../../../../components/LoadingComponent/LoadingComponent";
import { Modal } from "react-responsive-modal";

const StudentActiveAssignment = () => {
  const navigate = useNavigate();
  const { token } = useAuth();
  //   const lessons = [
  //     { name: "Lesson 01", description: "Basic structure of neurons" },
  //     { name: "Lesson 02", description: "Basic structure of akdadsasd" },
  //   ];
  const itemsPerPage = 12;
  const [selLession, setSelLession] = useState(-1);
  const [totalPages, setTotalPages] = useState(0);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setloading] = useState(false);



  const [courseDtls,setCourseDtls] = useState([]);
  const [lessonDtls,setlessonDtls] = useState([]);
  const [assignmentDtls,setassignmentDtls] = useState([]);
  const [rubricDtls,setrubricDtls] = useState([]);
  const handleCancelClick = (course,lesson,assignment,rubric) => {
    setCourseDtls(course);
    setlessonDtls(lesson);
    setassignmentDtls(assignment);
    setrubricDtls(rubric);
    setOpenDialog(true);
  };
  const cancelRegistration = () => {
    setOpenDialog(false);
    document.body.style.overflow = "auto";
    // Reset the selected role or handle cancellation
  };
  const onExpand = (i) => {
    setSelLession(selLession === i ? -1 : i);
  };
  const [lessonsDtls, setLessonsDtls] = useState();
  const inial_value = (currentPage - 1) * itemsPerPage;
  const hedleKeyupChnage = async (page) => {
    setloading(true);
    api
      .get("/assignments/student/active-assignments/", {
        params: {
          page: page,
        },
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setloading(false);
          setLessonsDtls(response.data.data.assignments);
          setTotalPages(
            response.data.count >= 12
              ? Math.ceil(response.data.count / itemsPerPage)
              : Math.floor(response.data.count / itemsPerPage)
          ); // Calculate total pages
          console.log("ceil", Math.ceil(response.data.count / itemsPerPage));
          console.log("floor", Math.floor(response.data.count / itemsPerPage));
          setNextUrl(response.data.next); // Update next URL
          setPrevUrl(response.data.previous); // Update previous URL
        }
      })
      .catch((error) => {
        //
      });
  };
  useEffect(() => {
    hedleKeyupChnage(currentPage);
  }, [currentPage]);
  const handlePageChange = (event, value) => {
    window.scrollTo(0, 0);
    setCurrentPage(value);
  };

  const onStartAssignment = (course, lesson, assignment, rubric) => {
    try {
      const state = {
        course: course ?? null,
        lesson: lesson ?? null,
        assignment: assignment ?? null,
        rubric: rubric ?? null,
        type: 1,
      };

      navigate(
        `/course/${course.course_id}/lesson/${lesson.lesson_id}/assignment/${assignment.assignment_id}/preview`,
        { state }
      );
    } catch (error) {
      console.error("Serialization error:", error);
    }
  };

  return (
    <>
      <div id="StudentActiveAssignment-section">
        <div className="view-course-Details-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="view-course-Details-back-btn-folder">
                  <BackButton buttonName={"Back"} />
                  {/* <i
                    onClick={() => navigate(-1)}
                    className="fa-solid fa-arrow-left"
                  ></i>
                  <h2>Back</h2> */}
                </div>
                <h2 className="student-Active-Lessons">Active Assignments</h2>
                <div className="Student-Active-Lesson-container">
                  {loading ?
                    <LoadingComponent
                      loading_text = "Educating the Future, Loading Now."
                    />
                    :
                    lessonsDtls && lessonsDtls.length > 0 ? (
                  lessonsDtls?.map((lesson, index) => (
                    <div className="Student-Active-main-wrapper">
                      <div
                        className="Student-Active-Lesson-folder active_assignment_folder"
                        key={index}
                      >
                        <div className="Student-Active-Lesson-title-folder">
                          <h2>
                            Assignment {inial_value + index + 1}:{" "}
                            <span>{lesson.assignment.name}</span>
                          </h2>
                          <span className="Student-Active-Assignment-des">
                            <span>Lesson: </span>
                            {lesson.lesson.name}
                          </span>
                          <span className="Student-Active-Assignment-des">
                            <span>Course: </span>
                            {lesson.course.name}
                          </span>
                        </div>
                        <p>
                          Duration: <i className="fa-solid fa-clock"></i>Approx{" "}
                          <span className="StudentViewCourseDetails-lesson-min">
                            {lesson.assignment.duration_minutes}
                          </span>{" "}
                          min
                        </p>
                        {/* <LinearWithValueLabel value={10} width="14%" /> */}
                        {lesson?.lesson?.lesson_status == "Completed" ? (
                          <button
                            className="Student-Active-start-Lesson"
                            onClick={() => {
                              handleCancelClick(lesson.course,
                                    lesson.lesson,
                                    lesson.assignment,
                                    lesson.rubric);
                            }}
                          >
                            Start Assignment
                          </button>
                        ) : (
                          // "Please Read Lesson First!"
                          <button
                            className="Student-Active-start-Lesson Student-Active-start-Lesson-greyout-btn"
                            disabled={true}
                            title="To begin the assignment, complete the lesson first."
                          >
                            Start Assignment
                          </button>
                        )}

                        
                      </div>
                    </div>
                  ))): (
                    <tr>
                      <td colSpan="8" className="text-center">No active assignments here</td>
                    </tr>
                  )
                  }
                </div>
                {openDialog && (
                  <Modal
                  open={openDialog}
                  onClose={()=>setOpenDialog(false)}
                  center
                  showCloseIcon={false}
                  classNames={{
                    modal: 'Cancel-Popup',
                  }}
                >
                              <div className="Course-created-successfully-folder">
                                {/* <div className="Course-created-successfully-image">
                                  <img src={Course_created_successfully_image} alt="" />
                                </div> */}
                                <h2>
                                  Are you sure you want to start the assignment?
                                </h2>
                                <div className="Course-close-btn">
                                  <button
                                    type="submit"
                                    onClick={cancelRegistration}
                                  >
                                    No
                                  </button>
                                  <button
                                    type="submit"
                                    onClick={() => {
                                      onStartAssignment(
                                        courseDtls,
                                        lessonDtls,
                                        assignmentDtls,
                                        rubricDtls
                                      );
                                    }}
                                    className="Course-close"
                                  >
                                    Yes
                                  </button>
                                </div>
                              </div>
                              </Modal>
                          // <div className="Course-created-successfully-main">
                          //   <div className="Course-created-successfully-container">
                          //   </div>
                          // </div>
                        )}
                <Grid xs={12} container justifyContent={"center"}>
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                    hideNextButton={
                      nextUrl === null || currentPage === totalPages
                    } // Disable if no next URL and on the last page
                    hidePrevButton={prevUrl === null || currentPage === 1} // Hide if no previous URL and on the first page
                  />
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentActiveAssignment;