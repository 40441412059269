import React, { useEffect, useState } from "react";
import './StudentsList.css';
import { useNavigate } from 'react-router-dom';
import {  Switch } from "@mui/material";
import api from "../../api/api";
import { useAuth } from "../../features/auth/AuthContext";
import { toast } from 'react-toastify';
import { upload_image } from "../../assets/images/images";

const StudentsList = ({ data,index,getAllteacherDetails,inial_value }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [isActive, setIsActive] = useState(data?.active);
  const navigate = useNavigate();
  const { token } = useAuth();
  const viewHandler = (id) => {
    navigate(`/student-detail/${id}`)
  }
  const handleSwitchToggle = () => {
    setOpenDialog(true);
  }
  const handleActiveinactive = () => {
    // setIsActive(!isActive);
    const payload = {
      user_id: data?.user_id,
      active: !isActive
    };
    
    api
      .post(`super-admin/school/active-deactive/`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`
        }
      })
      .then((response) => {
        if (response.status === 200) {
          setOpenDialog(false);
          getAllteacherDetails();
          !isActive ? (
            toast.success(
                "The student account has been enabled.",
              {
                position: toast.POSITION.TOP_CENTER,
              }
            )
          ):(
            toast.success(
                "The student account has been disabled",
              {
                position: toast.POSITION.TOP_CENTER,
              }
            )
          );
          setIsActive(!isActive);
          
        }
      })
      .catch((error) => {
        // Handle error
      });
    

  };

  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const cancelRegistration = () => {
    setOpenDialog(false);
    document.body.style.overflow = "auto";
    // Reset the selected role or handle cancellation
  };
  return (
    <>
    <tr className="table-row">
      {/* <td className="cell checkbox-cell">
        <input type="checkbox" />
      </td> */}
      <td className="cell">{inial_value + index + 1}</td>
      <td className="cell name-cell"  onClick={() => viewHandler(data.student_id)}>
        <div><img src={data.student_profile_pic ?? upload_image} className="avatar" /></div>
        <span>{data.name}</span>
      </td>
      <td className="cell">{data.uid}</td>
      <td className="cell">{data.grade}</td>
      {/* <td className="cell">{data.school_name}</td> */}
      <td className="cell">{data.email}</td>
      <td className="cell action-cell"><span onClick={() => viewHandler(data.student_id)}>View</span></td>
      <td className="cell">
        <Switch
          {...label}
          checked={isActive}
          onChange={handleSwitchToggle}
        />
      </td>
    </tr>
    <div className="table_blank_row"></div>
      {openDialog && (
        <div className="Course-created-successfully-main">
          <div className="Course-created-successfully-container">
            <div className="Course-created-successfully-folder">
              <h2>Are you sure you want to {isActive == true ? 'disable' : 'enable' } the student account?</h2>
              <div className="Course-close-btn">
                <button type="submit" onClick={cancelRegistration}>
                  No
                </button>
                <button
                  type="submit"
                  onClick={() => handleActiveinactive()}
                  className="Course-close"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      </>
  );
};

export default StudentsList;
