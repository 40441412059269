import { useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom";
import api from "../../../../../../api/api";
import { useAuth } from "../../../../../../features/auth/AuthContext";
import { updateGlobalState } from "../../../../../../state/global/slice";
import TabContent from "./TabContent";

function MyCourses() {
  const { token } = useAuth();
  const dispatch = useDispatch();
  const location = useLocation();
  const pathParts = location.pathname.split("/");
  const lastPathPart = pathParts[pathParts.length - 1];

  // Define the fetch function
  const fetchCourses = async () => {
    const response = await api.get(`courses/student/my-courses/`, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Token ${token}`,
      },
    });

    if (Number(response.data.status) === 200) {
      return response.data.data;
    } else {
      throw new Error("Error fetching courses");
    }
  };

  // Use the useQuery hook
  const { data: courses, isError, isLoading, error } = useQuery(
    "my_courses",
    fetchCourses,
    {
      onSuccess: (data) => {
        // Update global state on success
        dispatch(updateGlobalState({ key: "my_courses", value: data }));
      },
    }
  );

  // Calculate all_courses manually
  const allCourses = [
    ...(courses?.active_courses || []),
    ...(courses?.requested_courses || []),
    ...(courses?.completed_courses || []),
    ...(courses?.expired_courses || []),
  ];

  const totalLength = allCourses.length;

  useEffect(() => {
    dispatch(
      updateGlobalState({ key: "my_courses_length", value: totalLength })
    );
  }, [totalLength]);

  return (
    <div className="StudentCourseCard-section" id="StudentCourseCard-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="StudentCourseCard-wrapper">
              <div className="view-lessons-section">
                <div className="view-lessons-wrapper">
                  <div className="view-lessons-btn-container StudentCourseCard-lessons-tab-container">
                    {isLoading ? (
                      <>Loading</>
                    ) : (
                      courses &&
                      ["all_courses", "active_courses", "requested_courses", "completed_courses", "expired_courses"].map(
                        (courseCategory, index) => {
                          const courseCount =
                            courseCategory === "all_courses"
                              ? totalLength
                              : courseCategory === "active_courses"
                              ? (courses?.active_courses?.length || 0) + (courses?.completed_courses?.length || 0)
                              : courses[courseCategory]?.length || 0;
                          return (
                            <Link
                              to={`/dashboard/my-courses/${
                                courseCategory === "all_courses"
                                  ? "all-courses"
                                  : courseCategory.replace(/_/g, "-")
                              }`}
                              key={index}
                            >
                              <button
                                className={`tab-button ${
                                  lastPathPart ===
                                  (courseCategory === "all_courses"
                                    ? "all-courses"
                                    : courseCategory.replace(/_/g, "-"))
                                    ? "active"
                                    : ""
                                }`}
                              >
                                {`${
                                  courseCategory === "all_courses"
                                    ? "All Courses"
                                    : courseCategory.replace(/_/g, " ")
                                } (${courseCount})`}
                              </button>
                            </Link>
                          );
                        }
                      )
                    )}
                  </div>
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <Navigate to="/dashboard/my-courses/all-courses" />
                      }
                    />
                    <Route
                      path="all-courses"
                      element={
                        <TabContent
                          courses={allCourses}
                          isLoading={isLoading}
                        />
                      }
                    />
                    <Route
                      path="active-courses"
                      element={
                        <TabContent
                          courses={[
                            ...(courses?.active_courses || []),
                            ...(courses?.completed_courses || []),
                          ]}
                          isLoading={isLoading}
                        />
                      }
                    />
                    <Route
                      path="completed-courses"
                      element={
                        <TabContent
                          courses={courses?.completed_courses}
                          isLoading={isLoading}
                        />
                      }
                    />
                    <Route
                      path="requested-courses"
                      element={
                        <TabContent
                          courses={courses?.requested_courses}
                          isLoading={isLoading}
                        />
                      }
                    />
                    <Route
                      path="expired-courses"
                      element={
                        <TabContent
                          courses={courses?.expired_courses}
                          isLoading={isLoading}
                        />
                      }
                    />
                    <Route
                      path="favourite-courses"
                      element={<TabContent isLoading={isLoading} />}
                    />
                  </Routes>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyCourses;
