import { useGoogleLogin } from "@react-oauth/google";
import { gapi } from 'gapi-script';
import React, { useEffect, useState } from 'react';
import { Google_drive } from "../../../../../../assets/images/images";

function GoogleDocUploader({ nameValue }) {
  const [isSignedIn, setIsSignedIn] = useState(false);

  useEffect(() => {
    gapi.load('client:auth2', () => {
      gapi.client.init({
        clientId: process.env.REACT_APP_GOOGLE_API_CLIENT_ID,
        scope: 'https://www.googleapis.com/auth/drive.file',
      });
    });
  }, []);

  const uploadFileToGoogleDrive = () => {
    const htmlContent = document.querySelector(".editor-input").innerHTML;
    const base64Content = btoa(unescape(encodeURIComponent(htmlContent)));
    const boundary = 'foo_bar_baz';
    const delimiter = `\r\n--${boundary}\r\n`;
    const closeDelimiter = `\r\n--${boundary}--`;

    const metadata = {
      'name': nameValue,
      'mimeType': 'application/vnd.google-apps.document'
    };

    const multipartRequestBody =
      delimiter +
      'Content-Type: application/json; charset=UTF-8\r\n\r\n' +
      JSON.stringify(metadata) +
      delimiter +
      'Content-Type: text/html\r\n' +
      'Content-Transfer-Encoding: base64\r\n\r\n' +
      base64Content +
      closeDelimiter;

    gapi.client.request({
      'path': '/upload/drive/v3/files',
      'method': 'POST',
      'params': { 'uploadType': 'multipart' },
      'headers': { 'Content-Type': `multipart/related; boundary="${boundary}"` },
      'body': multipartRequestBody
    }).then((response) => {
      window.open(`https://docs.google.com/document/d/${response.result.id}/edit`, '_blank');
    }, (error) => console.error("Error uploading file", error));
  };

  const onSuccess = (credentialResponse) => {
    // setIsSignedIn(true);
    uploadFileToGoogleDrive();
  };

  const onFailure = (res) => {
    console.error("Login failed: res:", res);
  };

  const loginWithGoogle = useGoogleLogin({
    onSuccess: onSuccess,
    onFailure: onFailure,
    scope: 'https://www.googleapis.com/auth/drive.file',
  });

  return (
    <div onClick={() => loginWithGoogle()} className="view-course-social-links-image">
      <img src={Google_drive} alt="Upload to Google Docs" />
    </div>
  );
}

export default GoogleDocUploader;
