import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// interface School {
//     school_id: string;
//     uid: string;
//     name: string;
//     location: string;
//     description: string;
//     joined_at: string;
//     image: null | string; // It can be either null or a string
// }

interface Course {
    course: any;
    next: string | null;
    previous: string | null;
    count: number | null;
    totalPages: number | null;
    isLoading: boolean;
    error: string | null;
}

const initialState: Course = {
    course: [],
    next: null,
    previous: null,
    count: 0,
    totalPages: 0,
    isLoading: false,
    error: null,
};

const courseSlice = createSlice({
    name: "courseData",
    initialState,
    reducers: {
        setgetCourse: (state: Course, action: PayloadAction<Course>) => {
            state.course = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        // setPagination: (state: Course, action: PayloadAction<Course>) => {
        //     state.next = action.payload.next;
        //     state.previous = action.payload.previous;
        //     state.count = action.payload.count;
        //     state.totalPages = Math.ceil((action.payload.count ?? 0) / 12);
        //     state.isLoading = false;
        //     state.error = null;
        // },
        setloading: (state: Course, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: Course, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetgetCourse: () => {
            return initialState;
        },
    },
});

export const {
    setgetCourse,
    setloading,
    seterror,
    //setPagination,
    resetgetCourse,
} = courseSlice.actions;

export default courseSlice.reducer;
