// Create an object to hold your images categorized by type
const imgs = {
  png: {},
  jpg: {},
  svg: {},
};

// Dynamically import images from the current folder
function importAll(r) {
  r.keys().forEach((key) => {
    // Remove the "./" from the beginning of the key and use it as the property name
    const formattedKey = key.replace("./", "");

    // Get the file extension
    const extension = formattedKey
      .split(".")
      .pop()
      .toLowerCase();

    // Remove the extension from the key to use it as the property name
    const propertyName = formattedKey.replace(`.${extension}`, "");

    // Convert hyphens to underscores to make it a valid JavaScript identifier
    const validPropertyName = propertyName.replace(/-/g, "_");

    // Import the image and assign it to the appropriate category
    const importedImage = r(key);

    if (["png", "jpg", "svg"].includes(extension)) {
      imgs[extension][validPropertyName] = importedImage;
    }
  });
}

// Import all png, jpg, and svg files
importAll(require.context(".", false, /\.(png|jpe?g|svg)$/));

export default imgs;
