import { useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import { plus_rounded, Marketplace_icon } from "../../assets/images/images";
import imgs from "../../assets/images/imgs";
import Teachers from "../Teachers/Teachers";
import Students from "../Students/Students";
import { Link, Routes, Route, NavLink, useNavigate, Navigate, useLocation } from "react-router-dom";
import { Tooltip } from "react-tooltip";

const Sidebar = ({
  activeTab,
  setActiveTab,
  tabList,
  toggleCourse,
  toggleAddSchool,
  toggleshowAddProfile,
  toggleAddAdmin,
}) => {
  const { role } = useSelector((state) => state.globalState);
  const { userCurrentPlan } = useSelector((state) => state.globalState);
  const globalState = useSelector((state) => state.globalState);
  const navigate = useNavigate();
  const location = useLocation();
  const urlLocation = location.pathname.split("/")[location.pathname.split("/").length - 1];
  const teacherCurrentPlan = useSelector((state) => state.teacherCurrentPlan);
  let selectedTab;

  if (role === "Student") {
    selectedTab = "";
  } else {
    selectedTab = urlLocation.length ? tabList.find((tab) => tab.route === urlLocation)?.key : tabList[0].key;
  }


  return (
    <>
      <div className="dashboardSidePannel">
        {role === "Teacher" && activeTab === tabList[0].key ? (
          <div className="create-course"
            // onClick={() => !globalState.subdomain_exists ? (globalState.userCurrentPlan?.currentSubscription ? (userCurrentPlan?.currentSubscription?.remaining_credit === 0 ? '' : toggleCourse("hidden")) : '') : toggleCourse("hidden")}
            // onClick={() => toggleCourse("hidden")}
            onClick={() => (globalState.subdomain_exists === undefined ? (globalState.userCurrentPlan?.currentSubscription !== null ? (globalState.userCurrentPlan?.currentSubscription?.remaining_credit === 0 ? null : toggleCourse("hidden")) : null) : toggleCourse("hidden"))}
            data-tooltip-id="sidebar-create-course-validation"
            style={{ backgroundColor: (globalState.subdomain_exists === undefined ? (globalState.userCurrentPlan?.currentSubscription !== null ? (globalState.userCurrentPlan?.currentSubscription?.remaining_credit === 0 ? "gray" : '') : "gray") : '') }}>
            <ReactSVG src={plus_rounded} />
            <p className="create-course-text">Create Course</p>
          </div>
        ) : null}

        <Tooltip
          id="sidebar-create-course-validation"
          place="bottom"
          content={globalState.subdomain_exists === undefined ? (globalState.userCurrentPlan?.currentSubscription !== null ? (globalState?.userCurrentPlan?.currentSubscription?.remaining_credit === 0 ? "Upgrade Subscription Plan" : '') : "Upgrade Subscription Plan") : ""}
          type="light"
          effect="float"
        />

        {(role === "Admin" || role === "SuperAdmin") &&
          selectedTab === "Teachers" ? (
          <div className="create-course" onClick={() => toggleshowAddProfile("hidden", "Teacher")}>
            <ReactSVG src={plus_rounded} />
            <p className="create-course-text">Add Teacher</p>
          </div>
        ) : null}

        {(role === "Admin" || role === "SuperAdmin") &&
          selectedTab === "Students" ? (
          <div className="create-course" onClick={() => toggleshowAddProfile("hidden", "Student")}>
            <ReactSVG src={plus_rounded} />
            <p className="create-course-text">Add Student</p>
          </div>
        ) : null}

        {role === "SuperAdmin" && selectedTab === "Schools" ? (
          <div
            className="create-course"
            onClick={() => toggleAddSchool("hidden")}
          >
            <ReactSVG src={plus_rounded} />
            <p className="create-course-text">Add School</p>
          </div>
        ) : null}

        {tabList
          .filter((tab) => tab.role === role)
          .map((tab, index) => {
            return (
              <Link
                to={`/dashboard/${tab.route.toLowerCase()}`}
                key={index}
                onClick={() => setActiveTab(tab.key)}
                className={(tab.key === selectedTab ? "active" : "") + " dashboardlink"}
              >
                <ReactSVG
                  src={tab.value}
                  className={
                    selectedTab === tab.key ? "svg-active" : "svg-inactive"
                  }
                />
                {tab.key}
              </Link>
            );
          })}
        {role === "Admin" ? (
          <div className="create-course"
            onClick={toggleAddAdmin}
          >
            <ReactSVG src={plus_rounded} />
            <p className="create-course-text">Add Admin</p>
          </div>
        ) : null}
        {
          role === "Teacher" &&
          <>
            {/* <div className="Marketplace-btn" onClick={() => navigate(`/marketplace`)}>
              <ReactSVG src={Marketplace_icon} />
              <button type="button">Marketplace</button>
            </div>
            <p className="Purchases_course">(Purchase courses)</p> */}
          </>
        }
      </div >
    </>
  );
};

export default Sidebar;
