import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "./LessonGeneratorStudentPreview.css";
import OverviewLesson from "../../../pages/home/components/dashboard/teacher/lessons/OverviewLesson";

function LessonGeneratorStudentPreview() {

    const location = useLocation();
    const [canDownload, setCanDownload] = useState(false);
    const [lessonFiles, setLessonFiles] = useState([]);
    const [rubricDetails, setrubricDetails] = useState({});
    const [lessonParent, setlessonParent] = useState(location?.state?.lesson || {}); // Default active tab is 'lessons'

    return (
        <>
            <div className="Lesson-generator-student-preview-section">
                <div className="Lesson-generator-student-preview-wrapper">
                    <div className="Lesson-generator-student-preview-header-folder">
                        <h2>Lesson preview from student perspective </h2>
                        <span><i class="fa-solid fa-x"></i> Close</span>
                    </div>
                    <OverviewLesson setlessonParent={setlessonParent} setCanDownloadParent={setCanDownload} setLessonFilesParent={setLessonFiles} setrubricDetails={setrubricDetails} />
                </div>
            </div>
            {/* <IncrementDecrementPopup/> */}
        </>
    );
}

export default LessonGeneratorStudentPreview;

