import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// interface School {
//   school_id: string;
//   uid: string;
//   name: string;
//   location: string;
//   description: string;
//   joined_at: string;
//   image: null | string; // It can be either null or a string
// }

interface ProfileDetails {
  profileDetails: null | any;
  //schools: School[];
  //next: string | null;
  //previous: string | null;
  //count: number | null;
 // totalPages: number | null;
  isLoading: boolean;
  error: string | null;
}

const initialState: ProfileDetails = {
  profileDetails: null,
//   next: null,
//   previous: null,
//   count: 0,
//   totalPages: 0,
  isLoading: false,
  error: null,
};

const profileDetailsSlice = createSlice({
  name: "profileDetails",
  initialState,
  reducers: {
    setProfileDetails: (state: ProfileDetails, action: PayloadAction<ProfileDetails>) => {
      state.profileDetails = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    // setPagination: (state: ProfileDetails, action: PayloadAction<ProfileDetails>) => {
    //   state.next = action.payload.next;
    //   state.previous = action.payload.previous;
    //   state.count = action.payload.count;
    //   state.totalPages = Math.ceil((action.payload.count ?? 0) / 12);
    //   state.isLoading = false;
    //   state.error = null;
    // },
    setloading: (state: ProfileDetails, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
      state.error = null;
    },
    seterror: (state: ProfileDetails, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    resetProfileDetails: () => {
      return initialState;
    },
  },
});

export const {
  setProfileDetails,
  setloading,
  seterror,
  //setPagination,
  resetProfileDetails,
} = profileDetailsSlice.actions;

export default profileDetailsSlice.reducer;
