import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CourseCard from "../../../components/CourseCard/CourseCard";
import { useAuth } from "../../../features/auth/AuthContext";
import PaginationView from "../../../components/Pagination/PaginationView";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import CancelPopup from "../../../components/CancelPopup/CancelPopup";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { format } from "date-fns";
import api from "../../../api/api";
import { FormControl, TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import CongratulationsPopup from "../../../components/CongratulationPopup/Congratulations";
import { check_image } from "../../../assets/images/images";
import { Modal } from "react-responsive-modal";
import { updateCourse, resetupdateCourse } from "../../../state/actions/updateCourseAction";

const CoursesList = ({ currentPage, setCurrentPage, type, handleRefresh }) => {
  const { token } = useAuth();
  const dispatch = useDispatch();
  const { courses, next, previous, totalPages, isLoading } = useSelector(
    (state) => state.getCoursesbySchoolId
  );
  const updatecourse = useSelector(
    (state) => state.updatecourse
  );
  const [formState, setFormState] = useState("");
  const [republishConfirmPopup, setRepublishConfirmPopup] = useState(false);
  const [republishPopup, setRepublishPopup] = useState(false);
  const [republishSuccessPopup, setRepublishSucessPopup] = useState(false);

  const handleRepublishPopup = (course_details) => {
    setFormState(course_details);
    setRepublishConfirmPopup(true);
  };

  const handleRepublish = async () => {
    let payload = new FormData();
    payload.append(
      "end_date",
      format(new Date(formState.end_date ?? ""), "yyyy-MM-dd")
    );
    try {
      const response = await api.put(
        `/courses/update-course/${formState.course_id}/`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        handleRefresh();
        setRepublishPopup(false);
        setRepublishSucessPopup(true);
        setTimeout(() => {
          setRepublishSucessPopup(false);
        }, 5000);
      } else {
        toast.error("Failed to republish course.", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateCourse = (id) => {
    const req = {
      is_active: type === "active" ? false : true
    }
    dispatch(updateCourse(req, token, id));
  }

  useEffect(() => {
    if (updatecourse.message === "Update Successfull") {
      handleRefresh();
    }
  }, [updatecourse])

  return (
    <>
      {totalPages > 1 && (
        <div className="pagination_top_wrapper">
          <PaginationView
            totalPages={totalPages}
            next={next}
            previous={previous}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      )}
      <div className="CoursesDashboard-section">
        <div className="CoursesDashboard-wrapper">
          <div className="School_Course_Card_container">
            {courses.length > 0 && courses?.map((data, index) => (
              <CourseCard
                key={index}
                courseimage={data.image}
                coursename={data.name}
                totalstudents={data.students_enrolled}
                totalteachers="1"
                lesson_count={data.lessons_count}
                start_date={data.start_date}
                end_date={data.end_date}
                description={data.description}
                course_info_image={data.course_info_image}
                Teacher_title="Teacher"
                Teacher_name={data.teacher_name}
                createdcourse={dayjs(data.created_at).format("MMM DD, YYYY")}
                schooljoined="0"
                course_details={data}
                type={type}
                Republish_btn={type === "expired" ? "Re-publish" : ""}
                handleRepublishPopup={handleRepublishPopup}
                handleUpdateCourse={handleUpdateCourse}
              />
            ))}
            {isLoading && (
              <LoadingComponent loading_text="Educating the Future, Loading Now." />
            )}
            {!isLoading && courses && !courses.length && (
              <p style={{ textAlign: "center" }}>No Courses Found</p>
            )}
          </div>
        </div>
      </div>
      {totalPages > 1 && (
        <PaginationView
          totalPages={totalPages}
          next={next}
          previous={previous}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}

      {republishConfirmPopup && (
        <Modal
        open={republishConfirmPopup}
        onClose={()=>setRepublishConfirmPopup(false)}
        center
        showCloseIcon={false}
        classNames={{
          modal: 'Cancel-Popup',
        }}
      >
        <CancelPopup
          title="Are you sure you want to re-publish this course?"
          No="Back"
          Yes="Yes"
          show={() => {
            setRepublishConfirmPopup(false);
            setRepublishPopup(true);
          }}
          close={() => {
            setRepublishConfirmPopup(false);
          }}
        />
        </Modal>
      )}
      {republishPopup && (
        <div className="Republish-course-popup-wrapper">
          <div className="Republish-course-popup-folder">
            <div className="Republish-course-popup-back-btn">
              <i
                class="fa-solid fa-chevron-left"
                onClick={() => setRepublishPopup(false)}
              ></i>
              <h2>Select course start and end date</h2>
            </div>
            <div className="Republish-course-date-container">
              {/* <div className="Republish-Start-Date-input">
                <label>Course Start Date</label>
                <FormControl margin="none" fullWidth>
                  <DesktopDatePicker
                    disabled
                    name="start_date"
                    variant="outlined"
                    // value={dayjs(formState.start_date)}
                    // onChange={(date) =>
                    //   setFormState({ ...formState, start_date: date })
                    // }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled
                        onKeyDown={(e) => e.preventDefault()}
                        InputProps={{
                          ...params.InputProps,
                          sx: {
                            borderRadius: "12px",
                            backgroundColor: "#F5F5F5",
                          },
                        }}
                        size="small"
                      />
                    )}
                    slotProps={{
                      inputAdornment: {
                        position: "start",
                      },
                      textField: {
                        size: "small",
                        InputProps: {
                          sx: {
                            borderRadius: "12px",
                            backgroundColor: "#F5F5F5",
                          },
                        },
                      },
                    }}
                  />
                </FormControl>
              </div> */}
              <div className="Republish-end-Date-input">
                <label>Course End Date</label>
                <FormControl margin="none" fullWidth>
                  <DesktopDatePicker
                    name="end_date"
                    variant="outlined"
                    value={
                      formState.end_date === ""
                        ? null
                        : dayjs(formState.end_date)
                    }
                    onChange={(date) =>
                      setFormState({ ...formState, end_date: date })
                    }
                    minDate={dayjs(new Date())}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onKeyDown={(e) => e.preventDefault()}
                        InputProps={{
                          ...params.InputProps,
                          sx: {
                            borderRadius: "12px",
                            backgroundColor: "#F5F5F5",
                          },
                        }}
                        size="small"
                      />
                    )}
                    slotProps={{
                      inputAdornment: {
                        position: "start",
                      },
                      textField: {
                        size: "small",
                        InputProps: {
                          sx: {
                            borderRadius: "12px",
                            backgroundColor: "#F5F5F5",
                          },
                        },
                      },
                    }}
                  />
                </FormControl>
              </div>
            </div>
            <button
              type="button"
              className="Republish_btn"
              onClick={handleRepublish}
            >
              Republish
            </button>
          </div>
        </div>
      )}
      {republishSuccessPopup && (
        <CongratulationsPopup
          check_image={check_image}
          des="Your course has been successfully re-published!"
        />
      )}
    </>
  );
};

export default CoursesList;
