import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SetAdminSubscription {
    adminSubscriptionPlan: any;
    isLoading: Boolean;
    error: string | null;
}

const initialState: SetAdminSubscription = {
    adminSubscriptionPlan: null,
    isLoading: false,
    error: null,
};

const setAdminSubscriptionSlice = createSlice({
    name: 'setAdminSubscription',
    initialState,
    reducers: {
        setAdminSubscriptionplan: (state: SetAdminSubscription, action: PayloadAction<string>) => {
            console.warn(action.payload);
            state.adminSubscriptionPlan = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        isloading: (state: SetAdminSubscription, action: PayloadAction<Boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: SetAdminSubscription, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetAdminSubscriptionplan: () => {
            return initialState;
        },
    },
});

export const { setAdminSubscriptionplan, isloading, seterror, resetAdminSubscriptionplan } = setAdminSubscriptionSlice.actions;

export default setAdminSubscriptionSlice.reducer;
