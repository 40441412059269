import { Dispatch } from "redux";
import api from "../../api/api";
import {
    setDataList,
    setloading,
    seterror,
    resetDataList,
} from "../reducers/getdataListReducer";

const getDataList = (token: any) => async (
    dispatch: Dispatch
) => {
    dispatch(setloading(true));
    try {
        const result = await api.get(`/auth/get-datalist/`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
        });

        if (result) {
            dispatch(setloading(false));
            dispatch(setDataList(result.data));
        }
    } catch (error: any) {
        dispatch(seterror(error.response.data.message));
    }
};

export { getDataList, resetDataList };

