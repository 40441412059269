import React, { useState, useEffect } from "react";
import { StudentViewCourseDetails_assignments_icon } from "../../../../../../assets/images/images";
import { AssignmentType } from "../../../../../../utils/consts";
import { useAuth } from "../../../../../../features/auth/AuthContext";
import api from "../../../../../../api/api";

const StudentViewCourseLesson = (props) => {
  const { token } = useAuth();
  const [assignments, setAssignments] = useState([]);

  const getAssignmentTypeName = (type) => {
    return Object.values(AssignmentType).find((item) => {
      return item.value === type;
    })?.name;
  };

  useEffect(() => {
    if (props.selLession) {
      setAssignments([]);
      api
        .get(
          `assignments/get-assignments-by-lesson/${props.lesson?.lesson_id}/`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${token}`, // `Token ${token}`
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            setAssignments(response?.data?.data ?? []);
          }
        })
        .catch((error) => {
          setAssignments([]);
        });
    }
  }, [props.selLession]);

  return (
    <div className="StudentViewCourseDetails-lesson-containner">
      <div className="StudentViewCourseDetails-lesson-folder">
        <div className="StudentViewCourseDetails-lesson-toggle-dropdown-section">
          <div className="StudentViewCourseDetails-lesson-toggle-btn">
            <span onClick={() => props.onExpand()}>
              {props.selLession ? (
                <i className="fa-solid fa-arrow-down"></i>
              ) : (
                <i className="fa-solid fa-arrow-right"></i>
              )}
            </span>
            <h2>Lesson 0{props?.id + 1}</h2>
          </div>
          <span className="StudentViewCourseDetails-lesson-des">
            {props.lesson?.name}
          </span>
          <div className="StudentViewCourseDetails-lesson-assignments-tab">
            <p>
              <div className="StudentViewCourseDetails-assignments0-icon">
                <img src={StudentViewCourseDetails_assignments_icon} alt="" />
              </div>
              <span className="StudentViewCourseDetails-assignments-count">
                {props.lesson.assignment_count !== 0
                  ? (props.lesson.assignment_count === 1 ? `${props.lesson.assignment_count} assignment` : `${props.lesson.assignment_count} assignments`)
                  : "No assignments"}
                {/* {assignments && assignments?.length > 0
                  ? `${assignments?.length} assignment`
                  : "No assignments"} */}
              </span>
            </p>
          </div>
        </div>
        {props.selLession && (
          <div className="StudentViewCourseDetails-lesson-inner-assignments-container">
            <h2>{props.lesson?.name}</h2>
            <div className="StudentViewCourseDetails-lesson-inner-assignments-wrapper">
              {assignments.map((assignment, index) => (
                <div className="StudentViewCourseDetails-lesson-inner-assignments-folder">
                  <h2>
                    Assignment <span>{index > 9 ? index: '0'+(index + 1)}</span>: <span>{" "}{assignment?.name}{" "}</span>
                  </h2>
                  <h2>{" "}{getAssignmentTypeName(assignment?.type)}</h2>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentViewCourseLesson;
