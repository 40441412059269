import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CreateAssignStatus {
    isLoading: boolean;
    error: string | null;
    success: boolean;
}

const initialState: CreateAssignStatus = {
    isLoading: false,
    error: null,
    success: false
};

const createAssignStatusSlice = createSlice({
    name: 'createAssignStatus',
    initialState,
    reducers: {
        setcreateAssignStatus: (state: CreateAssignStatus, action: PayloadAction<boolean>) => {
            state.success = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: CreateAssignStatus, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: CreateAssignStatus, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetcreateAssignStatus: () => {
            return initialState;
        },
    },
});

export const { setcreateAssignStatus, setloading, seterror, resetcreateAssignStatus } = createAssignStatusSlice.actions;

export default createAssignStatusSlice.reducer;
