import React, { useEffect, useState } from 'react';
import { iconamoon_link_external_light, crown , restrichtion_icon} from "../../../assets/images/images";
import "./ModifySubscription.css";
import BackButton from '../../BackButton/BackButton';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { userActivePlan, resetUserActivePlan } from "../../../state/actions/getUserActivePlanAction";
import { useAuth } from '../../../features/auth/AuthContext';
import { cancelSubscription } from "../../../state/actions/cancelSubscriptionAction"
import CancelPopup from '../../CancelPopup/CancelPopup';
import { Modal } from "react-responsive-modal";
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

function ModifySubscription() {

    const { token } = useAuth();
    const dispatch = useDispatch()
    const [planData, setPlanData] = useState(null);
    const [currentPlan, setCurrentPlan] = useState("")
    const [openDialog, setOpenDialog] = useState(false);
    const [subscriptionId, setSubscriptionId] = useState(null);
    const userPlan = useSelector((state) => state.userActivePlan);
    const navigate = useNavigate();
    const globalState = useSelector((state) => state.globalState);
    console.log( "total_count", globalState);
    
    const assignment_count = globalState.userCurrentPlan?.currentSubscription && globalState.userCurrentPlan.currentSubscription.usage_count?.assignments
    const lessons_count = globalState.userCurrentPlan?.currentSubscription && globalState.userCurrentPlan.currentSubscription.usage_count?.lessons
    const courses_count = globalState.userCurrentPlan?.currentSubscription && globalState.userCurrentPlan.currentSubscription.usage_count?.total_course_enrolled
    const studentSide_total_count = globalState.userCurrentPlan?.currentSubscription && globalState.userCurrentPlan.currentSubscription.usage_count?.number_of_courses
    const teacherSide_total_count = globalState.userCurrentPlan?.currentSubscription && globalState.userCurrentPlan.currentSubscription.total_credit
  


    useEffect(() => {
        if (token) {
            dispatch(userActivePlan(token));
        }
    }, [token, dispatch]);

    console.log(userPlan);
    console.log(planData);

    useEffect(() => {
        if (userPlan.plan !== null) {
            console.log('stat')
            const plans = userPlan.plan
            setPlanData(userPlan.plan);
            setCurrentPlan(plans[0].current_plan)
        }
    }, [userPlan]);

    const handleCancelSubscription = () => {
        if (token && subscriptionId) {
            dispatch(cancelSubscription(token, subscriptionId));
            setOpenDialog(false);
        }
    };

    return (
        <>
            <div className='modify-subscription-section'>
                <div className='modify-subscription-wrapper'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12'>
                                <BackButton buttonName={"My Subscription"} />
                                <div className='modify-subscription-container'>
                                    <div className='modify-subscription-header-current-plan-wrapper'>
                                        <span className='subscription-name'>Subscription Name</span>
                                        <div className='modify-subscription-header-folder'>
                                            <div className='modify-subscription-current-plan-folder'>
                                                <div className='modify-subscription-header-current-plan-name'>
                                                    <h2>Current plan</h2>
                                                    <p>{currentPlan} <span>Best LLM</span></p>
                                                </div>
                                                <div className='modify-subscription-totle-count'>
                                                    <div className='modify-subscription-totle-count-folder'>
                                                        <h2>Total: <img src={restrichtion_icon} data-tooltip-id="monetize-course" /></h2>
                                                        {/* <p>300</p> */}
                                                        <p>
                                                            {
                                                            globalState.role === "Student" 
                                                            ? studentSide_total_count 
                                                            : globalState.role === "Teacher" 
                                                            ? teacherSide_total_count 
                                                            : ""}
                                                        </p>
                                                    </div>
                                                    {/* <div className='modify-subscription-totle-count-folder'>
                                                        <span>Lessons:</span>
                                                        <p>{lessons_count}</p>
                                                    </div>
                                                    <div className='modify-subscription-totle-count-folder'>
                                                        <span>Assignments:</span>
                                                        <p>{assignment_count}</p>
                                                    </div>
                                                    <div className='modify-subscription-totle-count-folder'>
                                                        <span>Courses:</span>
                                                        <p>{courses_count}</p>
                                                    </div> */}
                                                    {globalState.role === "Teacher" && (
                                                        <>
                                                            <div className='modify-subscription-totle-count-folder'>
                                                                <span>Lessons:</span>
                                                                <p>{lessons_count}</p>
                                                            </div>
                                                            <div className='modify-subscription-totle-count-folder'>
                                                                <span>Assignments:</span>
                                                                <p>{assignment_count}</p>
                                                            </div>
                                                        </>
                                                    )}
                                                    {globalState.role === "Student" && (
                                                        <div className='modify-subscription-totle-count-folder'>
                                                            <span>Courses:</span>
                                                            <p>{courses_count}</p>
                                                        </div>
                                                    )}
                                                </div>
                                                <Tooltip
                                                    id="monetize-course"
                                                    place="top"
                                                    content={
                                                        globalState.role === "Student"
                                                            ? `You can enroll in up to ${studentSide_total_count} courses.`
                                                            : globalState.role === "Teacher"
                                                            ? `You can create up to ${teacherSide_total_count} lessons and assignments combined.`
                                                            : ""
                                                    }
                                                    type="light"
                                                    effect="float"
                                                />
                                            </div>
                                            <div className='modify-subscription-header-btn-folder'>
                                                <button type='button' onClick={() => {
                                                    setSubscriptionId(planData[0].subscription_id); // Set the subscription id
                                                    setOpenDialog(true);
                                                }}>
                                                    Cancel Subscription
                                                </button>
                                                {/* <button type='button' onClick={()=> navigate('/subscription-plan')} >Modify Subscription</button> */}
                                                <button type='button' onClick={() => navigate('/subscription-plan')} ><img src={crown} /> Upgrade</button>
                                            </div>
                                        </div>
                                        {/* <div className='modify-subscription-feature-container'>
                                            <div className='modify-subscription-feature-folder'>
                                                <div className='modify-subscription-feature'>
                                                    <span>Current plan</span>
                                                    <button type='button'>Best</button>
                                                </div>
                                                <div className='modify-subscription-feature'>
                                                    <span>Subscription Status</span>
                                                    <span className='status'>Active</span>
                                                </div>
                                                <div className='modify-subscription-feature'>
                                                    <span>Invoice</span>
                                                    <a href='#'>Download Invoice <img src={iconamoon_link_external_light}/></a>
                                                </div>
                                                <div className='modify-subscription-feature'>
                                                    <span>Purchase Date</span>
                                                    <h2>29th April 2024</h2>
                                                </div>
                                                <div className='modify-subscription-feature'>
                                                    <span>Purchase Amount</span>
                                                    <h2>$480/ Yearly<p>/ Yearly</p></h2>
                                                </div>
                                                <div className='modify-subscription-feature'>
                                                    <span>Plan Expiring</span>
                                                    <h2>29th May 2025</h2>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className='modify-subscription-details-wrapper'>
                                    <div className='modify-subscription-container'>
                                        <div className='modify-subscription-header-current-plan-wrapper'>
                                            <span className='subscription-name'>Subscription Details</span>
                                            <div className='modify-subscription-header-folder-wrapper'>
                                                {planData && planData.length > 0 && planData.map((plan, index) => (
                                                    <div key={index} className='modify-subscription-header-folder'>
                                                        <div className='modify-subscription-header-current-plan-name'>
                                                            <h2>Subscription Status </h2>
                                                            {/* <p className='Active'>Active</p> */}
                                                            <p className={`Active ${plan.subscription_status === 'Inactive' ? 'status-inactive' : 'status-active'}`}>
                                                                {plan.subscription_status}
                                                            </p>
                                                        </div>
                                                        <div className='modify-subscription-header-current-plan-name'>
                                                            <h2>Purchase Date</h2>
                                                            <p>{plan.purchase_date}</p>
                                                        </div>
                                                        <div className='modify-subscription-header-current-plan-name'>
                                                            <h2>Purchase Amount</h2>
                                                            {/* <p>$480 <span className='yearly'>/ Yearly</span></p> */}
                                                            <p>{plan.purchase_amount}</p>
                                                        </div>
                                                        <div className='modify-subscription-header-current-plan-name'>
                                                            <h2>Plan Expiries At</h2>
                                                            <p>{plan.plan_expiring}</p>
                                                        </div>
                                                        <div className='modify-subscription-header-current-plan-name'>
                                                            <h2>Invoice</h2>
                                                            <a target='_blank' href={plan.invoice}>Download Invoice <img src={iconamoon_link_external_light} /></a>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {openDialog && (
                                    <Modal
                                        open={openDialog}
                                        onClose={() => setOpenDialog(false)}
                                        center
                                        showCloseIcon={false}
                                        classNames={{
                                            modal: "Cancel-Popup",
                                        }}
                                    >
                                        <CancelPopup
                                            title="Are you sure you want to cancel the subsciption?"
                                            No="No"
                                            Yes="Yes"
                                            show={handleCancelSubscription}
                                            close={() => setOpenDialog(false)}
                                        />
                                    </Modal>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ModifySubscription;
