import { Dispatch } from "redux";
import api from "../../api/api";

import {
    setteacherRating,
    setloading,
    seterror,
    setPagination,
    resetteacherRating,
} from '../reducers/teacherRatingReducer'


const teacherRating = (course_id: any, token: any, page: any) => async (dispatch: Dispatch) => {
    dispatch(setloading(true));
    try {
      const result = await api
         .get(
             `rating/course-ave-rating/${course_id}/?page=${page}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Token ${token}`,
                    },
                }
            )
        
        if(result){
         dispatch(setloading(false));
            if (result.data?.status === 200) {
                 dispatch(setteacherRating(result?.data?.data));
             dispatch(setPagination(result.data));
           }
           else {
             dispatch(seterror(result.data?.message ?? "Something Went Wrong!"));
           }
        }
    }
    catch (error: any) {
     dispatch(seterror(error.response.data.message));
    }
 }
   
 export { teacherRating, resetteacherRating} 