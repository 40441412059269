import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface updateTeacherProfile {
  profileDetails: null | any;
  isLoading: boolean;
  error: string | null;
}

const initialState: updateTeacherProfile = {
  profileDetails: null,
  isLoading: false,
  error: null,
};

const updateTeacherDetailSlice = createSlice({
  name: "updateTeacherDetail",
  initialState,
  reducers: {
    setTeacherProfile: (state: updateTeacherProfile, action: PayloadAction<updateTeacherProfile>) => {
      state.profileDetails = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    setloading: (state: updateTeacherProfile, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
      state.error = null;
    },
    seterror: (state: updateTeacherProfile, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    resetTeacherProfile: () => {
      return initialState;
    },
  },
});

export const {
  setTeacherProfile,
  setloading,
  seterror,
  resetTeacherProfile,
} = updateTeacherDetailSlice.actions;

export default updateTeacherDetailSlice.reducer;
