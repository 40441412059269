import React from "react";
import { useState } from "react";
import { dashboard_popup_teacher_profile_details_icon } from "../../assets/images/images";
import AddProfile from "./AddProfile";
import BulkUplode from "./BulkUplode/BulkUplode";
  
  const AddProfilePopup = ({toggleshowAddProfile, entity}) => {

    const [activeTab, setActiveTab] = useState("lessons"); // Default active tab is 'lessons'

    const handleTabClick = (newActiveTab) => {
      setActiveTab(newActiveTab);
    };
  
    return (
      <>
        <div className="dashboard-popup-section" id="AddProfilepopup">
          <div className="dashboard-popup-wrapper">
            <div className="dashboard-popup-container">
                <div className="dashboard-popup-form-wrapper">
                  <div className="dashboard-popup-teacher-profile-details-folder">
                      <div className="dashboard-popup-teacher-profile-details-title">
                          <div className="dashboard-popup-teacher-profile-details-image">
                          <img
                              src={dashboard_popup_teacher_profile_details_icon}
                              alt=""
                          />
                          </div>
                          <h2>Add {entity}</h2>
                      </div>
                      <div className="dashboard-popup-teacher-profile-details-form">
                        <div className="Create-Lesson-section" id="CreateLesson-section">
                          <div className="Create-Lesson-section-wrapper">
                            <div className="view-lessons-section">
                              <div className="view-lessons-wrapper">
                                {
                                  <div className="view-lessons-btn-container teacehr-tab-setion">
                                    <button
                                      className={`tab-button ${activeTab === "lessons" ? "active" : ""}`}
                                      onClick={() => handleTabClick("lessons")}>
                                      Individual {entity} 
                                    </button>
                                    <button
                                      className={`tab-button ${activeTab === "students" ? "active" : ""}`}
                                      onClick={() => handleTabClick("students")}
                                    >
                                      Bulk Upload  
                                    </button>
                                  </div>
                                }
                                <div className="tab-content">
                                  {activeTab === "lessons" && (
                                <AddProfile toggleshowAddProfile={toggleshowAddProfile} entity={entity} />
                                  )}

                                  {activeTab === "students" && (
                                <BulkUplode toggleshowAddProfile={toggleshowAddProfile} entity={entity} />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>  
                </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  export default AddProfilePopup;
  