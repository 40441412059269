import React, { useEffect, useRef, useState } from "react";
import api from "../../../../../api/api";
import { toast } from "react-toastify";
import { Modal } from "react-responsive-modal";
import RatingPopup from "../../../../../modules/superadmin/RatingPopup/RatingPopup";
import { useAuth } from "../../../../../features/auth/AuthContext";

const ShowRating = ({lesson,course_id,lesson_id,setOpen,setNewOpen,open}) => {
    const [lessonRating, setLessonRating] = useState(0);
    const { token } = useAuth();
    const [lessonRatingComment, setLessonRatingComment] = useState("");
    const handleSubmitLessonRating = async (token) => {
        const payload = {
          teacher: lesson.teacher?.teacher_id,
          rating: lessonRating,
          course: course_id,
          comments: lessonRatingComment,
          lesson: lesson_id,
        };
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        };
        try {
          const response = await api.post(
            `/rating/teacher-rating-by-student/`,
            payload,
            { headers }
          );
          if (response.status === 200 || response.status === 201) {
            setOpen(false);
            setNewOpen(true);
          } else {
            toast.error("Failed to rate the lesson. Please try again", {
              position: "top-center",
            });
          }
        } catch (error) {
          console.error(error);
          toast.error(
            `Failed to rate the lesson. Rating:- ${error?.response?.data?.errors?.rating[0]} Please try again`,
            {
              position: "top-center",
            }
          );
        }
      };
  return (
    <Modal
      keepMounted
      open={open}
      center
      closeOnOverlayClick={false}
      showCloseIcon={false}
      classNames={{
        modal: "assignment-rating-popup",
      }}
    >
      <RatingPopup
        inner_title="We're listening!"
        des="Share your feedback and help us shape future lessons."
        rating_button="Submit Rating"
        background="#F9F8F8"
        handleSubmitRatingClick={() => {
          handleSubmitLessonRating(token);
        }}
        lessonRating={lessonRating}
        setLessonRating={setLessonRating}
        lessonRatingComment={lessonRatingComment}
        setLessonRatingComment={setLessonRatingComment}
      />
    </Modal>
  );
};

export default ShowRating;
