import { useState, useEffect } from 'react';

const useSubmitAssignment = (socket) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const submitAssignment = (assignmentData) => {
        if (!socket) {
            setError("WebSocket is not connected.");
            return;
        }

        setIsLoading(true);

        try {
            socket.send(JSON.stringify(assignmentData));
        } catch (error) {
            setError("Failed to send data: " + error.message);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (socket) {
            socket.onmessage = (event) => {
                // Handle incoming messages (e.g., confirmation of submission)
                setIsLoading(false);
            };

            socket.onerror = (event) => {
                setError("WebSocket error observed:" + event.message);
                setIsLoading(false);
            };
        }
    }, [socket]);

    return { isLoading, error, submitAssignment };
};

export default useSubmitAssignment;
