import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AdminRegistration {
    data: any
    token: string | null
    email: string | null
    isLoading: boolean;
    error: string | null;
}

const initialState: AdminRegistration = {
    data: null,
    token: null,
    email: null,
    isLoading: false,
    error: null,
};

const adminregistrationslice = createSlice({
    name: 'adminRegistration',
    initialState,
    reducers: {
        setadminRegistration: (state : AdminRegistration, action: PayloadAction<AdminRegistration>) => {
            state.data = action.payload.data;
            state.token = action.payload.token;
            state.email = action.payload.data.email;
            state.isLoading = false;
            state.error = null;
        },
        loading: (state: AdminRegistration, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null; 
        },
        seterror: (state : AdminRegistration, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetadminregistration: () => {
            return initialState;
        },
    },
});

export const { setadminRegistration, loading, seterror, resetadminregistration } = adminregistrationslice.actions;

export default adminregistrationslice.reducer;
