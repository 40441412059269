import React from "react";
import {
  Privacy_Policy_title,
  Privacy_Policy_image,
  half_circle_bg_image,
  half_circle_bg_image2,
  book_logo3,
  Proschool_logo,
  instagrme_icon,
  facebook_icon,
  youtube_icon,
  linkedin_icon,
  twitter_icons
} from "../../assets/images/images";
import { NavLink, useNavigate } from "react-router-dom";
import HomeNavbar from "../home/components/HomeNavbar";
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from "react-helmet";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {

  const navigate = useNavigate();
  const globalState = useSelector((state) => state.globalState);
  const subadmin_details = globalState.subadmin_details;
  const handleClick = (e) => {
    e.preventDefault(); // Prevent default link behavior
    // Navigate to the home page
    navigate("/");

    // Use setTimeout to delay the scroll to the specific section
    setTimeout(() => {
      const element = document.getElementById("revolution_sec_new");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); // Adjust the timeout as needed
  };

  return (
    <>


      <Helmet>
        <title> ProSchool.ai Privacy Policy - Protecting Your Data</title>
        <meta name="description" content=" Your privacy matters at ProSchool.ai. Read our policy on how we protect your data in our AI-powered educational ecosystem." />
        <meta name="keywords" content=" Privacy policy, data protection, ProSchool.ai privacy, secure learning, educational privacy" />
        {/* Add more meta tags as needed */}
      </Helmet>

      <h1 style={{display : 'none'}}> ProSchool.ai Privacy Policy </h1>
      <HomeNavbar />
      <div className="Terms-and-Conditions-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="Terms-and-Conditions-wrapper">
                <div className="Privacy_Policy_title">
                  <img src={Privacy_Policy_title} />
                </div>
                <div className="Terms-and-Conditions-image">
                  <img src={Privacy_Policy_image} />
                </div>
                <div className="Terms-and-Cond itions-list-wrapper">
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>1. Introduction</h2>
                    <p>
                      This Privacy Policy outlines how we collect, use, and
                      protect the personal information of users on our Learning
                      Management System (LMS) platform. By using our services,
                      you consent to the practices described in this policy.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>2. Information Collected</h2>
                    <p>
                      We collect basic information such as names and email IDs
                      for security purposes. Additionally, we gather background
                      data to enable our AI system to offer a more tailored
                      learning experience. The data collected may include, but
                      is not limited to, academic performance, learning
                      preferences, and interaction history.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>3. Purpose of Data Collection</h2>
                    <p>
                      The information collected is used exclusively for
                      educational purposes. It aids in customizing lessons,
                      generating personalized assessments, and enhancing the
                      overall learning experience on our platform.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>4. Data Security</h2>
                    <p>
                      We employ industry-standard security measures to protect
                      the confidentiality and integrity of user data. This
                      includes encryption during data transmission and secure
                      storage practices.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>
                      5. EU General Data Protection Regulation (GDPR) Compliance
                    </h2>
                    <p>
                      If you are accessing our services from the European Union
                      (EU), you have specific rights under GDPR. These rights
                      include the right to access, rectify, or erase your
                      personal data. To exercise these rights, please contact us
                      using the provided contact information.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>
                      6. Children's Online Privacy Protection Act (COPPA)
                      Compliance
                    </h2>
                    <p className="childrens-online-privacy-protection-act">
                      {/* Our platform complies with the Children's Online Privacy
                      Protection Act (COPPA). We do not knowingly collect
                      personal information from children under the age of 13. If
                      it comes to our attention that we have inadvertently
                      collected such information, we will promptly take steps to
                      delete it. */}
                      We obtain Verifiable Parental Consent for children under the age of 13 Years intending to use our services and Verifiable Parental Consent from Educational Institutions / Teachers acting as the parents agents if the targeted audience intended to use our services are 13 Years and below.
                      Please read our Collection, Use and Disclosure Practices mentioned below.
                      <ul className="Terms-and-Conditions-list">
                        <span>1. ProSchool.ai collects the following information:</span>
                        <li>Information You Provide: When you use our services, you may provide us with personal information of the child such as the name, contact information, email address necessary for the creation of an account.</li>
                        <li>Information We Collect Automatically: We may automatically collect certain information about the child’s use of our services, including Geolocation data and device information along with browser type and usage patterns. The data collected may also include, but is not limited to, academic performance, learning preferences, and interaction history.</li>
                      </ul>
                      <ul className="Terms-and-Conditions-list">
                        <span>2. How ProSchool.ai uses the Information:</span>
                        <li>The information collected is used exclusively for educational purposes. It aids in customizing lessons, generating personalized assessments, and enhancing the overall learning experience on our platform.</li>
                        <li>We also use the information we collect to provide, maintain, and improve the services and insights based on AI analysis.</li>
                      </ul>
                      <ul className="Terms-and-Conditions-list">
                        <span>3. Disclosure of information collected</span>
                        <li>We do not provide personal information of the Children to any third party operators, such as advertisers, analytics providers, or service providers. The information collected is specifically used as to how we use your information.</li>
                      </ul>
                      <ul className="Terms-and-Conditions-list">
                        <span>4. Data Deletion</span>
                        <li>The consent provided to us is revocable and the personal information provided to us will be deleted from our servers within a reasonable time period of 20-30 days except where ProSchool.ai is required to retain copies under applicable laws, in which case ProSchool.ai will isolate and protect such personal data from any further processing except to the extent required by applicable laws.</li>
                      </ul>
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>7. Data Sharing</h2>
                    <p>
                      All content provided on the platform, including courses,
                      lessons, and assessments, is the intellectual property of
                      the respective creators. Users are prohibited from
                      reproducing, distributing, or otherwise using this content
                      without explicit permission.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>8. AI Chatbot Interactions</h2>
                    <p>
                      Both students and teachers can personalize their AI
                      chatbot interactions. These interactions are monitored to
                      enhance communication and support but are not used for
                      purposes other than improving the learning experience.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>9. Data Retention</h2>
                    <p>
                      We retain user data for the duration of the user's
                      account. Users can request the deletion of their account
                      and associated data by contacting us.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>10. Changes to Privacy Policy</h2>
                    <p>
                      We reserve the right to update this Privacy Policy to
                      reflect changes in our practices and services. Users will
                      be notified of any significant changes, and continued use
                      of the platform after such modifications constitutes
                      acceptance of the revised Privacy Policy.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>11. Contact Information.</h2>
                    <p>
                      For any queries or a detailed walkthrough of our platform,
                      please contact us at support@arcitech.ai
                    </p>
                  </div>
                </div>
                <div className="half-circle-bg-image">
                  <img src={half_circle_bg_image} />
                </div>
                <div className="half-circle-bg-image2">
                  <img src={half_circle_bg_image2} />
                </div>
                <div className="book-logo3">
                  <img src={book_logo3} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-section" id="footer-section">
        <div className="footer-wraper">
          <div className="footer-link-folder">
            <div className="proschool-logo">
              <img src={subadmin_details ? subadmin_details?.school_image : Proschool_logo} />
            </div>
            <p>We are more than just an LMS; we are a catalyst for comprehensive educational transformation. ProSchool.ai brings a multidimensional approach to education, 
              contrasting with traditional methods.
            </p>
            {/* <div className="footer-Join-our-newsletter-folder">
              <p>Join our newsletter</p>
              <div className="footer-Join-our-newsletter-search-folder">
                <input type="email" placeholder="Your email"></input>
                <button>Subscribe</button>
              </div>
            </div> */}
          </div>
          <div className="footer-Quick-Links-folder">
            <h2>Quick Links</h2>
            <ul className="footer-Quick-Links">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>
                <a href="revolution_sec_new" onClick={handleClick}>
                  Features
                </a>
              </li>
              <li>
                <NavLink to="/about">About Us</NavLink>
              </li>
              <li>
                <NavLink to="/terms-and-conditions">Terms & Conditions</NavLink>
              </li>
              <li>
                <NavLink to="/privacy-policy">Privacy Policy</NavLink>
              </li>
            </ul>
          </div>
          <div className="footer-Quick-Links-folder">
            <h2>Contact</h2>
            <div className="footer-contact">
              {/* <div className="footer-contact-folder">
                <a>
                  <i class="fa-solid fa-phone"></i>
                </a>
                <span>
                <a href="tel:+1 (646) 222-3901">{subadmin_details ? subadmin_details?.phone : '+1 (646) 222-3901'}</a>
                </span>
              </div> */}
              <div className="footer-contact-folder">
                <a>
                  <i class="fa-solid fa-envelope"></i>
                </a>
                {subadmin_details ? (
                  <a href={`mailto:${subadmin_details?.email}`}>{subadmin_details.email}</a>
                ) : (
                  <a href="mailto: info@proschool.ai">info@proschool.ai</a>
                )}


              </div>
              {/* <div className="footer-contact-folder">
                <a>
                  <i class="fa-solid fa-location-dot"></i>
                </a>
                {subadmin_details ? (
                  subadmin_details?.location
                ) : (
                  <a
                    href="https://www.google.com/maps/place/Arcitech+-+AI+Software,+Web,+App+Development/@19.0020445,72.8229651,16z/data=!4m10!1m2!2m1!1sA-802,+2nd+floor,+Trade+Link,+Kamala+Mills,+Lower+Parel,+Mumbai,+Maharashtra+400013!3m6!1s0x3be7ce9336955555:0x743645ef3c0b1a05!8m2!3d19.0033511!4d72.8274615!15sClNBLTgwMiwgMm5kIGZsb29yLCBUcmFkZSBMaW5rLCBLYW1hbGEgTWlsbHMsIExvd2VyIFBhcmVsLCBNdW1iYWksIE1haGFyYXNodHJhIDQwMDAxM1pPIk1hIDgwMiAybmQgZmxvb3IgdHJhZGUgbGluayBrYW1hbGEgbWlsbHMgbG93ZXIgcGFyZWwgbXVtYmFpIG1haGFyYXNodHJhIDQwMDAxM5IBEHNvZnR3YXJlX2NvbXBhbnmaASNDaFpEU1VoTk1HOW5TMFZKUTBGblNVTkxiMHBEVm1ObkVBReABAA!16s%2Fg%2F11lt56gqrs?entry=ttu"
                    target="_blank"
                  >
                    A-802, 2nd floor, Trade Link, Kamala Mills, Lower Parel,
                    Mumbai, Maharashtra 400013
                  </a>
                )}
              </div> */}
            </div>
          </div>
          <div className="footer-get-in-touch-folder">
            <span>Get in touch</span>
            <div className="footer-social-link-folder">
              <a
                href="https://www.facebook.com/proschoolai/"
                target="_blank"
              >
                {/* <i className="fa-brands fa-facebook"></i> */}
                <img src={facebook_icon}/>
              </a>
              <a 
              href="https://twitter.com/Proschoolai" 
              target="_blank"
              >
                {/* <i class="fa-brands fa-x-twitter"></i> */}
                <img src={twitter_icons}/>
              </a>
              <a
                href="https://www.instagram.com/proschool.ai/"
                target="_blank"
              >
                {/* <i className="fa-brands fa-instagram"></i> */}
                <img src={instagrme_icon}/>
              </a>
              <a
                href="https://www.linkedin.com/company/proschool-ai"
                target="_blank"
              >
                {/* <i class="fa-brands fa-linkedin"></i> */}
                <img src={linkedin_icon}/>
              </a>
              <a 
              href="https://www.youtube.com/@ProSchoolAI" 
              target="_blank"
              >
                {/* <i class="fa-brands fa-youtube"></i> */}
                <img src={youtube_icon}/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
