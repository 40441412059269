import OutlineFIleInput from "../styledComponents/OutlineButton";
import { FormControl, FormHelperText, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import Autocomplete from "react-google-autocomplete";
import { GOOGLE_MAP_KEY } from "../../consts/constants";
import CropProfileImageModal from "../../pages/home/components/dashboard/CropProfileImageModal";
import dayjs from "dayjs";
import { compressImage } from "../../utils/CompressImage";
import { toast } from "react-toastify";
import api from "../../api/api";
import { useAuth } from "../../features/auth/AuthContext";
import { addSchool, resetaddSchool } from "../../state/actions/addSchoolAction"
import { useDispatch, useSelector } from "react-redux";
import { updateGlobalState } from "../../state/global/slice.tsx";

import {
  isRequired,
  isAlphanumeric,
  isValidEmail,
  isValidDomain,
  isValidMobileNumber,
} from "../../utils/validator";
import { updateSchoolId } from "../../state/actions/updateSchoolIDAction";
import {
  Regenerate_course_image,
  close,
  location_image,
  add_school_icons,
  add_school_location,
  add_school_profile,
  add_school_building,
  add_school_call,
  add_school_email
} from "../../assets/images/images";
import "./AddSchool.css";
// import OutlineFIleInput from "../../../../../../components/styledComponents/OutlineButton";

const AddSchool = ({ setAddSchool, toggleAddSchool }) => {
  const { token, login } = useAuth();
  const [hasSelectedPlace, setHasSelectedPlace] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const [imageUploaded, setImageUploaded] = useState(false);
  const [uplodedImage, setUplodedImage] = useState(null);
  const getEmail = useSelector((state) => state.globalState.email);
  const [formState, setFormState] = useState(() => {
    // Attempt to load existing form data from local storage
    const savedFormData = localStorage.getItem("student_formState");
    var _savedFormData = {};
    try {
      _savedFormData = JSON.parse(savedFormData);
    } catch (error) {
      console.log(error);
    }
    // const globalState = useSelector((state) => state.globalState);
    return savedFormData
      ? _savedFormData
      : {
        name: null,
        phone: null,
        email: getEmail,
        domain: null,
        subdomain: null,
        description: null,
        location: null,
        country_code: "+1",
        image: null,
        admin_name: null,
        admin_number: null,
      };
  });
  const [formErrors, setFormErrors] = useState({
    name: null,
    phone: null,
    email: null,
    domain: null,
    subdomain: null,
    description: null,
    location: null,
    // country_code: "+1",
    image: null,
    admin_name: null,
    admin_number: null,
  });

  const dispatch = useDispatch();
  const addschool = useSelector((state) => state.addSchool)
  const { role } = useSelector((state) => state.globalState);
  const handlePlaceSelect = (place) => {
    if (place && place.formatted_address) {
      setFormState((prev) => ({
        ...prev,
        location: place.formatted_address,
      }));
      setFormErrors({ location: "" });
      setHasSelectedPlace(true);
    } else {
      setFormErrors({ location: "Please enter a valid location" });
    }
  };
  const handleAutocompleteInputChange = (event) => {
    setFormState((prev) => ({
      ...prev,
      location: event.target.value,
    }));
    if (event.target.value === "" || !hasSelectedPlace) {
      setFormErrors({ location: "Please enter a valid location" });
    } else {
      setFormErrors({ location: "" });
    }
  };

  const handleInputBlur = () => {
    if (!hasSelectedPlace && formState.location) {
      setFormState((prev) => ({
        ...prev,
        location: "",
      }));
      setFormErrors({ location: "Please enter a valid location" });
    }
  };

  const handleInputChange = async (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let error = "";
    if (name === "name") {
      error = isRequired(value);
    } else if (name === "email") {
      error = isRequired(value) || isValidEmail(value);
    }
    // else if (name === "phone") {
    //   error =
    //     isRequired(value) ||
    //     isValidMobileNumber(value, selectedCountry.dialCode);
    // }
    else if (name === "location") {
      error = isRequired(value);
    } else if (name === "description") {
      error = isRequired(value);
    } else if (name === "domain") {
      error = isRequired(value) || isValidDomain(value);
    } else if (name === "subdomain") {
      error = isAlphanumeric(value);
    } else if (name === "admin_name") {
      error = isRequired(value);
    } else if (name === "admin_number") {
      error = isRequired(value);
    }
    //   else if (name === "country_code") {
    //   error = isValidMobileNumber(value, selectedCountry.dialCode);
    // }

    setFormErrors({
      ...formErrors,
      [name]: error,
    });

    if (name === 'subdomain') {
      setFormState((prev) => ({ ...prev, [name]: value.toLowerCase() }));
    } else {
      setFormState((prev) => ({ ...prev, [name]: value }));
    }

  };

  const submitForm = async (e) => {
    e.preventDefault();
    let errors = {};
    errors.name = isRequired(formState.name);
    errors.phone = isRequired(formState.phone);
    errors.email = isRequired(formState.email) || isValidEmail(formState.email);
    errors.domain =
      isRequired(formState.domain) || isValidDomain(formState.domain);
    errors.subdomain = isAlphanumeric(formState.subdomain);
    errors.description = isRequired(formState.description);
    errors.admin_name = isRequired(formState.admin_name);
    errors.admin_number = isRequired(formState.admin_number);
    errors.location =
      formState.location === "" || formState.location === null
        ? "Please enter a valid location"
        : "";
    errors.image = selectedImage === null ? "Add School Logo/Icon *" : "";
    setFormErrors(errors);
    if (!Object.values(errors).some((error) => error)) {
      await submitSchoolDtails();
    }
  };

  const updateState = (key, value) => {
    dispatch(updateGlobalState({ key: key, value: value }));
  };

  useEffect(() => {
    const failedErrorMessage =
      "Oops, something went wrong. Please try again later.";
    if (addschool?.message?.length) {

      toast.success(addschool?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      if (role === "Admin") {
        const req = {
          school_id: addschool.school_id
        }
        updateState("school_id", addschool.school_id);
        dispatch(updateSchoolId(req, token));
      }
      dispatch(resetaddSchool());
      toggleAddSchool();
    } else if (addschool?.error?.length) {
      toast.error(addschool?.error || failedErrorMessage, {
        position: toast.POSITION.TOP_CENTER,
      });
      dispatch(resetaddSchool());
    }
  }, [addschool]);


  const submitSchoolDtails = async () => {
    toast.dismiss();
    // let _formData = formState;

    dispatch(addSchool(formState, token, role));

  };
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const onOpenModal = () => {
    setModalIsOpen(true);
  };

  const onCloseModal = () => {
    setModalIsOpen(false);
  };


  // const handleFocus = (event) => {
  //   event.target.blur(); // Prevents the field from being focused, hence not editable
  // };


  const [imageUploading, setImageUploading] = useState(false);
  // Handle file input
  const handleFileInput = async (event) => {
    setImageUploading(true);
    setSelectedImage(null);
    setImageUploaded(false)

    const file = event.target.files[0];
    if (file) {
      try {
        // Compress the image
        const compressedImage = await compressImage(file);

        setSelectedImage(URL.createObjectURL(compressedImage));
        onOpenModal();
        // setCourseFormState((prev) => ({
        //   ...prev,
        //   image: compressedImage,
        //   image_url: null,
        // }));

        setFormErrors({
          ...formErrors,
          image: "",
        });
      } catch (error) {
        console.error("Image compression failed:", error);
        setSelectedImage(null);
        // Handle error and set form error
        setFormErrors({
          ...formErrors,
          image: "Image compression failed.",
        });
      } finally {
        // Hide loader when compression is complete
        setImageUploading(false);
      }
    } else {
      setSelectedImage(null);
      // Hide loader when no file is selected (cancel)
      setImageUploading(false);
    }
  };

  return (
    <>
      {setAddSchool && (
        <form action="" className="Create-Courses-section">
          <div className="Create-Courses-wrapper">
            <h2 className="add-school">Add School</h2>
            <div className="Add-school-wrapper">
              <div className="Create-Course-inner-input-container">
                <div className="Add-school-left-inner-input">
                  <div className="Create-Course-inner-input Add-school-inner-input">
                    <label>School Name</label>
                    <FormControl margin="none">
                      <TextField
                        name="name"
                        value={formState.name || ""}
                        onChange={handleInputChange}
                        error={!!formErrors.name}
                        placeholder="Enter school name"
                        variant="outlined"
                        size="small"
                        className="add-school-input"
                        InputProps={{
                          sx: {
                            borderRadius: "12px",
                            backgroundColor: "#F5F5F5",
                            padding: "0px",
                          },
                        }}
                      />
                      <FormHelperText sx={{ color: "red" }}>{formErrors.name}</FormHelperText>
                    </FormControl>
                    <div className="add-school-icons">
                      <img src={add_school_building} />
                    </div>
                  </div>
                  <div className="Create-Course-inner-input Add-school-inner-input">
                    <label>Admin Name</label>
                    {/* <div className="School-location admin-name">
                      <Autocomplete
                        apiKey={GOOGLE_MAP_KEY}
                        onPlaceSelected={handlePlaceSelect}
                        defaultValue={formState.location}
                        onChange={handleAutocompleteInputChange}
                        onBlur={handleInputBlur}
                        options={{
                          types: ["(cities)"],
                        }}
                      />
                    </div> */}
                    <FormControl margin="none">
                      <TextField
                        name="admin_name"
                        value={formState.admin_name || ""}
                        onChange={handleInputChange}
                        error={!!formErrors.admin_name}
                        placeholder="Enter your name"
                        variant="outlined"
                        size="small"
                        className="add-school-input"
                        InputProps={{
                          sx: {
                            borderRadius: "12px",
                            backgroundColor: "#F5F5F5",
                            padding: "0px",
                          },
                        }}
                      />
                      <FormHelperText sx={{ color: "red" }}>{formErrors.admin_name}</FormHelperText>
                    </FormControl>
                    <div className="add-school-icons3">
                      <img src={add_school_profile} />
                    </div>
                    {/* <FormHelperText sx={{ color: "red" }}>{formErrors.location}</FormHelperText> */}
                    {/* <div className="form-icon location-icon">
                      <img src={location_image} alt="" />
                  </div> */}
                    {/* <p>{formErrors.location}</p> */}
                  </div>
                </div>
                <div className="Add-school-inner-input-feilds-folder">
                  <div className="Create-Course-inner-input Add-school-inner-input">
                    <label>Your contact number</label>
                    <FormControl margin="none">
                      <TextField
                        name="admin_number"
                        type="number"
                        value={formState.admin_number || ""}
                        onChange={handleInputChange}
                        error={!!formErrors.admin_number}
                        placeholder="Enter your contact number"
                        variant="outlined"
                        size="small"
                        className="add-school-input"
                        InputProps={{
                          sx: {
                            borderRadius: "12px",
                            backgroundColor: "#F5F5F5",
                            padding: "0px",
                            // Add the following lines to your sx prop
                            "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
                              "-webkit-appearance": "none",
                              margin: 0,
                            },
                            "&[type=number]": {
                              "-moz-appearance": "textfield",
                            },
                          },
                        }}
                      />
                      <FormHelperText sx={{ color: "red" }}>{formErrors.admin_number}</FormHelperText>
                    </FormControl>
                    <div className="add-school-icons2">
                      <img src={add_school_call} />
                    </div>
                  </div>
                  <div className="Create-Course-inner-input Add-school-inner-input">
                    <label>School contact number</label>
                    <FormControl margin="none">
                      <TextField
                        name="phone"
                        type="number"
                        value={formState.phone || ""}
                        onChange={handleInputChange}
                        error={!!formErrors.phone}
                        placeholder="Enter school contact number"
                        variant="outlined"
                        size="small"
                        className="add-school-input"
                        InputProps={{
                          sx: {
                            borderRadius: "12px",
                            backgroundColor: "#F5F5F5",
                            padding: "0px",
                            // Add the following lines to your sx prop
                            "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
                              "-webkit-appearance": "none",
                              margin: 0,
                            },
                            "&[type=number]": {
                              "-moz-appearance": "textfield",
                            },
                          },
                        }}
                      />
                      <FormHelperText sx={{ color: "red" }}>{formErrors.phone}</FormHelperText>
                    </FormControl>
                    <div className="add-school-icons2">
                      <img src={add_school_call} />
                    </div>
                  </div>
                  <div className="Create-Course-inner-input Add-school-inner-input">
                    <label>School Email ID</label>
                    <FormControl margin="none">
                      <TextField
                        name="email"
                        // value={getEmail || ""}
                        value={formState.email || ""}
                        onChange={handleInputChange}
                        error={!!formErrors.email}
                        placeholder="Enter school email id"
                        variant="outlined"
                        size="small"
                        className="add-school-input"
                        disabled
                        // readOnly 
                        // onFocus={handleFocus}
                        InputProps={{
                          sx: {
                            borderRadius: "12px",
                            backgroundColor: "#F5F5F5",
                            padding: "0px",
                          },
                        }}
                      />
                      <FormHelperText sx={{ color: "red" }}>{formErrors.email}</FormHelperText>
                    </FormControl>
                    <div className="add-school-icons2">
                      <img src={add_school_email} />
                    </div>
                  </div>
                  <div className="Create-Course-inner-input Add-school-inner-input">
                    <label>School Location</label>
                    <div className="School-location">
                      <Autocomplete
                        apiKey={GOOGLE_MAP_KEY}
                        onPlaceSelected={handlePlaceSelect}
                        defaultValue={formState.location}
                        placeholder="Enter school location"
                        onChange={handleAutocompleteInputChange}
                        onBlur={handleInputBlur}
                        options={{
                          types: ["(cities)"],
                        }}
                      />
                    </div>
                    <div className="add-school-icons3">
                      <img src={add_school_location} />
                    </div>
                    <FormHelperText sx={{ color: "red" }}>{formErrors.location}</FormHelperText>
                    {/* <div className="form-icon location-icon">
                      <img src={location_image} alt="" />
                  </div> */}
                    {/* <p>{formErrors.location}</p> */}
                  </div>
                  <div className="Create-Course-inner-input Add-school-inner-input">
                    <label>School Domain</label>
                    <FormControl margin="none">
                      <TextField
                        name="domain"
                        value={formState.domain || ""}
                        onChange={handleInputChange}
                        error={!!formErrors.domain}
                        placeholder="Enter Domain"
                        variant="outlined"
                        size="small"
                        className="add-school-input"
                        InputProps={{
                          sx: {
                            borderRadius: "12px",
                            backgroundColor: "#F5F5F5",
                            padding: "0px",
                          },
                        }}
                      />
                      <FormHelperText sx={{ color: "red" }}>{formErrors.domain}</FormHelperText>
                    </FormControl>
                    <div className="add-school-icons2">
                      <img src={add_school_icons} />
                    </div>
                  </div>
                  <div className="Create-Course-inner-input Add-school-inner-input">
                    <label>Create Your Sub-Domain</label>
                    <FormControl margin="none">
                      <TextField
                        name="subdomain"
                        value={formState.subdomain || ""}
                        onChange={handleInputChange}
                        error={!!formErrors.domain}
                        placeholder="subdomain"
                        variant="outlined"
                        size="small"
                        className="add-school-input"
                        InputProps={{
                          sx: {
                            borderRadius: "12px",
                            backgroundColor: "#F5F5F5",
                            padding: "0px",
                          },
                        }}
                      />
                      <FormHelperText sx={{ color: "red" }}>{formErrors.subdomain}</FormHelperText>
                    </FormControl>
                    <div className="add-school-icons2">
                      <img src={add_school_icons} />
                    </div>
                    <button type="button" className="create-your-Sub-domainproschool_ai_btn">.proschool.ai</button>
                  </div>
                </div>
                <div className="add-school-des-wrapper">
                  <div className="Create-Course-des-folder Add-school-inner-des">
                    {/* <div className="Create-Course-inner-input Add-school-inner-input">
                      <label>Sub domain Name</label>
                      <FormControl margin="none">
                        <TextField
                          name="subdomain"
                          value={formState.subdomain || ""}
                          onChange={handleInputChange}
                          error={!!formErrors.subdomain}
                          placeholder="Enter subdomain"
                          variant="outlined"
                          size="small"
                          InputProps={{
                            sx: {
                              borderRadius: "12px",
                              backgroundColor: "#F5F5F5",
                              padding: "0px",
                            },
                          }}
                        />
                        <FormHelperText sx={{ color: "red" }}>{formErrors.subdomain}</FormHelperText>
                      </FormControl>
                    </div> */}
                    <div className="Create-Course-des-title">
                      {/* <label>School description (Note : Only 500 letter allowed )</label> */}
                      <label>School description <span>(Note : Only 500 letter allowed )</span></label>
                    </div>
                    <FormControl margin="none">
                      <TextField
                        id="create-course-description"
                        name="description"
                        multiline
                        variant="outlined"
                        size="small"
                        margin="normal"
                        cols="30"
                        rows="6"
                        value={formState.description || ""}
                        onChange={handleInputChange}
                        error={!!formErrors.description}
                        placeholder="Enter a short description about your school"
                        InputProps={{
                          sx: {
                            borderRadius: "12px",
                            backgroundColor: "#F5F5F5",
                          },
                        }}
                        inputProps={{
                          maxLength: 500, // Set the maximum length of the input
                        }}
                      />
                      <FormHelperText sx={{ color: "red" }}>{formErrors.description}</FormHelperText>
                    </FormControl>
                    <div className="create-course-enhance-course-folder">
                      <div className="Course-description-inner-ai-feilds-folder">
                        {/* <EnhancedDescription
                                            courseName={courseFormState.name || ""}
                                            subject={courseFormState.subject || ""}
                                            from_grade={courseFormState.from_grade || ""}
                                            to_grade={courseFormState.to_grade || ""}
                                            keywords={courseFormState.description || ""}
                                        /> */}
                      </div>
                    </div>
                    <div className="Create-Course-btn-wrapper add-school-btn-folder">
                      <button type="button" className="add-school-cancle-btn">
                        Cancel
                      </button>
                      <button
                        type="submit"
                        onClick={submitForm}
                        className="Create-Courses-btn add-school-btn"
                      >
                        Add School
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Add-school-right-section">
                <div className="uploded-image-section">
                  <div className="Create-Course-image">
                    <div className="Create-Course-image-disclamer">
                      <p>School Logo/Image</p>
                    </div>

                    <div className="course-file-image add-school-file-image" />
                    {/* <div class="upload-container">
                        <button class="upload-btn">+ Upload Image</button>
                        <div class="action-btns">
                          <button class="action-btn">Cancel</button>
                          <button class="action-btn">Add School</button>
                        </div>
                      </div> */}
                    <div className="Create-Course-uplode-image-container" id="add-school-image-container">
                      <div className="Create-Course-uisng-ai uplode-image" id="uplode_image_btn">
                        {uplodedImage && (
                          <>
                            <div className="course-main-image">
                              <ReactSVG
                                className="course-image-close"
                                src={close}
                                onClick={() => {
                                  setFormState((prev) => ({
                                    ...prev,
                                    image: "",
                                  }));
                                  setUplodedImage(null)
                                  setImageUploaded(false)
                                  setSelectedImage(null);
                                }}
                              />
                              <img
                                className="course-uploded-image"
                                src={imageUploaded ? selectedImage : uplodedImage}
                                // src={selectedImage}
                                alt=""

                              />
                            </div>
                          </>
                        )}
                        {/* {selectedImage && (
                          <>
                            <div className="course-main-image">
                              <ReactSVG
                                className="course-image-close"
                                src={close}
                                onClick={() => {
                                  setFormState((prev) => ({
                                    ...prev,
                                    image: "",
                                  }));
                                  setSelectedImage(null);
                                }}
                              />
                              <img
                                className="course-uploded-image"
                                src={selectedImage}
                                alt=""

                              />
                            </div>
                          </>
                        )} */}
                        {!uplodedImage && (
                          <>
                            <OutlineFIleInput
                              label={"Upload Image"}
                              handleFileInput={handleFileInput}
                            />
                            {imageUploading && (
                              <img
                                className={` ${imageUploading ? "spinning" : ""
                                  }`}
                                src={Regenerate_course_image}
                                alt=""
                              />
                            )}
                          </>
                        )}
                        {/* {!selectedImage && (
                          <>
                            <OutlineFIleInput
                              label={"Upload Image"}
                              handleFileInput={handleFileInput}
                            />
                            {imageUploading && (
                              <img
                                className={` ${imageUploading ? "spinning" : ""
                                  }`}
                                src={Regenerate_course_image}
                                alt=""
                              />
                            )}
                          </>
                        )} */}
                      </div>
                    </div>
                    <span style={{ margin: "0px 112px", fontSize: "0.75rem", fontWeight: "400", color: "red" }}>{formErrors.image}</span>
                  </div>
                  <div className="Create-Course-btn-wrapper add-school-btn-folder">
                    {role === "SuperAdmin" && <button type="button" onClick={toggleAddSchool} className="Create-Course-cancle-btn">
                      Cancel
                    </button>}
                    {uplodedImage && (
                      <>
                        <div className="reuplode-image-folder">
                          <div className="Create-Course-uplode-outer-image">
                            <OutlineFIleInput
                              label={"Re-Upload Image"}
                              handleFileInput={(e) => {
                                setSelectedImage(null);
                                handleFileInput(e);
                              }}
                            />
                          </div>

                        </div>
                      </>
                    )}
                    {/* {selectedImage && (
                      <>
                        <div className="reuplode-image-folder">
                          <div className="Create-Course-uplode-outer-image">
                            <OutlineFIleInput
                              label={"Re-Upload Image"}
                              handleFileInput={(e) => {
                                setSelectedImage(null);
                                handleFileInput(e);
                              }}
                            />
                          </div>

                        </div>
                      </>
                    )} */}
                    {/* <button
                      type="submit"
                      onClick={submitForm}
                      className="Create-Courses-btn"
                    >
                      Add School
                    </button> */}
                    {/* <button
                      type="submit"
                      onClick={submitForm}
                      className="Create-Courses-btn add-school-btn"
                    >
                      Add School
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {modalIsOpen && (
            <CropProfileImageModal
              src={selectedImage}
              setSrc={setSelectedImage}
              setSelectedImage={setSelectedImage}
              setImageUploaded={setImageUploaded}
              setUplodedImage={setUplodedImage}
              modalStatus={modalIsOpen}
              setModalClose={onCloseModal}
              fieldName={"image"}
              setFormState={setFormState}
              formState={formState}
              aspectRatio={4 / 1}
            />
          )}
        </form>
      )}
    </>
  );
};

export default AddSchool;
