import { Dispatch } from "redux";
import api from "../../api/api";
import {
    setgetLesson,
    setloading,
    seterror,
    resetgetLesson,
} from "../reducers/getLessonByLessonIdReducer";

const getLessonDetails = (id: any, token: any) => async (
    dispatch: Dispatch
) => {
    dispatch(setloading(true));
    try {
        const result = await api.get(`/lessons/get-lesson/${id}/`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
        });

        if (result) {
            dispatch(setloading(false));
            if (result.data?.status === 200) {
                dispatch(setgetLesson(result.data.data));
                // dispatch(setPagination(result.data));
            } else {
                dispatch(seterror(result.data?.message ?? "Something Went Wrong!"));
            }
        }
    } catch (error: any) {
        dispatch(seterror(error.response.data.message));
    }
};

export { getLessonDetails, resetgetLesson };

