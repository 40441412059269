import React from "react";
import { CircularProgress, Grid } from "@mui/material";

const LoadingComponent = ({loading_text}) => {
  
  return (
    <>
        <div className="loading-gif">
            <Grid
                height={"50vh"}
                xs={12}
                container
                justifyContent={"center"}
                alignItems={"center"}
            >
                <CircularProgress size={50} />
            </Grid>
            <p className="loading-text">{loading_text}</p> 
        </div>
    </>
  );
};

export default LoadingComponent;
