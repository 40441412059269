import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FirstLogin {
    message: string | null;
    isLoading: boolean;
    error: string | null;
}

const initialState: FirstLogin = {
    message: null,
    isLoading: false,
    error: null,
};

const firstLoginslice = createSlice({
    name: 'firstLogin',
    initialState,
    reducers: {
        setfirstLogin: (state : FirstLogin, action: PayloadAction<FirstLogin>) => {
            state.message = action.payload.message;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: FirstLogin, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null; 
        },
        seterror: (state : FirstLogin, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetfirstLogin: () => {
            return initialState;
        },
    },
});

export const { setfirstLogin, setloading, seterror, resetfirstLogin } = firstLoginslice.actions;

export default firstLoginslice.reducer;
