import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ModifySubscription {
    isLoading: boolean;
    error: string | null;
    message: string | null;
}

const initialState: ModifySubscription = {
    message: null,
    isLoading: false,
    error: null,
};

const  ModifyUserSubscriptionSlice = createSlice({
    name: ' ModifyUserSubscription',
    initialState,
    reducers: {
        ModifyUserSubscriptionData: (state: ModifySubscription, action: PayloadAction<ModifySubscription>) => {
            state.message = action.payload.message;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: ModifySubscription, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: ModifySubscription, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetModifyUserSubscription: () => {
            return initialState;
        },
    },
});


export const { ModifyUserSubscriptionData, setloading, seterror, resetModifyUserSubscription } = ModifyUserSubscriptionSlice.actions;

export default ModifyUserSubscriptionSlice.reducer;
