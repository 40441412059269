import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface StudentSubscription {
    studentSubscription: any;
    isLoading: Boolean;
    error: string | null;
}

const initialState: StudentSubscription = {
    studentSubscription: [],
    isLoading: false,
    error: null,
};

const studentSubscriptionSlice = createSlice({
    name: 'studentSubscription',
    initialState,
    reducers: {
        setstudentSubscription: (state: StudentSubscription, action: PayloadAction<StudentSubscription>) => {
            state.studentSubscription = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        isloading: (state: StudentSubscription, action: PayloadAction<Boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: StudentSubscription, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetstudentSubscription: () => {
            return initialState;
        },
    },
});

export const { setstudentSubscription, isloading, seterror, resetstudentSubscription } = studentSubscriptionSlice.actions;

export default studentSubscriptionSlice.reducer;
