import React, { useEffect, useState } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import api from "../api/api";
import { useAuth } from "../features/auth/AuthContext";
import LinearWithValueLabel from "../utils/LinearProgresslabel";
import MyCourses from "../pages/home/components/dashboard/teacher/Tab/MyCourses";
import { updateGlobalState } from "../state/global/slice";
import Card from "../pages/home/components/dashboard/teacher/course/Card";
import {
    alert_image,
    graduation,
    Teacher_Dashboard_Default,
    dashborad_Courses_book_image,
    Asset_teacher,
    Asset_teacher2,
  } from "../assets/images/images";
  import { toast } from "react-toastify";
import BackButton from "./BackButton/BackButton";

const TeacherCourseList = () => {
  const navigate = useNavigate();
  const { token } = useAuth();
  //   const lessons = [
  //     { name: "Lesson 01", description: "Basic structure of neurons" },
  //     { name: "Lesson 02", description: "Basic structure of akdadsasd" },
  //   ];
  const itemsPerPage = 12;
  const [selLession, setSelLession] = useState(-1);
  
  const [nextUrl, setNextUrl] = useState(null);
  const location = useLocation();
  const [totalPages, setTotalPages] = useState(location?.state?.totalPages ?? 0);
  const [prevUrl, setPrevUrl] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const onExpand = (i) => {
    setSelLession(selLession === i ? -1 : i);
  };
  const [lessonsDtls, setLessonsDtls] = useState();
  const inial_value = (currentPage - 1) * itemsPerPage;
  const [deleteCourse, setdeleteCourse] = useState(false);
  const [loading, setLoading] = useState(false);
  const globalState = useSelector((state) => state.globalState);
    const dispatch = useDispatch();
    const updateState = (key, value) => {
      dispatch(updateGlobalState({ key: key, value: value }));
    };
  const toggleDeleteCourse = () => {
    setdeleteCourse(!deleteCourse);
    setLoading(false);
    document.body.style.overflow = "auto";
  };
 
  const [modalIsOpen, setIsOpen] = React.useState(false);
  
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  const [selectedCourse, setSelectedCourse] = useState(null);
  const deleteCourseId = async () => {
    setLoading(true);
    document.body.style.overflow = "auto";
    try {
      // setTimeout(() => {
      //     setLoading(false);
      //     setdeleteCourse(false);
      // }, 700);

      const response = await api.delete(
        `/courses/delete-course/${selectedCourse}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        }
      );
      if (response.status === 204) {
        setLoading(false);
        const course_list = globalState.course_list?.filter(
          (course) => course.course_id !== selectedCourse
        );
        // const updatedCourseList = courseList.filter(course => course.course_id !== selectedCourse);
        // setCourseList(updatedCourseList);
        updateState("course_list", course_list);
        // setMyCourses(course_list);
        toast.success("Course deleted successfully.", {
          position: toast.POSITION.TOP_CENTER,
        });
        setTimeout(() => {
          setdeleteCourse(false);
        }, 700);
      } else {
        setLoading(false);
        setdeleteCourse(false);
        toast.error("Something went wrong.", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      setLoading(false);
      setdeleteCourse(false);
      // Handle errors (e.g., display error message)
      //
      // setErrorMessage(error.response.data.message); // Set error message
    }
  };
   const handlePageChange = (event, value) => {
    window.scrollTo(0, 0);
    setCurrentPage(value);
  };
  return (
    <>
      <div id="CourseList-section">
        <div className="CourseList-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="view-course-Details-back-btn-folder">
                  <BackButton buttonName={"Back"} />
                  {/* <i
                    className="fa-solid fa-arrow-left"
                  ></i>
                  <h2>Back</h2> */}
                </div>
                {/* <h2 className="student-Active-Lessons">Active Assignment</h2> */}
                <div className="created-my-courses-wrapper">
                   {/* <h2 className="my-course-title">My Courses</h2> */}
              
                  {globalState?.course_list === null ||
                  globalState?.course_list?.length === 0 ? (
                    <>
                      <Card
                        image={Teacher_Dashboard_Default}
                        title="Craft an Interactive Course"
                        description="Crafting an interactive course involves designing engaging lessons with quizzes and activities to actively involve students in the learning process"
                      />
                      <Card
                        image={Teacher_Dashboard_Default}
                        title="Build an Exciting Learning Journey"
                        description="Crafting an interactive course involves designing engaging lessons with quizzes and activities to actively involve students in the learning process"
                      />
                    </>
                  ) : (
                    <MyCourses
                      totalPages={totalPages}
                      currentPage={currentPage}
                      nextUrl={nextUrl}
                      prevUrl={prevUrl}
                      handlePageChange={handlePageChange}
                      setSelectedCourse={setSelectedCourse}
                      closeModal={closeModal}
                      modalIsOpen={modalIsOpen}
                      openModal={openModal}
                      toggleDeleteCourse={toggleDeleteCourse}
                    />
                  )}
                </div>

                {deleteCourse && (
                  <form action="" className="delete-course-form">
                    <div
                      className="delete-course-form-container"
                      data-aos="zoom-in"
                    >
                      <div className="delete-course-alert-image">
                        <img src={alert_image} alt="" />
                      </div>
                      <h2>Are you sure you want to delete this course?</h2>
                      <div className="delete-course-form-btn-folder">
                        <button
                          type="button"
                          onClick={toggleDeleteCourse}
                          className="not-delete-btn"
                        >
                          No
                        </button>
                        {loading ? (
                          <div className="loading-spinner" />
                        ) : (
                          <button
                            type="button"
                            onClick={deleteCourseId}
                            disabled={loading}
                            className="delete-btn"
                          >
                            Delete
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                )}
                {/* <Grid xs={12} container justifyContent={"center"}>
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                    hideNextButton={
                      nextUrl === null || currentPage === totalPages
                    } // Disable if no next URL and on the last page
                    hidePrevButton={prevUrl === null || currentPage === 1} // Hide if no previous URL and on the first page
                  />
                </Grid> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeacherCourseList;