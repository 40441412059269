import { Dispatch } from "redux";
import api from "../../api/api";
import {
    setTeacherProfile,
    setloading,
    seterror,
    resetTeacherProfile,
} from "../reducers/updateTeacherProfileReducer";

const updateTeacherprofileDetails = (data: any, token: any) => async (
  dispatch: Dispatch
) => {
  dispatch(setloading(true));
  try {
    const result = await api.put(`auth/update-teacher/`, 
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Token ${token}`,
      },
    });

    if (result) {
      dispatch(setloading(false));
      if (result.data?.status === 200) {
        dispatch(setTeacherProfile(result.data));
      } else {
        dispatch(seterror(result.data?.message ?? "Something Went Wrong!"));
      }
    }
  } catch (error: any) {
    //dispatch(seterror(error.response.data.message));
  }
};

export { updateTeacherprofileDetails, resetTeacherProfile}; 

