import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserRegistration {
    data: any
    token: string | null
    email: string | null
    isLoading: boolean;
    error: string | null;
}

const initialState: UserRegistration = {
    data: null,
    token: null,
    email: null,
    isLoading: false,
    error: null,
};

const userregistrationslice = createSlice({
    name: 'userRegistration',
    initialState,
    reducers: {
        setuserRegistration: (state : UserRegistration, action: PayloadAction<UserRegistration>) => {
            state.data = action.payload.data;
            state.token = action.payload.token;
            state.email = action.payload.data.email;
            state.isLoading = false;
            state.error = null;
        },
        loading: (state: UserRegistration, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null; 
        },
        seterror: (state : UserRegistration, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetuserRegistration: () => {
            return initialState;
        },
    },
});

export const { setuserRegistration, loading, seterror, resetuserRegistration } = userregistrationslice.actions;

export default userregistrationslice.reducer;
