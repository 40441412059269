import React from 'react'

function Filter({ filterArray, filterOption, setfilterOption, setIsVisiblefillter }) {

    return (
        <div className="sort-popup-assignprocess filter-popup">
            <h3>Filter By:</h3>

            {filterArray?.map((option) => (
                <label>
                    <input
                        type="radio"
                        name="filterOption"
                        value={option.id}
                        checked={option.id === filterOption}
                        onChange={(e) => {
                            setfilterOption(e.target.value);
                            setIsVisiblefillter(false);
                        }}
                    />
                    {option.name}
                </label>
            ))}
        </div>
    )
}

export default Filter