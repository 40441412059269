import React from "react";

function StudentReviewCard({StudentReview_image, StudentReview_title, StudentReview_text}) {

  return <>
    <div className="student-review-card-foler">
        <div className="student-review-card-title">
            <div className="student-review-card-title-image">
                <img src={StudentReview_image}/>
            </div>
            <p>{StudentReview_title}</p>
        </div>
        <div className="StudentReview-star-wrapper">
            <span><i class="fa-solid fa-star"></i></span>
            <span><i class="fa-solid fa-star"></i></span>
            <span><i class="fa-solid fa-star"></i></span>
            <span><i class="fa-solid fa-star"></i></span>
        </div>
        <div className="StudentReview-card-des-folder">
            <p>{StudentReview_text} <span><a href="#">View more</a></span></p>
        </div>
    </div>
  </> 
}

export default StudentReviewCard;