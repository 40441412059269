import { Dispatch } from "redux";
import api from "../../api/api";
import {
    setenrolledcourses,
    setPagination,
    setloading,
    seterror,
    resetenrolledcourses,
} from "../reducers/getStudentEnrolledCoursesReducer";

const getStudentEnrolledCourses = (page: any, id: any, token: any) => async (
    dispatch: Dispatch
) => {
    dispatch(setloading(true));
    try {
        const result = await api.get(`/school-admin/school/get-student-enrolled/${id}/`, {
            params: {
                page: page,
            },
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Token ${token}`,
            },
        });

        if (result) {
            dispatch(setloading(false));
            if (result.data?.status === 200) {
                dispatch(setenrolledcourses(result.data.data));
                dispatch(setPagination(result.data));
            } else {
                dispatch(seterror(result.data?.message ?? "Something Went Wrong!"));
            }
        }
    } catch (error: any) {
        dispatch(seterror(error.response.data.message));
    }
};

export { getStudentEnrolledCourses, resetenrolledcourses };