import React, { useEffect } from "react";
import "../home/styles/r-style.css";
import HomeNavbar from "./components/HomeNavbar";
import "../home/styles/r-style.css";
import { Helmet } from 'react-helmet';

import {
  NavLink,
  NavLink as RouterNavLink,
  useNavigate,
} from "react-router-dom"; // Renamed to RouterNavLink
import { ReactSVG } from "react-svg";
import {
  blue_line_image,
  blue_line_image2,
  choose_your_role_bg_star,
  choose_your_role_book_image,
  choose_your_role_doted3_image,
  choose_your_role_doted4_image,
  choose_your_role_half_circle_image,
  choose_your_role_image1,
  choose_your_role_image2,
  choose_your_role_image3,
  customize_learing_journey_arrow,
  customize_learing_journey_image,
  half_circle_image,
  orange_line_image2,
  transforming_learning_experiences_circle_bg_icon,
  transforming_learning_experiences_doted2_image,
  transforming_learning_experiences_doted_image,
  transforming_learning_experiences_line_image,
  transforming_learning_experiences_list_icon,
  transforming_learning_experiences_right_image,
  school_girl_image,
  Proschool_logo,
  aboutus_bg_image2,
  for_teacher_image1,
  for_teacher_image2,
  for_teacher_image3,
  for_teacher_image4,
  aboutus_bg_image3,
  aboutus_list_icon,
  aboutus_book_image,
  half_circle_bg_image,
  half_circle_bg_image2,
  half_circle_bg_image3,
  half_circle_bg_image4,
  aboutus_school_image,
  right_arrow_bg,
  instagrme_icon,
  facebook_icon,
  youtube_icon,
  linkedin_icon,
  twitter_icons
} from "../../assets/images/images";
import image8 from "../../assets/images/bulb-upp.png";
import image4 from "../../assets/images/edu-inn-1.png";
import image3 from "../../assets/images/edu-inn.png";
import image2 from "../../assets/images/edu-rtg.svg";
import image7 from "../../assets/images/line-text-orn.png";
import image6 from "../../assets/images/line-text.png";
import image5 from "../../assets/images/rota-icn.png";
import image9 from "../../assets/images/rv-1.png";
import image10 from "../../assets/images/rv-2.png";
import image11 from "../../assets/images/rv-3.png";
import image12 from "../../assets/images/rv-4.png";

// import { useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "animate.css";
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";

import ChooseyourroleData from "../../components/ChooseyourroleData";
import CustomizedLearningCard from "../../components/CustomizedLearningCard";
import { useSelector } from "react-redux";
const Home = () => {
  const navigate = useNavigate();
  const globalState = useSelector((state) => state.globalState);
  const subadmin_details = globalState.subadmin_details;
  const customizedLearningObj = [
    {
      data_wow_duration: "1.8s",
      title: "Teacher’s AI Assistants:",
      des:
        "Our platform features AI assistants to help teachers with lesson planning, grading, and educational content creation, significantly enhancing teaching efficiency.",
    },
    {
      data_wow_duration: "2s",
      title: "Interactive Learning Tools:",
      des:
        "Engage students with a range of interactive tools, from multimedia content to collaborative projects, encouraging active participation and deeper comprehension.",
    },
    {
      data_wow_duration: "2.8s",
      title: "Parental Involvement and Monitoring:",
      des:
        "Features for parental involvement allow monitoring of progress, fostering a comprehensive educational experience that bridges home and school learning.",
    },
  ];

  const chooseyourroleObj = [
    {
      data_wow_duration: "1.8s",
      image: choose_your_role_image1,
      des: "Sign Up as Student",
      onClick: () => navigate("/signup", { state: { role: "Student" } }),
    },
    {
      data_wow_duration: "2s",
      image: choose_your_role_image2,
      des: "Sign Up as Teacher",
      onClick: () => navigate("/signup", { state: { role: "Teacher" } }),
    },
    {
      data_wow_duration: "2.8s",
      image: choose_your_role_image3,
      des: "Sign Up as a Parent",
      // onClick: () => navigate("/signup", { state: { role: "Parent" } }),
    },
  ];

  useEffect(() => {
    {
      const wow = new WOW.WOW();
      wow.init();
    }
  }, []);

  const handleClick = (e) => {
    e.preventDefault(); // Prevent default link behavior

    // const navigate = useNavigate();

    // Use setTimeout to delay the scroll to the specific section
    setTimeout(() => {
      const element = document.getElementById("revolution_sec_new");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); // Adjust the timeout as needed
  };

  return (
    <>


      <Helmet>
        <title> Revolutionise Learning with ProSchool.ai - AI-Powered Education</title>
        <meta name="description" content=" Discover ProSchool.ai, where AI-driven personalization transforms education. Engage, learn, and grow with our innovative LMS." />
        <meta name="keywords" content=" AI education, personalised learning, innovative LMS, interactive learning, education technology" />
        {/* Add more meta tags as needed */}
      </Helmet>


      <HomeNavbar />

      <section className="empower_sec_new">
      <h1 style={{display : 'none'}}>Revolutionise Learning with ProSchool.ai - AI-Powered Education</h1>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="empower_sec_new_inner">
                <div className="empower_sec_new_inner_left">
                  <h3 className="wow fadeInLeftBig" data-wow-duration="1.2s">
                    Empowering Education through Innovation{" "}
                    <img className="line_text_img" src={image6} alt="" />
                  </h3>
                  <p className="wow fadeInLeftBig" data-wow-duration="1.8s">
                    AI at the Heart of Learning: ProSchool's Futuristic Approach
                  </p>
                  <div
                    className="pre_stu_sec wow fadeInLeftBig"
                    data-wow-duration="2s"
                  >
                    <div className="stu_img_pro">
                      <img src={image3} alt="" />
                    </div>
                    <p>
                      Preparing <span>students</span> not just for college, but
                      for life beyond.
                    </p>
                  </div>
                  <div
                    className="indi_cls_main wow fadeInLeftBig"
                    data-wow-duration="2s"
                  >
                    <img src={image5} alt="" />
                  </div>
                  <div
                    className="pre_stu_sec_main wow fadeInLeftBig"
                    data-wow-duration="2.3s"
                  >
                    <div className="stu_img_pro">
                      <img src={image4} alt="" />
                    </div>
                    <p>
                      <span>AI-powered</span> interactive tools create engaging
                      and Dynamic learning environments.
                    </p>
                  </div>
                </div>
                <a href="https://crm.zoho.in/bookings/ProSchoolDemo?rid=6178c3ebae0db6dde263550288f2e43dcb116db82b90fe56443a8e99d785770849f0b633869eb9b32b0d21a8650121b5gid8448aaaed34574ac158ffd90cf68d04d4dbf42fd89ec0c98e2bfd39b89e7e432" className="header-book-demo">Book a Demo</a>
                <div className="empower_sec_new_inner_right">
                  {/* <img src={image2} alt='' /> */}
                  {/* <div className='empower_sec_new_inner_right_circle_background rotate-full'>
                                        <img src={circle_background} alt='' />
                                    </div> */}
                  <div className="empower_sec_new_inner_right_circle_image_background wow animate__zoomIn">
                    <img src={school_girl_image} alt="" />
                  </div>
                </div>
              </div>
              {/* <div
                className="lets_explore_btn"
                onClick={() => navigate("/book-a-demo")}
              >
                <a>
                  Request a Demo<i className="fas fa-arrow-circle-up"></i>
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      <section className="revolution_sec_new" id="revolution_sec_new">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="revolution_sec_new_inner">
                <div className="revolution_sec_new_head">
                  <span className="img_revo">
                    <img src={image8} alt="" />
                  </span>
                  <h5 className="wow animate__zoomIn">
                    Revolutionizing learning experiences with innovative
                    features
                    <img className="line_text_img" src={image7} alt="" />
                  </h5>
                </div>
                <div className="revolution_sec_new_body_sec">
                  <div
                    className="revolution_sec_new_single wow animate__fadeInUpBig"
                    data-wow-duration="1.2s"
                  >
                    <img className="revo_img" src={image9} alt="" />
                    <h6>Personalized Learning</h6>
                    <p>
                      Tailored learning paths based on student progress and
                      preferences
                    </p>
                  </div>
                  <div
                    className="revolution_sec_new_single wow animate__fadeInUpBig"
                    data-wow-duration="1.5s"
                  >
                    <img className="revo_img" src={image10} alt="" />
                    <h6>AI-Powered Insights</h6>
                    <p>
                      Real-time analytics for teachers and parents to track
                      student performance
                    </p>
                  </div>
                  <div
                    className="revolution_sec_new_single wow animate__fadeInUpBig"
                    data-wow-duration="2s"
                  >
                    <img className="revo_img" src={image11} alt="" />
                    <h6>Interactive Discussions</h6>
                    <p>
                      Virtual classrooms and discussion boards for interactive
                      learning
                    </p>
                  </div>
                  <div
                    className="revolution_sec_new_single wow animate__fadeInUpBig"
                    data-wow-duration="2.5s"
                  >
                    <img className="revo_img" src={image12} alt="" />
                    <h6>Seamless Updates</h6>
                    <p>
                      Instant notifications and updates for parents and students
                    </p>
                  </div>
                </div>
                {/* <div className="lets_explore_btn" onClick={() => navigate("/book-a-demo")}>
                  <a>
                    Request a Demo<i className="fas fa-arrow-circle-up"></i>
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="Benefits-Beyond-Boundaries">
        <div className="revolution_sec_new_head">
          <span className="img_revo">
            <img src={image8} alt="" />
          </span>
          <h5 className="wow animate__zoomIn">
            Benefits Beyond Boundaries: Empowering Students, Educators, and
            Families
            <img className="line_text_img" src={image7} alt="" />
          </h5>
        </div>
        <section className="aboutUs-for-teacher-section">
          <div className="aboutUs-for-teacher-wrapper">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="aboutUs-logo-image-folder">
                    {/* <div className='aboutUs-aboutus-teacher-logo-image'>
                                        <img src={aboutus_teacher_logo} alt='aboutUs-aboutus-teacher-logo-image'/>
                                    </div> */}
                  </div>
                  <div className="aboutUs-for-teacher-conatiner">
                    <div className="aboutUs-for-teacher-right-section">
                      <div
                        className="aboutUs-for-teacher-right-section-image wow animate__zoomIn"
                        data-wow-duration="1.2s"
                      >
                        <img
                          src={for_teacher_image1}
                          alt="aboutUs background"
                        />
                      </div>
                    </div>
                    <div className="aboutUs-for-teacher-left-section">
                      <h2 className="aboutus_title">For Teachers</h2>
                      <div className="aboutUs-for-teacher-list-container">
                        <div
                          className="aboutUs-for-teacher-list-list-folder wow animate__fadeInRight"
                          data-wow-duration="1.2s"
                        >
                          <ReactSVG
                            className="transforming-learning-experiences-list-icon"
                            src={transforming_learning_experiences_list_icon}
                          />
                          <p>
                            Empower Your Teaching: Simplify lesson planning and
                            unleash your teaching potential with our AI tools.
                          </p>
                        </div>
                        <div
                          className="aboutUs-for-teacher-list-list-folder wow animate__fadeInRight"
                          data-wow-duration="1.5s"
                        >
                          <ReactSVG
                            className="transforming-learning-experiences-list-icon"
                            src={transforming_learning_experiences_list_icon}
                          />
                          <p>
                            Create and Earn: Instantly create and sell courses
                            globally. Turn your knowledge into income on the
                            world's most advanced online learning platform.
                          </p>
                        </div>
                        <div
                          className="aboutUs-for-teacher-list-list-folder wow animate__fadeInRight"
                          data-wow-duration="2s"
                        >
                          <ReactSVG
                            className="transforming-learning-experiences-list-icon"
                            src={transforming_learning_experiences_list_icon}
                          />
                          <p>
                            Measure Success: Leverage AI-driven analytics to
                            monitor each student's progress, enabling tailored
                            enhancements in teaching strategies for optimal
                            educational outcomes.
                          </p>
                        </div>
                        <div
                          className="aboutUs-for-teacher-list-list-folder wow animate__fadeInRight"
                          data-wow-duration="2.5s"
                        >
                          <ReactSVG
                            className="transforming-learning-experiences-list-icon"
                            src={transforming_learning_experiences_list_icon}
                          />
                          <p>
                            AI-Assisted Efficiency: Streamline your teaching
                            with AI assistants that efficiently manage tasks
                            such as KPI-aligned grading and lesson planning,
                            freeing up more time for personalised teaching.
                          </p>
                        </div>
                        <div className="aboutUs-for-teacher-book-image Benefits-Beyond-Boundaries-logo1">
                          <img
                            src={aboutus_book_image}
                            alt="aboutUs background"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="aboutUs-for-student-section">
          <div className="aboutUs-for-student-wrapper">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="aboutUs-logo-image-folder">
                    {/* <div className='aboutUs-aboutus-teacher-logo-image wow animate__zoomIn' data-wow-duration="1.2s">
                                        <img src={aboutus_teacher_logo2} alt='aboutUs-aboutus-student-logo-image'/>
                                    </div> */}
                  </div>
                  <div className="aboutUs-for-student-conatiner">
                    <div className="aboutUs-for-student-left-section">
                      <h2 className="aboutus_title">For Students</h2>
                      <div className="aboutUs-for-student-list-container">
                        <div
                          className="aboutUs-for-student-list-list-folder wow animate__fadeInLeft"
                          data-wow-duration="1.2s"
                        >
                          <ReactSVG
                            className="transforming-learning-experiences-list-icon"
                            src={transforming_learning_experiences_list_icon}
                          />
                          <p>
                            Learn Your Way: Our AI customises your learning
                            journey, making education a perfect fit for you.
                          </p>
                        </div>
                        <div
                          className="aboutUs-for-student-list-list-folder wow animate__fadeInLeft"
                          data-wow-duration="1.5s"
                        >
                          <ReactSVG
                            className="transforming-learning-experiences-list-icon"
                            src={transforming_learning_experiences_list_icon}
                          />
                          <p>
                            Engage and Excel: With interactive tools and diverse
                            content, education is no longer a chore but an
                            adventure.
                          </p>
                        </div>
                        <div
                          className="aboutUs-for-student-list-list-folder wow animate__fadeInLeft"
                          data-wow-duration="2s"
                        >
                          <ReactSVG
                            className="transforming-learning-experiences-list-icon"
                            src={transforming_learning_experiences_list_icon}
                          />
                          <p>
                            AI Study Assistant: Get 24/7 assistance from AI
                            tutors, guiding you through challenging concepts and
                            enhancing your understanding.
                          </p>
                        </div>
                        <div className="aboutUs-for-student-book-image Benefits-Beyond-Boundaries-logo2">
                          <img
                            src={aboutus_book_image}
                            alt="aboutUs background"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="aboutUs-for-teacher-right-section">
                      <div className="aboutUs-for-teacher-right-section-image">
                        <img
                          src={for_teacher_image2}
                          alt="aboutUs background"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="aboutUs-for-teacher-section">
          <div className="aboutUs-for-teacher-wrapper">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="aboutUs-logo-image-folder">
                    {/* <div className='aboutUs-aboutus-teacher-logo-image'>
                                        <img src={aboutus_teacher_logo3} alt='aboutUs-aboutus-student-logo-image'/>
                                    </div> */}
                  </div>
                  <div className="aboutUs-for-parent-conatiner">
                    <div className="aboutUs-for-teacher-right-section">
                      <div
                        className="aboutUs-for-teacher-right-section-image wow animate__zoomIn"
                        data-wow-duration="1.2s"
                      >
                        <img
                          src={for_teacher_image3}
                          alt="aboutUs background"
                        />
                      </div>
                      <span className="light_logo">
                        <img src={image8} alt="" />
                      </span>
                    </div>
                    <div className="aboutUs-for-parent-left-section">
                      <h2 className="aboutus_title">For Schools</h2>
                      <div className="aboutUs-for-parent-list-container">
                        <div
                          className="aboutUs-for-parent-list-list-folder wow animate__fadeInRight"
                          data-wow-duration="1.2s"
                        >
                          <ReactSVG
                            className="transforming-learning-experiences-list-icon"
                            src={transforming_learning_experiences_list_icon}
                          />
                          <p>
                            Global Classroom: Extend your institution's reach by
                            connecting with students across the globe.
                          </p>
                        </div>
                        <div
                          className="aboutUs-for-parent-list-list-folder wow animate__fadeInRight"
                          data-wow-duration="1.2s"
                        >
                          <ReactSVG
                            className="transforming-learning-experiences-list-icon"
                            src={transforming_learning_experiences_list_icon}
                          />
                          <p>
                            Effortless Administration: Streamline management
                            with comprehensive tools, freeing up time to focus
                            on educational excellence.
                          </p>
                        </div>
                        <div
                          className="aboutUs-for-parent-list-list-folder wow animate__fadeInRight"
                          data-wow-duration="1.2s"
                        >
                          <ReactSVG
                            className="transforming-learning-experiences-list-icon"
                            src={transforming_learning_experiences_list_icon}
                          />
                          <p>
                            Data-Driven Insights: Use AI-generated analytics to
                            monitor school-wide educational trends and optimize
                            curriculum planning.
                          </p>
                        </div>
                        <div className="aboutUs-for-parent-book-image Benefits-Beyond-Boundaries-logo3">
                          <img
                            src={aboutus_school_image}
                            alt="aboutUs background"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="aboutUs-for-student-section">
          <div className="aboutUs-for-student-wrapper">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="aboutUs-logo-image-folder">
                    {/* <div className='aboutUs-aboutus-teacher-logo-image wow animate__zoomIn' data-wow-duration="1.2s">
                                        <img src={aboutus_teacher_logo2} alt='aboutUs-aboutus-student-logo-image'/>
                                    </div> */}
                  </div>
                  <div className="aboutUs-for-student-conatiner">
                    <div className="aboutUs-for-student-left-section">
                      <h2 className="aboutus_title">For Parents</h2>
                      <div className="aboutUs-for-student-list-container">
                        <div
                          className="aboutUs-for-student-list-list-folder wow animate__fadeInLeft"
                          data-wow-duration="1.2s"
                        >
                          <ReactSVG
                            className="transforming-learning-experiences-list-icon"
                            src={transforming_learning_experiences_list_icon}
                          />
                          <p>
                            Track Progress: Monitor your child’s academic growth
                            with real-time insights.
                          </p>
                        </div>
                        <div
                          className="aboutUs-for-student-list-list-folder wow animate__fadeInLeft"
                          data-wow-duration="1.5s"
                        >
                          <ReactSVG
                            className="transforming-learning-experiences-list-icon"
                            src={transforming_learning_experiences_list_icon}
                          />
                          <p>
                            Support and Collaborate: Be an active participant in
                            your child's learning journey with our supportive
                            tools.
                          </p>
                        </div>
                        <div
                          className="aboutUs-for-student-list-list-folder wow animate__fadeInLeft"
                          data-wow-duration="2s"
                        >
                          <ReactSVG
                            className="transforming-learning-experiences-list-icon"
                            src={transforming_learning_experiences_list_icon}
                          />
                          <p>
                            Supportive AI Interaction: Engage with AI tools to
                            better understand your child’s educational needs and
                            how to support them at home.
                          </p>
                        </div>
                        <div className="aboutUs-for-student-book-image Benefits-Beyond-Boundaries-logo4">
                          <img
                            src={aboutus_book_image}
                            alt="aboutUs background"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="aboutUs-for-teacher-right-section">
                      <div className="aboutUs-for-teacher-right-section-image">
                        <img
                          src={for_teacher_image4}
                          alt="aboutUs background"
                        />
                      </div>
                      <div
                        className="aboutUs-right_arrow_bg wow animate__zoomIn"
                        data-wow-duration="1.2s"
                      >
                        <img src={right_arrow_bg} alt="aboutUs background" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="half-circle-bg-image benefits-boundaries-half-circle-bg-image1">
          <img src={half_circle_bg_image} />
        </div>
        <div className="half-circle-bg-image2 benefits-boundaries-half-circle-bg-image2">
          <img src={half_circle_bg_image2} />
        </div>
        <div className="half-circle-bg-image benefits-boundaries-half-circle-bg-image3">
          <img src={half_circle_bg_image3} />
        </div>
        <div className="half-circle-bg-image benefits-boundaries-half-circle-bg-image4">
          <img src={half_circle_bg_image4} />
        </div>
      </div>

      <section className="customize_learing_journey">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="customize_learing_journey_inner">
                <div className="customize_learing_journey_inner_head">
                  <div className="revolution_sec_new_head customize_learing_journey_inner_title">
                    <span className="customize_learing_journey_light_image">
                      <img src={image8} alt="" />
                    </span>
                    <h5>
                      Customized Learning Journeys with Interactive Environments
                      and AI Insights
                      <img
                        className="line_text_image2"
                        src={blue_line_image}
                        alt=""
                      />
                    </h5>
                  </div>
                  <div className="customize_learing_journey-folder">
                    <div className="customize_learing_journey_left_section">
                      <div className="customize_learing_journey_image wow animate__zoomIn">
                        <img src={customize_learing_journey_image} alt="" />
                      </div>
                      <ReactSVG
                        className="customize_learing_journey_arrow"
                        src={customize_learing_journey_arrow}
                      />
                    </div>
                    <div className="customize_learing_journey_right_section">
                      <div className="customize_learing_journey_right_inner_container">
                        {customizedLearningObj.map((card, index) => (
                          <CustomizedLearningCard
                            key={index}
                            title={card.title}
                            des={card.des}
                            data_wow_duration={card.data_wow_duration}
                            icon={card.customize_learing_journey_right_icon}
                          />
                        ))}
                      </div>
                    </div>
                    <ReactSVG
                      className="customize_learing_journey_half_circle"
                      src={half_circle_image}
                    />
                  </div>
                  {/* <div
                    className="lets_explore_btn"
                    onClick={() => navigate("/book-a-demo")}
                  >
                    <a>
                      Request a Demo<i className="fas fa-arrow-circle-up"></i>
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="choose-your-role-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="choose-your-role-wraper">
                <h2>Choose your role and unlock a world of learning</h2>
                <img
                  className="blue-line-image2"
                  src={blue_line_image2}
                  alt=""
                />
                <div className="choose-your-role-container">
                  {chooseyourroleObj.map((card, index) => (
                    <ChooseyourroleData
                      key={index}
                      des={card.des}
                      title={card.title}
                      image={card.image}
                      data_wow_duration={card.data_wow_duration}
                      onClick={card.onClick}
                    />
                  ))}
                </div>
                <ReactSVG
                  className="choose-your-role-bg-star"
                  src={choose_your_role_bg_star}
                />
                <ReactSVG
                  className="choose-your-role-book-image"
                  src={choose_your_role_book_image}
                />
                <ReactSVG
                  className="choose-your-role-doted3-image"
                  src={choose_your_role_doted3_image}
                />
                <ReactSVG
                  className="choose-your-role-doted4-image"
                  src={choose_your_role_doted4_image}
                />
                <ReactSVG
                  className="choose-your-role-half-circle-image"
                  src={choose_your_role_half_circle_image}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="transforming-learning-experiences-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="transforming-learning-experiences-wrapper">
                <div className="transforming-learning-experiences-left-section wow animate__fadeInLeft">
                  <h2>Transforming Learning Experiences</h2>
                  <img
                    className="orange-line-image2"
                    src={orange_line_image2}
                    alt=""
                  />
                  <div className="transforming-learning-experiences-list-container">
                    <div className="transforming-learning-experiences-list-folder wow animate__fadeInLeft">
                      <ReactSVG
                        className="transforming-learning-experiences-list-icon"
                        src={transforming_learning_experiences_list_icon}
                      />
                      <p>Redefining Education Paradigms</p>
                    </div>
                    <div className="transforming-learning-experiences-list-folder wow animate__fadeInRight">
                      <ReactSVG
                        className="transforming-learning-experiences-list-icon"
                        src={transforming_learning_experiences_list_icon}
                      />
                      <p>Remote Learning and Resilience</p>
                    </div>
                    <div className="transforming-learning-experiences-list-folder wow animate__fadeInLeft">
                      <ReactSVG
                        className="transforming-learning-experiences-list-icon"
                        src={transforming_learning_experiences_list_icon}
                      />
                      <p>Digital Literacy and Future-Ready Skills</p>
                    </div>
                    <div className="transforming-learning-experiences-list-folder wow animate__fadeInRight">
                      <ReactSVG
                        className="transforming-learning-experiences-list-icon"
                        src={transforming_learning_experiences_list_icon}
                      />
                      <p>Gamified Learning</p>
                    </div>
                  </div>
                  {/* <div className="lets_explore_btn" onClick={() => navigate("/book-a-demo")}>
                    <a>
                      Request a Demo<i className="fas fa-arrow-circle-up"></i>
                    </a>
                  </div> */}
                </div>
                <div className="transforming-learning-experiences-right-section wow animate__zoomIn">
                  <div className="transforming-learning-experiences-right-image">
                    <img
                      src={transforming_learning_experiences_right_image}
                      alt=""
                    />
                  </div>
                  <ReactSVG
                    className="transforming-learning-experiences-circle-bg-icon"
                    src={transforming_learning_experiences_circle_bg_icon}
                  />
                  <ReactSVG
                    className="transforming-learning-experiences-line-image"
                    src={transforming_learning_experiences_line_image}
                  />
                  <ReactSVG
                    className="transforming-learning-experiences-doted-image"
                    src={transforming_learning_experiences_doted_image}
                  />
                  <ReactSVG
                    className="transforming-learning-experiences-doted2-image"
                    src={transforming_learning_experiences_doted2_image}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="footer-section" id="footer-section">
        <div className="footer-wraper">
          <div className="footer-link-folder">
            <div className="proschool-logo">
              <img src={subadmin_details ? subadmin_details?.school_image : Proschool_logo} />
            </div>
            <p>We are more than just an LMS; we are a catalyst for comprehensive educational transformation. ProSchool.ai brings a multidimensional approach to education, 
              contrasting with traditional methods.
            </p>
            {/* <div className="footer-Join-our-newsletter-folder">
              <p>Join our newsletter</p>
              <div className="footer-Join-our-newsletter-search-folder">
                <input type="email" placeholder="Your email"></input>
                <button>Subscribe</button>
              </div>
            </div> */}
          </div>
          <div className="footer-Quick-Links-folder">
            <h2>Quick Links</h2>
            <ul className="footer-Quick-Links">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>
                <a href="revolution_sec_new" onClick={handleClick}>
                  Features
                </a>
              </li>
              <li>
                <NavLink to="/about">About Us</NavLink>
              </li>
              <li>
                <NavLink to="/terms-and-conditions">Terms & Conditions</NavLink>
              </li>
              <li>
                <NavLink to="/privacy-policy">Privacy Policy</NavLink>
              </li>
            </ul>
          </div>
          <div className="footer-Quick-Links-folder">
            <h2>Contact</h2>
            <div className="footer-contact">
              {/* <div className="footer-contact-folder">
                <a>
                  <i class="fa-solid fa-phone"></i>
                </a>
                <span>
                <a href="tel:+1 (646) 222-3901">{subadmin_details ? subadmin_details?.phone : '+1 (646) 222-3901'}</a>
                </span>
              </div> */}
              <div className="footer-contact-folder">
                <a>
                  <i class="fa-solid fa-envelope"></i>
                </a>
                {subadmin_details ? (
                  <a href={`mailto:${subadmin_details?.email}`}>{subadmin_details.email}</a>
                ) : (
                  <a href="mailto: info@proschool.ai">info@proschool.ai</a>
                )}


              </div>
              {/* <div className="footer-contact-folder">
                <a>
                  <i class="fa-solid fa-location-dot"></i>
                </a>
                {subadmin_details ? (
                  subadmin_details?.location
                ) : (
                  <a
                    href="https://www.google.com/maps/place/Arcitech+-+AI+Software,+Web,+App+Development/@19.0020445,72.8229651,16z/data=!4m10!1m2!2m1!1sA-802,+2nd+floor,+Trade+Link,+Kamala+Mills,+Lower+Parel,+Mumbai,+Maharashtra+400013!3m6!1s0x3be7ce9336955555:0x743645ef3c0b1a05!8m2!3d19.0033511!4d72.8274615!15sClNBLTgwMiwgMm5kIGZsb29yLCBUcmFkZSBMaW5rLCBLYW1hbGEgTWlsbHMsIExvd2VyIFBhcmVsLCBNdW1iYWksIE1haGFyYXNodHJhIDQwMDAxM1pPIk1hIDgwMiAybmQgZmxvb3IgdHJhZGUgbGluayBrYW1hbGEgbWlsbHMgbG93ZXIgcGFyZWwgbXVtYmFpIG1haGFyYXNodHJhIDQwMDAxM5IBEHNvZnR3YXJlX2NvbXBhbnmaASNDaFpEU1VoTk1HOW5TMFZKUTBGblNVTkxiMHBEVm1ObkVBReABAA!16s%2Fg%2F11lt56gqrs?entry=ttu"
                    target="_blank"
                  >
                    A-802, 2nd floor, Trade Link, Kamala Mills, Lower Parel,
                    Mumbai, Maharashtra 400013
                  </a>
                )}
              </div> */}
            </div>
          </div>
          <div className="footer-get-in-touch-folder">
            <span>Get in touch</span>
            <div className="footer-social-link-folder">
              <a
                href="https://www.facebook.com/proschoolai/"
                target="_blank"
              >
                {/* <i className="fa-brands fa-facebook"></i> */}
                <img src={facebook_icon}/>
              </a>
              <a 
              href="https://twitter.com/Proschoolai" 
              target="_blank"
              >
                {/* <i class="fa-brands fa-x-twitter"></i> */}
                <img src={twitter_icons}/>
              </a>
              <a
                href="https://www.instagram.com/proschool.ai/"
                target="_blank"
              >
                {/* <i className="fa-brands fa-instagram"></i> */}
                <img src={instagrme_icon}/>
              </a>
              <a
                href="https://www.linkedin.com/company/proschool-ai"
                target="_blank"
              >
                {/* <i class="fa-brands fa-linkedin"></i> */}
                <img src={linkedin_icon}/>
              </a>
              <a 
              href="https://www.youtube.com/@ProSchoolAI" 
              target="_blank"
              >
                {/* <i class="fa-brands fa-youtube"></i> */}
                <img src={youtube_icon}/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;