import React, { useState } from "react";
import { MuiOtpInput } from "mui-one-time-password-input";
import { FormHelperText, Modal, styled } from "@mui/material";
import imgs from "../assets/images/imgs";
import api from "../api/api";
import {
    isRequired,
    isSamePassword,
    isValidEmail,
    isValidPassword,
} from "../utils/validator";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
const MuiOtpInputStyled = styled(MuiOtpInput)`
  display: flex;
  gap: 30px;
  max-width: 650px;
  margin-inline: auto;
`;
export default function ForgetPassword(props) {
    const [ResetPassFormStep, setResetPassFormStep] = useState(1);
    const [isLoadingResetPass, setisLoadingResetPass] = useState(false);
    const [formDataResetPass, setformDataResetPass] = useState({
        email: "",
        code: "",
        new_password: "",
        confirm_password: "",
    });
    const [formErrorsResetPass, setformErrorsResetPass] = useState({
        email: "",
        code: "",
        new_password: "",
        confirm_password: "",
    });

    const handleChangeResetPass = (e) => {
        const { name, value } = e.target;
        let error = "";
        if (name === "email") {
            error = isRequired(value, "email") || isValidEmail(value);
        } else if (name === "new_password") {
            error = isRequired(value, "password", true) || isValidPassword(value, true);
        } else if (name === "confirm_password") {
            error =
                isRequired(value, "password", true) ||
                isValidPassword(value, true) ||
                isSamePassword(formDataResetPass.new_password, value, true);
        }

        setformErrorsResetPass({
            ...formErrorsResetPass,
            [name]: error,
        });

        setformDataResetPass({
            ...formDataResetPass,
            [name]: value,
        });
    };
    const handleOtpChange = (value) => {
        let error = "";

        error = isRequired(value, "code");

        setformErrorsResetPass({
            ...formErrorsResetPass,
            code: error,
        });

        setformDataResetPass({
            ...formDataResetPass,
            code: value,
        });
    };
    const { isModalOpen, onClose } = props;

    // const { token } = useAuth();
    const onSendEmailResetPass = async (email) => {
        return await api
            .post("auth/reset-password/", {
                email,
            })
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err?.response;
            });
    };
    const onValidateOtpResetPass = async (email, otp) => {
        return await api
            .post("auth/reset-password/", {
                email,
                otp,
            })
            .then((res) => {

                return res;
            })
            .catch((err) => {

                return err?.response;
            });
    };
    const onResetPasswordResetPass = async (email, otp, new_password, confirm_password) => {
        return await api
            .post(
                "auth/reset-password/",
                {
                    email,
                    otp,
                    new_password,
                    confirm_password
                }
            )
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err?.response;
            });
    };
    const handleEmailSubmit = (e) => {
        e.preventDefault();
        setisLoadingResetPass(true);
        toast.dismiss();
        let err =
            isRequired(formDataResetPass.email, "email") ||
            isValidEmail(formDataResetPass.email);
        if (err) {
            setformErrorsResetPass({
                ...formErrorsResetPass,
                email: err,
            });
            console.log("err", err);
            setisLoadingResetPass(false);
            return;
        }
        onSendEmailResetPass(formDataResetPass.email).then((res) => {

            if (res.status === 200) {
                toast.success("Verification code sent successfully");
                setResetPassFormStep(2);
            } else {

                toast.error(res?.data?.message ?? "Failed to send verification code");
            }
        });
        setisLoadingResetPass(false);
    };
    const [token, setToken] = useState(null);
    const handleCodeSubmit = (e) => {
        e.preventDefault();
        toast.dismiss();
        setisLoadingResetPass(true);
        let err = isRequired(formDataResetPass.code, "code");
        if (err) {
            setformErrorsResetPass({
                ...formErrorsResetPass,
                code: err,
            });
            console.log("err", err);
            setisLoadingResetPass(false);
            return;
        }
        onValidateOtpResetPass(
            formDataResetPass.email,
            formDataResetPass.code
        ).then((res) => {
            if (res.status === 200) {

            } else {
                const err = res?.data?.message ?? "Failed to validate code";
                if (err === 'Both new password and confirm password must be provided.') {
                    toast.success("Code validated successfully");
                    setResetPassFormStep(3);
                } else {
                    toast.error(err === "Invalid OTP." ? "Invalid verification code" : err);
                }
            }
        });
        // then((res) => {
        //     console.warn(res);

        //     if (res.status === 200) {
        //         toast.success("Code validated successfully");
        //         setToken(res?.data?.token);
        //         setResetPassFormStep(3);
        //     } else {
        //         const err = res?.data?.message ?? "Failed to validate code";
        //         toast.error(err === "Invalid OTP." ? "Invalid verification code" : err);
        //     }
        // });
        setisLoadingResetPass(false);
    };
    const handleResetSubmitResetPass = (e) => {
        e.preventDefault();
        toast.dismiss();
        setisLoadingResetPass(true);
        let errors = {};

        errors.new_password =
            isRequired(formDataResetPass.new_password, "new_password", true) ||
            isValidPassword(formDataResetPass.new_password);
        errors.confirm_password =
            isRequired(formDataResetPass.confirm_password, "confirm_password", true) ||
            isValidPassword(formDataResetPass.confirm_password, true) ||
            isSamePassword(
                formDataResetPass.new_password,
                formDataResetPass.confirm_password, true
            );

        setformErrorsResetPass(errors);

        if (!Object.values(errors).some((error) => error)) {
            onResetPasswordResetPass(
                formDataResetPass.email,
                formDataResetPass.code,
                formDataResetPass.new_password,
                formDataResetPass.confirm_password
            ).then((res) => {

                if (res.status === 200) {
                    clearAllResetPass();

                    toast.success(res.data.message);

                } else {

                    toast.error(res?.data?.message ?? "Failed to reset password");
                }
            });
        }
        setisLoadingResetPass(false);
    };

    const clearAllResetPass = () => {

        onClose();
        //delay 1 second
        setTimeout(() => {
            setformDataResetPass({
                email: "",
                code: "",
            });
            setResetPassFormStep(1);
        }, 500);
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const [isNewPassword, setIsNewPassword] = useState(false);
    const [isConfirmPassword, setIsConfirmPassword] = useState(false);
    const customModalStyle = {
        modal: {
            background: "#F5F9FF",
            width: "560px",
            borderRadius: "10px",
        },
    };

    return (
        <Modal
            open={isModalOpen}
            onClose={clearAllResetPass}
            center
            styles={customModalStyle}
        >
            <div className="forget_modal_sec_new">
                <Link onClick={clearAllResetPass} className="chen_back_left">
                    <i className="fas fa-chevron-left"></i>Back
                </Link>
                <h6>Reset Password?</h6>
                <img src={imgs.png.frgt_img} alt=" " />
                <div className="forget_modal_sec_new_body_sec">
                    {ResetPassFormStep === 1 && (
                        <div className="email_add">
                            <label>Enter Email Address</label>
                            <input
                                type="text"
                                name="email"
                                value={formDataResetPass.email}
                                onChange={handleChangeResetPass}
                                style={
                                    formErrorsResetPass.email
                                        ? { border: "1px solid red" }
                                        : null
                                }
                                placeholder="Email address"
                            />
                            {formErrorsResetPass.email && (
                                <FormHelperText error>
                                    {formErrorsResetPass.email}
                                </FormHelperText>
                            )}
                            <div className="email_add_btn">
                                <button
                                    type="button"
                                    onClick={handleEmailSubmit}
                                    disabled={isLoadingResetPass}
                                >
                                    Send Verification code
                                </button>
                            </div>
                        </div>
                    )}

                    {ResetPassFormStep === 2 && (
                        <div className="verification_code">
                            <label>Enter verification code</label>
                            <MuiOtpInputStyled
                                label="6-Digit Code"
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                length={6}
                                name="code"
                                TextFieldsProps={{ size: "small" }}
                                error={!!formErrorsResetPass.code}
                                value={formDataResetPass.code}
                                onChange={handleOtpChange}
                            />
                            {formErrorsResetPass.code && (
                                <FormHelperText error>
                                    {formErrorsResetPass.code}
                                </FormHelperText>
                            )}

                            <div className="email_add_btn">
                                <button type="button" onClick={handleCodeSubmit}>
                                    Confirm code
                                </button>
                            </div>
                        </div>
                    )}

                    {ResetPassFormStep === 3 && (
                        <div className="set_passcode_sec">
                            <div className="set_passcode_sec_single">
                                <label>Set New Password</label>
                                <input
                                    type="text"
                                    name="new_password"
                                    value={formDataResetPass.new_password}
                                    onChange={handleChangeResetPass}
                                    style={
                                        formErrorsResetPass.new_password
                                            ? { border: "1px solid red" }
                                            : null
                                    }
                                    placeholder="Enter new password"
                                />
                            </div>
                            {formErrorsResetPass.new_password && (
                                <FormHelperText error>
                                    {/* {formErrorsResetPass.new_password} */}
                                    <ul>
                                        {formErrorsResetPass.new_password.split(".").map((error) => (
                                            <li key={error}>{error}</li>
                                        ))}
                                    </ul>
                                </FormHelperText>
                            )}
                            <div className="set_passcode_sec_single">
                                <label>Confirm New Password</label>
                                <input
                                    type="text"
                                    placeholder="Enter new password"
                                    name="confirm_password"
                                    onChange={handleChangeResetPass}
                                    value={formDataResetPass.confirm_password}
                                    style={
                                        formErrorsResetPass.confirm_password
                                            ? { border: "1px solid red" }
                                            : null
                                    }
                                />
                            </div>
                            {formErrorsResetPass.confirm_password && (
                                <FormHelperText error>
                                    {/* {formErrorsResetPass.confirm_password} */}
                                    <ul>
                                        {formErrorsResetPass.new_password.split(".").map((error) => (
                                            <li key={error}>{error}</li>
                                        ))}
                                    </ul>
                                </FormHelperText>
                            )}
                            <div className="email_add_btn">
                                <button
                                    type="button"
                                    onClick={handleResetSubmitResetPass}
                                    disabled={isLoadingResetPass}
                                >
                                    Continue
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
}
