// import React, { useState, useEffect, useRef } from "react";
// import { visa_image, credit_card, visa_image2, visa_image3, visa_image4, check_image } from "../../assets/images/images";
// import { loadStripe } from '@stripe/stripe-js';
// import { removeCartData, resetRemoveCart } from '../../state/actions/removeCartAction';
// import { Elements, CardNumberElement, CardExpiryElement, CardCvcElement, cardholderName, useStripe, useElements } from '@stripe/react-stripe-js';
// import BackButton from '../BackButton/BackButton';
// import { useDispatch, useSelector } from "react-redux";
// import { useAuth } from '../../features/auth/AuthContext';
// import api from '../../api/api';
// import { useNavigate } from "react-router-dom";
// import { toast } from 'react-toastify';
// import {
//     cartList,
//     resetCartList
// } from "../../state/actions/cartlistAction";
// import "./SubscriptionPlanPayment.css";

// const stripePromise = loadStripe(process.env.REACT_APP_PUBLISH_KEY); // Replace with your Stripe publishable key

// function CheckoutForm() {
//     const stripe = useStripe();
//     const elements = useElements();
//     const navigate = useNavigate();
//     const [error, setError] = useState(null);
//     const [paymentStatus, setPaymentStatus] = useState('');
//     const [paymentSuccessPopup, setpaymentSuccessPopup] = useState(false);
//     const [cardholderName, setCardholderName] = useState(''); // State to hold the cardholder's name
//     const [isCardNumberValid, setCardNumberValid] = useState(false);
//     const [isCardExpiryValid, setCardExpiryValid] = useState(false);
//     const [isCardCvcValid, setCardCvcValid] = useState(false);
//     const [openDialog, setOpenDialog] = useState(false);
//     const [item_id, setitem_id] = useState();
//     const formRef = useRef(null); // Ref for the form
//     const { token } = useAuth();
//     const dispatch = useDispatch();
//     const removeCart = useSelector((state) => state.removeCartData);


//     const proceedToPay = useSelector((state) => state.proceedToPay);


//     const paymenPricedata = useSelector((state) => state.cartlist);
//     // console.log(paymenPricedata);
//     // const dispatch = useDispatch();

//     // Calculate total price
//     const calculateTotalPrice = () => {
//         let totalPrice = 0;
//         paymenPricedata.cartList.forEach(item => {
//             totalPrice += item.price;
//         });
//         return totalPrice.toFixed(2); // Format to 2 decimal places
//     }

//     // Function to dynamically adjust CARD_ELEMENT_OPTIONS based on screen width
//     const getCardElementOptions = () => {
//         let fontSize = '16px';
//         if (window.innerWidth <= 768) {
//             fontSize = '12px';
//         }

//         // Return the CARD_ELEMENT_OPTIONS with the adjusted fontSize
//         return {
//             style: {
//                 base: {
//                     fontSize: fontSize,
//                     color: '#424770', // Text color
//                     '::placeholder': {
//                         color: '#808080', // Placeholder text color
//                     },
//                 },
//                 invalid: {
//                     color: '#9e2146', // Color for invalid input
//                 },
//             },
//         };
//     };

//     // Use this function to get the dynamically adjusted options
//     const CARD_ELEMENT_OPTIONS = getCardElementOptions();


//     const [fontSize, setFontSize] = useState('14px'); // Default to mobile view

//     useEffect(() => {
//         const updateFontSize = () => {
//             const screenWidth = window.innerWidth;
//             if (screenWidth > 1024) {
//                 setFontSize('16px'); // Desktop
//             } else if (screenWidth > 768) {
//                 setFontSize('15px'); // Tablet
//             } else {
//                 setFontSize('14px'); // Mobile
//             }
//         };

//         window.addEventListener('resize', updateFontSize);
//         updateFontSize(); // Initialize font size

//         return () => window.removeEventListener('resize', updateFontSize);
//     }, []);

//     const handleSubmit = async () => {
//         //event.preventDefault();

//         if (!stripe || !elements) {
//             setError('Stripe has not loaded yet.');
//             return;
//         }

//         try {
//             const cardElement = elements.getElement(CardNumberElement);
//             const payload = {
//                 order_id: proceedToPay.order_id
//             };
//             const config = {
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Authorization': `Token ${token}`
//                 }
//             };
//             const paymentIntentResponse = await api.post('payments/payment/', payload, config);
//             const clientSecret = paymentIntentResponse.data.client_secret

//             const result = await stripe.confirmCardPayment(clientSecret, {
//                 payment_method: {
//                     card: cardElement,
//                     billing_details: {
//                         name: cardholderName,
//                     },
//                 },
//             });

//             console.log("OnConfirm")

//             if (result.error) {
//                 setError(result.error.message);
//             } else {
//                 if (result.paymentIntent.status === 'succeeded') {
//                     setPaymentStatus('Payment succeeded!');
//                     setpaymentSuccessPopup(true);
//                 }
//             }
//         } catch (err) {
//             setError('An error occurred.');
//             console.error(err);
//         }
//     };


//     useEffect(() => {

//         if (proceedToPay.order_id != null) {
//             handleSubmit();
//         }

//     }, [proceedToPay.order_id])

//     useEffect(() => {
//         if (paymentSuccessPopup) {
//             setTimeout(() => {
//                 dispatch(cartList(token));
//                 setpaymentSuccessPopup(false)
//                 navigate(`/`);
//             }, 10000)
//         }
//     }, [paymentSuccessPopup])

//     useEffect(() => {
//         if (removeCart?.message?.length) {
//             toast.success(removeCart?.message);
//         }
//         if (removeCart?.error?.length) {
//             toast.error(removeCart?.error)
//         }
//         return () => {
//             dispatch(resetRemoveCart());
//         }
//     }, [removeCart]);

//     // Trigger form submission from the "Proceed to Pay" button
//     const handleClick = () => {
//         if (formRef.current) {
//             formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
//         }
//     };

//     const handleChange = (event) => {
//         // Update the validity state based on the element's type
//         switch (event.elementType) {
//             case 'cardNumber':
//                 setCardNumberValid(event.complete);
//                 break;
//             case 'cardExpiry':
//                 setCardExpiryValid(event.complete);
//                 break;
//             case 'cardCvc':
//                 setCardCvcValid(event.complete);
//                 break;
//             default:
//                 break;
//         }
//     };

//     const isFormValid = () => {
//         return isCardNumberValid && isCardExpiryValid && isCardCvcValid && cardholderName.trim() !== '' && paymenPricedata?.cartList.length > 0;
//     };

//     const show = () => {
//         setOpenDialog(false)
//         const req = {
//             cart_item_id: item_id
//         };
//         dispatch(removeCartData(req, token));
//     };

//     const close = (id) => {
//         setitem_id(id)
//         setOpenDialog(!openDialog); // This will close the popup
//     };


//     return (
//         <>
//             <div className='payment-des-container'>
//                 <p>Safe money transfer using  your bank account.
//                     Visa, Mastercard, Discover, and American express.
//                 </p>
//                 <div className='payment-visa-image-folder'>
//                     <div className='payment-visa-image'>
//                         <img src={visa_image} />
//                     </div>
//                     <div className='payment-visa-image'>
//                         <img src={visa_image2} />
//                     </div>
//                     <div className='payment-visa-image'>
//                         <img src={visa_image3} />
//                     </div>
//                     <div className='payment-visa-image'>
//                         <img src={visa_image4} />
//                     </div>
//                 </div>
//             </div>
//             <form className='payment-form'>
//                 <div className="payment-form-input">
//                     <label htmlFor="cardNumber">Card Number</label>
//                     <CardNumberElement id="cardNumber" options={CARD_ELEMENT_OPTIONS} onChange={handleChange} />
//                     <img className="credit_card" src={credit_card} />
//                 </div>
//                 <div className='payment-form-Card-inner-wrapper'>
//                     <div className="payment-form-input">
//                         <label htmlFor="cardExpiry">Card Expiry</label>
//                         <CardExpiryElement id="cardExpiry" options={CARD_ELEMENT_OPTIONS} onChange={handleChange} />
//                         <img className="credit_card" src={credit_card} />
//                     </div>
//                     <div className="payment-form-input">
//                         <label htmlFor="cardCvc">CVC</label>
//                         <CardCvcElement id="cardCvc" options={CARD_ELEMENT_OPTIONS} onChange={handleChange} />
//                         <img className="credit_card" src={credit_card} />
//                     </div>
//                 </div>
//                 <div className="payment-form-input">
//                     <label for="cardholder-name">Name on Card</label>
//                     <input
//                         id="cardholder-name"
//                         type="text"
//                         placeholder="Enter your name"
//                         required
//                         value={cardholderName}
//                         onChange={(e) => setCardholderName(e.target.value)}
//                     />
//                     <img className="credit_card" src={credit_card} />
//                 </div>
//             </form>
//         </>
//     );
// }

// function SubscriptionPlanPaymentCard() {
//     return (
//         <Elements stripe={stripePromise}>
//             <CheckoutForm />
//         </Elements>
//     );
// }


// export default SubscriptionPlanPaymentCard;



import React, { useState, useEffect, useRef } from "react";
import { visa_image, credit_card, visa_image2, visa_image3, visa_image4, check_image, select_payment_method_card_image } from "../../assets/images/images";
import { loadStripe } from '@stripe/stripe-js';
import { removeCartData, resetRemoveCart } from '../../state/actions/removeCartAction';
import { Elements, CardNumberElement, CardExpiryElement, CardCvcElement, cardholderName, useStripe, useElements } from '@stripe/react-stripe-js';
import BackButton from '../BackButton/BackButton';
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from '../../features/auth/AuthContext';
import api from '../../api/api';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import {
    cartList,
    resetCartList
} from "../../state/actions/cartlistAction";
import "./SubscriptionPlanPayment.css";

// const stripePromise = loadStripe(process.env.REACT_APP_PUBLISH_KEY); // Replace with your Stripe publishable key

function CheckoutForm() {
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [paymentStatus, setPaymentStatus] = useState('');
    const [paymentSuccessPopup, setpaymentSuccessPopup] = useState(false);
    const [cardholderName, setCardholderName] = useState(''); // State to hold the cardholder's name
    const [isCardNumberValid, setCardNumberValid] = useState(false);
    const [isCardExpiryValid, setCardExpiryValid] = useState(false);
    const [isCardCvcValid, setCardCvcValid] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [item_id, setitem_id] = useState();
    const formRef = useRef(null); // Ref for the form
    const { token } = useAuth();
    const dispatch = useDispatch();
    const removeCart = useSelector((state) => state.removeCartData);


    const proceedToPay = useSelector((state) => state.proceedToPay);


    const paymenPricedata = useSelector((state) => state.cartlist);
    // console.log(paymenPricedata);
    // const dispatch = useDispatch();

    // Calculate total price
    const calculateTotalPrice = () => {
        let totalPrice = 0;
        paymenPricedata.cartList.forEach(item => {
            totalPrice += item.price;
        });
        return totalPrice.toFixed(2); // Format to 2 decimal places
    }

    // Function to dynamically adjust CARD_ELEMENT_OPTIONS based on screen width
    const getCardElementOptions = () => {
        let fontSize = '16px';
        if (window.innerWidth <= 768) {
            fontSize = '12px';
        }

        // Return the CARD_ELEMENT_OPTIONS with the adjusted fontSize
        return {
            style: {
                base: {
                    fontSize: fontSize,
                    color: '#424770', // Text color
                    '::placeholder': {
                        color: '#808080', // Placeholder text color
                    },
                },
                invalid: {
                    color: '#9e2146', // Color for invalid input
                },
            },
        };
    };

    // Use this function to get the dynamically adjusted options
    const CARD_ELEMENT_OPTIONS = getCardElementOptions();


    const [fontSize, setFontSize] = useState('14px'); // Default to mobile view

    useEffect(() => {
        const updateFontSize = () => {
            const screenWidth = window.innerWidth;
            if (screenWidth > 1024) {
                setFontSize('16px'); // Desktop
            } else if (screenWidth > 768) {
                setFontSize('15px'); // Tablet
            } else {
                setFontSize('14px'); // Mobile
            }
        };

        window.addEventListener('resize', updateFontSize);
        updateFontSize(); // Initialize font size

        return () => window.removeEventListener('resize', updateFontSize);
    }, []);

    const handleSubmit = async () => {
        //event.preventDefault();

        if (!stripe || !elements) {
            setError('Stripe has not loaded yet.');
            return;
        }

        try {
            const cardElement = elements.getElement(CardNumberElement);
            const payload = {
                order_id: proceedToPay.order_id
            };
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                }
            };
            const paymentIntentResponse = await api.post('payments/payment/', payload, config);
            const clientSecret = paymentIntentResponse.data.client_secret

            const result = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: cardElement,
                    billing_details: {
                        name: cardholderName,
                    },
                },
            });

            console.log("OnConfirm")

            if (result.error) {
                setError(result.error.message);
            } else {
                if (result.paymentIntent.status === 'succeeded') {
                    setPaymentStatus('Payment succeeded!');
                    setpaymentSuccessPopup(true);
                }
            }
        } catch (err) {
            setError('An error occurred.');
            console.error(err);
        }
    };


    useEffect(() => {

        if (proceedToPay.order_id != null) {
            handleSubmit();
        }

    }, [proceedToPay.order_id])

    useEffect(() => {
        if (paymentSuccessPopup) {
            setTimeout(() => {
                dispatch(cartList(token));
                setpaymentSuccessPopup(false)
                navigate(`/`);
            }, 10000)
        }
    }, [paymentSuccessPopup])

    useEffect(() => {
        if (removeCart?.message?.length) {
            toast.success(removeCart?.message);
        }
        if (removeCart?.error?.length) {
            toast.error(removeCart?.error)
        }
        return () => {
            dispatch(resetRemoveCart());
        }
    }, [removeCart]);

    // Trigger form submission from the "Proceed to Pay" button
    const handleClick = () => {
        if (formRef.current) {
            formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
        }
    };

    const handleChange = (event) => {
        // Update the validity state based on the element's type
        switch (event.elementType) {
            case 'cardNumber':
                setCardNumberValid(event.complete);
                break;
            case 'cardExpiry':
                setCardExpiryValid(event.complete);
                break;
            case 'cardCvc':
                setCardCvcValid(event.complete);
                break;
            default:
                break;
        }
    };

    const isFormValid = () => {
        return isCardNumberValid && isCardExpiryValid && isCardCvcValid && cardholderName.trim() !== '' && paymenPricedata?.cartList.length > 0;
    };

    const show = () => {
        setOpenDialog(false)
        const req = {
            cart_item_id: item_id
        };
        dispatch(removeCartData(req, token));
    };

    const close = (id) => {
        setitem_id(id)
        setOpenDialog(!openDialog); // This will close the popup
    };


    return (
        <>
            <div className='payment-des-container'>
                <p>Safe money transfer using  your bank account.
                    Visa, Mastercard, Discover, and American express.
                </p>
                <div className='payment-visa-image-folder'>
                    <div className='payment-visa-image'>
                        <img src={visa_image} />
                    </div>
                    <div className='payment-visa-image'>
                        <img src={visa_image2} />
                    </div>
                    <div className='payment-visa-image'>
                        <img src={visa_image3} />
                    </div>
                    <div className='payment-visa-image'>
                        <img src={visa_image4} />
                    </div>
                </div>
            </div>
            <div className="select-payment-method-container">
                <h2>Select Payment Method</h2>
                <div className="select-payment-method-tab-folder">
                    <div className="select-payment-method-tab">
                        <div className="select-payment-method-card-image">
                            <img src={select_payment_method_card_image}/>
                        </div>
                        <p>Debit / Credit Card</p>
                    </div>
                    {/* <div className="select-payment-method-tab">
                        <div className="select-payment-method-card-image">
                            <img src={select_payment_method_card_image}/>
                        </div>
                        <p>Bank Transfer</p>
                    </div> */}
                </div>
            </div>
            <form className='payment-form'>
                <div className="payment-form-input">
                    <label for="cardholder-name">Card Holder Name</label>
                    <input
                        id="cardholder-name"
                        type="text"
                        placeholder="Enter your name"
                        required
                        value={cardholderName}
                        onChange={(e) => setCardholderName(e.target.value)}
                    />
                    <img className="credit_card" src={credit_card} />
                </div>
                <div className="payment-form-input">
                    <label htmlFor="cardNumber">Card Number</label>
                    <input type="text" placeholder="Enter card number" id="cardNumber" options={CARD_ELEMENT_OPTIONS} onChange={handleChange} />
                    {/* <CardNumberElement id="cardNumber" options={CARD_ELEMENT_OPTIONS} onChange={handleChange} /> */}
                    <img className="credit_card" src={credit_card} />
                </div>
                <div className='payment-form-Card-inner-wrapper'>
                    <div className="payment-form-input">
                        <label htmlFor="cardExpiry">Card Expiry</label>
                        <input type="text" placeholder="Enter card expiry" id="cardExpiry" options={CARD_ELEMENT_OPTIONS} onChange={handleChange} />
                        {/* <CardExpiryElement id="cardExpiry" options={CARD_ELEMENT_OPTIONS} onChange={handleChange} /> */}
                        <img className="credit_card" src={credit_card} />
                    </div>
                    <div className="payment-form-input">
                        <label htmlFor="cardCvc">CVC</label>
                        <input type="text" placeholder="Enter CVV" id="cardCvc" options={CARD_ELEMENT_OPTIONS} onChange={handleChange} />
                        {/* <CardCvcElement id="cardCvc" options={CARD_ELEMENT_OPTIONS} onChange={handleChange} /> */}
                        <img className="credit_card" src={credit_card} />
                    </div>
                </div>
            </form>
        </>
    );
}

function SubscriptionPlanPaymentCard() {

    const globalState = useSelector((state) => state.globalState);


    const stripePromise = loadStripe(globalState.is_test ? process.env.REACT_APP_TEST_PUBLISH_KEY : process.env.REACT_APP_PUBLISH_KEY); // Replace with your Stripe publishable key
  

    
    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm />
        </Elements>
    );
}


export default SubscriptionPlanPaymentCard;

