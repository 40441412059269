import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { useAuth } from "../features/auth/AuthContext";
import { useSelector } from "react-redux";
import Dashboardheader from "../pages/home/components/dashboard/Dashboardheader";
import { useCourseContext } from '../context/CourseContext';
import Pagination from "@mui/material/Pagination";
import { CircularProgress, Grid } from "@mui/material";
import Helper from "../utils/Helper";
import {
  massege,
  search_icon,
  student_grade_flage,
  srot
} from "../assets/images/images";
import AOS from 'aos';
import 'aos/dist/aos.css';
import api from "../api/api";
import SortPopup from "../pages/customSort/SortPopup";
import FilterPopup from "../pages/customFilter/FilterPopup";
import BackButton from "./BackButton/BackButton";
import LoadingComponent from "./LoadingComponent/LoadingComponent";



const LessonViewProgress = () => {

  const { loading, error, errormsg, filter_data, filters, updateLessionFilter } = useCourseContext();

  const navigate = useNavigate();
  const location = useLocation();
  const [search, setSearch] = useState("");
  const [TempglobalState, setTempglobalState] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const { token } = useAuth();
  // const navigate = useNavigate();


  const fillterstatus = [
    // { id: "Not-Started ", name: "Not Started" },
    { id: "Active", name: "Active" },
    { id: "Completed", name: "Completed" },
  ];
  const fillterScore = [];
  const itemsPerPage = 12;

  const [currentPage, setCurrentPage] = useState(1);
  const inial_value = (currentPage - 1) * itemsPerPage;
  const [isVisible, setIsVisible] = useState(false);
  const [isVisiblefillter, setIsVisiblefillter] = useState(false);
  const [assignmentStatus, setAssignmentStatus] = useState("");
  const [score, setScore] = useState("");
  const [student_assignment_details, setStudent_assignment_details] = useState(
    []
  );
  const [sortOption, setSortOption] = useState("A-Z");

  const [showdownlodefile, setShowdownlodefile] = useState(false); // State to control visibility
  const modalRef = useRef();
  const modalContentRef = useRef(); // Reference to the modal content

  // Function to open the modal
  const openModal = () => {
    setShowdownlodefile(true);
  };

  const toggledownlodefile = () => {
    // setShowdownlodefile(!showdownlodefile); // Toggle the visibility state
    setShowdownlodefile(false);
  };

  // Event listener to close modal if clicked outside the modal content
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalContentRef.current && !modalContentRef.current.contains(event.target)) {
        toggledownlodefile();
      }
    };

    // Attach the event listener
    if (showdownlodefile) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    // Cleanup the event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showdownlodefile]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSearch(value);
  };
  const handleAssignmentStatusChange = (event) => {
    setAssignmentStatus(event.target.value);
  };
  const handleScoreChange = (event) => {
    setScore(event.target.value);
  };
  const hedleKeyupChnage = async (page) => {
    try {
      // loading = true; // Set loading to true before the request
      const pageNumber = typeof page === 'number' ? page : 1;
      const response = await api.get(`lessons/teachersearchlessons/`, {
        params: {
          page: pageNumber,
          student__name: search,
          // status: "Pending",
          result: assignmentStatus,
          sorting: sortOption,
          lesson_id: location?.state?.lesson?.lesson_id,
        },
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      });

      if (response.status == 200) {
        setStudent_assignment_details(response.data.data);
        setTotalPages(
          response.data.count >= 5
            ? Math.ceil(response.data.count / itemsPerPage)
            : Math.floor(response.data.count / itemsPerPage)
        ); // Calculate total pages
        setNextUrl(response.data.next); // Update next URL
        setPrevUrl(response.data.previous); // Update previous URL
        setIsVisible(false);
        setIsVisiblefillter(false);
      }
    } catch (error) {
      console.error("Fetching error:", error);
    } finally {
      // loading = false;// Set loading to false after the request is completed or if an error occurs
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 500,
    });
    hedleKeyupChnage(currentPage);
  }, [currentPage, TempglobalState, search]);

  const selectGrade = [
    {
      filtergrade: "A+"
    },
    {
      filtergrade: "A"
    },
    {
      filtergrade: "B"
    },
    {
      filtergrade: "C"
    }
  ];

  const handlePageChange = (event, value) => {
    window.scrollTo(0, 0);
    setCurrentPage(value);
  };

  const handleSortOptionChange = (newSortOption) => {
    setSortOption(newSortOption);
  };

  const handleApplyClick = () => {
    hedleKeyupChnage(currentPage);
    // onApplySort(sortOption);
    // setIsVisible(false);
  };
  const onPreviewLesson = (lesson) => {
    const course = location.state.course;
    const teacher_id = location.state.course.teacher_id;
    navigate("/NewLessons", { state: { course, teacher_id, lesson } });
  };

  // filterDropdownRef start
  const filterDropdownRef = useRef(null);
  useEffect(() => {
    const handleFilterClick = (event) => {
      if (filterDropdownRef.current && !filterDropdownRef.current.contains(event.target)) {
        setIsVisiblefillter(false);
      }
    };

    document.addEventListener('click', handleFilterClick);

    return () => {
      document.removeEventListener('click', handleFilterClick);
    };
  }, []);
  // filterDropdownRef end

  // sortDropdownRef start
  const sortDropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sortDropdownRef.current && !sortDropdownRef.current.contains(event.target)) {
        setIsVisible(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  // sortDropdownRef end
  return (
    <>
      <div id="LessonViewProgress-section">
        <div className="view-course-Details-wrapper">
          <div className="LessonViewProgress-header-section">
            {/* <div className="view-course-Details-back-btn-folder LessonViewProgress-back-btn-folder"> */}
              {/* <BackButton buttonName={location?.state?.lesson?.name} /> */}
              {/* <BackButton buttonName={location?.state?.lesson?.name} /> */}
              {/* <i
                onClick={() => navigate(-1)}
                className="fa-solid fa-arrow-left"
              ></i>
              <h2>
              {location?.state?.lesson?.name }
              </h2> */}
            {/* </div> */}
            {/* <div className="date-inner-wrapper">
              <div className="view-course-details-container">
                <div className="view-Students-course-details-folder LessonViewProgress-student-course-folder">
                  <span>Created</span>
                  <div className="view-Students-course-details-counts">
                    <p>{Helper.formatDateTime(location?.state?.lesson?.start_date).date}</p>
                  </div>
                </div>
                <div className="view-Students-course-details-folder LessonViewProgress-student-course-folder">
                  <span>Due date</span>
                  <div className="view-Students-course-details-counts">
                    <p>{Helper.formatDateTime(location?.state?.lesson?.end_date).date}</p>
                    <p>{location?.state?.lesson?.end_date !== null && location?.state?.lesson?.end_date !== "" ? Helper.formatDateTime(location?.state?.lesson?.end_date).date : "- -"}</p>
                  </div>
                </div>
              </div>
              <button type="button" onClick={() => onPreviewLesson(location?.state?.lesson)}>Preview Lesson</button>
            </div> */}
          </div>
          <div className="LessonViewProgress-tab-folder-container">
            <div className="LessonViewProgress-serach-filter">
              <ReactSVG className="search-icon" src={search_icon} />
              <input
                type="text"
                name="student_name"
                value={search}
                onChange={handleInputChange}
                onKeyUp={hedleKeyupChnage}
                placeholder="Search by student name"
              />
            </div>
            <div className="LessonViewProgress-tab-filter-wrapper">
              {/* <div
                className="LessonViewProgress-tab-filter-downlode-folder"
                onClick={openModal}
              >
                <span>
                  <i className="fa-solid fa-download"></i>
                </span>
              </div> */}
              {/* <div className="LessonViewProgress-filder-tab-wrapper">
                <div className="lessonviewprogress-filder-tab">
                  <span>
                    <i className="fa-solid fa-sliders"></i>
                  </span>
                  <p>Filter</p>
                </div>
              </div>
              <div className="LessonViewProgress-filder-tab-wrapper">
                <div className="lessonviewprogress-filder-tab">
                  <span>
                    <i className="fa-solid fa-sliders"></i>
                  </span>
                  <p>Sort</p>
                </div>
                
              </div> */}
              <div className="LessonViewProgress-filder-tab-wrapper">
                <div className="lessonViewProgress-filter-popup-wrapper" ref={filterDropdownRef}>
                  <div
                    className="lessonviewprogress-filder-tab"
                    onClick={() => {
                      if (!isVisiblefillter) {
                        setIsVisiblefillter(true);
                        setIsVisible(false);
                      } else {
                        setIsVisiblefillter(false);
                      }
                    }}
                  >
                    <span>
                      <i className="fa-solid fa-sliders"></i>
                    </span>
                    <p>Filter</p>
                  </div>
                  <FilterPopup
                    assignmentStatus={assignmentStatus}
                    score={score}
                    isVisiblefillter={isVisiblefillter}
                    handleApplyClick={handleApplyClick}
                    handleAssignmentStatusChange={handleAssignmentStatusChange}
                    fillterstatus={fillterstatus}
                    fillterScore={fillterScore}
                    handleScoreChange={handleScoreChange}
                  />
                </div>
              </div>
              <div className="LessonViewProgress-filder-tab-wrapper">
                <div className="lessonViewProgress-filter-popup-wrapper" ref={sortDropdownRef}>
                  <div
                    className="lessonviewprogress-filder-tab"
                    onClick={() => {
                      if (!isVisible) {
                        setIsVisible(true);
                        setIsVisiblefillter(false);
                      } else {
                        setIsVisible(false);
                      }
                    }}
                  >
                    <span>
                      {/* <img src={srot}/> */}
                      <i class="fa-solid fa-arrow-down-wide-short"></i>
                    </span>
                    <p>Sort</p>
                  </div>
                  <SortPopup
                    isVisible={isVisible}
                    handleApplyClick={handleApplyClick}
                    sortOption={sortOption}
                    onSortOptionChange={handleSortOptionChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="LessonViewProgress-table-container">
            <table>
              <thead>
                <tr>
                  <th>Sr no.</th>
                  <th>Student Name</th>
                  <th>Started At</th>
                  <th>Completed At</th>
                  <th>Lesson Status</th>
                  {/* <th>Lesson Progress</th> */}
                  {/* <th>Student Chat</th> */}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  error === true ?
                    (
                      <tr>
                        <td colSpan="8" className="tex-center">{errormsg}</td>
                      </tr>
                    ) : (
                      <>
                        {
                          loading === true
                            ? (
                              <LoadingComponent
                                loading_text="Educating the Future, Loading Now."
                              />
                            ) : (
                              <>
                                {
                                  student_assignment_details && student_assignment_details.length !== 0 ? (
                                    student_assignment_details?.map((student, index) => (
                                      <tr key={student.id}>
                                        <td>{inial_value + index + 1}</td>
                                        <td>{student.student_name}</td>
                                        <td>
                                          <div className="startedAt-time">
                                            <span>{Helper.formatDateTime(student.created_at).date}</span>
                                            <span>{Helper.formatDateTime(student.created_at).time}</span>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="completedat-time">
                                            <span>{student.completed_at ? (Helper.formatDateTime(student.completed_at).date) : '-'}</span>
                                            <span>{student.completed_at ? (Helper.formatDateTime(student.completed_at).time) : ''}</span>
                                            {/* <img src={student_grade_flage} alt="" /> */}
                                          </div>
                                        </td>
                                        <td>
                                          <div className={`LessonViewProgress-student-status ${Helper.getClassNameByStatus(student.status)}`}>
                                            {student.status}
                                          </div>
                                        </td>
                                        {/* <td>
                                    <div className="progress">
                                      <div
                                        className="progress-bar"
                                        style={{ width: `${student.progress}%` }}
                                      ></div>
                                    </div>
                                  </td> */}
                                        {/* <td>
                                    <div className="LessonViewProgress-student-chat" onClick={(e) => {
                                          e.preventDefault();
                                          navigate(`/dashboard/messageschat/`, {
                                            state: {
                                              studentName: student.student_name, // Passing student name
                                              studentProfile: student.student_profile_pic,
                                              studentId : student.student_user_id,
                                              teacherId : student.teacher_user_id
                                            }
                                          });
                                        }}>
                                      <a> */}
                                        {/* ND */}
                                        {/* <img src={student.student_profile_pic} className="profile-pic-image"/>
                                      </a>
                                      <img src={massege}  alt=""/>
                                    </div>
                                  </td> */}
                                        {/* <td>
                                    <a 
                                    onClick={(e) => {
                                      e.preventDefault();
                                      navigate(
                                        `/course/${location?.state?.course?.course_id}/lesson/${location?.state?.lesson?.lesson_id}/analytics/${student.id}/`,
                                        { state:  {lesson: location?.state?.lesson, analytic_id:student.id}  }
                                      );
                                    }}
                                    >View analytics</a>
                                  </td> */}
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan="8" className="tex-center">
                                        No data found
                                      </td>
                                    </tr>
                                  )
                                }
                              </>
                            )
                        }
                      </>
                    )
                }
              </tbody>
            </table>
            <br />
            <Grid xs={12} container justifyContent={"center"}>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                hideNextButton={
                  nextUrl === null || currentPage === totalPages
                } // Disable if no next URL and on the last page
                hidePrevButton={prevUrl === null || currentPage === 1} // Hide if no previous URL and on the first page
              />
            </Grid>
          </div>
          {/* <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
              </div>
            </div>
          </div> */}
          {showdownlodefile && (
            <div ref={modalRef} className="Download-File-popup-wrappper">
              <div ref={modalContentRef} className="Download-File-popup-container" data-aos="zoom-in">
                <div className="download-popup">
                  <h2>Download File as</h2>
                  <button className="close-btn" onClick={toggledownlodefile}>
                    ×
                  </button>
                  <div className="input-section">
                    <label>
                      File Name *{" "}
                      <input type="text" placeholder="Enter File Name" />
                    </label>
                  </div>
                  <div className="button-section">
                    <button className="pdf-btn">PDF (.pdf)</button>
                    <span>Or</span>
                    <button className="spreadsheet-btn">
                      Spreadsheet (.xlsx)
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default LessonViewProgress;
