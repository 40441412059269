import React, { useEffect } from "react";
import { dashborad_Courses_book_image } from "../../../assets/images/images";
import DashboardheaderTab from "../../../components/DashboardTab";
import { getDashboardCount, resetdashboardcount } from "../../../state/actions/adminDashboardCountAction";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../../features/auth/AuthContext";


const Dashboard = () => {

  const { token } = useAuth();

  const dispatch = useDispatch();
  const superadminCount = useSelector((state) => state.superadminCount);

  const getDashboardCountReq = () => {
    dispatch(getDashboardCount(token));
  }

  useEffect(() => {
    getDashboardCountReq()
  }, [])

  const DashboardTab = [
    {
      dashborad_count_image: dashborad_Courses_book_image,
      total_count_number: superadminCount?.schools_count ?? 0,
      count_title: "Total Schools",
      count_background: "#FFE0E0"
    },
    {
      dashborad_count_image: dashborad_Courses_book_image,
      total_count_number: superadminCount?.teacher_count ?? 0,
      count_title: "Total Teachers",
      count_background: "#FFDFFA"
    },
    {
      dashborad_count_image: dashborad_Courses_book_image,
      total_count_number: superadminCount?.courses_count ?? 0,
      count_title: "Total Courses",
      count_background: "#F6E7FF"
    },
    {
      dashborad_count_image: dashborad_Courses_book_image,
      total_count_number: superadminCount?.students_count ?? 0,
      count_title: "Total Students",
      count_background: "#FFEADB"
    },
    {
      dashborad_count_image: dashborad_Courses_book_image,
      total_count_number: superadminCount?.lessons_count ?? 0,
      count_title: "Total Lessons",
      count_background: "#CFE5FB"
    },
    {
      dashborad_count_image: dashborad_Courses_book_image,
      total_count_number: superadminCount?.assignment_count ?? 0,
      count_title: "Total Assignments",
      count_background: "#FFE0E0"
    },
  ];

  return <>
    <div className="dashboard_tab">
          {DashboardTab.map((data, index) => (
            <DashboardheaderTab
              key={index}
              dashborad_Courses_book_image={data.dashborad_count_image}
              total_courses_number={data.total_count_number}
              total_courses={data.count_title}
              count_background={data.count_background}
            />
          ))}
    </div>
  </>
};

export default Dashboard;
