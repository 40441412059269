import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { course_demo_image, course_info_image } from "../../../assets/images/images";
import TopRatingReview from '../TopRatingReview/TopRatingReview';
import Course from '../Rating/CourseRating/Course';
// import Course from '../../../components/Course/Course';
import BackButton from "../../../components/BackButton/BackButton";
import { useAuth } from '../../../features/auth/AuthContext';
import { getCourseById, resetgetCourse } from "../../../state/actions/getCourseByIdAction";

function RatingDetails() {

    const { token } = useAuth();
    const { id } = useParams();
    const dispatch = useDispatch();

    const { course } = useSelector((state) => state.getCourse);

    useEffect(() => {
        dispatch(getCourseById(id, token))
    }, [])


    return (

        <>  
        <div className='ratingDetails-section'>
            <div className='ratingDetails-wrapper'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12'>
                            <BackButton buttonName="Ratings"/>
                                <Course
                                    course_demo_image={course.image}
                                    Course_title={course.name}
                                    Course_des={course.description}
                                    Lesson_number={course.total_lessons}
                                Lessons_name= "Lessons"
                                    student_number={course.enrolled_students}
                                Students_name= "Students"
                                    Course_Start_Date={course.start_date}
                                    Course_Expiry_Date={course.end_date}
                                    course_info_image={course.ai_avatar}
                                    Teacher_title={course.title}
                                    Teacher_name={course.teacher}
                            />
                                <TopRatingReview />
                            {/* <RatingPopup/> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default RatingDetails;