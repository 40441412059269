import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AddSchool {
    isLoading: boolean;
    error: string | null;
    message: string | null;
    school_id: number | null;
}

const initialState: AddSchool = {
    message: null,
    isLoading: false,
    error: null,
    school_id: null,
};

const addschoolslice = createSlice({
    name: 'addSchool',
    initialState,
    reducers: {
        setAddSchool: (state : AddSchool, action: PayloadAction<AddSchool>) => {
            state.message = action.payload.message;
            state.isLoading = false;
            state.error = null;
        },
        setSchoolId: (state : AddSchool, action: PayloadAction<AddSchool>) => {
            state.school_id = action.payload.school_id;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: AddSchool, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null; 
        },
        seterror: (state : AddSchool, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetaddSchool: () => {
            return initialState;
        },
    },
});

export const { setAddSchool, setSchoolId, setloading, seterror, resetaddSchool } = addschoolslice.actions;

export default addschoolslice.reducer;
