import { Dispatch } from "redux";
import api, { wsURL } from "../../api/api";
import {
    setcourseImageGenerate,
    setloading,
    seterror,
    resetCourseImageGenerate,
} from "../reducers/courseImageGenerateReducer";

const courseImageGenerateDtl = (data: any,token: any ) => async (
    dispatch: Dispatch
) => {
    dispatch(setloading(true));
    try {
        const result = await api.post(`/ai/generate_course_image/?token=${token}`,
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`
                },
            });

        if (result) {
            dispatch(setloading(false));
            dispatch(setcourseImageGenerate(result.data));
        }
    } catch (error: any) {
        dispatch(seterror(error.response?.data?.message || "Error generating image"));
        dispatch(setloading(false));
    }
};

export { courseImageGenerateDtl, resetCourseImageGenerate };