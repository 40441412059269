import React, { useState, useEffect } from 'react';
import { coming_soon } from "../../assets/images/images";
import './CommingSoon.css'; // Make sure this CSS file exists with the desired styles
// import moment from "moment";

function CountdownTimer({ startDate, endDate }) {
  // Calculate the time difference between now and the end date

  const calculateTimeLeft = () => {


    const moment = require('moment-timezone');

    // Get the current date and time in the user's timezone
    const currentTime = moment();


    // Convert to US Eastern Timezone
    const usTime = currentTime.clone().tz("America/New_York");
    // console.warn(usTime.format("ddd MMM DD YYYY HH:mm:ss"));


    // const now = new Date();
    const endTime = new Date(endDate);
    let timeLeft = {};

    if (usTime < endTime) {
      // If the current time is before the end time, calculate the difference
      const difference = endTime - usTime;
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };

    } else {
      // If the current time is after the end time, the event has ended
      timeLeft = null;
      // setCoundown(false)
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    // Update the countdown every second
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(timer);
  }, []); // Empty dependency array means this effect runs once on mount and never again




  // Helper function to format time as two digits
  const formatTime = (time) => (time < 0 ? '00' : time.toString().padStart(2, '0'));

  // If the event has ended, return a message
  if (timeLeft === null) {
    // return <div className='countdown-timer-folder'>The event has ended.</div>;
    return <div></div>;
  }

  // Render the countdown timer
  return (
    <div className='countdown-timer-folder-section' >
      <div className='countdown-timer-folder'>
          {/* <div className='coming-soon-image'>
              <img src={coming_soon}/>
          </div> */}
          <div className='countdown-timer-coming-soon-wrapper'>
              <div className="countdown-timer">
                  {/* Display days, hours, minutes, and seconds in countdown */}
                  <div className="time-section">
                  <span className="time">{formatTime(timeLeft.days)}</span>
                  <span className="label">DAYS</span>
                  </div>
                  <div className="time-section">
                  <span className="time">{formatTime(timeLeft.hours)}</span>
                  <span className="label">HR</span>
                  </div>
                  <div className="time-section">
                  <span className="time">{formatTime(timeLeft.minutes)}</span>
                  <span className="label">MIN</span>
                  </div>
                  <div className="time-section">
                  <span className="time">{formatTime(timeLeft.seconds)}</span>
                  <span className="label">SEC</span>
                  </div>
              </div>
              <p>Launching on 15th April at 2:00 PM</p>
          </div>
          {/* <div className='coming-soon-image'>
              <img src={coming_soon}/>
          </div> */}
      </div>
    </div>
  );
}

export default CountdownTimer;
