import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { AuthProvider } from "./features/auth/AuthContext";

import reportWebVitals from "./reportWebVitals";
import GlobalProvider from "./state/global/provider.tsx";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { GoogleOAuthProvider } from "@react-oauth/google";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import * as Sentry from "@sentry/react";
import { ProfanityFilterProvider } from "./context/ProfanityFilterContext";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENVIRONMENT,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
const clientId = process.env.REACT_APP_GOOGLE_API_CLIENT_ID;
root.render(
  <Sentry.ErrorBoundary fallback={"An error has occurred"}>
    <React.StrictMode>
      <GoogleOAuthProvider clientId={clientId}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <GlobalProvider>
            <AuthProvider>
              <ProfanityFilterProvider>
                <App />
              </ProfanityFilterProvider>
            </AuthProvider>
          </GlobalProvider>
        </LocalizationProvider>
      </GoogleOAuthProvider>
    </React.StrictMode>
  </Sentry.ErrorBoundary>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
