import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SuggestedCourses {
    suggestedCourses: any;
    isLoading: boolean;
    error: string | null;
    message: string | null
}

const initialState: SuggestedCourses = {
    suggestedCourses: [],
    message: null,
    isLoading: false,
    error: null
};

const cartSuggestedCoursesSlice = createSlice({
    name: 'cartSuggestedCourses',
    initialState,
    reducers: {
        setCartSuggestedCoursesList: (state: SuggestedCourses, action: PayloadAction<SuggestedCourses>) => {
            state.suggestedCourses = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: SuggestedCourses, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: SuggestedCourses, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetSuggestedCoursesList: () => {
            return initialState;
        },
    },
});

export const { setCartSuggestedCoursesList, setloading, seterror,  resetSuggestedCoursesList } = cartSuggestedCoursesSlice.actions;

export default cartSuggestedCoursesSlice.reducer;
