import { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { updateGlobalState } from "../state/global/slice";

const useWebSocket = (url) => {
  const dispatch = useDispatch();
  const updateState = (key, value) => {
    dispatch(updateGlobalState({ key: key, value: value }));
  };
  const [socket, setSocket] = useState(null);
  const [notifications, setNotifications] = useState([]);

  const sendMessage = useCallback(
    (message) => {
      if (socket?.readyState === WebSocket.OPEN) {
        socket.send(JSON.stringify(message));
      }
    },
    [socket]
  );

  useEffect(() => {
    const ws = new WebSocket(url);

    ws.onopen = () => {
      console.log("WebSocket connected");
    };

    ws.onmessage = (event) => {
      const newNotification = JSON.parse(event.data);
      setNotifications((prev) => [newNotification]);
    };

    ws.onclose = (event) => {
      console.log("WebSocket disconnected:", event.reason);
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    setSocket(ws);

    return () => {
      ws.close();
    };
  }, [url]);
  updateState("notifications", notifications);
  return { notifications, sendMessage };
};

export default useWebSocket;
