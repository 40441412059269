import { Dispatch } from "redux";
import api from "../../api/api";
import {
    setAdminGraph,
    setloading,
    seterror,
    resetAdminGraph,
} from "../reducers/adminDashboardGraphReducer";

const getAdminGraph = (token: any, type: any, year: any) => async (
    dispatch: Dispatch
) => {
    dispatch(setloading(true));
    try {
        const result = await api.get(`/school-admin/school/dashbord-graph-count/`, {
            params: {
                type: type,
                year: year,
            },
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
        });

        if (result) {
            dispatch(setloading(false));
            if (result.data?.status === 200) {
                dispatch(setAdminGraph(result.data.result.results));
            } else {
                dispatch(seterror(result.data?.message ?? "Something Went Wrong!"));
            }
        }
    } catch (error: any) {
        dispatch(seterror(error.response.data.message));
    }
};

export { getAdminGraph, resetAdminGraph };

