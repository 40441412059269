import { Dispatch } from "redux";
import api from "../../api/api";
import {
    setInvitationList, setPagination, isloading, seterror, resetInvitationList
} from "../reducers/sentInvitationListReducer";

const getSentInvitationList = (id: any, page: any, token: any, search: any, filterOption: any, sortOption: any, teacher_id: any) => async (dispatch: Dispatch) => {
    dispatch(isloading(true));
    try {
        const result = await api
            .get(
                `courses/teacher/invite-student-list/${id}/?page=${page}&search=${search}&filter=${filterOption}&sort=${sortOption}&teacher_id=${teacher_id}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Token ${token}`,
                    },
                }
            )
        if (result) {
            dispatch(isloading(false));
            if (result.data?.status === 200) {
                dispatch(setInvitationList(result?.data?.data));
                dispatch(setPagination(result.data));
            }
            else {
                dispatch(seterror(result.data?.status ?? "Something went wrong"));
            }
        }
    }
    catch (error: any) {
        dispatch(seterror(error.response.data.message || "Something went wrong"));
    }
}

export { getSentInvitationList, resetInvitationList };



