import React, { useRef, useState } from "react";
import { google_drive, uplode_file } from "../../assets/images/images";
import "./GoogleDriveUpload.css";
import useDrivePicker from "react-google-drive-picker";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const CLIENT_ID = process.env.REACT_APP_GOOGLEDRIVE_CLIENTID;
const API_KEY = process.env.REACT_APP_GOOGLEDRIVE_API_KEY;

async function getAccessToken() {
  const CLIENT_ID = process.env.REACT_APP_GOOGLEDRIVE_CLIENTID;
  const CLIENT_SECRET = process.env.REACT_APP_GOOGLEDRIVE_SECRET_KEY;
  const REFRESH_TOKEN = process.env.REACT_APP_GOOGLEDRIVE_REFRESH_TOKEN;
  const TOKEN_ENDPOINT = "https://oauth2.googleapis.com/token";

  const params = new URLSearchParams();
  params.append("client_id", CLIENT_ID);
  params.append("client_secret", CLIENT_SECRET);
  params.append("refresh_token", REFRESH_TOKEN);
  params.append("grant_type", "refresh_token");

  try {
    const response = await fetch(TOKEN_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: params.toString(),
    });

    if (!response.ok) {
      throw new Error(`Failed to refresh access token: ${response.statusText}`);
    }

    const data = await response.json();
    return data.access_token;
  } catch (error) {
    console.error("Error fetching access token:", error);
    return null;
  }
}

const 
GoogleDriveUpload = ({
  setShowUploadPop,
  handleFileChange
}) => {
  const fileInputRef = useRef(null);
  const [openPicker] = useDrivePicker();
  const [statusCode, setStatusCode] = useState(null);

  const googleAccessToken = useSelector(state => state.getGoogleAccessToken);

  const openFilePicker = async () => {
    const accessToken = await getAccessToken();

    // const accessToken = googleAccessToken?.googleToken?.google_token?.stsTokenManager?.accessToken;
  
    setShowUploadPop(false);

    if (!accessToken) {
      console.error("Unable to retrieve access token");
      return;
    }

    openPicker({
      clientId: CLIENT_ID,
      developerKey: API_KEY,
      viewId: "DOCUMENTS,DOCS,PDFS,SPREADSHEETS,IMAGES,PHOTOS,PLAIN_TEXT",
      viewMimeTypes: [
        "application/pdf",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "image/jpeg",
        "image/png",
        "text/plain",
        "application/xls",
        "application/xlsx",
        "application/doc",
        "application/docx",
      ].join(","),
      setIncludeFolders: true,
      setSelectFolderEnabled: true,
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      oauth_token: accessToken,
      scopes: [
        "https://www.googleapis.com/auth/drive.readonly",
        "https://www.googleapis.com/auth/drive.file",
        "https://www.googleapis.com/auth/drive",
        "https://www.googleapis.com/auth/drive.metadata.readonly",
        "https://www.googleapis.com/auth/drive.metadata",
      ],
     
      callbackFunction: async (data) => {

        if (data.action === "picked") {
          const files = await Promise.all(
            data.docs.map(async (doc) => {

              try {
                let downloadUrl;
                if (
                  doc.mimeType === "application/vnd.google-apps.spreadsheet"
                ) {
                  // Export Google Spreadsheet as XLSX
                  downloadUrl = `https://www.googleapis.com/drive/v3/files/${doc.id}/export?mimeType=application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`;
                  doc.mimeType = "application/xlsx";
                  doc.name = `${doc.name}.xlsx`;
                } else if (
                  doc.mimeType === "application/vnd.google-apps.document"
                ) {
                  // Export Google Docs as DOCX
                  downloadUrl = `https://www.googleapis.com/drive/v3/files/${doc.id}/export?mimeType=application/vnd.openxmlformats-officedocument.wordprocessingml.document`;
                  doc.mimeType = "application/docx";
                  doc.name = `${doc.name}.docx`;
                }else{
                  downloadUrl = `https://www.googleapis.com/drive/v3/files/${doc.id}?alt=media`;
                }

                const response = await fetch(downloadUrl, {
                  headers: new Headers({
                    Authorization: `Bearer ${accessToken}`,
                  }),
                });

                if (!response.ok) {
                  setStatusCode(response.status);
                  throw new Error(
                    `Failed to fetch file: ${doc.name}, Status: ${response.status}`
                  );
                }

                const blob = await response.blob();

                // Additional validation
                if (blob.size === 0) {
                  toast.error(`${doc.name} seems corrupted.`);
                  // console.error(`Blob is empty for file: ${doc.name}`);
                  return null;
                }

                // Validate MIME type and Blob integrity
                const mimeType = doc.mimeType || blob.type;

                return new File([blob], doc.name, {
                  type: mimeType,
                  lastModified: new Date(doc.lastEditedUtc).getTime(),
                });
              } catch (error) {
                console.error("Error fetching file:", error);
                return null;
              }
            })
          );

          const failedFiles = files.some((file) => file === null);

          if (failedFiles) {
            if (statusCode === 403) {
              toast.error("File/s too large to upload.");
              return;
            }
            else {
              toast.error("Failed to upload file/s.");
            }
            return;
          }

          const filteredFiles = files.filter((file) => file !== null);

          const dataTransfer = new DataTransfer();
          filteredFiles.forEach((file) => dataTransfer.items.add(file));
          const fileList = dataTransfer.files;

          const event = new Event("change", { bubbles: true });
          Object.defineProperty(event, "target", {
            writable: true,
            value: { files: fileList },
          });
          setStatusCode(null);
          handleFileChange(event);
        } else if (data.action === "cancel") {
          console.log("User clicked cancel/close button");
        } else {
          console.log("Unhandled action: ", data);
        }
      },
    });
  };

  return (
    <>
            <div
              className="google-drive-popup-folder"
              onClick={openFilePicker}
              style={{ cursor: "pointer" }}
            >
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
              />
              <div className="google-drive-image">
                <img src={google_drive} alt="Google Drive" />
              </div>
              <p>Connect To Drive</p>
            </div>
    </>
  );
};

export default GoogleDriveUpload;