import { Dispatch } from "redux";
import api from "../../api/api";
import {
  setdashboardcount, setloading, seterror, resetdashboardcount
} from "../reducers/adminDashboardCountReducer";

const getDashboardCount = (token: any) => async (dispatch: Dispatch) => {
   dispatch(setloading(true));
   try {
     const result = await api
       .get(
        `super-admin/dashboard-count/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
       )
       
       if(result){
        dispatch(setloading(false));
        if (result.data?.status === 200) {
                dispatch(setdashboardcount(result.data.data));
          }
          else {
            dispatch(seterror(result.data?.message ?? "Something Went Wrong!"));
          }
       }
   }
   catch (error: any) {
    dispatch(seterror(error.response.data.message));
   }
}

export { getDashboardCount, resetdashboardcount} 

