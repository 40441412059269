import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../../features/auth/AuthContext';
import { useParams } from 'react-router-dom';
import { teacherRatingReply, resetteacherRatingReply } from "../../../state/actions/teacherRatingReplyAction"
import { Modal } from "react-responsive-modal";
import CancelPopup from '../../../components/CancelPopup/CancelPopup';
import { send_message_icon } from "../../../assets/images/images";
import dayjs from "dayjs";

function TopRatingCard({ data, index, integerStars, decimalStar }) {

    const [showMessageWrapper, setShowMessageWrapper] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [message, setMessage] = useState(data?.reply_comment);
    const [deleteConfirm, setdeleteConfirm] = useState(false);

    const textareaRef = useRef(null);
    const { id } = useParams();
    const dispatch = useDispatch();
    const { token } = useAuth();

    const globalState = useSelector((state) => state.globalState);

    const toggleExpand = () => {
        setExpanded(!expanded);
    };

    const handleReplyButtonClick = () => {
        setShowMessageWrapper(true);
    };

    const handleEditClick = () => {
        // Show the message wrapper if it's hidden
        setShowMessageWrapper(true);
        // Focus on the textarea
        // if (textareaRef.current) {
        //     textareaRef.current.focus();
        // }
    };

    const handleReplyChange = (event) => {
        setMessage(event.target.value);
    };

    const handleReplySend = () => {
        const req = {
            reply: message
        }
        dispatch(teacherRatingReply('POST', data?.rating_id, token, req));
    }

    const handleReviewDelete = () => {
        dispatch(teacherRatingReply('DELETE', data?.rating_id, token));
    }

    return (
        <>
            <div key={index} className='topratingreview-card-folder'>
                <div className='topratingreview-card-inner-header-wrapper'>
                    <div className='topratingreview-card-inner-profile-folder'>
                        <div className='topratingreview-card-inner-profile'>
                            <img src={data?.student_profile_pic} />
                        </div>
                        <div className='topratingreview-card-profile-wrapper'>
                            <p className='topratingreview-card-profile-title'>{data?.name}</p>
                            <div className='topratingreview-stars'>
                                {integerStars}
                                {decimalStar}
                                <span className='topratingreview-stars-number'>({data?.rating})</span>
                            </div>
                        </div>
                    </div>
                    {globalState.role !== "Student" && <div className='topratingreview_btn-container'>
                        {data?.comment !== "" && !data?.reply_comment?.length && <button onClick={handleEditClick} className='topratingreview-reply-btn'><i class="fa-solid fa-reply"></i> Reply</button>}
                        {globalState.role === "Admin" && <span><i class="fa-solid fa-trash" onClick={() => setdeleteConfirm(true)}></i></span>}
                    </div>}
                </div>
                <div className='topratingreview-card-des-folder'>
                    <p>

                        {data?.comment.length <= 2 || expanded ? data?.comment : `${data?.comment.slice(0, 400)}...`}
                        {data?.comment.length > 150 && <span onClick={toggleExpand}> {expanded ? 'Show less' : 'View more'}</span>}
                    </p>
                </div>

                {
                    <div className='topratingreview-card-massege-wrapper'>

                        {!showMessageWrapper ?
                            data?.reply_comment !== null ?
                                <>
                                    <div className='topratingreview-card-inner-profile'><img src={globalState?.role === "Admin" ? globalState?.school_details?.image : (data?.teacher_profile_pic ?? globalState?.teacher_details?.data?.ai_avatar)} /></div>
                                    <p className='topratingreview-card-edit-massege'>{message}</p>
                                </>
                                : <></>
                            :
                            <>  <div className='topratingreview-card-inner-profile'><img src={globalState?.role === "Admin" ? globalState?.school_details.image : globalState?.teacher_details?.data?.ai_avatar} /></div>
                                <textarea className='send-message' ref={textareaRef} rows="2" cols="2" onChange={handleReplyChange} placeholder={`Thank you for your Feedback! ${data?.name} |`} value={message} />
                                <div className='send-message-icon' onClick={message?.length ? handleReplySend : () => { }}>
                                    <img src={send_message_icon} />
                                </div>
                            </>}
                    </div>
                }

                {globalState.role !== "Student" && data?.reply_comment !== null && <div className='topratingreview-card-massege-edit-folder'>
                    <button onClick={handleEditClick} >Edit Reply</button>
                </div>}

                <div className='topratingreview-card-date-folder'>
                    <span className='date'>{dayjs(data?.date).format("MMM DD, YYYY")}</span>
                </div>

            </div>

            {deleteConfirm &&
                <Modal
                    keepMounted
                    open={deleteConfirm}
                    onClick={() => setdeleteConfirm(false)}
                    center
                    showCloseIcon={false}
                    classNames={{
                        modal: 'Cancel-Popup',
                    }}
                >
                    <CancelPopup
                        title="Are you sure you want to delete this review"
                        No="Back"
                        Yes="Yes"
                        show={handleReviewDelete}
                        close={() => setdeleteConfirm(false)}
                    />
                </Modal>
            }
        </>
    )
}

export default TopRatingCard