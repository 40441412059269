// src/components/SwitchTab.js

// import React from 'react';

// const tabs = ['Student', 'Teacher', 'Parent'];
// const SwitchTab = ({ activeTab, onTabChange }) => {
//     const handleClick = (tab, event) => {
//         event.preventDefault();
//         onTabChange(tab);
//     };
//     return (

//         <div className="signup-tab-folder">

//             {tabs.map((tab) => (
//                 <button key={tab}
//                     className={activeTab === tab ? 'active' : null}
//                     onClick={(e) => handleClick(tab, e)}>

//                     {tab}
//                 </button>
//             ))}
//         </div>
//     );
// };

// export default SwitchTab;


import React from 'react';
import {coming_soon_image} from '../assets/images/images';

const tabs = ['Student', 'Teacher', 'Parent'];
const SwitchTab = ({ activeTab, onTabChange }) => {
    const handleClick = (tab, event) => {
        event.preventDefault();
        onTabChange(tab);
    };
    return (

        <div className="signup-tab-folder">
            {tabs.map((tab) => (
                <>
                    {tab === 'Parent' && (<div className='coming-soon'><img src={coming_soon_image} alt=''/></div>)}
                    <button key={tab}
                        className={activeTab === tab ? 'active' : null}
                        disabled={tab === 'Parent' ? true : false}
                        onClick={(e) => handleClick(tab, e)}>
                        {tab}
                    </button>
                </>
            ))}
        </div>
    );
};

export default SwitchTab;

