import { Dispatch } from "redux";
import api from "../../api/api";
import {
  setProfileDetails,
  setloading,
  seterror,
  resetProfileDetails,
} from "../reducers/getProfileDetailsReducer";

const getProfileDetails = (token: any) => async (
  dispatch: Dispatch
) => {
  dispatch(setloading(true));
  try {
    const result = await api.get(`/students/get-student/`, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Token ${token}`,
      },
    });

    if (result) {
      dispatch(setloading(false));
      if (result.data?.status === 200) {
        dispatch(setProfileDetails(result.data));
      } else {
        dispatch(seterror(result.data?.message ?? "Something Went Wrong!"));
      }
    }
  } catch (error: any) {
    //dispatch(seterror(error.response.data.message));
  }
};

export { getProfileDetails, resetProfileDetails}; 

