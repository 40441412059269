import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AddToCart {
    isLoading: boolean;
    error: string | null;
    message: string | null;
}

const initialState: AddToCart = {
    message: null,
    isLoading: false,
    error: null,
};

const AddToCartSlice = createSlice({
    name: 'AddToCart',
    initialState,
    reducers: {
        setAddToCart: (state: AddToCart, action: PayloadAction<AddToCart>) => {
            state.message = action.payload.message;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: AddToCart, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: AddToCart, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetAddToCart: () => {
            return initialState;
        },
    },
});

export const { setAddToCart, setloading, seterror, resetAddToCart } = AddToCartSlice.actions;

export default AddToCartSlice.reducer;
