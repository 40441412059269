import React from 'react';
import './IncompleteRecords.css';
import { scan } from "../../../../assets/images/images"; 
import BackButtonFolder from '../../../BackButtonFolder/BackButtonFolder';

const IncompleteRecords = () => {
    const studentData = [
        {
          name: 'Thomas Shelby',
          studentId: 'TE2525425',
          age: '14 yrs',
          grade: '1st',
          email: 'thomasshelby123@gmail.com',
          contactNo: '+10987345210',
          location: 'New York',
        },
        {
            name: 'Thomas Shelby',
            studentId: 'TE2525425',
            age: '14 yrs',
            grade: '1st',
            email: 'thomasshelby123@gmail.com',
            contactNo: '+10987345210',
            location: 'New York',
          },
          {
            name: 'Thomas Shelby',
            studentId: 'TE2525425',
            age: '14 yrs',
            grade: '1st',
            email: 'thomasshelby123@gmail.com',
            contactNo: '+10987345210',
            location: 'New York',
          },
      ];
  return (
    <>
        <div className='incomplete-records-table'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-lg-12 col-md-12'>
                        <div className='table-Records-header-wrapper'>
                            <div className='BackButtonFolder-folder'>
                                <BackButtonFolder
                                    buttonName="Incomplete Records"
                                />
                            </div>
                             <div className='table-records-btn-wrapper'>
                                <div className='table-records-add-studsent'>
                                    <span><i class="fa-solid fa-plus"></i></span>
                                    <p>Add Student</p>
                                </div>
                                <div className='table-records-Scan-studsent'>
                                    <div className='scan-table-image'>
                                        <img src={scan} alt='scan' />
                                    </div>
                                    <p>Scan Table</p>
                                </div>
                                <button type='button'>Update</button>
                             </div>
                        </div>
                        <div className="student-table-container">
                            <table className="student-table">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Student ID</th>
                                        <th>Age</th>
                                        <th>Grade</th>
                                        <th>Email</th>
                                        {/* <th>Contact no.</th> */}
                                        <th>Location</th>
                                        <th></th> {/* Empty header for checkbox */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {studentData.map((student, index) => (
                                        <tr key={index}>
                                            <td>{student.name}</td>
                                            <td>{student.studentId}</td>
                                            <td>{student.age}</td>
                                            <td>{student.grade}</td>
                                            <td>{student.email}</td>
                                            {/* <td>{student.contactNo}</td> */}
                                            <td>{student.location}</td>
                                            <td>
                                                <i class="fa-solid fa-trash-can"></i>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
};

export default IncompleteRecords;
