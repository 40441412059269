import React, { useRef } from "react";
import "cropperjs/dist/cropper.css"; // Import the CSS for cropper.js
import error_image from "../../../../../assets/images/page-not-found.png";
import { useNavigate} from "react-router-dom";

const ErrorPagenotFound = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="error-page-section" id="error-page-not-found-section">
        <div className="error-page-wrapper">
            <div className="error-page-image">
                <img src={error_image} alt="error-page-image" />
            </div>
            <div className="error-page-text">
                <h1>Oops....</h1>
                <span>Page  not found</span>
                <p>This Page doesn`t exist or was removed. We suggest you to headback to homepage.</p>
                <button type="button" onClick={()=> {navigate("/dashboard/home");}}>Back to home</button>
            </div>
        </div>
      </div>
    </>
  );
};

export default ErrorPagenotFound;
