import React from "react";

function ShowTextPopup({ title, data, onClick }) {
  return (
    <>
      <div className="teacherviewcoursedetails-inner-popup-wrappper">
        <div className="teacherviewcoursedetails-inner-popup-folder">
          <h2>{title}</h2>
          <p
            className="Overview-Lesson-description"
            dangerouslySetInnerHTML={{ __html: data }}
          />
          <span className="my-course-close-btn" onClick={onClick}>
            <i className="fa-solid fa-x"></i>
          </span>
        </div>
      </div>
    </>
  );
}

export default ShowTextPopup;
