import React, { useState, useEffect, useRef } from "react";
import { loadStripe } from '@stripe/stripe-js';
import { removeCartData, resetRemoveCart } from '../../state/actions/removeCartAction';
import { Elements, CardNumberElement, CardExpiryElement, CardCvcElement, cardholderName, useStripe, useElements } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from '../../features/auth/AuthContext';
import api from '../../api/api';
import { proceedToPayData, resetProceedToPay } from '../../state/actions/coursePaymentAction';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import {
    cartList,

} from "../../state/actions/cartlistAction";

import DeletePopup from "../../components/DeletePopup/Deletepopup";
import "./SubscriptionPlanPayment.css";
// import { setStudentSubscriptionList, resetstudentSubscriptionplan } from "../../state/actions/setStudentSubscriptionPlanAction";
import { setTeacherSubscriptionList, resetTeacherDetailsPlan } from "../../state/actions/setTeacherSubsciptionPlanAction";
import { ModifyUserSubscriptionPlan, resetModifyUserSubscription } from "../../state/actions/modifySubscriptionAction";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import SavePopUp from "../SavePopUp/SavePopUp";

// const stripePromise = loadStripe(process.env.REACT_APP_PUBLISH_KEY); // Replace with your Stripe publishable key

function CheckoutForm({ selectedPlanPrice, planType, plan, currentPlan, plans, plan_id, current_plan, status, updatesPlan }) {


    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [paymentStatus, setPaymentStatus] = useState('');
    const [paymentSuccessPopup, setpaymentSuccessPopup] = useState(false);
    const [cardholderName, setCardholderName] = useState(''); // State to hold the cardholder's name
    const [isCardNumberValid, setCardNumberValid] = useState(false);
    const [isCardExpiryValid, setCardExpiryValid] = useState(false);
    const [isCardCvcValid, setCardCvcValid] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [item_id, setitem_id] = useState();
    const formRef = useRef(null); // Ref for the form
    const { token } = useAuth();
    const dispatch = useDispatch();
    const removeCart = useSelector((state) => state.removeCartData);
    const [toggle, setToggle] = useState(false)
    const setTeacherSubsciptionPlan = useSelector((state) => state.setTeacherSubsciptionPlan);
    const globalState = useSelector((state) => state.globalState);

    const proceedToPay = useSelector((state) => state.proceedToPay);
    const modifyUserSubscription = useSelector((state) => state.modifyUserSubscription);

    const [togglePopUp, setTogglePopUp] = useState(false)
    const [isOpenPop, setisOpenPop] = useState(false)

    const teacherCurrentPlan = useSelector((state) => state.teacherCurrentPlan);



    const [current_Plan_Id, setCurrent_Plan_Id] = useState()




    useEffect(() => {
        if (teacherCurrentPlan.error === null) {
            // setCurrent_Plan_Id(teacherCurrentPlan?.currentSubscription?.current_plan)
        }
    }, [teacherCurrentPlan])

    const proceedToPaybtn = () => {

        // dispatch(proceedToPayDatat(token));
        // let id = plans ? currentPlan ? plan.plan_id : plan.yearly_plan_id : currentPlan ? plan.monthly_plan_id : plan.plan_id

        // const req = {
        //     plan_type: globalState.role.toLowerCase(),
        //     plan_id: plan_id
        // }
        // dispatch(setTeacherSubscriptionList(req, token))
        // setToggle(true)

        if (current_plan === null) {
            const req = {
                plan_type: globalState.role.toLowerCase(),
                plan_id: plan_id
            }
            dispatch(setTeacherSubscriptionList(req, token))
            setToggle(true)
        } else {
            setTogglePopUp(true)
            const confirmValue = false
            const payload = {
                subscription_id: current_plan,
                new_plan_id: plan_id,
                confirm_upgrade: confirmValue,
                status: 5
                // "subscription_id": current_plan,
                // "new_plan_id": plan_id,
                // "confirm_upgrade": confirmValue,
                // "status": 5
            }

            dispatch(ModifyUserSubscriptionPlan(payload, token))
        }
    };


    useEffect(() => {
        return () => {
            if (current_plan !== null) {
                dispatch(resetModifyUserSubscription());
            }
        };
    }, [])



    useEffect(() => {
        if (setTeacherSubsciptionPlan?.setTeacher?.session_url) {
            if (toggle) {
                // window.open(setTeacherSubsciptionPlan?.setTeacher?.session_url, '_blank');
                window.location.href = setTeacherSubsciptionPlan?.setTeacher?.session_url;


                setToggle(false);
                dispatch(resetTeacherDetailsPlan());
            }
        }
    }, [setTeacherSubsciptionPlan])


    useEffect(() => {
        return () => {
            dispatch(resetTeacherDetailsPlan());
        };
    }, []);

    const paymenPricedata = useSelector((state) => state.cartlist);
    const calculateTotalPrice = () => {
        let totalPrice = 0;
        paymenPricedata.cartList.forEach(item => {
            totalPrice += item.price;
        });
        return totalPrice.toFixed(2); // Format to 2 decimal places
    }

    const getCardElementOptions = () => {
        let fontSize = '16px';
        if (window.innerWidth <= 768) {
            fontSize = '12px';
        }

        return {
            style: {
                base: {
                    fontSize: fontSize,
                    color: '#424770', // Text color
                    '::placeholder': {
                        color: '#808080', // Placeholder text color
                    },
                },
                invalid: {
                    color: '#9e2146', // Color for invalid input
                },
            },
        };
    };

    const CARD_ELEMENT_OPTIONS = getCardElementOptions();

    const [fontSize, setFontSize] = useState('14px'); // Default to mobile view

    useEffect(() => {
        const updateFontSize = () => {
            const screenWidth = window.innerWidth;
            if (screenWidth > 1024) {
                setFontSize('16px'); // Desktop
            } else if (screenWidth > 768) {
                setFontSize('15px'); // Tablet
            } else {
                setFontSize('14px'); // Mobile
            }
        };

        window.addEventListener('resize', updateFontSize);
        updateFontSize(); // Initialize font size

        return () => window.removeEventListener('resize', updateFontSize);
    }, []);

    const handleSubmit = async () => {

        if (!stripe || !elements) {
            setError('Stripe has not loaded yet.');
            return;
        }

        try {
            const cardElement = elements.getElement(CardNumberElement);
            const payload = {
                order_id: proceedToPay.order_id
            };
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                }
            };
            const paymentIntentResponse = await api.post('payments/payment/', payload, config);
            const clientSecret = paymentIntentResponse.data.client_secret

            const result = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: cardElement,
                    billing_details: {
                        name: cardholderName,
                    },
                },
            });

            console.log("OnConfirm")

            if (result.error) {
                setError(result.error.message);
            } else {
                if (result.paymentIntent.status === 'succeeded') {
                    setPaymentStatus('Payment succeeded!');
                    setpaymentSuccessPopup(true);
                }
            }
        } catch (err) {
            setError('An error occurred.');
            console.error(err);
        }
    };

    const handleConfime = () => {


        setTogglePopUp(false)
        const confirmValue = true
        const payload = {
            subscription_id: current_plan,
            new_plan_id: plan_id,
            confirm_upgrade: confirmValue,
            status: 5
            // "subscription_id": current_plan,
            // "new_plan_id": plan_id,
            // "confirm_upgrade": confirmValue,
            // "status": 5
        }

        dispatch(ModifyUserSubscriptionPlan(payload, token))

    }
    const handleCancel = () => {
        setTogglePopUp(false)
    }

    const onClose = () => {
        setisOpenPop(false)
        navigate(`/`);
    }

    useEffect(() => {
        if (modifyUserSubscription.message === "Subscription upgraded and charged successfully") {
            setisOpenPop(true)
        }

    }, [modifyUserSubscription.message])


    useEffect(() => {

        if (proceedToPay.order_id != null) {
            handleSubmit();
        }

    }, [proceedToPay.order_id])

    useEffect(() => {
        if (paymentSuccessPopup) {
            setTimeout(() => {
                dispatch(cartList(token));
                setpaymentSuccessPopup(false)
                navigate(`/`);
            }, 10000)
        }
    }, [paymentSuccessPopup])

    useEffect(() => {
        if (removeCart?.message?.length) {
            toast.success(removeCart?.message);
        }
        if (removeCart?.error?.length) {
            toast.error(removeCart?.error)
        }
        return () => {
            dispatch(resetRemoveCart());
        }
    }, [removeCart]);

    const handleClick = () => {
        if (formRef.current) {
            formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
        }
    };


    const handleChange = (event) => {
        switch (event.elementType) {
            case 'cardNumber':
                setCardNumberValid(event.complete);
                break;
            case 'cardExpiry':
                setCardExpiryValid(event.complete);
                break;
            case 'cardCvc':
                setCardCvcValid(event.complete);
                break;
            default:
                break;
        }
    };

    const isFormValid = () => {
        return isCardNumberValid && isCardExpiryValid && isCardCvcValid && cardholderName.trim() !== '' && paymenPricedata?.cartList.length > 0;
    };

    const show = () => {
        setOpenDialog(false)
        const req = {
            cart_item_id: item_id
        };
        dispatch(removeCartData(req, token));
    };

    const close = (id) => {
        setitem_id(id)
        setOpenDialog(!openDialog); // This will close the popup
    };

    const [selectedPlan, setSelectedPlan] = useState(null);

    const handlePlanSelect = (plan) => {
        setSelectedPlan(plan);
    };


    return (
        <>
            <div className="subscription-select-your-plan-container">
                {/* <h2>Select your Plan</h2> */}
                {/* <div className="subscription-select-your-plan-folder">
                    <div className="subscription-select-your-plan-tab-folder">
                        <div className="subscription-select-your-plan-tab">
                            <input type="radio"/>
                            <div className="subscription-select-your-inner-plan">
                                <span>Paid Monthly</span>
                                <p>$10 / Month</p>
                            </div>
                        </div>
                        <h2>$10</h2>
                    </div>
                    <div className="subscription-select-your-plan-tab-folder">
                        <div className="subscription-select-your-plan-tab">
                            <input type="radio"/>
                            <div className="subscription-select-your-inner-plan">
                                <span>Paid Yearly <span className="subscription-select-your-inner-plan-offer">Save 20%</span></span>
                                <p>$40 X 12 Months</p>
                            </div>
                        </div>
                        <h2>$480</h2>
                    </div>
                </div> */}
                {/* <div className="subscription-select-your-plan-folder">
                    <div
                        className={`subscription-select-your-plan-tab-folder ${selectedPlan === 'monthly' ? 'selected' : ''
                            }`}
                        onClick={() => handlePlanSelect('monthly')}
                    >
                        <div className="subscription-select-your-plan-tab">
                            <input type="radio" checked={selectedPlan === 'monthly'} readOnly />
                            <div className="subscription-select-your-inner-plan">
                                <span>Paid Monthly</span>
                                <p>$10 / Month</p>
                            </div>
                        </div>
                        <h2>$10</h2>
                    </div>
                    <div
                        className={`subscription-select-your-plan-tab-folder ${selectedPlan === 'yearly' ? 'selected' : ''
                            }`}
                        onClick={() => handlePlanSelect('yearly')}
                    >
                        <div className="subscription-select-your-plan-tab">
                            <input type="radio" checked={selectedPlan === 'yearly'} readOnly />
                            <div className="subscription-select-your-inner-plan">
                                <span>
                                    Paid Yearly <span className="subscription-select-your-inner-plan-offer">Save 20%</span>
                                </span>
                                <p>$40 X 12 Months</p>
                            </div>
                        </div>
                        <h2>$480</h2>
                    </div>
                </div> */}
            </div>
            <h2>Billing Summary</h2>
            <div className="paymentStripe-course-container">
                <p>Subscription</p>
                <div className="paymentStripe-course-folder">
                    {/* {paymenPricedata?.cartList?.length === 0 ? <p className="paymentStripe-course-no-courses-found">No Courses Found</p> : paymenPricedata?.cartList?.map((items, index) => (
                        <div key={index} className="paymentStripe-course">
                            <span>{items.name}</span>
                            <span><span onClick={() => close(items.cart_item_id)}><i class="fa-solid fa-x"></i></span> ${items.price}</span>
                        </div>
                    ))} */}
                    <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                        <span style={{ fontSize: '16px', color: '#797979', fontWeight: '400', marginBottom: '14px' }}>{planType} Subscription Plan</span>
                        <span style={{ fontSize: '16px', color: '#797979', fontWeight: '400', marginBottom: '14px' }}>
                            ${selectedPlanPrice}

                        </span>
                    </div>
                </div>
            </div>
            <div className='paymentStrip-price-wrapper'>
                <div className='paymentStrip-price-totle-folder'>
                    <span>Total</span>
                    <span>${selectedPlanPrice}</span>
                </div>
            </div>
            {/* <LoadingComponent /> */}
            {
                (togglePopUp && modifyUserSubscription.message !== null) && <DeletePopup
                    title={modifyUserSubscription.message}
                    cancel="Cancel"
                    submit="Confirm"
                    onclick={handleConfime}
                    onClose={handleCancel} // Pass handleClose as a prop
                />
            }
            {
                togglePopUp ? modifyUserSubscription.message !== "Subscription upgraded and charged successfully" ? <LoadingComponent /> : <button
                    className='payment-proceed-to-pay'
                    onClick={proceedToPaybtn}
                    // disabled={!isFormValid()}
                    style={{
                        backgroundColor: '#0068FF', // Change 'normalBackgroundColor' to your desired color
                        color: '#ffffff', // Optionally set the text color
                    }}
                >
                    Proceed to Pay
                </button> : <button
                    className='payment-proceed-to-pay'
                    onClick={proceedToPaybtn}
                    // disabled={!isFormValid()}
                    style={{
                        backgroundColor: '#0068FF', // Change 'normalBackgroundColor' to your desired color
                        color: '#ffffff', // Optionally set the text color
                    }}
                >
                    Proceed to Pay
                </button>
            }

            <SavePopUp isOpen={isOpenPop} onClose={onClose} text={modifyUserSubscription.message} />

        </>
    );
}

function SubscriptionPlanPaymentBilling({ selectedPlanPrice, planType, plan, currentPlan, plans, plan_id, current_plan, status, updatesPlan }) {

    const globalState = useSelector((state) => state.globalState);


    const stripePromise = loadStripe(globalState.is_test ? process.env.REACT_APP_TEST_PUBLISH_KEY : process.env.REACT_APP_PUBLISH_KEY); // Replace with your Stripe publishable key


    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm selectedPlanPrice={selectedPlanPrice} planType={planType} plan={plan} currentPlan={currentPlan} plans={plans} plan_id={plan_id} current_plan={current_plan} status={status} updatesPlan={updatesPlan} />
        </Elements>
    );
}


export default SubscriptionPlanPaymentBilling;

