import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DownlodeTeacherRevenue {
    downlodeTeacherRevenue: any;
    isLoading: boolean;
    error: string | null;
    message: string | null;
}

const initialState: DownlodeTeacherRevenue = {
    downlodeTeacherRevenue: [],
    message: null,
    isLoading: false,
    error: null,
};

const downlodeTeacherRevenueSlice = createSlice({
    name: 'downlodeTeacherRevenue',
    initialState,
    reducers: {
        setDownlodeTeacherRevenue: (state: DownlodeTeacherRevenue, action: PayloadAction<DownlodeTeacherRevenue>) => {
            state.downlodeTeacherRevenue = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: DownlodeTeacherRevenue, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: DownlodeTeacherRevenue, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetDownlodeTeacherRevenue: () => {
            return initialState;
        },
    },
});

export const { setDownlodeTeacherRevenue, setloading, seterror, resetDownlodeTeacherRevenue } = downlodeTeacherRevenueSlice.actions;

export default downlodeTeacherRevenueSlice.reducer;
