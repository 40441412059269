import React from "react";
import { useNavigate } from "react-router-dom";

import { useState } from "react";
import LinearWithValueLabel from '../../../../../../utils/LinearProgresslabel';
import Dashboardheader from "../../Dashboardheader";
import BackButton from "../../../../../../components/BackButton/BackButton";

const StudentActiveLesson = () => {
  const navigate = useNavigate();
  // const lessons = [
  //   { name: "Lesson 01", description: "Basic structure of neurons" },
  //   { name: "Lesson 02", description: "Basic structure of akdadsasd" },
  // ];
  const [selLession, setSelLession] = useState(-1);

  const onExpand = (i) => {
    setSelLession(selLession === i ? -1 : i);
  }

  const lessons = [
    {
      id: 1,
      title: 'Neuroscience quiz',
      course: 'Fundamental of neurons',
      duration: 60,
    },
    {
      id: 1,
      title: 'Neuroscience quiz',
      course: 'Fundamental of neurons',
      duration: 60,
    },
    {
      id: 1,
      title: 'Neuroscience quiz',
      course: 'Fundamental of neurons',
      duration: 60,
    },
    // Add more lesson objects as needed
  ];
  return (
    <>
      <div id="StudentActiveLesson-section">
        <div className="view-course-Details-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="view-course-Details-back-btn-folder">
                  <BackButton buttonName={"Back"} />
                  {/* <i onClick={() => navigate(-1)} className="fa-solid fa-arrow-left"></i>
                  <h2>Back</h2> */}
                </div>
                <h2 className="student-Active-Lessons">Active Lessons</h2>
                <div className="Student-Active-Lesson-main-container">
                  <div className="Student-Active-Lesson-container">
                    {lessons.map((lesson) => (
                      <div className="Student-Active-Lesson-folder" key={lesson.id}>
                        <div className="Student-Active-Lesson-title-folder">
                          <h2>Lesson {lesson.id}: <span>{lesson.title}</span></h2>
                          {/* <p><span>Course: </span>{lesson.course}</p> */}
                          <span className="Student-Active-Lesson-des"><span>Course: </span>{lesson.course}</span>
                        </div>
                        {/* <p>Duration: <i className="fa-solid fa-clock"></i>Approx <span className="StudentViewCourseDetails-lesson-min">{lesson.duration}</span> min</p> */}
                        {/* <LinearWithValueLabel value={10} width="14%" /> */}
                        <button  className="Student-Active-start-Lesson">Start Lesson</button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="view-course-Details-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="view-course-Details-back-btn-folder">
                  <i onClick={() => navigate(-1)} className="fa-solid fa-arrow-left"></i>
                  <h2>Back</h2>
                </div>
                <h2 className="student-Active-Lessons">Active Lessons</h2>
                <div className="Student-Active-Lesson-container">
                  <div className="Student-Active-Lesson-folder">
                    <div className="Student-Active-Lesson-title-folder">
                      <h2>Assignment <span>01</span>: <span>Neuroscience quiz</span></h2>
                      <p><span>Course: </span>Fundamental of neurons</p>
                    </div>
                    <p>Duration: <i className="fa-solid fa-clock"></i>Approx <span className="StudentViewCourseDetails-lesson-min">60</span> min</p>
                    <LinearWithValueLabel value={10} width="14%" />
                    <button className="Student-Active-start-Lesson">Start Lesson</button>
                  </div>
                  <div className="Student-Active-Lesson-folder">
                    <div className="Student-Active-Lesson-title-folder">
                      <h2>Assignment <span>01</span>: <span>Neuroscience quiz</span></h2>
                      <p><span>Course: </span>Fundamental of neurons</p>
                    </div>
                    <p>Duration: <i className="fa-solid fa-clock"></i>Approx <span className="StudentViewCourseDetails-lesson-min">60</span> min</p>
                    <LinearWithValueLabel value={10} width="14%" />
                    <button className="Student-Active-start-Lesson">Start Lesson</button>
                  </div>
                  <div className="Student-Active-Lesson-folder">
                    <div className="Student-Active-Lesson-title-folder">
                      <h2>Assignment <span>01</span>: <span>Neuroscience quiz</span></h2>
                      <p><span>Course: </span>Fundamental of neurons</p>
                    </div>
                    <p>Duration: <i className="fa-solid fa-clock"></i>Approx <span className="StudentViewCourseDetails-lesson-min">60</span> min</p>
                    <LinearWithValueLabel value={10} width="14%" />
                    <button className="Student-Active-start-Lesson">Start Lesson</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default StudentActiveLesson;
