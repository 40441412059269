import { Dispatch } from "redux";
import api from "../../api/api";
import {
    setfirstLogin, setloading, seterror, resetfirstLogin
} from "../reducers/firstLoginReducer";

const firstLogin = (id: string, data: any) => async (dispatch: Dispatch) => {
   dispatch(setloading(true));
   try {
     const result = await api
       .post(
        `auth/bulkupload/passwordreset/${id}/`,
        data,
        {
          headers: {
            "Content-Type": "application/json"
          },
        }
       )
       
       if(result){
        dispatch(setloading(false));
        if (result.data?.status === 200) {
                dispatch(setfirstLogin(result.data));
          }
          else {
            dispatch(seterror(result.data?.message ?? "Something Went Wrong!"));
          }
       }
   }
   catch (error: any) {
    dispatch(seterror(error.response.data.message));
   }
}


export { firstLogin, resetfirstLogin };

