import React, { useEffect, useState } from "react";
import LessonAndAssingmentTab from "./LessonAndAssingmentTab";
import BackButton from "../BackButton/BackButton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CircularProgress, Grid } from "@mui/material";
import api from "../../api/api";
import { useAuth } from "../../features/auth/AuthContext";
import { updateGlobalState } from "../../state/global/slice";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import "./AssignmentTab.css";
import SortPopup from "../../pages/customSort/SortPopup";
import SearchComponent from "../SearchComponent/SearchComponent";
import { search } from "../../assets/images/images";
import { getAssignmentsByLesson } from "../../state/actions/getAssignmentsByLessonAction";


const AssignmentTab = () => {
  const location = useLocation();

  const navigate = useNavigate();
  const { token } = useAuth();
  const { lesson_id } = useParams();
  const itemsPerPage = 12;
  const [selLession, setSelLession] = useState(-1);
  const [totalPages, setTotalPages] = useState(0);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchOption, setsearchOption] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [sortOption, setSortOption] = useState("");
  const onExpand = (i) => {
    setSelLession(selLession === i ? -1 : i);
  };
  const [lessonsDtls, setLessonsDtls] = useState();
  const inial_value = (currentPage - 1) * itemsPerPage;
  const [deleteCourse, setdeleteCourse] = useState(false);
  const [loading, setLoading] = useState(false);
  const globalState = useSelector((state) => state.globalState);
  const dispatch = useDispatch();
  const updateState = (key, value) => {
    dispatch(updateGlobalState({ key: key, value: value }));
  };

  const [lessonlisting, setlessonlisting] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const teacher_id =
    location.state?.teacher_id ??
    globalState.teacher_details?.data?.teacher_id ??
    "";
  const getLessonDtails = async (page) => {
    setLoading(true);
    document.body.style.overflow = "auto";
    try {
      const response = await api.get(
        `/auth/get-assignment-by-teacher-course/`,
        {
          params: {
            page: page,
          },
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setLoading(false);
        setlessonlisting(response.data.data);
        setTotalPages(
          response.data.count >= 12
            ? Math.ceil(response.data.count / itemsPerPage)
            : Math.floor(response.data.count / itemsPerPage)
        );

        setNextUrl(response.data.next); // Update next URL
        setPrevUrl(response.data.previous); // Update previous URL
      } else {
        setLoading(false);
        toast.error("Something went wrong.", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLessonDtails(currentPage);
  }, [currentPage]);
  const handlePageChange = (event, value) => {
    window.scrollTo(0, 0);
    setCurrentPage(value);
  };
  function formatDate(isoDate) {
    const date = new Date(isoDate);

    // Get individual date components
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" }); // 'Jan', 'Feb', 'Mar' etc.
    const year = date.getFullYear();

    // Get the suffix for the day
    let suffix = "th";
    if (day % 10 === 1 && day !== 11) {
      suffix = "st";
    } else if (day % 10 === 2 && day !== 12) {
      suffix = "nd";
    } else if (day % 10 === 3 && day !== 13) {
      suffix = "rd";
    }

    return `${day}${suffix} ${month}, ${year}`;
  }

  const {
    count,
    next,
    previous,
    error,
    assignments,
    isLoading,
  } = useSelector((state) => state.getassignmentsbyLesson);

  const refreshAssignments = () => {
    dispatch(getAssignmentsByLesson(lesson_id, currentPage, token, searchOption, sortOption));
    localStorage.removeItem("assignmentanalyticsdetails");
  };

  useEffect(() => {
    refreshAssignments();
  }, [currentPage, lesson_id, token, dispatch, searchOption, sortOption]);

  const handleSearch = (e) => {
    setsearchOption(e.target.value);
  };

  const handleSortOptionChange = (newSortOption) => {
    setSortOption(newSortOption);
  };

  return (
    <>
      <div className="assignment-list-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <BackButton buttonName={"Back"} />
              <div className="assignment-list-section">
                <h2 className="student-Active-Lessons">Assignments</h2>
                <div className="search_header-container">
                  {/* <SearchComponent
                    search={search}
                    searchPlaceholder="Search"
                    searchValue="search"
                    onchange={handleSearch}
                    name="searchOption"
                    value={searchOption}
                  /> */}
                  <div className="filter-wrapper">
                    <div
                      className="lessonviewprogress-filder-tab"
                      onClick={() => {
                        setIsVisible(!isVisible);
                      }}
                    >
                      <span>
                        <i className="fa-solid fa-arrow-down-wide-short"></i>
                      </span>
                      <p>Sort</p>
                      {/* <SortPopup
                        isVisible={isVisible}
                        handleApplyClick={() => { }}
                        sortOption={sortOption}
                        onSortOptionChange={handleSortOptionChange}
                      /> */}
                    </div>
                  </div>
                </div>
                <div className="assignment-list-wraper">
                  {loading ?
                    <LoadingComponent
                      loading_text="Educating the Future, Loading Now."
                    /> :
                    lessonlisting.length > 0 ? (
                      lessonlisting.map((lesson, index) => (
                        <LessonAndAssingmentTab
                          type="Assignment"
                          titleHeader={inial_value + index}
                          title={lesson?.name}
                          Coursename={lesson?.course?.name}
                          start_date={formatDate(lesson.start_date)}
                          end_date={
                            lesson.end_date != null ? formatDate(lesson.end_date) : "-"
                          }
                          Progress_btn="Assignment Progress"
                          isAssignmentTab={false} // Add this line
                          course={lesson?.course}
                          lesson={lesson?.lesson}
                          assignment={lesson}
                          teacher_id={teacher_id}
                          refreshAssignments={() => getLessonDtails(currentPage)}
                        />
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5">No Assignment found.</td>
                      </tr>
                    )}
                  <Grid xs={12} container justifyContent={"center"}>
                    <Pagination
                      count={totalPages}
                      page={currentPage}
                      onChange={handlePageChange}
                      color="primary"
                      hideNextButton={
                        nextUrl === null || currentPage === totalPages
                      } // Disable if no next URL and on the last page
                      hidePrevButton={prevUrl === null || currentPage === 1} // Hide if no previous URL and on the first page
                    />
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignmentTab;
