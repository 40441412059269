import React from "react";

function Card({title, description, image}){
    return(
        <>
            <div className="main-default-card-wrapper">
                <div className="created-my-courses-container" id="default-card-wrapper">
                    <div className="created-my-courses-folder">
                        <div className="created-my-courses-folder-image-left-folder">
                            <div className="created-my-courses-folder-image">
                                <img src={image} />
                            </div>
                        </div>
                        <div className="created-my-courses-des-wrapper">
                            <div className="created-my-courses-title-folder">
                                <div className="created-my-courses-title-wrapper">
                                    <h2>{title}</h2>
                                </div>
                            </div>
                            <p className="Students-des">{description}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Card;