import React, { useEffect, useState } from "react";
import { search, user_activity_log_card_image } from "../../assets/images/images";
import "./LiveActivityLog.css";
import BackButton from "../BackButton/BackButton";
import { useDispatch, useSelector } from "react-redux";
import {userActivityLogs, resetuseractivitylogs} from "../../state/actions/userActivityLogsAction"
import { useAuth } from "../../features/auth/AuthContext";
import PaginationView from "../../components/PaginationView/PaginationView";
import LoadingComponent from "../LoadingComponent/LoadingComponent";

function LiveActivityLog() {

  const dispatch = useDispatch()
  const {token} = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const {count, next, previous, totalPages, activity, isLoading} = useSelector((state) => state.userActivityLogs);

  const liveActivity =()=>{
    dispatch(userActivityLogs(currentPage,token))
}

  useEffect(()=>{
    liveActivity();

    return () =>{
        dispatch(resetuseractivitylogs());
    }
  }, [])

  console.log(activity);

  const LiveActivityLogs = [
    {
      user_activity_log_card_image: user_activity_log_card_image,
      title: 'Eleanor Pena',
      time: '6 min ago',
      date: 'Feb-27-2024',
      des: 'Jane Doe (Student) Joined your course (Fundaments of neuroscience).',
    },
    {
        user_activity_log_card_image: user_activity_log_card_image,
        title: 'Eleanor Pena',
        time: '6 min ago',
        date: 'Feb-27-2024',
        des: 'Jane Doe (Student) Joined your course (Fundaments of neuroscience).',
    },
    {
        user_activity_log_card_image: user_activity_log_card_image,
        title: 'Eleanor Pena',
        time: '6 min ago',
        date: 'Feb-27-2024',
        des: 'Jane Doe (Student) Joined your course (Fundaments of neuroscience).',
    },
    {
        user_activity_log_card_image: user_activity_log_card_image,
        title: 'Eleanor Pena',
        time: '6 min ago',
        date: 'Feb-27-2024',
        des: 'Jane Doe (Student) Joined your course (Fundaments of neuroscience).',
    },
    {
        user_activity_log_card_image: user_activity_log_card_image,
        title: 'Eleanor Pena',
        time: '6 min ago',
        date: 'Feb-27-2024',
        des: 'Jane Doe (Student) Joined your course (Fundaments of neuroscience).',
    },
  ];

   // Utility function to format date
   function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
        year: 'numeric',
        month: 'short',
        day: '2-digit'
    }).replace(/ /g, ' '); // Ensuring spaces are correctly placed without extra characters
}

  // Utility function to format time
  function formatTime(dateString) {
    const date = new Date(dateString);
    return date.toLocaleTimeString([], { timeStyle: 'short' }); // Output format example: "12:00 AM"
  }


  return (
    <>
      <div className="live-activity-log-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-1">
              <div className="live-activity-log-wrapper">
                <div className="live-activity-log-container">
                  <BackButton buttonName={"Live Activity Log"}/>
                  <div className="live-activity-log-card-container">
                    {
                      isLoading ? <LoadingComponent
                      loading_text="Educating the Future, Loading Now."
                      /> :
                    activity && activity.length > 0 ?
                    activity.map((item, index) => (
                      <div key={index} className="live-activity-log-card-folder">
                        <div className="live-activity-log-card-image">
                          <img src={item.user_detail.profile_pic} alt="Activity"/>
                        </div>
                        <div className="live-activity-log-card-des-folder">
                          <p className="live-activity-log-card-des">{item.message}</p>
                          <span>{formatTime(item.created_at)}, {formatDate(item.created_at)}</span>
                        </div>
                      </div>
                    )) : <p style={{ textAlign: "center", marginTop: "10px", margin: "0px auto" }}>No History Found</p>
                  }
                  </div> 
                </div>
                {totalPages > 1 && (
                                      <PaginationView
                                          totalPages={totalPages}
                                          next={next}
                                          previous={previous}
                                          currentPage={currentPage}
                                          setCurrentPage={setCurrentPage}
                                      />
                                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LiveActivityLog;
