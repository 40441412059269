import React, { useState, useEffect } from "react";
import { useProfanityFilter } from "../../context/ProfanityFilterContext";

const FilteredTextarea = ({ onChange, value, ...props }) => {
  const [error, setError] = useState("");
  const { loadFilter, clean } = useProfanityFilter();

  // Load the filter when the component mounts
  useEffect(() => {
    loadFilter();
  }, [loadFilter]);

  const handleChange = (event) => {
    const originalValue = event.target.value || ""; // Ensure originalValue is never null/undefined
    const filteredValue = clean(originalValue); // The clean function should also be able to handle an empty string without errors

    if (originalValue !== filteredValue) {
      setError("Profanity is not allowed."); // Set the error message
    } else {
      setError(""); // Clear the error message if there's no profanity
    }

    if (onChange) {
      // Create a new event object to avoid directly mutating the original event
      const modifiedEvent = {
        ...event,
        target: {
          ...event.target,
          value: filteredValue, // Ensure this is always a string
          name: event.target.name,
        },
        preventDefault: event.preventDefault.bind(event),
      };
      onChange(modifiedEvent); // Call the external onChange with the modified event
    }
  };

  const handlePaste = (e) => {
    e.preventDefault(); // Prevent the default paste action
    const pastedText = e.clipboardData.getData("text"); // Get the pasted text
    const filteredText = clean(pastedText); // Filter the pasted text

    const textarea = e.target;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

    // Insert the filtered text where the cursor is, or replace the selected text
    const newValue =
      value.substring(0, start) + filteredText + value.substring(end);

    if (pastedText !== filteredText) {
      setError("Profanity is not allowed."); // Set the error message
    } else {
      setError(""); // Clear the error message if there's no profanity
    }

    // Call the provided onChange handler with the new value
    if (onChange) {
      onChange({
        ...e,
        target: { ...e.target, value: newValue, name: e.target.name },
      });
    }
  };

  return (
    <>
      <textarea
        {...props}
        value={value}
        onChange={handleChange}
        onPaste={handlePaste}
      />
      {error && <p style={{ color: "red" }}>{error}</p>}
    </>
  );
};

export default FilteredTextarea;
