import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface userActivityLogs {
    activity: any;
    isLoading: boolean;
    error: string | null;
    count: number | null;
    next: string | null;
    previous: string | null;
    totalPages: number | null;
  }

  const initialState: userActivityLogs  = {
    activity: [],
    isLoading: false,
    error: null,
    count: 0,
    next: null,
    previous: null,
    totalPages: 0,
  };

  const useractivitylogsslice = createSlice({
    name: "userActivityLogs",
    initialState,
    reducers: {
      setuseractivitylogs: (
        state: userActivityLogs,
        action: PayloadAction<userActivityLogs>
      ) => {
        state.activity = action.payload;
        state.isLoading = false;
        state.error = null;
      },
      setPagination: (state: userActivityLogs, action: PayloadAction<userActivityLogs>) => {
        state.next = action.payload.next;
        state.previous = action.payload.previous;
        state.count = action.payload.count;
        state.totalPages = Math.ceil((action.payload.count ?? 0) / 12);
        state.isLoading = false;
        state.error = null;
    },
      setloading: (state: userActivityLogs, action: PayloadAction<boolean>) => {
        state.isLoading = action.payload;
        state.error = null;
      },
      seterror: (state: userActivityLogs, action: PayloadAction<string>) => {
        state.error = action.payload;
        state.isLoading = false;
      },
      resetuseractivitylogs: () => {
        return initialState;
      },
    },
  });

  export const {
    setuseractivitylogs,
    setPagination,
    setloading,
    seterror,
    resetuseractivitylogs,
  } = useractivitylogsslice.actions;
  
  export default useractivitylogsslice.reducer;
  