import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../features/auth/AuthContext';
import { resetteacherRating, teacherRating } from "../../../state/actions/teacherRatingAction";
import "./TopRatingReview.css";
import TopRatingReviewCard from './TopRatingReviewCard';
import { resetteacherRatingReply } from "../../../state/actions/teacherRatingReplyAction"
import PaginationView from '../../../components/PaginationView/PaginationView';

function TopRatingReview()
{
    const [expanded, setExpanded] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const {id} = useParams();
    const dispatch = useDispatch();
    const {token} = useAuth();
    const teacherRatingT = useSelector((state) => state.teacherRating);
    const teacherRatingReply = useSelector((state) => state.teacherRatingReply);

    const teacherRatings =()=>{
        dispatch(teacherRating(id, token, currentPage))
    }


    useEffect(()=>{
        teacherRatings();

        return () =>{
            dispatch(resetteacherRating());
            dispatch(resetteacherRatingReply());
        }
    }, [teacherRatingReply, currentPage])

// Function to calculate total value
function calculateTotalValue() {
    let total = 0;
    for (let key in teacherRatingT?.rating?.ratings_count) {
        if (teacherRatingT?.rating?.ratings_count.hasOwnProperty(key)) {
            total += parseInt(teacherRatingT?.rating?.ratings_count[key]);
        }
    }
    return total;
}

// Calculate the total value
const totalValue = calculateTotalValue(teacherRatingT?.rating?.ratings_count);
// console.log("Total value:", totalValue);
// console.log(calculateTotalValue());



const containers = document.querySelectorAll(".topratingreview-stars");
const originalSpan = document.createElement("span");
originalSpan.innerHTML = '<i class="fa-solid fa-star"></i>'


const numberOfClones = {teacherRatingT};

containers.forEach(container => {
    for (let i = 0; i < numberOfClones; i++) {
        // Clone the original span element
        const clonedSpan = originalSpan.cloneNode(true);
        
        // Append the cloned span to the container div
        container.appendChild(clonedSpan);
    }
});

// const integerPart = Math.floor(data.rating);
// const decimalPart = data.rating - integerPart;

// // Create an array for the integer part of the rating
// const integerStarsLeft = [...Array(integerPart)].map((_, i) => (
//     <span key={i}><i className="fa-solid fa-star"></i></span>
// ));

// // Create an array for the decimal part of the rating
// let decimalStarLeft = null;
// if (decimalPart > 0) {
//     decimalStar = <span key="decimalStar"><i className="fa-solid fa-star-half"></i></span>;
// }

    return (
        <>  
            <div className='topratingreview-section'>
           <div className='topratingreview-wrapper'>
                    <div className='topratingreview-left-section'>
                        <div className='topratingreview-left-container'>
                            <p className='topratingreview-title'>Student Feedback and Ratings</p>
                            <h2>{teacherRatingT?.rating?.average_rating}</h2>
                            <div className='topratingreview-stars'>
                                {typeof teacherRatingT?.rating?.average_rating === 'number' && !isNaN(teacherRatingT?.rating?.average_rating) && (
                                    <>
                                        {[...Array(Math.floor(teacherRatingT?.rating?.average_rating))].map((_, index) => (
                                            <span key={index}><i className="fa-solid fa-star"></i></span>
                                        ))}
                                        {teacherRatingT?.rating?.average_rating % 1 !== 0 && (
                                            <span><i className="fa-solid fa-star-half-alt"></i></span>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className='rating-numbers'>
                                <p>({totalValue} Ratings)</p>
                            </div>
                            <div className='topratingreview-progress-bar-wrapper'>
                                <div className='topratingreview-progress-bar-folder'>
                                    <p>5 Star</p>
                                    <progress id="ratings_progress_bar" value={(teacherRatingT?.rating?.ratings_count["5"] / totalValue) * 100} max="100" />
                                    <span>{teacherRatingT?.rating?.ratings_count["5"]}</span>
                                    {/* <LinearWithValueLabel value={teacherRatingT?.rating?.ratings_count["1"]} /> */}
                                </div>
                                <div className='topratingreview-progress-bar-folder'>
                                    <p>4 Star</p>
                                    <progress id="ratings_progress_bar" value={(teacherRatingT?.rating?.ratings_count["4"] / totalValue) * 100} max="100" />
                                    <span>{teacherRatingT?.rating?.ratings_count["4"]}</span>
                                </div>
                                <div className='topratingreview-progress-bar-folder'>
                                    <p>3 Star</p>
                                    <progress id="ratings_progress_bar" value={(teacherRatingT?.rating?.ratings_count["3"] / totalValue) * 100} max="100"/>
                                    <span>{teacherRatingT?.rating?.ratings_count["3"]}</span>
                                </div>
                                <div className='topratingreview-progress-bar-folder'>
                                    <p>2 Star</p>
                                    <progress id="ratings_progress_bar" value={(teacherRatingT?.rating?.ratings_count["2"] / totalValue) * 100} max="100" />
                                    <span>{teacherRatingT?.rating?.ratings_count["2"]}</span>
                                </div>
                                <div className='topratingreview-progress-bar-folder'>
                                    <p>1 Star</p>
                                    <progress id="ratings_progress_bar" value={(teacherRatingT?.rating?.ratings_count["1"] / totalValue) * 100} max="100" />
                                    <span>{teacherRatingT?.rating?.ratings_count["1"]}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <TopRatingReviewCard />
                </div>
                {teacherRatingT.totalPages > 1 && (
                    <PaginationView
                        totalPages={teacherRatingT.totalPages}
                        next={teacherRatingT.next}
                        previous={teacherRatingT.previous}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                )}
            </div>
            
        </>
    )
}

export default TopRatingReview;