import React from "react";

function DashboardheaderTab({ dashborad_Courses_book_image, total_courses_number, total_courses, count_background }) {

  return <>
    <div className="dashborad-Courses-folder" style={{ backgroundColor: count_background }}>
        <div className="dashborad-Courses-folder-image">
            <img src={dashborad_Courses_book_image} alt="" />
        </div>
        <div className="dashborad-Courses-count">
            <h2>{total_courses_number}</h2>
            <p>{total_courses}</p>
        </div>
    </div>
  </> 
}

export default DashboardheaderTab;