import React from "react";

function CongratulationsPopup({check_image, des}) {

  return (
    <>
        <div className="Congratulations-Popup-section">
            <div className="Congratulations-Popup-wrapper">
                <div className="Congratulations-Popup-folder">
                    <div className="check-image">
                        <img src={check_image}/>
                    </div>
                    <h2>{des}</h2>
                </div>
            </div>
        </div>
    </>
  );
}

export default CongratulationsPopup;

