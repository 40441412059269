import { Dispatch } from "redux";
import api from "../../api/api";
import {
    setTeacherSubscriptionPlan, setLoadingPlan, setErrorPlan, resetTeacherDetailsPlan
} from "../reducers/setTeacherSubsciptionPlanReducer";

const setTeacherSubscriptionList = (data: any, token: any) => async (dispatch: Dispatch) => {
    dispatch(setLoadingPlan(true));
    try {
        const result = await api.post(
            `/subscriptions/create-checkout-session/`,
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`,
                },
            }
        );
        dispatch(setLoadingPlan(false));
        if (result.data?.status === 200) {
            dispatch(setTeacherSubscriptionPlan(result.data?.data));
        } else {
            dispatch(setErrorPlan(result.data?.status ?? "Something went wrong"));
        }
    } catch (error: any) {
        dispatch(setErrorPlan(error.response?.data?.message || "Something went wrong"));
    }
};

export { setTeacherSubscriptionList, resetTeacherDetailsPlan };
