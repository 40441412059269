import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  studentDashboard_AI_chatbot_image
} from "../../assets/images/images";
import { useAuth } from "../../features/auth/AuthContext";
import { getSentInvitationList, resetInvitationList } from "../../state/actions/sentInvitationListAction";
import BackButton from "../BackButton/BackButton";
import PaginationView from "../PaginationView/PaginationView";
import SearchBar from "../Search/SearchBar"
import { CircularProgress, Grid } from "@mui/material";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import RemovePopup from "../RemovePopup/RemovePopup";
import { toast } from "react-toastify";
import { removeStudentsFromCourse, resetremovestudents } from "../../state/actions/removeStudentsFromCourseAction";
import "./Invitedstudentslist.css";

const Invitedstudentslist = () => {

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sortOption, setSortOption] = useState("");
  const [filterOption, setfilterOption] = useState("");
  const [removeStudentsArray, setremoveStudentsArray] = useState([]);
  const filterArray = [
    { id: "Invited", name: "Invited & Enrolled" },
    { id: "Enrolled", name: "Enrolled" },
    // { id: "Rejected", name: "Rejected" },
    { id: "", name: "All" },
  ]

  const navigate = useNavigate();
  const location = useLocation();
  const { token } = useAuth();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { count,
    next,
    previous,
    totalPages,
    error, invitations, isLoading } = useSelector((state) => state.sentInvitations);
  const removestudentfromcourse = useSelector((state) => state.removestudentfromcourse);

  const getSentInvitationListReq = () => {
    dispatch(getSentInvitationList(id, currentPage, token, search, filterOption, sortOption, location.state.teacher_id))
  }

  const onStudentViewProfile = (id) => {
    navigate(`/student/${id}`);
  }

  const formatDate = (isoTimestamp) => {
    const fixedDateTime = new Date(isoTimestamp);

    // Get individual date components
    const readableFormat = fixedDateTime.toLocaleString('en-US', {
      month: 'short', // Short name of the month
      day: 'numeric', // Numeric day of the month
      year: 'numeric', // Numeric year
    });
    return readableFormat;
  }

  useEffect(() => {
    getSentInvitationListReq();

    return () => {
      dispatch(resetInvitationList());
    }
  }, [currentPage, search, sortOption, filterOption, removestudentfromcourse])

  useEffect(() => {
    if (removestudentfromcourse?.message?.length) {
      toast.success(removestudentfromcourse.message);
      setremoveStudentsArray([]);
      handleClose();
    }
    else if (removestudentfromcourse?.error?.length) {
      toast.error(removestudentfromcourse.error);
      handleClose();
    }

    return () => {
      dispatch(resetremovestudents());
    }
  }, [removestudentfromcourse])

  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

  // Function to close the popup
  const handleClose = () => {
    setIsDeletePopupOpen(false);
  };

  // Function to handle the action after confirmation (optional extra functionality)
  const handleRemoveStudent = () => {

    // const studentArr = removeStudentsArray.map(Number);

    const req = {
      student_emails: removeStudentsArray,
      course_id: parseInt(id)
    }
    dispatch(removeStudentsFromCourse(req, token))
  }

  // Function to open the popup
  const handleOpenPopup = () => {
    setIsDeletePopupOpen(true);
  };

  const handleDeleteCheck = (e) => {
    if (removeStudentsArray.some(element => element === e.target.value)) {
      setremoveStudentsArray(removeStudentsArray.filter(element => element !== e.target.value))
    }
    else {
      setremoveStudentsArray([...removeStudentsArray, e.target.value]);
    }
  }

  const handleDeleteAll = (e) => {
    if (e.target.checked) {
      setremoveStudentsArray(invitations.map(student => student.email));
    }
    else {
      setremoveStudentsArray([]);
    }
  }

  return (
    <>
      <div id="invited-student-list-section">
        <div className="view-course-Details-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="view-course-Details-back-btn-folder">
                  <BackButton buttonName={"Invited students list"} />
                </div>
                <SearchBar
                  search={search}
                  setSearch={setSearch}
                  filterArray={filterArray}
                  sortOption={sortOption}
                  setSortOption={setSortOption}
                  filterOption={filterOption}
                  setfilterOption={setfilterOption} />
                  <div className="invited-student-list-table-wrapper">
                    <div className="list-of-students-wrappper student-request-wrapper">
                      <table>
                        <thead>
                          <tr>
                          <th data-label="Name">
                            <input type="checkbox" value={"all"} onChange={handleDeleteAll} checked={removeStudentsArray.length > 0 && removeStudentsArray.length === invitations.length} />
                          </th>
                            <th>Name</th>
                            <th>Invited On</th>
                            <th>Enrolled On</th>
                            <th>Email</th>
                            <th>Status</th>
                          <th>
                            <button type="button" className="student-remove" onClick={handleOpenPopup} disabled={removeStudentsArray.length === 0}><i class="fa-solid fa-trash"></i> Remove</button>
                          </th>
                          </tr>
                          <div className="list-of-students-gap"></div>
                        </thead>

                        { isLoading ? 
                        <div style={{ display: "table-caption" }}>
                            <LoadingComponent
                              loading_text = "Educating the Future, Loading Now."
                            /> 
                        </div>
                        :
                          invitations && invitations?.length > 0 ? (
                          invitations?.map((invitation, index) => (
                              <>
                              <tbody>
                                <tr key={index}>
                                  <td data-label="Name">
                                    <input value={invitation?.email} type="checkbox" onChange={handleDeleteCheck} checked={removeStudentsArray.some(element => element === invitation?.email)} />
                                  </td>
                                <td
                                  >
                                    <div className="studentrequest-profile">
                                      <div className="studentrequest-profile-image">
                                        <img
                                          src={
                                          invitation?.student_profile_pic ||
                                            studentDashboard_AI_chatbot_image
                                          }
                                          alt="Student"
                                        />
                                      </div>
                                      <div className="studentrequest-profile-name">
                                      {invitation?.student_name}
                                      </div>
                                    </div>
                                  </td>
                                <td>{invitation?.source === "Enrolled" ? '-' : (invitation?.invited_on === null ? "-" : formatDate(invitation?.invited_on))}</td>
                                <td>{invitation?.source === "Enrolled" ? (invitation?.invited_on === null ? "-" : formatDate(invitation?.invited_on)) : (invitation?.enrolled_on === null ? "-" : formatDate(invitation?.enrolled_on))}</td>
                                <td> {invitation?.email}</td>
                                  <td>
                                  <div className={(invitation?.source === "Enrolled" ? "enrolled_bg" : invitation?.source === "Invited" ? "invited_bg" : invitation?.source === "Rejected" ? "rejected_bg" : "") + " LessonViewProgress-student-status"}>
                                    {invitation?.source === "Invited" ? invitation?.source + " & Enrolled" : invitation?.source}
                                    </div>
                                  </td>
                                  <td>
                                    <a
                                      href="#"
                                    // disable = {invitation ? 'false' : 'true'}
                                    // onClick={(e) => {
                                    //   e.preventDefault(); // This prevents the default anchor link behavior
                                    //   onStudentViewProfile(invitation?.student);
                                    // }}
                                    style={{ pointerEvents: invitation?.student_name ? 'auto' : 'none', color: invitation?.student_name ? 'blue' : 'grey' }}
                                      onClick={(e) => {
                                        if (!invitation?.student_name) return; // Do nothing if invitation doesn't exist
                                        e.preventDefault(); // This prevents the default anchor link behavior
                                        onStudentViewProfile(invitation.student);
                                      }}
                                    >
                                      View Profile
                                    </a>
                                  </td>
                                </tr>
                                <div className="blank-div"></div>
                              </tbody>
                              </>
                            ))
                        ) : (

                            <tr>
                              <td colSpan="6">No Invitation Found</td>
                            </tr>
                          )}

                      </table>
                      {totalPages > 1 && (
                        <PaginationView
                          totalPages={totalPages}
                          next={next}
                          previous={previous}
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                        />
                      )}
                    {isDeletePopupOpen && (
                      <RemovePopup
                        title="Are you sure you want to remove this student?"
                        cancel="No"
                        submit="Remove"
                        onclick={handleRemoveStudent}
                        onClose={handleClose}
                      />
                    )}
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Invitedstudentslist;
