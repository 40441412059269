import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { welcome_polly_mage } from '../../assets/images/images';
import AOS from "aos";
import "aos/dist/aos.css";

const Welcome = (props) => {
    const globalState = useSelector((state) => state.globalState);
    const profileFirstName = globalState?.teacher_details?.data?.name;
    const ai_avatar = globalState?.teacher_details?.data?.ai_avatar ?? welcome_polly_mage;

    useEffect(() => {
        AOS.init({
            duration: 500,
        });
    }, []); 

    return (
        <>
            <div className='welcome-polly-popup-wrapper'>
                <div className='welcome-polly-popup-container' data-aos="zoom-in">
                    <div className='welcome-polly-popup-image'>
                        <img src={ai_avatar} alt='' />
                    </div>
                    <h2>Welcome {profileFirstName}!</h2>
                    <p>We're excited to have you on board.
                        Let's start creating your first course!"
                    </p>
                    <span className='welcome-polly-cross-btn' onClick={() => {
                        props.setWelcomePopupStatus(false);
                    }}><i className="fa-solid fa-xmark"></i></span>
                </div>
            </div>
        </>
    )
}

export default Welcome