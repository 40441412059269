import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface School {
    school: any,
    isLoading: boolean;
    error: string | null;
}

const initialState: School = {
    school: {},
    isLoading: false,
    error: null,
};

const schoolDetailsSlice = createSlice({
    name: "schoolDetails",
    initialState,
    reducers: {
        setSchoolDetails: (state: School, action: PayloadAction<School>) => {
            state.school = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: School, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: School, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetSchoolDetails: () => {
            return initialState;
        },
    },
});

export const {
    setSchoolDetails,
    setloading,
    seterror,
    resetSchoolDetails,
} = schoolDetailsSlice.actions;

export default schoolDetailsSlice.reducer;
