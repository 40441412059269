import React from 'react';
import "./MarketPlaceShoppingCart.css"
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

function MarketplacePriceChekoutList({ close }) {
    const navigate= useNavigate();
    const priceData = useSelector((state) => state.cartlist);

    // Calculate total price
    const calculateTotalPrice = () => {
        let totalPrice = 0;
        priceData.cartList.forEach(item => {
            totalPrice += item.discounted_price === null ? item?.price : item?.discounted_price;
        });
        return totalPrice.toFixed(2); // Format to 2 decimal places
    }

    return (
        <>  
           <div className='marketplace-shopping-cart-right-section'>
                <div className='marketplace-price-container'>
                    <div className='marketplace-price-header'>
                        <div className='marketplace-price'>
                            {/* <p>Price ({priceData?.cartList?.length} courses)</p> */}
                            <p>Courses ({priceData?.cartList?.length})</p>
                            {/* <h2 className='price'>${calculateTotalPrice()}</h2> */}
                        </div>
                        <div className='marketplace-course-price-scroller-folder'>
                            {priceData?.cartList?.length === 0 ?  <p>No Courses Found</p> : priceData?.cartList?.map((items, index) => (
                                <div className='marketplace-course-price-scroller'>
                                    <button type='button' onClick={() => navigate(`/course/${items.course_id}/view`, {
                                        state: {
                                            redirectTo: "cart"
                                        }
                                    })}>{items?.name}</button>
                                    <p><span onClick={() => close(items.cart_item_id)}><i class="fa-solid fa-x"></i></span> ${items?.discounted_price === null ? items?.price : items?.discounted_price}</p>
                                </div>
                            ))}
                        </div>
                        {/* <div className='marketplace-inner-price'>
                            <span>Summer Special</span>
                            <span>-$4.99 <i class="fa-solid fa-x"></i></span>
                        </div> */}
                    </div>
                    <div className='marketplace-price'>
                        <p>Total</p>
                        <h2>${calculateTotalPrice()}</h2>
                    </div>
                    {/* <div className='cupen-Applied-btn'>
                        <textarea rows="1" placeholder='Enter Promo Code'/>
                        <button>Apply</button>
                    </div> */}
                    <button className='price-checkout-btn' onClick={() => navigate(`/Checkout`)}
                        style={{
                            backgroundColor: priceData?.cartList.length !== 0 ? '#0068FF' : 'gray', // Change 'normalBackgroundColor' to your desired color
                            color: priceData?.cartList.length !== 0 ? '#ffffff' : 'colorWhenDisabled', // Optionally set the text color
                        }}
                        disabled={priceData?.cartList.length === 0}>Checkout</button>
                </div>
            </div>
        </>
    )
}

export default MarketplacePriceChekoutList;