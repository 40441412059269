import React, { useEffect, useRef, useState } from 'react';
import "./TopRatingReview.css"
import LinearWithValueLabel from '../../../utils/LinearProgresslabel';
import { Modal } from "react-responsive-modal";
import {teacherRating , resetteacherRating} from "../../../state/actions/teacherRatingAction"
import { useDispatch, useSelector } from "react-redux";
import TopRatingCard from './TopRatingCard';

function TopRatingReviewCard() {

    const teacherRatingT = useSelector((state) => state.teacherRating);

// Function to calculate total value
function calculateTotalValue() {
    let total = 0;
    for (let key in teacherRatingT?.rating?.ratings_count) {
        if (teacherRatingT?.rating?.ratings_count.hasOwnProperty(key)) {
            total += parseInt(teacherRatingT?.rating?.ratings_count[key]);
        }
    }
    return total;
}

// Calculate the total value
const totalValue = calculateTotalValue(teacherRatingT?.rating?.ratings_count);
// console.log("Total value:", totalValue);
// console.log(calculateTotalValue());


const containers = document.querySelectorAll(".topratingreview-stars");
const originalSpan = document.createElement("span");
originalSpan.innerHTML = '<i class="fa-solid fa-star"></i>'


const numberOfClones = {teacherRatingT};

containers.forEach(container => {
    for (let i = 0; i < numberOfClones; i++) {
        // Clone the original span element
        const clonedSpan = originalSpan.cloneNode(true);
        
        // Append the cloned span to the container div
        container.appendChild(clonedSpan);
    }
});

// const integerPart = Math.floor(data.rating);
// const decimalPart = data.rating - integerPart;

// // Create an array for the integer part of the rating
// const integerStarsLeft = [...Array(integerPart)].map((_, i) => (
//     <span key={i}><i className="fa-solid fa-star"></i></span>
// ));

// // Create an array for the decimal part of the rating
// let decimalStarLeft = null;
// if (decimalPart > 0) {
//     decimalStar = <span key="decimalStar"><i className="fa-solid fa-star-half"></i></span>;
// }



    // const handleEditClick = () => {
    //     // Show the message wrapper if it's hidden
    //     setShowMessageWrapper(true);
    //     // Focus on the textarea
    //     if (textareaRef.current) {
    //         textareaRef.current.focus();
    //     }
    // };


    
    return (
        <>  
            <div className='topratingreview-right-section'>
                <div className='top-reviews-headre-wrapper'>
                    <h2>Top Reviews</h2>
                    {/* <div className="filter-wrapper">
                        <div className="top-reviews-filter-dropdown">
                            <div className="lessonviewprogress-filder-tab"
                            >
                                <span>
                                    <i className="fa-solid fa-sliders"></i>
                                </span>
                                <p>Filter</p>
                            </div>
                            <div className='topratingreview-filter'>
                                <ul className='topratingreview-filter-wrapper'>
                                    <li><a>None</a></li>
                                    <li><a>1 Star Ratings</a></li>
                                    <li><a>2 Star Ratings</a></li>
                                    <li><a>3 Star Ratings</a></li>
                                    <li><a>4 Star Ratings</a></li>
                                    <li><a>5 Star Ratings</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="top-reviews-Sort-dropdown">
                            <div className="lessonviewprogress-filder-tab"
                            onClick={() => {

                                setIsVisible(!isVisible);
                                setIsVisiblefillter(false);

                            }}
                            >
                                <span>
                                    <i class="fa-solid fa-arrow-down-wide-short"></i>
                                </span>
                                <p>Sort</p>
                            </div>
                            <div className='topratingreview-sortby-filter'>
                                <ul className='topratingreview-filter-wrapper'>
                                    <li><a>None</a></li>
                                    <li><a>Rating : High to Low</a></li>
                                    <li><a>Rating : Low to High</a></li>
                                    <li><a>Oldest First</a></li>
                                    <li><a>Newest First</a></li>
                                </ul>
                            </div>
                        </div>
                    </div> */}
                    </div>
                <div className='topratingreview-card-wrapper'>

                        {teacherRatingT?.rating?.reviews?.map((data, index)=> {
                          // Extract the integer and decimal parts of the rating
                                const integerPart = Math.floor(data.rating);
                                const decimalPart = data.rating - integerPart;

                                // Create an array for the integer part of the rating
                                const integerStars = [...Array(integerPart)].map((_, i) => (
                                    <span key={i}><i className="fa-solid fa-star"></i></span>
                                ));

                                // Create an array for the decimal part of the rating
                                let decimalStar = null;
                                if (decimalPart > 0) {
                                    decimalStar = <span key="decimalStar"><i className="fa-solid fa-star-half"></i></span>;
                                }
                          return (

                                <div  className='topratingreview-card-wrapper'>
                                    <TopRatingCard data={data} index={index} integerStars={integerStars} decimalStar={decimalStar} />
                                </div>
                         );  
                        })}
                </div>
            </div>
            
        </>
    )
}

export default TopRatingReviewCard;