import React from "react";
import "./LessonGenerator.css";
import "react-datetime/css/react-datetime.css";
import { Tooltip } from "react-tooltip";
import Datetime from "react-datetime";
import { ass_img, go_btn, Calimg, promote_suggention_star } from "../../assets/images/images";
import { FormControl, TextField } from "@mui/material";
import LessonGenratorWorkBoardDropdown from "./LessonGenratorWorkBoardDropdown";

function LessonGenratorWorkBoard({ toggleChatBoard }) {

    return (
        <>
            <div className="lesson_generator_body_sec_right_body">
                <div className="lesson_generator_editor_toos-folder">
                    {/* <UpdateActiveWorkbaordPlugin assignmentsCount={assignmentsCount} setAssignmentCounts={setAssignmentCounts} showAssingment={showAssingment}
                            setShowAssingment={setShowAssingment} selectedAssignment={selectedAssignment} setSelectedAssignment={setSelectedAssignment}
                            isPopupVisible={isPopupVisible} setPopupVisible={setPopupVisible} setIsAssignment={setIsAssignment} onChangeAssignmentData={onChangeAssignmentData}
                            setOnChangeAssignmentData={setOnChangeAssignmentData} setAssignmentCountsTwo={setAssignmentCountsTwo} editWorkboardStatues={editWorkboardStatues}
                            setEditWorkboardStatues={setEditWorkboardStatues} assignmentsCountTwo={assignmentsCountTwo} showDropdownRubric={showDropdownRubric}
                            setShowDropdownRubric={setShowDropdownRubric} rubricCount={rubricCount} setRubricCount={setRubricCount} rubricCounttTwo={rubricCounttTwo}
                            setRubricCountTwo={setRubricCountTwo} onChangeRubricData={onChangeRubricData} setOnChangeRubricData={setOnChangeRubricData}
                            selectedRubric={selectedRubric} setSelectedRubric={setSelectedRubric} previesCountAssignment={previesAssignment} setPreviesAssignment={setPreviesAssignment} setPreviesRubric={setPreviesRubric}
                            setFormAssignmentState={setFormAssignmentState} updateFuntion={updateFuntion} setUpdateFuntion={setUpdateFuntion} isRubricConfirmModalData={isRubricConfirmModalData}
                            setIsRubricConfirmModalData={setIsRubricConfirmModalData} afterConfirmation={afterConfirmation} setAfterConfirmation={setAfterConfirmation}
                            isAssingmentConfirmModalData={isAssingmentConfirmModalData} setIsAssingmentConfirmModalData={setIsAssingmentConfirmModalData} /> */}
                    <LessonGenratorWorkBoardDropdown />
                    <div className="all-editer-tools-section">
                        <div>
                            <span>
                                {/* {isCopied ? (
                                        <i className="fa-solid fa-check"></i>
                                    ) : ( */}
                                <i
                                    data-tooltip-id="copy-data-workboard"
                                    // onClick={() =>
                                    //     onCopyClicked({
                                    //         answer: document.querySelector(
                                    //             ".editor-input"
                                    //         ).innerHTML,
                                    //     })
                                    // }
                                    className="fa-regular fa-clipboard"
                                ></i>
                                {/* )} */}
                                <Tooltip
                                    id="copy-data-workboard"
                                    place="top"
                                    content="Copy"
                                    type="light"
                                    effect="float"
                                />
                            </span>
                        </div>
                        <span>
                            <i class="fa-solid fa-share-nodes" data-tooltip-id="Share-data-workboard"></i>
                            <Tooltip
                                id="Share-data-workboard"
                                place="top"
                                content="Share"
                                type="light"
                                effect="float"
                            />
                        </span>
                        <span>
                            <i
                                className="fa-solid fa-download"
                                data-tooltip-id="download-data-workboard"
                            ></i>
                            <Tooltip
                                id="download-data-workboard"
                                place="top"
                                content="Download"
                                type="light"
                                effect="float"
                            />
                        </span>
                        {/* {showDropdown && (
                                <div className="view-course-details-share-form">
                                    <div
                                        className="view-course-details-share-folder"
                                        data-aos="zoom-in"
                                    >
                                        <h2>Share Lesson</h2>
                                        <div className="view-course-details-inner-wrapper">
                                            <p>Share this link via</p>
                                            <div className="view-course-social-links-folder">
                                                {showDropdown && (
                                                    <GoogleDocFile
                                                        nameValue={formState.name}
                                                    />
                                                )}
                                            </div>

                                            <i
                                                // onClick={toggleDropdown}
                                                id="create-lesson-share-canle-btn"
                                                className="fa-solid fa-xmark shocial-cross-btn"
                                            />
                                        </div>
                                    </div>
                                </div>
                            )} */}

                        {/* {showDownloadFile && (
                            <div
                                ref={modalRef}
                                className="Download-File-popup-wrappper"
                                id="create-lesson-downloode-wrapper"
                            >
                                <div
                                    ref={modalContentRef}
                                    className="Download-File-popup-container"
                                    data-aos="zoom-in"
                                >
                                    <div className="download-popup">
                                        <h2>Download File as</h2>
                                        <button
                                            className="close-btn"
                                            onClick={toggledownlodefile}
                                        >
                                            ×
                                        </button>
                                        <div className="input-section">
                                            <label>
                                                File Name *
                                                <input
                                                    type="text"
                                                    name="namefiletodnld"
                                                    placeholder="Enter File Name"
                                                    value={fileNameDownload}
                                                    onChange={handleFileNameChange}
                                                />
                                                {fileNameError && (
                                                    <p style={{ color: "red" }}>
                                                        {fileNameError}
                                                    </p>
                                                )}
                                            </label>
                                        </div>
                                        <div className="button-section">
                                            <button
                                                className="spreadsheet-btn"
                                                onClick={exportToWord}
                                            >
                                                Word File(.doc){" "}
                                                <i
                                                    className="fa-solid fa-file-lines"
                                                    style={{
                                                        color: "#ffffff",
                                                        fontSize: "16px",
                                                    }}
                                                ></i>
                                            </button>
                                            <span>Or</span>
                                            <button
                                                className="pdf-btn"
                                                onClick={downloadAsPdf}
                                            >
                                                PDF (.pdf){" "}
                                                <i
                                                    className="fa-solid fa-file-lines"
                                                    style={{
                                                        color: "#ffffff",
                                                        fontSize: "16px",
                                                    }}
                                                ></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )} */}
                    </div>
                </div>
                <div className="lesson-genrator-workboard-container">
                    <div className="editor-container">
                        <p>Lesson on TestNG
                            Introduction to TestNG
                            TestNG is a testing framework inspired by JUnit and NUnit but introduces new functionalities that make it more powerful and easier to use.
                            TestNG is designed to cover all categories of tests: unit, functional, end-to-end, integration, etc. It is widely used in the Java programming ecosystem
                            for test automation.
                            Lesson on TestNG
                            Introduction to TestNG
                            TestNG is a testing framework inspired by JUnit and NUnit but introduces new functionalities that make it more powerful and easier to use.
                            TestNG is designed to cover all categories of tests: unit, functional, end-to-end, integration, etc. It is widely used in the Java programming ecosystem
                            for test automation.
                            Lesson on TestNG
                            Introduction to TestNG
                            TestNG is a testing framework inspired by JUnit and NUnit but introduces new functionalities that make it more powerful and easier to use.
                            TestNG is designed to cover all categories of tests: unit, functional, end-to-end, integration, etc. It is widely used in the Java programming ecosystem
                            for test automation.
                            Lesson on TestNG
                            Introduction to TestNG
                            TestNG is a testing framework inspired by JUnit and NUnit but introduces new functionalities that make it more powerful and easier to use.
                            TestNG is designed to cover all categories of tests: unit, functional, end-to-end, integration, etc. It is widely used in the Java programming ecosystem
                            for test automation.
                            Lesson on TestNG
                            Introduction to TestNG
                            TestNG is a testing framework inspired by JUnit and NUnit but introduces new functionalities that make it more powerful and easier to use.
                            TestNG is designed to cover all categories of tests: unit, functional, end-to-end, integration, etc. It is widely used in the Java programming ecosystem
                            for test automation.
                            Lesson on TestNG
                            Introduction to TestNG
                            TestNG is a testing framework inspired by JUnit and NUnit but introduces new functionalities that make it more powerful and easier to use.
                            TestNG is designed to cover all categories of tests: unit, functional, end-to-end, integration, etc. It is widely used in the Java programming ecosystem
                            for test automation.
                            Lesson on TestNG
                            Introduction to TestNG
                            TestNG is a testing framework inspired by JUnit and NUnit but introduces new functionalities that make it more powerful and easier to use.
                            TestNG is designed to cover all categories of tests: unit, functional, end-to-end, integration, etc. It is widely used in the Java programming ecosystem
                            for test automation.
                            Lesson on TestNG
                            Introduction to TestNG
                            TestNG is a testing framework inspired by JUnit and NUnit but introduces new functionalities that make it more powerful and easier to use.
                            TestNG is designed to cover all categories of tests: unit, functional, end-to-end, integration, etc. It is widely used in the Java programming ecosystem
                            for test automation.
                            Lesson on TestNG
                            Introduction to TestNG
                            TestNG is a testing framework inspired by JUnit and NUnit but introduces new functionalities that make it more powerful and easier to use.
                            TestNG is designed to cover all categories of tests: unit, functional, end-to-end, integration, etc. It is widely used in the Java programming ecosystem
                            for test automation.
                            Lesson on TestNG
                            Introduction to TestNG
                            TestNG is a testing framework inspired by JUnit and NUnit but introduces new functionalities that make it more powerful and easier to use.
                            TestNG is designed to cover all categories of tests: unit, functional, end-to-end, integration, etc. It is widely used in the Java programming ecosystem
                            for test automation.
                            Lesson on TestNG
                            Introduction to TestNG
                            TestNG is a testing framework inspired by JUnit and NUnit but introduces new functionalities that make it more powerful and easier to use.
                            TestNG is designed to cover all categories of tests: unit, functional, end-to-end, integration, etc. It is widely used in the Java programming ecosystem
                            for test automation.
                            Lesson on TestNG
                            Introduction to TestNG
                            TestNG is a testing framework inspired by JUnit and NUnit but introduces new functionalities that make it more powerful and easier to use.
                            TestNG is designed to cover all categories of tests: unit, functional, end-to-end, integration, etc. It is widely used in the Java programming ecosystem
                            for test automation.
                        </p>
                        {/* <ToolbarPlugin />
                            <div className="editor-inner">
                                <RichTextPlugin
                                    contentEditable={
                                        <ContentEditable className="editor-input" />
                                    }
                                    placeholder={<Placeholder />}
                                />
                                {
                                    activeWorkboard.name === 'Rubric' ?
                                        <OnChangeRubricPlugin onChangeRubricData={onChangeRubricData} rubricCounttTwo={rubricCounttTwo} /> :
                                        <OnChangePlugin />
                                }
                                <HistoryPlugin />
                                <AutoFocusPlugin />
                                <ClearEditorPlugin />
                                <HtmlPlugin
                                    initialHtml={formState?.description || ""}
                                    onHtmlChanged={(e) => { }}
                                />
                                <CodeHighlightPlugin />
                                <ListPlugin />
                                <LinkPlugin />
                                <AutoLinkPlugin />
                                <ListMaxIndentLevelPlugin maxDepth={7} />
                                <MarkdownShortcutPlugin
                                    transformers={TRANSFORMERS}
                                />
                            </div> */}
                    </div>
                    <div className="create-all-btns-container">
                        {/* <button
                                onClick={handleAssinmentRubricSubmit}
                                className="proc_btn_new"
                                disabled={
                                    isAssignment
                                        ? workboards.ASSIGNMENT.data ===
                                        defaultWorkboardContent ||
                                        workboards.ASSIGNMENT.data === "" ||
                                        defaultWorkboardData.ASSIGNMENT?.data ===
                                        workboards.ASSIGNMENT?.data ||
                                        workboards.ASSIGNMENT.data === onChangeAssignmentData[assignmentsCountTwo]
                                        : isRubric
                                            ? workboards.RUBRIC.data ===
                                            defaultWorkboardContent ||
                                            workboards.RUBRIC.data === "" ||
                                            defaultWorkboardData.RUBRIC?.data ===
                                            workboards.RUBRIC?.data ||
                                            workboards.RUBRIC.data === onChangeRubricData[rubricCounttTwo]
                                            : workboards.LESSON.data ===
                                            defaultWorkboardContent ||
                                            workboards.LESSON.data === "" ||
                                            defaultWorkboardData.LESSON?.data ===
                                            workboards.LESSON?.data
                                }
                            >
                                {isAssignment
                                    ? `${defaultWorkboardData.ASSIGNMENT?.data !==
                                        workboards.ASSIGNMENT?.data
                                        ? "Save"
                                        : "Changes Saved"
                                    }`
                                    : isRubric
                                        ? `${defaultWorkboardData.RUBRIC?.data !==
                                            workboards.RUBRIC?.data
                                            ? "Save"
                                            : "Changes Saved"
                                        }`
                                        : `${defaultWorkboardData.LESSON?.data !==
                                            workboards.LESSON?.data
                                            ? "Save"
                                            : "Changes Saved"
                                        }`}
                            </button> */}
                        {/* <button
                                        onClick={(e) => {
                                            navigate(-1);
                                        }}
                                    >
                                        Back to Course
                                    </button> */}
                        {/* {defaultWorkboardData.RUBRIC?.data ===
                                workboards.RUBRIC?.data &&
                                defaultWorkboardData.LESSON?.data ===
                                workboards.LESSON?.data &&
                                defaultWorkboardData.ASSIGNMENT?.data ===
                                workboards.ASSIGNMENT.data && (
                                    <button
                                        onClick={(e) => {
                                            navigate(-1);
                                        }}
                                    >
                                        Back to Course
                                    </button>
                                )} */}
                    </div>
                    <div className="lesson-genrator-workboard-slider" data-tooltip-id="lesson-genrator-workboard-slider" onClick={toggleChatBoard}>
                        <i class="fa-solid fa-caret-right"></i>
                        <Tooltip
                            id="lesson-genrator-workboard-slider"
                            place="top"
                            content="Lesson genrator"
                            type="light"
                            effect="float"
                        />
                    </div>
                    <div className="lesson-genrator-workboard-generate-all-folder">
                        <button><i class="fa-solid fa-plus"></i> Generate All</button>
                        <button><i class="fa-solid fa-plus"></i> Generate Lesson</button>
                    </div>
                </div>
                {/* <button
                        disabled={
                            workboards.LESSON.data === defaultWorkboardContent ||
                            workboards.LESSON.data === ''
                        }
                        onClick={handleForm1Submit}
                        className="proc_btn_new"
                    >
                        Proceed
                    </button> */}
                <div
                    className={`create-Assignment-container-popup show`}
                    style={{ padding: "10px" }}
                >
                    <div className="create-Assignment-container-popup-folder2">
                        <div className="assign_main_box_right_date">
                            <label>Start Date</label>
                            <FormControl
                                margin="none"
                                fullWidth
                            // error={
                            //     !!formErrorAssignmentState.start_date
                            // }
                            >
                                <img
                                    src={Calimg}
                                    className="cal_img_sec_assignment"
                                    alt=""
                                />
                                <Datetime
                                    // isValidDate={
                                    //     isValidDateAssignmentStartDate
                                    // }
                                    // min={minDate}
                                    timeFormat={false}
                                    inputProps={{
                                        placeholder: "MM:DD:YYYY",
                                        readOnly: true,
                                    }}
                                    closeOnSelect={true}
                                    dateFormat="MMM DD, YYYY"
                                    placeholder="E.g. Sep 10, 2023"
                                    name="start_date"
                                    // value={
                                    //     formAssignmentState.start_date
                                    //         ? dayjs(
                                    //             formAssignmentState.start_date
                                    //         ).format("MMM DD, YYYY")
                                    //         : ""
                                    // }
                                    // onChange={(date) =>
                                    //     handleAssignmentInputChangedate(
                                    //         "start_date",
                                    //         date
                                    //     )
                                    // }
                                    className="custom-datetime my-custom-picker"
                                />
                                {/* <FormHelperText sx={{ color: "#d31332" }}>
                                                    {formErrorAssignmentState.start_date}
                                                </FormHelperText> */}
                            </FormControl>
                        </div>
                        <div className="assign_main_box_right_date">
                            <label>Expiry Date</label>
                            <FormControl
                                margin="none"
                                fullWidth
                            // error={
                            //     !!formErrorAssignmentState.start_date
                            // }
                            >
                                <img
                                    src={Calimg}
                                    className="cal_img_sec_assignment"
                                    alt=""
                                />

                                <Datetime
                                    // isValidDate={
                                    //     isValidDateAssignmentEndDate
                                    // }
                                    // min={dayjs(
                                    //     formAssignmentState?.start_date
                                    //         ? formState.start_date
                                    //         : formAssignmentState?.start_date
                                    // )
                                    //     .add(1, "day")
                                    //     .format("MMM DD, YYYY")}
                                    timeFormat={false}
                                    inputProps={{
                                        placeholder: "MM:DD:YYYY",
                                        readOnly: true,
                                    }}
                                    // closeOnSelect={true}
                                    dateFormat="MMM DD, YYYY"
                                    placeholder="E.g. Sep 10, 2023"
                                    name="end_date"
                                    // value={formAssignmentState.end_date === null ? '' : dayjs(formAssignmentState.end_date).format("MMM DD, YYYY")}
                                    // onChange={(date) =>
                                    //     handleAssignmentInputChangedate(
                                    //         "end_date",
                                    //         date
                                    //     )
                                    // }
                                    className="custom-datetime my-custom-picker"
                                // initialViewDate={dayjs(
                                //     formAssignmentState?.start_date ??
                                //     formState.start_date
                                // )
                                //     .add(1, "day")
                                //     .toDate()}
                                />
                                {/* <FormHelperText sx={{ color: "#d31332" }}>
                                                    {formErrorAssignmentState.end_date}
                                                </FormHelperText> */}
                            </FormControl>
                        </div>
                        <div className="Create-Course-inner-input Create-Assignment-dropdown create-Assignment-popup-input-feild">
                            <label>Choose Assignment type</label>
                            <FormControl margin="none">
                                <TextField
                                    name="type"
                                    defaultValue={"ddasas"}
                                    // value={formAssignmentState.type}
                                    placeholder="Select Assignment type"
                                    variant="outlined"
                                    select
                                    size="small"
                                    // error={!!formErrorAssignmentState.type}
                                    // onChange={handleAssignmentInputChange}
                                    InputProps={{
                                        sx: {
                                            borderRadius: "6px",
                                            backgroundColor: "#FFFFFF",
                                            height: "35px",
                                            padding: "0px",
                                        },
                                    }}
                                >
                                    {/* {Object.values(AssignmentType).map(
                                                        (type) => (
                                                            <MenuItem
                                                                key={type.name}
                                                                value={type.value}
                                                            >
                                                                {type.name}
                                                            </MenuItem>
                                                        )
                                                    )} */}
                                </TextField>
                                {/* <FormHelperText sx={{ color: "#d31332" }}>
                                                    {formErrorAssignmentState.type}
                                                </FormHelperText> */}
                            </FormControl>
                        </div>
                        <div className="Create-Course-inner-input Create-Assignment-dropdown">
                            <label>Assignment timer</label>
                            <FormControl margin="none">
                                <div className="time-field">
                                    <button
                                    // onClick={(e) => {
                                    //     e.preventDefault();
                                    //     assignmentDurationChangeTime(-5);
                                    //     setAssignmentDurationCount(assignmentDurationCount > 0 ? assignmentDurationCount - 1 : 0)
                                    // }}
                                    >
                                        -
                                    </button>
                                    <span>
                                        {/* {`${assignmentDuration.minutes
                                                            .toString()
                                                            .padStart(2, "0")} Minute${assignmentDuration.minutes?.toString() >
                                                                1
                                                                ? "s"
                                                                : ""
                                                            }`} */}
                                        00 Minute
                                    </span>
                                    <button
                                    // onClick={(e) => {
                                    //     e.preventDefault();
                                    //     assignmentDurationChangeTime(5);
                                    //     setAssignmentDurationCount(Number(assignmentDurationCount) + 1)
                                    // }}
                                    >
                                        +
                                    </button>
                                </div>

                                {/* <FormHelperText sx={{ color: "#d31332" }}>
                                                    {
                                                        formErrorAssignmentState.duration_minutes
                                                    }
                                                </FormHelperText> */}
                            </FormControl>
                        </div>
                    </div>
                </div>
                <div className="lesson-genrator-workBoard-btn-folder">
                    <button type="button">Save</button>
                    <button type="button">Exit</button>
                </div>
                {/* {loading ? (
                        <div className="loading-spinner-lesson" />
                    ) : lesson_id !== "" ? (
                        <div className="create-all-btns-container">
                            <button
                                onClick={handleAssinmentRubricSubmit}
                                className="proc_btn_new"
                                disabled={
                                    isAssignment
                                        ? workboards.ASSIGNMENT.data ===
                                        defaultWorkboardContent ||
                                        workboards.ASSIGNMENT.data === "" ||
                                        defaultWorkboardData.ASSIGNMENT?.data ===
                                        workboards.ASSIGNMENT?.data ||
                                        workboards.ASSIGNMENT.data === onChangeAssignmentData[assignmentsCountTwo]
                                        : isRubric
                                            ? workboards.RUBRIC.data ===
                                            defaultWorkboardContent ||
                                            workboards.RUBRIC.data === "" ||
                                            defaultWorkboardData.RUBRIC?.data ===
                                            workboards.RUBRIC?.data ||
                                            workboards.RUBRIC.data === onChangeRubricData[rubricCounttTwo]
                                            : workboards.LESSON.data ===
                                            defaultWorkboardContent ||
                                            workboards.LESSON.data === "" ||
                                            defaultWorkboardData.LESSON?.data ===
                                            workboards.LESSON?.data
                                }
                            >
                                {isAssignment
                                    ? `${defaultWorkboardData.ASSIGNMENT?.data !==
                                        workboards.ASSIGNMENT?.data
                                        ? "Save"
                                        : "Changes Saved"
                                    }`
                                    : isRubric
                                        ? `${defaultWorkboardData.RUBRIC?.data !==
                                            workboards.RUBRIC?.data
                                            ? "Save"
                                            : "Changes Saved"
                                        }`
                                        : `${defaultWorkboardData.LESSON?.data !==
                                            workboards.LESSON?.data
                                            ? "Save"
                                            : "Changes Saved"
                                        }`}
                            </button>
                            {defaultWorkboardData.RUBRIC?.data ===
                                workboards.RUBRIC?.data &&
                                defaultWorkboardData.LESSON?.data ===
                                workboards.LESSON?.data &&
                                defaultWorkboardData.ASSIGNMENT?.data ===
                                workboards.ASSIGNMENT.data && (
                                    <button
                                        onClick={(e) => {
                                            navigate(-1);
                                        }}
                                    >
                                        Back to Course
                                    </button>
                                )}
                        </div>
                    ) : (
                        <button
                            disabled={
                                workboards.LESSON.data === defaultWorkboardContent ||
                                workboards.LESSON.data === ''
                            }
                            onClick={handleForm1Submit}
                            className="proc_btn_new"
                        >
                            Proceed
                        </button>
                    )} */}
            </div>
        </>
    );
}

export default LessonGenratorWorkBoard;


