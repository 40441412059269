import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface courseDescriptionEnhancement {
    message: any,
    isLoading: boolean;
    error: string | null;
}

const initialState: courseDescriptionEnhancement = {
    message: null,
    isLoading: false,
    error: null,
};

const coursedescriptionenhancementSlice = createSlice({
    name: "coursedescriptionenhancement",
    initialState,
    reducers: {
        coursedescriptionenhancement: (state: courseDescriptionEnhancement, action: PayloadAction<courseDescriptionEnhancement>) => {
            state.message = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: courseDescriptionEnhancement, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: courseDescriptionEnhancement, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetCourseDescriptionEnhancement: () => {
            return initialState;
        },
    },
});

export const {
    coursedescriptionenhancement,
    setloading,
    seterror,
    resetCourseDescriptionEnhancement,
} = coursedescriptionenhancementSlice.actions;

export default coursedescriptionenhancementSlice.reducer;