import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  teacher_profile,
  student_icon,
  dashborad_Courses_book_image,
} from "../../../../assets/images/images";
import DashboardSummaryCard from './DashboardSummaryCard';
import "./dashboardSummary.css";
// import  { getDashboardCount, resetdashboardcount} from '../../../../state/actions/adminDashboardCountAction';
import { useAuth } from '../../../../features/auth/AuthContext';
import { schoolById } from '../../../../state/actions/getSchoolByIDAction';
import api from "../../../../api/api";
import { updateGlobalState } from "../../../../state/global/slice";

const DashboardSummary = () => {
  const globalState = useSelector((state) => state.globalState);
  const { token } = useAuth();
  const dispatch = useDispatch();
  const updateState = (key, value) => {
    dispatch(updateGlobalState({ key: key, value: value }));
  };
  const schooldetils = useSelector((state) => state.schoolById);
  const dashboardSummary = [
    {
      title: "Total Teachers",
      count: schooldetils?.total_teachers,
      Teacher_profile: teacher_profile,
      redirect_page: "/dashboard/teachers",
    },
    {
      title: "Total Students",
      count: schooldetils?.total_students,
      Teacher_profile: student_icon,
      redirect_page: "/dashboard/students"
    },
    {
      title: "Total Courses",
      count: schooldetils?.total_courses,
      Teacher_profile: dashborad_Courses_book_image,
      redirect_page: "/dashboard/courses"
    },
  ];

  function getDashboardCountRequest() {
    const id = globalState.school_id;
    dispatch(schoolById(id, token));
  }

  useEffect(() => {
 
    getDashboardCountRequest();
  }, [])


  return (
    <>
      <div className='AdminDashboard-summary-tabs'>
        {dashboardSummary.map((teacher, index) => (
          <DashboardSummaryCard
            key={index}
            title={teacher.title}
            count={teacher.count}
            Teacher_profile={teacher.Teacher_profile}
            redirect_page={teacher.redirect_page}
            Teacher_profile2={dashborad_Courses_book_image}
          />
        ))}
      </div>
    </>
  )
}

export default DashboardSummary;