import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Teacher {
    teacher_id:number | null;
    name:string | null;
    uid:string | null;
    email:string | null;
    joined_at:string | null;
    ai_avatar:string | null;
}
interface Student {
    student_id: number | null;
    name: string | null;
    uid: string | null;
    email: string | null;
    joined_at: string | null;
    ai_avatar: string | null;
  }
interface TeacherBySchool {
    teachers:Teacher[];
    students: Student[];
    count:number | null;
    next:string | null;
    previous:string | null;
    isLoading: boolean;
    error: string | null;
    totalPages: number | null;
}

const initialState: TeacherBySchool = {
    teachers:[],
    students: [],
    next: null,
    previous: null,
    count: 0,
    totalPages: 0,
    isLoading: false,
    error: null,
  };
const getTeacherBySchool = createSlice({
    name: 'fetchSchoolData ',
    initialState,
    reducers: {
        setTeacherBySchool: (state: TeacherBySchool, action: PayloadAction<Teacher[]>) => {
            // state.teachers=[];
            state.teachers = action.payload;
            //state.teachers =state.teachers.concat(action.payload);
            // state.teachers = [{...action.payload}];
            state.isLoading = false;
            state.error = null;
        },
        setStudentBySchool: (state, action: PayloadAction<Student[]>) => {
            state.students = action.payload;
        //state.students = state.students.concat(action.payload);
            // state.students = [{...action.payload}];
            state.isLoading = false;
            state.error = null;
        },
        setPagination: (state: TeacherBySchool, action: PayloadAction<TeacherBySchool>) => {
            state.next = action.payload.next;
            state.previous = action.payload.previous;
            state.count = action.payload.count;
            if (action.payload.count !== null) {
                state.totalPages = action.payload.count >= 12 ? Math.ceil(action.payload.count / 12) : Math.floor(action.payload.count / 12);
            } 
            // state.totalPages = action.payload.count !== null || action.payload.count >= 5 ? Math.ceil(action.payload.count / 12) : Math.floor(action.payload.count / 12)
            // state.totalPages = Math.ceil((action.payload.count ?? 0) / 12);
            state.isLoading = false;
            state.error = null;
        },  
        setloading: (state: TeacherBySchool, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null; 
        },
        seterror: (state : TeacherBySchool, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetAllTeacherBySchool: () => {
            return initialState;
        },
    },
});

export const { setTeacherBySchool,setStudentBySchool,setPagination, setloading, seterror, resetAllTeacherBySchool } = getTeacherBySchool.actions;

export default getTeacherBySchool.reducer;
