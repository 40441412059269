import React, { useState } from "react";
import "./RatingPopup.css";

const RatingPopup = ({
  inner_title,
  des,
  rating_starts_title,
  rating_button,
  background,
  handleSubmitRatingClick,
  lessonRating,
  setLessonRating,
  lessonRatingComment,
  setLessonRatingComment,
}) => {
  // Handle the click on a star
  const handleStarClick = (index) => {
    setLessonRating(index);
  };

  // Render stars dynamically based on the rating
  const renderStars = () => {
    let stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <i
          key={i}
          className={`fa-solid fa-star ${i <= lessonRating ? "golden" : ""}`}
          onClick={() => handleStarClick(i)}
        ></i>
      );
    }
    return stars;
  };

  return (
    <>
      <div className="ratingPopup-section">
        <div className="ratingPopup-wrapper">
          <div className="ratingPopup-contaier">
            <div className="ratingPopup-header">
              <span>{inner_title}</span>
              <p>{des}</p>
            </div>
            <div className="ratingPopup-folder">
              <span>{rating_starts_title}</span>
              <div className="ratingPopup-stars-folder">{renderStars()}</div>
              <textarea
                className="ratingPopup-des"
                placeholder="Give us your thoughts on the lesson! What did you enjoy and how can we improve"
                style={{ background: background }}
                onChange={(e) => {
                  e.preventDefault();
                  setLessonRatingComment(e.target.value);
                }}
              >
                {lessonRatingComment}
              </textarea>
              <button
                type="button"
                className="ratingPopup_btn"
                onClick={handleSubmitRatingClick}
              >
                {rating_button}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RatingPopup;
