import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import Filter from "./Filter";
import Sort from "./Sort";
import { search as srch } from "../../assets/images/images";

const SearchBar = ({ search, setSearch, filterArray, sortOption, setSortOption, filterOption, setfilterOption }) => {

  const [isVisible, setIsVisible] = useState(false);

  const [isVisiblefillter, setIsVisiblefillter] = useState(false);

  const sortArray = [
    { id: "A-Z", name: "A-Z" },
    { id: "Z-A", name: "Z-A" },
    { id: "Newest", name: "Newest first" },
    { id: "Oldest", name: "Oldest First" },
  ]


  const handleApplyClick = () => {
  };

  const handleSortOptionChange = (newSortOption) => {
    setSortOption(newSortOption);
  };

  return (
    <>
      <div className="search_header-container">
        <div className="search-list">
          <ReactSVG className="search-icon" src={srch} />
          <input type="text" className="search-input" placeholder="Search" name="search" value={search} onChange={(e) => setSearch(e.target.value)} />
        </div>
        <div className="filter-wrapper">
          <div className="lessonviewprogress-filder-tab"

          >
            <p onClick={() => {
              setIsVisiblefillter(!isVisiblefillter); setIsVisible(false);
            }}><i className="fa-solid fa-sliders" style={{ paddingRight: "10px" }}></i>Filter</p>
            {isVisiblefillter && <Filter filterArray={filterArray} filterOption={filterOption} setfilterOption={setfilterOption} setIsVisiblefillter={setIsVisiblefillter} />}
          </div>
          <div className="lessonviewprogress-filder-tab"
          >
            <p onClick={() => {
              setIsVisible(!isVisible); setIsVisiblefillter(false);
            }}><span>
              {/* <img src={srot}/> */}
              <i class="fa-solid fa-arrow-down-wide-short"></i>
            </span>Sort</p>
            {isVisible && <Sort sortArray={sortArray} sortOption={sortOption} setSortOption={setSortOption} setIsVisible={setIsVisible} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchBar;