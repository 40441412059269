import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface School {
  school_id: string;
  uid: string;
  name: string;
  location: string;
  description: string;
  joined_at: string;
  image: null | string; // It can be either null or a string
}

interface SchoolState {
  schools: School[];
  next: string | null;
  previous: string | null;
  count: number | null;
  totalPages: number | null;
  isLoading: boolean;
  error: string | null;
}

const initialState: SchoolState = {
  schools: [],
  next: null,
  previous: null,
  count: 0,
  totalPages: 0,
  isLoading: false,
  error: null,
};

const schoolListSlice = createSlice({
  name: "schoolList",
  initialState,
  reducers: {
    setSchoolList: (state: SchoolState, action: PayloadAction<School[]>) => {
      state.schools = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    setPagination: (state: SchoolState, action: PayloadAction<SchoolState>) => {
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.count = action.payload.count;
      state.totalPages = Math.ceil((action.payload.count ?? 0) / 12);
      state.isLoading = false;
      state.error = null;
    },
    setloading: (state: SchoolState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
      state.error = null;
    },
    seterror: (state: SchoolState, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    resetSchoolList: () => {
      return initialState;
    },
  },
});

export const {
  setSchoolList,
  setloading,
  seterror,
  setPagination,
  resetSchoolList,
} = schoolListSlice.actions;

export default schoolListSlice.reducer;
