import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface GlobalState {
    [key: string]: any;
}

const initialState: GlobalState = {
    role: undefined,
    email: undefined,
    school_id: null,
};

const globalStateSlice = createSlice({
    name: 'globalState',
    initialState,
    reducers: {
        updateGlobalState: (state, action: PayloadAction<{ key: string; value: any }>) => {
            state[action.payload.key] = action.payload.value;
        },
        resetGlobalState: () => {
            return initialState;
        },
    },
});

export const { updateGlobalState, resetGlobalState } = globalStateSlice.actions;

export default globalStateSlice.reducer;
