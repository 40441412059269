import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../features/auth/AuthContext';
import { useCustomNavigation } from '../../../hooks/useCustomNavigation';
import { getStudentDetails, resetstudentDetails } from "../../../state/actions/getStudentByStudentIdAction";
import { updateGlobalState } from "../../../state/global/slice";
import CustomTabs from '../../CustomTabs/CustomTabs';
import EnrolledCourses from './EnrolledCourses';
import StudentProfile from './StudentProfile';

function StudentDetails() {

    const [isStudentDetailsLoaded, setisStudentDetailsLoaded] = useState(false);
    const getstudentbystudentId = useSelector((state) => state.getstudentByStudentId);

    const tabList = [
        {
            title: getstudentbystudentId?.student?.data?.name + " Profile",
            displayItem: <StudentProfile />,
            // displayItem: <Profile />,
            key: "Student Profile", // Add the title property
            route: "profile",
        },
        {
            title: "Courses",
            displayItem: <EnrolledCourses />,
            key: "Courses Enrolled", // Add the title property
            route: "courses",
        },
    ];


    const { navigateBackOrDashboard } = useCustomNavigation();
    const { token } = useAuth();
    const navigate = useNavigate();
    const { student_id } = useParams();
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(tabList[0].key);

    const updateState = (key, value) => {
        dispatch(updateGlobalState({ key: key, value: value }));
    };

    const getStudentDetailsRequest = () => {
        dispatch(getStudentDetails(student_id, token))
    }

    useEffect(() => {
        getStudentDetailsRequest();
    }, [student_id])

    useEffect(() => {
        if (Object.keys(getstudentbystudentId?.student).length > 0) {
            const studentdata = getstudentbystudentId?.student ?? {};
            // Update the state
            updateState("student_details", studentdata);
            setisStudentDetailsLoaded(true)
        }
    }, [getstudentbystudentId?.student])

    useEffect(() => {
        return () => {
            dispatch(resetstudentDetails());
        }
    }, [])

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12 col-md-12">
                    <div
                        className="view-course-Details-back-btn-folder"
                        onClick={navigateBackOrDashboard}
                    >
                        <i
                            // onClick={() => navigate(-1)}
                            class="fa-solid fa-chevron-left"
                        ></i>

                        <h2>Back</h2>
                    </div>
                    {/* <BackButton buttonName={"Back"} /> */}
                    {/* <div className="studentDashboardSidePannel Custom_Tabs_section">
                        {tabList.map((tab) => (
                            <Link
                                key={tab.key}
                                to={`${tab.route.toLowerCase()}`}
                                className={`tab-link ${activeTab === tab.key ? 'active' : ''}`}
                                onClick={(e) => {
                                    e.preventDefault(); // Prevent the default link behavior
                                    setActiveTab(tab.key);
                                }}
                            >{tab.title}
                            </Link>
                        ))}
                    </div>
                    <div className="studentDashboardMainBody">
                        <div className='created-my-courses-container'>
                            <Routes>
                                {tabList.map((tab) => (
                                    <Route
                                        key={tab.key}
                                        path={tab.route}
                                        element={tab.displayItem}
                                    />
                                ))}
                            </Routes>
                        </div>
                    </div> */}
                    {isStudentDetailsLoaded && <CustomTabs tabs={tabList} url={`/student-detail/${student_id}`} urlpath="/profile"/>}
                </div>
            </div>
        </div>
    )
}

export default StudentDetails