// CropProfileImageModal.js
import React from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import "../../styles/profile-image-popup.css";
import CropperComponent from "./Cropper";
import {
  useMediaQuery,
  useTheme,
} from "@mui/material";

const CropProfileImageModal = (props) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const customModalStyle ={

    modal: {
      background: "#F5F9FF",
      borderRadius: "10px",
      // Base style for larger screens
      width: isSmallScreen ? "70%" : "560px", 
    },
  };

    // Adjust the modal close to take a parameter indicating if changes should be saved
    const handleModalClose = (saveChanges) => {
      props.setModalClose(saveChanges); // true for save, false for cancel
    };
    
  return (
    <Modal
      open={props.modalStatus}
      // onClose={props.setModalClose}
      onClose={() => handleModalClose(false)} 
      center
      styles={customModalStyle}
    >
      <div className="crop_modal_sec_new">
        <h6>Upload Profile Picture</h6>
        <div className="forget_modal_sec_new_body_sec">
          <CropperComponent
            src={props.src}
            setSrc={props.setSrc}
            setSelectedImage={props.setSelectedImage}
            setImageUploaded={props.setImageUploaded}
            setUplodedImage={props.setUplodedImage}
            modalClose={props.setModalClose}
            setFormState={props.setFormState}
            formState={props.formState}
            fieldName={props.fieldName} // Dynamic field name
            isSmallScreen={isSmallScreen}
            aspectRatio={props.aspectRatio}
            onSave={() => handleModalClose(true)}  // Pass true when saving
            onCancel={() => handleModalClose(false)}  // Pass false when canceling
          />
        </div>
      </div>
    </Modal>
  );
};

export default CropProfileImageModal;
