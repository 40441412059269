import React, { useEffect } from "react";
import { useLocation, useNavigate,useParams } from "react-router-dom";
import api from "../../../../../../api/api";
import {
  LessonAnalytics_massege_image,
  student_grade_flage
} from "../../../../../../assets/images/images";

import { useState } from "react";
import { useAuth } from "../../../../../../features/auth/AuthContext";
import Helper from '../../../../../../utils/Helper';
import BackButton from "../../../../../../components/BackButton/BackButton";
import { CircularProgress, Grid } from "@mui/material";
import LoadingComponent from "../../../../../../components/LoadingComponent/LoadingComponent";

const LessonAnalytics = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let { course_id } = useParams();
  const { token } = useAuth();
  const [isLoading, setisLoding] = useState(null);
  const [analyticHeadData, setAnalyticHeadData] = useState(null);
  const [analyticDetailsData, setAnalyticDetailsData] = useState(null);
  const [errorData, setErrorData] = useState({
    error: false,
    errormsg: "",
  });
  const locationState = location?.state;
  const getLessionAssignmentsAnalytics = async () => {
    setisLoding(true);
    let analyticId = locationState?.analytic_id;
    api.get(`/lessons/get-lesson-analytics/` + analyticId + `/?teacher_id=${locationState.teacher_id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization:  `Token ${token}`,
      },
    }).then((response) => {
      if (response.status === 200) {
        setisLoding(false);
        if(response.data?.data){
          setAnalyticHeadData(response?.data?.data);
        }else{
          setErrorData({
            error: true,
            errormsg: response?.message?.message ?? 'No Record Found'
          });
        }
      }else{
        setErrorData({
          error: true,
          errormsg: response?.message?.message ?? 'Something went wrong'
        });
      }
    }).catch((error) => {
      setErrorData({
        error: true,
        errormsg: error
      });
    });
  }
  const getLessionAssignmentsAnalyticsDetails = async () => {
    let analyticId = locationState?.analytic_id;
    let lessonId = locationState?.lesson?.lesson_id;
    api.get(`/time_trackers/list/?student_id=`+analyticId+`&lesson_id=`+lessonId,{
      headers: {
        "Content-Type": "application/json",
        Authorization:  `Token ${token}`,
      },
    }).then((response) => {
      if (response.status === 200) {
        if(response.data.data.length>0){
          setAnalyticDetailsData(response?.data?.data);
        }
      }
    }).catch((error) => {
      console.log(error);
    });
  }
  useEffect(() => {
    if(locationState?.analytic_id){
      getLessionAssignmentsAnalytics();
      getLessionAssignmentsAnalyticsDetails();
    }
  }, []);
  let totalLessonDurationInSeconds = 0;
  let totalLessonCompletedInSeconds = 0;
  if(analyticDetailsData?.length>0){
    analyticDetailsData.forEach(entry => {
      const requiredDurationInSeconds = parseTimeToSeconds(entry.time_required);
      const takenDurationInSeconds = parseTimeToSeconds(entry.time_taken);
  
      totalLessonDurationInSeconds += requiredDurationInSeconds;
      totalLessonCompletedInSeconds += takenDurationInSeconds;
    });
  }
  const parseTimeToSeconds = (time) => {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }

  const onStudentViewProfile = (student_id) => {
    navigate(`/student/${student_id}`);
  };
  return (
    <>
      <div id="LessonAnalytics-section">
        <div className="LessonAnalytics-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="view-course-Details-back-btn-folder">
                  <BackButton buttonName={"Lesson Analytics"} />
                  {/* <i
                    onClick={() => navigate(-1)}
                    className="fa-solid fa-arrow-left"
                  ></i>
                  <h2>Lesson Analytics</h2> */}
                </div>
                <p>
                  {/* Lesson <span>01</span>: Neurons: The Building Blocks of the
                  Nervous System */}
                  {location?.state?.lesson?.name }
                </p>
                <div className="LessonAnalytics-profile-container">
                  <div className="LessonAnalytics-profile">
                    <div className="LessonAnalytics-profile-image">
                      <img src={analyticHeadData?.student_profile_pic} alt="" />
                    </div>
                    <h2>{analyticHeadData?.student_name}</h2>
                    <a href="#"
                      onClick={(e) => {
                        e.preventDefault(); // This prevents the default anchor link behavior
                        onStudentViewProfile(analyticHeadData?.student_id);
                      }}
                    >View Profile</a>
                  </div>
                  <div className="LessonAnalytics-profile-folder">
                    <p>Lesson Duration</p>
                    <h2>
                      <span>{Helper.formatSecondsToDuration(totalLessonDurationInSeconds)}</span> 
                    </h2>
                  </div>
                  <div className="LessonAnalytics-profile-folder">
                    <p>Lesson Started At</p>
                    <h2>
                      {/* <span>60</span> min */}
                      {Helper.formatDateTime(analyticHeadData?.started_at).time}
                    </h2>
                    <span className="lessonanalystics-date">{Helper.formatDateTime(analyticHeadData?.started_at).date}</span>
                  </div>
                  <div className="LessonAnalytics-profile-folder">
                    <p>Lesson Ended At</p>
                    <h2>
                      {
                      //check if completed_at is null then show 'in progress' else show date
                        analyticHeadData?.completed_at ?  Helper.formatDateTime(analyticHeadData?.completed_at).time : <span className="lessonanalystics-date">_ _</span>
                      }
                    </h2>
                      {
                        analyticHeadData?.completed_at ?  <span className="lessonanalystics-date">{Helper.formatDateTime(analyticHeadData?.completed_at).date}</span> : <span className="lessonanalystics-date">_ _</span>
                      }
                  </div>
                  <div className="LessonAnalytics-profile-folder">
                    <p>Lesson completed in</p>
                    <h2>
                      <span>{Helper.formatSecondsToDuration(totalLessonCompletedInSeconds)}</span>
                    </h2>
                  </div>
                  
                    <div className="LessonAnalytics-profile-massege-image" onClick={(e) => {
                            e.preventDefault();
                            navigate(`/dashboard/messageschat/`, {
                              state: {
                                studentName: analyticHeadData.student_name, // Passing student name
                                studentProfile: analyticHeadData.student_profile_pic,
                                studentId : analyticHeadData.student_user_id,
                                teacherId : analyticHeadData.teacher_user_id
                              }
                            });
                          }}>
                        <a>
                          <img src={LessonAnalytics_massege_image} alt="" />
                        </a>
                    </div>
                  
                </div>
                <div className="LessonViewProgress-table-container LessonAnalytics-table-container">
                  <table>
                    <thead>
                      <tr>
                        <th>Sr no.</th>
                        <th>Topic Name</th>
                        <th>Reading time</th>
                        <th>Reading time</th>
                      </tr>
                    </thead>

                      { isLoading ? 
                      <div style={{ display: "table-caption" }}>
                        <LoadingComponent
                          loading_text = "Educating the Future, Loading Now."
                        />
                      </div>
                      : 
                        errorData.error===true ?
                        (
                          <tr>
                            <td colSpan="4" className="tex-center">{errorData.errormsg}</td>
                          </tr>
                        ) : (
                          <>
                            {
                              analyticDetailsData && analyticDetailsData.length !== 0 ?
                                <tbody>
                                  {analyticDetailsData?.map((student, index) => (

                                <tr key={index}>
                                  <td>{index+1}</td>
                                  <td>
                                    <span>{student.question}</span>
                                  </td>
                                  <td>{student.time_required}</td>
                                  <td>
                                    <div className="hover-wrapper">
                                      <div className="completedat-time">
                                        <span>{student.time_taken}</span>
                                        {/* <img src={student_grade_flage} alt="" /> */}
                                      </div>
                                      <div className="completed-lesson-inner-popup">
                                        <p>
                                          Student completed the lesson way sooner than
                                          the assigned reading time
                                        </p>
                                      </div>
                                    </div>
                                  </td>
                                </tr>

                              ))}
                                </tbody>
                                :
                                <div style={{ display: "table-caption" }}>
                                  <p style={{ textAlign: "center", margin: "20px auto" }}>No Lesson Analytics Found</p>
                                </div>
                            }
                          </>
                        )
                    }
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LessonAnalytics;
