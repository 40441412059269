import { Dispatch } from "redux";
import api from "../../api/api";
import {
    setRemoveCart, setloading, seterror, resetRemoveCart
} from "../reducers/removeCartReducer";

const removeCartData = (data: any, token: any) => async (dispatch: Dispatch) => {
    dispatch(setloading(true));
    try {
            const result = await api.delete(`orders/cart/remove-from-cart/`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`
                },
                data  // Include the data here, within the configuration object
            });
        if (result) {
            dispatch(setloading(false));
            if (result.data?.status === 200) {
                dispatch(setRemoveCart(result.data));
            }
            else {
                dispatch(seterror(result.data?.message ?? "Something Went Wrong!"));
            }
        }
    }
    catch (error: any) {
        dispatch(seterror(error.response.data.message));
    }
}

export { removeCartData, resetRemoveCart }

