import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../features/auth/AuthContext';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCourseList, resetgetAllCoursesList } from "../../../state/actions/getAllCoursesAction";
import CustomTabs from '../../../components/CustomTabs/CustomTabs';
import CoursesList from './CoursesList';
import { addToHistory } from '../../../state/reducers/historyReducer';

function AllCoursesDashboard() {

    const [currentPage, setCurrentPage] = useState(1);

    const tabList = [
        {
            title: "Courses",
            displayItem: <CoursesList currentPage={currentPage} setCurrentPage={setCurrentPage} />,
            key: "school", // Add the title property
        },
        // {
        //     title: "Sales Courses",
        //     displayItem: <CoursesList currentPage={currentPage} setCurrentPage={setCurrentPage} />,
        //     key: "sales",
        // },
        // {
        //     title: "Individual Courses",
        //     displayItem: <CoursesList currentPage={currentPage} setCurrentPage={setCurrentPage} />,
        //     key: "individual",
        // },
        {
            title: "Expired Courses",
            displayItem: <CoursesList currentPage={currentPage} setCurrentPage={setCurrentPage} />,
            key: "expired",
        },
    ];

    const [activeTab, setActiveTab] = useState(tabList[0].key);
    const { token } = useAuth();
    const dispatch = useDispatch();
    const { role } = useSelector((state) => state.globalState);
    const { courses, count, next, previous, totalPages, error } = useSelector(
        (state) => state.getCoursesbySchoolId
    );
    const globalState = useSelector((state) => state.globalState);
    // const token = "e8e92304ad0b1e4370334cb4940e912b76eda727";
    const school_id = globalState.school_id;

    const getCourseListRequest = () => {
        dispatch(getAllCourseList(token, activeTab, currentPage));
    };

    useEffect(() => {
        getCourseListRequest();
        return () => {
            dispatch(resetgetAllCoursesList());
        };
    }, [activeTab, currentPage]);

    useEffect(() => {
        setCurrentPage(1);
    }, [activeTab]);

    useEffect(() => {
        dispatch(addToHistory('/dashboard/courses'))
    }, [])

    useEffect(() => {
        return () => {
            dispatch(resetgetAllCoursesList());
        };
    }, []);

    const renderActiveTabContent = () => {
        const activeTabData = tabList.find((tab) => tab.key === activeTab);
        // console.log(activeTabData)
        return activeTabData ? activeTabData.displayItem : null;
    };

    return (

        <div className="row">
            <div className="col-lg-12 col-md-12">
                <div className="studentDashboardSidePannel Custom_Tabs_section">
                    {tabList.map((tab) => (
                        <button
                            key={tab.key}
                            //to={`/dashboard/${tab.route.toLowerCase()}`}
                            className={`tab-link ${activeTab === tab.key ? 'active' : ''}`}
                            onClick={(e) => {
                                e.preventDefault(); // Prevent the default link behavior
                                setActiveTab(tab.key);
                            }}
                        >{tab.title}
                            {/* <Tab isActive={activeTab === tab.key}>
            {tab.key}
          </Tab> */}
                        </button>
                    ))}
                </div>
                <div className="studentDashboardMainBody">
                    <div className='created-my-courses-container'>
                        {renderActiveTabContent()}
                    </div>
                </div>
                {/* <CustomTabs tabs={tabList} /> */}
            </div>
        </div>
    )
}

export default AllCoursesDashboard