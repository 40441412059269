import React from "react";
import "./dashboardSummary.css";
import { useNavigate } from "react-router-dom";

const DashboardSummaryCard = ({ Teacher_profile, count, title, redirect_page }) => {

  const navigate = useNavigate();

  return (
    <>  
      <div className="studentDashboar-actives-folder" onClick={() => { navigate(redirect_page) }}>
            <div className="studentDashboar-actives-image">
                <img src={Teacher_profile} alt="" />
            </div>
            <div className="studentDashboar-actives-course-count">
                <h2>{count}</h2>
                <p>{title}</p>
            </div>
        </div>
    </>
  );
};
export default DashboardSummaryCard;
