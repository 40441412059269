import React from "react";

const DownlodeFile = ({ Word_File, pdf_File, Or, onClose, Onclick_Word_File, Onclick_Pdf_File, onChange, fileName, rename_file = true }) => {
    
    return (
      <>
        <div className="Download-File-popup-wrappper" id="create-lesson-downloode-wrapper">
            <div className="Download-File-popup-container">
                <div className="download-popup">
                    <h2>Download File as</h2>
                    <button className="close-btn" onClick={onClose}>×</button>
                        {rename_file && <div className="input-section">
                        <label>File Name *<input
                            type="text"
                            name="namefiletodnld"
                            placeholder="Enter File Name"
                            onChange={onChange}
                            value={fileName}
                        />
                        </label>
                        </div>}
                    <div className="button-section">
                        {pdf_File.length > 0 && <button className="pdf-btn" onClick={Onclick_Pdf_File}>{pdf_File}
                            <i
                                className="fa-solid fa-file-lines"
                                style={{
                                color: "#ffffff",
                                fontSize: "16px",
                                }}
                            ></i>
                        </button>}
                        {Or.length > 0 &&<span>{Or}</span>}
                        {Word_File.length > 0 && <button className="spreadsheet-btn" onClick={Onclick_Word_File}>{Word_File}
                            <i className="fa-solid fa-file-lines"
                                style={{
                                color: "#ffffff",
                                fontSize: "16px",
                                }}
                            ></i>
                        </button>}
                        {/* {Or.length > 0 &&<span>{Or}</span>} */}
                        {/* {pdf_File.length > 0 && <button className="pdf-btn">{pdf_File}
                            <i
                                className="fa-solid fa-file-lines"
                                style={{
                                color: "#ffffff",
                                fontSize: "16px",
                                }}
                            ></i>
                        </button>} */}
                    </div>
                </div>
            </div>
        </div>
      </>
    );
  };

  export default DownlodeFile;