import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TeacherState {
    teacher: any,
    isLoading: boolean;
    error: string | null;
}

const initialState: TeacherState = {
    teacher: {},
    isLoading: false,
    error: null,
};

const teacherDetailsSlice = createSlice({
    name: "teacherDetails",
    initialState,
    reducers: {
        setTeacherDetails: (state: TeacherState, action: PayloadAction<TeacherState>) => {
            state.teacher = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: TeacherState, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: TeacherState, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetTeacherDetails: () => {
            return initialState;
        },
    },
});

export const {
    setTeacherDetails,
    setloading,
    seterror,
    resetTeacherDetails,
} = teacherDetailsSlice.actions;

export default teacherDetailsSlice.reducer;
