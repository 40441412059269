import React from "react";
const Sort = ({ sortArray, sortOption, setSortOption, setIsVisible }) => {

    return (
        <>
            <div className="sort-popup-assignprocess">
                <h3>Sort By:</h3>

                {sortArray?.map((option) => (
                    <label>
                        <input
                            type="radio"
                            name="filterOption"
                            value={option.id}
                            checked={option.id === sortOption}
                            onChange={(e) => {
                                setSortOption(e.target.value);
                                setIsVisible(false);
                            }}
                        />
                        {option.name}
                    </label>
                ))}
            </div>
        </>
    );
};

export default Sort;
